import { connect } from "react-redux";
import ReportingMainPage from "./ReportingMainPage";
import {
  getClientsRequest,
  supportCoordinatorsRequest,
  getCosReportingFundingTableDataRequest,
} from "../../../../store/actions/cos.actions";

import {
  getClienListSelector,
  getCaseNotesHistoryListSelector,
  getSupportCoordinator,
  getTableDataForReportingSelector,
  tableDataForReportingLoadingSelector,
  getTableDataOfCaseNotesForReportingSelector,
} from "../../../../store/selector/cos.selector";

const mapDispatchToProps = {
  getClientList: getClientsRequest,
  getSupportCoordinatoreList: supportCoordinatorsRequest,
  getTableDataForReporting: getCosReportingFundingTableDataRequest,
};

const mapStateToProps = (state) => {
  const clientsList = getClienListSelector(state);

  const caseNotesHistoryList = getCaseNotesHistoryListSelector(state);

  const supportCoordinator = getSupportCoordinator(state);
  const tableDataForReporting = getTableDataForReportingSelector(state);

  const tableDataForReportingLoading =
    tableDataForReportingLoadingSelector(state);

  const tableDataOfCaseNotesForReporting =
    getTableDataOfCaseNotesForReportingSelector(state);

  return {
    tableDataOfCaseNotesForReporting,

    tableDataForReportingLoading,
    tableDataForReporting,
    supportCoordinator,

    clientsList,
    caseNotesHistoryList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingMainPage);
