import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { TOAST_TYPE } from "../../../Constants/constant";
import { removeToast } from "../../../store/actions/general.actions";
import { getToastList } from "../../../store/selector/general.selector";

const ToastMessage = (props) => {
  const dispatch = useDispatch();
  const message = useSelector(getToastList);

  useEffect(() => {
    if (message && "id" in message) {
      if (!toast.isActive(message.id)) {
        if (message.type === TOAST_TYPE.SUCCESS) {
          toast.success(message.title, { toastId: message.id });
        } else if (message.type === TOAST_TYPE.ERROR) {
          toast.error(message.title, { toastId: message.id });
        } else {
          toast(message.title, { toastId: message.id });
        }
      }
      return;
    } else if (message) {
      if (message.type === TOAST_TYPE.SUCCESS) {
        toast.success(message.title);
      } else if (message.type === TOAST_TYPE.ERROR) {
        toast.error(message.title);
      } else {
        toast(message.title);
      }
    }
    dispatch(removeToast());
  }, [message]);

  return <></>;
};

export default ToastMessage;
