import { connect } from "react-redux";
import SubmittedFormView from "./SubmittedFormView";
import {
  getSupportPlanSubmittedFormsListRequest,
  resetSubmittedFormsList,
} from "../../../store/actions/sp.actions";
import {
  getSubmittedFormsList,
  getSubmittedFormsListLoading,
} from "../../../store/selector/sp.selector";

const mapDispatchToProps = {
  getSupportPlanFormsList: getSupportPlanSubmittedFormsListRequest,
  resetSubmittedFormsList,
};

const mapStateToProps = (state) => {
  const supportPlanFormsList = getSubmittedFormsList(state);
  const loading = getSubmittedFormsListLoading(state);
  return { loading, supportPlanFormsList };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedFormView);
