import { connect } from "react-redux";
import {
  spAllFormRequest,
  SpFormCategoriesRequest,
  clearCrmHrmformstemplatesLocally,
} from "../../../store/actions/sp.actions";
import {
  getSPFormCategories,
  SpFormCategoriesLaoder,
  spFormTemplates,
  spFormTemplatesLoader,
} from "../../../store/selector/sp.selector";
import AllForm from "./AllForms";

const mapDispatchToProps = {
  fetchFormCategories: SpFormCategoriesRequest,
  fetchFormTemplate: spAllFormRequest,
  clearCrmHrmformstemplatesLocally,
};

const mapStateToProps = (state) => {
  const categoryLoader = SpFormCategoriesLaoder(state);
  const formCategoriesList = getSPFormCategories(state);
  const spFormTemplatesList = spFormTemplates(state);
  const spFormTemplateLoader = spFormTemplatesLoader(state);

  return {
    categoryLoader,
    formCategoriesList,
    spFormTemplatesList,
    spFormTemplateLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllForm);
