import { connect } from "react-redux";
import {
  rolesAndPermissionslistRequest,
  defaultRolesAndPermissionsRequest,
  roleAndPermisisonCreateOrUpdateRequest,
  resetDefaultRolesPermissionLocally,
} from "store/actions/auth.actions";
import RolesAndPermissionSetting from "./RoleAndPermissionSetting";
import {
  getDefaultRoleAndPerLoader,
  getDefaultRoleAndPermissions,
  getListingRoleAndPerLoader,
  getRoleAndPermissionsCreateOrUpdateLoader,
  getRoleAndPermissionsList,
} from "store/selector/auth.selector";

const mapDispatchToProps = {
  roleAndPermisisonCreateOrUpdateRequest,
  rolesAndPermissionslistRequest,
  defaultRolesAndPermissionsRequest,
  resetDefaultRolesPermissionLocally,
};

const mapStateToProps = (state) => {
  const roleAndPermissionsCreateOrUpdateLoader =
    getRoleAndPermissionsCreateOrUpdateLoader(state);
  const roleAndPermissionsList = getRoleAndPermissionsList(state);
  const defaultRoleAndPerLoader = getDefaultRoleAndPerLoader(state);
  const defaultRoleAndPermissions = getDefaultRoleAndPermissions(state);
  const listingRoleAndPerLoader = getListingRoleAndPerLoader(state);

  return {
    roleAndPermissionsCreateOrUpdateLoader,
    roleAndPermissionsList,
    defaultRoleAndPerLoader,
    defaultRoleAndPermissions,
    listingRoleAndPerLoader,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesAndPermissionSetting);
