import { EMAIL_REGEX_EXP, REGEX_PHONE_NUMBER } from "Constants/constant";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CustomButton from "../../../components/common/Button/CustomButton";
import ModalSkeleton from "../../../components/Modals/ModalSkeleton";
import { apiPostRequest } from "../../../helpers/Requests";

const AddServiceProviderModal = (props) => {
  const { openModal, closeModal } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const dataSubmitHandler = async (data) => {
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiPostRequest("super-admin/sale-leads", data);
      if (res.status !== 201) {
        // console.log("Something Went Wrong");
        toast.error("Something Went Wrong");
        setRequest({ ...request, isRequested: false, isFailed: true });
      }
      if (res.status === 201) {
        toast.success("Invite Sent Successfully!");
        setRequest({ ...request, isRequested: false, isSuccess: true });
        handleClose();
      }
    } catch (err) {
      console.log("err", err);
      toast.error(err.message);
      setRequest({ ...request, isRequested: false, isFailed: true });
    }
  };

  const onSubmit = (data) => {
    dataSubmitHandler(data);
  };

  const services = [
    "Plan Management",
    "Support Coordination",
    "Technical Skills",
    "Others",
  ];

  const modalFooter = () => {
    return (
      <div>
        <CustomButton
          showLoading={request.isRequested}
          isDisabled={request.isRequested}
          label={"Invite"}
          clickHandler={() => {
            const buttonRef = document.getElementById(
              "serviceProviderModalButton"
            );
            if (buttonRef) {
              buttonRef.click();
            }
          }}
        />
      </div>
    );
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <ModalSkeleton
      isOpen={openModal}
      closeModal={handleClose}
      modalFooter={modalFooter()}
      cssClass='w-[50%]'
      title={"Create New Service Provider"}
    >
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500 mb-3'>
          Please enter following details to create Service Provider
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className=''>
          <div className='grid grid-cols-2 gap-x-4 gap-y-2'>
            <div className='col-span-2 md:col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Business Name
              </label>
              <input
                {...register("name", {
                  required: true,
                  maxLength: 254,
                })}
                type='text'
                className='did-floating-input'
              />
              {errors?.name?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
            <div className='col-span-2 md:col-span-1'>
              <label
                htmlFor=''
                className='block text-sm font-medium text-gray-700'
              >
                Email Address
              </label>
              <input
                {...register("email", {
                  required: true,
                  pattern: EMAIL_REGEX_EXP,
                })}
                type='email'
                className='did-floating-input'
              />
              {errors?.email?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
              {errors?.email?.type === "pattern" && (
                <span className='text-sm text-red-500'>
                  Please enter a valid email address!
                </span>
              )}
            </div>
            <div className='col-span-2 md:col-span-1'>
              <label
                htmlFor=''
                className='block text-sm font-medium text-gray-700'
              >
                Contact Number
              </label>
              <input
                {...register("phone", {
                  required: true,
                  maxLength: 10,
                  // minLength: 10,
                  pattern: REGEX_PHONE_NUMBER,
                  onChange: (e) => {
                    const sanitizedValue = e.target.value.replace(/\D/g, "");
                    const formattedValue = sanitizedValue.slice(0, 10);
                    e.target.value = formattedValue;
                  },
                })}
                type='number'
                className='did-floating-input'
              />
              {errors?.phone?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
              {errors?.phone?.type === "maxLength" && (
                <span className='text-sm text-red-500'>
                  Please enter a valid phone number!
                </span>
              )}
              {errors?.phone?.type === "minLength" && (
                <span className='text-sm text-red-500'>
                  Please enter a valid phone number!
                </span>
              )}
              {errors?.phone?.type === "pattern" && (
                <span className='text-sm text-red-500'>
                  {/* Phone number must start with 04, 1300 or 1800 */}
                  Please enter a valid phone number!
                </span>
              )}
            </div>
            <div className='col-span-2 md:col-span-1'>
              <label
                htmlFor=''
                className='block text-sm font-medium text-gray-700'
              >
                ABN
              </label>
              <input
                {...register("abn", {
                  required: true,
                  pattern: "[0-9]*",
                  maxLength: 11,
                  minLength: 11,
                  onChange: (e) =>
                    (e.target.value = e.target.value.toString().slice(0, 11)),
                })}
                type='number'
                className='did-floating-input'
              />
              {errors?.abn?.type === "required" ? (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              ) : errors?.abn?.type === "maxLength" ? (
                <span className='text-sm text-red-500'>
                  Please enter a valid ABN number max 11 digits.
                </span>
              ) : errors?.abn?.type === "minLength" ? (
                <span className='text-sm text-red-500'>
                  Please enter a valid ABN number min 11 digits.
                </span>
              ) : null}
            </div>
            <div className='col-span-2 md:col-span-1'>
              <label
                htmlFor=''
                className='block text-sm font-medium text-gray-700'
              >
                ACN
              </label>
              <input
                {...register("asn", {
                  // required: true,
                  pattern: "[0-9]*",
                })}
                type='number'
                className='did-floating-input'
              />
              {errors?.asn?.type === "required" ? (
                <span className='text-red-500'>This field is required</span>
              ) : errors.asn?.type === "pattern" ? (
                <span className='text-red-500'>
                  Please enter a valid number
                </span>
              ) : null}
            </div>
            <div className='col-span-2'>
              <label
                htmlFor=''
                className='block text-sm font-medium text-gray-700'
              >
                Services
              </label>
              <div className='flex w-full flex-wrap pt-2 space-y-1'>
                {services.map((service, i) => {
                  return (
                    <div
                      key={i}
                      className='flex items-center space-x-2 w-full sm:w-1/2'
                    >
                      <input
                        {...register(`services[${i}].${service}`, {})}
                        type='checkbox'
                        id={i}
                        className='border text-sm rounded p-1  focus-visible:outline-blue-500'
                      />
                      <label htmlFor={i} className='text-sm'>
                        {service}
                      </label>
                    </div>
                  );
                })}
              </div>
              {errors?.service?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>

          <button
            type='submit'
            className='sr-only hidden'
            id='serviceProviderModalButton'
          />
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default AddServiceProviderModal;
