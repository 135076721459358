import React, { useState, useEffect, useRef } from "react";
import "./select.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import Loader from "../Loader/Loader";

const CustomMultiSelect = ({
  options,
  selectedRegions,
  onChange,
  placeholder = "Select...",
  disabled = false,
  errorMessage = null,
  onFocusHandler,
  setSelectedRegions,
  isApiLoading=false,
  isDisabled=false
}) => {

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDetailedDropdown, setShowDetailedDropdown] = useState(false);
  // const [selectedItems, setSelectedItems] = useState(selectedRegions || []);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
        setShowDetailedDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleToggleDropdown = () => {
    onFocusHandler();
    if (!disabled) setShowDropdown((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    let updatedSelection;
  
    if (option.value === "all") {
      if (!selectedRegions?.some(item => item.value === "all")) {
        updatedSelection = [option];
      } else {
        updatedSelection = [];
      }
    } else {
      if (selectedRegions?.some(item => item.value === "all")) {
        updatedSelection = selectedRegions?.filter(item => item.value !== "all");
      } else {
        updatedSelection = [...selectedRegions];
      }

      if (updatedSelection.some(item => item.value === option.value)) {
        updatedSelection = updatedSelection.filter(item => item.value !== option.value);
      } else {
        updatedSelection.push(option);
      }
    }

    setSelectedRegions(updatedSelection);
    onChange && onChange(updatedSelection);
  };

  const handleRemoveItem = (item) => {
    if (isDisabled) return "";
    const updatedSelection = selectedRegions?.filter((selected) => selected.value !== item.value);
    setSelectedRegions(updatedSelection);
    onChange && onChange(updatedSelection);
  };

  return (
    
 <div className="custom-multi-select" ref={dropdownRef}>
    <div
      className={`dropdown-header  ${disabled || isDisabled ? "disabled" : ""}`}
      onClick={!isDisabled ? handleToggleDropdown : undefined} 
    >
      {selectedRegions?.length > 0 ? (
        <div className="selected-items">
          {selectedRegions?.slice(0, 2).map((item) => (
            <span key={item.value} className="selected-item">
              {item.label}
              <span
                className="remove-item"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveItem(item);
                }}
              >
                ×
              </span>
            </span>
          ))}
          {selectedRegions?.length > 2 && (
            <span
              className="rounded-full bg-red-400 px-2 flex items-center text-xs font-semibold"
              onMouseEnter={() => setShowDetailedDropdown(true)}
              onMouseLeave={() => setShowDetailedDropdown(false)}
            >
              +{selectedRegions?.length - 2}
            </span>
          )}
        </div>
      ) : (
        <span className="placeholder">{placeholder}</span>
      )}
      <span className="dropdown-arrow">
        {showDropdown ? <FiChevronUp className="h-5 w-5" /> : <FiChevronDown  className="h-5 w-5" />}
      </span>
    </div>

    {showDropdown && !isDisabled && (
        <div className="dropdown-list max-h-48 overflow-auto custom-scroll border shadow shadow-lg pl-1">

        {options.length > 0 ? (
          options
            .filter(option => !selectedRegions?.some(item => item.value === option.value))
            .map(option => (
              <div
                key={option.value}
                className="dropdown-item"
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ))
        ) : (
          <div className="no-options">
            {isApiLoading ? (
              <div className="flex justify-center">
                <Loader
                  isSmallView={true}
                  cssClass={"text-black font-bo  mr-0 p-0 flex"}
                />
              </div>
            ) : (
              <p>No Options Available</p>
            )}
          </div>
        )}
      </div>
    )}

    {showDetailedDropdown && !isDisabled && (
      <div
        className="detailed-dropdown shadow shadow-lg"
        onMouseEnter={() => setShowDetailedDropdown(true)}
        onMouseLeave={() => setShowDetailedDropdown(false)}
      >
        {selectedRegions?.slice(2).map((item) => (
          <span key={item.value} className="detailed-selected-item">
            {item.label}
          </span>
        ))}
      </div>
    )}

    {errorMessage && <span className="error-message">{errorMessage}</span>}
  </div>

  );
};

export default CustomMultiSelect;
