/* eslint-disable no-fallthrough */
import {
  //   FaFileAlt,
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileImage,
  FaFileAudio,
  FaFileVideo,
  //   FaFileZip,
} from "react-icons/fa";
import FileImage from "assets/file.svg";
import { BsFileZip } from "react-icons/bs";

const FileIcon = (extension, size = 10) => {
  switch (extension) {
    case "pdf":
      return <FaFilePdf size={size} />;
    case "doc":
    case "docx":
      return <FaFileWord size={size} />;
    case "xls":
    case "xlsx":
    case "csv":
      return <FaFileExcel size={size} />;
    case "ppt":
    case "pptx":
      return <FaFilePowerpoint size={size} />;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "svg":
      return <FaFileImage size={size} />;
    case "mp3":
    case "wav":
      return <FaFileAudio size={size} />;
    case "mp4":
    case "avi":
    case "mov":
      return <FaFileVideo size={size} />;
    case "zip":
    case "rar":
      return <BsFileZip size={size} />;
    default:
      return FileImage;
  }
};

export default FileIcon;
