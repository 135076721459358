import { BiGroup, BiLineChartDown } from "react-icons/bi";
import { AiOutlineUsergroupAdd, AiOutlineAlert } from "react-icons/ai";
import {
  MdDashboard,
  MdOutlineDocumentScanner,
  MdOutlineFeedback,
} from "react-icons/md";
import { USER_ROLE } from "../constant";
import { RiCustomerService2Fill, RiCalendar2Fill,RiArticleLine } from "react-icons/ri";
import { PERMISSION_KEYS } from "store/constants";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { icons } from "react-icons/lib";
import { TbReport } from "react-icons/tb";




export const SpAdminNavigation = [
  {
    name: "Dashboard",
    icon: MdDashboard,
    current: false,
    to: "/",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    // permissionName: PERMISSION_KEYS.DASHBOARD,
  },
  {
    name: "Participants",
    icon: AiOutlineUsergroupAdd,
    current: false,
    to: "/participant-list",
    children: [{ name: "Participant List", href: "/participant-list" }],
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.PARTICIPANT,
  },
  {
    name: "HRM",
    icon: BiGroup,
    current: false,
    to: "/hrm",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.HRM,
  },
  {
    name: "Support Coordination",
    icon: RiCustomerService2Fill,
    current: "/cos/",
    to: "/cos/dashboard",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.COS,
  },
  {
    name: "Rostering",
    icon: RiCalendar2Fill,
    current: false,
    to: "/rostering/dashboard",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.ROSTERING,
  },
  {
    name: "Invoicing",
    icon: RiArticleLine,
    current: false,
    to: "/rostering/invoicing",
    role: [USER_ROLE.Sp_Admin,USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.ROSTER_INVOICE,
  },
  {
    name: "Reporting",
    icon: TbReport,
    current: false,
    to: "/reporting",
    role: [USER_ROLE.Sp_Admin,USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.REPORTING,
  },
  {
    name: "Forms Management CRM",
    icon: BiLineChartDown,
    current: false,
    to: "/forms-list",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.CRM_FORM,
  },
  {
    name: "Forms Management HRM",
    icon: BiLineChartDown,
    current: false,
    to: "/forms-list-hrm",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.HRM_FORM,
  },
  {
    name: "Policies",
    icon: MdOutlineDocumentScanner,
    current: false,
    to: "/policies",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.POLICy,
  },
  {
    name: "Complaints",
    icon: AiOutlineAlert,
    current: false,
    to: "/complaints",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.COMPLAINT,
  },
  {
    name: "Feedback",
    icon: MdOutlineFeedback,
    current: false,
    to: "/feedbacks",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.FEEDBACK,
  },
  {
    name: "Ask a Question",
    icon: AiOutlineQuestionCircle,
    current: false,
    to: "/ask-a-question",
    role: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
    permissionName: PERMISSION_KEYS.ASK_QUESTION,
  },
  // {
  //   name: "Notification Setting",
  //   icon: MdEditNotifications,
  //   current: false,
  //   to: "/notification-setting",
  //   role: [USER_ROLE.Sp_Admin],
  // },
];
