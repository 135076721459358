import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "../../../../components/common/Button/CustomButton";
import ServiceCategoryBudgetModal from "./ServiceCategoryBudgetModal";
import ServiceCategoryTable from "./ServiceCategoryTable";

const ServiceCategoryBudget = (props) => {
  const {
    selectedFunding,
    participant,
    getCatalogServicesSubCategory,
    catalogStates,
    getPlanBudgetCatAndSubCat,
    planBudgetCatAndSubCatOptions,
    storeServiceCategoryPlanBudget,
    externalSps,
    storeServicePlanLoader,
    storeServicePlanError,
    getServiceCategoryList,
    serviceCategories,
    fetchServiceCategoryLoader,
    removeServiceCategoryLoader,
    removeServiceCategory,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const [catalogState, setCatalogState] = useState(null);
  const [editServiceCategory, setEditServiceCategory] = useState(null);

  useEffect(() => {
    if (editServiceCategory) {
      setOpenModal(true);
    }
  }, [editServiceCategory]);

  useEffect(() => {
    if (participant.state_id && catalogStates) {
      const stateObj = catalogStates.find(
        (x) => x.value === participant.state_id
      );
      setCatalogState({ ...stateObj });
    }
  }, [participant, catalogStates]);

  useEffect(() => {
    if (!catalogStates) {
      getCatalogServicesSubCategory({ isService: false });
    }
    getPlanBudgetCatAndSubCat({
      funding_reference_id: selectedFunding.contract_id,
      pt_id: participant.uuid,
    });
    // if (selectedFunding && participant && !serviceCategories) {
    //   getServiceCategoryList({
    //     funding_uuid: selectedFunding.uuid,
    //     pt_id: participant.uuid,
    //   });
    // }
  }, []);

  return (
    <div className="max-w-7xl mx-auto py-3 bg-white">
      <div>
        <div className="flex justify-between items-center px-6 bg-gray-25 border md:rounded-t-lg font-bold text-lg text-gray-900 py-4">
          <div>Service Provider Category Budget</div>
          <CustomButton
            label="SP Category Budget"
            clickHandler={() => setOpenModal(true)}
            Icon={<BiPlus />}
            cssClass="border-0"
          />
        </div>
        <div>
          <ServiceCategoryTable
            getServiceCategoryList={getServiceCategoryList}
            funding={selectedFunding}
            participant={participant}
            serviceCategories={serviceCategories}
            fetchLoader={fetchServiceCategoryLoader}
            removeLoader={removeServiceCategoryLoader}
            removeServiceCategory={removeServiceCategory}
            setEditServiceCategory={setEditServiceCategory}
          />
        </div>
      </div>

      {openModal && (
        <ServiceCategoryBudgetModal
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
            setEditServiceCategory(null);
          }}
          externalSps={externalSps}
          PlanBudgetCatAndSubCatagories={planBudgetCatAndSubCatOptions}
          funding={selectedFunding}
          catalogState={catalogState}
          storeServiceCategoryPlanBudget={storeServiceCategoryPlanBudget}
          storeServicePlanLoader={storeServicePlanLoader}
          storeServicePlanError={storeServicePlanError}
          participant={participant}
          editServiceCategoryBudget={editServiceCategory}
        />
      )}
    </div>
  );
};

export default ServiceCategoryBudget;
