import { useRef } from "react";
import uniqueId from "lodash/uniqueId";

export default function useUniqueId(prefix, len) {
  const idRef = useRef();

  const createUID = (len) => {
    const buf = [];
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charlen = chars.length;
    const length = len || 10;
    for (let i = 0; i < length; i++) {
      buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
    }
    return buf.join("");
  };

  if (!idRef.current) {
    idRef.current = `${uniqueId(prefix)}-${createUID(len)}`;
  }

  return idRef.current;
}
