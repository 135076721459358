import { connect } from "react-redux";
import COSInvoicePage from "./COSInvoicePage";
import {
  addCaseNotesToClaimListRequest,
  getCaseNotesListForClaimsRequest,
  getClientsRequest,
  getCosInvoicesListRequest,
  getCreateCosInvoicesRequest,
  RemoveReviewCaseNoteList,
  supportCoordinatorsRequest,
} from "store/actions/cos.actions";
import {
  createCosInvoicesLoader,
  getCaseNoteReviewSelector,
  getCaseNotesListForClaimLoaderSelector,
  getCaseNotesListForClaimSelector,
  getClienListLoaderSelector,
  getClienListSelector,
  getCOSInvoiceLoaderSelector,
  getcosInvoiceSelector,
  getSupportCoordinator,
} from "store/selector/cos.selector";

const mapDispatchToProps = {
  getClientsRequest,
  supportCoordinatorsRequest,
  getCaseNotesRequest: getCaseNotesListForClaimsRequest,
  createClaim: addCaseNotesToClaimListRequest,
  emptyTableData: RemoveReviewCaseNoteList,
  getCosInvoice: getCosInvoicesListRequest,
  createCosInvoices: getCreateCosInvoicesRequest,
};

const mapStateToProps = (state) => {
  const caseNoteReview = getCaseNoteReviewSelector(state);
  const clientsList = getClienListSelector(state);
  const clientsListLoader = getClienListLoaderSelector(state);
  const supportCoordinator = getSupportCoordinator(state);
  const caseNotesListForClaim = getCaseNotesListForClaimSelector(state);
  const caseNotesListForClaimLoader =
    getCaseNotesListForClaimLoaderSelector(state);
  const cosInvoiceList = getcosInvoiceSelector(state); // these are support coordinators not a category
  const cosInvoiceLoading = getCOSInvoiceLoaderSelector(state); // these are support coordinators not a category
  const createCosInvoiceLoading = createCosInvoicesLoader(state); // these are support coordinators not a category

  return {
    createCosInvoiceLoading,
    cosInvoiceList,
    cosInvoiceLoading,
    clientsList,
    supportCoordinator,
    caseNotesListForClaim,
    caseNoteReview,
    caseNotesListForClaimLoader,
    clientsListLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(COSInvoicePage);
