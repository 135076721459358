import TableHeader from 'components/common/Table/TableHeader'
import React from 'react'

const Table = ({COLUMNS}) => {
  return (
    <div className="bg-white relative shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
    <table className="w-full table-fixed divide-y divide-gray-300 bg-white">
      <thead
        className="bg-red-50 sticky z-[2] capitalize"
        style={{ top: "157px" }}
      >
        <tr>
          {
            COLUMNS?.map((item,index)=>(     <TableHeader  label={item?.header} isSort={item?.isSort} />))
          }
     
         
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
      <tr  className="even:bg-white odd:bg-gray-50">
        {COLUMNS.length > 0
          && COLUMNS.map((data, index) => (
           
                <td className="whitespace-nowrap px-3 py-4 text-start text-sm text-gray-500 truncate capitalize">
               -
                </td>
           
              
            ))}
            </tr>
      
      </tbody>
    </table>
  </div>
  )
}

export default Table
