const parseError = (error) => {
  let message = "";
  if (error.response) {
    message = error.response.data.error ?? error.response.data.message;
  } else if (error.request) {
    message = "No Internet Connectivity!";
  } else {
    message = error.message;
  }
  throw new Error(message);
};

export default parseError;
