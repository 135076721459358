import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PAYROLL_PLATFORMS } from "store/constants";
import Loader from "components/common/Loader/Loader";
import refreshIcon from "assets/svg/refresh.svg";
import { AiOutlineQuestion } from "react-icons/ai";
import ImageDisplayModal from "../../TeamManagment/ImageDisplayModalForXeroWorker";
import {
  capitalizeFirstLetter,
  classNames,
  parseBooleanVal,
} from "Utils/utils";
import { FiArrowUpRight } from "react-icons/fi";
import spApi from "store/services/sp.services";
import SelectOption from "components/common/Select/Select";
import InputField from "components/common/Input/Input";

const WorkerPayrollSection = (props) => {
  const {
    worker,
    register,
    watchFormData,
    clearErrors,
    setValue,
    errors,
    isAuthenticateForPayrolls,
    isCheckPayrollAuthLoading,
  } = props;

  const navigate = useNavigate();

  //Quickbook
  // const [quickBooksEmployees, setQuickBooksEmployees] = useState([]);
  // const [isLoadingQuickBookEmployee, setisLoadingQuickBookEmployee] =
  //   useState(false);
  const [quickBooksBusinesses, setQuickBooksBusinesses] = useState([]);
  const [isLoadingQuickBookBusiness, setisLoadingQuickBookBusiness] =
    useState(false);

  // MYOB
  const [myobBusinesses, setMyobBusinesses] = useState([]);
  const [myobEmployees, setMyobEmployees] = useState([]);
  const [myobCategories, setMyobCategories] = useState([]);
  const [isLoadingMyobBusiness, setisLoadingMyobBusiness] = useState(false);
  const [myobFetchEmployeesLoader, setMyobFetchEmployeesLoader] =
    useState(false);
  const [myobSyncEmployeesLoader, setMyobSyncEmployeesLoader] = useState(false);
  const [myobFetchCategoriesLoader, setMyobFetchCategoriesLoader] =
    useState(false);
  const [myobSyncCategoriesLoader, setMyobSyncCategoriesLoader] =
    useState(false);

  const [showXeroImage, setXeroImage] = useState(false);
  const [showQuickbooksImage, setQuickbooksImage] = useState(false);

  useEffect(() => {
    if (isAuthenticateForPayrolls) {
      // getQuickBookEmployees();
      if (
        parseBooleanVal(isAuthenticateForPayrolls[PAYROLL_PLATFORMS.QUICKBOOKS])
      ) {
        getQuickBookBusinesses();
      }
      if (parseBooleanVal(isAuthenticateForPayrolls[PAYROLL_PLATFORMS.MYOB])) {
        getMyobBusiness();
        if (worker?.myob_company_id) {
          getMyobEmployees();
          getMyobCategories();
        }
      }
    }
  }, [isAuthenticateForPayrolls]);

  useEffect(() => {
    const company_id =
      watchFormData?.myob_company_id ?? worker?.myob_company_id;
    if (
      (myobCategories.length === 0 || myobEmployees.length === 0) &&
      company_id &&
      isAuthenticateForPayrolls &&
      parseBooleanVal(isAuthenticateForPayrolls[PAYROLL_PLATFORMS.MYOB])
    ) {
      getMyobEmployees();
      getMyobCategories();
    }
  }, [watchFormData?.myob_company_id]);

  // const syncQuickBooksEmployeesCall = async () => {
  //   try {
  //     setisLoadingQuickBookEmployee(true);
  //     const response = await employeeApi.syncQuickBooksEmployees();
  //     if (response && response.data) {
  //       setQuickBooksEmployees(
  //         response?.data?.map((x) => ({
  //           ...x,
  //           label: x.name,
  //           value: x.qb_id,
  //         })) || []
  //       );
  //     }
  //     setisLoadingQuickBookEmployee(false);
  //   } catch (err) {
  //     console.log("err", err);
  //     setisLoadingQuickBookEmployee(false);
  //   }
  // };

  const getQuickBookBusinesses = async () => {
    try {
      setisLoadingQuickBookBusiness(true);
      const response = await spApi.getQuickBookBusinesses();

      if (response && response.data) {
        setQuickBooksBusinesses(
          response?.data?.map((x) => ({
            ...x,
            label: x.business_name,
            value: x.business_id,
          })) || []
        );
      }
      setisLoadingQuickBookBusiness(false);
    } catch (err) {
      console.log("err", err);
      setisLoadingQuickBookBusiness(false);
    }
  };

  const getMyobBusiness = async () => {
    try {
      setisLoadingMyobBusiness(true);
      const response = await spApi.getMyobBusinesses();

      if (response && response.data) {
        setMyobBusinesses(
          response?.data?.map((x) => ({
            ...x,
            label: x.company_name,
            value: x.company_id,
          })) || []
        );
      }
      setisLoadingMyobBusiness(false);
    } catch (err) {
      console.log("err", err);
      setisLoadingMyobBusiness(false);
    }
  };

  const getMyobEmployees = async () => {
    try {
      const company_id =
        watchFormData?.myob_company_id ?? worker?.myob_company_id;
      setMyobFetchEmployeesLoader(true);
      const response = await spApi.getMyobEmployees({
        myob_company_id: company_id,
      });
      if (response && response.data) {
        setMyobEmployees(
          response?.data?.map((x) => ({
            ...x,
            label: capitalizeFirstLetter(`${x.first_name} ${x.last_name}`),
            value: x.myob_id,
          })) || []
        );
      }
      setMyobFetchEmployeesLoader(false);
    } catch (err) {
      console.log("err", err);
      setMyobFetchEmployeesLoader(false);
    }
  };

  const syncMyobEmployees = async () => {
    try {
      const company_id =
        watchFormData?.myob_company_id ?? worker?.myob_company_id;
      setMyobSyncEmployeesLoader(true);
      const response = await spApi.syncMyobEmployees({
        myob_company_id: company_id,
      });
      if (response && response.data) {
        setMyobEmployees(
          response?.data?.map((x) => ({
            ...x,
            label: capitalizeFirstLetter(`${x.first_name} ${x.last_name}`),
            value: x.myob_id,
          })) || []
        );
      }
      setMyobSyncEmployeesLoader(false);
    } catch (err) {
      console.log("err", err);
      setMyobSyncEmployeesLoader(false);
    }
  };

  const getMyobCategories = async () => {
    try {
      const company_id =
        watchFormData?.myob_company_id ?? worker?.myob_company_id;
      setMyobFetchCategoriesLoader(true);
      const response = await spApi.getMyobCategories({
        myob_company_id: company_id,
      });
      if (response && response.data) {
        setMyobCategories(
          response?.data?.map((x) => ({
            ...x,
            label: x.name,
            value: x.uid,
          })) || []
        );
      }
      setMyobFetchCategoriesLoader(false);
    } catch (err) {
      console.log("err", err);
      setMyobFetchCategoriesLoader(false);
    }
  };

  const syncMyobCategories = async () => {
    try {
      const company_id =
        watchFormData?.myob_company_id ?? worker?.myob_company_id;
      setMyobSyncCategoriesLoader(true);
      const response = await spApi.syncMyobCategories({
        myob_company_id: company_id,
      });
      if (response && response.data) {
        setMyobCategories(
          response?.data?.map((x) => ({
            ...x,
            label: x.name,
            value: x.uid,
          })) || []
        );
      }
      setMyobSyncCategoriesLoader(false);
    } catch (err) {
      console.log("err", err);
      setMyobSyncCategoriesLoader(false);
    }
  };

  return (
    <>
      {isCheckPayrollAuthLoading ? (
        <>
          <br />
          <Loader cssClass={"text-black"} isSmallView={true} />
        </>
      ) : (
        <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-5 mt-2'>
          <div>
            {isAuthenticateForPayrolls !== null &&
            parseBooleanVal(
              isAuthenticateForPayrolls[PAYROLL_PLATFORMS.XERO]
            ) ? (
              <div className='w-full flex space-x-2'>
                <div className='w-[95%]'>
                  <InputField
                    setValue={setValue}
                    register={register}
                    value={watchFormData?.xero_worker_id ?? null}
                    label='Xero Employee ID'
                    type='text'
                    name='xero_worker_id'
                  />
                </div>
                <div className='w-[5%] mt-4 flex justify-center items-center'>
                  <span
                    onClick={() => setXeroImage(true)}
                    title='xero employee id'
                    className='h-6 w-6 flex  cursor-pointer  justify-center items-center rounded-full bg-[#3E5A99] text-white'
                  >
                    <AiOutlineQuestion />
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Xero Employee ID
                </label>
                <div
                  onClick={() => navigate("/payroll-setting")}
                  className='mt-1 text-sm text-gray-900 flex items-center space-x-2 cursor-pointer'
                >
                  <span className='text-blue-700'>Connect Xero</span>
                  <span className='text-blue-600'>
                    <FiArrowUpRight />
                  </span>
                </div>
              </div>
            )}
          </div>
          <div />

          {/* QuickBook */}
          <div className='w-full flex space-x-2'>
            <div className='w-[95%]'>
              <InputField
                setValue={setValue}
                register={register}
                value={watchFormData?.quick_books_worker_id ?? null}
                label='QuickBooks Employee ID'
                type='text'
                name='quick_books_worker_id'
              />
            </div>
            <div className='w-[5%] mt-4 flex justify-center items-center'>
              <span
                onClick={() => setQuickbooksImage(true)}
                title='QuickBooks Employee ID'
                className='h-6 w-6 flex  cursor-pointer  justify-center items-center rounded-full bg-[#3E5A99] text-white'
              >
                <AiOutlineQuestion />
              </span>
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-700'>
              QuickBooks Business
            </label>
            <div className='w-full flex space-x-2 mt-1'>
              {isAuthenticateForPayrolls !== null &&
              parseBooleanVal(
                isAuthenticateForPayrolls[PAYROLL_PLATFORMS.QUICKBOOKS]
              ) ? (
                <div className='w-[100%]'>
                  <SelectOption
                    isRequired={false}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    name='quick_books_business_id'
                    loading={isLoadingQuickBookBusiness}
                    isDisabled={isLoadingQuickBookBusiness}
                    isSearch={false}
                    data={quickBooksBusinesses}
                    selectedOption={
                      watchFormData?.quick_books_business_id
                        ? quickBooksBusinesses.find(
                            (x) =>
                              x.value ===
                              parseInt(
                                watchFormData?.quick_books_business_id || 0
                              )
                          )
                        : null
                    }
                    errorMessage={
                      errors?.quick_books_business_id?.type === "required"
                        ? "This field is required!"
                        : null
                    }
                  />
                </div>
              ) : (
                <div
                  onClick={() => navigate("/payroll-setting")}
                  className='mt-1 text-sm text-gray-900 flex items-center space-x-2 cursor-pointer'
                >
                  <span className='text-blue-700'>Connect QuickBook</span>
                  <span className='text-blue-600'>
                    <FiArrowUpRight />
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* MYOB */}
          <div className='col-span-2 grid sm:grid-cols-1 md:grid-cols-3 gap-y-4 gap-x-2 mt-2 '>
            {isAuthenticateForPayrolls !== null &&
            parseBooleanVal(
              isAuthenticateForPayrolls[PAYROLL_PLATFORMS.MYOB]
            ) ? (
              <>
                {/* MYOB Business */}
                <div className='col-span-1'>
                  <label className='block text-sm font-medium text-gray-700'>
                    MYOB Business
                  </label>
                  <div className='w-full flex space-x-2 mt-1'>
                    {isAuthenticateForPayrolls !== null &&
                    parseBooleanVal(
                      isAuthenticateForPayrolls[PAYROLL_PLATFORMS.MYOB]
                    ) ? (
                      <div className='w-[100%]'>
                        <SelectOption
                          isRequired={false}
                          register={register}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          name='myob_company_id'
                          loading={isLoadingMyobBusiness}
                          isDisabled={isLoadingMyobBusiness}
                          isSearch={false}
                          data={myobBusinesses}
                          selectedOption={
                            watchFormData?.myob_company_id
                              ? myobBusinesses.find(
                                  (x) =>
                                    x.value === watchFormData?.myob_company_id
                                )
                              : null
                          }
                          errorMessage={
                            errors?.myob_company_id?.type === "required"
                              ? "This field is required!"
                              : null
                          }
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => navigate("/payroll-setting")}
                        className='mt-1 text-sm text-gray-900 flex items-center space-x-2 cursor-pointer'
                      >
                        <span className='text-blue-700'>Connect Myob</span>
                        <span className='text-blue-600'>
                          <FiArrowUpRight />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {/* MYOB Employees */}
                <div className='col-span-1'>
                  <label className='block text-sm font-medium text-gray-700'>
                    MYOB Employees
                  </label>
                  <div className='w-full flex space-x-2 mt-1'>
                    <div className='w-[90%]'>
                      <SelectOption
                        isRequired={false}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        name='myob_worker_id'
                        loading={myobFetchEmployeesLoader}
                        isDisabled={
                          myobFetchEmployeesLoader || myobSyncEmployeesLoader
                        }
                        isSearch={false}
                        data={myobEmployees}
                        selectedOption={
                          watchFormData?.myob_worker_id
                            ? myobEmployees.find(
                                (x) => x.value === watchFormData?.myob_worker_id
                              )
                            : null
                        }
                        errorMessage={
                          errors?.myob_worker_id?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div className='w-[10%] flex justify-center items-center'>
                      <div
                        title='Sync MYOB Employees'
                        role='button'
                        tabIndex={0}
                        onClick={() =>
                          myobSyncEmployeesLoader ? {} : syncMyobEmployees()
                        }
                        className={classNames(
                          "border border-gray-200 bg-gray-50 rounded-lg cursor-pointer p-2 py-2.5",
                          myobSyncEmployeesLoader && "bg-[#4b6bb710]"
                        )}
                      >
                        {myobSyncEmployeesLoader ? (
                          <Loader
                            isSmallView={true}
                            cssClass={"text-black mr-0 p-0 flex"}
                          />
                        ) : (
                          <img
                            className='w-4 h-4'
                            src={refreshIcon}
                            alt='refresh icon'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* MYOB Categories */}
                <div className='col-span-1'>
                  <label className='block text-sm font-medium text-gray-700'>
                    MYOB Payroll Categories
                  </label>
                  <div className='w-full flex space-x-2 mt-1'>
                    <div className='w-[90%]'>
                      <SelectOption
                        isRequired={false}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        name='myob_payroll_category'
                        loading={myobFetchCategoriesLoader}
                        isDisabled={
                          myobFetchCategoriesLoader || myobSyncCategoriesLoader
                        }
                        isSearch={true}
                        data={myobCategories}
                        selectedOption={
                          watchFormData?.myob_payroll_category
                            ? myobCategories.find(
                                (x) =>
                                  x.value ===
                                  watchFormData?.myob_payroll_category
                              )
                            : null
                        }
                        errorMessage={
                          errors?.myob_payroll_category?.type === "required"
                            ? "This field is required!"
                            : null
                        }
                      />
                    </div>
                    <div className='w-[10%] flex justify-center items-center'>
                      <div
                        title='Sync MYOB Categories'
                        role='button'
                        tabIndex={0}
                        onClick={() =>
                          myobSyncCategoriesLoader ? {} : syncMyobCategories()
                        }
                        className={classNames(
                          "border border-gray-200 bg-gray-50 rounded-lg cursor-pointer p-2 py-2.5",
                          myobSyncCategoriesLoader && "bg-[#4b6bb710]"
                        )}
                      >
                        {myobSyncCategoriesLoader ? (
                          <Loader
                            isSmallView={true}
                            cssClass={"text-black mr-0 p-0 flex"}
                          />
                        ) : (
                          <img
                            className='w-4 h-4'
                            src={refreshIcon}
                            alt='refresh icon'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  MYOB Payroll
                </label>
                <div
                  onClick={() => navigate("/payroll-setting")}
                  className='mt-1 text-sm text-gray-900 flex items-center space-x-2 cursor-pointer'
                >
                  <span className='text-blue-700'>Connect MYOB</span>
                  <span className='text-blue-600'>
                    <FiArrowUpRight />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showXeroImage && (
        <ImageDisplayModal
          isOpen={showXeroImage}
          setOpen={setXeroImage}
          type={PAYROLL_PLATFORMS.XERO}
        />
      )}
      {showQuickbooksImage && (
        <ImageDisplayModal
          isOpen={showQuickbooksImage}
          setOpen={setQuickbooksImage}
          type={PAYROLL_PLATFORMS.QUICKBOOKS}
        />
      )}
    </>
  );
};

export default WorkerPayrollSection;
