import ACTIONS, { FETCH_STATUS } from "../constants";

export const emptyNewData = () => ({
  type: ACTIONS.REMOVE_SETTING_DATA,
});

export const handleDynamicBreadCrumbForHRMRequest = (payload) => ({
  type: ACTIONS.DYNAMIC_BREADCRUMB_FOR_HRM_CALL,
  payload,
});

export const getFolderListForHRMAction = {
  pending: {
    type: ACTIONS.GET_FOLDER_LIST_FOR_HRM.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_FOLDER_LIST_FOR_HRM.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_FOLDER_LIST_FOR_HRM.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getFolderListForHRMRequest = (payload) => ({
  type: ACTIONS.GET_FOLDER_LIST_FOR_HRM_CALL,
  payload,
});

export const hrmFormCategoriesList = {
  pending: {
    type: ACTIONS.HRM_FORM_CATEGORIES_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.HRM_FORM_CATEGORIES_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.HRM_FORM_CATEGORIES_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const hrmFormCategoriesListRequest = () => ({
  type: ACTIONS.HRM_FORM_CATEGORIES_LIST_CALL,
});

export const hrmSubmittedFormsListByCategory = {
  pending: {
    type: ACTIONS.HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getHrmSubmittedFormsListByCategoryRequest = (payload) => ({
  type: ACTIONS.HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY_CALL,
  payload,
});
