import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import {
  baseURLImage,
  formatDateIntoDMYHMS,
  getNameProfile,
} from "Utils/utils";

const TimesheetHistory = ({ selectedTimeSheetHistory, scrollHeight = 831 }) => {
  const [showData, setShowData] = useState(false);
  const [historyList, setHisoryList] = useState([...selectedTimeSheetHistory]);

  useEffect(() => {
    setHisoryList([...selectedTimeSheetHistory]);
  }, [selectedTimeSheetHistory]);

  useEffect(() => {
    let data = [];
    if (!showData) {
      data = historyList.sort((a, b) => a.field_name > b.field_name);
    } else {
      data = historyList.sort((a, b) => a.field_name < b.field_name);
    }
    setHisoryList([...data]);
  }, [showData]);

  const handleSort = () => {
    setShowData(!showData);
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <div
      style={{ height: `${scrollHeight}px` }}
      className={`border flex flex-col y-overflow rounded-md border-gray-300`}
    >
      <div className='min-w-full'>
        <div className='block'>
          <table className='min-w-full bg-white'>
            <thead>
              <tr>
                <th className='py-3.5 px-3 text-left text-sm font-semibold text-[#000] bg-[#EEEFF1] '>
                  Name
                  <span onClick={handleSort}>
                    {showData ? (
                      <AiOutlineArrowUp className='inline-block ml-1 w-4 h-4 cursor-pointer' />
                    ) : (
                      <AiOutlineArrowDown className='inline-block ml-1 w-4 h-4 cursor-pointer' />
                    )}
                  </span>
                </th>
                <th className='py-3.5 px-3 text-left text-sm font-semibold text-[#000] bg-[#EEEFF1]  '>
                  Field
                </th>
                <th className='py-3.5 px-3 text-left text-sm font-semibold text-[#000] bg-[#EEEFF1] '>
                  Old value
                </th>
                <th className='py-3.5 px-3 text-left text-sm font-semibold text-[#000] bg-[#EEEFF1] '>
                  New Value
                </th>
                <th className='py-3.5 px-3 text-left text-sm font-semibold text-[#000] bg-[#EEEFF1]  '>
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {historyList.length > 0 ? (
                historyList.map((item, index) => (
                  <tr
                    key={`${item.id}-${index}`}
                    className='even:bg-white odd:bg-gray-50'
                  >
                    <td className='whitespace-nowrap py-4 pl-4 text-sm'>
                      <div className='flex items-center'>
                        <div className='h-[24px] w-[24px] flex-shrink-0'>
                          <img
                            onError={(e) =>
                              handleImageError(
                                e,
                                item?.updated_by_user?.name,
                                " "
                              )
                            }
                            className='capitalize object-cover h-[24px] w-[24px]  rounded-full'
                            src={
                              item?.updated_by_user?.profile_picture
                                ? baseURLImage(
                                    item?.updated_by_user?.profile_picture
                                  )
                                : getNameProfile(
                                    item?.updated_by_user?.name,
                                    " "
                                  )
                            }
                            alt='profile_photo'
                          />
                        </div>
                        <div className='ml-4 capitalize'>
                          <span className='capitalize font-medium text-gray-500 max-w-32 truncate'>
                            {item?.updated_by_user?.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 text-sm text-gray-500'>
                      {item.field_name && item.field_name.includes("_")
                        ? item.field_name.replace("_", " ")
                        : item?.field_name}
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 text-sm text-gray-500 truncate'>
                      {item.field_name === "start_time" ||
                      item.field_name === "end_time"
                        ? formatDateIntoDMYHMS(item.old_value)
                        : item.old_value ?? "-"}
                      {/* <br></br> */}
                      {/* {item.fine} */}
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 text-sm text-gray-500 truncate'>
                      {item.field_name === "start_time" ||
                      item.field_name === "end_time"
                        ? formatDateIntoDMYHMS(item.new_value)
                        : item.new_value}
                    </td>
                    <td className='whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 '>
                      {moment(item?.created_at).format(
                        "Do MMM YYYY, h:mm:ss A"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className='py-6 text-center text-red-600'>
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TimesheetHistory;
