import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import SelectOption from "components/common/Select/Select";
import PermissionTable from "../PermissionsTable";
import InputField from "components/common/Input/Input";
import { REGEX_ALPHABETS_ONLY, portalTypeOptions } from "Constants/constant";
import _ from "lodash";

const RoleAndPermissionModal = ({
  isOpen,
  setIsOpenModal,
  selectedPermissionUUID = null,
  roleAndPermisisonCreateOrUpdateRequest,
  roleAndPermissionsCreateOrUpdateLoader,
  defaultRoleAndPermissions,
  defaultRoleAndPerLoader,
  defaultRolesAndPermissionsRequest,
  resetDefaultRolesPermissionLocally,
  selectedPortalFilter,
  isUserGracePeriodOver,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    watch,
  } = useForm();

  const watchFormData = watch();

  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const [createLoader, setCreateLoader] = useState(false);

  useEffect(() => {
    setRolesPermissions(
      defaultRoleAndPermissions?.permissions
        ? [
            ...defaultRoleAndPermissions.permissions.map((x) => {
              const obj = x;
              const child = obj?.children || [];
              return {
                ...obj,
                isChecked: x.status,
                children: child.map((y) => ({ ...y, isChecked: y.status })),
              };
            }),
          ]
        : []
    );

    if (defaultRoleAndPermissions?.role && selectedPermissionUUID) {
      setValue("portal_type", defaultRoleAndPermissions.role.type);
      setValue("role_title", defaultRoleAndPermissions.role.name);
    }
  }, [defaultRoleAndPermissions, selectedPermissionUUID]);

  useEffect(() => {
    if (selectedPermissionUUID !== null) {
      defaultRolesAndPermissionsRequest({ id: selectedPermissionUUID });
    }
    if (
      selectedPermissionUUID === null &&
      selectedPortalFilter &&
      selectedPortalFilter?.value
    ) {
      setValue("portal_type", selectedPortalFilter.value);
    }

    return () => resetDefaultRolesPermissionLocally();
  }, []);

  const modalFooter = () => (
    <CustomButton
      type='submit'
      label={selectedPermissionUUID ? "Update" : "Create"}
      showLoading={roleAndPermissionsCreateOrUpdateLoader}
      isDisabled={
        roleAndPermissionsCreateOrUpdateLoader ||
        defaultRoleAndPerLoader ||
        isUserGracePeriodOver
      }
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("addRole");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  const closeModal = () => {
    setIsOpenModal(false);
    reset();
  };

  useEffect(() => {
    if (createLoader && !roleAndPermissionsCreateOrUpdateLoader) {
      closeModal();
      setCreateLoader(false);
    }
  }, [roleAndPermissionsCreateOrUpdateLoader]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    let permissions = [];
    rolesPermissions.forEach((x) => {
      if (x.isChecked) permissions.push(x.id);
      x?.children.forEach((y) => y.isChecked && permissions.push(y.id));
    });
    const payload = {
      permissions,
      name: data.role_title,
      type: data.portal_type,
    };
    if (selectedPermissionUUID !== null) {
      Object.assign(payload, {
        id: selectedPermissionUUID,
      });
    }
    setCreateLoader(true);
    roleAndPermisisonCreateOrUpdateRequest(payload);
  };

  useEffect(() => {
    if (watchFormData?.portal_type && selectedPermissionUUID === null) {
      const payload = { type: watchFormData?.portal_type };
      defaultRolesAndPermissionsRequest(payload);
    }
  }, [watchFormData?.portal_type]);

  const handleRowClick = (id) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(id)) {
      const indexToRemove = newExpandedRows.indexOf(id);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
      newExpandedRows.push(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleSwitchChange = (parentIndex, value, childIndex = null) => {
    const obj = _.cloneDeep(
      rolesPermissions
        // .filter(
        //   (x) =>
        //     watchFormData?.portal_type === portalTypeOptions[0].value ||
        //     (watchFormData?.portal_type === portalTypeOptions[1].value &&
        //       x.name !== "cos" &&
        //       x.parent_id === null)
        // )
        .filter(
          (x) => x.name !== "participant.dashboard" && x.parent_id === null
        )
    );
    if (rolesPermissions) {
      if (childIndex === null) {
        Object.assign(obj[parentIndex], {
          isChecked: value,
        });
        // obj[parentIndex].splice(parentIndex, 1, {
        //   obj[parentIndex],
        //   isChecked: value,
        //   // children: rolesPermissions[parentIndex].children.map((x) => ({
        //   //   ...x,
        //   //   isChecked: value,
        //   // })),
        // });
      } else {
        obj[parentIndex].children.splice(childIndex, 1, {
          ...obj[parentIndex].children[childIndex],
          isChecked: value,
        });
        const isSome = obj[parentIndex].children.some(
          (x) => x.isChecked === true
        );
        if (isSome) {
          Object.assign(obj[parentIndex], {
            isChecked: true,
          });
        }
      }
    }

    setRolesPermissions([...obj]);
  };

  return (
    <ModalSkeleton
      isOpen={isOpen}
      cssClass='w-[60%]'
      title={`${selectedPermissionUUID ? "Edit" : "Add"} Roles & Permissions`}
      closeModal={closeModal}
      modalFooter={modalFooter()}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!defaultRoleAndPerLoader && (
            <div className='grid grid-cols-2 gap-x-4'>
              <div className='col-span-2 sm:col-span-1'>
                <label className='block text-sm font-medium text-gray-700'>
                  Portal Type
                </label>
                <SelectOption
                  name='portal_type'
                  isRequired={true}
                  register={register}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  data={portalTypeOptions}
                  placeHolder='Select Portal'
                  selectedOption={portalTypeOptions.find(
                    (p) => p.value === watchFormData?.portal_type
                  )}
                  isSearch={false}
                  cssClass='z-20 mt-1'
                  errorMessage={
                    errors?.portal_type?.type === "required"
                      ? "This field is required!"
                      : null
                  }
                  isDisabled={defaultRoleAndPerLoader || selectedPermissionUUID}
                />
              </div>
              <div className='col-span-2 sm:col-span-1'>
                <InputField
                  pattern={REGEX_ALPHABETS_ONLY}
                  setValue={setValue}
                  register={register}
                  label={"Role Title"}
                  type='text'
                  name='role_title'
                  isRequired={true}
                  ErrorMessage={
                    errors?.role_title?.type === "required"
                      ? "This field is required!"
                      : errors?.role_title?.type === "pattern"
                      ? "Only alphabets and numbers allow!"
                      : null
                  }
                  value={watchFormData?.role_title}
                  isDisabled={defaultRoleAndPerLoader}
                />
              </div>
            </div>
          )}
          <div className='mt-4'>
            <PermissionTable
              isLoading={defaultRoleAndPerLoader}
              rolesPermissions={rolesPermissions}
              handleRowClick={handleRowClick}
              expandedRows={expandedRows}
              handleSwitchChange={handleSwitchChange}
              portalType={watchFormData?.portal_type ?? null}
            />
          </div>
          <button type='submit' className='hidden sr-only' id='addRole' />
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default RoleAndPermissionModal;
