import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "components/common/Button/CustomButton";
import ServiceSupportModal from "./ServiceSupportModal";
import ServiceSupportTable from "./ServiceSupportTable";

const ServiceSupport = (props) => {
  const {
    selectedFunding,
    participant,
    getPlanBudgetCatAndSubCat,
    externalSps,
    getCatalogServicesSubCategory,
    catalogStates,
    serviceSupportCreate,
    createServiceSupportLoader,
    createServiceSupportError,
    getServiceSupportList,
    serviceSupportBudgets,
    fetchServiceSupportLoader,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [serviceSupportItem, setServiceSupportItem] = useState(null);

  useEffect(() => {
    if (selectedFunding && participant && externalSps.length === 0) {
      getPlanBudgetCatAndSubCat({
        funding_reference_id: selectedFunding.contract_id,
        pt_id: participant.uuid,
      });
    }
    if (!catalogStates) {
      getCatalogServicesSubCategory({ isService: false });
    }
  }, []);

  return (
    <div className="max-w-7xl mx-auto py-3 bg-white">
      <div>
        <div>
          <div className="flex justify-between items-center px-6 bg-gray-25 border md:rounded-t-lg font-bold text-lg text-gray-900 py-4">
          <div>Service Support</div>
            <CustomButton
              label="Service Support Budget"
              clickHandler={() => setOpenModal(true)}
              Icon={<BiPlus />}
              cssClass="border-0"
            />
          </div>

        </div>
        <div>
          <ServiceSupportTable
            getServiceSupportList={getServiceSupportList}
            funding={selectedFunding}
            participant={participant}
            serviceSupportBudgets={serviceSupportBudgets}
            // planBudgets={planBudgets}
            fetchLoader={fetchServiceSupportLoader}
            // removeLoader={removePlanBudgetLoader}
            // removePlanBudget={removePlanBudget}
            setServiceSupportItem={setServiceSupportItem}
          />
        </div>
      </div>

      {(openModal || serviceSupportItem !== null) && (
        <ServiceSupportModal
          openModal={openModal || serviceSupportItem !== null}
          closeModal={() => {
            setOpenModal(false);
            setServiceSupportItem(null);
          }}
          catalogStates={catalogStates}
          externalSps={externalSps}
          //   PlanCatagories={PlanCatagoriesList}
          funding={selectedFunding}
          serviceSupportCreate={serviceSupportCreate}
          createServiceSupportLoader={createServiceSupportLoader}
          createServiceSupportError={createServiceSupportError}
          participant={participant}
          serviceSupporitemEdit={serviceSupportItem}
        />
      )}
    </div>
  );
};

export default ServiceSupport;
