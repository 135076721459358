import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";
import Search from "components/common/Search/Search";
import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  REPORTING_LIMIT,
  SORT_TYPE,
  StatusOptions,
  StatusOptionsReporting,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_PARTICIPANT_EXPIRING_DOCUMENT_REPORTS } from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import { toast } from "react-toastify";
import { addMonths, exportTOExcel, getUrlRole } from "Utils/utils";

const ParticipantExpiringDocuments = ({
  getParticipantList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
  }, []);

  const [participantList, setParticipant] = useState([
    { label: "All", value: "all" },
  ]);

  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState(null);
  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    const updateLists = async () => {
      const participants =
        spParticipants?.participant?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedParticipants = participants.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setParticipant(sortedParticipants);

      setParticipant((prevParticipantList) => [
        { label: "All", value: "all" },
        ...prevParticipantList,
      ]);
    };

    updateLists();
  }, [spParticipants]);

  useEffect(() => {
    getDocCategory();
  }, []);

  const [isDocTypeLoader, setIsDocTypeLoader] = useState(false);
  const [fileTypeOptions, setFileTypeOptions] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [isDisableSearch,setIsDisableSearch]=useState(false)
  const getDocCategory = async () => {
    try {
      setIsDocTypeLoader(true);
      const res = await apiGetRequest(`${getUrlRole()}/doc-types`);
      if (res.status === 200) {
        const options = res.data.doc_types.map((doc) => {
          const obj = {
            label: doc.name,
            value: doc.id,
          };
          return obj;
        });
        const sortedData = options?.sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        );
        await setFileTypeOptions(sortedData);
        setFileTypeOptions((prev) => [{ label: "All", value: "all" }, ...prev]);
        // setFileTypeOptions([...options]);
      }
      setIsDocTypeLoader(false);
    } catch (err) {
      console.log("err", err);
      setIsDocTypeLoader(false);
    }
  };

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    fetchListReports();
  }, [page]);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    if (!fromDate && !toDate) {
      toast("Select create date range to get data");
      return;
    }

    setLoader(true);
    let url = `${URL_REPORTING_PARTICIPANT_EXPIRING_DOCUMENT_REPORTS}?per_page=25&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_PARTICIPANT_EXPIRING_DOCUMENT_REPORTS}?per_page=25&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",

      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      status: isClearAll ? "all" : status ? status.value : "all",
      category: isClearAll
        ? "all"
        : selectedFileType
        ? selectedFileType?.value
        : "all",
      expire_date: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
    };
    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            if (report === "xlsx") {
              const data = res?.data?.data;
              exportTOExcelFile(data);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
            // window.open(`${DOC_DOWNLOAD_URL}${res?.data?.data}`, "_blank");
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.generalDocuments?.total,
              totalPages: Math.ceil(
                res?.data?.data?.generalDocuments?.total /
                  res?.data?.data?.generalDocuments?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        toast.error(`${error}`);
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);

    // if (e === "xlsx") {
    //   exportTOExcelFile();
    // } else {
    //   fetchListReports(false, e);
    // }
  };

  const exportTOExcelFile = (reportData) => {
    const transformedData =
      reportData &&
      reportData.generalDocuments &&
      reportData?.generalDocuments?.map((item) => {
        return {
          Participant: `${item.participant.first_name} ${item.participant.last_name}`,
          Document: item.name,
          Expiry_Date:
            item.expire_date && moment(item.expire_date).format("DD/MM/YYYY"),
          Contact_Number: item.participant.primary_no,
          Email: item.participant.email,
          Participant_Status: item.participant.status ? "Active" : "InActive",
          Document_Category: item.document_type?.name,
        };
      });
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='Participant Expiring Document Reports' />
          <div className='min-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.PARTICIPANT_EXPIRING_DOCUMENTS_REPORTS
                )}
              />
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by document name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex pt-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Participant's Status
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={StatusOptionsReporting || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm  font-medium text-gray-700 text-start'>
                          Select Date Range(Max 6 months){" "}
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                            if (obj) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 6)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Category
                        </label>
                        <SelectOption
                          name='category'
                          isDisabled={isDocTypeLoader}
                          isSearch={true}
                          data={fileTypeOptions || []}
                          selectedOption={selectedFileType}
                          handleChange={(e) => {
                            setSelectedFileType(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() => fetchListReports(false)}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setStatus(null);
                            setSelectedParticipant(null);
                            setSelectedFileType(null);
                            setSearch(null);
                            setReports([]);
                            setFromDate(null)
                            setToDate(null)
                            setSelectedFromDate(null)
                            // fetchListReports(true);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='min-w-full  flex justify-center overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader isSort={false} label={"Participant"} />

                      <TableHeader
                        sortName='name'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Document"}
                      />
                      <TableHeader
                        sortName='document_type_id'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Category"}
                      />

                      <TableHeader
                        sortName='expiry_time'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Expiry Date"}
                      />

                      <TableHeader isSort={false} label={"Contact Number"} />

                      <TableHeader isSort={false} label={"Email"} />

                      <TableHeader isSort={false} label={"Status"} />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={7} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.generalDocuments?.data?.length > 0 ? (
                      reportList?.generalDocuments?.data?.map(
                        (document, index) => (
                          <tr
                            key={index}
                            className='even:bg-white odd:bg-gray-50'
                          >
                            <td className='whitespace-nowrap py-4 text-sm'>
                              <div className='flex items-center'>
                                <div className='capitalize'>
                                  <span
                                    title={`${document.participant.first_name} ${document.participant.last_name}`}
                                  >
                                    <p
                                      className={`w-40 truncate cursor-pointer pl-1`}
                                    >{`${document.participant.first_name} ${document.participant.last_name}`}</p>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                              {document?.name || "-"}
                            </td>
                            <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                              {document?.document_type?.name || "-"}
                            </td>
                            <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                              {document?.expire_date
                                ? moment(document.expire_date).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {document?.participant?.primary_no || "-"}
                            </td>
                            <td className='w-40 truncate py-4  px-3 text-sm'>
                              {document?.participant?.email || "-"}
                            </td>

                            <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                              <span
                                className={`${
                                  document?.participant?.status
                                    ? "bg-green-100 text-green-700"
                                    : "bg-red-100 text-red-700"
                                } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
                              >
                                {document?.participant?.status || "-"}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td
                          colSpan={7}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {reportList && reportList?.generalDocuments?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.generalDocuments?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ParticipantExpiringDocuments;
