import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  CLAIM_TYPE_OPTIONS,
  DELIVERED_SERVICE_STATUS_OPTIONS,
  DUMMY_ADD_SERVICE_PROVIDER_VALUE,
  CASE_NOTES_TIME_VALUES,
  REGEX_HHH_MM,
} from "Constants/constant";
import { caseNotesCreateRequest } from "store/actions/cos.actions";
import api from "store/services/cos.services";
import { LOADER_STATUS } from "store/constants";
import SelectOption from "components/common/Select/Select";
import FileInput from "components/common/FileUpload/FileInput";
import CheckBox from "components/common/checkBox/checkbox";
import Loader from "components/common/Loader/Loader";
import { gethoursAndMins } from "Utils/utils";
import FileUploadInput from "components/common/FileUpload/FileUploadInput";
import { LoginContext } from "helpers/LoginContext";

const CreateCaseNotesModal = (props) => {
  const {
    ContactList,
    getContactListRequest,
    caseNotesCreateLoading,
    SupportItems,
    // isOpen,
    // closeModal,
    selectedFunding,
    cosParticipantsProfile,
    selectedServiceSupport,
    setSelectedServiceSupport,
    singleSupportTrackLoading,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [cosParticipantState, setCosParticipantState] = useState(null);
  const [selectedExternalSp, setSelectedExternalSp] = useState(null);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState({
    name: "",
    id: "",
  });
  const [file, setFile] = useState();
  const [remAmount, setRemAmount] = useState(0.0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [serviceSupportId, setServiceId] = useState(null);
  const [serviceTimeEntered, setServiceTimeEntered] = useState();
  const [contractType, setContractType] = useState(null);
  const [isBillabel, setIsBillabel] = useState(true);
  const [externalSpFetchRequest, setExternalSpFetchRequest] = useState(false);
  const [externalSpOptions, setExternalSpOptions] = useState([]);
  const [pricePerMinute, setPricePerMinute] = useState(0);
  const [claimType, setClaimType] = useState();
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [isCreateCaseNoteLoader, setIsCreateCaseNoteLoader] = useState(
    LOADER_STATUS.NA
  );

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();

  const watchFormData = watch();

  const currentSelectedSupportItem = useMemo(() => {
    if (selectedServiceSupport && SupportItems && SupportItems.length > 0) {
      const item = SupportItems.find(
        (x) => x.uuid === selectedServiceSupport.uuid
      );
      return item ? item : selectedServiceSupport;
    }
  }, [SupportItems, selectedServiceSupport]);

  useEffect(() => {
    handleCancelCaseNote();
  }, [selectedServiceSupport]);

  const handleCancelCaseNote = () => {
    if (currentSelectedSupportItem) {
      reset({
        service_support_id: currentSelectedSupportItem?.id,
        totalAmount: "$0",
        delivered_date: null,
        note: null,
        subject: null,
        Hours: null,
      });
      handleChangeSupportItems({
        ...currentSelectedSupportItem,
        value: currentSelectedSupportItem?.id,
        label:
          currentSelectedSupportItem?.price_catalog?.support_item_number.concat(
            " ",
            currentSelectedSupportItem?.price_catalog?.support_item_name
          ),
      });
    }

    setClaimType(null);
    setContractType(null);
    setIsBillabel(true);
    setFile("");
    // setHours("");
  };

  useEffect(() => {
    if (remainingAmount >= 0) {
      clearErrors("remainingamount");
    }

    setValue(
      "remainingamount",
      remainingAmount > 0 ? parseFloat(remainingAmount).toFixed(2) : 0
    );
  }, [remainingAmount]);

  useEffect(() => {
    getContactListRequest({ status: "active" });
    getEXternalSpSearchList();
  }, []);

  useEffect(() => {
    if (
      !caseNotesCreateLoading &&
      currentSelectedSupportItem &&
      isCreateCaseNoteLoader !== LOADER_STATUS.PENDING
    ) {
      handleChangeSupportItems({
        ...currentSelectedSupportItem,
        value: currentSelectedSupportItem?.id,
        label:
          currentSelectedSupportItem?.price_catalog?.support_item_number.concat(
            " ",
            currentSelectedSupportItem?.price_catalog?.support_item_name
          ),
      });
    }
  }, [currentSelectedSupportItem]);

  useEffect(() => {
    if (
      !caseNotesCreateLoading &&
      isCreateCaseNoteLoader === LOADER_STATUS.PENDING &&
      isCreateCaseNoteLoader !== LOADER_STATUS.NA
    ) {
      setIsCreateCaseNoteLoader(LOADER_STATUS.COMPLETE);
    }
  }, [caseNotesCreateLoading]);

  useEffect(() => {
    if (
      !caseNotesCreateLoading &&
      isCreateCaseNoteLoader === LOADER_STATUS.COMPLETE
    ) {
      setIsCreateCaseNoteLoader(LOADER_STATUS.NA);
      handleCancelCaseNote();
    }
  }, [isCreateCaseNoteLoader, caseNotesCreateLoading]);

  useEffect(() => {
    if (contractType) {
      setValue("contract_type", contractType);
      clearErrors("contract_type");
    }
    if (isBillabel) {
      setValue("bill_types", isBillabel);
      clearErrors("bill_types");
    }
  }, [contractType, isBillabel]);

  useEffect(() => {
    if (claimType) {
      setValue("claim_type", claimType);
      clearErrors("claim_type");
    }
  }, [claimType]);

  const onSubmit = (data, event) => {
    if (isUserGracePeriodOver) return;
    if (event.key === "Enter") {
      event.preventDefault();
      return;
    }
    const formData = new FormData();

    if (selectedExternalSp) {
      formData.append("external_sp_id", selectedServiceProvider.value);
    }
    if (cosParticipantState) {
      formData.append("state_id", cosParticipantState.id);
    }

    if (claimType) {
      formData.append("claim_type", claimType.label);
    }
    if (data.delivered_date) {
      formData.append("delivered_date", data.delivered_date);
    }
    if (data.delivered_from) {
      formData.append("delivered_from", data.delivered_from);
    }
    if (data.delivered_to) formData.append("delivered_to", data?.delivered_to);
    formData.append("delivered_quantity", serviceTimeEntered || "00:00");
    formData.append("funding_id", selectedFunding.uuid);
    formData.append("subject", data?.subject);
    formData.append("notes", data?.note);
    // formData.append("provider_invoice_date", data?.provider_invoice_date);
    // formData.append("provider_invoice_number", data?.provider_invoice_number);
    formData.append("pt_id", cosParticipantsProfile.id);
    formData.append("cos_contact_type_id", contractType?.id || "");
    formData.append("service_support_id", serviceSupportId);
    formData.append("status", DELIVERED_SERVICE_STATUS_OPTIONS[9].value);
    formData.append("total_amount", calculatedPrice.toFixed(2) || 0);
    formData.append(
      "bill_types",
      isBillabel ? "billable_hours" : "non_billable_hour"
    );
    if (file) {
      formData.append("attachment", file);
    }
    dispatch(caseNotesCreateRequest({ formData }));
    setIsCreateCaseNoteLoader(LOADER_STATUS.PENDING);
  };

  const getEXternalSpSearchList = async () => {
    try {
      setExternalSpFetchRequest(true);
      const response = await api.getServiceProviderSearchList({ name: "" });
      if (response && response.external_sps) {
        setExternalSpFetchRequest(false);
        const data = response.external_sps.map((x) => {
          const obj = { ...x, label: x.name, value: x.id };
          return { ...obj };
        });
        data.unshift({
          value: "add_service_provider",
          label: "Add New Service Provider",
        });
        setExternalSpOptions([...data]);
        return [...data];
      }
      setExternalSpFetchRequest(false);

      setExternalSpOptions([]);
    } catch (err) {
      console.log("err", err);
      setExternalSpFetchRequest(false);
    }
  };

  const handleChangeSupportItems = (e) => {
    // alert("here");
    // console.log("e", e);
    setValue("totalAmount", "$0");
    setCalculatedPrice(0);
    if (e.external_service_provider) {
      setSelectedExternalSp({
        label: e.external_service_provider.name,
        value: e.external_service_provider.id,
      });
    } else {
      setSelectedExternalSp(null);
    }
    setCosParticipantState(e.state);
    setRemAmount(e.remain_amount);
    setRemainingAmount(
      parseFloat(e.amount_allocated || 0) ===
        parseFloat(e.remain_amount || 0) &&
        parseFloat(e.delivered_amount || 0) === 0
        ? parseFloat(e.total_budget_amount)
        : parseFloat(e.remain_amount || 0)
    );
    setServiceId(e.id);
    if (e.value) {
      setServiceId(e.id);
      if (e.alternative_rate && parseFloat(e.alternative_rate) > 0) {
        setPricePerMinute(parseFloat(e?.alternative_rate) / 60);
      } else {
        setPricePerMinute(parseFloat(e?.original_rate) / 60);
      }
      if (e?.external_service_provider) {
        setSelectedServiceProvider({
          label: e?.external_service_provider?.name,
          value: e?.external_service_provider?.id,
        });
      } else {
        setSelectedServiceProvider({
          label: "",
          value: "",
        });
      }
      setValue("service_support_id", e);
      clearErrors("service_support_id");
    }
  };

  const handleHoursInputOnChange = (inputValue) => {
    if (inputValue && REGEX_HHH_MM.test(inputValue)) {
      // setHours(inputValue);
      clearErrors("Hours");
      const valueEntered = inputValue;
      setServiceTimeEntered(valueEntered);
      const hourToMinutes = parseInt(valueEntered.split(":")[0] * 60);
      let min =
        parseInt(valueEntered.split(":")[1]) > 0
          ? parseInt(valueEntered.split(":")[1])
          : 0;
      const minutes = parseInt(hourToMinutes) + parseInt(min);
      setValue(
        "totalAmount",
        `$${parseFloat(pricePerMinute * minutes).toFixed(2)}`
      );
      if (pricePerMinute * minutes > 0) {
        if (
          inputValue.toString() ===
          gethoursAndMins(
            currentSelectedSupportItem.remain_total_hours,
            currentSelectedSupportItem.remain_total_mint
          )
        ) {
          setCalculatedPrice(remAmount);
          setRemainingAmount(0.0);
          setValue("totalAmount", `$${remAmount}`);
        } else {
          setCalculatedPrice(pricePerMinute * minutes);
          const ttlRem =
            parseFloat(remAmount) - parseFloat(pricePerMinute * minutes);
          setRemainingAmount(ttlRem > 0.0 ? ttlRem : 0.0);
        }
      } else {
        setRemainingAmount(remAmount);
      }
    } else {
      setValue("totalAmount", "$0");
      setRemainingAmount(remAmount);
    }
  };

  const validateTime = useMemo(() => {
    if (watchFormData?.Hours && currentSelectedSupportItem) {
      const typedHrMin = watchFormData?.Hours.toString().split(":");
      const srHrMin = gethoursAndMins(
        currentSelectedSupportItem.remain_total_hours,
        currentSelectedSupportItem.remain_total_mint
      ).split(":");
      if (
        parseInt(typedHrMin[0]) === parseInt(srHrMin[0]) &&
        parseInt(typedHrMin[1]) === parseInt(srHrMin[1])
      ) {
        clearErrors("Hours");
        return true;
      } else if (parseInt(typedHrMin[0]) > parseInt(srHrMin[0])) {
        return false;
      } else if (
        parseInt(typedHrMin[0]) === parseInt(srHrMin[0]) &&
        parseInt(typedHrMin[1]) > parseInt(srHrMin[1])
      ) {
        return false;
      }
    }
    clearErrors("Hours");
    return true;
  }, [watchFormData?.Hours]);

  useEffect(() => {
    if (watchFormData?.Hours && validateTime) {
      handleHoursInputOnChange(watchFormData?.Hours);
    }
  }, [watchFormData?.Hours]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-3 gap-4 px-3'>
          <div className='col-span-1'>
            <div className='hidden'>
              <select
                {...register("service_support_id", {
                  required: true,
                })}
                name='service_support_id'
                id='service_support_id'
                // htmlFor="service_support_id"
                className='hidden did-floating-input'
              />
            </div>
            <label className='did-floating-label text-start mt-1'>
              Support Items
            </label>
            <SelectOption
              selectedOption={
                currentSelectedSupportItem
                  ? {
                      ...currentSelectedSupportItem,
                      value: currentSelectedSupportItem?.id,
                      label:
                        currentSelectedSupportItem?.price_catalog?.support_item_number.concat(
                          " ",
                          currentSelectedSupportItem?.price_catalog
                            ?.support_item_name
                        ),
                    }
                  : null
              }
              name='service_support_id'
              isSearch={false}
              data={SupportItems}
              handleChange={(e) => {
                setSelectedServiceSupport({ ...e, value: e.uuid });
                handleCancelCaseNote();
              }}
              placeHolder={singleSupportTrackLoading ? "Loading..." : ""}
              loading={singleSupportTrackLoading}
            />
            {errors?.service_support_id?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required
              </span>
            )}
          </div>
          <div className='col-span-2'>
            <div className='grid grid-cols-3 gap-4 px-3'>
              <div className='col-span-1'>
                <div className='hidden'>
                  <select
                    {...register("claim_type", {
                      required: true,
                    })}
                    name='claim_type'
                    id='claim_type'
                    className='hidden did-floating-input'
                  />
                </div>
                <div className='text-start'>
                  <label htmlFor='claim_type' className='did-floating-label '>
                    Claim Type
                  </label>
                </div>
                <SelectOption
                  isSearch={false}
                  data={CLAIM_TYPE_OPTIONS}
                  selectedOption={claimType}
                  handleChange={setClaimType}
                  // placeHolder="Select Claim Type..."
                />
                {errors?.claim_type?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required
                  </span>
                )}
              </div>
              <div className='col-span-1'>
                <label htmlFor='' className=' text-start did-floating-label'>
                  Delivered Date
                </label>
                <input
                  {...register("delivered_date", {
                    required: true,
                  })}
                  name='delivered_date'
                  id='delivered_date'
                  type='date'
                  min={moment().format("DD-MM-YYYY")}
                  className='did-floating-input'
                />
                {errors?.delivered_date?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
              <div className='col-span-1'>
                <div className=''>
                  <div className='hidden'>
                    <input
                      {...register("serviceProvider")}
                      name='price_catalog_id'
                    />
                  </div>
                  <label className='text-start did-floating-label'>
                    Service Provider
                  </label>
                  <SelectOption
                    isClearable
                    isSearch={true}
                    data={
                      externalSpOptions && externalSpOptions.length > 0
                        ? externalSpOptions
                        : []
                    }
                    selectedOption={selectedExternalSp}
                    handleChange={(e) => {
                      if (e.value === DUMMY_ADD_SERVICE_PROVIDER_VALUE) {
                        navigate("/cos/external-service-provider");
                      } else {
                        setSelectedExternalSp(e);
                      }
                    }}
                    // placeHolder={
                    //   externalSpFetchRequest ? "Loading..." : "Select External SP"
                    // }
                    loading={externalSpFetchRequest}
                    isDisabled={
                      !selectedExternalSp ||
                      (selectedExternalSp ? true : externalSpFetchRequest)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='hr width-full col-span-3 border-b border-b-gray-200 mt-4 mb-3'></div>
        <div className='grid grid-cols-3 gap-4 px-3'>
          <div className='col-span-1'>
            <div className='hidden'>
              <select
                {...register("contract_type", {
                  required: true,
                })}
                name='contract_type'
                id=''
                className='hidden did-floating-input'
              />
            </div>
            <label htmlFor='contract_type' className='did-floating-label '>
              Contact Type
            </label>
            <SelectOption
              isSearch={false}
              name='contract_type'
              data={ContactList}
              selectedOption={contractType}
              handleChange={setContractType}
              // placeHolder="Contract Type"
            />
            {errors?.contract_type?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
          </div>
          <div className='col-span-1'>
            <label htmlFor='' className=' text-start did-floating-label'>
              Subject
            </label>
            <input
              {...register("subject", {
                required: true,
                maxLength: 254,
              })}
              // value={participantStateId.name}
              name='subject'
              id='subject'
              type='text'
              // placeholder="Subject..."
              className='did-floating-input'
            />
            {errors?.subject?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
          </div>
          <div className='col-span-1'>
            <div className='hidden'>
              <input
                {...register("bill_types", {
                  required: true,
                })}
                name='bill_types'
                id=''
                className='hidden did-floating-input'
              />
            </div>
            <label htmlFor='' className='did-floating-label '>
              Is Billable
            </label>
            <div className='flex gap-4 justify-start'>
              <div className='flex gap-x-2 items-center'>
                <CheckBox
                  name='bill_types'
                  label='Billable'
                  checked={isBillabel}
                  handleChange={() => setIsBillabel(!isBillabel)}
                />
              </div>
              <div className='flex gap-x-2 items-center'>
                <CheckBox
                  name='bill_types'
                  label='Non-Billable'
                  checked={!isBillabel}
                  handleChange={() => setIsBillabel(!isBillabel)}
                />
              </div>
              {errors?.bill_types?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='hr width-full col-span-3 border-b border-b-gray-200 mt-4 mb-3'></div>
        <div className='grid grid-cols-3 gap-4 px-3'>
          <div className='col-span-2'>
            <div className='grid grid-cols-3 gap-4'>
              <div className='col-span-1'>
                <label className='text-gray-700 text-sm font-medium mb-1'>
                  Hours
                </label>
                <SelectOption
                  cssClass='mt-[1%]'
                  validate={validateTime}
                  isRequired={true}
                  register={register}
                  setValue={setValue}
                  name='Hours'
                  isSearch={false}
                  data={CASE_NOTES_TIME_VALUES}
                  selectedOption={
                    watchFormData?.Hours
                      ? CASE_NOTES_TIME_VALUES.find(
                          (x) => x.value === watchFormData?.Hours
                        )
                      : null
                  }
                  errorMessage={
                    errors?.Hours?.type === "required" ? (
                      <span className='text-sm text-red-500'>
                        This field is required!
                      </span>
                    ) : errors?.Hours?.type === "validate" ? (
                      <span className='text-sm text-red-500'>
                        Hours should be Equal or less than Time Left
                      </span>
                    ) : null
                  }
                />
              </div>
              <div className='col-span-1'>
                <label htmlFor='' className=' text-start did-floating-label'>
                  Total Amount
                </label>
                <input
                  {...register("totalAmount", {
                    required: true,
                    disabled: true,
                    // value: calculatedPrice
                    //   ? `$${parseFloat(calculatedPrice).toFixed(2)}`
                    //   : "$0",
                  })}
                  // defaultValue={parseFloat(calculatedPrice).toFixed(2)}
                  // placeholder="$0.00"
                  name='totalAmount'
                  type='text'
                  className='did-floating-input'
                  style={{
                    backgroundColor: "#4b6bb710",
                  }}
                />
              </div>
              <div className='col-span-1'>
                <label htmlFor='' className='text-start did-floating-label'>
                  Remaining Amount
                </label>
                <input
                  {...register("remainingamount")}
                  value={
                    remainingAmount.toFixed(2)
                      ? `$${remainingAmount.toFixed(2)}`
                      : ""
                  }
                  // pattern="^\d*(\.\d{0,2})?$"
                  disabled
                  name='remainingamount'
                  type='text'
                  className='did-floating-input'
                  style={{
                    backgroundColor: "#4b6bb710",
                  }}
                />
              </div>
            </div>
          </div>
          <div className='col-span-1'>
            <label htmlFor='' className='did-floating-label text-start'>
              Attachment
            </label>
            <div>
              <FileUploadInput
                type='any'
                handleFile={(e) => setFile(e)}
                size='2'
                file={file}
                handleDelete={() => setFile("")}
                showDragAndDrop={false}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4 px-3 mt-4'>
          <div className='col-span-2'>
            <label htmlFor='' className='did-floating-label text-start'>
              Case Notes
            </label>
            <textarea
              {...register("note", {
                // required: true,
              })}
              rows='4'
              className='w-full border-gray-300 rounded oeverflow-y-auto resize-none'
            ></textarea>
            {errors?.note?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
          </div>
          <div className='col-span-1'>
            <label>&nbsp;</label>
            <div>
              <button
                type='submit'
                disabled={
                  caseNotesCreateLoading ||
                  singleSupportTrackLoading ||
                  isUserGracePeriodOver
                }
                className='bg-green-50 w-full h-[44px] text-green-700 text-base font-medium mb-4 rounded-lg	shadow-sm border border-green-50'
              >
                {caseNotesCreateLoading && (
                  <Loader cssClass='text-black' isSmallView />
                )}
                Create
              </button>
            </div>
            <div>
              <button
                type='button'
                onClick={handleCancelCaseNote}
                className='bg-gray-200 w-full h-[44px] text-gray-400 text-base font-medium rounded-lg	shadow-sm border-gray-200'
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default CreateCaseNotesModal;
