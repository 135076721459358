import React from "react";
import { classNames } from "Utils/utils";
import Loader from "../Loader/Loader";

const CustomButton = (props) => {
  const {
    variant = "primary",
    loaderCssClass,
    type,
    cssClass,
    clickHandler,
    label,
    Icon,
    showLoading,
    isDisabled,
  ...rest
  } = props;

  const getVariant = () => {
    switch (variant) {
      case "primary":
        return "background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2";
      case "secondary":
        return "border border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2";
      case "wsecondary":
        return "border border-gray-100 bg-white text-gray-500 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2";
      case "tertiary":
        return "";
      case "danger":
        return "text-red-800 bg-red-200 border-red-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2";
      case "btn-purple":
        return "my-2 bg-ndisPurple py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-50 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500";
      default:
        return "bg-gradient";
    }
  };
  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={clickHandler}
      className={classNames(
        `tracking-normal border-2 border-solid py-2 px-4 text-sm font-semibold rounded-lg inline-flex items-center capitalize gap-x-1`,
        cssClass && cssClass,
        isDisabled && "disabled:opacity-50",
        variant && `${getVariant()}`
      )}
      {...rest}
    >
      {showLoading && <Loader cssClass={loaderCssClass} isSmallView />}
      {Icon && !showLoading && (
        <span className="inline-flex text-base">{Icon}</span>
      )}
      {label}
    </button>
  );
};

export default CustomButton;
