import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

const DropdownItem = ({ item, selectedItems, onSelect, multiSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const isChecked = () => {
    if (multiSelect) {
      return (
        Array.isArray(selectedItems) &&
        selectedItems?.some((selected) => selected?.id === item?.id)
      );
    }
    return selectedItems?.id === item?.id;
  };

  const hasCheckedChild = (children) => {
    if (!children) return false;
    return children?.some((child) =>
      multiSelect
        ? (Array.isArray(selectedItems) &&
            selectedItems?.some((selected) => selected?.id === child?.id)) ||
          hasCheckedChild(child?.children)
        : selectedItems?.id === child?.id || hasCheckedChild(child?.children)
    );
  };

  useEffect(() => {
    if (hasCheckedChild(item?.children) || isChecked()) {
      setIsOpen(true);
    }
  }, [item, selectedItems, multiSelect]);

  return (
    <div className='relative'>
      <div
        onClick={toggleOpen}
        className='min-w-full text-left px-2 py-1.5 cursor-pointer hover:bg-gray-100 rounded-md flex items-center justify-between'
      >
        <div className='flex items-center'>
          <input
            type='checkbox'
            className='mr-2'
            checked={isChecked()}
            onChange={() => onSelect(item)}
          />
          {item?.name}
        </div>
        {item?.children && item?.children?.length > 0 && (
          <span className={`ml-2 transform ${isOpen ? "rotate-90" : ""}`}>
            <IoIosArrowForward className='w-5 h-5 text-gray-400' />
          </span>
        )}
      </div>

      {isOpen && item?.children && item?.children?.length > 0 && (
        <div className='ml-4 border-l pl-4'>
          {item?.children?.map((child) => (
            <DropdownItem
              key={child?.id}
              item={child}
              selectedItems={selectedItems}
              onSelect={onSelect}
              multiSelect={multiSelect}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const MultiLevelDropdown = ({
  regions,
  selectedItems,
  setSelectedItem,
  parentRegionLoader,
  multiSelect = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (!parentRegionLoader) setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (item) => {
    if (multiSelect) {
      if (
        Array.isArray(selectedItems) &&
        selectedItems?.some((selected) => selected?.id === item?.id)
      ) {
        setSelectedItem(
          selectedItems?.filter((selected) => selected?.id !== item?.id)
        );
      } else {
        setSelectedItem([...(selectedItems || []), item]);
      }
    } else {
      setSelectedItem(selectedItems?.id === item?.id ? null : item);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const displayText = () => {
    if (multiSelect) {
      return Array.isArray(selectedItems) && selectedItems?.length > 0
        ? selectedItems?.map((item) => item?.name).join(", ")
        : "Select regions";
    } else {
      return selectedItems?.name || "Select a region";
    }
  };

  return (
    <div ref={dropdownRef} className='relative inline-block text-left w-full'>
      <div
        onClick={toggleDropdown}
        className={`flex items-center justify-between border border-gray-300 min-w-full px-2 py-1.5 rounded-md cursor-pointer ${
          parentRegionLoader ? "bg-gray-200" : "bg-transparent"
        }`}
      >
        {parentRegionLoader ? (
          <span className='flex items-center text-gray-400 space-x-1'>
            <span className='animate-bounce'>.</span>
            <span className='animate-bounce animation-delay-600'>.</span>
            <span className='animate-bounce animation-delay-900'>.</span>
          </span>
        ) : (
          <span className='text-gray-700'>{displayText()}</span>
        )}
        <span
          className={`ml-2 transform ${
            isDropdownOpen && !parentRegionLoader ? "rotate-180" : ""
          }`}
        >
          <IoIosArrowDown
            className={`w-5 h-5 ${
              parentRegionLoader ? "text-gray-400" : "text-gray-700"
            }`}
          />
        </span>
      </div>
      {isDropdownOpen && !parentRegionLoader && (
        <div className='absolute left-0 top-full p-2 mt-2 w-full max-h-[300px] overflow-y-auto custom-scroll bg-white border border-gray-200 rounded-md shadow-lg z-50'>
          {regions?.length > 0 ? (
            regions?.map((region) => (
              <DropdownItem
                key={region?.id}
                item={region}
                selectedItems={selectedItems}
                onSelect={handleSelect}
                multiSelect={multiSelect}
              />
            ))
          ) : (
            <p className='min-w-full   text-center  text-gray-300 text-sm font-semibold'>
              No Option
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiLevelDropdown;
