import React, { useEffect, useMemo, useState } from "react";
import PricingItemCard from "./PricingItemCard";
import CustomButton from "components/common/Button/CustomButton";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { BsDash } from "react-icons/bs";
import { useSelector } from "react-redux";
import { getStripeSaveCardDataLoader } from "store/selector/sp.selector";
import useDefaultPaymentMethod from "hooks/useDefaultPaymentMethod";
import { useNavigate } from "react-router";
import { classNames } from "Utils/utils";
import CheckoutWrapper from "pages/Stripe/checkout/index";
import {
  BILLING_BREADCRUMB,
  BILLING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import Breadcrumb from "components/common/Breadcrumb";
import { getUser } from "store/selector/auth.selector";

const PricingTableNew = (props) => {
  const {
    data,
    paymentSettings,
    isCurrentSubscription = null,
    isTrialChoosePlanBtnClick,
  } = props;

  const { service_provider } = useSelector(getUser);

  const PLAN_TYPE = {
    TOTAL_SUITE: "total_suite",
    PLATINUM_PLAN: "platinum_plan",
  };

  const saveCardDataLoader = useSelector(getStripeSaveCardDataLoader);

  const { getDefaultCardDetails, stripeDefaultPaymentMethodLaoder } =
    useDefaultPaymentMethod();

  const defaultCardDetails = useMemo(() => {
    return getDefaultCardDetails;
  }, [paymentSettings, stripeDefaultPaymentMethodLaoder, saveCardDataLoader]);

  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedParticipants, setSelectedParticipants] = useState(null);
  const navigate = useNavigate();

  const [checkoutPage, setCheckoutPage] = useState(false);
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState(
    PLAN_TYPE.TOTAL_SUITE
  );

  useEffect(() => {
    if (isTrialChoosePlanBtnClick !== 0) {
      setCheckoutPage(false);
    }
  }, [isTrialChoosePlanBtnClick]);

  useEffect(() => {
    if (isCurrentSubscription !== null && isCurrentSubscription?.id) {
      setCurrentSelectedPlan(
        isCurrentSubscription?.subscriptionPlan
          ?.toLowerCase()
          ?.includes("total")
          ? PLAN_TYPE.TOTAL_SUITE
          : PLAN_TYPE.PLATINUM_PLAN

        // : isCurrentSubscription?.subscriptionPlan
        //     ?.toLowerCase()
        //     ?.includes("platinum")
        // ? PLAN_TYPE.PLATINUM_PLAN
        // : ""
      );
      if (isCurrentSubscription.noOfParticipants?.replace(":", "")) {
        setSelectedParticipants(
          isCurrentSubscription.noOfParticipants?.replace(":", "")
        );
        setSelectedPrice({
          [`${isCurrentSubscription.noOfParticipants?.replace(":", "")}`]: {
            price_id: isCurrentSubscription.id,
            unit_amount: isCurrentSubscription?.amount / 100,
          },
        });
      }
    }
  }, [isCurrentSubscription]);

  // const features = {
  //   both: [
  //     {
  //       value: "1 Month Software Subscription.",
  //       isBasicPlan: true,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "Comprehensive Participant Management.",
  //       isBasicPlan: true,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "Support Coordination.",
  //       isBasicPlan: true,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "Rostering.",
  //       isBasicPlan: true,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "HRM and CRM.",
  //       isBasicPlan: true,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "24/7 Customer Support.",
  //       isBasicPlan: true,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "Your Dedicated Relationship Manager.",
  //       isBasicPlan: true,
  //       isPlatinumPlan: true,
  //     },
  //   ],
  //   complimentery: [
  //     {
  //       value: "Month Software Subscription.",
  //       isBasicPlan: false,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "Advice by NDIS Legal Experts.",
  //       isBasicPlan: false,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "Customised Website for Your Business.",
  //       isBasicPlan: false,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "Legal Employment Contracts.",
  //       isBasicPlan: false,
  //       isPlatinumPlan: true,
  //     },
  //     {
  //       value: "Restraint of Trade / Anti-Poaching Policy.",
  //       isBasicPlan: false,
  //       isPlatinumPlan: true,
  //     },
  //   ],
  // };

  const features = {
    Invoicing: [
      {
        value: "Effortless Invoicing",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Built in NDIS Price Catalogue",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Streamlined Process",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Conveniently Email Invoices",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Versatile Output Options",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Improved Accuracy",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
    ],
    "Digital Participant Support Plans": [
      {
        value: "Complete Digital Participant Onboarding",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Participant Listing",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Participant Support Plans",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Feedback Register",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Appointment Setting",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Document Management System",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Funding Management",
        isBasicPlan: false,
        isPlatinumPlan: true,
      },
      {
        value: "Participant NDIS Compliance",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
    ],
    Rostering: [
      {
        value: "One Simple Screen for All Your Participants and Workers",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Easily Add New Shifts",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Copy and Recurring Shifts",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Task List for Support Workers",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Group Shift Rostering",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Worker Tracking With Welfare Check via Mobile Application",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Participant Signature via Mobile Application",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Payroll Integration (Xero, MYOB, QuickBooks)",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "NDIS Compliance",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
    ],
    HRM: [
      {
        value: "Applicant Tracking System",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Worker Onboarding",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Worker Web Portal",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Staff Skill Metrics and Traits",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Staff Training Registers",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Document Expiry Reminders ",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Employee's Performance Review ",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Birthday and Work Anniversary Greetings",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Deactivating Worker ",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
    ],
    "Risk Management": [
      {
        value: "Participant Risk Assessment",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Risk Mitigation Planning",
        isBasicPlan: false,
        isPlatinumPlan: true,
      },
      {
        value: "Document Management System",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "NDIS Workers Compliance",
        isBasicPlan: true,
        isPlatinumPlan: true,
      },
      {
        value: "Risk Ratings",
        isBasicPlan: false,
        isPlatinumPlan: true,
      },
    ],
  };

  const handleCloseCheckoutPage = () => {
    setSelectedParticipants(null);
    // setSelectedPlan(null);
    setSelectedPrice(null);
    setCheckoutPage(false);
    navigate("/subscription");
  };

  useEffect(() => {
    if (isCurrentSubscription === null) {
      setSelectedPrice(null);
    }
  }, [currentSelectedPlan]);

  const pricingTableByplan = useMemo(() => {
    if (currentSelectedPlan && data?.length > 0) {
      const structuredData = data.reduce((acc, item) => {
        const participants = item.name;
        const year1 = item[currentSelectedPlan]["1_year"];
        const year2 = item[currentSelectedPlan]["2_year"];

        if (!acc[currentSelectedPlan]) {
          acc[currentSelectedPlan] = {
            participants: [],
            year_1: {},
            year_2: {},
          };
        }

        acc[currentSelectedPlan].participants.push(participants);
        acc[currentSelectedPlan].year_1[participants] = year1;
        acc[currentSelectedPlan].year_2[participants] = year2;

        return acc;
      }, {});
      return structuredData?.[currentSelectedPlan];
    }
    return null;
  }, [data, currentSelectedPlan]);

  const handlechangePrice = (eventType, data) => {
    if (data && eventType === "noOfParticipants") {
      setSelectedParticipants(data);
      setSelectedPrice(null);
    } else if (eventType === "price" && Object.keys(data).length > 0) {
      const keyName = Object.keys(data)?.[0] || null;
      if (keyName !== null) {
        setSelectedParticipants(keyName);
        setSelectedPrice(data);
      }
    }
  };

  const isSubscriptionNotChangeable = () => {
    if (isCurrentSubscription === null) return false;
    return true;
  };

  if (
    selectedPrice &&
    selectedPrice !== null &&
    selectedParticipants &&
    selectedParticipants !== null &&
    // selectedPlan &&
    // selectedPlan !== null &&
    checkoutPage &&
    currentSelectedPlan
  ) {
    return (
      <CheckoutWrapper
        handleBack={() => setCheckoutPage(false)}
        data={{
          selectedPrice:
            selectedPrice && selectedParticipants
              ? selectedPrice?.[selectedParticipants]
              : null,
          selectedParticipants: selectedParticipants,
          selectedPlan:
            currentSelectedPlan === PLAN_TYPE.TOTAL_SUITE
              ? "Total Suite "
              : currentSelectedPlan === PLAN_TYPE.PLATINUM_PLAN
              ? "Platinum Plan"
              : null,
        }}
        paymentSettings={paymentSettings}
        defaultCardDetails={defaultCardDetails}
        stripeDefaultPaymentMethodLaoder={stripeDefaultPaymentMethodLaoder}
        handleCloseCheckoutPage={handleCloseCheckoutPage}
      />
    );
  }

  return (
    <div className='w-full mx-auto max-w-7xl px-4 py-2'>
      <div className='mb-5 border-gray-200 px-4 pt-2'>
        <Breadcrumb
          pages={BILLING_BREADCRUMB(BILLING_BREADCRUMB_PAGES.BILLING_PLAN)}
        />
      </div>
      <div className='py-4 px-6'>
        <div>
          <h1 className='font-black text-2xl'>Pricing</h1>
        </div>
        <div className='py-4 text-sm text-gray-600'>
          <p>
            Our Pricing is desgined to accommodate your needs, whether you are
            starting our or managing at least 50 participant.
          </p>
          <p>
            Enjoy the flexibility of our NDIS software solutions at competitive
            pricing.
          </p>
        </div>
        <div className='w-full'>
          <div
            className={
              // service_provider?.number_of_participants === "Up to 20"
              //   ? "w-[15%]"
              //   : "w-[30%]"
              "w-[30%]"
            }
          >
            <div
              className={classNames(
                `w-full grid border border-1 border-gray-300 rounded-full grid-cols-2`
                // service_provider?.number_of_participants === "Up to 20"
                // ? "grid-cols-1"
                // : "grid-cols-2"
              )}
            >
              <span
                onClick={() =>
                  !isSubscriptionNotChangeable() &&
                  setCurrentSelectedPlan(PLAN_TYPE.TOTAL_SUITE)
                }
                role='button'
                tabIndex={0}
                className={classNames(
                  "transition duration-500 col-span-1 rounded-full flex items-center justify-center py-2.5",
                  currentSelectedPlan === PLAN_TYPE.TOTAL_SUITE
                    ? "bg-[#2f4778] text-white"
                    : "text-gray-700"
                )}
              >
                Total Suite
              </span>
              {/* {service_provider?.number_of_participants !== "Up to 20" && ( */}
              <span
                onClick={() =>
                  !isSubscriptionNotChangeable() &&
                  setCurrentSelectedPlan(PLAN_TYPE.PLATINUM_PLAN)
                }
                role='button'
                tabIndex={0}
                className={classNames(
                  "transition duration-300 col-span-1 rounded-full  flex items-center justify-center py-2.5",
                  currentSelectedPlan === PLAN_TYPE.PLATINUM_PLAN
                    ? "bg-[#2f4778] text-white"
                    : "text-gray-700"
                )}
              >
                Platinum Plan
              </span>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className='pt-4'>
          <div className='grid grid-cols-3 gap-x-4'>
            <div className='col-span-1'>
              <PricingItemCard
                selectedData={{
                  selectedPrice: selectedPrice,
                  selectedParticipants: selectedParticipants,
                }}
                renderFor='participants'
                data={
                  pricingTableByplan?.hasOwnProperty("participants")
                    ? pricingTableByplan.participants
                    : []
                }
                handleChange={handlechangePrice}
                isSubscriptionNotChangeable={isSubscriptionNotChangeable()}
              />
            </div>
            <div className='col-span-1'>
              <PricingItemCard
                selectedData={{
                  selectedPrice:
                    selectedPrice && selectedParticipants
                      ? selectedPrice?.[selectedParticipants]
                      : null,
                  selectedParticipants: selectedParticipants,
                }}
                renderFor='year_1'
                data={
                  pricingTableByplan?.hasOwnProperty("year_1")
                    ? pricingTableByplan.year_1
                    : []
                }
                handleChange={handlechangePrice}
                isSubscriptionNotChangeable={isSubscriptionNotChangeable()}
              />
            </div>
            <div className='col-span-1'>
              <PricingItemCard
                selectedData={{
                  selectedPrice:
                    selectedPrice && selectedParticipants
                      ? selectedPrice?.[selectedParticipants]
                      : null,
                  selectedParticipants: selectedParticipants,
                }}
                renderFor='year_2'
                data={
                  pricingTableByplan?.hasOwnProperty("year_2")
                    ? pricingTableByplan.year_2
                    : []
                }
                handleChange={handlechangePrice}
                isSubscriptionNotChangeable={isSubscriptionNotChangeable()}
              />
            </div>
          </div>
        </div>
        <div className='py-8'>
          {!isSubscriptionNotChangeable() && (
            <div className='w-full flex justify-center items-center'>
              <CustomButton
                isDisabled={
                  !(
                    selectedPrice &&
                    selectedPrice !== null &&
                    selectedParticipants &&
                    selectedParticipants !== null
                  ) || isSubscriptionNotChangeable()
                }
                clickHandler={() => setCheckoutPage(true)}
                label='Purchase'
                cssClass='rounded-full flex justify-center items-center w-[20%] py-3'
              />
            </div>
          )}
        </div>
        {/* <div className='grid'>
          <span
            className='font-black text-black text-2xl'
            style={{ fontWeight: "1000" }}
          >
            There is Nothing Basic About Our Plan!
          </span>
          <span className='text-sm text-gray-700 py-5'>
            Experience the extraordinary in our Basic Plan--a realm where
            'basic' takes on a whole new meaning. Get ready to elevate your NDIS
            journey with feature that defy convention and redefine excellence.
            Basic and Custom Plans include:
          </span>
        </div> */}
        <div className=' py-4'>
          <div
            className={classNames(
              "px-10 mx-10 pt-2 grid grid-cols-5 font-extrabold text-md gap-x-2 border-t-2 border-gray-200"
            )}
          >
            <div
              className={
                // service_provider?.number_of_participants === "Up to 20" &&
                // "grid-cols-4"
                //   ? "col-start-1 col-end-4"
                //   :
                "col-start-2 col-end-4"
              }
            >
              Features
            </div>
            <div className='col-span-1'>Total Suite</div>
            {/* {service_provider?.number_of_participants !== "Up to 20" && ( */}
            <div className='col-span-1'>Platinum</div>
            {/* )} */}
          </div>
          {Object.keys(features).map((key, index) => (
            <>
              <div
                key={index}
                className='mx-10 px-1 py-2 m-2 border-t-2 border-b-2 border-gray-200 bg-gray-300 font-extrabold text-md'
              >
                {key}
              </div>
              {features[key].map((item, index) => (
                <div
                  key={index}
                  className={`px-10 grid grid-cols-5 gap-x-2 text-sm py-3 ${
                    index % 2 === 0 ? "bg-gray-50" : ""
                  }`}
                >
                  <div className='col-span-3 px-2 flex justify-start gap-x-2 items-center'>
                    {/* <span>•</span> */}
                    {item.value}
                  </div>
                  <div className='pl-2 col-span-1 text-center'>
                    {item.isBasicPlan === false ? (
                      <BsDash
                        color='red'
                        style={{ width: "25px", height: "auto" }}
                      />
                    ) : (
                      <CheckCircleIcon height={20} width={20} color='green' />
                    )}
                  </div>
                  {/* {service_provider?.number_of_participants !== "Up to 20" && ( */}
                  <div className='pl-6 col-span-1 text-center'>
                    {item.isPlatinumPlan === false ? (
                      <BsDash
                        color='red'
                        style={{ width: "25px", height: "auto" }}
                      />
                    ) : (
                      <CheckCircleIcon height={20} width={20} color='green' />
                    )}
                  </div>
                  {/* )} */}
                </div>
              ))}
            </>
          ))}
        </div>
        {/* <div className='pt-2'>
          <div className='pb-10'>
            <span
              className='font-black text-black text-2xl '
              style={{ fontWeight: "1000" }}
            >
              Complimentary Benefits and Rewards
            </span>
          </div>
          <div className='px-10 grid grid-cols-5 gap-x-2 pb-4'>
            <div className='col-span-3 font-extrabold text-md'>Features</div>
            <div className='col-span-1 text-md'>Basic</div>
            <div className='col-span-1 text-md'>Platinum</div>
          </div>
          {features.complimentery.map((item, index) => (
            <div
              key={index}
              className={`px-10 grid grid-cols-5 gap-x-2 text-sm py-2 ${
                index % 2 !== 0 ? "bg-gray-50" : ""
              }`}
            >
              <div className='col-span-3 flex justify-start gap-x-2 items-center'>
                <span>•</span>
                {item.value}
              </div>
              <div className='pl-2 col-span-1 text-center'>
                {item.isBasicPlan === false ? (
                  <BsDash
                    color='red'
                    style={{ width: "25px", height: "auto" }}
                  />
                ) : (
                  <CheckCircleIcon height={20} width={20} color='green' />
                )}
              </div>
              <div className='pl-6 col-span-1 text-center'>
                {item.isPlatinumPlan === false ? (
                  <BsDash
                    color='red'
                    style={{ width: "25px", height: "auto" }}
                  />
                ) : (
                  <CheckCircleIcon height={20} width={20} color='green' />
                )}
              </div>
            </div>
          ))}
        </div> */}
        <div className='py-6'>
          <div className='pb-6'>
            <span
              className='font-black text-black text-2xl '
              style={{ fontWeight: "1000" }}
            >
              Terms & Conditions*
            </span>
          </div>
          <div className='pl-1 space-y-2.5 text-sm'>
            <div className='col-span-3 flex justify-start gap-x-2 items-center'>
              <span>•</span>
              Pricing is indicative only and at the absolute discretion of the
              Software Vendor.
            </div>
            <div className='col-span-3 flex justify-start gap-x-2 items-center'>
              <span>•</span>
              To avail complimentary benefits and rewards, you must have signed
              up for a period of one year.
            </div>
            <div className='col-span-3 flex justify-start gap-x-2 items-center'>
              <span>•</span>
              To avail complimentary benefits , you must have signed up for a
              Platinum Plan.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTableNew;
