import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SelectOption from "../../../../../components/common/Select/Select";
import CustomButton from "../../../../../components/common/Button/CustomButton";
import ModalSkeleton from "../../../../../components/Modals/ModalSkeleton";
import { BiPlus } from "react-icons/bi";
import InputField from "components/common/Input/Input";
import { activeAndDeactiveOptions, LOADER_STATUS } from "Constants/constant";
import { LoginContext } from "helpers/LoginContext";

const CreateContactModel = ({
  createNewContactError,
  createNewContactLoading,
  createNewContactRequest,
  updateContactRequest,
  updateContactSuccess,
  updateContactLoading,
  updateContactError,
  selectedContact,
  isOpen,
  closeModal,
}) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const watchFormData = watch();

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [isRequestCall, setIsRequestCall] = useState(LOADER_STATUS.NA);
  useEffect(() => setIsRequestCall(LOADER_STATUS.NA), []);

  useEffect(() => {
    if (selectedContact) {
      setValue("title", selectedContact?.title);
      setValue("note", selectedContact?.note);
      const selectedStatus = activeAndDeactiveOptions.find(
        (x) => x?.value.toLowerCase() === selectedContact?.status.toLowerCase()
      );
      setValue("status", selectedStatus ? selectedStatus.value : null);
    }
  }, [selectedContact]);

  useEffect(() => {
    if (
      isRequestCall === LOADER_STATUS.PENDING &&
      !createNewContactLoading &&
      createNewContactError.length === 0
    ) {
      closeModal();
      setIsRequestCall(LOADER_STATUS.NA);
    }
  }, [createNewContactLoading]);

  useEffect(() => {
    if (
      isRequestCall === LOADER_STATUS.PENDING &&
      !updateContactLoading &&
      updateContactError.length === 0
    ) {
      closeModal();
      setIsRequestCall(LOADER_STATUS.NA);
    }
  }, [updateContactLoading]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    setIsRequestCall(LOADER_STATUS.PENDING);
    if (selectedContact && selectedContact.uuid) {
      updateContactRequest({
        ...data,
        contact_type_id: selectedContact.uuid,
      });
    } else {
      createNewContactRequest({
        ...data,
      });
    }
  };

  const modalFooter = () => (
    <CustomButton
      type='submit'
      showLoading={createNewContactLoading || updateContactLoading}
      isDisabled={
        createNewContactLoading || updateContactLoading || isUserGracePeriodOver
      }
      label={selectedContact ? "Update" : "Create"}
      {...(selectedContact === null && {
        Icon: <BiPlus />,
      })}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("cosContactSetting");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <div>
      <ModalSkeleton
        cssClass='w-[40%]'
        title={`${selectedContact ? "Update" : "Create"} Note Contact Type`}
        modalFooter={modalFooter()}
        isOpen={isOpen}
        closeModal={() => closeModal(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-cols-2 space-x-4'>
            <div className='col-span-1'>
              <InputField
                isDisabled={selectedContact ? true : false}
                readOnly={selectedContact ? true : false}
                setValue={setValue}
                register={register}
                maxLength={254}
                label='Title'
                type='title'
                value={watchFormData?.title ?? null}
                name='title'
                isRequired={true}
                ErrorMessage={
                  errors?.title?.type === "required"
                    ? "This field is required!"
                    : null
                }
              />
            </div>
            <div className='col-span-1'>
              <label
                htmlFor=''
                className='block text-sm font-medium text-gray-700'
              >
                Status
              </label>
              <SelectOption
                cssClass='mt-1'
                isRequired={true}
                register={register}
                setValue={setValue}
                clearErrors={clearErrors}
                name='status'
                isSearch={false}
                data={activeAndDeactiveOptions}
                selectedOption={activeAndDeactiveOptions.find(
                  (x) =>
                    x.value.toLowerCase() ===
                    watchFormData?.status?.toLowerCase()
                )}
                errorMessage={
                  errors?.status?.type === "required"
                    ? "This field is required!"
                    : null
                }
              />
            </div>
            <div className='col-span-2' style={{ margin: "0px" }}>
              <label htmlFor='' className='did-floating-label text-start'>
                Description
              </label>
              <textarea
                {...register("note", {
                  required: true,
                })}
                rows='3'
                className='w-full border-gray-300 rounded oeverflow-y-auto resize-none'
              ></textarea>
              {errors?.note?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <button
            type='submit'
            className='hidden sr-only'
            id='cosContactSetting'
          />
        </form>
      </ModalSkeleton>
    </div>
  );
};
export default CreateContactModel;
