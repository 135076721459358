// import React from "react";
// import "./CircularLoader.css";
// import logo from "../../../assets/logo/vertex-logo-animation.gif";
// const CircularLoader = ({ classes, classloader }) => {
//   return (
//     <div className={classes}>
//       {/* <div className={classloader}> */}
//       {/* </div> */}
//       <img className="w-1/2" src={logo} alt="vertex-logo" />
//     </div>
//   );
// };

// export default CircularLoader;


import React from "react";
import "./CircularLoader.css";
import logo from "../../../assets/logo/vertex-logo-animation.gif";

const CircularLoader = ({ classes }) => {
  return (
    <div className={`flex justify-center items-center ${classes}`}>
      <img className="w-1/2" src={logo} alt="vertex-logo" />
    </div>
  );
};

export default CircularLoader;
