import React, { useState } from "react";
import Input, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./Style.css";

const PhoneNumberInput = (props) => {
  const { setPhone, phone, setDisable } = props;
  const [onFocuseInput, setOnFocuseInput] = useState("");
  const handleChange = (value) => {
    // console.log("value", value);
    if (typeof value === "undefined") {
      setDisable(true);
    } else {
      setDisable(false);
    }
    setPhone(value);
    let p = "";
    let c = "";
    const parsedValue = parsePhoneNumber(value ? value : "", "US");
    if (parsedValue) {
      p = parsedValue.nationalNumber;
      c = parsedValue.countryCallingCode;
    }
    // setPhoneNumber(p);
    // setCountry(c);
  };
  return (
    <div>
      <div className="mb-6 flex">
        <Input
          className={`${
            onFocuseInput === "phoneNumber"
              ? "focusedInput w-full"
              : "registerInput w-full"
          }`}
          placeholder="Phone Number"
          dir="ltr"
          defaultCountry="US"
          value={phone}
          limitMaxLength
          onChange={handleChange}
          name="phoneNumber"
          onFocus={() => setOnFocuseInput("phoneNumber")}
          required
        />
      </div>
    </div>
  );
};
export default PhoneNumberInput;
