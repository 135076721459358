import { call, put, all, takeEvery } from "redux-saga/effects";
// import { TOAST_TYPE } from "../../Constants/constant";
import { employeeActions } from "../actions";
import ACTIONS from "../constants";
import EmployeeAPI from "../services/employee.services";

function* getHrmFormCategoriesList() {
  try {
    yield put(employeeActions.hrmFormCategoriesList.pending);
    const response = yield call(EmployeeAPI.hrmFormCategoriesList);
    if (response && response?.form_categories) {
      yield put(
        employeeActions.hrmFormCategoriesList.success(response.form_categories)
      );
    }
  } catch (error) {
    yield put(employeeActions.hrmFormCategoriesList.error(error));
  }
}

function* getHrmSubmittedFormsListByCategory(state) {
  try {
    yield put(employeeActions.hrmSubmittedFormsListByCategory.pending);
    const response = yield call(
      EmployeeAPI.hrmSubmittedFormsListByCategory,
      state.payload
    );
    if (response && response?.data) {
      yield put(
        employeeActions.hrmSubmittedFormsListByCategory.success(response.data)
      );
    }
  } catch (error) {
    yield put(employeeActions.hrmSubmittedFormsListByCategory.error(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.HRM_FORM_CATEGORIES_LIST_CALL, getHrmFormCategoriesList),
    takeEvery(
      ACTIONS.HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY_CALL,
      getHrmSubmittedFormsListByCategory
    ),
  ]);
}
