import React, { useContext, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import {
  FUNDING_TABS,
  FUNDING_TABS_NAME,
  PARTICIPANT_PROFILE_TABS,
} from "../../../../Constants/constant";
import { LoginContext } from "../../../../helpers/LoginContext";
import Tabs from "../../../../pages/HRM/Components/Tabs/Tabs";
import { selectedTabState } from "../../../../Utils/utils";
import PlanBudget from "../PlanBudget/index";
import ServiceCategoryBudget from "../ServiceCategoryBudget/index";
import ServiceSupport from "../ServiceSupport/index";
import BudgetStats from "./BudgetStats";
import FundingStats from "./FundingStats";
import FundingNotes from "../FundingNotes/index";
import DeliveredService from "../DeliveredService/index";
import ManualDelivery from "../ManualDelivery/index";
import {
  FUNDING_BREADCRUMB,
  FUNDING_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import ParticipantImageToolTip from "components/common/Tooltip/ParticipantImageToolTip";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const FundingBudget = (props) => {
  const {
    participant,
    resetParticipant,
    getFundingSetup,
    isLoading,
    selectedFunding,
    resetSelectedParticipant,
    getParticipant,
    resetParticipantFundings,
    getParticipantLoader,
  } = props;
  const { uuid, ptUUID } = useParams();
  const { sideBarPadding } = useContext(LoginContext);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(FUNDING_TABS_NAME.PLAN_BUDGET);
  const { state } = location;

  useEffect(() => {
    if (state !== null && state.participant && state.participantOnBoarding) {
      resetParticipant(state);
    }
  }, [state]);

  useEffect(() => {
    if (ptUUID && uuid) {
      getFundingSetup({ funding_id: uuid, pt_id: ptUUID });
    }
    return () => {
      resetSelectedParticipant();
      resetParticipantFundings();
      selectedTabState.save(
        JSON.stringify(PARTICIPANT_PROFILE_TABS.find((x) => x.id === 17))
      );
    };
  }, []);

  useEffect(() => {
    if (!participant && state === null) {
      getParticipant({ participantUUID: ptUUID });
    }
  }, [participant]);

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const tabToRender = () => {
    switch (selectedTab) {
      case FUNDING_TABS_NAME.PLAN_BUDGET:
        return <PlanBudget />;
      case FUNDING_TABS_NAME.SERVICE_PROVIDER_CATAGORY_BUDGET:
        return <ServiceCategoryBudget />;
      case FUNDING_TABS_NAME.SERVICE_SUPPORT:
        return <ServiceSupport />;
      case FUNDING_TABS_NAME.DELIVERED_SERVICES:
        return <DeliveredService />;
      case FUNDING_TABS_NAME.MANUAL_DELIVERED_SERVICES:
        return <ManualDelivery />;
      case FUNDING_TABS_NAME.NOTES:
        return <FundingNotes />;
      default:
        return <PlanBudget />;
    }
  };

  return (
    <div className='w-full'>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col`}
        >
          {!isLoading &&
          !getParticipantLoader &&
          participant &&
          selectedFunding ? (
            <main className='flex-1 w-full'>
              <div className='bg-white sticky top-0  items-center flex border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8'>
                <div className='mt-4 flex sm:mt-0'>
                  {participant && Object.keys(participant).length > 0 && (
                    <div className='flex items-center'>
                      <div className='h-10 w-10 flex-shrink-0 group relative'>
                        <ParticipantImageToolTip
                          cssClass={
                            "bg-black py-1 px-1 rounded text-white z-[9]"
                          }
                          participant={participant}
                          imgCssClass={
                            "capitalize object-cover h-10 w-10 rounded-full"
                          }
                          // position="right"
                        />
                      </div>
                      <div className='ml-4'>
                        <div className='font-medium text-gray-900 capitalize'>
                          {`${participant.first_name} ${participant.last_name}`}
                        </div>
                        <div className='text-gray-500 flex items-center gap-2'>
                          {roleAccess(
                            [],
                            PERMISSION_KEYS.PARTICIPANT,
                            PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_EMAIL
                          ) && <>{participant.email}</>}
                          <p className='mt-1 max-w-5xl text-sm text-gray-500 break-words'>
                            <span
                              className={`${
                                selectedFunding.status
                                  ? "bg-green-100 text-green-700"
                                  : "bg-red-100 text-red-700"
                              } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
                            >
                              {selectedFunding.status ? "Active" : "Inactive"}
                            </span>
                            {selectedFunding.status}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex-1 min-w-0 flex justify-end '>
                  <h1 className='capitalize text-lg font-bold leading-6 text-gray-900 sm:truncate'>
                    {selectedFunding.contract_id}
                  </h1>
                </div>
              </div>
              <div className='max-w-7xl mx-auto'>
                <div className='px-8 py-4'>
                  <Breadcrumb
                    pages={FUNDING_BREADCRUMB(
                      FUNDING_PAGES.PARTICIPANTS_PROFILE,
                      selectedFunding
                    )}
                  />
                </div>
                <div>
                  <section>
                    <FundingStats funding={selectedFunding} />
                  </section>
                  <section>
                    <BudgetStats funding={selectedFunding} />
                  </section>
                  <div
                    className='sticky z-[7] bg-white py-2'
                    style={{ top: "80px" }}
                  >
                    <div className='mx-8 py-1 px-3 border-1 border-[#F2F4F7] pt-1 bg-[#F9FAFB] rounded-full '>
                      <Tabs
                        tabs={FUNDING_TABS}
                        handleChange={handleTabChange}
                        selectedTab={selectedTab}
                      />
                    </div>
                  </div>
                  <div className='mt-8 m-8 bg-white rounded-md custom-scroll11 overflow-y-auto11 allApplicantHeight11'>
                    <div className=''>
                      <div className='w-full'>{tabToRender()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          ) : (
            <div className='px-0 flex justify-center items-center min-h-screen bg-gray-50'>
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FundingBudget;
