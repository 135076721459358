import React from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { HiOutlineChevronDoubleLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

const PermissionDenied = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className='min-h-full pt-16 pb-12 flex flex-col bg-white'>
        <main className='flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
          <div className='flex-shrink-0 flex justify-center'>
            <Link to='/dashboard' className='inline-flex'>
              <AiOutlineFileSearch className='text-blue-dark h-20 w-20' />
            </Link>
          </div>
          <div className='py-16'>
            <div className='text-center'>
              <p className='text-sm font-semibold text-gray-400 uppercase tracking-wide'>
                403 error
              </p>
              <h1 className='mt-2 text-4xl font-extrabold text-blue-dark tracking-tight sm:text-5xl'>
                Permission Denied
              </h1>
              <p className='mt-2 text-base text-gray-400'>
                Access to this module is not granted. Please get in touch with
                the administrator for assistance.
              </p>
              <div className='mt-6 flex justify-center'>
                <button
                  className='text-base font-medium text-blue-dark hover:underline flex items-center space-x-4 justify-center'
                  onClick={() => navigate("/")}
                >
                  <HiOutlineChevronDoubleLeft />
                  <span>Go Back to dashboard</span>
                </button>
              </div>
            </div>
          </div>
        </main>
        <footer className='flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
          <nav className='flex justify-center space-x-4'>
            <p className='text-sm font-medium text-blue-dark'>
              &copy; all rights are reserved by Vertex
            </p>
          </nav>
        </footer>
      </div>
    </>
  );
};

export default PermissionDenied;
