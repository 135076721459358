import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getUser } from "store/selector/auth.selector";
import CustomButton from "components/common/Button/CustomButton";
// import CaseNoteFileInput from "components/common/FileUpload/CaseNoteFileInput";
import SwitchInput from "components/common/Input/SwitchInput";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { USER_ROLE } from "Constants/constant";
import {
  apiFormDataRequest,
  apiPostRequest,
  apiPutRequest,
} from "helpers/Requests";
import {
  baseURLImage,
  getFileFromServerAsFileType,
  getNameProfile,
  getUrlRole,
  parseBooleanVal,
  sleep,
} from "Utils/utils";
import { roleAccess } from "helpers/RolesPermission";
// import { FaDownload, FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import MultiFileInput from "components/common/FileUpload/MultiFileInput";
import Loader from "components/common/Loader/Loader";
import api from "store/services/sp.services";
import { cloneDeep } from "lodash";

const EditCaseNoteModal = ({
  isOpen,
  selectedComment,
  onCloseModal,
  fetchNotes,
  setFetchNotes,
  participantId,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const watchFormData = watch();

  const [isUpdating, setIsUpdating] = useState(false);
  const currentUser = useSelector(getUser);

  const [isFileDeleteLoader, setIsFileDeleteLoader] = useState(null);

  useEffect(() => {
    return () => {
      reset({});
      setIsUpdating(false);
    };
  }, []);

  const [fetchServerFiles, setFetchServerFiles] = useState(false);

  useEffect(() => {
    if (selectedComment) {
      // console.log("selectedComment", selectedComment);
      async function fetchAttachments() {
        try {
          const serverFiles = await Promise.all(
            selectedComment.attachments?.map(async (item) => {
              try {
                setFetchServerFiles(true);
                const response = await getFileFromServerAsFileType(
                  baseURLImage(item?.attachments),
                  item?.original_name
                );
                setFetchServerFiles(false);
                return { file: response, ...item };
              } catch (error) {
                setFetchServerFiles(false);
                console.error(
                  `Error fetching file from ${baseURLImage(
                    item?.original_name
                  )}:`,
                  error
                );
                // return null;
                return { file: null, ...item };
              }
            }) || []
          );

          reset({
            id: selectedComment?.id,
            uuid: selectedComment?.uuid,
            notes: selectedComment.notes || "",
            attachments: serverFiles.filter((file) => file !== null) || [],
            show_to_worker: parseBooleanVal(selectedComment.show_to_worker),
            worker_to_worker: parseBooleanVal(selectedComment.worker_to_worker),
          });
        } catch (error) {
          console.error("Error fetching attachments:", error);
        }
      }

      fetchAttachments();
    }
  }, [selectedComment, reset]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const handleUpdateCaseNote = async (data) => {
    setIsUpdating(true);
    try {
      const formData = new FormData();
      formData.append("pt_id", participantId);
      formData.append("notes", data.notes);
      const fileToUpload = data?.attachments?.filter(
        (fl) => fl instanceof File
      );
      // console.log("fileToUpload", fileToUpload);
      if (fileToUpload && fileToUpload?.length > 0) {
        fileToUpload?.forEach((fl) => {
          formData.append("attachments[]", fl);
        });
      }
      if (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) {
        formData.append("show_to_worker", data?.show_to_worker);
      } else if (roleAccess([USER_ROLE.Sp_Standard_User])) {
        formData.append("worker_to_worker", data?.worker_to_worker);
      }

      if (data.uuid) {
        formData.append("uuid", data.uuid);
      }
      const response = await apiFormDataRequest(
        `/${getUrlRole()}/case-note`,
        formData
      );
      // if (response.status !== 201 || response.status !== 200) {
      //   toast.error("Failed to update case note. Please try again later.");
      // } else {
      const prevCaseNotes = cloneDeep(fetchNotes);
      // console.log("prevCaseNotes", prevCaseNotes);
      const index = prevCaseNotes?.findIndex((e) => e.uuid === data.uuid);
      if (index !== -1) {
        // console.log("prevCaseNotes:before", prevCaseNotes[index]);
        // console.log("response?.data?.case_notes", response?.data?.case_notes);
        prevCaseNotes.splice(index, 1, {
          ...response?.data?.case_notes,
        });
        setFetchNotes([...prevCaseNotes]);
        toast.success("Case note updated successfully");
        // console.log("prevCaseNotes:after", prevCaseNotes[index]);
      }
      onCloseModal();
      // }

      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
      console.error("Error updating case note:", err);
      toast.error(
        err?.message ||
          "An error occurred while updating case note. Please try again later."
      );
    }
  };

  // const handleView = (attachments) => {
  //   const fileName = attachments.name || attachments.fileName || "";
  //   const fileUrl = `https://docs.vertex360.io/${attachments.attachments}`;
  //   if (
  //     fileName.endsWith(".jpg") ||
  //     fileName.endsWith(".png") ||
  //     fileName.endsWith(".jpeg") ||
  //     fileName.endsWith(".gif")
  //   ) {
  //     window.open(baseURLImage(fileUrl), "_blank");
  //   } else {
  //     window.open(fileUrl, "_blank");
  //   }
  // };

  // const handleDownload = (attachments) => {
  //   const fileName = attachments.name || attachments.fileName || "";
  //   const downloadLink = `https://docs.vertex360.io/${attachments.attachments}`;

  //   fetch(downloadLink)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.blob();
  //     })
  //     .then((blob) => {
  //       const blobUrl = URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = blobUrl;
  //       a.download = fileName || attachments.attachments;
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //       URL.revokeObjectURL(blobUrl);
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading file:", error);
  //     });
  // };

  const modalUpdateFooterButton = () => (
    <div className='flex space-x-2'>
      <CustomButton
        clickHandler={() => {
          const buttonRef = document.getElementById(
            "updateParticipantCaseNoteButton"
          );
          if (buttonRef) {
            buttonRef.click();
          }
        }}
        type='button'
        isDisabled={isUpdating || fetchServerFiles || isFileDeleteLoader}
        showLoading={isUpdating}
        label='Update'
        variant='primary'
      />
      <CustomButton
        isDisabled={isUpdating || isFileDeleteLoader}
        clickHandler={onCloseModal}
        type='button'
        label='Close'
        variant='danger'
      />
    </div>
  );

  const handleDeleteItemCall = (item, callback) => {
    try {
      setIsFileDeleteLoader(item?.id ? item?.file?.name : item?.name);
      api
        .deleteCaseNoteAttachment(selectedComment?.uuid, item.id, getUrlRole())
        .then(() => {
          setIsFileDeleteLoader(null);
          const prevCaseNotes = cloneDeep(fetchNotes);
          const index = prevCaseNotes?.findIndex(
            (e) => e.uuid === selectedComment?.uuid
          );
          if (index !== -1) {
            prevCaseNotes.splice(index, 1, {
              ...prevCaseNotes[index],
              attachments: prevCaseNotes[index]?.attachments?.filter(
                (x) => x.id !== item.id
              ),
            });
            setFetchNotes([...prevCaseNotes]);
          }
          if (callback) callback();
        })
        .catch((error) => {
          setIsFileDeleteLoader(null);
          toast.error(error?.message || "Something Went Wrong!");
        });
    } catch (err) {
      setIsFileDeleteLoader(null);
      toast.error(err?.message || "Something Went Wrong!");
      console.log("err", err);
    }
  };

  return (
    <>
      {isOpen && (
        <ModalSkeleton
          isOpen={isOpen}
          closeModal={() => {
            if (isFileDeleteLoader !== null) return;
            onCloseModal();
          }}
          modalFooter={modalUpdateFooterButton()}
          cssClass='w-[50%]'
          title='Edit CaseNote'
        >
          <div className='bg-gray-50 px-4 py-6 sm:px-6'>
            <div className='flex space-x-3'>
              {currentUser && (
                <div className='flex-shrink-0'>
                  <img
                    onError={(e) =>
                      handleImageError(e, currentUser.name || " ", " ")
                    }
                    className='h-10 w-10 rounded-full object-cover'
                    src={
                      currentUser.profile_picture
                        ? baseURLImage(currentUser.profile_picture)
                        : getNameProfile(currentUser.name || " ", " ")
                    }
                    alt='profilePicture'
                  />
                </div>
              )}
              <div className='min-w-0 flex-1'>
                <form onSubmit={handleSubmit(handleUpdateCaseNote)}>
                  <div>
                    <textarea
                      {...register("notes", {
                        required: "Case note is required",
                      })}
                      rows={3}
                      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    />
                    {errors.notes && (
                      <span className='text-red-600 text-sm'>
                        {errors.notes.message}
                      </span>
                    )}
                  </div>
                  <br />
                  <div className='col-span-1'>
                    <label className='did-floating-label text-start'>
                      Attachment
                    </label>
                    <div>
                      {fetchServerFiles ? (
                        <Loader isSmallView={true} cssClass={"text-black"} />
                      ) : (
                        <MultiFileInput
                          type='casenote'
                          handleFile={(e) => {
                            setValue("attachments", e);
                          }}
                          size='1'
                          files={watchFormData?.attachments}
                          handleDelete={(e) => {
                            setValue("attachments", e);
                          }}
                          handleDeleteItem={handleDeleteItemCall}
                          showDragAndDrop={false}
                          deleteFileLoader={isFileDeleteLoader}
                          isDisabled={isFileDeleteLoader}
                          filenameLimit={150}
                        />
                      )}
                    </div>
                  </div>
                  <br />
                  {/* <div className='space-y-4'>
                    <div>
                      <label className='block text-sm font-medium text-gray-700'>
                        Attach Files
                      </label>
                      <div className='flex flex-col space-y-2 item'>
                        {selectedComment?.attachments?.map(
                          (attachment, index) => (
                            <div
                              key={index}
                              className='flex items-center gap-2'
                            >
                              <span className='text-sm text-gray-700'>
                                File {index + 1}: {attachment.name}
                              </span>
                              <div className='flex space-y-2 items-end'>
                                <CustomButton
                                  type='button'
                                  label={
                                    <>
                                      <FaEye
                                        style={{
                                          color: "white",
                                          marginRight: "5px",
                                        }}
                                      />
                                      View
                                    </>
                                  }
                                  onClick={() => handleView(attachment)}
                                  style={{
                                    fontSize: "0.8em",
                                    padding: "5px 10px",
                                    marginRight: "10px",
                                  }}
                                />
                                <CustomButton
                                  type='button'
                                  label={
                                    <>
                                      <FaDownload
                                        style={{
                                          color: "white",
                                          marginRight: "5px",
                                        }}
                                      />
                                      Download
                                    </>
                                  }
                                  onClick={() => handleDownload(attachment)}
                                  style={{
                                    fontSize: "0.8em",
                                    padding: "5px 10px",
                                  }}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className='mt-3 flex items-center justify-between'>
                    <div className='flex gap-x-4'>
                      {roleAccess([
                        USER_ROLE.Sp_Admin,
                        USER_ROLE.Sp_manager,
                      ]) && (
                        <SwitchInput
                          handleChange={(checked) =>
                            setValue("show_to_worker", Boolean(checked))
                          }
                          checked={watchFormData?.show_to_worker}
                          label='Show to worker'
                        />
                      )}

                      {roleAccess([USER_ROLE.Sp_Standard_User]) && (
                        <SwitchInput
                          label='Show to other workers'
                          checked={watchFormData?.worker_to_worker}
                          handleChange={(checked) =>
                            setValue("worker_to_worker", Boolean(checked))
                          }
                        />
                      )}
                    </div>
                  </div>

                  <button
                    className='hidden sr-only'
                    type='submit'
                    id={`updateParticipantCaseNoteButton`}
                  />
                </form>
              </div>
            </div>
          </div>
        </ModalSkeleton>
      )}
    </>
  );
};

export default EditCaseNoteModal;
