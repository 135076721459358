import { fromJS } from "immutable";
import ACTIONS, { FETCH_STATUS } from "../constants";

const initialState = fromJS({
  loading: false,
  error: "",
  DynamicBreadCrumb: [],
  getFolderListForHRM: [],
  getFileListForHRM: [],
});

export default function cosReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.DYNAMIC_BREADCRUMB_FOR_HRM_CALL: {
      console.log("action", action);
      return state.set("DynamicBreadCrumb", action.payload);
    }

    case ACTIONS.GET_FOLDER_LIST_FOR_HRM.PENDING: {
      return state
        .set("getFolderListForHRMLoading", true)
        .set("getFolderListForHRMError", "")
        .set("getFolderListForHRM", [])
        .set("getFileListForHRM", []);
    }
    case ACTIONS.GET_FOLDER_LIST_FOR_HRM.SUCCESS: {
      // console.log("Data", action.data);
      return state
        .set("getFolderListForHRM", action.data.folder_structure)
        .set("getFileListForHRM", action.data.files_managements)
        .set("getFolderListForHRMLoading", false)
        .set("getFolderListForHRMError", "");
    }
    case ACTIONS.GET_FOLDER_LIST_FOR_HRM.ERROR: {
      return state
        .set("getFolderListForHRM", [])
        .set("getFileListForHRM", [])
        .set("getFolderListForHRMLoading", false)
        .set("getFolderListForHRMError", false);
    }

    case ACTIONS.HRM_FORM_CATEGORIES_LIST.PENDING: {
      return state
        .set("getHrmFormCatsLoader", true)
        .set("getHrmFormCatsError", "");
    }
    case ACTIONS.HRM_FORM_CATEGORIES_LIST.SUCCESS: {
      return state
        .set("hrmFormCategories", action.data)
        .set("getHrmFormCatsLoader", false)
        .set("getHrmFormCatsError", "");
    }
    case ACTIONS.HRM_FORM_CATEGORIES_LIST.ERROR: {
      return state
        .set("getHrmFormCatsLoader", false)
        .set("getHrmFormCatsError", action.error);
    }

    case ACTIONS.HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY.PENDING: {
      return state
        .set("getHrmSubmittedFormListLoader", true)
        .set("getHrmSubmittedFormListError", "");
    }
    case ACTIONS.HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY.SUCCESS: {
      return state
        .set("hrmSubmittedFormsList", action.data)
        .set("getHrmSubmittedFormListLoader", false)
        .set("getHrmSubmittedFormListError", "");
    }
    case ACTIONS.HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY.ERROR: {
      return state
        .set("getHrmSubmittedFormListLoader", false)
        .set("getHrmSubmittedFormListError", action.error);
    }

    default: {
      return state;
    }
  }
}
