import React from "react";
import moment from "moment";
import { BsPlusLg } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import TableHeader from "components/common/Table/TableHeader";
import PopoverContainer from "components/common/Popover/PopoverContainer";
import { truncateTextReporting } from "Utils/utils";

const RiskAssessmentSubTable = (props) => {
  const { item } = props;
  const [expandedRows, setExpandedRows] = React.useState([]);

  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(index)) {
      const indexToRemove = newExpandedRows.indexOf(index);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };

  return (
    <>
      <tr className='text-center even:bg-white odd:bg-gray-50'>
        <td
          className='flex justify-center items-center h-[50px]  cursor-pointer'
          onClick={() => handleRowClick(item.uuid)}
        >
          <div className='flex align-center cursor-pointer '>
            {expandedRows.includes(item.uuid) ? (
              <BiMinus className='mt-1 text-gray-600' />
            ) : (
              <BsPlusLg className='h-3 w-3 mt-1 text-gray-600' />
            )}
          </div>
        </td>

        <td className=' px-3 py-4 text-sm text-gray-500 text-start capitalize'>
          <div
            className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
            style={{
              maxWidth: "100px",
              textOverflow: "ellipsis",
            }}
          >
            {item?.participant_name ? (
              <PopoverContainer
                cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                itemComponent={() => {}}
                toolTip={`${item?.participant_name}`}
              >
                <span>{truncateTextReporting(item?.participant_name)}</span>
              </PopoverContainer>
            ) : (
              "-"
            )}
          </div>
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          <div
            className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
            style={{
              maxWidth: "100px",
              textOverflow: "ellipsis",
            }}
          >
            {item?.title ? (
              <PopoverContainer
                cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                itemComponent={() => {}}
                toolTip={`${item?.title}`}
              >
                <span>{truncateTextReporting(item?.title)}</span>
              </PopoverContainer>
            ) : (
              "-"
            )}
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          <div
            className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
            style={{
              maxWidth: "100px",
              textOverflow: "ellipsis",
            }}
          >
            {item?.created_by ? (
              <PopoverContainer
                cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                itemComponent={() => {}}
                toolTip={`${item?.created_by}`}
              >
                <span>{truncateTextReporting(item?.created_by)}</span>
              </PopoverContainer>
            ) : (
              "-"
            )}
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {item?.created_at || "-"}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          <div
            className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
            style={{
              maxWidth: "100px",
              textOverflow: "ellipsis",
            }}
          >
            {item?.updated_by ? (
              <PopoverContainer
                cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                itemComponent={() => {}}
                toolTip={`${item?.updated_by}`}
              >
                <span>{truncateTextReporting(item?.updated_by)}</span>
              </PopoverContainer>
            ) : (
              "-"
            )}
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {item?.updated_at || "-"}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {item?.expiry_date || "-"}
        </td>
      </tr>

      {expandedRows.includes(item.uuid) && (
        <tr className='text-center w-full'>
          <td colSpan={8} className='px-3 py-4 min-w-full'>
            <table className='min-w-full divide-y divide-gray-300 border'>
              <thead>
                <tr>
                  <TableHeader
                    isSort={false}
                    label={"Risk Assessed Questions"}
                  />
                  <TableHeader isSort={false} label={"Status"} />

                  <TableHeader isSort={false} label={"Risk Rating"} />
                  <TableHeader isSort={false} label={"Risk Owner"} />
                  <TableHeader isSort={false} label={"Mitigation Date"} />
                  <TableHeader isSort={false} label={"Mitigation Strategy"} />
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-whit'>
                {item?.template && item?.template?.length > 0 ? (
                  item?.template?.map((el, index) => (
                    <tr key={index}>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs text-left'>
                        <div
                          className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                          style={{
                            maxWidth: "100px",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {el?.question ? (
                            <PopoverContainer
                              cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                              itemComponent={() => {}}
                              toolTip={`${el?.question}`}
                            >
                              <span>{truncateTextReporting(el?.question)}</span>
                            </PopoverContainer>
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-xs text-left'>
                        <div
                          className={`px-2 py-1 rounded-full ${
                            el?.mitigation_strategy_data
                              ? el?.mitigation_strategy_data
                                  ?.risk_migration_status
                                ? "bg-green-50 text-green-500"
                                : "bg-orange-50 text-orange-500"
                              : "bg-red-50 text-red-500"
                          }`}
                        >
                          {el?.mitigation_strategy_data
                            ? el?.mitigation_strategy_data
                                ?.risk_migration_status
                              ? "Closed"
                              : "Mitigation Strategy"
                            : "Open"}
                        </div>
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-xs text-left'>
                        <div className={`px-2 py-1 rounded-full `}>
                          {el?.risk_rating || "-"}
                        </div>
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs text-left'>
                        {el?.mitigation_strategy_data?.risk_owner || "-"}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs text-left'>
                        {el?.mitigation_strategy_data
                          ?.risk_migration_strategy_date
                          ? moment(
                              el?.mitigation_strategy_data
                                ?.risk_migration_strategy_date
                            ).format("DD/MM/YYYY")
                          : "-"}
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs text-left'>
                        <div
                          className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                          style={{
                            maxWidth: "100px",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {el?.mitigation_strategy_data
                            ?.risk_migration_strategy ? (
                            <PopoverContainer
                              cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                              itemComponent={() => {}}
                              toolTip={`${el?.mitigation_strategy_data?.risk_migration_strategy}`}
                            >
                              <span>
                                {truncateTextReporting(
                                  el?.mitigation_strategy_data
                                    ?.risk_migration_strategy
                                )}
                              </span>
                            </PopoverContainer>
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                      className='py-6 text-sm text-center text-red-600'
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default RiskAssessmentSubTable;
