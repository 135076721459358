import React, { useEffect, useState } from "react";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import PaginationComponent from "../../../../components/common/Pagination/index";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import {
  emptyPaginationObj,
  LOADER_STATUS,
  POLICIES_LIST_LIMIT,
  SORT_TYPE,
  StatusOptions,
} from "../../../../Constants/constant";
import { LoginContext } from "../../../../helpers/LoginContext";
import { getPaginationObj } from "../../../../Utils/utils";
import TeamManagementListTable from "./TeamManagementListTable";

const TeamManagment = (props) => {
  const { getTeamManagmentListCall, teamManagementList, loading } = props;

  const { sideBarPadding, isUserGracePeriodOver } =
    React.useContext(LoginContext);

  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [paginationObj, setPaginationObj] = useState(
    teamManagementList
      ? getPaginationObj(
          teamManagementList.employees,
          teamManagementList.query_params
        )
      : emptyPaginationObj
  );
  const [sortBy, setSortBy] = useState(null);

  useEffect(() => {
    if (!loading && renderLoading === LOADER_STATUS.PENDING) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
    }
  }, [loading]);

  useEffect(() => {
    if (teamManagementList) {
      setPaginationObj(
        getPaginationObj(
          teamManagementList.employees,
          teamManagementList.query_params
        )
      );
    }
  }, [teamManagementList]);

  useEffect(() => {
    if (teamManagementList) {
      setPaginationObj(
        getPaginationObj(
          teamManagementList.employees,
          teamManagementList.query_params
        )
      );
    }
  }, []);

  const fetchFormTemplateList = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: POLICIES_LIST_LIMIT });
    let bodyFilter = filters.hasOwnProperty("filter") ? filters.filter : null;
    if (filters.hasOwnProperty("filter")) {
      delete filters.filter;
    }
    if (filters.status === "both") {
      bodyFilter = null;
    }
    if (renderLoading === LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.PENDING);
    }
    getTeamManagmentListCall({ filters, bodyFilter });
  };

  const checkStatus = (status) => {
    return status === "active" ? true : false;
  };

  const handleFetch = (obj, sortBy) => {
    if (sortBy) {
      Object.assign(obj, {
        sort_field: obj.hasOwnProperty("sort_field")
          ? obj.sort_field
          : paginationObj.sort_field
          ? paginationObj.sort_field
          : null,
        sort_order: obj.hasOwnProperty("sort_order")
          ? obj.sort_order
          : paginationObj.sort_order === SORT_TYPE.DESC
          ? SORT_TYPE.DESC
          : SORT_TYPE.ASC,
      });
    }
    if (paginationObj.page) {
      Object.assign(obj, { page: obj.page ? obj.page : paginationObj.page });
    }
    if (paginationObj.filter) {
      !paginationObj.filter.hasOwnProperty("status")
        ? Object.assign(obj, {
            filter: paginationObj.filter,
          })
        : Object.assign(obj, {
            filter: {
              status: paginationObj.filter.status.map((page) => page),
            },
          });
    }
    if (
      !sortBy &&
      paginationObj.sort_field &&
      !obj.hasOwnProperty("sort_field")
    ) {
      Object.assign(obj, {
        sort_field: sortBy,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [checkStatus(obj.status)],
        },
      });
    }
    fetchFormTemplateList({ ...obj });
  };

  return (
    <div className='min-h-full pb-10'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        } flex flex-col`}
      >
        <main className='flex-1'>
          {teamManagementList && teamManagementList === null ? (
            <div
              className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
            >
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            <TeamManagementListTable
              templateList={
                teamManagementList &&
                teamManagementList?.employees &&
                teamManagementList?.employees?.data &&
                teamManagementList?.employees?.data?.length > 0
                  ? teamManagementList?.employees?.data
                  : []
              }
              isLoading={loading}
              queryParams={paginationObj}
              formCategoriesList={StatusOptions}
              handleFetch={handleFetch}
              getSortBy={(e) => setSortBy(e)}
              renderLoading={renderLoading}
              isUserGracePeriodOver={isUserGracePeriodOver}
            />
          )}
        </main>
        <div>
          {teamManagementList && teamManagementList !== null && (
            <div className='bg-white max-w-7xl mx-auto sm:px-6 lg:px-8 py-2'>
              <PaginationComponent
                page={paginationObj.page}
                activeListLength={
                  teamManagementList &&
                  teamManagementList?.employees?.data?.length > 0
                    ? teamManagementList?.employees?.data
                    : []
                }
                limit={POLICIES_LIST_LIMIT}
                totalList={paginationObj.total}
                handlePagination={(e) => {
                  const obj = paginationObj;
                  obj.page = e;
                  setPaginationObj({ ...obj });
                  handleFetch({}, sortBy);
                }}
                totalPages={paginationObj.totalPages}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamManagment;
