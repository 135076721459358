import React from "react";
import { useForm } from "react-hook-form";

function Modal(props) {
  const { showModal, closeModalHandler, errorStyles, datasubmitHandler } =
    props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    datasubmitHandler(data);
    closeModalHandler();
  };
  return (
    <div>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl max-h-screen overflow-y-auto custom-scroll">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Add other Reports</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={closeModalHandler}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="relative p-6 flex-auto space-y-4">
                    <div className="flex flex-col">
                      <label htmlFor="" className="text-gray-500">
                        Name of Person
                      </label>
                      <input
                        {...register("personName", {
                          required: true,
                          minLength: 6,
                          maxLength: 20,
                        })}
                        name="personName"
                        type="text"
                        className="bg-gray-100 rounded px-4 py-2"
                      />
                      {errors?.personName?.type === "required" && (
                        <span className="text-sm text-red-500">
                          This field is required!
                        </span>
                      )}
                      {errors?.personName?.type === "minLength" && (
                        <span className="text-sm text-red-500">
                          Person Name must contain atleast 6 characters
                        </span>
                      )}
                      {errors?.personName?.type === "maxLength" && (
                        <span className="text-sm text-red-500">
                          Person Name must not exceed 20 characters
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <label htmlFor="" className="text-gray-500">
                        Status
                      </label>
                      <input
                        {...register("status", {
                          required: true,
                          maxLength: 254,
                        })}
                        name="status"
                        type="text"
                        className="bg-gray-100 rounded px-4 py-2"
                      />
                      {errors?.status?.type === "required" && (
                        <span className="text-sm text-red-500">
                          This field is required!
                        </span>
                      )}
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={closeModalHandler}
                    >
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </div>
  );
}

export default Modal;
