import React, { useState } from "react";

import InputField from "components/common/Input/Input";
import { checkPasswordValidity, hasBlankSpaces } from "Utils/utils";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const PasswordFields = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  errors,
  setErrors,
}) => {


  const [passwordType, setPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false);

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({ ...errors, [e.target.name]: `${name} is Required` });
    } else if (
      (name === "Password" || name === "Confirm Password") &&
      value &&
      value.length > 0
    ) {
      if (checkPasswordValidity(password) !== null) {
        setErrors({
          ...errors,
          password: checkPasswordValidity(password),
        });
      } else if (password !== confirmPassword) {
        setErrors({
          ...errors,
          password: "Password does not match",
          confirmPassword: "Confirm Password does not match",
        });
      } else {
        setErrors({
          ...errors,
          password: null,
          confirmPassword: null,
        });
      }
    } else if (errors[e.target.name] && value.length > 0) {
      setErrors({ ...errors, [e.target.name]: null });
    }
  };
  const getError = (value) => {
    if (!value) return "";
    return errors[value] && errors[value].length > 0 ? errors[value] : "";
    // return errors[value] && errors[value].length > 0
    //   ? "The Field is required!"
    //   : "";
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <InputField
          label="Password"
          type={passwordType ? "text" : "password"}
          handleChange={setPassword}
          value={password}
          name="Password"
          blurHandler={handleBlur}
          ErrorMessage={getError("password")}
          icon={!passwordType ? <AiFillEyeInvisible /> : <AiFillEye />}
          handleClickIcon={() => setPasswordType(!passwordType)}
          redstar
        />
      </div>
      <div>
        <InputField
          label="Confirm Password"
          type={confirmPasswordType ? "text" : "password"}
          handleChange={setConfirmPassword}
          value={confirmPassword}
          name="Confirm Password"
          blurHandler={handleBlur}
          ErrorMessage={getError("confirmPassword")}
          icon={!confirmPasswordType ? <AiFillEyeInvisible /> : <AiFillEye />}
          handleClickIcon={() => setConfirmPasswordType(!confirmPasswordType)}
          redstar
        />
      </div>
    </div>
  );
};

export default PasswordFields;
