import { fromJS } from "immutable";
import ACTIONS from "../constants";

const initialState = fromJS({
  user: {},
  loading: false,
  authError: "",
  error: "",
  logoutLoader: false,
  confirmOtpSuccess: false,
  selectedTabData: null,
  payrollAuth: null,
  defaultRoleAndPermissions: null,
  payment_setting: null,
});

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.RESEND_OTP.PENDING: {
      return state.set("reSendOTPLoading", true).set("errorResend", "");
    }
    case ACTIONS.RESEND_OTP.SUCCESS: {
      return state.set("reSendOTPLoading", false).set("errorResend", "");
    }
    case ACTIONS.RESEND_OTP.ERROR: {
      return state.set("reSendOTPLoading", false).set("errorResend", "");
    }
    case ACTIONS.SEND_OTP_FOR_CONFIRMATION.PENDING: {
      return state
        .set("sendOTPLoading", true)
        .set("confirmOtpSuccess", false)
        .set("confirmOtpError", null);
    }
    case ACTIONS.SEND_OTP_FOR_CONFIRMATION.SUCCESS: {
      return state
        .set("sendOTPForConfirmationLoading", false)
        .set("error", "")
        .set("confirmOtpError", null)
        .set("confirmOtpSuccess", true);
    }
    case ACTIONS.SEND_OTP_FOR_CONFIRMATION.ERROR: {
      return state
        .set("confirmOtpSuccess", false)
        .set("sendOTPLoading", false)
        .set("confirmOtpError", action.error);
    }

    case ACTIONS.SEND_OTP.PENDING: {
      return state.set("sendOTPLoading", true).set("sendOTPSuccess", false);
    }
    case ACTIONS.SEND_OTP.SUCCESS: {
      return state
        .set("sendOTPLoading", false)
        .set("error", "")
        .set("sendOTPSuccess", true);
    }
    case ACTIONS.SEND_OTP.ERROR: {
      return state
        .set("sendOTPSuccess", false)
        .set("sendOTPLoading", false)
        .set("error");
    }

    case ACTIONS.LOGIN.PENDING: {
      return state.set("loading", true);
    }
    case ACTIONS.LOGIN.SUCCESS: {
      const payment_setting = action.data?.payment_setting || null;
      return state
        .set("loading", false)
        .set("error", "")
        .set("user", action.data)
        .set("payment_setting", payment_setting);
    }
    case ACTIONS.LOGIN.ERROR: {
      return state
        .set("loading", false)
        .set("authError", JSON.parse(action.error.message).detail);
    }
    case ACTIONS.LOGOUT.PENDING: {
      return state.set("logoutLoader", true);
    }
    case ACTIONS.LOGOUT.SUCCESS: {
      return state.set("logoutLoader", false).set("user", null);
    }
    case ACTIONS.LOGOUT.ERROR: {
      return state.set("logoutLoader", false);
    }
    case ACTIONS.STORE_CURRENT_OPEN_TAB: {
      return state.set("selectedTabData", action.payload);
    }
    case ACTIONS.UPDATE_AUTH_USER: {
      if (action.data) {
        return state.set("user", action.data);
      }
      return state;
    }
    case ACTIONS.AUTHENTICATED_USER: {
      // let payment_setting = state.get("payment_setting");
      // if (!payment_setting || payment_setting === null) {
      //   payment_setting = action.data?.payment_setting || null;
      // }
      return state
        .set("user", { ...action.data })
        .set("payment_setting", action.data?.payment_setting);
    }

    case ACTIONS.RESEND_VERIFICATION_CODE.PENDING: {
      return state
        .set("resendEmailCodeLoader", true)
        .set("resendEmailCodeError", "");
    }
    case ACTIONS.RESEND_VERIFICATION_CODE.SUCCESS: {
      return state
        .set("resendEmailCodeLoader", false)
        .set("resendEmailCodeError", "");
    }
    case ACTIONS.RESEND_VERIFICATION_CODE.ERROR: {
      return state
        .set("resendEmailCodeLoader", false)
        .set("resendEmailCodeError", action);
    }
    case ACTIONS.HAS_UNSEEN_NOTIFICATION: {
      // Assuming `action.data` contains the updated `has_unseen_notification` value
      if (action.payload !== undefined) {
        // Use setIn to update the specific value in the user object
        return state.setIn(["user", "has_unseen_notification"], action.payload);
      }
      return state;
    }

    case ACTIONS.PAYROLL_AUTHENTICATION_UPDATE_LOCALLY: {
      return state.set("payrollAuth", action.flag);
    }

    case ACTIONS.ROLE_AND_PERMISSION_CREATE_OR_UPDATE.PENDING: {
      return state
        .set("roleAndPermissionsCreateOrUpdateLoader", true)
        .set("roleAndPermissionsCreateOrUpdateError", "");
    }
    case ACTIONS.ROLE_AND_PERMISSION_CREATE_OR_UPDATE.SUCCESS: {
      const prevList = state.get("roleAndPermissions") || [];
      if (action.isUpdateCall) {
        const index = prevList.findIndex((x) => x.id === action.data.id);
        prevList.splice(index, 1, { ...action.data });
      } else {
        prevList.unshift(action.data);
      }

      return state
        .set("roleAndPermissionsCreateOrUpdateLoader", false)
        .set("roleAndPermissionsCreateOrUpdateError", "")
        .set("roleAndPermissions", [...prevList]);
    }
    case ACTIONS.ROLE_AND_PERMISSION_CREATE_OR_UPDATE.ERROR: {
      return state
        .set("roleAndPermissionsCreateOrUpdateLoader", false)
        .set("roleAndPermissionsCreateOrUpdateError", action);
    }

    case ACTIONS.RESET_DEFAULT_ROLE_AND_PERMISSION_LOCALLY: {
      return state
        .set("defaultRoleAndPerLoader", false)
        .set("defaultRoleAndPerError", "")
        .set("defaultRoleAndPermissions", null);
    }

    case ACTIONS.DEFAULT_ROLE_AND_PERMISSION.PENDING: {
      return state
        .set("defaultRoleAndPerLoader", true)
        .set("defaultRoleAndPerError", "")
        .set("defaultRoleAndPermissions", null);
    }
    case ACTIONS.DEFAULT_ROLE_AND_PERMISSION.SUCCESS: {
      return state
        .set("defaultRoleAndPerLoader", false)
        .set("defaultRoleAndPerError", "")
        .set("defaultRoleAndPermissions", action.data);
    }
    case ACTIONS.DEFAULT_ROLE_AND_PERMISSION.ERROR: {
      return state
        .set("defaultRoleAndPerLoader", false)
        .set("defaultRoleAndPerError", action);
    }

    case ACTIONS.ROLE_AND_PERMISSION_LISTED.PENDING: {
      return state
        .set("listingRoleAndPerLoader", true)
        .set("listingRoleAndPerError", "");
    }
    case ACTIONS.ROLE_AND_PERMISSION_LISTED.SUCCESS: {
      return state
        .set("listingRoleAndPerLoader", false)
        .set("listingRoleAndPerError", "")
        .set("roleAndPermissions", action.data);
    }
    case ACTIONS.ROLE_AND_PERMISSION_LISTED.ERROR: {
      return state
        .set("listingRoleAndPerLoader", false)
        .set("listingRoleAndPerError", action);
    }
    case ACTIONS.UPDATE_PAYMENT_SETTING_LOCALLY: {
      return state.set("payment_setting", action.data);
    }
    default: {
      return state;
    }
  }
}
