import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";

import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {

  emptyPaginationObj,
  REPORTING_LIMIT,

  SORT_TYPE,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import {
  URL_REPORTING_GET_SP_USERS_LIST,

  URL_REPORTING_SUMMARY_INVOICING_REPORTS,
} from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";

import { toast } from "react-toastify";

import { addMonths, exportTOExcel, truncateTextReporting } from "Utils/utils";
import PopoverContainer from "components/common/Popover/PopoverContainer";

const SummaryInvoiceReport = ({ getParticipantList, spParticipants }) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const [selectedFromDate, setSelectedFromDate] = useState(null);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
  }, []);

  const [participantList, setParticipant] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  const [userNameList, setUserNameList] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState(null);

  useEffect(() => {
    if (!isInitialRender) {
      fetchListReports(false, null, {
        page,
        sort_field: sortBy,
        sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
      });
    }
  }, [page]);

  const handleSearch = () => {
    setPage(1);

    const updatedPaginationObj = { ...paginationObj, page: 1 };
    setPaginationObj(updatedPaginationObj);

    fetchListReports(false, null, {
      page: 1,
      sort_field: sortBy,
      sort_order: sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };
  useEffect(() => {
    apiGetRequest(`${URL_REPORTING_GET_SP_USERS_LIST}?for=only_manager`)
      .then((res) => {
        if (res.status === 200) {
          const userNames = res.data?.data || [];
          const users =
            userNames.length > 0
              ? userNames.map((user) => ({
                  label: user?.name,
                  value: user?.id,
                }))
              : [];
          const sortedData = users?.sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          );
          setUserNameList(sortedData);

          setUserNameList((prevParticipantList) => [
            { label: "All", value: "all" },
            ...prevParticipantList,
          ]);
          // setUserName(users);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);
  useEffect(() => {
    const updateLists = async () => {
      const participants =
        spParticipants?.participant?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedParticipants = participants.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setParticipant(sortedParticipants);

      setParticipant((prevParticipantList) => [
        { label: "All", value: "all" },
        ...prevParticipantList,
      ]);
    };

    updateLists();
  }, [spParticipants]);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = {}
  ) => {
    if (!fromDate && !toDate) {
      toast("Select date range to get data");
      return;
    }
    const currentPage= filters?.page || page;
    setLoader(true);
    let url = `${URL_REPORTING_SUMMARY_INVOICING_REPORTS}?per_page=25&page=${currentPage}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_SUMMARY_INVOICING_REPORTS}?per_page=25&page=${currentPage}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",

      service_date: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
      created_by: isClearAll
        ? "all"
        : selectedUserName
        ? selectedUserName.value
        : "all",
    };
    if (report) {
      payload.report = report === "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            if (report === "xlsx") {
              const data = res?.data?.data;
             
              exportTOExcelFile(data);
            } else {
              window.open(`${res?.data?.data}`, "_blank");
            }
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.invoices?.total,
              totalPages: Math.ceil(
                res?.data?.data?.invoices?.total /
                  res?.data?.data?.invoices?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        toast.error(`${error}`);
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);
  };

  const exportTOExcelFile = (reportData) => {
    const transformedData =
      reportData &&
      reportData.invoices &&
      reportData?.invoices?.map((item) => {
        return {
          Invoice_Number: item?.invoice_number || "N/A",
          Created_By: item?.created_by || "N/A",
          Participant: item?.participant || "N/A",
          Created_Date: item?.created_at
            ? moment(item?.created_at,"DD-MM-YYYY").format("DD/MM/YYYY")
            : "N/A",
          Total_KMs: item?.total_km || "N/A",
          Total_Hours: item?.total_hours || "N/A",
          Total_Amount: item?.total_amount || "N/A",
        };
      });
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='Summary Invoicing Reports' />
          <div className='max-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.SUMMARY_INVOICING_REPORTS
                )}
              />
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by participant name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Created By
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={userNameList || []}
                          handleChange={(e) => {
                            setSelectedUserName(e);
                          }}
                          selectedOption={selectedUserName}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant Name
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Created Date Range(Max 3 Months)
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          // isDisabled={clientsListLoader}
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                            if (obj && obj.fromDate && obj.toDate) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addMonths(selectedFromDate, 3)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        // style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={handleSearch}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setSelectedUserName(null);
                            setFromDate(null);
                            setToDate(null);
                            setSearch(null);
                            setReports([]);
                            setSelectedFromDate(null);
                            setSelectedParticipant(null);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='min-w-full  flex justify-center overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader isSort={false} label={"Invoice Number"} />

                      <TableHeader isSort={false} label={"Created By"} />
                      <TableHeader isSort={false} label={"Participant Name"} />
                      <TableHeader
                        sortName='created_at'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Created Date"}
                      />
                      <TableHeader isSort={false} label={"Total KMs"} />
                      <TableHeader isSort={false} label={"Total Hours"} />
                      <TableHeader 
                      sortName='total_amount'
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      label={"Total Amount"} />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={13} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen  items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.invoices?.data?.length > 0 ? (
                      reportList?.invoices?.data?.map((shift, index) => (
                        <tr
                          key={index}
                          className='even:bg-white odd:bg-gray-50'
                        >
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            {shift?.invoice_number || "N/A"}
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            <div
                              className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                              style={{
                                maxWidth: "100px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {shift?.created_by ? (
                                <PopoverContainer
                                  cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                  itemComponent={() => {}}
                                  toolTip={`${shift?.created_by}`}
                                >
                                  <span>
                                    {truncateTextReporting(shift?.created_by)}
                                  </span>
                                </PopoverContainer>
                              ) : (
                                "-"
                              )}
                            </div>
                          </td>
                          <td className='py-[11px] px-3 text-left  text-sm text-gray-500'>
                            <div
                              className=' font-medium whitespace-nowrap overflow-hidden text-gray-900 capitalize'
                              style={{
                                maxWidth: "100px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {shift?.participant ? (
                                <PopoverContainer
                                  cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                  itemComponent={() => {}}
                                  toolTip={`${shift?.participant}`}
                                >
                                  <span>
                                    {truncateTextReporting(shift?.participant)}
                                  </span>
                                </PopoverContainer>
                              ) : (
                                "-"
                              )}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            {shift?.created_at ? moment(shift?.created_at,"DD-MM-YYYY").format("DD/MM/YYYY") : "-"}
                          
                          </td>
                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500'>
                            {shift?.total_km || "-"}
                          </td>

                          <td className='px-3 text-left py-[11px] text-sm text-gray-500'>
                            {shift.total_hours || "-"}
                          </td>

                          <td className='whitespace-nowrap px-3 text-left py-4 text-sm text-gray-500 capitalize'>
                            {shift?.total_amount
                              ? `$${shift?.total_amount}`
                              : "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={13}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {reportList && reportList?.invoices?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.invoices?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                      fetchListReports(false, null, {
                        page: e,
                        sort_field: sortBy,
                        sort_order: sortDescending
                          ? SORT_TYPE.DESC
                          : SORT_TYPE.ASC,
                      });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SummaryInvoiceReport;
