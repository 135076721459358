import { connect } from "react-redux";
import Schedule from "./Schedule";
import {
  fetchWorkerAndParticipantListRequest,
  sortCalendarShiftsItem,
  calendarDateChangeLocally,
  workerAndParticipantShiftCopyRequest,
  recurringMessageModal,
} from "store/actions/rostering.actions";
import {
  getCopyWorkerShiftError,
  getCopyWorkerShiftLoader,
  getIsSortCalendarShiftsItem,
  getRecurringMessage,
  getWorkerAndParticipantOptions,
  getWorkerAndPartsListLoader,
  showRecurringModal,
} from "store/selector/rostering.selector";
import { spRosteringSettingsRequest } from "store/actions/sp.actions";
import {
  getSpRosteringSetting,
  getSpRosteringSettingLoader,
} from "store/selector/sp.selector";

const mapDispatchToProps = {
  getWorkerAndParticipantList: fetchWorkerAndParticipantListRequest,
  sortCalendarShiftsItem,
  calendarDateChangeLocally,
  workerAndParticipantShiftCopyRequest,
  recurringMessageModal,
  spRosteringSettingsRequest,
};

const mapStateToProps = (state) => {
  const workerAndParticipantOptions = getWorkerAndParticipantOptions(state);
  const workerAndPartsListLoader = getWorkerAndPartsListLoader(state);
  const isSortCalendarShiftsItem = getIsSortCalendarShiftsItem(state);
  const copyWorkerShiftLoader = getCopyWorkerShiftLoader(state);
  const copyWorkerShiftError = getCopyWorkerShiftError(state);
  const isRecurringModalShow = showRecurringModal(state);
  const recurringMessages = getRecurringMessage(state);
  const spRosteringSettingLoader = getSpRosteringSettingLoader(state);
  const spRosteringSetting = getSpRosteringSetting(state);

  return {
    workerAndParticipantOptions,
    workerAndPartsListLoader,
    isSortCalendarShiftsItem,
    copyWorkerShiftLoader,
    copyWorkerShiftError,
    isRecurringModalShow,
    recurringMessages,
    spRosteringSettingLoader,
    spRosteringSetting,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
