import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { changeDateToYearMonthDay } from "../../../Utils/utils";

const DateRangePickerNew = (props) => {
  const {
    isSingleDate = false,
    singleSelectedDate,
    handleCancel,
    handleChange,
    currentDate,
    fromDate,
    selected,
    toDate,
    isDisabled = false,
    showCalendar = false,
    isClearable = true,
    cssClass="",
    maxDate,
    setSelectedFromDate=null,
    setIsDisableSearch
  } = props;

  useEffect(() => {
    setDateRange([
      fromDate ? new Date(fromDate) : null,
      toDate ? new Date(toDate) : null,
    ]);
  }, [fromDate, toDate]);

  const [dateRange, setDateRange] = useState([null, null]);

  const [startDate, endDate] = dateRange;

  if (isSingleDate) {
    return (
      <DatePicker
        dateFormat={"dd/MM/yyyy"}
        disabled={isDisabled}
        selectsRange={false}
        className={`did-floating-input capitalize ${cssClass}`}
        selected={singleSelectedDate}
        onChange={(selectedDates) => {
          if (selectedDates) {
            handleChange(changeDateToYearMonthDay(selectedDates));
          } else {
            handleCancel();
          }
        }}
        isClearable={true}
        placeholderText={"DD/MM/YYYY"}
        maxDate={maxDate}
      />

    );
  }

  return (
    <DatePicker
      inline={showCalendar}
      dateFormat={"dd/MM/yyyy"}
      disabled={isDisabled}
      className="did-floating-input capitalize"
      selectsRange={true}
      selected={selected}
      startDate={startDate}
      endDate={endDate}
      onChange={(selectedDates) => {
        if(setSelectedFromDate && selectedDates[0]){
          setSelectedFromDate(selectedDates[0])
        }else if(setSelectedFromDate && !selectedDates[0]){
          setSelectedFromDate(null)
        }
        if (
          selectedDates &&
          selectedDates.length > 0 &&
          !selectedDates[0] &&
          !selectedDates[1]
        ) {
          handleCancel();
          setDateRange([null, null]);
        } else if (
          selectedDates &&
          selectedDates.length > 0 
          &&
          selectedDates[0] &&
          selectedDates[1]
        ) {
          setDateRange(selectedDates);
          if(setIsDisableSearch){
            setIsDisableSearch(false)
          }
          handleChange({
            fromDate: changeDateToYearMonthDay(selectedDates[0]),
            toDate: changeDateToYearMonthDay(selectedDates[1]),
          });
        } else {
          setDateRange(selectedDates);
          if(setIsDisableSearch){
            setIsDisableSearch(true)
          }
        }
      }}
      isClearable={isClearable}
      placeholderText={"DD/MM/YYYY  to  DD/MM/YYYY "}
      maxDate={maxDate}
    />
  );
};

export default DateRangePickerNew;
