import { connect } from "react-redux";
import {
  deliveredServiceCreateRequest,
  deliveredServiceFetchRequest,
  fetchServiceSupportsForDeliveredServiceRequest,
} from "../../../../store/actions/cos.actions";
import {
  getDeliveredServiceFetchLoader,
  getDeliveredServices,
  getDeliveredServiceStoreError,
  getDeliveredServiceStoreLoader,
  getSelectedFunding,
  getServiceSupportItems,
  grtServiceSupportItemsForDeliveredLoading,
} from "../../../../store/selector/cos.selector";
import { getSelectedParticipant } from "../../../../store/selector/participant.selector";
import DeliveredService from "./DeliveredService";

const mapDispatchToProps = {
  getServiceSupportsItem: fetchServiceSupportsForDeliveredServiceRequest,
  createDeliveredService: deliveredServiceCreateRequest,
  getDeliveredServices: deliveredServiceFetchRequest,
};

const mapStateToProps = (state) => {
  const selectedFunding = getSelectedFunding(state);
  const participant = getSelectedParticipant(state);
  const serviceSupportItems = getServiceSupportItems(state);
  const storeDeliveredServiceLoader = getDeliveredServiceStoreLoader(state);
  const storeDeliveredServiceError = getDeliveredServiceStoreError(state);
  const fetchServicesLoader = grtServiceSupportItemsForDeliveredLoading(state);
  const fetchDeliveredServiceLoader = getDeliveredServiceFetchLoader(state);
  const deliveredServices = getDeliveredServices(state);

  return {
    selectedFunding,
    participant,
    serviceSupportItems,
    storeDeliveredServiceLoader,
    storeDeliveredServiceError,
    fetchServicesLoader,
    fetchDeliveredServiceLoader,
    deliveredServices,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveredService);
