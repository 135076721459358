import { connect } from "react-redux";
import {
  getCreatePlanBudgetError,
  getCreatePlanBudgetLoader,
  getFetchPlanBudgetLoader,
  getPlanBudgets,
  getPlanCatagories,
  // getRemovePlanBudgetLoader,
  getSelectedFunding,
  getServiceCatalogStates,
} from "../../../../store/selector/cos.selector";
import {
  getPlanCategoriesRequest,
  getCatalogServicesSubCategoryRequest,
  createPlanBudgetRequest,
  getPlanBudgetListRequest,
  planBudgetRemoved,
} from "../../../../store/actions/cos.actions";
import PlanBudget from "./PlanBudget";
import { getSelectedParticipant } from "../../../../store/selector/participant.selector";

const mapDispatchToProps = {
  getPlanCatagories: getPlanCategoriesRequest,
  getCatalogServicesSubCategory: getCatalogServicesSubCategoryRequest,
  createPlanBudget: createPlanBudgetRequest,
  getPlanBudgetList: getPlanBudgetListRequest,
  planBudgetRemoved,
};

const mapStateToProps = (state) => {
  const PlanCatagories = getPlanCatagories(state);
  const selectedFunding = getSelectedFunding(state);
  const participant = getSelectedParticipant(state);
  const catalogStates = getServiceCatalogStates(state);
  const storePlanBudgetLoading = getCreatePlanBudgetLoader(state);
  const planBudgets = getPlanBudgets(state);
  const fetchPlanBudgetLoader = getFetchPlanBudgetLoader(state);
  const storePlanBudgetError = getCreatePlanBudgetError(state);

  return {
    PlanCatagories,
    selectedFunding,
    participant,
    catalogStates,
    storePlanBudgetLoading,
    planBudgets,
    fetchPlanBudgetLoader,
    storePlanBudgetError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanBudget);
