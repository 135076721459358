import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "../../../../components/common/Button/CustomButton";
import { LOADER_STATUS } from "../../../../Constants/constant";
import PlanBudgetModal from "./PlanBudgetModel";
import PlanBudgetTable from "./PlanBudgetTable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PDFModalSkeleton from "../../../../components/Modals/PdfModal/PdfModal";
import PlanBudgetPdfView from "./PlanBudgetPdfView";
// import ModalSkeleton from "components/Modals/ModalSkeleton";

const PlanBudget = (props) => {
  const {
    PlanCatagories,
    getPlanCatagories,
    selectedFunding,
    participant,
    getCatalogServicesSubCategory,
    catalogStates,
    createPlanBudget,
    storePlanBudgetLoading,
    getPlanBudgetList,
    planBudgets,
    fetchPlanBudgetLoader,
    planBudgetRemoved,
    storePlanBudgetError,
  } = props;

  // const [PlanCatagoriesList, setPlanCatagoriesList] = useState(PlanCatagories);
  const [catalogState, setCatalogState] = useState(null);
  const [editplanBudget, setEditPlanBudget] = useState(null);
  const [exportLoading, setExportLoading] = useState(LOADER_STATUS.NA);
  const [dataForPrint, setDataForPrint] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const printRef = React.useRef();

  useEffect(() => {
    if (editplanBudget) {
      setOpenModal(true);
    }
  }, [editplanBudget]);

  useEffect(() => {
    // console.log("participant", participant);
    if (participant && participant.state_id && catalogStates) {
      const stateObj = catalogStates.find(
        (x) => x.value === participant.state_id
      );
      setCatalogState({ ...stateObj });
    }
  }, [participant, catalogStates]);

  useEffect(() => {
    if (!catalogStates) {
      getCatalogServicesSubCategory({ isService: false });
    }
    if (selectedFunding && participant) {
      getPlanCatagories({
        funding_id: selectedFunding.uuid,
        pt_id: participant.uuid,
      });
    }
  }, []);

  const handlePrint = () => {
    setExportLoading(LOADER_STATUS.PENDING);
    const element = printRef.current;

    html2canvas(element)
      .then(async (canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const imgData = canvas.toDataURL("img/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("download.pdf");
        setExportLoading(LOADER_STATUS.COMPLATE);
        setDataForPrint(null);
      })
      .catch((e) => {
        console.log("error", e);
        setExportLoading(LOADER_STATUS.COMPLATE);
        setDataForPrint(null);
      });
  };

  return (
    <div className='max-w-7xl mx-auto py-3 bg-white'>
      <div>
        <div className='flex justify-between items-center px-6 bg-gray-25 border md:rounded-t-lg font-bold text-lg text-gray-900 py-2'>
          <div>Plan Budget</div>
          <div className='py-2 flex justify-end items-center'>
            <CustomButton
              label='Plan Budget'
              cssClass='border-none'
              clickHandler={() => setOpenModal(true)}
              Icon={<BiPlus />}
            />
          </div>
        </div>

        <div>
          <PlanBudgetTable
            exportLoading={exportLoading}
            getPlanBudgetList={getPlanBudgetList}
            funding={selectedFunding}
            participant={participant}
            planBudgets={planBudgets}
            fetchLoader={fetchPlanBudgetLoader}
            planBudgetRemoved={planBudgetRemoved}
            setEditPlanBudget={setEditPlanBudget}
            handlePrint={(data) =>
              setDataForPrint({ ...data, selectedFunding, participant })
            }
            selectedData={dataForPrint}
          />
        </div>
      </div>
      {openModal && (
        <PlanBudgetModal
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
            setEditPlanBudget(null);
          }}
          PlanCatagories={PlanCatagories}
          funding={selectedFunding}
          catalogState={catalogState}
          createPlanBudget={createPlanBudget}
          storePlanBudgetLoading={storePlanBudgetLoading}
          storePlanBudgetError={storePlanBudgetError}
          participant={participant}
          editplanBudget={editplanBudget}
        />
      )}
      {dataForPrint && (
        <PDFModalSkeleton
          dataForPrint={dataForPrint}
          printRef={printRef}
          handlePrint={handlePrint}
        >
          <PlanBudgetPdfView data={dataForPrint} />
        </PDFModalSkeleton>
      )}

      {/* <ModalSkeleton
        isOpen={dataForPrint ? true : false}
        closeModal={() => setDataForPrint(null)}
        cssClass="w-[100%]"
      >
        <PlanBudgetPdfView data={dataForPrint} />
      </ModalSkeleton> */}
    </div>
  );
};
export default PlanBudget;
