import React from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { HiOutlineChevronDoubleLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import comingSoonImage from "assets/images/coming-soon.png";

const ComingSoonPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center inline-flex">
            {/* <Link to="/" className="inline-flex"> */}
            <img
              src={comingSoonImage}
              alt="coming_soon_image"
              className="h-20 w-20"
            />

            {/* </Link> */}
          </div>
          <div className="py-8">
            <div className="text-center">
              {/* <p className="text-sm font-semibold text-gray-400 uppercase tracking-wide">
                Page Error
              </p> */}
              <h1 className="mt-2 text-4xl font-extrabold text-blue-dark tracking-tight sm:text-5xl">
                Coming Soon
              </h1>
              <p className="mt-2 text-base text-gray-400">
                This Site is Under Construction
              </p>
              <div className="mt-6 flex justify-center">
                <button
                  className="text-base font-medium text-blue-dark hover:underline flex items-center space-x-4 justify-center"
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                  }}
                >
                  <HiOutlineChevronDoubleLeft />
                  <span>Go Back to login</span>
                </button>
              </div>
            </div>
          </div>
        </main>
        <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <p className="text-sm font-medium text-blue-dark">
              &copy; all rights are reserved by Vertex
            </p>
          </nav>
        </footer>
      </div>
    </>
  );
};

export default ComingSoonPage;
