import React from "react";
import {
  baseURLImage,
  capitalizeFirstLetter,
  classNames,
  getNameProfile,
} from "../../../Utils/utils";
import PopoverStickOnHover from "../Popover/PopoverStick";
import PopoverContainer from "../Popover/PopoverContainer";

const AvatarGroup = (props) => {
  const {
    arrayData,
    type,
    cssClass,
    maxLength = 0,
    isForRostering = false,
    isGroupShift = [],
    popoverCss,
    styles = {},
  } = props;

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const popOverContent = (data) => {
    return (
      <div
        className={classNames(
          "flex bg-white shadow-md rounded-md border border-1 border-[#F2F4F7] p-2 w-full",
          popoverCss
        )}
      >
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <PopoverContainer
              cssClass={`${cssClass} text-sm bg-gradient ml-2 p-1 rounded-md text-white`}
              itemComponent={() => {}}
              toolTip={`${capitalizeFirstLetter(
                item?.first_name
              )} ${capitalizeFirstLetter(item?.last_name)}`}
            >
              <img
                onError={(e) =>
                  handleImageError(e, item?.first_name, item?.last_name)
                }
                key={index}
                className='relative z-10 inline-block h-6 w-6 rounded-full ring-2 ring-white'
                src={
                  item?.photo || item?.profile_picture
                    ? baseURLImage(
                        item?.photo ? item?.photo : item?.profile_picture
                      )
                    : getNameProfile(item?.first_name, item?.last_name || " ")
                }
                alt='avatar'
              />
            </PopoverContainer>
          ))}
      </div>
    );
  };

  return (
    <div className='flex justify-start'>
      <div
        style={{ ...styles }}
        className={`mt-3 -space-x-1 relative z-0 overflow-hidden flex ${cssClass}`}
      >
        {arrayData &&
        arrayData.length > 0 &&
        maxLength !== 0 &&
        arrayData.length >= maxLength ? (
          <>
            {arrayData.slice(0, maxLength).map((item, index) => (
              <PopoverContainer
                cssClass={`${cssClass} text-sm bg-gradient ml-2 p-1 rounded-md text-white`}
                itemComponent={() => {}}
                toolTip={`${capitalizeFirstLetter(
                  item?.first_name
                )} ${capitalizeFirstLetter(item?.last_name)}`}
              >
                <img
                  onError={(e) =>
                    handleImageError(e, item?.first_name, item?.last_name)
                  }
                  key={index}
                  className={`relative z-10 inline-block ${
                    isForRostering ? "w-12 h-12" : "h-6 w-6"
                  } rounded-full ring-2 ring-white`}
                  src={
                    item?.photo || item?.profile_picture
                      ? baseURLImage(
                          item?.photo ? item?.photo : item?.profile_picture
                        )
                      : getNameProfile(item?.first_name, item?.last_name || " ")
                  }
                  alt='avatar'
                />
              </PopoverContainer>
            ))}
            {arrayData && arrayData.length > maxLength && (
              <PopoverStickOnHover
                item={popOverContent(
                  arrayData.slice(maxLength, arrayData.length)
                )}
              >
                <img
                  title={`${arrayData.length - maxLength}   ${
                    type
                      ? type === "worker"
                        ? "Workers"
                        : "Support Coordinators"
                      : ""
                  }`}
                  className='relative z-10 inline-block h-3 w-3 rounded-full ring-2 ring-white cursor-pointer'
                  src={getNameProfile(`${arrayData.length - maxLength}`, "+")}
                  alt='avatar'
                />
              </PopoverStickOnHover>
            )}
          </>
        ) : arrayData && arrayData?.length > 0 ? (
          arrayData?.map((item, index) => (
            <>
              <PopoverContainer
                cssClass={`${cssClass} text-sm bg-gradient ml-2 p-1 rounded-md text-white`}
                itemComponent={() => {}}
                toolTip={`${capitalizeFirstLetter(
                  item?.first_name
                )} ${capitalizeFirstLetter(item?.last_name)}`}
              >
                <img
                  onError={(e) =>
                    handleImageError(e, item?.first_name, item?.last_name)
                  }
                  key={index}
                  className={`relative z-10 inline-block ${
                    isForRostering ? "w-11 h-11" : "h-6 w-6"
                  } rounded-full ring-2 ring-white`}
                  src={
                    item?.photo || item?.profile_picture
                      ? baseURLImage(
                          item?.photo ? item?.photo : item?.profile_picture
                        )
                      : getNameProfile(item?.first_name, item?.last_name || " ")
                  }
                  alt='avatar'
                />
              </PopoverContainer>
            </>
          ))
        ) : (
          `No ${
            type === "worker" ? "Workers" :type === "participant" ? "participant":"Support Coordinators"
          } Assigned`
        )}
      </div>
    </div>
  );
};

export default AvatarGroup;
