import { connect } from "react-redux";
import ExportTimeSheet from "./ExportTimeSheet";
import {
  workerTimeSheetExportListRequest,
  singleTimesheetDetailsRequest,
  exportTimesheetToPayrollRequest,
} from "store/actions/rostering.actions";
import {
  getExportTimesheetToPayrollLoader,
  getSingleTimesheetDetails,
  getSingleTimesheetDetailsLoader,
  getWorkerTimeSheetExportList,
  getWorkerTimeSheetExportListLoader,
} from "store/selector/rostering.selector";
import { getSpSetting } from "store/selector/sp.selector";
import { payrollAuthenticationUpdateLocally } from "store/actions/auth.actions";
import { getUser, getPayrollAuth } from "store/selector/auth.selector";

const mapDispatchToProps = {
  workerTimeSheetExportListRequest,
  singleTimesheetDetailsRequest,
  payrollAuthenticationUpdateLocally,
  exportTimesheetToPayrollRequest,
};

const mapStateToProps = (state) => {
  const workerTimeSheetExportList = getWorkerTimeSheetExportList(state);
  const workerTimeSheetExportListLoader =
    getWorkerTimeSheetExportListLoader(state);
  const singleTimesheetDetailsLoader = getSingleTimesheetDetailsLoader(state);
  const singleTimesheetDetails = getSingleTimesheetDetails(state);
  const currentSpUser = getSpSetting(state);
  const currentLoginUser = getUser(state);
  const payrollAuth = getPayrollAuth(state);
  const exportTimesheetToPayrollLoader =
    getExportTimesheetToPayrollLoader(state);

  return {
    workerTimeSheetExportList,
    workerTimeSheetExportListLoader,
    singleTimesheetDetailsLoader,
    singleTimesheetDetails,
    currentSpUser,
    payrollAuth,
    exportTimesheetToPayrollLoader,
    currentLoginUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportTimeSheet);
