import { convertCentsToDollars, parsePriceInfo } from "Utils/utils";
import createSelector from "../../Utils/reselect";

const spAdminStateData = (state) => {
  const { spAdmin } = state;
  return spAdmin || {};
};

export const getLoading = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("loading") || false
);

export const getError = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("error") || ""
);

export const getSpSettingLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spSettingLoader") || false
);

export const getSpSettingUpdateLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spSettingUpdateLoader") || false
);

export const getSpSettingError = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spSettingError") || ""
);

export const getSpSetting = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spSetting") || null
);

export const getDashboard = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_dashboard") || {}
);

export const getProgressBarDetails = createSelector(
  spAdminStateData,
  (spAdmin) => {
    const spDashbard = spAdmin.get("sp_dashboard" || {});
    if (spDashbard && Object.keys(spDashbard).length > 0) {
      return spDashbard.hasOwnProperty("on_boarding_progress")
        ? spDashbard.on_boarding_progress
        : [];
    }
    return [];
  }
);

export const getParticipantsList = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_participants") || null
);

export const getApplicants = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_applicants") || []
);

export const getSPEmployeeDocuments = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_employee_documents") || []
);

export const getSpWorkers = createSelector(spAdminStateData, (spAdmin) => {
  const data = spAdmin.get("sp_workers");
  if (!data) return [];
  return data.workers;
});

export const getSpWorkersAndManagers = createSelector(
  spAdminStateData,
  (spAdmin) => {
    const data = spAdmin.get("sp_workers");
    if (!data) return [];
    const managers =
      "manager_list" in data
        ? data.manager_list.map((x) => {
            const obj = { ...x, label: x.name, value: x.uuid };
            return obj;
          })
        : [];
    const workers = data.workers;
    return [...workers, ...managers];
  }
);

export const getSpSupportCoordinators = createSelector(
  spAdminStateData,
  (spAdmin) => {
    const data = spAdmin.get("sp_workers");
    if (!data) return [];
    return data.support_coordinators;
  }
);

export const getSPFeedback = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_feedback") || null
);

export const getSPComplaints = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_complaints") || null
);

export const getSPQuestions = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_questions") || null
);

export const getSPFormCategories = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_form_categories") || []
);

export const SpFormCategoriesLaoder = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("form_category_loading") || false
);

export const spFormTemplates = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_form_templates") || null
);

export const spFormTemplatesLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("form_templates_loading") || false
);

export const getDocuments = createSelector(spAdminStateData, (spAdmin) =>
  spAdmin.get("sp_documents" || null)
);

export const spDocUpdateLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spDocUpdateLoader") || false
);

export const getHrmDocuments = createSelector(spAdminStateData, (spAdmin) =>
  spAdmin.get("hrm_documents" || null)
);

export const getRiskRatingTabList = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_riskRatingTabList") || null
);

export const getSubmittedFormsList = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_submittedFormsList") || null
);

export const getSubmittedFormsListLoading = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("submittedFormsList_loading") || null
);

export const getManagers = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("sp_managers") || []
);
export const getRefreshManagerList = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("refreshManagerList") || false
);
export const getTeamManagmentList = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("hrm_team_managment") || null
);

export const getWorkerHourlySettingLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("workerHourlySettingLoader") || false
);

export const getWorkerProfileDetailLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("workerProfileDataLoader") || false
);

export const getSelectedWorker = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("selectedWorker") || null
);

export const getWorkerStatusUpdateLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("workerStatusUpdateLoader") || false
);

export const getStripePricingTableLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("stripePricingTableLoader") || false
);

export const getStripePricingList = createSelector(
  spAdminStateData,
  (spAdmin) => {
    const PricingData = spAdmin.get("stripePricingTableList") || [];

    const transformData = (products) => {
      const transformedFeatures = {};
      products.forEach((product) => {
        const { name: plan, price_info } = product;

        price_info.forEach((itemPrice) => {
          const { nickname, unit_amount, id: priceInfoId } = itemPrice;

          const parseNickName = parsePriceInfo(nickname);
          if (parseNickName === null) return null;
          const { description, amount, frequency, year } = parseNickName;
          const key = description.trim();
          const priceKey = `${year}_year`;

          if (!transformedFeatures[key]) {
            transformedFeatures[key] = {
              name: key,
              [plan.toLowerCase().replace(/\s+/g, "_")]: {
                [priceKey]: {
                  monthly: `$${amount} ${frequency}`,
                  unit_amount: convertCentsToDollars(unit_amount),
                  price_id: priceInfoId,
                },
              },
            };
          } else {
            if (
              !transformedFeatures[key][plan.toLowerCase().replace(/\s+/g, "_")]
            ) {
              transformedFeatures[key][
                plan.toLowerCase().replace(/\s+/g, "_")
              ] = {};
            }
            transformedFeatures[key][plan.toLowerCase().replace(/\s+/g, "_")][
              priceKey
            ] = {
              monthly: `$${amount} ${frequency}`,
              unit_amount: convertCentsToDollars(unit_amount),
              price_id: priceInfoId,
            };
          }
        });
      });
      return Object.values(transformedFeatures);
    };

    const onlySelectedplan = PricingData?.sort((a, b) =>
      a.name.toLowerCase().trim() === "total suite"
        ? -1
        : a.name.toLowerCase().trim() === "total suite"
        ? 1
        : 0
    )?.filter(
      (plan) =>
        plan.name.toLowerCase().trim() === "total suite" ||
        plan.name.toLowerCase().trim() === "platinum plan"
    );

    const transformedData = transformData(onlySelectedplan);
    function getLowerBound(name) {
      const match = name.match(/(\d+)\s+to\s+\d+\s+Participants/);
      if (match) {
        return parseInt(match[1]);
      }
      return null;
    }

    return transformedData.sort((a, b) => {
      const lowerBoundA = getLowerBound(a.name);
      const lowerBoundB = getLowerBound(b.name);
      return lowerBoundA - lowerBoundB;
    });
  }
);

export const getStripeSaveCardDataLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("stripeSaveCardDataLoader") || false
);

export const getStripeListAllCardsLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("stripeListAllCardLoader") || false
);

export const getStripeListAllCards = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("stripeCards") || []
);

export const getStripeGetDefaultPaymentMethodLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("stripeGetDefaultPaymentMethodLoader") || false
);

export const getStripeSaveCardDataError = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("stripeSaveCardDataError") || ""
);

export const getSpStaffComplianceSettingFetchLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spStaffComplianceSettingFetchLoader") || false
);

export const getSpStaffComplianceSetting = createSelector(
  spAdminStateData,
  (spAdmin) =>
    spAdmin.get("spStaffComplianceSetting") || { document: [], form: [] }
);

export const getSpStaffComplianceSettingUpdateLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spStaffComplianceSettingUpdateLoader") || false
);

export const getSpRosteringSettingLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spRosteringSettingFetchLoader") || false
);

export const getSpRosteringSettingUpdateLoader = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spRosteringSettingUpdateLoader") || false
);

export const getSpRosteringSetting = createSelector(
  spAdminStateData,
  (spAdmin) => spAdmin.get("spRosteringSetting") || null
);
