import React, { useEffect, useMemo, useState } from "react";
import Breadcrumb from "../../../components/common/Breadcrumb/Breadcrumb";
import Search from "../../../components/common/Search/Search";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "../../../Constants/BreadcrumbConstant";
import { LoginContext } from "../../../helpers/LoginContext";
import SelectOption from "../../../components/common/Select/Select";
import {
  emptyPaginationObj,
  CATALOG_SERVICES_LIST_LIMIT,
  SORT_TYPE,
} from "../../../Constants/constant";
import { convertLabel, getPaginationObj } from "../../../Utils/utils";
import PaginationComponent from "../../../components/common/Pagination/index";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import FilterSlider from "./FilterSlider";
import { HiOutlineEye } from "react-icons/hi";
import CSModal from "./CSModal";
import Header from "../../../components/common/Header/Header";

const CatalogServices = (props) => {
  const {
    getCOSCatlogServicesRequest,
    catlogServices,
    catalogServicesLoading,
    getCOSCatlogServiceFilter,
    getCOSCatlogServiceSubCategory,
    catlogServiceSubCategory,
    catalogVersions,
    fetchCatalogServicesLoader,
  } = props;

  const { sideBarPadding } = React.useContext(LoginContext);

  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [catalogServices, setCatalogServices] = useState([]);
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [search, setSearch] = useState(null);
  const [versionId, setVersionId] = useState(null);
  const [open, setOpen] = useState(false);
  const [cosModalData, setCosModalData] = useState(null);

  let cat;
  let versionState;

  if (catalogVersions && catlogServiceSubCategory) {
    cat = catalogVersions[catalogVersions.length - 1]?.value;
    versionState = catlogServiceSubCategory[0]?.id;
  }

  const getCatlogService = (filters) => {
    if (cat && versionState) {
      if (!filters || filters === undefined) {
        Object.assign((filters = {}), {
          page: paginationObj.page,
        });
      }

      Object.assign(filters, { per_page: CATALOG_SERVICES_LIST_LIMIT });
      Object.assign(filters, {
        state_id:
          filters.state_id === null || filters.state_id === undefined
            ? filters.state_id
            : filters.state_id,
      });
      if (
        filters?.per_page &&
        (filters?.version_id || filters?.state_id) &&
        catalogVersions &&
        catlogServiceSubCategory
      ) {
        getCOSCatlogServicesRequest(filters);
      }
    }
  };

  useEffect(() => {
    if (catlogServices) {
      setCatalogServices({ ...catlogServices.forms });
      setPaginationObj({
        ...getPaginationObj(catlogServices.forms, catlogServices.query_params),
      });
      if (
        catlogServices &&
        catlogServices.hasOwnProperty("query_params") &&
        catlogServices.query_params.hasOwnProperty("version_uuid")
      ) {
        setVersionId(parseInt(catlogServices.query_params.version_uuid, 10));
      }
    }
  }, [catlogServices]);

  useEffect(() => {
    getCOSCatlogServiceFilter();
    getCOSCatlogServiceSubCategory({ isService: true });
  }, []);

  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order) {
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
    }
  }, [paginationObj]);

  useEffect(() => {
    const obj = {};
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (
      !search &&
      parseInt(catalogServices?.query_params?.page) !== paginationObj.page &&
      catalogVersions !== null
    ) {
      handleFetch({
        ...obj,
        state_id: selectedStatus?.id,
        version_id: versionId,
      });
    }
  }, [paginationObj.page]);

  const handleFetch = (obj, sortBy) => {
    if (paginationObj.page && !obj.hasOwnProperty("page")) {
      Object.assign(obj, { page: paginationObj.page });
    }
    if (paginationObj.state_id && !obj.hasOwnProperty("state_id")) {
      Object.assign(obj, { filter: paginationObj.state_id });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (obj.hasOwnProperty("page")) {
      Object.assign(obj, { page: obj.page });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }

    getCatlogService({ ...obj });
  };

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
          state_id: selectedStatus?.id,
          version_id: versionId,
        }),
      600
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const catalogServicesToMap = useMemo(() => {
    if (catalogServices && catalogServices?.data) {
      return catalogServices.data;
    }
    return [];
  }, [catalogServices]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginationObj.page]);

  return (
    <div className="min-h-screen">
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className="flex-1 bg-white">
          <Header title="Catalogue Services" />
          <div className="sm:px-6 lg:px-8 mt-3">
            <Breadcrumb
              pages={COS_BREADCRUMB(COS_BREADCRUMB_PAGES.SERVICE_CATALOG)}
            />
          </div>

          {!catalogServicesLoading &&
            catalogVersions &&
            catalogVersions !== null && (
              <div className="mt-4 mb-4 sm:mx-6 lg:mx-8 bg-blue-50 py-2 px-4 rounded-lg">
                <FilterSlider
                  filterCatlog={catalogVersions}
                  handleFetch={handleFetch}
                  setVersionId={setVersionId}
                  versionId={versionId}
                  stateId={selectedStatus?.id}
                />
              </div>
            )}

          <div className="flex flex-col lg:px-8 sm:px-6 mt-5">
            <div className=" shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div
                className="sm:flex sm:items-center py-5 px-5 bg-gray-50 border-b sticky z-[5]"
                style={{ top: "67px" }}
              >
                <div className="sm:flex-auto flex">
                  <h1 className="text-xl font-semibold text-gray-900 mr-3">
                    Services List
                  </h1>
                  {catlogServices && catalogVersions && (
                    <span className="inline-flex items-center px-2.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                      {catlogServices?.forms?.total} items
                    </span>
                  )}
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex">
                  <Search
                    // placeholder={"Search by support item number"}
                    width={"xl:w-80"}
                    search={search}
                    setSearch={setSearch}
                  />
                </div>
                {catlogServiceSubCategory ? (
                  <div className="ml-2 w-64">
                    <SelectOption
                      isDisabled={catalogServicesLoading}
                      isSearch={false}
                      selectedOption={selectedStatus}
                      handleChange={(e) => {
                        setSelectedStatus(e);
                        handleFetch(
                          { page: 1, state_id: e.id, version_id: versionId },
                          sortBy
                        );
                      }}
                      data={convertLabel(catlogServiceSubCategory)}
                      // placeHolder="Select State..."
                      // cssClass="z-10"
                    />
                  </div>
                ) : null}
              </div>
              {catalogServicesLoading || fetchCatalogServicesLoader ? (
                <div
                  className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                >
                  <CircularLoader
                    classes="flex justify-center items-center"
                    classloader="loader-l"
                  />
                </div>
              ) : (
                <table className="w-full divide-y divide-gray-300 table-fixed border-t">
                  <thead
                    className="bg-white sticky capitalize"
                    style={{ top: "145px" }}
                  >
                    <tr className="bg-blue-50">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[20%]"
                      >
                        Support Item Number
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[40%]"
                      >
                        Support Item Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[30%]"
                      >
                        Support Category Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 w-[10%]"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {catalogServicesToMap.map((service, index) => (
                      <tr key={index} className="even:bg-white odd:bg-gray-50">
                        <td
                          title={service.support_item_number}
                          className="truncate w-full py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6"
                        >
                          {service.support_item_number}
                        </td>
                        <td
                          title={service.support_item_name}
                          className="truncate w-full px-3 py-4 text-sm text-gray-500"
                        >
                          {service.support_item_name}
                        </td>
                        <td
                          title={service.support_category_name}
                          className="truncate w-full px-3 py-4 text-sm text-gray-500"
                        >
                          {service.support_category_name}
                        </td>
                        <td className="py-4 pl-3 pr-4 text-center">
                          <span
                            onClick={() => {
                              setOpen(true);
                              setCosModalData(service);
                            }}
                            className="icon-eye-wrapper inline-block"
                            title="View"
                          >
                            <HiOutlineEye className="icon-size" />
                          </span>
                        </td>
                      </tr>
                    ))}
                    {catalogServices.length === 0 && (
                      <tr>
                        <td
                          colSpan={7}
                          className="py-6 text-center text-red-600"
                        >
                          No data found !
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          {catalogServicesLoading ||
          fetchCatalogServicesLoader ||
          catalogServices.length > 0 ? null : (
            <div className="bg-white px-4 mt-5 sm:px-6 lg:px-8 max-w-7xl mx-auto pb-10">
              <PaginationComponent
                page={paginationObj.page}
                activeListLength={catalogServicesToMap}
                limit={CATALOG_SERVICES_LIST_LIMIT}
                totalList={paginationObj.total}
                handlePagination={(e) => {
                  const obj = paginationObj;
                  obj.page = e;
                  setPaginationObj({ ...obj });
                  handleFetch({}, sortBy);
                }}
                totalPages={paginationObj.totalPages}
              />
            </div>
          )}
        </main>
      </div>

      <CSModal
        open={open}
        setOpen={setOpen}
        cosModalData={cosModalData}
        version={catalogVersions}
        state={catlogServiceSubCategory}
      />
    </div>
  );
};

export default CatalogServices;
