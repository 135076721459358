import React, { useEffect, useState } from "react";
import GetItemFromLocalstorage from "Utils/GetItemFromLocalstorage";
import Loader from "components/common/Loader/Loader";
import {
  STRIPE_CHECK_SUBSCRIPTION_STATUS,
  checkSubscriptionStatus,
} from "helpers/RolesPermission";
import { BiExit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { authenticatedUser } from "store/actions/auth.actions";
import { getPaymentSettings, getUser } from "store/selector/auth.selector";
import { getStripeGetDefaultPaymentMethodLoader } from "store/selector/sp.selector";
import api from "store/services/sp.services";

const SubscriptionDetails = () => {
  const navigate = useNavigate();

  const defaultPaymentMethodLoader = useSelector(
    getStripeGetDefaultPaymentMethodLoader
  );

  const [isFetchCurrentSubs, setIsFetchCurrentSubs] = useState(false);
  const [isCurrentSubscription, setIsCurrentSubscription] = useState(null);

  const currentUser = useSelector(getUser);
  const paymentSetting = useSelector(getPaymentSettings);

  const stripeStatusVal = checkSubscriptionStatus(currentUser);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  const getCurrentSubscription = async () => {
    try {
      setIsFetchCurrentSubs(true);
      const response = await api.stripeCurrentSubscription();
      setIsFetchCurrentSubs(false);
      if (response) {
        if (response?.subscription?.plan?.nickname) {
          const [participantsRange, pricingDetails] =
            response?.subscription?.plan?.nickname.split("$");
          setIsCurrentSubscription(
            {
              ...response?.subscription?.plan,
              noOfParticipants: participantsRange.trim(),
              duration: pricingDetails.trim(),
              subscriptionPlan: response?.subscription?.metadata?.product_name,
            } || null
          );
        }
      }
    } catch (err) {
      setIsFetchCurrentSubs(false);
      console.log("err", err);
    }
  };

  const handleRetryPayment = async () => {
    try {
      if (isLoading === true) return;

      setIsLoading(true);
      const response = await api.stripeRetryPayment();

      setIsLoading(false);

      if (response) {
        toast.info(
          "Thank you for subscribing! Your subscription is currently being processed and will be updated shortly."
        );
        const user = await GetItemFromLocalstorage("user");
        if (user && user?.payment_setting) {
          const pay = user?.payment_setting;
          pay.errorMessage = "";
          user.payment_setting = pay;
        }
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(authenticatedUser(user));
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
      // toast.error(err?.message ? err?.message : "Something Went Wrong!");
    }
  };

  // console.log("stripeStatusVal", stripeStatusVal);
  // console.log("paymentSetting", paymentSetting);

  const isErrorInPayment = () => {
    return (
      (stripeStatusVal === STRIPE_CHECK_SUBSCRIPTION_STATUS.PAYMENTFAILED ||
        stripeStatusVal ===
          STRIPE_CHECK_SUBSCRIPTION_STATUS.PAYMENT_FAILED_TRAIL_EXISTS ||
        stripeStatusVal ===
          STRIPE_CHECK_SUBSCRIPTION_STATUS.RETRY_PAYMENT_FAILED ||
        stripeStatusVal ===
          STRIPE_CHECK_SUBSCRIPTION_STATUS.SUBSCRIPTION_UNPAID) &&
      paymentSetting?.errorMessage &&
      paymentSetting?.errorMessage?.length > 0
    );
  };

  // console.log("isErrorInPayment", isErrorInPayment());

  return (
    <div className='relative bg-indigo-200 p-4 sm:p-6 rounded-sm overflow-hidden mb-8 md:rounded-lg'>
      <div
        className='absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block'
        aria-hidden='true'
      >
        <svg width='319' height='198' xmlnsXlink='http://www.w3.org/1999/xlink'>
          <defs>
            <path id='welcome-a' d='M64 0l64 128-64-20-64 20z' />
            <path id='welcome-e' d='M40 0l40 80-40-12.5L0 80z' />
            <path id='welcome-g' d='M40 0l40 80-40-12.5L0 80z' />
            <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='welcome-b'>
              <stop stopColor='#A5B4FC' offset='0%' />
              <stop stopColor='#818CF8' offset='100%' />
            </linearGradient>
            <linearGradient
              x1='50%'
              y1='24.537%'
              x2='50%'
              y2='100%'
              id='welcome-c'
            >
              <stop stopColor='#4338CA' offset='0%' />
              <stop stopColor='#6366F1' stopOpacity='0' offset='100%' />
            </linearGradient>
          </defs>
          <g fill='none' fillRule='evenodd'>
            <g transform='rotate(64 36.592 105.604)'>
              <mask id='welcome-d' fill='#fff'>
                <use xlinkHref='#welcome-a' />
              </mask>
              <use fill='url(#welcome-b)' xlinkHref='#welcome-a' />
              <path
                fill='url(#welcome-c)'
                mask='url(#welcome-d)'
                d='M64-24h80v152H64z'
              />
            </g>
            <g transform='rotate(-51 91.324 -105.372)'>
              <mask id='welcome-f' fill='#fff'>
                <use xlinkHref='#welcome-e' />
              </mask>
              <use fill='url(#welcome-b)' xlinkHref='#welcome-e' />
              <path
                fill='url(#welcome-c)'
                mask='url(#welcome-f)'
                d='M40.333-15.147h50v95h-50z'
              />
            </g>
            <g transform='rotate(44 61.546 392.623)'>
              <mask id='welcome-h' fill='#fff'>
                <use xlinkHref='#welcome-g' />
              </mask>
              <use fill='url(#welcome-b)' xlinkHref='#welcome-g' />
              <path
                fill='url(#welcome-c)'
                mask='url(#welcome-h)'
                d='M40.333-15.147h50v95h-50z'
              />
            </g>
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className='relative'>
        {isFetchCurrentSubs ? (
          <Loader isSmallView={true} cssClass={"text-black"} />
        ) : isCurrentSubscription === null ? (
          <div className='flex items-center justify-start gap-x-2'>
            <span>No Subscription Subscribe!</span>
            <p
              onClick={() => {
                navigate("/subscription/plans");
              }}
              className='w-auto flex justify-start items-center text-center gap-x-1 text-md text-indigo-500 hover:underline cursor-pointer'
            >
              Choose Plan
              <span className='inline-flex text-base'>
                <BiExit />
              </span>
            </p>
          </div>
        ) : (
          <>
            <div className='grid grid-cols-3 gap-x-2'>
              {/* Plan */}
              <div className='col-span-1'>
                <h1 className='text-xl text-slate-800 font-bold mb-1 text-left'>
                  Subscription Plan
                </h1>
              </div>
              <div className='col-span-2'>
                <p className='text-xl'>
                  {isCurrentSubscription?.subscriptionPlan}
                </p>
              </div>
              {/* Number Of Participants */}
              <div className='col-span-1'>
                <h1 className='text-xl text-slate-800 font-bold mb-1 text-left'>
                  Number Of Participants
                </h1>
              </div>
              <div className='col-span-2'>
                <p className='text-xl'>
                  {isCurrentSubscription?.noOfParticipants?.replace(":", "")}
                </p>
              </div>
              {/* Duration */}
              <div className='col-span-1'>
                <h1 className='text-xl text-slate-800 font-bold mb-1 text-left'>
                  Duration
                </h1>
              </div>
              <div className='col-span-2'>
                <p className='text-xl'>{`$${isCurrentSubscription?.duration}`}</p>
              </div>
              <div className='col-span-3 pt-4'>
                <button
                  onClick={() => {
                    navigate("/subscription/plans");
                  }}
                  className='w-auto flex justify-start items-center text-center gap-x-1 text-md text-indigo-500 hover:underline cursor-pointer'
                >
                  More Details
                  <span className='inline-flex text-base'>
                    <BiExit />
                  </span>
                </button>
              </div>
            </div>
            {isErrorInPayment() && (
              <div className='py-3'>
                <hr className='line-hr-stripe-tab' />
              </div>
            )}
            <div className='grid grid-cols-1 gap-x-2'>
              {defaultPaymentMethodLoader ? (
                <Loader isSmallView={true} cssClass={"text-black"} />
              ) : (
                isErrorInPayment() && (
                  <>
                    <div className='col-span-1'>
                      <h1 className='text-xl text-slate-800 font-bold mb-1  text-left'>
                        Payment Failure Reason:
                      </h1>
                    </div>
                    <div className='col-span-1 flex items-center gap-x-2'>
                      <span
                        style={{
                          // fontSize: "17px",
                          color: "red",
                        }}
                        className='text-xl'
                      >
                        {paymentSetting?.errorMessage}
                      </span>
                      {isLoading ? (
                        <Loader isSmallView={true} cssClass={"text-black"} />
                      ) : (
                        <button
                          onClick={() => {
                            handleRetryPayment();
                          }}
                          className='w-auto flex justify-start items-center text-center gap-x-1 text-md text-indigo-500 hover:underline cursor-pointer'
                        >
                          Retry Payment
                          {/* <span className='inline-flex text-base'>
                        < />
                      </span> */}
                        </button>
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SubscriptionDetails;
