import React, { useState, useEffect, useMemo, useContext } from "react";
import { useParams } from "react-router";
import SideBar from "components/common/SideBar/SideBarNew";
import TableHeader from "components/common/Table/TableHeader";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import { LoginContext } from "helpers/LoginContext";
import {
  ROSTERING_INVOICING_BREADCRUMB,
  ROSTERING_INVOICING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { connect } from "react-redux";
import { getSPParticipantsListRequest } from "store/actions/sp.actions";
import { getParticipantsList } from "store/selector/sp.selector";
import { apiGetRequest } from "helpers/Requests";
import { URL_ROSTERING_INVOICE_DETAIL } from "store/services/URL";
import InvoicePreviewTable from "./InvoicePreviewTable";

const RosterInvoicePreview = () => {
  const { sideBarPadding } = useContext(LoginContext);
  const [invoicesDetailList, setInvoiceDetailList] = useState([]);
  const [loader, setLoader] = useState(false);
  const { uuid } = useParams();
  useEffect(() => {
    if (uuid) {
      setLoader(true);
      apiGetRequest(`${URL_ROSTERING_INVOICE_DETAIL}?uuid=${uuid}`)
        .then((res) => {
          if (res?.status === 200) {
            setInvoiceDetailList(res.data?.data?.invoice_detail);
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [uuid]);

  const createdRosterRiviewInvoiceToMap = useMemo(() => {
    if (invoicesDetailList && invoicesDetailList?.length > 0) {
      return invoicesDetailList;
    }
    return [];
  }, [invoicesDetailList]);

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className='h-screen flex-1 bg-white'>
          <Header title='Invoice Preview' />
          <div className='max-w-full mx-auto bg-white mt-3'>
            <div className='sm:px-6 lg:px-8'>
              <Breadcrumb
                pages={ROSTERING_INVOICING_BREADCRUMB(
                  ROSTERING_INVOICING_BREADCRUMB_PAGES.ROSTER_INVOICE_PREVIEW
                )}
              />
            </div>
            <div className='px-2 sm:px-6 lg:px-8 py-3'>
              <div className='flex flex-col'>
                <div className=' overflow-x-auto'>
                  <div className='min-w-full py-2 align-middle'>
                    <div className='overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                      <table className='min-w-full divide-y divide-gray-300 sticky capitalize'>
                        <thead className='bg-blue-50 sticky capitalize'>
                          <tr>
                          <TableHeader
                              isSort={false}
                              label={""}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              label={"Reference "}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              label={"Worker(s)"}
                              cssClass='normal-case text-center'
                            />
                            <TableHeader
                              isSort={false}
                              label={"Work Start time"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              label={"Work end time"}
                              cssClass='text-center'
                            />

                            
                            <TableHeader
                              isSort={false}
                              label={"Date Delivered"}
                              cssClass='text-center'
                            />
                          </tr>
                        </thead>
                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {loader ? (
                            <tr>
                              <td colSpan={14} className='bg-gray-50 py-2'>
                                <CircularLoader
                                  classes='flex justify-center items-center'
                                  classloader='loader-l'
                                />
                              </td>
                            </tr>
                          ) : createdRosterRiviewInvoiceToMap &&
                            createdRosterRiviewInvoiceToMap?.length > 0 ? (
                            createdRosterRiviewInvoiceToMap?.map(
                              (item, index) => (
                                <InvoicePreviewTable item={item} key={index} />
                              )
                            )
                          ) : (
                            (!createdRosterRiviewInvoiceToMap ||
                              createdRosterRiviewInvoiceToMap?.length ===
                                0) && (
                              <tr>
                                <td
                                  colSpan={14}
                                  className='py-6 text-center text-red-600'
                                >
                                  No Data Found !
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getParticipantList: getSPParticipantsListRequest,
};

const mapStateToProps = (state) => {
  const spParticipants = getParticipantsList(state);

  return {
    spParticipants,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterInvoicePreview);
