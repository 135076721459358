import React, { useContext, useMemo, useState } from "react";
import StripePayment from "./StripePayment";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import InputField from "components/common/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { getStripeSaveCardDataLoader } from "store/selector/sp.selector";
import CardList from "./Card/CardList";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { capitalizeFirstLetter, classNames } from "Utils/utils";
import useDefaultPaymentMethod from "hooks/useDefaultPaymentMethod";
// import {
//   BILLING_BREADCRUMB,
//   BILLING_BREADCRUMB_PAGES,
// } from "Constants/BreadcrumbConstant";
// import SideBarNew from "components/common/SideBar/SideBarNew";
// import { LoginContext } from "helpers/LoginContext";
// import Header from "components/common/Header/Header";
// import Breadcrumb from "components/common/Breadcrumb";
// import { BiExit } from "react-icons/bi";
// import { callLogout } from "store/actions/auth.actions";
// import { useNavigate } from "react-router";

const PaymentMethod = ({ istrailExpired = false }) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const saveCardDataLoader = useSelector(getStripeSaveCardDataLoader);
  // const { sideBarPadding } = useContext(LoginContext);

  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  const {
    getDefaultCardDetails,
    stripeDefaultPaymentMethodLaoder,
    paymentSettings,
    cardsListData,
  } = useDefaultPaymentMethod();

  const defaultCardDetails = useMemo(() => {
    return getDefaultCardDetails;
  }, [
    paymentSettings,
    stripeDefaultPaymentMethodLaoder,
    saveCardDataLoader,
    getDefaultCardDetails,
  ]);

  // const handleLogout = () => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   dispatch(callLogout());
  //   navigate("/");
  // };

  return (
    <>
      <div className='min-h-full'>
        {/* {istrailExpired === false && <SideBarNew />}
        <main>
          <div
            className={classNames(
              istrailExpired === false
                ? sideBarPadding
                  ? "lg:pl-[4rem] duration-300"
                  : "lg:pl-64 duration-300"
                : "",
              `${sideBarPadding} flex flex-col fullScreenHeight`
            )}
          >
            {istrailExpired === false ? (
              <Header title='Billing Method' cssClass={"z-[10]"} />
            ) : (
              <header className='bg-white flex items-center sticky top-0 z-[10] border-b border-gray-200 sm:px-6 lg:px-8 py-[20px] h-[80px]'>
                <div className='items-center w-full flex sm:flex sm:items-center sm:justify-between'>
                  <div className='flex-1 min-w-0'>
                    <h1 className='text-xl font-semi bold text-gray-900 capitalize'>
                      Billing Method
                    </h1>
                  </div>
                  <div className='mt-4 flex justify-space-between sm:mt-0 space-x-2 print:hidden'>
                    <div className='md:mt-0 md:ml-auto space-x-2 md:space-x-4 hidden sm:flex'>
                      <CustomButton
                        clickHandler={handleLogout}
                        label={"Logout"}
                        Icon={<BiExit />}
                        variant={"danger"}
                      />
                    </div>
                  </div>
                </div>
              </header>
            )}
            <div className='px-4 py-2'>
              <div className='mb-5 border-gray-200 px-4 pt-4'>
                <Breadcrumb
                  pages={BILLING_BREADCRUMB(
                    BILLING_BREADCRUMB_PAGES.BILLING_METHOD
                  )}
                />
              </div>
              <div
                style={{ paddingTop: "15px", top: "70px" }}
                className='px-6 flex flex-col sm:flex-row justify-between items-center pb-4'
              >
                <p className='font-bold text-xl'>Billing Method</p>
                <div>
                  <CustomButton
                    isDisabled={saveCardDataLoader}
                    showLoading={saveCardDataLoader}
                    label='Add Card'
                    clickHandler={() => setOpenPaymentModal(true)}
                  />
                </div>
              </div>
              <div className='px-6 pb-4 py-4 ring-1 ring-black ring-opacity-5 md:rounded-lg mb-4'>
                <div className='flex justify-between items-center py-2'>
                  <p className='text-md font-bold'>Card List</p>
                </div>
                <div className={`w-full py-2 rounded-lg`}>
                  <CardList cards={cardsListData} />
                </div>
              </div>
              <>
                {defaultCardDetails !== null && (
                  <div className='px-6 py-4 bg-gray-50 md:rounded-lg ring-1 ring-black ring-opacity-5'>
                    <div className='flex justify-between items-center py-2'>
                      <p className='text-md font-bold'>Default Card Details</p>
                    </div>
                    <>
                      {stripeDefaultPaymentMethodLaoder ? (
                        <CircularLoader
                          classes='flex justify-center items-center'
                          classloader='loader-l'
                        />
                      ) : (
                        <div
                          className={`w-full py-2 grid gap-6 md:rounded-lg lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1`}
                        >
                          {defaultCardDetails !== null ? (
                            <>
                              <div>
                                <InputField
                                  label='Card Number'
                                  type='text'
                                  isDisabled={true}
                                  value={`**** **** **** ${defaultCardDetails?.last4}`}
                                />
                              </div>
                              <div>
                                <InputField
                                  label='Name On Card'
                                  type='text'
                                  isDisabled={true}
                                  value={capitalizeFirstLetter(
                                    defaultCardDetails?.card_name
                                  )}
                                />
                              </div>
                              <div>
                                <InputField
                                  label='Expiry Date'
                                  type='text'
                                  isDisabled={true}
                                  value={`${defaultCardDetails?.exp_month}/${defaultCardDetails?.exp_year}`}
                                />
                              </div>
                            </>
                          ) : (
                            <div className='col-span-2 w-full bg-gray-50 px-5 py-4'>
                              <div className='text-red-500 text-center'>
                                No Data Found!
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  </div>
                )}
              </>
            </div>
          </div>
        </main> */}

        <div className='flex justify-between items-center pb-4'>
          <p className='font-bold text-xl'>Billing Method</p>
          <div>
            <CustomButton
              cssClass='w-max'
              isDisabled={saveCardDataLoader}
              showLoading={saveCardDataLoader}
              label='Add Card'
              clickHandler={() => setOpenPaymentModal(true)}
            />
          </div>
        </div>
        <div className='px-6 pb-4 py-4 ring-1 ring-black ring-opacity-5 md:rounded-lg mb-4'>
          <div className='flex justify-between items-center py-2'>
            <p className='text-md font-bold'>Card List</p>
          </div>
          <div className={`w-full py-2 rounded-lg`}>
            <CardList cards={cardsListData} />
          </div>
        </div>
        <>
          {defaultCardDetails !== null && (
            <div className='px-6 py-4 bg-gray-50 md:rounded-lg ring-1 ring-black ring-opacity-5'>
              <div className='flex justify-between items-center py-2'>
                <p className='text-md font-bold'>Default Card Details</p>
              </div>
              <>
                {stripeDefaultPaymentMethodLaoder ? (
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                ) : (
                  <div
                    className={`w-full py-2 grid gap-6 md:rounded-lg lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1`}
                  >
                    {defaultCardDetails !== null ? (
                      <>
                        <div>
                          <InputField
                            label='Card Number'
                            type='text'
                            isDisabled={true}
                            value={`**** **** **** ${defaultCardDetails?.last4}`}
                          />
                        </div>
                        <div>
                          <InputField
                            label='Name On Card'
                            type='text'
                            isDisabled={true}
                            value={capitalizeFirstLetter(
                              defaultCardDetails?.card_name
                            )}
                          />
                        </div>
                        <div>
                          <InputField
                            label='Expiry Date'
                            type='text'
                            isDisabled={true}
                            value={`${defaultCardDetails?.exp_month}/${defaultCardDetails?.exp_year}`}
                          />
                        </div>
                      </>
                    ) : (
                      <div className='col-span-2 w-full bg-gray-50 px-5 py-4'>
                        <div className='text-red-500 text-center'>
                          No Data Found!
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            </div>
          )}
        </>
      </div>
      {openPaymentModal && (
        <ModalSkeleton
          cssClass='w-[35%]'
          title='Add Credit Card Details'
          isOpen={openPaymentModal}
          closeModal={() => setOpenPaymentModal(false)}
        >
          <div className='w-full flex justify-center items-center'>
            <StripePayment handleClose={() => setOpenPaymentModal(false)} />
          </div>
        </ModalSkeleton>
      )}
    </>
  );
};

export default PaymentMethod;
