import React from "react";

const Heading = ({heading, desc}) => {
  return (
    <div>
      <p className="text-sm text-gray-700 font-medium">{heading}</p>
      <p className="text-sm text-gray-500 font-normal">{desc}</p>
    </div>
  );
};

export default Heading;
