import React, { useEffect } from "react";

const PasswordValidationBox = (props) => {
  const { password } = props;

  const letter = document.getElementById("letter");
  const capital = document.getElementById("capital");
  const small = document.getElementById("small");
  const number = document.getElementById("number");
  const length = document.getElementById("length");

  useEffect(() => {
    validatePassword();
  }, [password]);

  const validatePassword = () => {
    let specialCharacter = /(?=.*[!@#$%^&*])/;
    if (password && password.match(specialCharacter)) {
      letter.classList.remove("invalid");
      letter.classList.add("valid");
      letter.childNodes[1].classList.remove("invalid-letters");
      letter.childNodes[1].classList.add("valid-letters");
    } else if (letter) {
      letter.classList.remove("valid");
      letter.classList.add("invalid");
      letter.childNodes[1].classList.remove("valid-letters");
      letter.childNodes[1].classList.add("invalid-letters");
    }

    // Validate capital letters
    let upperCaseLetters = /[A-Z]/g;
    if (password && password.match(upperCaseLetters)) {
      capital.classList.remove("invalid");
      capital.classList.add("valid");
      capital.childNodes[1].classList.remove("invalid-letters");
      capital.childNodes[1].classList.add("valid-letters");
    } else if (capital) {
      capital.classList.remove("valid");
      capital.classList.add("invalid");
      capital.childNodes[1].classList.remove("valid-letters");
      capital.childNodes[1].classList.add("invalid-letters");
    }
    // Validate small letters
    let lowerCaseLetters = /[a-z]/g;
    if (password && password.match(lowerCaseLetters)) {
      small.classList.remove("invalid");
      small.classList.add("valid");
      small.childNodes[1].classList.remove("invalid-letters");
      small.childNodes[1].classList.add("valid-letters");
    } else if (small) {
      small.classList.remove("valid");
      small.classList.add("invalid");
      small.childNodes[1].classList.remove("valid-letters");
      small.childNodes[1].classList.add("invalid-letters");
    }

    // Validate numbers
    let numbers = /[0-9]/g;
    if (password && password.match(numbers)) {
      number.classList.remove("invalid");
      number.classList.add("valid");
      number.childNodes[1].classList.remove("invalid-letters");
      number.childNodes[1].classList.add("valid-letters");
    } else if (number) {
      number.classList.remove("valid");
      number.classList.add("invalid");
      number.childNodes[1].classList.remove("valid-letters");
      number.childNodes[1].classList.add("invalid-letters");
    }

    // Validate length
    if (password && password.length >= 8) {
      length.classList.remove("invalid");
      length.classList.add("valid");
      length.childNodes[1].classList.remove("invalid-letters");
      length.childNodes[1].classList.add("valid-letters");
    } else if (length) {
      length.classList.remove("valid");
      length.classList.add("invalid");
      length.childNodes[1].classList.remove("valid-letters");
      length.childNodes[1].classList.add("invalid-letters");
    }
  };

  return (
    <div className="rounded-lg">
      <h3>Password must contain the following:</h3>
      <p id="letter" className="invalid">
        A <span className="invalid-letters">special character</span>
      </p>
      <p id="capital" className="invalid">
        A <span className="invalid-letters">capital (uppercase)</span> letter
      </p>
      <p id="small" className="invalid">
        A <span className="invalid-letters">small (lowercase)</span> letter
      </p>
      <p id="number" className="invalid">
        A <span className="invalid-letters">number</span>
      </p>
      <p id="length" className="invalid">
        Minimum <span className="invalid-letters">8 characters</span>
      </p>
    </div>
  );
};

export default PasswordValidationBox;
