

import React, { useEffect } from "react";
import SelectOption from "components/common/Select/Select";
import { RECURRING_INTERVAL } from "store/constants";
import CheckboxWithLabel from "components/common/CheckboxWithLabel";
import moment from "moment";

const RecurringShift = ({
  setRecurringType,
  recurringType,
  watchFormData,
  setRecurrInterval,
  recurrInterval,
  setRecurringDates,
  setSelectedDays,
  selectedDays,
  selectedShift,
  setRecurringFor,
  recurringFor,
  handleIntervalChange,
  shiftDetailData,
  setLoading,
}) => {
  useEffect(() => {
    if (selectedShift !== null && shiftDetailData?.recurring_days) {
      const parsedDays = JSON.parse(shiftDetailData?.recurring_days);
      const formattedDays = {
        mon: parsedDays.monday,
        tue: parsedDays.tuesday,
        wed: parsedDays.wednesday,
        thu: parsedDays.thursday,
        fri: parsedDays.friday,
        sat: parsedDays.saturday,
        sun: parsedDays.sunday,
      };

      if (formattedDays) {
        setSelectedDays(formattedDays);
      }
    }
  }, [
    watchFormData?.shift_date,
    recurrInterval,
    recurringType,
    selectedShift,
    setSelectedDays,
    shiftDetailData,
  ]);
  useEffect(() => {
    if (watchFormData?.shift_date && selectedShift === null) {
      const shiftDate = moment(watchFormData.shift_date);
      const dayName = shiftDate.format("ddd");
      setSelectedDays((prevSelectedDays) => ({
        ...prevSelectedDays,
        [dayName]: true,
      }));
    }
  }, [
    watchFormData?.shift_date,
    recurrInterval,
    recurringType,
    selectedShift,
    setSelectedDays,
  ]);

  const getWeekOfMonth = (date) => {
    const firstDayOfMonth = date.clone().startOf("month");
    return Math.ceil((date.date() + firstDayOfMonth.day()) / 7);
  };

 
  useEffect(() => {
    if (recurringType) {
      // const shiftDate = moment(watchFormData?.shift_date).add(1, "month"); // Start from the next month

      const number = parseInt(recurringType.value.split("_")[0]);
      const label = recurringType.value.split("_")[1];

      const formattedDates = [];

      if (label === "Fortnight") {
        const shiftDate = moment(watchFormData?.shift_date);
        const initialDate = shiftDate.clone().add(7, "days");

        for (let period = 1; period <= number; period++) {
          const startOfSecondWeek = initialDate
            .clone()
            .add(period * 14 - 7, "days");

          for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
            const currentDate = startOfSecondWeek
              .clone()
              .add(dayOffset, "days");
            const dayName = currentDate.format("ddd");

            if (selectedDays[dayName]) {
              formattedDates.push(
                currentDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
              );
            }
          }
        }
      } else if (label === "Month") {


        const shiftDate = moment(watchFormData?.shift_date);
    const initialWeekOfMonth = getWeekOfMonth(shiftDate); // Determine the week position of the initial shift date

    for (let monthIndex = 1; monthIndex <= number; monthIndex++) {
        const currentMonth = shiftDate.clone().add(monthIndex, "months");
        
        // Calculate the target week for each selected day within the same month
        let targetWeekStart = currentMonth.clone().startOf("month").add((initialWeekOfMonth - 1) * 7, "days");

        // Adjust if the target week goes beyond the end of the month
        const endOfMonth = currentMonth.clone().endOf("month");
        if (targetWeekStart.isAfter(endOfMonth)) {
            // Move to the last week within the month
            targetWeekStart = endOfMonth.clone().subtract(6, "days").startOf("week");
        }

        // Loop through each selected day in the week
        for (const [day, isSelected] of Object.entries(selectedDays)) {
            if (!isSelected) continue;  // Skip unselected days

            let foundDate = null;

            // Try to find the specified day within the target week
            for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
                const currentDate = targetWeekStart.clone().add(dayOffset, "days");
                const dayName = currentDate.format("ddd");

                if (dayName === day) {
                    foundDate = currentDate;
                    break;
                }
            }

            // If the day isn't found in the target week, find the last occurrence within the month
            if (!foundDate || !foundDate?.isSame(currentMonth, 'month')) {
                foundDate = null;
                for (let dayOffset = 0; dayOffset <= endOfMonth.date(); dayOffset++) {
                    const currentDate = endOfMonth.clone().subtract(dayOffset, "days");
                    const dayName = currentDate.format("ddd");

                    if (dayName === day) {
                        foundDate = currentDate;
                        break;
                    }
                }
            }

            // Add the found date to formattedDates, now ensured to be within the target month and week position
            if (foundDate) {
                formattedDates?.push(foundDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
            }
        }
    }
        // const shiftDate = moment(watchFormData?.shift_date);
        // const initialWeekOfMonth = getWeekOfMonth(shiftDate);
    
        // for (let monthIndex = 1; monthIndex <= number; monthIndex++) {
        //     const currentMonth = shiftDate.clone().add(monthIndex, "months");
            
        //     // Get the last day of the current month
        //     const lastDayOfMonth = currentMonth.clone().endOf("month");
        //     let startOfLastWeek = lastDayOfMonth.clone().startOf("week");
    
        //     // Loop through each selected day in the week
        //     for (const [day, isSelected] of Object.entries(selectedDays)) {
        //         if (!isSelected) continue;  // Skip unselected days
    
        //         let foundDate = null;
    
        //         // Try to find the specified day within the last week of the month
        //         for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
        //             const currentDate = startOfLastWeek.clone().add(dayOffset, "days");
        //             const dayName = currentDate.format("ddd");
    
        //             if (dayName === day) {
        //                 foundDate = currentDate;
        //                 break;
        //             }
        //         }
    
        //         // Check if foundDate is still within the current month
        //         if (!foundDate || !foundDate.isSame(currentMonth, 'month')) {
        //             // Reset foundDate and search backwards from the last day of the month
        //             foundDate = null;
        //             for (let dayOffset = 0; dayOffset <= lastDayOfMonth.date(); dayOffset++) {
        //                 const currentDate = lastDayOfMonth.clone().subtract(dayOffset, "days");
        //                 const dayName = currentDate.format("ddd");
    
        //                 if (dayName === day) {
        //                     foundDate = currentDate;
        //                     break;
        //                 }
        //             }
        //         }
    
        //         // Add the found date to formattedDates, now ensured to be within the current month
        //         if (foundDate) {
        //             formattedDates.push(foundDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
        //         }
        //     }
        // }
    //     const shiftDate = moment(watchFormData?.shift_date);
    // const initialWeekOfMonth = getWeekOfMonth(shiftDate);

    // for (let monthIndex = 1; monthIndex <= number; monthIndex++) {
    //     const currentMonth = shiftDate.clone().add(monthIndex, "months");
        
    //     // Calculate the initial target week
    //     let startOfWeek = currentMonth.clone().startOf("month").add((initialWeekOfMonth - 1) * 7, "days");

    //     // Check if the starting week goes beyond the end of the month
    //     const endOfMonth = currentMonth.clone().endOf("month");
    //     if (startOfWeek.isAfter(endOfMonth)) {
    //         // Adjust to the last full week within the month
    //         startOfWeek = endOfMonth.clone().subtract(6, "days").startOf("week");
    //     }

    //     // Try to find the selected day within the calculated week
    //     let foundDate = null;
    //     for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
    //         const currentDate = startOfWeek.clone().add(dayOffset, "days");
    //         const dayName = currentDate.format("ddd");

    //         if (selectedDays[dayName]) {
    //             foundDate = currentDate;
    //             break;
    //         }
    //     }

    //     // Move backwards if the day is not found in the target week
    //     while (!foundDate && startOfWeek.isAfter(currentMonth.clone().startOf("month"))) {
    //       console.log("backward")
    //         startOfWeek = startOfWeek.clone().subtract(7, "days");
    //         for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
    //             const currentDate = startOfWeek.clone().add(dayOffset, "days");
    //             const dayName = currentDate.format("ddd");

    //             if (selectedDays[dayName]) {
    //                 foundDate = currentDate;
    //                 break;
    //             }
    //         }
    //     }

    //     // If no week contained the target day, select the last occurrence of the day within the month
    //     if (!foundDate) {
    //       console.log("weeek")
    //         const lastDayOfMonth = currentMonth.clone().endOf("month");
    //         for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
    //             const currentDate = lastDayOfMonth.clone().subtract(dayOffset, "days");
    //             const dayName = currentDate.format("ddd");

    //             if (selectedDays[dayName]) {
    //                 foundDate = currentDate;
    //                 break;
    //             }
    //         }
    //     }

    //     // Add the found date to the formatted dates array if found within the month
    //     if (foundDate) {
    //         formattedDates.push(foundDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    //     }
    // }
        
        // const shiftDate = moment(watchFormData?.shift_date);
        // const initialWeekOfMonth = getWeekOfMonth(shiftDate);

        
        // for (let monthIndex = 1; monthIndex <= number; monthIndex++) {
        //   const currentMonth = shiftDate.clone().add(monthIndex, "months");
      
        //   const startOfWeek = currentMonth.clone().startOf("month").add((initialWeekOfMonth - 1) * 7, "days");

        //   for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
        //     const currentDate = startOfWeek.clone().add(dayOffset, "days");
        //     const dayName = currentDate.format("ddd");

        //     if (selectedDays[dayName] ) {
        //       formattedDates.push(
        //         currentDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        //       );
        //     }
        //   }
        // }
      } else {
 
        const shiftDate = moment(watchFormData?.shift_date);

        const intervalFactor = 7;
        // const initialDate = shiftDate.clone().add(7, "days");
        for (let i = 0; i < number * intervalFactor; i++) {
          const currentDate = shiftDate.clone().add(i, "days");
          const dayName = currentDate.format("ddd");
          if (selectedDays[dayName]) {
            formattedDates.push(
              currentDate?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
            );
          }
        }
      }

      setRecurringDates(formattedDates);
    }
  }, [
    recurringType,
    watchFormData?.shift_date,
    selectedDays,
    setRecurringDates,
  ]);

  return (
    <div>
      <div className='flex items-center gap-x-3 mt-4'>
        <div className='w-[50%]'>
          <label className='text-gray-700 text-md font-medium mb-1'>
            Please choose recurring interval
          </label>
          <div>
            <SelectOption
              data={RECURRING_INTERVAL}
              placeHolder='Recurring Interval'
              selectedOption={recurrInterval}
              handleChange={handleIntervalChange}
              isSearch={false}
              cssClass='z-8'
              isDisabled={selectedShift !== null}
            />
          </div>
        </div>
        <div className='w-[50%]'>
          <label className='text-gray-700 text-md font-medium mb-1'>
            Repeat for
          </label>
          <div>
            <SelectOption
              data={recurringFor || []}
              placeHolder={`select number `}
              selectedOption={recurringType}
              handleChange={(e) => {
                setRecurringType(e);
              }}
              isSearch={false}
              cssClass='z-8'
              isDisabled={selectedShift !== null}
            />
          </div>
        </div>
      </div>
      {watchFormData?.is_recurring && recurringType && (
        <div className='mt-6'>
          <label className='text-gray-700 text-md font-medium mb-1'>
            Please choose the day/days to repeat the shift
          </label>
          <div className='flex flex-row flex-wrap mt-2 w-full gap-3'>
            {Object.entries(selectedDays)?.map(([day, isSelected]) => (
              <div key={day} className='flex items-center '>
                <CheckboxWithLabel
                  label={day}
                  checkboxId={day}
                  checked={isSelected}
                  setSelectedDays={setSelectedDays}
                  disabled={selectedShift !== null}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecurringShift;
