import React, { useEffect, useState } from "react";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import PublishShiftItem from "./PublishShiftItem";
import CheckBox from "components/common/checkBox/checkbox";
import { getAllPendingShifts } from "store/selector/rostering.selector";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SHIFT_STATUS, TOAST_TYPE } from "Constants/constant";
import RosteringAPI from "store/services/rostering.services";
import { workerAndParticipantShiftStatusChangedLocally } from "store/actions/rostering.actions";
import { capitalizeFirstLetter } from "Utils/utils";
import { showToast } from "store/actions/general.actions";
import { TOAST_IDS } from "store/constants";

const PubishShiftModal = (props) => {
  const { isShow, handleClose, calendarView, isUserGracePeriodOver } = props;

  const dispatch = useDispatch();

  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const data = useSelector((state) => getAllPendingShifts(state));
const filterData=data?.filter((item)=>item.worker_id);

  const handlePublishShift = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (checkboxes.length === 0) {
      dispatch(
        showToast({
          id: TOAST_IDS.PUBLISH_SHIFT_MODAL_BUTTON_ID,
          type: TOAST_TYPE.DEFAULT,
          title: "No Any Shift Selected!",
        })
      );
      return;
    }
    const data = {
      uuid: checkboxes.map((x) => x.uuid),
      status: SHIFT_STATUS.PUBLISHED,
    };
    try {
      setIsLoading(true);
      const response = await RosteringAPI.changeStatusWorkerAndParticipantShift(
        data
      );
      if (response) {
        dispatch(
          workerAndParticipantShiftStatusChangedLocally({
            shifts: checkboxes,
            status: SHIFT_STATUS.PUBLISHED,
            calendarView:
              calendarView === "worker-view" ? "worker" : "participant",
          })
        );
        toast.success(
          `Shift ${capitalizeFirstLetter(
            SHIFT_STATUS.PUBLISHED
          )}  Successfully!`
        );
      }
      setIsLoading(false);
      handleClose();
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
      toast.error(err?.message ?? "Something went wrong!");
    }
  };

  const toggleCheckboxes = (val) => {

      setCheckboxes(val ? data.filter((s) => s.worker_id ) :[]);
  
  };

  useEffect(() => {
    if (
      data &&
      checkboxes &&
      checkboxes.length === data.filter((s) => s.worker_id ).length &&
      data.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);

  const handleCheckboxChange = (e, uuid, item) => {
    const filteredList = checkboxes.filter((c) => c.uuid !== uuid);
    if (e) {
      filteredList.push({ ...item });
    }
    setCheckboxes([...filteredList]);
  };

  const modalFooter = () => (
    <>
      <CustomButton
        variant='danger'
        clickHandler={() => {
          handleClose();
        }}
        label='Cancel'
      />
      <CustomButton
        clickHandler={handlePublishShift}
        showLoading={isLoading}
        isDisabled={isLoading || isUserGracePeriodOver}
        label='Publish'
      />
    </>
  );

  const publishShiftContent = () => (
    <div className='min-w-full p-4 py-0'>
      {data && data.length > 0 && (
        <div className='py-2'>
          <h4>
            <b>Total Shifts:</b> {data.length}
          </h4>
        </div>
      )}
      <div className='overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-300 border'>
          <thead className='capitalize'>
            <tr>
              <th
                scope='col'
                className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50 w-[10%]`}
              >
                <CheckBox
                  checked={allSelected}
                  handleChange={(e) => {
                    if (data && data.length > 0) {
                      setAllSelected(e);
                      toggleCheckboxes(e);
                    }
                  }}
                />
              </th>
              <TableHeader isSort={false} label={"Worker name"} />
              <TableHeader isSort={false} label={"Participant name"} />
              <TableHeader
                cssClass='text-center'
                isSort={false}
                label={"Shift date"}
              />
              <TableHeader
                cssClass='text-center'
                isSort={false}
                label={"Shift length"}
              />
              <TableHeader
                cssClass='text-center'
                isSort={false}
                label={"Shift start time"}
              />
              <TableHeader
                cssClass='text-center'
                isSort={false}
                label={"Shift end time"}
              />
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {false ? (
              <tr>
                <td colSpan={7} className='bg-gray-50 py-2'>
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                </td>
              </tr>
            ) : data && data?.length > 0 ? (
              data?.map((shift, index) => (
                <PublishShiftItem
                  shift={shift}
                  handleCheckboxChange={(e) =>
                    handleCheckboxChange(e, shift.uuid, shift)
                  }
                  checkboxes={checkboxes}
                  key={index}
                  calendarView={calendarView}
                />
              ))
            ) : (
              data.length === 0 && (
                <tr>
                  <td colSpan={7} className='py-6 text-center text-red-600'>
                    No Data Found !
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div>
      {isShow && (
        <ModalSkeleton
          title='Shift Publish'
          isOpen={isShow}
          cssClass='w-[60%]'
          bodyPadding='px-0'
          closeModal={() => {
            handleClose();
          }}
          modalFooter={modalFooter()}
        >
          {false ? (
            <div
              className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
            >
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            publishShiftContent()
          )}
        </ModalSkeleton>
      )}
    </div>
  );
};

export default PubishShiftModal;
