import React from "react";
import { isGracePeriodOver } from "helpers/RolesPermission";
import { useSelector } from "react-redux";
import { getUser } from "store/selector/auth.selector";

const useGracePeriodOverHook = () => {
  const currentUser = useSelector(getUser);

  return isGracePeriodOver(currentUser);
};

export default useGracePeriodOverHook;
