import React from "react";
import moment from "moment";
import PopoverContainer from "components/common/Popover/PopoverContainer";
const InprogressShiftStatusPopover = (props) => {
  const { workerStartTime, startTime } = props;
  const INPROGRESS_SHIFT_START_TIME_STATUS = {
    ["start-on-time"]: "Start On Time",
    ["start-early"]: "Start Early",
    ["late-start"]: "Late Start",
    ["late-3-minutes"]: "Late 3 Minutes",
  };
  const getMessage = () => {
    if (!startTime || !workerStartTime) return "";
    const scheduledTime = moment(startTime);
    const actualTime = moment(workerStartTime);
    const diffMinutes = actualTime.diff(scheduledTime, "minutes");
    if (diffMinutes === 0) {
      return "start-on-time";
    } else if (diffMinutes < 0) {
      return "start-early";
    } else if (diffMinutes > 3) {
      return "late-3-minutes";
    } else {
      return "late-start";
    }
  };
  const getStatusIcon = (place = "icon") => {
    switch (getMessage()) {
      case "start-on-time":
      case "start-early":
        return place === "icon"
          ? "shadow-gray-100 bg-gray-400"
          : "bg-gray-200 border-gray-400";
      case "late-start":
      case "late-3-minutes":
        return place === "icon"
          ? "shadow-[#ffcbc8] bg-[#ff5349]"
          : "bg-[#ffcbc8] border-[#ff5349]";
      default:
        return "";
    }
  };
  // console.log("worker start time, start itme", workerStartTime, startTime);
  // console.log("getMessage", getMessage());
  return (
    <PopoverContainer
      isHover
      itemComponent={() => (
        <div
          className={`border-2 rounded px-2 capitalize text-xs ${getStatusIcon(
            "text"
          )}`}
        >
          {INPROGRESS_SHIFT_START_TIME_STATUS[getMessage()]}
        </div>
      )}
    >
      <span
        className={`absolute left-0 top-0 w-[7px] h-[7px] rounded-full shadow drop-shadow-2xl ${getStatusIcon(
          "icon"
        )}`}
      />
    </PopoverContainer>
  );
};
export default InprogressShiftStatusPopover;
