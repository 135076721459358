import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../../components/common/Loader/Loader";
import { LOADER_STATUS } from "../../../../Constants/constant";
import api from "../../../../store/services/cos.services";
import { DropDown } from "components/common/DropDown/DropDown";
import NumberFormatter from "components/common/NumberFormatter/NumberFormatter";
import { LoginContext } from "helpers/LoginContext";

const PlanBudgetItem = (props) => {
  const {
    setEditPlanBudget,
    planBudgetRemoved,
    exportLoading,
    handlePrint,
    selectedData,
    planBudgetToMap,
    type,
  } = props;

  const [isDeleteLoader, setIsDeleteLoader] = useState(false);
  const [deleteModalUUID, setDeleteModalUUID] = useState(null);
  const { isUserGracePeriodOver } = useContext(LoginContext);

  const handleDeleteRow = async (id) => {
    try {
      setIsDeleteLoader(true);
      const response = await api.planBudgetRemove({ plan_budget_id: id });
      if (response) {
        const obj = { plan_budget_id: id, funding: response.funding };
        planBudgetRemoved(obj);
        setIsDeleteLoader(false);
        setDeleteModalUUID(null);
        toast.success("Plan Budget Successfully Deleted!");
      }
    } catch (err) {
      console.log("err", err);
      setDeleteModalUUID(null);
      toast.error(err.message || "Something Went Wrong!");
      setIsDeleteLoader(false);
    }
  };

  const remainCheck = (data) => {
    if (
      parseFloat(data.amount_allocated ?? 0) === 0 &&
      parseFloat(data.amount_remaining ?? 0) === 0
    ) {
      return parseFloat(data.total_budget_amount ?? 0);
    } else {
      return parseFloat(data.amount_remaining ?? 0);
    }
  };

  return (
    <>
      {planBudgetToMap &&
        planBudgetToMap.length > 0 &&
        planBudgetToMap
          .filter((x) => !x.hasOwnProperty("isStats"))
          .map((fund, index) => (
            <tr key={`${index}-${fund.id}`} className='text-center'>
              {index === 0 && (
                <td
                  rowSpan={planBudgetToMap.length - 1}
                  className='px-3 py-2 text-[15px] text-[#4B6CB7] w-[20%] overflow-hidden text-ellipsis font-medium capitalize text-left'
                >
                  {type}
                </td>
              )}
              <td className='whitespace-normal px-3 py-2 text-sm text-gray-500 w-[20%] text-left'>
                {fund.service_sub_category.title}
              </td>
              <td className='whitespace-nowrap pr-2  py-2 text-sm text-gray-500 text-start'>
                <div>
                  {
                    <NumberFormatter
                      cssClass='text-gray-500 mr-10'
                      prefix={"$"}
                      value={fund.total_budget_amount ?? 0}
                    />
                  }
                </div>
              </td>
              <td className='whitespace-nowrap  py-2 text-sm text-gray-500 text-left'>
                <div className='text-gray-500'>
                  {
                    <NumberFormatter
                      prefix={"$"}
                      cssClass='px-0'
                      value={fund.amount_allocated ?? 0}
                    />
                  }
                </div>
              </td>
              <td className='whitespace-nowrap py-2 text-sm text-gray-500 text-left'>
                <div className='text-gray-500'>
                  {
                    <NumberFormatter
                      prefix={"$"}
                      cssClass='px-0'
                      value={fund.amount_delivered ?? 0}
                    />
                  }
                </div>
              </td>
              <td className='whitespace-nowrap  py-2 text-sm text-gray-500 text-left'>
                <div className='text-gray-500'>
                  {remainCheck(fund) === 0 ? (
                    "---"
                  ) : (
                    <NumberFormatter
                      prefix={"$"}
                      cssClass='pl-0'
                      value={remainCheck(fund)}
                    />
                  )}
                </div>
              </td>
              <td className='relative whitespace-nowrap px-3 py-2 text-sm text-gray-500 '>
                <>
                  {(exportLoading === LOADER_STATUS.PENDING &&
                    selectedData.uuid === fund.uuid) ||
                  (isDeleteLoader && deleteModalUUID === fund.uuid) ? (
                    <Loader isSmallView cssClass='mt-1 text-black' />
                  ) : (
                    <DropDown
                      isUserGracePeriodOver={isUserGracePeriodOver}
                      print
                      handlePrint={() => handlePrint(fund)}
                      openConfirmationModalHandler={() => {
                        setDeleteModalUUID(fund.uuid);
                        handleDeleteRow(fund.uuid);
                      }}
                      openEditSectionModalHandler={() => {
                        setEditPlanBudget(fund);
                      }}
                    />
                  )}
                </>
              </td>
            </tr>
          ))}

      <tr key={type} className='bg-[#ECFDF3]'>
        <td
          colSpan='2'
          className='text-gray-700 text-sm font-semibold whitespace-nowrap px-3 py-2'
        >
          Total
        </td>
        <td className='text-gray-700 text-sm font-semibold whitespace-nowrap px-3 py-2 text-left'>
          {
            <NumberFormatter
              cssClass={"bg-[#ECFDF3] border-[#ECFDF3] pl-0"}
              prefix={"$"}
              value={planBudgetToMap[0].total_budget_amount ?? 0}
            />
          }
        </td>
        <td className='text-gray-700 text-sm font-semibold whitespace-nowrap  py-2 text-left '>
          {
            <NumberFormatter
              cssClass={"bg-[#ECFDF3] border-[#ECFDF3] pl-0"}
              prefix={"$"}
              value={planBudgetToMap[0].amount_allocated ?? 0}
            />
          }
        </td>
        <td className='text-gray-700 text-sm font-semibold whitespace-nowrap  py-2 text-left'>
          {
            <NumberFormatter
              cssClass={"bg-[#ECFDF3] border-[#ECFDF3] pl-0"}
              prefix={"$"}
              value={planBudgetToMap[0].amount_delivered ?? 0}
            />
          }
        </td>
        <td className='text-gray-700 text-sm font-semibold whitespace-nowrap  py-2 text-left'>
          {
            <NumberFormatter
              cssClass={"bg-[#ECFDF3] border-[#ECFDF3] pl-0"}
              prefix={"$"}
              value={planBudgetToMap[0].amount_remaining ?? 0}
            />
          }
        </td>
        <td />
      </tr>
    </>
  );
};

export default PlanBudgetItem;
