import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "../../../../../components/common/Button/CustomButton";
import CircularLoader from "../../../../../components/common/CircularLoader/CircularLoader";
import ClientTaskModal from "./ClientTaskModal";
import { getPaginationObj } from "../../../../../Utils/utils";
import {
  emptyPaginationObj,
  FUNDING_CASE_NOTES_LIMIT,
  LOADER_STATUS,
} from "../../../../../Constants/constant";
import PaginationComponent from "../../../../../components/common/Pagination/index";
import ClientTaskItem from "./ClientTaskItem";

const ClientTask = (props) => {
  const {
    clientTaskListLoading,
    clientTaskList,
    uuid,
    supportCoordinatorsOptions,
    selectedFunding,
    createclientTaskListLoading,
    updateClientTaskResponse,
    ClientTaskListUnmount,
    removeClientTaskSuccess,
    clientTaskResponse,
    periorityList,
    getPeriorityListRequest,
    getCatogeryListRequest,
    CatogeryList,
    cosParticipantsProfile,
    createTaskRequest,
    updateClientTask,
    gettaskList,
  } = props;

  const [openCreateTaskModal, setOpenCreateTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [paginationObjCLT, setPaginationObjCLT] = useState(
    clientTaskList ? getPaginationObj(clientTaskList) : emptyPaginationObj
  );
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);

  useEffect(() => {
    if (clientTaskList) {
      setPaginationObjCLT({
        ...getPaginationObj(clientTaskList),
      });
    }
  }, [clientTaskList]);

  useEffect(() => {
    if (
      cosParticipantsProfile &&
      selectedFunding &&
      clientTaskList &&
      clientTaskList.current_page !== paginationObjCLT.page
    ) {
      handleFetchTask(paginationObjCLT.page);
    }
  }, [paginationObjCLT.page]);

  useEffect(() => {
    if (selectedFunding && cosParticipantsProfile) {
      handleFetchTask(1);
    }
  }, [selectedFunding, cosParticipantsProfile]);

  useEffect(() => {
    if (!clientTaskListLoading && renderLoading !== LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
    }
  }, [clientTaskListLoading]);

  const handleFetchTask = (page) => {
    setRenderLoading(LOADER_STATUS.PENDING);
    gettaskList({
      funding_id: selectedFunding.uuid,
      pt_id: cosParticipantsProfile.uuid,
      page,
      per_page: FUNDING_CASE_NOTES_LIMIT,
    });
  };

  return (
    <>
      {clientTaskListLoading ? (
        <div
          className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
        >
          <CircularLoader
            classes="flex justify-center items-center"
            classloader="loader-l"
          />
        </div>
      ) : (
        <>
          <div className="mt-4 relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <div className=" bg-gray-100 font-medium h-20 py-4 pl-4 border col-span-2 flex justify-between">
              <div className="text-[#101828] font-semibold text-lg pt-2">
                Participant Task
              </div>
              <div className="flex mr-2">
                <div className="pt-1">
                  <CustomButton
                    clickHandler={() => {
                      setSelectedTask(null);
                      setOpenCreateTaskModal(true);
                    }}
                    label="Participant Task"
                    Icon={<BiPlus />}
                    type="button"
                  />
                </div>
              </div>
            </div>
            <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
              <thead className="capitalize">
                <tr className="bg-[#F5FAFF]">
                  <th
                    scope="col"
                    className="min-w-[12rem] pl-5 px-3 py-3.5 text-left text-xs text-gray-500"
                  >
                    Task
                  </th>
                  {/* <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs text-gray-500"
            >
              Category
            </th> */}
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs text-gray-500"
                  >
                    Assigned to
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs text-gray-500"
                  >
                    Due Date
                  </th>
                  {/* <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs text-gray-500"
            >
              Priority
            </th> */}
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs text-gray-500"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-xs text-gray-500 text-center"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {clientTaskList &&
                clientTaskList.data &&
                clientTaskList.data.length > 0
                  ? clientTaskList.data.map((item, index) => (
                      <ClientTaskItem
                        key={index}
                        item={item}
                        setOpenCreateTaskModal={setOpenCreateTaskModal}
                        setSelectedTask={setSelectedTask}
                        index={index}
                      />
                    ))
                  : clientTaskList &&
                    clientTaskList.data &&
                    clientTaskList.data.length === 0 &&
                    renderLoading === LOADER_STATUS.COMPLATE && (
                      <tr>
                        <td
                          colSpan={7}
                          className="py-6 text-center text-red-600"
                        >
                          No Data Found !
                        </td>
                      </tr>
                    )}
              </tbody>
            </table>
          </div>
          <div className="py-2">
            <PaginationComponent
              page={paginationObjCLT.page}
              activeListLength={
                clientTaskList && clientTaskList.data ? clientTaskList.data : []
              }
              limit={FUNDING_CASE_NOTES_LIMIT}
              totalList={
                paginationObjCLT.totalPages === 1
                  ? clientTaskList && clientTaskList.data
                    ? clientTaskList.data.length
                    : 0
                  : paginationObjCLT.total
              }
              handlePagination={(e) => {
                const obj = paginationObjCLT;
                obj.page = e;
                setPaginationObjCLT({ ...obj });
              }}
              totalPages={paginationObjCLT.totalPages}
            />
          </div>

          {openCreateTaskModal && (
            <ClientTaskModal
              createclientTaskListLoading={createclientTaskListLoading}
              selectedFundingId={selectedFunding.id}
              updateClientTaskResponse={updateClientTaskResponse}
              ClientTaskListUnmount={ClientTaskListUnmount}
              removeClientTaskSuccess={removeClientTaskSuccess}
              clientTaskResponse={clientTaskResponse}
              periorityList={periorityList}
              getPeriorityListRequest={getPeriorityListRequest}
              getCatogeryListRequest={getCatogeryListRequest}
              CatogeryList={CatogeryList}
              updateClientTask={updateClientTask}
              editObjact={selectedTask}
              list={supportCoordinatorsOptions}
              uuid={uuid}
              setOpenCTModel={setOpenCreateTaskModal}
              openModel={openCreateTaskModal}
              participantId={cosParticipantsProfile?.id}
              createTaskRequest={createTaskRequest}
            />
          )}
        </>
      )}
    </>
  );
};

export default ClientTask;
