import React, { useEffect, useState } from "react";
import CustomButton from "../../../../components/common/Button/CustomButton";
import ModalSkeleton from "../../../../components/Modals/ModalSkeleton";
import { toast } from "react-toastify";
import moment from "moment";
import { checkEmail, checkPhoneNo } from "../../../../Utils/FormValidation";
import InputField from "../../../../components/common/Input/Input";
import { apiFormDataRequest } from "../../../../helpers/Requests";
import { hasBlankSpaces } from "../../../../Utils/utils";
import FileUploadInput from "../../../../components/common/FileUpload/FileUploadInput";
import { REGEX_PHONE_NUMBER } from "Constants/constant";

const AddNewApplicantModal = (props) => {
  const { handleClose, openModel, getApplicantsList, isUserGracePeriodOver } =
    props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [applicantCity, setApplicantCity] = useState("");
  const [resume, setResume] = useState("");
  const [appliedOn, setAppliedOn] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (resume && getError("resume")) {
      setErrors({ ...errors, resume: null });
    }
  }, [resume]);

  const getError = (value) => {
    if (!value) return "";
    // return errors[value] && errors[value].length > 0 ? errors[value] : "";

    return errors[value] && errors[value].length > 0 && value !== "contact"
      ? `Field is Required`
      : errors[value] && errors[value].length > 0 && value === "contact"
      // ? "Phone No required & must start with 04, 1300 or 1800 & 10 digits"
      ? "Phone No required & must be digits only"
      : "";
  };

  // const getError = (value) => {
  //   if (!value) return "";
  //   // return errors[value] && errors[value].length > 0 ? errors[value] : "";
  //   return errors[value] && errors[value].length > 0
  //     ? "This field is required."
  //     : "";
  // };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({ ...errors, [name]: `${name} is Required` });
    } else if (name === "email" && value && value.length > 0) {
      const inValid = checkEmail(value);
      if (inValid) {
        setErrors({ ...errors, email: "Invalid email" });
      } else {
        setErrors({ ...errors, email: null });
      }
      return;
    } else if (errors[name] && !hasBlankSpaces(value) && value.length > 0) {
      setErrors({ ...errors, [name]: null });
    }
    if (name === "contact" && value.length > 0) {
      const inValidPhoneNo = checkPhoneNo(value);
      if (inValidPhoneNo) {
        setErrors({ ...errors, contact: "phone no must start with 0" });
      } else {
        setErrors({ ...errors, contact: null });
      }
    }
  };

  const handleFile = (e) => setResume(e);

  const handleClear = () => {
    setFirstName("");
    setLastName("");
    setAppliedOn("");
    setApplicantCity("");
    setEmail("");
    setContact("");
    setJobTitle("");
    setResume("");
    setErrors({});
  };

  const handleSubmitButton = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    const fnError = errors;
    if (!firstName || firstName.length === 0) {
      Object.assign(fnError, { firstName: "First Name is Required" });
    }
    if (!lastName || lastName.length === 0) {
      Object.assign(fnError, { lastName: "Last Name is Required" });
    }
    if (!appliedOn || appliedOn.length === 0) {
      Object.assign(fnError, { appliedOn: "Applied On is Required" });
    }
    if (!jobTitle || !jobTitle.length === 0) {
      Object.assign(fnError, { jobTitle: "Job Title is Required" });
    }
    if (!email || email.length <= 0 || checkEmail(email)) {
      const inValid = checkEmail(email);
      if (inValid) {
        Object.assign(fnError, { email: "Invalid email" });
      } else {
        Object.assign(fnError, { email: "Email is Required" });
      }
    }
    if (!contact || contact.length <= 0) {
      Object.assign(fnError, { contact: "Contact is Required" });
    }
    if (!applicantCity || applicantCity.length <= 0) {
      Object.assign(fnError, { applicantCity: "Applicant City is Required" });
    }
    if (!resume) {
      Object.assign(fnError, { resume: "Resume is Required" });
    }

    // console.log("fnError", fnError);
    if (
      Object.values(fnError).filter((x) => x !== null).length > 0 ||
      checkEmail(email)
    ) {
      setErrors({ ...fnError });
      return;
    }
    let formData = new FormData();

    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("job_type", jobTitle);
    formData.append("phone", contact);
    formData.append("city", applicantCity);
    formData.append("email", email);
    formData.append("applied_on", appliedOn);
    formData.append("resume", resume);
    // formData.append("photo", resume);

    try {
      setIsLoading(true);
      const res = await apiFormDataRequest("hrm/store/job-applicant", formData);
      if (res && res.status === 201) {
        handleClear();
        toast.success("Applicant Added Successfully");
        setIsLoading(false);
        getApplicantsList();
        handleClose(false);
        // setTimeout(() => navigate("/hrm/all-applicants"), 1200);
        return;
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setError(err ? err.message : "Something Went Wrong");
      toast.error(err ? err.message : "Something Went Wrong");
    }
  };

  const modalFooter = () => (
    <>
      <CustomButton
        showLoading={isLoading}
        isDisabled={isLoading || isUserGracePeriodOver}
        label='Submit'
        clickHandler={handleSubmitButton}
      />
    </>
  );
  return (
    <ModalSkeleton
      isOpen={openModel}
      title='Add Applicant'
      cssClass={"w-[50%]"}
      modalFooter={modalFooter()}
      closeModal={() => handleClose(false)}
    >
      <div className='w-full'>
        <p className='px-2 bg-white text-sm text-gray-500'>
          Please enter following details to create new applicant
        </p>
        <div className='sm:rounded-md sm:overflow-hidden'>
          <div className='px-2 py-5 bg-white'>
            <div className='grid grid-cols-6 gap-6'>
              <div className='col-span-6 sm:col-span-3'>
                <InputField
                  label='First Name'
                  type='text'
                  // placeHolder="Enter First Name"
                  handleChange={setFirstName}
                  value={firstName}
                  ErrorMessage={getError("firstName")}
                  blurHandler={handleBlur}
                  name='firstName'
                />
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <InputField
                  label='Last Name'
                  type='text'
                  // placeHolder="Enter Last Name"
                  handleChange={setLastName}
                  value={lastName}
                  ErrorMessage={getError("lastName")}
                  blurHandler={handleBlur}
                  name='lastName'
                />
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <InputField
                  // min={moment().format("DD-MM-YYYY")}
                  label='Applied On'
                  type='date'
                  handleChange={(e) =>
                    moment(e).isAfter() ? "" : setAppliedOn(e)
                  }
                  value={appliedOn}
                  ErrorMessage={getError("appliedOn")}
                  blurHandler={handleBlur}
                  name='appliedOn'
                />
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <InputField
                  label='Job Title'
                  type='text'
                  value={jobTitle}
                  // placeHolder="Enter Job Title"
                  handleChange={setJobTitle}
                  ErrorMessage={getError("jobTitle")}
                  name='jobTitle'
                  blurHandler={handleBlur}
                />
              </div>

              <div className='col-span-6 sm:col-span-3'>
                <InputField
                  label='Email Address'
                  type='email'
                  value={email}
                  // placeHolder="Enter Email Address"
                  handleChange={setEmail}
                  ErrorMessage={getError("email")}
                  name='email'
                  blurHandler={handleBlur}
                />
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <InputField
                  label='Phone Number'
                  value={contact}
                  maxLength={10}
                  // placeHolder="Enter Contact"
                  pattern={REGEX_PHONE_NUMBER}
                  type='number'
                  handleChange={(e) => {
                    const sanitizedValue = e.replace(/\D/g, "");
                    const formattedValue = sanitizedValue.slice(0, 10);
                    setContact(formattedValue);
                  }}
                  ErrorMessage={getError("contact")}
                  blurHandler={handleBlur}
                  name='contact'
                />
              </div>
              <div className='col-span-6 sm:col-span-3'>
                <InputField
                  label='Applicant Suburb'
                  type='text'
                  value={applicantCity}
                  // placeHolder="Enter Applicant Suburb"
                  handleChange={setApplicantCity}
                  ErrorMessage={getError("applicantCity")}
                  blurHandler={handleBlur}
                  name='applicantCity'
                />
              </div>
            </div>
          </div>
          <div className='px-2 py-5 bg-white space-y-6'>
            <div className='col-span-2'>
              <label htmlFor='' className='did-floating-label text-start'>
                File Attachment
              </label>
              <div>
                <FileUploadInput
                  type='resume'
                  handleFile={handleFile}
                  size={10}
                  file={resume}
                  handleDelete={() => setResume("")}
                />
              </div>
              {getError("resume") && (
                <span style={{ color: "red" }}>{getError("resume")}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default AddNewApplicantModal;
