import React, { useRef, useState, useEffect } from "react";
import { BiPlus } from "react-icons/bi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomButton from "../../../../components/common/Button/CustomButton";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import LoaderButton from "../../../../components/common/LoaderButton/LoaderButton";
import {
  emptyPaginationObj,
  LOADER_STATUS,
} from "../../../../Constants/constant";
import { apiGetRequest, apiPostRequest } from "../../../../helpers/Requests";
import { getUser } from "../../../../store/selector/auth.selector";
import {
  baseURLImage,
  getNameProfile,
  getPaginationObj,
  hasBlankSpaces,
} from "../../../../Utils/utils";

const ApplicantNotes = (props) => {
  const { applicant, isUserGracePeriodOver } = props;
  const myRef = useRef(null);
  const currentUser = useSelector(getUser);
  const [note, setNote] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [reachedLast, setReachedLast] = useState(false);
  const [fetchNotes, setFetchNotes] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(LOADER_STATUS.NA);

  useEffect(() => {
    setFetchLoader(LOADER_STATUS.PENDING);
    getNotes();
  }, []);

  useEffect(() => scrollToBottom(), [myRef]);

  const scrollToBottom = () => {
    if (myRef.current) myRef.current.scrollTop = 0;
  };

  useEffect(() => {
    if (reachedLast && paginationObj.totalPages > paginationObj.page) {
      const obj = paginationObj;
      obj.page = paginationObj.page + 1;
      setPaginationObj({ ...obj });
      getNotes();
    }
  }, [reachedLast]);

  const handleSubmit = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    try {
      if (!note) return setError("Note is Required");
      if (hasBlankSpaces(note) || note.length <= 0) {
        setError("Note is Required");
        return;
      }
      setError(null);

      const data = {
        applicant_id: applicant.id,
        notes: note,
      };
      setIsLoading(true);
      const res = await apiPostRequest("/hrm/applicant-notes", data);
      if (res.status === 201) {
        const obj = fetchNotes;
        obj.unshift(res.data.applicant_notes);
        setFetchNotes([...obj]);
        toast.success("Note added successfully");
        setNote("");
        setIsLoading(false);
      }
      setIsLoading(false);
      scrollToBottom();
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  const getNotes = async () => {
    try {
      if (!reachedLast) setIsFetchLoading(true);

      const res = await apiGetRequest(
        `/hrm/applicant-notes?applicant_id=${applicant.uuid}&page=${parseInt(
          paginationObj.page
        )}`
      );
      if (res.status === 200) {
        setPaginationObj(getPaginationObj({ ...res.data.applicant_notes }));
        const obj = fetchNotes;
        obj.push(...res.data.applicant_notes.data);
        setFetchNotes([...obj]);
        setReachedLast(false);
        setFetchLoader(LOADER_STATUS.COMPLATE);
      }
      setIsFetchLoading(false);
      setReachedLast(false);
    } catch (err) {
      setReachedLast(false);
      setIsFetchLoading(false);
      console.log("err", err);
    }
  };

  // const handleBlur = (e) => {
  //   const value = e.target.value;
  //   if (hasBlankSpaces(value) || value.length === 0) {
  //     setError("Note is Required");
  //   } else if (!hasBlankSpaces(value) && value.length > 0) {
  //     setError(null);
  //   }
  // };

  const handleScroll = () => {
    if (myRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = myRef.current;
      if (
        scrollTop + 1 + clientHeight >= scrollHeight &&
        paginationObj.totalPages > paginationObj.page
      ) {
        setReachedLast(true);
      }
    }
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };
  const handleFocusOnApplicantNotes = () => {
    if (document.getElementById("applicantNotesTextArea")) {
      document.getElementById("applicantNotesTextArea").focus();
    }
  };

  return (
    <>
      <div className='min-h-full'>
        <main className='py-5'>
          <section aria-labelledby='notes-title'>
            <div className='bg-gray-50 px-4 py-6 sm:px-6 rounded-md shadow-sm'>
              <div className='flex space-x-3'>
                <div className='flex-shrink-0'>
                  <img
                    className='h-10 w-10 rounded-full object-cover'
                    onError={(e) => handleImageError(e, currentUser.name, " ")}
                    src={
                      currentUser.profile_picture
                        ? baseURLImage(currentUser.profile_picture)
                        : getNameProfile(currentUser.name, " ")
                    }
                    alt='Sp-avatar'
                  />
                </div>
                <div className='min-w-0 flex-1'>
                  <div>
                    <label htmlFor='submit' className='sr-only'>
                      About
                    </label>
                    <textarea
                      id='applicantNotesTextArea'
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      name='submit'
                      rows={3}
                      className='shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md overflow-y-auto'
                      // placeholder="Add a note"
                    />
                    {error && <span className='errorMessage'>{error}</span>}
                  </div>
                  <div className='mt-3 flex items-center justify-between'>
                    <CustomButton
                      showLoading={isLoading}
                      isDisabled={
                        isLoading || isFetchLoading || isUserGracePeriodOver
                      }
                      label='Submit'
                      clickHandler={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='divide-y divide-gray-200'>
              <div
                onScroll={handleScroll}
                ref={myRef}
                className='px-4 py-6 sm:px-6 overflow-y-auto custom-scroll applicantNotesContainer'
              >
                {isFetchLoading ? (
                  <div
                    style={{
                      justifyContent: "center",
                      minHeight: "100%",
                      height: "100%",
                    }}
                    className='flex items-center'
                  >
                    <CircularLoader
                      classes='flex justify-center items-center'
                      classloader='loader-l'
                    />
                  </div>
                ) : (
                  <>
                    <ul className='space-y-8'>
                      {fetchNotes &&
                        fetchNotes.length > 0 &&
                        fetchNotes.map((comment, index) => (
                          <li key={index}>
                            <div className='flex space-x-3'>
                              <div className='flex-shrink-0'>
                                <img
                                  onError={(e) =>
                                    handleImageError(
                                      e,
                                      comment.author.name || " ",
                                      " "
                                    )
                                  }
                                  className='h-10 w-10 rounded-full object-cover'
                                  src={
                                    comment.author.profile_picture
                                      ? baseURLImage(
                                          comment.author.profile_picture
                                        )
                                      : getNameProfile(
                                          comment.author.name || " ",
                                          " "
                                        )
                                  }
                                  alt='profilePicture'
                                />
                              </div>
                              <div>
                                <div className='text-sm'>
                                  <p className='font-medium text-gray-900'>
                                    {comment.author.name}
                                  </p>
                                </div>
                                <div className='mt-1 text-sm text-gray-700'>
                                  <p>{comment.notes}</p>
                                </div>
                                <div className='mt-2 text-sm space-x-2'>
                                  <span className='text-gray-500 font-medium'>
                                    {comment.posted_time}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      {reachedLast &&
                        paginationObj.totalPages >= paginationObj.page && (
                          <div
                            className='flex justify-center
                  items-center'
                          >
                            <LoaderButton
                              classes='
                    flex
                    justify-center
                    items-center
                    py-2
                    px-4
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-white
                    bg-blue-dark
                    hover:bg-blue-dark
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-blue-dark'
                              svgClasses='inline mr-3 w-5 h-5 text-white animate-spin'
                              textbutton='Loading More...'
                            />
                          </div>
                        )}
                      {fetchNotes &&
                        fetchNotes.length === 0 &&
                        fetchLoader === LOADER_STATUS.COMPLATE && (
                          <div className='bg-white'>
                            <div className='max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
                              <h2 className='block pt-6 text-xl font-semibold text-gray-900'>
                                No Applicant Notes
                              </h2>
                              <div className='p-6 flex justify-center'>
                                <div className='mt-2 text-sm text-gray-700 inline-flex hover:underline underline-offset-1 cursor-pointer hover:text-blue-800'>
                                  Get started by creating a new applicant Note
                                </div>
                              </div>
                              <div className='mt-8 flex justify-center'>
                                <div className='ml-3 inline-flex'>
                                  <CustomButton
                                    label='Applicant Notes'
                                    clickHandler={handleFocusOnApplicantNotes}
                                    Icon={<BiPlus />}
                                    cssClass='w-full border border-gradient shadow px-4 py-2 rounded-md bg-gradient text-white flex items-center space-x-1'
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default ApplicantNotes;
