import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import SelectOption from "components/common/Select/Select";
import moment from "moment";
import {
  FUNDIND_SOURCE_OPTIONS,
  LOADER_STATUS,
  MOMENT_ADD_OPTIONS,
  TOAST_TYPE,
  planManagedByOptions,
} from "Constants/constant";
import { futureDateValidation, validateCurrentDate } from "Utils/utils";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import SwitchInput from "components/common/Input/SwitchInput";
import { NumericFormat } from "react-number-format";
import { isArray } from "lodash";
import { showToast } from "store/actions/general.actions";
import { TOAST_IDS } from "store/constants";
import { useDispatch } from "react-redux";

const FundingSetupModal = (props) => {
  const {
    participant,
    storeAndUpdateFundingSetupRequest,
    fundingSetupLoading,
    setOpenModal,
    openModal,
    catalogVersions,
    editFundingData = null,
    storeAndUpdateError,
    isUserGracePeriodOver,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const dispatch = useDispatch();

  const [fundingSource, setFundingSource] = useState(null);
  const [fundingManagement, setFundingManagement] = useState(null);
  const [storeLoader, setStoreLoader] = useState(LOADER_STATUS.NA);
  const [selectedCatalogVersion, setSelectedCatalogVersion] = useState(null);
  const [status, setStatus] = useState(true);

  const setPlanManagements = () => {
    const parsePlanBy =
      typeof participant.plan_managed_by === "string" &&
      participant.plan_managed_by.includes("label")
        ? JSON.parse(participant.plan_managed_by)
        : [];
    const planData =
      parsePlanBy && isArray(parsePlanBy) && parsePlanBy.length > 0
        ? parsePlanBy[0]
        : null;

    setFundingManagement(planData);
  };

  useEffect(() => {
    if (!editFundingData) {
      reset();
      setPlanManagements();
    } else {
      const parseInJson = (data) => {
        if (data) {
          if (typeof data === "string") {
            Object.assign(editFundingData, {
              funding_management: JSON.parse(data),
            });
            return typeof JSON.parse(data);
          } else {
            Object.assign(editFundingData, {
              funding_management: data,
            });
            return typeof data;
          }
        }
        return null;
      };
      if (editFundingData) {
        do {
          parseInJson(editFundingData.funding_management);
        } while (parseInJson(editFundingData.funding_management) === "string");
        setFundingManagement(
          editFundingData.funding_management &&
            isArray(editFundingData.funding_management)
            ? editFundingData.funding_management[0]
            : editFundingData.funding_management
        );
        setFundingSource(
          FUNDIND_SOURCE_OPTIONS.find(
            (x) => x.value === editFundingData.funding_source
          ) || null
        );
        setStatus(editFundingData.status);
        reset(editFundingData);
      }
    }
  }, [editFundingData]);

  useEffect(() => {
    if (!editFundingData) {
      reset();
    }
  }, []);

  useEffect(() => {
    if (catalogVersions && editFundingData) {
      const version = catalogVersions.find(
        (x) => x.value === editFundingData.price_catalog_version_id
      );
      if (version) {
        setSelectedCatalogVersion(version);
      }
    }
  }, [catalogVersions, editFundingData]);

  useEffect(() => {
    if (
      !fundingSetupLoading &&
      storeLoader === LOADER_STATUS.PENDING &&
      storeAndUpdateError.length === 0
    ) {
      setStoreLoader(LOADER_STATUS.COMPLATE);
      closeModal();
    }
  }, [fundingSetupLoading]);

  useEffect(() => {
    if (participant && !editFundingData) setPlanManagements();
  }, [participant]);

  const watchFormData = watch();

  useEffect(() => {
    if (fundingSource !== null) {
      setValue("funding_source", fundingSource);
      clearErrors("funding_source");
    }
    if (selectedCatalogVersion !== null) {
      setValue("price_catalog_version_id", selectedCatalogVersion);
      clearErrors("price_catalog_version_id");
    }
    if (fundingManagement !== null) {
      setValue("funding_management", fundingManagement);
      clearErrors("funding_management");
    } else {
      setValue("funding_management", null);
    }
  }, [fundingSource, fundingManagement, selectedCatalogVersion]);

  useEffect(() => {
    setValue("status", status);
    clearErrors("status");
  }, [status]);

  const closeModal = () => {
    setOpenModal(false);
    clearErrors();
    reset();
    setFundingSource(null);
    setPlanManagements();
  };

  useEffect(() => {
    if (watchFormData.start_date) {
      setValue(
        "progress_date",
        moment(watchFormData.start_date).add(3, "M").format("YYYY-MM-DD")
      );
    }
  }, [watchFormData.start_date]);

  useEffect(() => {
    if (watchFormData.end_date) {
      setValue(
        "review_date",
        moment(watchFormData.end_date).subtract(1, "M").format("YYYY-MM-DD")
      );
    }
  }, [watchFormData.end_date]);

  const isFundingExpired = (end_date) => {
    const currentDate = new Date();
    const fundingenddate = new Date(end_date);

    return currentDate >= fundingenddate;
  };

  const fundingenddate = watchFormData.end_date;

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    // console.log("data", data);
    if (data.funding_management) {
      const fmData = isArray(data.funding_management)
        ? data.funding_management
        : [data.funding_management];
      Object.assign(data, {
        funding_management: JSON.stringify(fmData),
      });
    }
    Object.assign(data, {
      price_catalog_version_id: data.price_catalog_version_id.value,
      review_date: moment(watchFormData.end_date)
        .subtract(1, "M")
        .format("YYYY-MM-DD"),
      progress_date: moment(watchFormData.start_date)
        .add(3, "M")
        .format("YYYY-MM-DD"),
      pt_id: participant.id,
      funding_source: data.funding_source.value,
    });
    if (editFundingData) {
      Object.assign(data, {
        funding_id: editFundingData.id,
      });
    }
    setStoreLoader(LOADER_STATUS.PENDING);
    storeAndUpdateFundingSetupRequest({
      payload: data,
      isUpdate: editFundingData ? true : false,
    });
  };

  const content = () => {
    return (
      <>
        <div className='mt-2'>
          <p className='text-sm text-gray-500'>
            Please enter the following details to
            {`${editFundingData ? " update existing" : " create"}`} funding
            budget
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='mt-3 space-y-3'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("funding_source", {
                    required: true,
                  })}
                  name='funding_source'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Funding Source <span className='text-red-600'>*</span>
              </label>
              <SelectOption
                cssClass='mt-1'
                isSearch={false}
                data={FUNDIND_SOURCE_OPTIONS}
                selectedOption={fundingSource}
                handleChange={setFundingSource}
                // placeHolder="Select Funding Source"
              />
              {errors.funding_source && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("funding_management", {
                    required: true,
                    disabled: editFundingData ? true : false,
                  })}
                  name='funding_management'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Funding Management <span className='text-red-600'>*</span>
              </label>
              <SelectOption
                isDisabled={editFundingData ? true : false}
                cssClass='mt-1'
                isSearch={false}
                data={planManagedByOptions}
                selectedOption={fundingManagement}
                handleChange={setFundingManagement}
                // placeHolder="Select Funding Management"
              />
              {errors?.funding_management?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="col-span-1">
              <div className="hidden">
                <select
                  {...register("price_catalog_version_id", {
                    required: true,
                  })}
                  name='price_catalog_version_id'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Catalogue Version <span className='text-red-600'>*</span>
              </label>
              <SelectOption
                cssClass='mt-1'
                isSearch={false}
                data={catalogVersions}
                selectedOption={selectedCatalogVersion}
                handleChange={setSelectedCatalogVersion}
                // placeHolder="Select Catalog Version"
              />
              {errors?.price_catalog_version_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='total_contract_amount'
                className='block text-sm font-medium text-gray-700'
              >
                Total Amount <span className='text-red-600'>*</span>
              </label>
              <div className='mt-1'>
                <input
                  type='hidden'
                  {...register("total_contract_amount", {
                    required: true,
                  })}
                />
                <NumericFormat
                  min={1}
                  type='text'
                  maxLength={"13"}
                  className={`focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md 
                
                  `}
                  // className={`focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${
                  //   isFundingExpired(fundingenddate) ? "bg-gray-200" : ""
                  // }`}
                  // disabled={isFundingExpired(fundingenddate)}
                  value={watchFormData.total_contract_amount}
                  thousandsGroupStyle='thousand'
                  thousandSeparator=','
                  valueIsNumericString={true}
                  decimalScale={2}
                  prefix={"$"}
                  allowLeadingZeros={false}
                  onValueChange={(e) => {
                    const { value } = e;
                    if (value > 0) {
                      setValue("total_contract_amount", value);
                      clearErrors("total_contract_amount");
                    } else {
                      setValue("total_contract_amount", null);
                    }
                  }}
                />
                {errors?.total_contract_amount?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-x-4">
            {/* Start Date Input */}
            <div className="col-span-1">
              <label
                htmlFor='start_date'
                className='block text-sm font-medium text-gray-700'
              >
                Funding Start Date <span className='text-red-600'>*</span>
              </label>
              <div className='mt-1'>
                <input
                  {...register("start_date", {
                    required: true,
                  })}
                  type="date"
                  //min={moment().format("YYYY-MM-DD")}
                  name="start_date"
                  id="start_date"
                  className={`focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md `}
                  // className={`focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${
                  //   isFundingExpired(fundingenddate) ? "bg-gray-200" : ""
                  // }`}
                  // disabled={isFundingExpired(fundingenddate)} // Disable if funding is expired
                />
                {errors?.start_date?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>

            {/* End Date Input */}
            <div className="col-span-1">
              <label
                htmlFor='time'
                className='block text-sm font-medium text-gray-700'
              >
                Funding End Date <span className='text-red-600'>*</span>
              </label>
              <div className='mt-1'>
                <input
                  {...register("end_date", {
                    required: true,
                    pattern: futureDateValidation(
                      watchFormData.end_date,
                      6,
                      MOMENT_ADD_OPTIONS.MONTH,
                      watchFormData.start_date,
                      true
                    ),
                  })}
                  type="date"
                  name="end_date"
                  id="end_date"
                  className={`focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md `}
                  // className={`focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${
                  //   isFundingExpired(fundingenddate) ? "bg-gray-200" : ""
                  // }`}
                  // disabled={isFundingExpired(fundingenddate)} // Disable if funding is expired
                />
                {errors?.end_date?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
                {futureDateValidation(
                  watchFormData.end_date,
                  6,
                  MOMENT_ADD_OPTIONS.MONTH,
                  watchFormData.start_date,
                  true
                ) instanceof RegExp &&
                  errors?.end_date?.type === "pattern" && (
                    <span className="text-sm text-red-500">
                      Date should be more than 6 months
                    </span>
                  )}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-x-4">
            <div className="col-span-1">
              <label
                htmlFor='progress_date'
                className='block text-sm font-medium text-gray-700'
              >
                Progress Date
              </label>
              <div className='mt-1'>
                <input
                  {...register("progress_date", {
                    required: true,
                    disabled: true,
                  })}
                  value={moment(watchFormData.start_date)
                    .add(3, "M")
                    .format("YYYY-MM-DD")}
                  disabled
                  type='date'
                  name='progress_date'
                  id='progress_date'
                  className='bg-gray-200 focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                />
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='review_date
                '
                className='block text-sm font-medium text-gray-700'
              >
                Review Date
              </label>
              <div className='mt-1'>
                <input
                  {...register("review_date", {
                    required: true,
                    disabled: true,
                  })}
                  value={moment(watchFormData.end_date)
                    .subtract(1, "M")
                    .format("YYYY-MM-DD")}
                  // moment(watchFormData.start_date)
                  // .add(9, "M")
                  // .format("YYYY-MM-DD")
                  disabled
                  type='date'
                  name='review_date'
                  id='review_date'
                  className='bg-gray-200 focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="col-span-1">
              <label
                htmlFor='referred_date'
                className='block text-sm font-medium text-gray-700'
              >
                Referred Date <span className='text-red-600'>*</span>
              </label>
              <div className='mt-1'>
                <input
                  {...register("referred_date", {
                    required: true,
                    pattern: validateCurrentDate(
                      moment(watchFormData.referred_date),
                      moment(new Date()),
                      ["past", "present"],
                      true
                    ),
                  })}
                  type='date'
                  name='referred_date'
                  id='referred_date'
                  className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                />
                {errors?.referred_date?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
                {errors?.referred_date?.type === "pattern" && (
                  <span className='text-sm text-red-500'>
                    {validateCurrentDate(
                      moment(watchFormData.referred_date),
                      moment(new Date()),
                      ["past", "present"],
                      false
                    )}
                  </span>
                )}
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='sign_date'
                className='block text-sm font-medium text-gray-700'
              >
                Signed Date
              </label>
              <div className='mt-1'>
                <input
                  {...register("sign_date")}
                  // min={moment(watchFormData.start_date).format("YYYY-MM-DD")}
                  type='date'
                  name='sign_date'
                  id='sign_date'
                  className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="col-span-2">
              <label
                htmlFor='notes'
                className='block text-sm font-medium text-gray-700'
              >
                Notes
              </label>
              <div className='mt-1'>
                <textarea
                  {...register("notes")}
                  rows={3}
                  name='notes'
                  id='notes'
                  // placeholder="Notes..."
                  className='resize-none overflow-y-auto focus:bg-[#4b6bb710] border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="col-span-2">
              <SwitchInput
                handleChange={setStatus}
                checked={status}
                label={status ? "Is active" : "Is not active"}
                subLabel={
                  status ? "is the funding active" : "funding is not active"
                }
              />
            </div>
          </div>
          <button
            id='fundingButtonSubmit'
            className='hidden sr-only'
            type='submit'
          />
        </form>
      </>
    );
  };

  const modalFooter = () => (
    <CustomButton
      clickHandler={() => {
        const isEndDatePast = moment(watchFormData.end_date).isBefore();

        if (isEndDatePast && editFundingData) {
          dispatch(
            showToast({
              id: TOAST_IDS.EXPIRY_DATE_IN_FUNDING,
              type: TOAST_TYPE.DEFAULT,
              title: "The funding has expired, and updates cannot be made!",
            })
          );
          return;
        }
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("fundingButtonSubmit");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
      showLoading={fundingSetupLoading}
      isDisabled={fundingSetupLoading || isUserGracePeriodOver}
      label={`${editFundingData ? "Update" : "Submit"}`}
    />
  );

  return (
    <ModalSkeleton
      modalFooter={modalFooter()}
      isOpen={openModal}
      closeModal={closeModal}
      cssClass='w-[50%]'
      title={`${
        editFundingData ? "Update Existing Funding" : "Create New Funding"
      }`}
    >
      {content()}
    </ModalSkeleton>
  );
};

export default FundingSetupModal;
