import React, { useMemo } from "react";
import { classNames } from "Utils/utils";

const PricingItemCard = (props) => {
  const {
    renderFor,
    data,
    handleChange,
    selectedData,
    isSubscriptionNotChangeable,
  } = props;

  const dataToMap = useMemo(() => {
    if (renderFor === "participants") {
      return data;
    }
    return data ? Object.entries(data) : [];
  }, [data]);

  return (
    <div className='border border-1 border-gray-400 rounded-lg'>
      <div className='pt-8 pb-6 px-4'>
        <span className='bg-gradient text-white font-extrabold py-2 flex rounded-full justify-center items-center'>
          {renderFor === "year_1"
            ? "1 Year Price"
            : renderFor === "year_2"
            ? "2 Year Price"
            : "Participants"}
        </span>
      </div>
      {renderFor !== "participants" &&
        dataToMap.map(([participants, pricing], index) => (
          <div
            key={index}
            role='button'
            tabIndex={0}
            onClick={() => {
              if (isSubscriptionNotChangeable) return;
              if (pricing && Object.values(pricing).length === 0) return;
              handleChange("price", { [`${participants}`]: { ...pricing } });
            }}
            className={classNames(
              "transition duration-300 ease-in py-4 flex justify-center items-center",
              pricing && Object.values(pricing).length === 0
                ? "bg-gray-200 opacity-50 cursor-auto"
                : "hover:bg-gray-100",
              pricing &&
                Object.values(pricing).length > 0 &&
                selectedData?.selectedPrice?.price_id === pricing.price_id
                ? "bg-gradient text-white"
                : "text-black"
            )}
          >
            {pricing?.monthly ?? "-"}
          </div>
        ))}

      {renderFor === "participants" &&
        dataToMap.map((item, index) => (
          <div
            key={index}
            role='button'
            tabIndex={0}
            onClick={() => {
              if (isSubscriptionNotChangeable) return;
              if (item?.includes("200+")) return;
              handleChange("noOfParticipants", item);
            }}
            className={classNames(
              "transition duration-300 ease-in py-4 flex justify-center items-center",
              item?.includes("200+")
                ? "bg-gray-200 opacity-50 cursor-auto"
                : "hover:bg-gray-100",
              selectedData?.selectedParticipants === item
                ? " bg-gradient text-white"
                : "text-black"
            )}
          >
            {item}
          </div>
        ))}
    </div>
  );
};

export default PricingItemCard;
