import { connect } from "react-redux";
import COSInvoiceListPage from "./COSInvoiceListPage";
import {
  getCreatedCosInvoicesRequest,
  RemoveReviewCaseNoteList,
} from "store/actions/cos.actions";
import {
  createdCosInvoicesLoader,
  getCOSInvoiceLoaderSelector,
  getCreatedCosInvoiceSelector,
} from "store/selector/cos.selector";

const mapDispatchToProps = {
  emptyTableData: RemoveReviewCaseNoteList,
  getInvoicesList: getCreatedCosInvoicesRequest,
};

const mapStateToProps = (state) => {
  const cosInvoiceLoading = getCOSInvoiceLoaderSelector(state);
  const createdCosInvoiceLoading = createdCosInvoicesLoader(state);
  const createdCosInvoice = getCreatedCosInvoiceSelector(state);

  return {
    cosInvoiceLoading,
    createdCosInvoiceLoading,
    createdCosInvoice,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(COSInvoiceListPage);
