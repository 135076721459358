import React from "react";

const labelCheckBox = ({ heading, desc }) => {
  return (
    <div className="flex w-full items-start space-x-2 space-y-1">
      <span>
        {/* <input type="checkbox" className='h-4 w-4 rounded accent-teal-700' checked/> */}
      </span>
      <div>
        <p className="text-gray-700 font-medium text-sm">{heading}</p>
        <p className="text-gray-500 font-normal text-sm">{desc}</p>
      </div>
    </div>
  );
};

export default labelCheckBox;
