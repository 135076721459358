import React from "react";
import { Dialog } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import CustomButton from "../../../../components/common/Button/CustomButton";
import ModalSkeleton from "../../../../components/Modals/ModalSkeleton";

const NdisClaimNewModal = (props) => {
  const { openModel, handleClose, selectedNdisClaims } = props;

  const getTotalAmount = () => {
    if (selectedNdisClaims && selectedNdisClaims.length > 0) {
      const response = selectedNdisClaims.reduce((obj, data) => {
        Object.assign(obj, {
          total_amount:
            (obj["total_amount"] || 0) + parseFloat(data.original_rate),
        });
        return obj;
      }, {});

      if (response)
        return response.total_amount ? response.total_amount.toFixed(2) : 0;
    }
    return 0;
  };

  return (
    <ModalSkeleton isOpen={openModel} closeModal={handleClose}>
      <div className="w-1/2 absoute-appointments inline-block px-4 sm:px-8 align-middle transition-all transform bg-white shadow-xl border border-gray-100 rounded-lg">
        <button
          onClick={handleClose}
          className="absolute right-8 top-6 text-blue-800"
        >
          <AiOutlineClose className="h-5 w-5 text-blue-800" />
        </button>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 px-8 pb-8 space-y-6">
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h2"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Confirm Selection
              </Dialog.Title>
              <div>
                <p className="text-sm text-gray-500">
                  Please confirm the following.
                </p>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Total Case Notes:
                {selectedNdisClaims && selectedNdisClaims.length
                  ? selectedNdisClaims.length
                  : 0}
              </label>
            </div>
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700">
                Total Amount:
                {selectedNdisClaims && selectedNdisClaims.length
                  ? `$${getTotalAmount()}`
                  : `$0`}
              </label>
            </div>

            <div className="flex justify-end">
              <div className="flex space-x-2 w-[30%]">
                <CustomButton
                  showLoading={false}
                  isDisabled={false}
                  label={"Cancel"}
                  clickHandler={handleClose}
                  variant="secondary"
                />
                <CustomButton
                    showLoading={false}
                    isDisabled={false}
                    label={"Confirm"}
                    variant="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default NdisClaimNewModal;
