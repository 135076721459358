import React, { useContext } from "react";
import Header from "components/common/Header/Header";
import SideBarNew from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import { useNavigate } from "react-router";
import { classNames } from "Utils/utils";
import { callLogout } from "store/actions/auth.actions";
import { useDispatch } from "react-redux";
import { BiExit } from "react-icons/bi";
import CustomButton from "components/common/Button/CustomButton";
import StripeTabs from "../StripeTabs/StripeTabs";
import { useSearchParams } from "react-router-dom";

const SubscriptionDetailsDashboard = ({ istrailExpired = false }) => {
  const { sideBarPadding, unSubscribeChannels } = useContext(LoginContext);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    unSubscribeChannels();
    dispatch(callLogout());
    navigate("/");
  };

  return (
    <>
      <div className='min-full'>
        {istrailExpired === false && <SideBarNew classes='bg-gradient' />}
        <div
          className={classNames(
            istrailExpired === false
              ? sideBarPadding
                ? "lg:pl-[4rem] duration-300"
                : "lg:pl-64 duration-300"
              : "",
            `${sideBarPadding} flex flex-col fullScreenHeight`
          )}
        >
          <main className='flex-1'>
            {istrailExpired === false ? (
              <Header title='Subscription Details' cssClass={"z-[9]"} />
            ) : (
              <header className='bg-white flex items-center sticky top-0 z-[9] border-b border-gray-200 sm:px-6 lg:px-8 py-[20px] h-[80px]'>
                <div className='items-center w-full flex sm:flex sm:items-center sm:justify-between'>
                  <div className='flex-1 min-w-0'>
                    <h1 className='text-xl font-semi bold text-gray-900 capitalize'>
                      Subscription Details
                    </h1>
                  </div>
                  <div className='mt-4 flex justify-space-between sm:mt-0 space-x-2 print:hidden'>
                    <div className='md:mt-0 md:ml-auto space-x-2 md:space-x-4 hidden sm:flex'>
                      <CustomButton
                        clickHandler={handleLogout}
                        label={"Logout"}
                        Icon={<BiExit />}
                        variant={"danger"}
                      />
                    </div>
                  </div>
                </div>
              </header>
            )}
            <div className='min-full'>
              <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-6 py-8'>
                <StripeTabs tabQuery={searchParams?.get("tab")} />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default SubscriptionDetailsDashboard;
