import React from "react";
import TableHeader from "components/common/Table/TableHeader";
import NumberFormatter from "components/common/NumberFormatter/NumberFormatter";
import { formatDateIntoDMYToDisplay, textTruncate } from "Utils/utils";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { roleAccess } from "helpers/RolesPermission";

const ReportingTable = (props) => {
  const { fundingData } = props;

  return (
    <div className='bg-white mt-4 relative shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-auto'>
      <table className='w-full  divide-y divide-gray-300 bg-white'>
        <thead className='bg-gray-50 capitalize'>
          <tr className=''>
            <TableHeader label={"Participant Name"} isSort={false} />
            {roleAccess(
              [],
              PERMISSION_KEYS.PARTICIPANT,
              PERMISSION_KEYS_CHILDREN.PARTICIPANT.NDIS_NUMBER
            ) && <TableHeader label={"NDIS No."} isSort={false} />}
            {/* <TableHeader label={"Participant Status"} isSort={false} /> */}
            {/* <TableHeader label={"Funding Ref No."} isSort={false} /> */}
            <TableHeader label={"Funding Start Date"} isSort={false} />
            <TableHeader label={"Funding End Date"} isSort={false} />
            <TableHeader label={"Total Funding"} isSort={false} />
            <TableHeader label={"Funding Remaining"} isSort={false} />
            <TableHeader label='Funding Delivered' isSort={false} />
            <TableHeader label='Notes' isSort={false} cssClass='w-[20%]' />
            <th
              className={`px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
            >
              <div>
                <tr className='h-28 mx-4'>
                  <TableHeader label={"Service Name"} isSort={false} />
                  <TableHeader label={"Service Total Budget"} isSort={false} />
                  <TableHeader label={"Service Remaining"} isSort={false} />
                  <TableHeader label={"Service Delivered"} isSort={false} />
                </tr>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200 bg-white '>
          {fundingData.map((data, index) => (
            <tr
              key={index}
              className='even:bg-white odd:bg-gray-50 text-center'
            >
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize'>
                <div className='flex'>
                  {`${data.participant?.first_name} ${data.participant?.last_name}`}
                  {data.participant?.status ? (
                    <div
                      className={`${
                        data.participant?.status === "inactive"
                          ? "text-red-500"
                          : "text-green-500"
                      } flex items-center ml-2`}
                    >
                      <span
                        className={`${
                          data.participant?.status === "inactive"
                            ? "bg-red-50"
                            : "bg-green-50"
                        } text-xs inline-flex items-center px-2 py-0.5 rounded-full font-medium`}
                      >
                        {data.participant?.status === "inactive"
                          ? "In Active"
                          : "Active"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </td>
              {roleAccess(
                [],
                PERMISSION_KEYS.PARTICIPANT,
                PERMISSION_KEYS_CHILDREN.PARTICIPANT.NDIS_NUMBER
              ) && (
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {data.participant?.ndis_no}
                </td>
              )}
              {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize">
                {data.participant?.status ? (
                  <div
                    className={`${
                      data.participant?.status === "inactive"
                        ? "text-red-500"
                        : "text-green-500"
                    } flex items-center space-x-1`}
                  >
                    <span
                      className={`${
                        data.participant?.status === "inactive"
                          ? "bg-red-50"
                          : "bg-green-50"
                      } text-sm inline-flex items-center px-3 py-0.5 rounded-full font-medium`}
                    >
                      {data.participant?.status === "inactive"
                        ? "In Active"
                        : "Active"}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </td> */}
              {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
                {data.service_contract_ref_no}
              </td> */}
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                {formatDateIntoDMYToDisplay(data.start_date)}
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                {formatDateIntoDMYToDisplay(data?.end_date)}
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                <NumberFormatter
                  cssClass={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } p-0 text-sm`}
                  prefix={"$"}
                  value={
                    data?.total_service_contract_amount
                      ? data?.total_service_contract_amount
                          .toString()
                          .replace("$", "")
                      : 0
                  }
                />
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                <NumberFormatter
                  cssClass={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } p-0 text-sm`}
                  prefix={"$"}
                  value={
                    data?.amount_remaining
                      ? data?.amount_remaining.toString().replace("$", "")
                      : 0
                  }
                />
              </td>
              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                <NumberFormatter
                  cssClass={`${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  } p-0 text-sm`}
                  prefix={"$"}
                  value={
                    data?.amount_delivered
                      ? data?.amount_delivered.toString().replace("$", "")
                      : 0
                  }
                />
              </td>
              <td
                title={data?.notes}
                className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate text-start'
              >
                {textTruncate(data?.notes, 40, 39)}
              </td>
              <td>
                {data?.categories &&
                  data?.categories.length > 0 &&
                  data?.categories.map((item, childIndex) => (
                    <div
                      className={`grid grid-cols-4   ${
                        data?.categories.length > 1 &&
                        childIndex % 2 === 1 &&
                        " divide-y"
                      }`}
                      key={childIndex}
                    >
                      <div className=' text-sm text-gray-500 py-4 px-2'>
                        {item?.service_sub_category?.title}
                      </div>
                      <div className='whitespace-nowrap text-sm py-4  text-gray-500 truncate px-2'>
                        <NumberFormatter
                          cssClass={`${
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          } p-0 text-sm`}
                          prefix={"$"}
                          value={
                            item?.total_budget_amount
                              ? item?.total_budget_amount
                                  .toString()
                                  .replace("$", "")
                              : 0
                          }
                        />
                      </div>
                      <div className='whitespace-nowrap py-4  text-sm text-gray-500 truncate px-2'>
                        <NumberFormatter
                          cssClass={`${
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          } p-0 text-sm`}
                          prefix={"$"}
                          value={
                            item?.amount_remaining
                              ? item?.amount_remaining
                                  .toString()
                                  .replace("$", "")
                              : 0
                          }
                        />
                      </div>
                      <div className='whitespace-nowrap py-4 text-sm text-gray-500 truncate px-2'>
                        <NumberFormatter
                          cssClass={`${
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          } p-0 text-sm`}
                          prefix={"$"}
                          value={
                            item?.amount_delivered
                              ? item?.amount_delivered
                                  .toString()
                                  .replace("$", "")
                              : 0
                          }
                        />
                      </div>
                    </div>
                  ))}
              </td>
            </tr>
          ))}
          {!fundingData ||
            (fundingData.length === 0 && (
              <tr>
                <td colSpan={10} className='py-6 text-center text-red-600'>
                  <div>No Data Found!</div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default ReportingTable;
