import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import {
  addFiltersAndPagination,
  apiGetRequest,
} from "../../../../helpers/Requests";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import { LoginContext } from "../../../../helpers/LoginContext";
import { getNameProfile, getPaginationObj } from "../../../../Utils/utils";
import { useNavigate } from "react-router-dom";

import Search from "../../../../components/common/Search/Search";
import {
  emptyPaginationObj,
  PARTICIPANT_LIST_LIMIT,
  SORT_TYPE,
} from "../../../../Constants/constant";
import PaginationComponent from "../../../../components/common/Pagination/index";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import {
  EMPLOYEE_BREADCRUMB,
  EMPLOYEE_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import { IMAGE_URL } from "../../../../store/services/URL";
import TableHeader from "../../../../components/common/Table/TableHeader";
import Header from "../../../../components/common/Header/Header";
import { roleAccess } from "helpers/RolesPermission";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
} from "store/constants";

export default function Participants() {
  const navigate = useNavigate();
  const { sideBarPadding } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [search, setSearch] = useState(null);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );

  const getParticipants = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: PARTICIPANT_LIST_LIMIT });
    try {
      setIsLoading(true);
      // const res = await apiGetRequest("/hrm/employee-participants");
      let res = await apiGetRequest(
        addFiltersAndPagination("/hrm/employee-participants", filters)
      );
      if (res.status === 200) {
        setParticipants(res?.data?.data?.participant);
        setPaginationObj(
          getPaginationObj(
            res?.data?.data?.participant,
            res?.data?.data?.query_params
          )
        );
      }
      if (res.status !== 200) {
        console.log("error in fetching");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err.message);
    }
  };

  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order)
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
  }, [paginationObj]);

  useEffect(() => {
    const obj = {};
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (!search) handleFetch({ ...obj });
  }, [paginationObj.page]);

  const handleFetch = (obj) => {
    if (paginationObj.page) Object.assign(obj, { page: paginationObj.page });
    if (paginationObj.filter && !obj.hasOwnProperty("status")) {
      Object.assign(obj, { filter: paginationObj.filter });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status],
        },
      });
    }
    getParticipants({ ...obj });
  };

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
        }),
      600
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  const viewParticipantProfile = (uuid) => {
    navigate(`/participant-profile/${uuid}`);
  };

  const handleImageError = (e, fName, lName) => {
    return (e.target.src = getNameProfile(fName, lName));
  };

  return (
    <>
      <div className='min-h-full bg-white'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col`}
        >
          <main className='flex-1'>
            <Header title='All Participants' />
            <main className='max-w-7xl mx-auto'>
              <div className='px-6 border-gray-200 sticky top-20 z-[8] pb-0 py-4 bg-white'>
                <Breadcrumb
                  pages={EMPLOYEE_BREADCRUMB(
                    EMPLOYEE_BREADCRUMB_PAGES.ALL_PARTICIPANTS
                  )}
                />
              </div>
              <div
                className='px-6 flex justify-between items-center sticky z-8 bg-white py-2'
                style={{ top: "115px" }}
              >
                <div className='w-1/3 md:w-3/12 lg:w-3/12'></div>
                <Search setSearch={setSearch} search={search} />
              </div>
              <div className='px-6 bg-white flex flex-col'>
                <div className='min-w-full  align-middle'>
                  <div className='shadow ring-1 ring-black ring-opacity-5 rounded-md'>
                    <table className='min-w-full divide-y divide-gray-300'>
                      <thead
                        className='bg-blue-50 sticky capitalize'
                        style={{ top: "168px" }}
                      >
                        <tr className='bg-gray-50 z-10'>
                          <TableHeader
                            sortName='first_name'
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label={"Name"}
                          />
                          {roleAccess(
                            [],
                            PERMISSION_KEYS.PARTICIPANT,
                            BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT
                              .PRIMARY_PHONE
                          ) && (
                            <th
                              scope='col'
                              className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                            >
                              Contact
                            </th>
                          )}
                          <th
                            scope='col'
                            className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                          >
                            Address
                          </th>
                          {roleAccess(
                            [],
                            PERMISSION_KEYS.PARTICIPANT,
                            BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT
                              .NDIS_NUMBER
                          ) && (
                            <th
                              scope='col'
                              className='bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                            >
                              NDIS No.
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-white'>
                        {isLoading ? (
                          <tr>
                            <td colSpan={7} className='p-5'>
                              <CircularLoader
                                classes='flex justify-center items-center'
                                classloader='loader-l'
                              />
                            </td>
                          </tr>
                        ) : (
                          participants?.data?.map((person) => (
                            <tr
                              key={person.email}
                              className='even:bg-white odd:bg-gray-50'
                            >
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='flex items-center'>
                                  <div className='h-10 w-10 flex-shrink-0'>
                                    <img
                                      onError={(e) =>
                                        handleImageError(
                                          e,
                                          person.first_name,
                                          person.last_name || " "
                                        )
                                      }
                                      className='object-cover h-10 w-10 rounded-full'
                                      src={
                                        person.profile_picture
                                          ? `${IMAGE_URL}${person.profile_picture}`
                                          : getNameProfile(
                                              person.first_name,
                                              person.last_name || " "
                                            )
                                      }
                                      alt=''
                                    />
                                  </div>
                                  <div className='ml-4 w-32'>
                                    <div
                                      role='button'
                                      tabIndex={0}
                                      className='font-medium text-gray-900 capitalize'
                                      onClick={() =>
                                        viewParticipantProfile(person.uuid)
                                      }
                                    >
                                      {`${person.first_name} ${person.last_name}`}
                                    </div>
                                    {roleAccess(
                                      [],
                                      PERMISSION_KEYS.PARTICIPANT,
                                      BLK_PORTAL_PREMISSION_KEYS_CHILDREN
                                        .PARTICIPANT.PRIMARY_EMAIL
                                    ) && (
                                      <div className='text-gray-500'>
                                        {person.email}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                              {roleAccess(
                                [],
                                PERMISSION_KEYS.PARTICIPANT,
                                BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT
                                  .PRIMARY_EMAIL
                              ) && (
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-900'>
                                    {person.primary_no}
                                  </div>
                                </td>
                              )}
                              <td className='whitespace-nowrap pl-0 px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-900 w-64 m-2 truncate'>
                                  {person.address}
                                </div>
                              </td>
                              {roleAccess(
                                [],
                                PERMISSION_KEYS.PARTICIPANT,
                                BLK_PORTAL_PREMISSION_KEYS_CHILDREN.PARTICIPANT
                                  .NDIS_NUMBER
                              ) && (
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-900'>
                                    {person.ndis_no}
                                  </div>
                                </td>
                              )}
                            </tr>
                          ))
                        )}
                        {participants?.data?.length === 0 && (
                          <tr>
                            <td
                              colSpan={7}
                              className='py-6 text-center text-red-600'
                            >
                              No data found !
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='px-6 bg-white mt-5 pb-10'>
                <PaginationComponent
                  page={paginationObj.page}
                  activeListLength={participants?.data || []}
                  limit={PARTICIPANT_LIST_LIMIT}
                  totalList={paginationObj.total}
                  handlePagination={(e) => {
                    const obj = paginationObj;
                    obj.page = e;
                    setPaginationObj({ ...obj });
                  }}
                  totalPages={paginationObj.totalPages}
                />
              </div>
            </main>
          </main>
        </div>
      </div>
    </>
  );
}
