import CircularLoader from "components/common/CircularLoader/CircularLoader";
import Search from "components/common/Search/Search";
import TableHeader from "components/common/Table/TableHeader";
import { emptyPaginationObj } from "Constants/constant";
import { apiPostRequest } from "helpers/Requests";
import React, { useEffect, useState, useCallback } from "react";
import { BiPencil, BiMinus } from "react-icons/bi";
import PaginationComponent from "components/common/Pagination/index";
import { URL_TEAM_LEAD_GET_REGION_LIST } from "store/services/URL";
import { BsPlusLg } from "react-icons/bs";
import EditRegion from "./EditRegion";

const RegionTable = React.memo(({ setRefresh,isRefresh }) => {
  const [search, setSearch] = useState("");
  const [listData, setListData] = useState([]);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [expandedRows, setExpandedRows] = useState({});
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUUID, setSelectedUUID] = useState(null);

  const handleClose = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    fetchRegions();
  }, [page,isRefresh]);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(() => fetchRegions(), 600);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const fetchRegions = () => {
    setLoader(true);

    const payload = {
      search: search ? search : "",
    };
    apiPostRequest(
      `${URL_TEAM_LEAD_GET_REGION_LIST}?per_page=10&page=${page}`,
      payload
    )
      .then((res) => {
        const regions = res?.data?.data?.regions;
        setListData(regions);
        setPaginationObj((prev) => ({
          ...prev,
          total: regions?.total,
          totalPages: Math.ceil(regions?.total / regions?.per_page),
        }));
      })
      .catch((error) => {
        console.error("Error fetching regions:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleExpandRow = useCallback((rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  }, []);
  const getBgColor = (color) => {
  
    switch (color) {
      case 1:
        return "bg-green-300"; 
      case 2:
        return  "bg-orange-300"; 
      case 3:
        return  "bg-blue-300"; 
      case 4:
        return "bg-purple-300"; 
      case 5:
        return "bg-red-300"; 
      case 6:
        return "bg-yellow-300"; 
      case 7:
        return "bg-gray-300"; 
        case 8:
          return "bg-pink-300"; 
      default:
        return "bg-gray-200"; 
    }
  };
  

  // const getBgColor = (color) => {
  //   switch (color) {
  //     case 1:
  //       return "bg-[#4b6cb7]";
  //     case 2:
  //       return "bg-[#708dd2]"; 
  //     case 3:
  //       return "bg-[#95afea]"; 
  //     case 4:
  //       return "bg-[#bacdf5]"; 
  //     case 5:
  //       return "bg-[#d6e1fa]"; 
  //     case 6:
  //       return "bg-[#ecf0fd]"; 
  //     case 7:
  //       return "bg-[#f5f8fe]"; 
  //     case 8:
  //       return "bg-[#fafcfe]"; 
  //     default:
  //       return "bg-gray-200"; 
  //   }
  // };
  
  
  // const renderChildRows = (children) => (
  //   <tr>
  //     <td colSpan={4} className='px-4 py-2'>
  //       <div className=''>
  //         <table className='min-w-full border border-gray-300 divide-y divide-gray-300'>
  //           <thead className='bg-blue-50 sticky top-0 z-10 capitalize'>
  //             <tr>
  //               <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'></th>
  //               <TableHeader label={"Region Name"} isSort={false} />
  //               <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
  //                 Regional Manager
  //               </th>
  //               <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'></th>
  //             </tr>
  //           </thead>
  //           <tbody className='divide-y divide-gray-200 bg-gray-50'>
  //             {children?.map((child,index) => (
  //               <React.Fragment key={child.id}>
  //                 <tr className='even:bg-white odd:bg-gray-50'>
  //                   <td className='whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900'>
  //                     <div className='flex items-center'>
  //                       {child?.children?.length > 0 && (
  //                         <button
  //                           className='mr-2'
  //                           onClick={() => handleExpandRow(child.uuid)}
  //                         >
  //                           {expandedRows[child.uuid] ? (
  //                             <BiMinus className=' mt-1 text-gray-600' />
  //                           ) : (
  //                             <BsPlusLg className='mt-1 text-gray-600' />
  //                           )}
  //                         </button>
  //                       )}
  //                     </div>
  //                   </td>
  //                   <td className='whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900'>
  //                     {child.name}
  //                   </td>
  //                   <td className='whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900'>
  //                     {child.managers?.length > 0
  //                       ? child.managers.map((el) => el?.user?.name).join(", ")
  //                       : "N/A"}
  //                   </td>
  //                   <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
  //                     <button
  //                       className='icon-pencil-wrapper'
  //                       title='Edit'
  //                       onClick={() => {
  //                         setOpenModal(true);
  //                         setSelectedUUID(child?.uuid);
  //                       }}
  //                     >
  //                       <BiPencil className='icon-size' />
  //                     </button>
  //                   </td>
  //                 </tr>
  //                 {expandedRows[child.uuid] &&
  //                   child?.children?.length > 0 &&
  //                   renderChildRows(child.children)}
  //               </React.Fragment>
  //             ))}
  //           </tbody>
  //         </table>
  //       </div>
  //     </td>
  //   </tr>
  // );
  const renderChildRows = (children, level = 1) => (
    <tr>
      
      <td colSpan={4} className='px-4 py-2'>
        <div className='' style={{ paddingLeft: `${level * 20}px` }}>
          <table className='min-w-full border border-gray-300 divide-y divide-gray-300'>
            <thead className={`${getBgColor(level)} sticky top-0 z-10 capitalize`} >
              <tr>
                <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'></th>
                {/* <TableHeader label={"Region Name"} isSort={false} /> */}
                <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Region Name
                </th>
                <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                  Regional Manager
                </th>
                <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'></th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-gray-50'>
              {children?.map((child, index) => (
                <React.Fragment key={child.id}>
                  <tr className='even:bg-white odd:bg-gray-50'>
                    <td className='whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900'>
                      <div className='flex items-center'>
                        {child?.children?.length > 0 && (
                          <button
                            className='mr-2'
                            onClick={() => handleExpandRow(child.uuid)}
                          >
                            {expandedRows[child.uuid] ? (
                              <BiMinus className=' mt-1 text-gray-600' />
                            ) : (
                              <BsPlusLg className='mt-1 text-gray-600' />
                            )}
                          </button>
                        )}
                      </div>
                    </td>
                    <td className='whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900'>
                      {child.name}
                    </td>
                    <td className='whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900'>
                      {child.managers?.length > 0
                        ? child.managers.map((el) => el?.user?.name).join(", ")
                        : "N/A"}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                      <button
                        className='icon-pencil-wrapper'
                        title='Edit'
                        onClick={() => {
                          setOpenModal(true);
                          setSelectedUUID(child?.uuid);
                        }}
                      >
                        <BiPencil className='icon-size' />
                      </button>
                    </td>
                  </tr>
                  {expandedRows[child.uuid] &&
                    child?.children?.length > 0 &&
                    renderChildRows(child.children, level + 1)}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  );
  
  return (
    <main className='relative'>
      <div className='w-full'>
        <div className='flex sm:px-6 lg:px-8 justify-end items-center py-2 bg-white sticky top-0 z-10'>
          <Search setSearch={setSearch} search={search} />
        </div>
        <div className='bg-white max-w-full mx-auto px-4 sm:px-6 lg:px-8 pb-10 pt-4'>
          <div className='flex flex-col'>
            <div className='-my-2 -mx-4 overflow-x-auto scrollbar-hide sm:-mx-6 lg:-mx-8'>
              <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                  <table className='min-w-full border border-gray-300 divide-y divide-gray-300'>
                    <thead className='bg-blue-50 pt-2 sticky top-0 z-10 capitalize'>
                      <tr>
                        <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'></th>
                        <TableHeader label={"Region Name"} isSort={false} />
                        <th className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Regional Manager
                        </th>
                        <th className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'>
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {loader ? (
                        <tr>
                          <td colSpan={4} className='bg-gray-50 py-2'>
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </td>
                        </tr>
                      ) : listData?.data?.length > 0 ? (
                        listData?.data?.map((region) => (
                          <React.Fragment key={region.uuid}>
                            <tr className='even:bg-white odd:bg-gray-50'>
                              <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
                                <div className='flex items-center'>
                                  {region?.children?.length > 0 && (
                                    <button
                                      className='mr-2'
                                      onClick={() =>
                                        handleExpandRow(region.uuid)
                                      }
                                    >
                                      {expandedRows[region.uuid] ? (
                                        <BiMinus className='mt-1 text-gray-600' />
                                      ) : (
                                        <BsPlusLg className='mt-1 text-gray-600' />
                                      )}
                                    </button>
                                  )}
                                </div>
                              </td>
                              <td className='whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900'>
                                {region.name}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                {region.managers?.length > 0
                                  ? region.managers
                                      .map((el) => el.user?.name)
                                      .join(", ")
                                  : "N/A"}
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500'>
                                <button
                                  className='icon-pencil-wrapper'
                                  title='Edit'
                                  onClick={() => {
                                    setOpenModal(true);
                                    setSelectedUUID(region?.uuid);
                                  }}
                                >
                                  <BiPencil className='icon-size' />
                                </button>
                              </td>
                            </tr>
                            {expandedRows[region.uuid] &&
                              region?.children?.length > 0 &&
                              renderChildRows(region.children)}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={4}
                            className='py-6 text-center text-red-600'
                          >
                            No data found!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='mx-auto px-4 sm:px-6 lg:px-8  '>
                <PaginationComponent
                  page={paginationObj?.page || 1}
                  activeListLength={listData?.data || []}
                  limit={10}
                  totalList={paginationObj?.total}
                  handlePagination={(e) => setPage(e)}
                  totalPages={paginationObj?.totalPages}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <EditRegion
          openModal={openModal}
          handleClose={handleClose}
          setOpenModal={setOpenModal}
          selectedUUID={selectedUUID}
          setRefresh={setRefresh}
          isRefresh = {isRefresh}
        />
      )}
    </main>
  );
});

export default RegionTable;
