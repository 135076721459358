import { connect } from "react-redux";
import ServiceProvider from "./ServiceProvider";
import {
  getExternalSPListRequest,
  createExternalSPRequest,
  getCatalogServicesSubCategoryRequest,
} from "../../../store/actions/cos.actions";
import {
  getExternalSPLists,
  getCOSLoader,
  getServiceCatalogStates,
  getExternalSPCreateLoader,
  getExternalSPCreateError,
} from "../../../store/selector/cos.selector";

const mapDispatchToProps = {
  getExternalSPListRequest,
  createExternalSP: createExternalSPRequest,
  getExternalSubCategory: getCatalogServicesSubCategoryRequest,
};

const mapStateToProps = (state) => {
  const loading = getCOSLoader(state);
  const externalSPList = getExternalSPLists(state);
  const catalogStates = getServiceCatalogStates(state);
  const ExternalSpCreateLoader = getExternalSPCreateLoader(state);
  const externalSpCreateError = getExternalSPCreateError(state);

  return {
    externalSPList,
    loading,
    catalogStates,
    ExternalSpCreateLoader,
    externalSpCreateError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvider);
