import React, { useState } from "react";
import { toast } from "react-toastify";
import { getUrlRole } from "Utils/utils";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { apiDeleteRequest } from "helpers/Requests";

const DeleteCaseNoteModal = ({
  deleteModalOpen,
  selectedComment,
  onCloseModal,
  fetchNotes,
  setFetchNotes,
}) => {
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);
  const onDelete = async () => {
    try {
      setIsDeleteLoader(true);
      const response = await apiDeleteRequest(
        `/${getUrlRole()}/case-notes/${selectedComment.id}`
      );
      if (response.status === 403) {
        toast.error("You do not have permission to delete this case note.");
        console.log("Failed to delete case note. Status:", response.status);
      } else if (response.status !== 200) {
        toast.error("Failed to delete case note. Please try again later.");
        console.log("Failed to delete case note. Status:", response.status);
      } else {
        const updatedNotes = fetchNotes?.filter(
          (note) => note.id !== selectedComment.id
        );
        setFetchNotes(updatedNotes);
        setIsDeleteLoader(false);
        toast.success("Case note deleted successfully");
        onCloseModal(false);
      }
    } catch (err) {
      setIsDeleteLoader(false);
      console.error("Error deleting case note:", err);
      toast.error(
        "An error occurred while deleting case note. Please try again later."
      );
    }
  };

  const modalDeleteFooterButton = () => (
    <div className='flex space-x-2'>
      <CustomButton
        showLoading={isDeleteLoader}
        isDisabled={isDeleteLoader}
        clickHandler={() => {
          if (!selectedComment) return;
          onDelete();
        }}
        type='button'
        label='Delete'
        variant='primary'
      />
      <CustomButton
        isDisabled={isDeleteLoader}
        clickHandler={onCloseModal}
        type='button'
        label='Close'
        variant='danger'
      />
    </div>
  );

  return (
    <>
      {deleteModalOpen && (
        <ModalSkeleton
          isOpen={deleteModalOpen}
          title='Delete CaseNote'
          closeModal={onCloseModal}
          cssClass='w-[50%]'
          modalFooter={modalDeleteFooterButton()}
        >
          <div>
            <p>Are you sure you want to delete this Case Note?</p>
          </div>
        </ModalSkeleton>
      )}
    </>
  );
};

export default DeleteCaseNoteModal;
