import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import { apiGetRequest, apiPatchRequest } from "helpers/Requests";
import InputField from "components/common/Input/Input";
import MultiLevelDropdown from "components/common/MultilevelDropdown";
import {
  URL_TEAM_LEAD_GET_REGION_BY_UUID,
  URL_TEAM_LEAD_UPDATE_REGION,
} from "store/services/URL";
import MultiSelectDropdown from "components/common/MultiSelectDropdown";
import { FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
const EditRegion = (props) => {
  const {
    openModal,
    handleClose,
    isUserGracePeriodOver,
    setOpenModal,
    selectedUUID,
    setRefresh,
    isRefresh,
  } = props;
  // const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [regionsList, setRegionsList] = useState([]);
  const [managersList, setManagersList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const [parentRegionLoader, setParentRegionLoader] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm();

  useEffect(() => {
    setParentRegionLoader(true);
    apiGetRequest(`${URL_TEAM_LEAD_GET_REGION_BY_UUID}/${selectedUUID}`)
      .then((res) => {
        if (res?.status === 200) {
          setRegionsList(res?.data?.data?.parentRegion);
          setSelectedRegion(res?.data?.data?.region);

          setValue("region_name", res?.data?.data?.region?.name);
          setValue("description", res?.data?.data?.region?.description);
          const managers = res?.data?.data?.managers?.map((el) => ({
            label: el?.user?.name,
            value: el?.id,
          }));
          setManagersList(managers);

          const selectedManager = res?.data?.data?.managers
            ?.filter((el) => el?.status)
            ?.map((el) => ({
              label: el?.user?.name,
              value: el?.id,
            }));

          setValue("team_leader", selectedManager);
          const foundRegion = findRegionWithStatusTrue(
            res?.data?.data?.parentRegion
          );
          if (foundRegion) {
            setSelectedItems(foundRegion);
          }
        }
      })
      .catch((error) => console.log({ error }))
      .finally(() => setParentRegionLoader(false));
  }, [selectedUUID]);

  const findRegionWithStatusTrue = (regions) => {
    for (const region of regions) {
      if (region.status === true) {
        return region;
      }

      if (region.children?.length > 0) {
        const foundChildRegion = findRegionWithStatusTrue(region.children);
        if (foundChildRegion) {
          return foundChildRegion;
        }
      }
    }

    return null;
  };

  const onSubmit = async (data) => {
    setSubmitLoader(true);
    const payload = {
      id: selectedRegion?.id,
      name: data?.region_name,
      parent_id: selectedItems?.id,
      managers: data?.team_leader?.map((el) => el.value),
      description:data?.description,
    };

    apiPatchRequest(URL_TEAM_LEAD_UPDATE_REGION, payload)
      .then((res) => {
        toast.success("Region added successfuly!");
        setRefresh(!isRefresh);
        setOpenModal(false);
      })
      .catch((error) => {
        console.log({ error });
        toast.error(error.message);
      })
      .finally(() => {
        setSubmitLoader(false);
      });
  };

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const modalFooter = () => (
    <CustomButton
      showLoading={submitLoader || parentRegionLoader}
      isDisabled={submitLoader || parentRegionLoader}
      label='Update'
      variant='primary'
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("editteamleadButtonModal");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );
  const getToolTipContent = () => {
    return <div>You can add up to 5 levels of regions. </div>;
  };
  return (
    <>
      <ModalSkeleton
        title='Update Region'
        cssClass='w-[50%]'
        isOpen={openModal}
        closeModal={handleClose}
        modalFooter={modalFooter()}
      >
        <div className='flex flex-col '>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='w-full'
            onKeyDown={handleFormKeyDown}
          >
            <div className='grid grid-cols-1 gap-x-4 gap-y-3 mt-3 max-w-[70%] mx-auto'>
              <div className='col-span-1 sm:col-span-1'>
                <label className='text-gray-700 text-sm font-medium mb-1 flex items-center'>
                  Region Name{" "}
                  <a data-tooltip-id='my-tooltip'>
                    <FaQuestionCircle
                      data-tip
                      data-tooltip-id='manualAcceptHelp'
                      className='ml-1 text-gray-500 cursor-pointer'
                    />
                  </a>
                  <Tooltip
                    id='my-tooltip'
                    place='right'
                    content={getToolTipContent()}
                    variant='dark'
                    className='z-50'
                  />
                </label>
                <InputField
                  register={register}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  name='region_name'
                  cssClass='z-20'
                  isRequired={true}
                  autoComplete='off'
                  isDisabled={parentRegionLoader}
                  ErrorMessage={
                    errors?.region_name?.type === "required"
                      ? "This field is required!"
                      : null
                  }
                />
              </div>
              <div className='col-span-1 sm:col-span-1'>
                <label className='block text-sm font-medium text-gray-700'>
                  Description
                </label>
                <textarea
                  rows={3}
              
                  className='px-4 mt-1 py-2 border  
                  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full
                   text-sm border-gray-300 rounded-md resize-none overflow-y-auto'
                  {...register("description", {
                    // required: true,
                  })}
                />
              </div>
              <div className='col-span-1 sm:col-span-1'>
                <label className='block text-sm font-medium text-gray-700'>
                  Parent Region
                </label>
                <MultiLevelDropdown
                  regions={regionsList}
                  setSelectedItem={setSelectedItems}
                  selectedItems={selectedItems}
                  parentRegionLoader={parentRegionLoader}
                  
                />
              </div>
              <div className='col-span-1 sm:col-span-1'>
                <label className='block text-sm font-medium text-gray-700'>
                  Please update the manager for this region
                </label>

                <MultiSelectDropdown
                  showIcon
                  data={managersList}
                  cssClass='z-30'
                  name='team_leader'
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  register={register}
                  setValue={setValue}
                  control={control}
                  watch={watch}
                  clearErrors={clearErrors}
                  isDisabled={parentRegionLoader}
                  isTeamLeadModule={true}
                />
              </div>
            </div>

            <button
              className='hudden sr-only'
              type='submit'
              id='editteamleadButtonModal'
            />
          </form>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default EditRegion;
