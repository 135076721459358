import React from "react";
import SortIcon from "../SortIcon/SortIcon";

const TableHeader = (props) => {
  const {
    sortName,
    sortDescending,
    title,
    label,
    cssClass,
    sortBy,
    flipSort,
    isSort = true,
  } = props;

  const SetSortIcon = ({ sortName }) => {
    return (
      <SortIcon
        sortName={sortName}
        sortBy={sortBy}
        sortDescending={sortDescending}
      />
    );
  };

  return (
    <th
      title={title || ""}
      scope="col"
      className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50 ${cssClass}`}
    >
      <div className={isSort ? "flex items-center flex-start space-x-2" : ""}>
        {label}
        {isSort && (
          <div
            role="button"
            tabIndex={0}
            onClick={() => flipSort(sortName)}
            className="ml-1 cursor-pointer "
          >
            <SetSortIcon sortName={sortName} />
          </div>
        )}
      </div>
    </th>
  );
};

export default TableHeader;
