import React, { useState } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import NdisNews from "./NdisNews";
import NdisPage from "./NdisPage";
import SystemNews from "./SystemNews";

const Tab = () => {
  const [activeTab, setActiveTab] = useState(0);
 
  const tabs = [
    { id: 0, title: "System News", content: <SystemNews  /> },
    { id: 1, title: "NDIS On Twitter", content: <NdisNews /> },
    { id: 2, title: "NDIS On Facebook", content: <NdisPage /> },
  ];
  const handlePrevTab = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
    }
  };

  const handleNextTab = () => {
    if (activeTab < tabs.length - 1) {
      setActiveTab(activeTab + 1);
    }
  };
  return (
    <>
      <div
        className="max-w-lg mx-auto  bg-[#F6F7F8] py-5"
        style={{
          borderTopRightRadius: "15px",
          borderTopLeftRadius: "15px",
        }}
      >
        <p className="text-lg font-bold text-[#161736] pl-5">Feeds</p>
        <div className="flex border-b-2 w-full">
          {tabs.map((tab) => (
            <span
              key={tab.id}
              className={`text-center  sm:text-xs py-2 px-1 w-full cursor-pointer ${
                activeTab === tab.id
                  ? "border-b-2 border-blue-500 font-bold text-[#0177FB] "
                  : "text-gray-500 font-bold hover:text-gray-500"
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.title}
            </span>
          ))}
        </div>
       
      </div>
  
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${activeTab === tab.id ? "block" : "hidden"} mt-2 flex justify-center `}
          >
            
            {tab.content}
          
          </div>
        ))}
     
    </>
  );
};

export default Tab;
