import React from 'react';
import { ChevronRightIcon } from "@heroicons/react/outline";

function BreadCrumb(props) {
  const {breadCrumb, index, handleClick} = props;
  const clickHandler = () => {
    handleClick(breadCrumb, index);
  }
  return (
    <>
      <li className="flex items-center space-x-4 cursor-pointer ">
          {index !== 0 && (
            <span>
              <ChevronRightIcon
                className="flex-shrink-0 h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
            </span>
          )}
          <span className="text-gray-500 font-medium text-sm hover:text-gray-800 font-semibold" onClick={clickHandler}>
            {breadCrumb.name}
          </span>
      </li>
    </>
  )
}

export default BreadCrumb