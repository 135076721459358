import { apiCall } from "./service";
import {
  URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH,
  URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_SP,
  URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_FOR_HRM,
  URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_FOR_HRM_SP,
} from "./URL";

export const api = {
  formTemplateDraftToPublish(params) {
    return apiCall.post({
      url:
        params.role === "hrm"
          ? URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_FOR_HRM
          : URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH,
      isAuthToken: true,
      params: params,
    });
  },

  formTemplateDraftToPublishSP(params) {
    return apiCall.post({
      url:
        params.role === "hrm"
          ? URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_FOR_HRM_SP
          : URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_SP,
      isAuthToken: true,
      params: params,
    });
  },
  // formTemplateDraftToPublishHRM(params) {
  //   return apiCall.post({
  //     url: ,
  //     isAuthToken: true,
  //     params: params,
  //   });
  // },
  // formTemplateDraftToPublishHRMSP(params) {
  //   return apiCall.post({
  //     url: URL_FORM_TEMPLATE_DRAFT_TO_PUBLISH_FOR_HRM_SP,
  //     isAuthToken: true,
  //     params: params,
  //   });
  // },
};

export default api;
