import { fromJS } from "immutable";
import { emptyPaginationObj } from "../../Constants/constant";
import ACTIONS, { FETCH_STATUS } from "../constants";

const initialState = fromJS({
  loading: false,
  catalog_services: null,
  catalog_versions: null,
  participantFunding: {},
  planCatagories: null,
  fundingLoadNotes: false,
  cosParticipantsProfile: null,
  serviceSupportItemsCaseNotes: null,
  ndisClaims: {},
  cosInvoice: {},
  createdCosInvoice: null,
  createNewCategory: null,
  error: "",
  cos_category_setting: [],
  cos_contact_setting: [],
  cos_priority_setting: [],
  periorityList: [],
  DynamicBreadCrumb: [],
  getFolderListForHRM: [],
  getFileListForHRM: [],
  createNewFolderSuccess: false,
  createNewFolderLoading: false,
  createNewFolderError: "",
  removeFolderSuccess: "",
  removeFolderLoading: false,
  removeFolderError: "",
  removeFileSuccess: "",
  removeFileLoading: false,
  removeFileError: "",
});

export default function cosReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.DYNAMIC_BREADCRUMB_FOR_HRM_CALL: {
      return state.set("DynamicBreadCrumb", action.payload);
    }
    case ACTIONS.GET_CASE_NOTE_DETAIL.PENDING: {
      return state
        .set("getCaseNoteDetailLoading", true)
        .set("createNewFolderError", "")
        .set("getCaseNoteDetailData", "")
        .set("getCaseNoteData", "");
    }
    case ACTIONS.GET_CASE_NOTE_DETAIL.SUCCESS: {
      return state
        .set("getCaseNoteDetailLoading", false)
        .set("getCaseNoteData", action.data)
        .set("getCaseNoteDetailData", "");
    }
    case ACTIONS.GET_CASE_NOTE_DETAIL.ERROR: {
      return state
        .set("getCaseNoteDetailLoading", false)
        .set("createNewFolderError", "")
        .set("getCaseNoteData", "")
        .set("getCaseNoteDetailData", "");
    }

    case ACTIONS.RESET_FOLDER_SUCCESS_VALUE: {
      return state.set("createNewFolderSuccess", action.data);
    }

    case ACTIONS.CREATE_NEWFOLDER.PENDING: {
      return state
        .set("createNewFolderLoading", true)
        .set("createNewFolderError", "")
        .set("createNewFolderSuccess", false);
    }
    case ACTIONS.CREATE_NEWFOLDER.SUCCESS: {
      const updated = state.get("getFolderListForHRM");
      updated.unshift(action.data);
      return state
        .set("createNewFolderSuccess", true)
        .set("getFolderListForHRM", updated)
        .set("createNewFolderLoading", false)
        .set("createNewFolderError", "");
    }
    case ACTIONS.CREATE_NEWFOLDER.ERROR: {
      return state
        .set("createNewFolderSuccess", false)
        .set("createNewFolderLoading", false)
        .set("createNewFolderError", action);
    }

    // Update folder

    case ACTIONS.UPDATE_CASE_NOTE_DETAIL.PENDING: {
      return state
        .set("updateCaseNotesLoading", true)
        .set("updateCaseNotesError", "")
        .set("updateCaseNotesSuccess", false);
    }

    case ACTIONS.UPDATE_CASE_NOTE_DETAIL.SUCCESS: {
      const updated = state.get("casNotesList");
      const oldFunding = state.get("fundingsForCos");
      const dataCase = state.get("serviceSupportItemsCaseNotes");
      const updatedSupport = dataCase.map((item) => {
        if (item.id === action.data.service_support_budget.id) {
          return {
            ...item,
            remain_total_hours:
              action.data.service_support_budget.remain_total_hours,
            remain_total_mint:
              action.data.service_support_budget.remain_total_mint,
            delivered_amount:
              action.data.service_support_budget.delivered_amount,
            remain_amount: action.data.service_support_budget.remain_amount,
            remain_quantity: action.data.service_support_budget.remain_quantity,
          };
        } else {
          return { ...item };
        }
      });
      const dataNew = updated.data.map((index) => {
        if (index.id === action.data.cos_case_note.id) {
          return {
            ...index,
            ...action.data.cos_case_note,
          };
        } else {
          return { ...index };
        }
      });

      updated.data = dataNew;

      const updatedFunding = oldFunding.map((item) => {
        if (item.id === action.data.funding.id) {
          return {
            id: action?.data?.funding?.id,
            uuid: action?.data?.funding?.uuid,
            contract_id: action?.data?.funding?.contract_id,
            start_date: action?.data?.funding?.start_date,
            end_date: action?.data?.funding?.end_date,
            amount_allocated: action?.data?.funding?.amount_allocated,
            amount_delivered: action?.data?.funding?.amount_delivered,
            amount_remaining: action?.data?.funding?.amount_remaining,
            review_date: action?.data?.funding?.review_date,
            sign_date: action?.data?.funding?.sign_date,
            status: action?.data?.funding?.status,
            total_contract_amount: action?.data?.funding?.total_contract_amount,
          };
        } else {
          return { ...item };
        }
      });
      return state
        .set("fundingsForCos", updatedFunding)
        .set("serviceSupportItemsCaseNotes", [...updatedSupport])
        .set("singleSupportTract", {
          ...action.data.service_support_budget,
        })
        .set("casNotesList", updated)
        .set("updateCaseNotesSuccess", true)
        .set("updateCaseNotesLoading", false)
        .set("updateCaseNotesError", "");
    }

    case ACTIONS.UPDATE_CASE_NOTE_DETAIL.ERROR: {
      return state
        .set("updateCaseNotesSuccess", false)
        .set("updateCaseNotesLoading", false)
        .set("updateCaseNotesError", action);
    }

    case ACTIONS.UPDATE_FOLDER.PENDING: {
      return state
        .set("createNewFolderLoading", true)
        .set("createNewFolderError", "")
        .set("createNewFolderSuccess", true);
    }

    case ACTIONS.UPDATE_FOLDER.SUCCESS: {
      const updated = state.get("getFolderListForHRM");
      const data = updated.map((index) => {
        if (index.id === action.data.id) {
          return {
            ...index,
            name: action.data.name,
          };
        } else {
          return { ...index };
        }
      });
      return state
        .set("createNewFolderSuccess", false)
        .set("getFolderListForHRM", data)
        .set("createNewFolderLoading", false)
        .set("createNewFolderError", "");
    }

    case ACTIONS.UPDATE_FOLDER.ERROR: {
      return state
        .set("createNewFolderSuccess", false)
        .set("createNewFolderLoading", false)
        .set("createNewFolderError", action);
    }

    // Remove folder

    case ACTIONS.REMOVE_FOLDER.PENDING: {
      return state
        .set("removeFolderSuccess", "")
        .set("removeFolderLoading", true)
        .set("removeFolderError", "");
    }

    case ACTIONS.REMOVE_FOLDER.SUCCESS: {
      const updated = state.get("getFolderListForHRM");
      const removed = JSON.parse(localStorage.getItem("removedItem"));
      const data = updated.filter((item) => {
        return item.id !== removed.id;
      });
      localStorage.removeItem("removedItem");
      return state
        .set("getFolderListForHRM", data)
        .set("removeFolderSuccess", state.data)
        .set("removeFolderLoading", false)
        .set("removeFolderError", "");
    }

    case ACTIONS.REMOVE_FOLDER.ERROR: {
      return state
        .set("removeFolderSuccess", "")
        .set("removeFolderLoading", false)
        .set("removeFolderError", state.data);
    }
    case ACTIONS.SET_UPDATED_FILE_CALL: {
      const updated = state.get("getFileListForHRM");

      updated.unshift(action.payload.data.data);
      return state.set("getFileListForHRM", updated);
    }
    // Remove file

    case ACTIONS.REMOVE_FILE.PENDING: {
      return state
        .set("removeFileSuccess", "")
        .set("removeFileLoading", true)
        .set("removeFileError", "");
    }

    case ACTIONS.REMOVE_FILE.SUCCESS: {
      const updated = state.get("getFileListForHRM");
      const data = updated.filter((item) => {
        return item.id !== action.data.removedId.file_management_id;
      });
      return state.set("getFileListForHRM", data);
    }

    case ACTIONS.REMOVE_FILE.ERROR: {
      return state
        .set("removeFileSuccess", "")
        .set("removeFileLoading", false)
        .set("removeFileError", state.data);
    }

    ///////////

    case ACTIONS.GET_FOLDER_LIST_FOR_HRM.PENDING: {
      return state
        .set("getFolderListForHRMLoading", true)
        .set("getFolderListForHRMError", "")
        .set("getFolderListForHRM", [])
        .set("getFileListForHRM", []);
    }
    case ACTIONS.GET_FOLDER_LIST_FOR_HRM.SUCCESS: {
      return state
        .set("getFolderListForHRM", action.data.folder_structure)
        .set("getFileListForHRM", action.data.files_managements)
        .set("getFolderListForHRMLoading", false)
        .set("getFolderListForHRMError", "");
    }
    case ACTIONS.GET_FOLDER_LIST_FOR_HRM.ERROR: {
      return state
        .set("getFolderListForHRM", [])
        .set("getFileListForHRM", [])
        .set("getFolderListForHRMLoading", false)
        .set("getFolderListForHRMError", false);
    }

    case ACTIONS.UPDATE_PRIORITY.PENDING: {
      return state
        .set("updatePriorityLoading", true)
        .set("updatePriorityError", "")
        .set("updatePrioritySuccess", false);
    }
    case ACTIONS.UPDATE_PRIORITY.SUCCESS: {
      const data = state.get("cos_priority_setting");
      const updated = data.filter((item) => item.uuid !== action.data.uuid);
      updated.unshift(action.data);
      return state
        .set("updatePrioritySuccess", true)
        .set("cos_priority_setting", updated)
        .set("updatePriorityLoading", false)
        .set("updatePriorityError", "")
        .set("updatePriority", action.data);
    }
    case ACTIONS.UPDATE_PRIORITY.ERROR: {
      return state
        .set("updatePrioritySuccess", false)
        .set("updatePriorityLoading", false)
        .set("updatePriorityError", action.error);
    }

    case ACTIONS.UPDATE_CONTACT.PENDING: {
      return state
        .set("updateContactLoading", true)
        .set("updateContactSuccess", false)
        .set("updateContactError", "");
    }
    case ACTIONS.UPDATE_CONTACT.SUCCESS: {
      const data = state.get("cos_contact_setting");
      const updated = data.filter((item) => item.uuid !== action.data.uuid);
      updated.unshift(action.data);

      return state
        .set("updateContactSuccess", true)
        .set("cos_contact_setting", updated)
        .set("updateContactLoading", false)
        .set("updateContactError", "")
        .set("updateContact", action.data);
    }
    case ACTIONS.UPDATE_CONTACT.ERROR: {
      return state
        .set("updateContactSuccess", false)
        .set("updateContactLoading", false)
        .set("updateContactError", action.error);
    }

    case ACTIONS.UPDATE_CATEGORY.PENDING: {
      return state
        .set("updateCategorySuccess", false)
        .set("updateCategoryLoading", true)
        .set("updateCategoryError", "");
    }
    case ACTIONS.UPDATE_CATEGORY.SUCCESS: {
      const data = state.get("cos_category_setting");
      const updated = data.filter((item) => item.uuid !== action.data.uuid);
      updated.unshift(action.data);

      return state
        .set("updateCategorySuccess", true)

        .set("cos_category_setting", updated)
        .set("updateCategoryLoading", false)
        .set("updateCategoryError", "")
        .set("updateCategory", action.data);
    }
    case ACTIONS.UPDATE_CATEGORY.ERROR: {
      return state
        .set("updateCategorySuccess", false)

        .set("updateCategoryLoading", false)
        .set("updateCategoryError", action.error);
    }
    case ACTIONS.GET_DASHBOARD_DATA.PENDING: {
      return state
        .set("getDashboardDataLoading", true)
        .set("getDashboardDataError", "");
    }
    case ACTIONS.GET_DASHBOARD_DATA.SUCCESS: {
      return state
        .set("getDashboardDataLoading", false)
        .set("getDashboardDataError", "")
        .set("getDashboardData", action.data.stats);
    }
    case ACTIONS.GET_DASHBOARD_DATA.ERROR: {
      return state
        .set("getDashboardDataLoading", false)
        .set("getDashboardDataError", action);
    }
    case ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS.PENDING: {
      return state
        .set("fundingsForCosLoading", action.status === FETCH_STATUS.LOADING)
        .set("fundingsForCosError", "");
    }
    case ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS.SUCCESS: {
      return state
        .set("fundingsForCosLoading", false)
        .set("fundingsForCosError", "")
        .set("fundingsForCos", action.data.funding);
    }
    case ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS.ERROR: {
      return state
        .set("fundingsForCosLoading", false)
        .set("fundingsForCosError", action);
    }

    case ACTIONS.GET_COS_REPORTING_TABLEDATA.PENDING: {
      return state
        .set("tableDataForReportingLoading", true)
        .setIn(["cosInvoice", "error"], "")
        .set("tableDataForReporting", null);
    }

    case ACTIONS.GET_COS_REPORTING_FOR_CASE_NOTES_TABLEDATA.SUCCESS: {
      return state
        .set("tableDataForReportingLoading", false)
        .set("tableDataForReportingForCaseNotes", action.data.data);
    }
    case ACTIONS.GET_COS_REPORTING_TABLEDATA.SUCCESS: {
      return state
        .set("tableDataForReportingLoading", false)
        .setIn(["cosInvoice", "error"], "")
        .set("tableDataForReporting", action.data.data);
    }
    case ACTIONS.GET_COS_REPORTING_TABLEDATA.ERROR: {
      return state
        .set("tableDataForReportingLoading", false)
        .setIn(["cosInvoice", "error"], action.error);
    }

    case ACTIONS.GET_PERIORITY_LIST.PENDING: {
      return state
        .setIn(["cosInvoice", "periorityListLoading"], true)
        .setIn(["cosInvoice", "error"], "");
    }
    case ACTIONS.GET_PERIORITY_LIST.SUCCESS: {
      return state
        .setIn(["cosInvoice", "periorityListLoading"], false)
        .setIn(["cosInvoice", "error"], "")
        .set("periorityList", action.data.cos_priority_list);
    }
    case ACTIONS.GET_PERIORITY_LIST.ERROR: {
      return state
        .setIn(["cosInvoice", "periorityListLoading"], false)
        .setIn(["cosInvoice", "error"], action.error);
    }

    case ACTIONS.CREATE_NEW_PERIORITY.PENDING: {
      return state
        .set("createNewPriorityLoading", true)
        .set("createNewPriorityError", "");
    }
    case ACTIONS.CREATE_NEW_PERIORITY.SUCCESS: {
      const data = state.get("cos_priority_setting");
      data.unshift(action.data.data);
      return state
        .set("createNewPriorityLoading", false)
        .set("createNewPriorityError", "")
        .set("periorityList", data);
    }
    case ACTIONS.CREATE_NEW_PERIORITY.ERROR: {
      return state
        .set("createNewPriorityLoading", false)
        .set("createNewPriorityError", action.error);
    }

    case ACTIONS.REMOVE_SETTING_DATA: {
      return state
        .setIn(["cosInvoice", "newPeriorityList"], null)
        .setIn(["cosInvoice", "createNewContact"], null)
        .setIn(["cosInvoice", "createNewCategory"], null);
    }

    case ACTIONS.GET_CONTACT_LIST.PENDING: {
      return state
        .setIn(["cosInvoice", "getContactListLoading"], true)
        .setIn(["cosInvoice", "error"], "");
    }
    case ACTIONS.GET_CONTACT_LIST.SUCCESS: {
      return state
        .setIn(["cosInvoice", "getContactListLoading"], false)
        .set("ContactList", action.data.contact_type_list);
    }
    case ACTIONS.GET_CONTACT_LIST.ERROR: {
      return state
        .setIn(["cosInvoice", "getContactListLoading"], false)
        .setIn(["cosInvoice", "error"], action);
    }

    case ACTIONS.GET_CATOGERY_LIST.PENDING: {
      return state
        .setIn(["cosInvoice", "getCatogeryListLoading"], true)
        .setIn(["cosInvoice", "error"], "");
    }
    case ACTIONS.GET_CATOGERY_LIST.SUCCESS: {
      return state
        .setIn(["cosInvoice", "getCatogeryListLoading"], false)
        .set("CatogeryList", action.data.client_task_category_list);
    }
    case ACTIONS.GET_CATOGERY_LIST.ERROR: {
      return state
        .setIn(["cosInvoice", "getCatogeryListLoading"], false)
        .setIn(["cosInvoice", "error"], action);
    }

    case ACTIONS.CREATE_NEW_CONTACT.PENDING: {
      return state
        .set("createNewContactLoading", true)
        .set("createNewContactError", "");
    }
    case ACTIONS.CREATE_NEW_CONTACT.SUCCESS: {
      const data = state.get("cos_contact_setting");
      data.unshift(action.data.data);
      return state
        .set("cos_contact_setting", data)
        .set("createNewContactLoading", false)
        .set("createNewContactError", "");
    }
    case ACTIONS.CREATE_NEW_CONTACT.ERROR: {
      return state
        .set("createNewContactLoading", false)
        .set("createNewContactError", action.error);
    }
    case ACTIONS.CREATE_NEW_CATEGORY.PENDING: {
      return state
        .set("createNewCategoryLoading", true)
        .set("createNewCategoryError", "");
    }
    case ACTIONS.CREATE_NEW_CATEGORY.SUCCESS: {
      const data = state.get("cos_category_setting");
      data.unshift(action.data.data);
      return state
        .set("cos_category_setting", data)
        .set("createNewCategoryLoading", false)
        .set("createNewCategoryError", "");
    }
    case ACTIONS.CREATE_NEW_CATEGORY.ERROR: {
      return state
        .set("createNewCategoryLoading", false)
        .set("createNewCategoryError", action.error);
    }

    case ACTIONS.CHANGE_INVOICE_STATUS.PENDING: {
      return state
        .setIn(["cosInvoice", "changeNDISCaseNotesInvoiceStatusLoading"], true)
        .setIn(["cosInvoice", "changeCaseNotesInvoiceStatusSuccess"], false)
        .setIn(["cosInvoice", "error"], "");
    }
    case ACTIONS.CHANGE_INVOICE_STATUS.SUCCESS: {
      const data = state.getIn(["cosInvoice", "createdCosReviewInvoice"] || {});
      let res = [];
      res = data.filter((el) => {
        return !action.data.data.created_cos_invoice.find((element) => {
          return element.uuid === el.uuid;
        });
      });
      action?.data?.data?.created_cos_invoice.forEach((item) => {
        if (item.invoice_status !== "ready_for_extraction") {
          res.push(item);
        }
      });
      return state
        .setIn(
          ["cosInvoice", "createdCosReviewInvoiceDeliveryStatus"],
          action.data.data.delivery_status
        )
        .setIn(["cosInvoice", "createdCosInvoiceReviewLoading"], false)
        .setIn(["cosInvoice", "changeCaseNotesInvoiceStatusSuccess"], true)
        .setIn(["cosInvoice", "createdCosReviewInvoice"], res)
        .setIn(
          ["cosInvoice", "createdCosReviewInvoiceClients"],
          action.data.data.clients
        )
        .setIn(["cosInvoice", "changeNDISCaseNotesInvoiceStatusLoading"], false)
        .setIn(["cosInvoice", "changeNDISCaseNotesInvoiceStatus"], action.data);
    }
    case ACTIONS.CHANGE_INVOICE_STATUS.ERROR: {
      return state
        .setIn(["cosInvoice", "changeCaseNotesInvoiceStatusSuccess"], false)
        .setIn(["cosInvoice", "changeNDISCaseNotesInvoiceStatusLoading"], false)
        .setIn(["cosInvoice", "error"], action);
    }

    case ACTIONS.CHANGE_NDIS_CLAIM_STATUS.PENDING: {
      return state
        .setIn(["cosInvoice", "changeNDISCaseNotesStatusLoading"], true)
        .set("changeStatusSuccess", false)
        .setIn(["cosInvoice", "error"], "");
    }
    case ACTIONS.CHANGE_NDIS_CLAIM_STATUS.SUCCESS: {
      const data = state.get("caseNoteReview");
      let res = [];
      res = data.filter((el) => {
        return !action.data.data.created_cos_ndis_claims.find((element) => {
          return element.uuid === el.uuid;
        });
      });
      action?.data?.data?.created_cos_ndis_claims.forEach((item) => {
        if (item.status !== "ready_for_extraction") {
          res.push(item);
        }
      });
      return state
        .set("caseNoteReview", res)
        .set("changeStatusSuccess", true)
        .set("selectedClientList", action.data.data.clients)
        .set("statusList", action.data.data.delivery_status)
        .setIn(["cosInvoice", "changeNDISCaseNotesStatusLoading"], false)
        .setIn(["cosInvoice", "changeNDISCaseNotesStatus"], action.data);
    }
    case ACTIONS.CHANGE_NDIS_CLAIM_STATUS.ERROR: {
      return state
        .set("changeStatusSuccess", false)

        .setIn(["cosInvoice", "changeNDISCaseNotesStatusLoading"], false)
        .setIn(["cosInvoice", "error"], action);
    }

    case ACTIONS.GET_CREATED_COS_INVOICES_RIVIEW.PENDING: {
      return state
        .setIn(["cosInvoice", "createdCosInvoiceReviewLoading"], true)
        .setIn(["cosInvoice", "error"], "");
    }
    case ACTIONS.GET_CREATED_COS_INVOICES_RIVIEW.SUCCESS: {
      return state
        .setIn(["cosInvoice", "createdCosInvoiceReviewLoading"], false)
        .setIn(
          ["cosInvoice", "createdCosReviewInvoice"],
          action.data.data.created_cos_invoice
        )
        .setIn(
          ["cosInvoice", "createdCosReviewInvoiceDeliveryStatus"],
          action.data.data.delivery_status
        )
        .setIn(
          ["cosInvoice", "createdCosReviewInvoiceClients"],
          action.data.data.clients
        );
    }
    case ACTIONS.GET_CREATED_COS_INVOICES_RIVIEW.ERROR: {
      return state
        .setIn(["cosInvoice", "createdCosInvoiceReviewLoading"], false)
        .setIn(["cosInvoice", "error"], action);
    }

    case ACTIONS.GET_CREATED_COS_INVOICES.PENDING: {
      return state
        .setIn(["cosInvoice", "createdCosInvoiceLoading"], true)
        .setIn(["cosInvoice", "error"], "");
    }
    case ACTIONS.GET_CREATED_COS_INVOICES.SUCCESS: {
      return state
        .setIn(["cosInvoice", "createdCosInvoiceLoading"], false)
        .set("createdCosInvoice", action.data);
    }
    case ACTIONS.GET_CREATED_COS_INVOICES.ERROR: {
      return state
        .setIn(["cosInvoice", "createdCosInvoiceLoading"], false)
        .setIn(["cosInvoice", "error"], action);
    }

    case ACTIONS.CREATE_COS_INVOICE.PENDING: {
      return state
        .setIn(["cosInvoice", "createCosInvoiceLoading"], true)
        .setIn(["cosInvoice", "error"], "");
    }
    case ACTIONS.CREATE_COS_INVOICE.SUCCESS: {
      const newData = state.get("createdCosInvoice") || null;
      if (newData) {
        newData.unshift({
          ...action.data,
          created_by: action.data.created_by,
          num_delivered_ser: action.data.case_notes,
        });
      }
      return state
        .setIn(["cosInvoice", "createCosInvoiceLoading"], false)
        .setIn(["cosInvoice", "createCosInvoice"], action.data)
        .set("createdCosInvoice", newData);
    }
    case ACTIONS.CREATE_COS_INVOICE.ERROR: {
      return state
        .setIn(["cosInvoice", "createCosInvoiceLoading"], false)
        .setIn(["cosInvoice", "error"], action);
    }

    case ACTIONS.GET_COS_INVOICES.PENDING: {
      return state
        .setIn(["cosInvoice", "cosInvoiceLoading"], true)
        .setIn(["cosInvoice", "error"], "");
    }
    case ACTIONS.GET_COS_INVOICES.SUCCESS: {
      return state
        .setIn(["cosInvoice", "cosInvoiceLoading"], false)
        .setIn(["cosInvoice", "cosInvoiceList"], action.data);
    }
    case ACTIONS.GET_COS_INVOICES.ERROR: {
      return state
        .setIn(["cosInvoice", "cosInvoiceLoading"], false)
        .setIn(["cosInvoice", "error"], action.error);
    }

    case ACTIONS.REMOVE_SETTINGS_DATA: {
      return state

        .set("updateCategorySuccess", false)
        .set("updateContactSuccess", false)
        .set("updatePrioritySuccess", false);
    }

    case ACTIONS.REMOVE_CASE_NOTES_REVIEW_LIST: {
      return state
        .setIn(["cosInvoice", "changeCaseNotesInvoiceStatusSuccess"], false)
        .set("caseNoteReview", null)
        .set("changeStatusSuccess", false)
        .set("caseNotesListForClaim", null)
        .set("claimedCaseNotesList", null);
    }
    case ACTIONS.GET_CASE_NOTES_REVIEW.PENDING: {
      return state.set("caseNoteReviewLoading", true).set("error", "");
    }
    case ACTIONS.GET_CASE_NOTES_REVIEW.SUCCESS: {
      return state
        .set("caseNoteReviewLoading", false)
        .set("error", "")
        .set("caseNoteReview", action.data.data.created_cos_ndis_claims)
        .set("selectedClientList", action.data.data.clients)
        .set("statusList", action.data.data.delivery_status);
    }
    case ACTIONS.GET_CASE_NOTES_REVIEW.ERROR: {
      return state.set("caseNoteReviewLoading", false).set("error", action);
    }

    case ACTIONS.GET_CLAIMED_CASE_NOTES_LIST.PENDING: {
      return state.set("claimedCaseNotesListLoading", true).set("error", "");
    }
    case ACTIONS.GET_CLAIMED_CASE_NOTES_LIST.SUCCESS: {
      return state
        .set("claimedCaseNotesListLoading", false)
        .set("error", "")
        .set("claimedCaseNotesList", action.data);
    }
    case ACTIONS.GET_CLAIMED_CASE_NOTES_LIST.ERROR: {
      return state
        .set("claimedCaseNotesListLoading", false)
        .set("error", action);
    }
    case ACTIONS.ADD_CASE_NOTES_TO_CLAIM_LIST.PENDING: {
      return state
        .set("addCaseNotesToListLoading", true)
        .set("addCaseNotesToListError", "");
    }
    case ACTIONS.ADD_CASE_NOTES_TO_CLAIM_LIST.SUCCESS: {
      const newData = state.get("claimedCaseNotesList");
      if (newData) {
        newData.push({
          ...action.data,
          created_by: action.data.created_by,
          created_date: action.data.created_at,
        });
      }

      return state
        .set("addCaseNotesToListLoading", false)
        .set("addCaseNotesToListError", "")
        .setIn(["cosInvoice", "addCaseNotesToList"], action.data)
        .set("claimedCaseNotesList", newData);
    }
    case ACTIONS.ADD_CASE_NOTES_TO_CLAIM_LIST.ERROR: {
      return state
        .set("addCaseNotesToListLoading", false)
        .set("addCaseNotesToListError", action.error);
    }
    case ACTIONS.GET_CASE_NOTES_LIST_FOR_CLAIM.PENDING: {
      return state
        .setIn(["ndisClaims", "fetchNdisClaimsCaseNotesLoader"], true)
        .setIn(["ndisClaims", "fetchNdisClaimsCaseNotesError"], "");
    }
    case ACTIONS.GET_CASE_NOTES_LIST_FOR_CLAIM.SUCCESS: {
      return state
        .setIn(["ndisClaims", "NdisClaimsCaseNotes"], action.data)
        .setIn(["ndisClaims", "fetchNdisClaimsCaseNotesLoader"], false)
        .setIn(["ndisClaims", "fetchNdisClaimsCaseNotesError"], "");
    }
    case ACTIONS.GET_CASE_NOTES_LIST_FOR_CLAIM.ERROR: {
      return state
        .setIn(["ndisClaims", "fetchNdisClaimsCaseNotesLoader"], false)
        .setIn(["ndisClaims", "fetchNdisClaimsCaseNotesError"], action.error);
    }

    case ACTIONS.GET_CASE_NOTES_HISTROY.PENDING: {
      return state
        .set("caseNotesHistoryLoading", true)
        .set("error", "")
        .set("caseNotesHistoryList", null);
    }
    case ACTIONS.GET_CASE_NOTES_HISTROY.SUCCESS: {
      return state
        .set("caseNotesHistoryLoading", false)
        .set("error", "")
        .set("caseNotesHistoryList", action.data);
    }
    case ACTIONS.GET_CASE_NOTES_HISTROY.ERROR: {
      return state.set("caseNotesHistoryLoading", false).set("error", action);
    }

    case ACTIONS.GET_CASE_NOTES_CLIENT.PENDING: {
      return state
        .setIn(["ndisClaims", "fetchNdisClaimCaseNoteClientsLoader"], true)
        .setIn(["ndisClaims", "fetchNdisClaimCaseNoteClientsError"], "");
    }
    case ACTIONS.GET_CASE_NOTES_CLIENT.SUCCESS: {
      return state
        .setIn(["ndisClaims", "fetchNdisClaimCaseNoteClientsLoader"], false)
        .setIn(["ndisClaims", "clientsList"], action.data)
        .setIn(
          ["ndisClaims", "fetchNdisClaimCaseNoteClientsError"],
          action.error
        );
    }
    case ACTIONS.GET_CASE_NOTES_CLIENT.ERROR: {
      return state
        .setIn(["ndisClaims", "fetchNdisClaimCaseNoteClientsLoader"], false)
        .setIn(
          ["ndisClaims", "fetchNdisClaimCaseNoteClientsError"],
          action.error
        );
    }
    case ACTIONS.GET_SINGLE_SUPPORT_TRACT.PENDING: {
      return state.set("singleSupportTrackLoading", true).set("error", "");
    }
    case ACTIONS.GET_SINGLE_SUPPORT_TRACT.SUCCESS: {
      return state
        .set("singleSupportTrackLoading", false)
        .set("error", "")
        .set("singleSupportTract", action.data.data);
    }
    case ACTIONS.GET_SINGLE_SUPPORT_TRACT.ERROR: {
      return state.set("singleSupportTrackLoading", false).set("error", action);
    }
    case ACTIONS.GET_SUPPORT_TRACT_LIST.PENDING: {
      return state.set("supportTrackListLoading", true).set("error", "");
    }
    case ACTIONS.GET_SUPPORT_TRACT_LIST.SUCCESS: {
      return state
        .set("supportTrackListLoading", false)
        .set("error", "")
        .set("supportTractList", action.data);
    }
    case ACTIONS.GET_SUPPORT_TRACT_LIST.ERROR: {
      return state.set("supportTrackListLoading", false).set("error", action);
    }
    case ACTIONS.RESET_PARTICIAPNT_PROFILE: {
      return state
        .set("supportTractList", null)
        .set("singleSupportTract", null)
        .set("fundingList", null)
        .set("clientTaskList", null)
        .set("casNotesList", null)
        .set("fundingsForCos", null)
        .set("serviceSupportItemsCaseNotes", [])
        .set("cosParticipantsProfile", {});
    }

    case ACTIONS.GET_REPORT_DATA.PENDING: {
      return state.set("reportLoading", true).set("error", "");
    }
    case ACTIONS.GET_REPORT_DATA.SUCCESS: {
      return state
        .set("reportLoading", false)
        .set("error", "")
        .set("reportData", action.data);
    }
    case ACTIONS.GET_REPORT_DATA.ERROR: {
      return state.set("reportLoading", false).set("error", action);
    }

    case ACTIONS.UPDATE_CLIENT_TASKS_LIST.PENDING: {
      return state.set("createclientTaskListLoading", true).set("error", "");
    }

    case ACTIONS.UPDATE_CLIENT_TASKS_LIST.ERROR: {
      return state
        .set("createclientTaskListLoading", false)
        .set("error", action);
    }
    case ACTIONS.GET_CLIENT_TASKS_LIST.PENDING: {
      return state.set("clientTaskListLoading", true).set("error", "");
    }
    case ACTIONS.GET_CLIENT_TASKS_LIST.SUCCESS: {
      return state
        .set("clientTaskListLoading", false)
        .set("error", "")
        .set("clientTaskList", action.data.client_tasks);
    }
    case ACTIONS.GET_CLIENT_TASKS_LIST_UNMOUNT: {
      return state.set("updateClientTaskResponse", "");
    }
    case ACTIONS.UPDATE_CLIENT_TASKS_LIST.SUCCESS: {
      const list = state.get("clientTaskList");

      const updated = list?.data.filter(
        (item) => item.uuid !== action.data.client_task.uuid
      );

      updated.unshift(action.data.client_task);

      const newObj = {
        ...list,
        data: updated,
      };

      return state
        .set("clientTaskList", newObj)
        .set("createclientTaskListLoading", false)
        .set("error", "")
        .set("updateClientTaskResponse", action.data);
    }

    case ACTIONS.GET_CLIENT_TASKS_LIST.ERROR: {
      return state.set("clientTaskListLoading", false).set("error", action);
    }
    case ACTIONS.CREATE_CLIENT_TASK.PENDING: {
      return state.set("createclientTaskListLoading", true).set("error", "");
    }
    case ACTIONS.CREATE_CLIENT_TASK.SUCCESS: {
      const data = state.get("clientTaskList");

      data.data.unshift(action.data.client_task);

      return state
        .set("clientTaskList", data)
        .set("createclientTaskListLoading", false)
        .set("error", "")
        .set("clientTaskResponse", action.data.client_task);
    }

    case ACTIONS.CREATE_CLIENT_TASK.ERROR: {
      return state
        .set("createclientTaskListLoading", false)
        .set("error", action);
    }
    case ACTIONS.DELETE_CLIENT_TASKS.PENDING: {
      return state.set("loading", true).set("error", "");
    }
    case ACTIONS.DELETE_CLIENT_TASKS.SUCCESS: {
      const list = state.get("clientTaskList") || {
        data: [],
        ...emptyPaginationObj,
      };

      const updated = list?.data.filter(
        (item) => item.uuid !== action.data.client_task.uuid
      );

      const newObj = {
        ...list,
        data: updated,
      };

      return state
        .set("clientTaskList", newObj)
        .set("loading", false)
        .set("error", "")
        .set("deleteClientTaskResponse", action.data);
    }
    case ACTIONS.DELETE_CLIENT_TASKS.ERROR: {
      return state.set("loading", false).set("error", action);
    }
    case ACTIONS.CREATE_CLIENT_TASK_UNMOUNT: {
      return state.set("clientTaskResponse", "");
    }

    case ACTIONS.GET_COS_CASENOTES.PENDING: {
      return state.set("casNotesListLoading", true).set("error", "");
    }
    case ACTIONS.GET_COS_CASENOTES.SUCCESS: {
      return state
        .set("casNotesListLoading", false)
        .set("error", "")
        .set("casNotesList", action.data);
    }
    case ACTIONS.GET_COS_CASENOTES.ERROR: {
      return state.set("casNotesListLoading", false).set("error", action);
    }
    case ACTIONS.COS_CASENOTES_CREATE.PENDING: {
      return state
        .set("caseNotesCreateLoading", true)
        .set("caseNoteerror", null)
        .set("cerateCaseNotesSuccessData", null);
    }
    case ACTIONS.COS_CASENOTES_CREATE.SUCCESS: {
      const data = state.get("casNotesList");
      const dataCase = state.get("serviceSupportItemsCaseNotes");
      const oldFunding = state.get("fundingsForCos");
      const updatedFunding = oldFunding.map((item) => {
        if (item.id === action.data.data.funding.id) {
          return {
            id: action?.data?.data?.funding?.id,
            uuid: action?.data?.data?.funding?.uuid,
            contract_id: action?.data?.data?.funding?.contract_id,
            start_date: action?.data?.data?.funding?.start_date,
            end_date: action?.data?.data?.funding?.end_date,
            amount_allocated: action?.data?.data?.funding?.amount_allocated,
            amount_delivered: action?.data?.data?.funding?.amount_delivered,
            amount_remaining: action?.data?.data?.funding?.amount_remaining,
            review_date: action?.data?.data?.funding?.review_date,
            sign_date: action?.data?.data?.funding?.sign_date,
            status: action?.data?.data?.funding?.status,
            total_contract_amount:
              action?.data?.data?.funding?.total_contract_amount,
          };
        } else {
          return { ...item };
        }
      });
      const updated = dataCase.map((item) => {
        if (item.id === action.data.data.service_support_budget.id) {
          return {
            ...item,
            remain_total_hours:
              action.data.data.service_support_budget.remain_total_hours,
            remain_total_mint:
              action.data.data.service_support_budget.remain_total_mint,
            delivered_amount:
              action.data.data.service_support_budget.delivered_amount,
            remain_amount:
              action.data.data.service_support_budget.remain_amount,
            remain_quantity:
              action.data.data.service_support_budget.remain_quantity,
          };
        } else {
          return { ...item };
        }
      });
      if (data?.data && data.data.length > 0) {
        data.data.unshift({
          ...action.data.data.cos_case_note,
          external_sp: action.data.data.external_sp,
        });
      } else {
        data.data = [
          {
            ...action.data.data.cos_case_note,
            external_sp: action.data.data.external_sp,
          },
        ];
      }
      return state
        .set("fundingsForCos", updatedFunding)
        .set("serviceSupportItemsCaseNotes", [...updated])
        .set("singleSupportTract", {
          ...action.data.data.service_support_budget,
        })
        .set("cerateCaseNotesSuccessData", { ...action.data })
        .set("caseNotesCreateLoading", false)
        .set("caseNoteerror", null)
        .set("casNotesList", { ...data });
    }
    case ACTIONS.COS_CASENOTES_CREATE.ERROR: {
      return state
        .set("caseNotesCreateLoading", false)
        .set("caseNoteerror", action);
    }
    case ACTIONS.COS_PARTICIPANTS_PROFILE.PENDING: {
      return state.set("loading", true).set("error", "");
    }
    case ACTIONS.COS_PARTICIPANTS_PROFILE.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("cosParticipantsProfile", action.data);
    }
    case ACTIONS.COS_PARTICIPANTS_PROFILE.ERROR: {
      return state.set("loading", false).set("error", action);
    }

    case ACTIONS.CATALOG_VERSIONS.PENDING: {
      return state.set("catalogServicesLoading", true).set("error", "");
    }
    case ACTIONS.CATALOG_VERSIONS.SUCCESS: {
      return state
        .set("catalogServicesLoading", false)
        .set("error", "")
        .set("catalog_versions", action.data);
    }
    case ACTIONS.CATALOG_VERSIONS.ERROR: {
      return state.set("catalogServicesLoading", false).set("error", action);
    }

    case ACTIONS.CATALOG_SERVICES.PENDING: {
      return state
        .set("loading", action.status === FETCH_STATUS.LOADING)
        .set("error", "");
    }
    case ACTIONS.CATALOG_SERVICES.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("catalog_services", action.data);
    }
    case ACTIONS.CATALOG_SERVICES.ERROR: {
      return state.set("loading", false).set("error", action);
    }

    case ACTIONS.STORE_AND_UPDATE_FUNDING_SETUP.PENDING: {
      return state
        .setIn(
          ["participantFunding", "storeFundingSetupLoading"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "storeFundingSetupError"], "");
    }
    case ACTIONS.STORE_AND_UPDATE_FUNDING_SETUP.SUCCESS: {
      const fundings = state.getIn(
        ["participantFunding", "fundings"] || {
          data: [],
          ...emptyPaginationObj,
        }
      );
      if (!action.isUpdate) {
        fundings.data.unshift({ ...action.data });
      } else {
        const index = fundings.data.findIndex(
          (x) => x.uuid === action.data.uuid
        );
        if (index !== -1) {
          fundings.data.splice(index, 1, { ...action.data });
        }
      }
      return state
        .setIn(["participantFunding", "storeFundingSetupLoading"], false)
        .setIn(["participantFunding", "storeFundingSetupError"], "")
        .setIn(["participantFunding", "fundings"], { ...fundings });
    }
    case ACTIONS.STORE_AND_UPDATE_FUNDING_SETUP.ERROR: {
      return state
        .setIn(["participantFunding", "storeFundingSetupLoading"], false)
        .setIn(["participantFunding", "storeFundingSetupError"], action);
    }
    case ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT.PENDING: {
      return state
        .setIn(
          ["participantFunding", "fundingsLoading"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "fundingsError"], "");
    }
    case ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT.SUCCESS: {
      return state
        .setIn(["participantFunding", "fundingsLoading"], false)
        .setIn(["participantFunding", "fundingsError"], "")
        .setIn(["participantFunding", "fundings"], action.data.funding);
    }
    case ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT.ERROR: {
      return state
        .setIn(["participantFunding", "fundingsLoading"], false)
        .setIn(["participantFunding", "fundingsError"], action);
    }

    case ACTIONS.CATALOG_SERVICES_SUB_CATEGORY.PENDING: {
      return state.set("loading", true).set("error", "");
    }
    case ACTIONS.CATALOG_SERVICES_SUB_CATEGORY.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("catalog_sub_category", action.data);
    }
    case ACTIONS.CATALOG_SERVICES_SUB_CATEGORY.ERROR: {
      return state.set("loading", false).set("error", action);
    }

    case ACTIONS.SELCETED_FUNDING_SETUP.PENDING: {
      return state
        .setIn(
          ["participantFunding", "selectedFundingLoading"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "selectedFundingError"], "");
    }
    case ACTIONS.SELCETED_FUNDING_SETUP.SUCCESS: {
      return state
        .setIn(["participantFunding", "selectedFundingLoading"], false)
        .setIn(["participantFunding", "selectedFundingError"], "")
        .setIn(["participantFunding", "selectedFunding"], action.data.funding);
    }
    case ACTIONS.SELCETED_FUNDING_SETUP.ERROR: {
      return state
        .setIn(["participantFunding", "selectedFundingLoading"], false)
        .setIn(["participantFunding", "selectedFundingError"], action);
    }
    case ACTIONS.RESET_PARTICIAPNT_ALL_FUNDINGS: {
      return state.set("participantFunding", {}).set("planCatagories", null);
    }
    case ACTIONS.PLAN_CATAGORIES.PENDING: {
      return state
        .set("loading", action.status === FETCH_STATUS.LOADING)
        .set("error", "");
    }
    case ACTIONS.PLAN_CATAGORIES.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("planCatagories", action.data);
    }
    case ACTIONS.PLAN_CATAGORIES.ERROR: {
      return state.set("loading", false).set("error", action);
    }

    case ACTIONS.CREATE_PLAN_BUDGET.PENDING: {
      return state
        .setIn(
          ["participantFunding", "createPlanBudgetLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "createPlanBudgetError"], "");
    }
    case ACTIONS.CREATE_PLAN_BUDGET.SUCCESS: {
      const prevPlanBudgets =
        state.getIn(["participantFunding", "planBudgetFunding"]) || [];
      if (action.data.isUpdate) {
        const index = prevPlanBudgets.data.findIndex(
          (x) => x.uuid === action.data.plan_budget.uuid
        );
        if (index !== -1) {
          prevPlanBudgets.data.splice(index, 1, { ...action.data.plan_budget });
        }
      } else {
        prevPlanBudgets.data.unshift({ ...action.data.plan_budget });
        prevPlanBudgets.total += 1;
      }

      return state
        .setIn(["participantFunding", "createPlanBudgetLoader"], false)
        .setIn(["participantFunding", "createPlanBudgetError"], "")
        .setIn(["participantFunding", "planBudgetFunding"], {
          ...prevPlanBudgets,
        })
        .setIn(["participantFunding", "selectedFunding"], action.data.funding);
    }
    case ACTIONS.CREATE_PLAN_BUDGET.ERROR: {
      return state
        .setIn(["participantFunding", "createPlanBudgetLoader"], false)
        .setIn(["participantFunding", "createPlanBudgetError"], action);
    }

    case ACTIONS.PLAN_BUDGET_LIST.PENDING: {
      return state
        .setIn(
          ["participantFunding", "fetchPlanBudgetLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "fetchPlanBudgetError"], "");
    }
    case ACTIONS.PLAN_BUDGET_LIST.SUCCESS: {
      return state
        .setIn(["participantFunding", "fetchPlanBudgetLoader"], false)
        .setIn(["participantFunding", "fetchPlanBudgetError"], "")
        .setIn(["participantFunding", "planBudgetFunding"], action.data);
    }
    case ACTIONS.PLAN_BUDGET_LIST.ERROR: {
      return state
        .setIn(["participantFunding", "fetchPlanBudgetLoader"], false)
        .setIn(["participantFunding", "fetchPlanBudgetError"], action.error);
    }

    case ACTIONS.PLAN_BUDGET_DELETED: {
      const prevPlanBudgets =
        state.getIn(["participantFunding", "planBudgetFunding"]) || null;
      if (prevPlanBudgets && prevPlanBudgets.data) {
        const index = prevPlanBudgets.data.findIndex(
          (x) => x.uuid === action.data.plan_budget_id
        );
        if (index !== -1) {
          prevPlanBudgets.data.splice(index, 1);
          prevPlanBudgets.total -= 1;
        }
        if (
          prevPlanBudgets.data.length === 0 &&
          prevPlanBudgets.total_pages >= prevPlanBudgets.current_page &&
          prevPlanBudgets.current_page > 1
        ) {
          prevPlanBudgets.current_page -= 1;
          prevPlanBudgets.total_pages -= 1;
        }
      }
      return state
        .setIn(["participantFunding", "planBudgetFunding"], {
          ...prevPlanBudgets,
        })
        .setIn(["participantFunding", "selectedFunding"], {
          ...action.data.funding,
        });
    }

    case ACTIONS.PLAN_BUDGET_CAT_AND_SUB_CAT.PENDING: {
      return state
        .setIn(
          ["participantFunding", "fetchPlanBudgetCatAndSubCatLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "fetchPlanBudgetCatAndSubCatError"], "");
    }
    case ACTIONS.PLAN_BUDGET_CAT_AND_SUB_CAT.SUCCESS: {
      return state
        .setIn(
          ["participantFunding", "fetchPlanBudgetCatAndSubCatLoader"],
          false
        )
        .setIn(["participantFunding", "fetchPlanBudgetCatAndSubCatError"], "")
        .setIn(["participantFunding", "planBudgetCatAndSubCat"], action.data);
    }
    case ACTIONS.PLAN_BUDGET_CAT_AND_SUB_CAT.ERROR: {
      return state
        .setIn(
          ["participantFunding", "fetchPlanBudgetCatAndSubCatLoader"],
          false
        )
        .setIn(
          ["participantFunding", "fetchPlanBudgetCatAndSubCatError"],
          action.error
        );
    }

    case ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_STORE.PENDING: {
      return state
        .setIn(
          ["participantFunding", "createServiceCategoryBudgetLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "serviceCategoryPlanBudgetError"], "");
    }
    case ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_STORE.SUCCESS: {
      const prevServiceCategoryPlan = state.getIn([
        "participantFunding",
        "serviceCategoryPlanBudget",
      ]) || {
        data: [],
        ...emptyPaginationObj,
      };
      if (action.data.isUpdate) {
        const index = prevServiceCategoryPlan.data.findIndex(
          (x) => x.uuid === action.data.response.sp_cat_budget.uuid
        );
        if (index !== -1) {
          prevServiceCategoryPlan.data.splice(index, 1, {
            ...action.data.response.sp_cat_budget,
          });
        }
      } else {
        prevServiceCategoryPlan.data.unshift({
          ...action.data.response.sp_cat_budget,
        });
      }

      const prevPlanBudgetFunding = state.getIn([
        "participantFunding",
        "planBudgetFunding",
      ]) || { data: [], ...emptyPaginationObj };
      if (
        action.data.response.sp_cat_budget &&
        prevPlanBudgetFunding &&
        prevPlanBudgetFunding.data.length > 0
      ) {
        const index = prevPlanBudgetFunding.data.findIndex(
          (x) => action.data.response.sp_cat_budget.plan_budget.uuid === x.uuid
        );
        if (index !== -1) {
          Object.assign(prevPlanBudgetFunding.data[index], {
            amount_allocated:
              action.data.response.sp_cat_budget.plan_budget.amount_allocated,
            amount_remaining:
              action.data.response.sp_cat_budget.plan_budget.amount_remaining,
            amount_claimed:
              action.data.response.sp_cat_budget.plan_budget.amount_claimed,
            amount_unclaimed:
              action.data.response.sp_cat_budget.plan_budget.amount_unclaimed,
            amount_delivered:
              action.data.response.sp_cat_budget.plan_budget.amount_delivered,
          });
          prevPlanBudgetFunding.data.splice(
            index,
            1,
            prevPlanBudgetFunding.data[index]
          );
        }
      }

      return state
        .setIn(
          ["participantFunding", "createServiceCategoryBudgetLoader"],
          false
        )
        .setIn(["participantFunding", "serviceCategoryPlanBudgetError"], "")
        .setIn(["participantFunding", "serviceCategoryPlanBudget"], {
          ...prevServiceCategoryPlan,
        })
        .setIn(["participantFunding", "planBudgetFunding"], {
          ...prevPlanBudgetFunding,
        });
    }
    case ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_STORE.ERROR: {
      return state
        .setIn(
          ["participantFunding", "createServiceCategoryBudgetLoader"],
          false
        )
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudgetError"],
          action.error
        );
    }

    case ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_FETCH.PENDING: {
      return state
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudgetFetchLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudgetFetchError"],
          ""
        );
    }
    case ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_FETCH.SUCCESS: {
      return state
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudgetFetchLoader"],
          false
        )
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudgetFetchError"],
          ""
        )
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudget"],
          action.data.sp_cat_budgets
        );
    }
    case ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_FETCH.ERROR: {
      return state
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudgetFetchLoader"],
          false
        )
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudgetFetchError"],
          action.error
        );
    }

    case ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_DELETE.PENDING: {
      return state
        .setIn(
          ["participantFunding", "removeServiceCategoryLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "removeServiceCategoryLoader"], true);
    }
    case ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_DELETE.SUCCESS: {
      const spCategoryBudget =
        state.getIn(["participantFunding", "serviceCategoryPlanBudget"]) ||
        null;
      if (spCategoryBudget && spCategoryBudget.data) {
        const index = spCategoryBudget.data.findIndex(
          (x) => x.uuid === action.data.sp_category_budget_id
        );
        if (index !== -1) {
          spCategoryBudget.data.splice(index, 1);
        }
      }

      const prevPlanBudgetFunding = state.getIn([
        "participantFunding",
        "planBudgetFunding",
      ]) || { data: [], ...emptyPaginationObj };
      if (
        action.data &&
        action.data.plan_budget &&
        prevPlanBudgetFunding &&
        prevPlanBudgetFunding.data.length > 0
      ) {
        const index = prevPlanBudgetFunding.data.findIndex(
          (x) => x.uuid === action.data.plan_budget.uuid
        );
        if (index !== -1) {
          Object.assign(prevPlanBudgetFunding.data[index], {
            amount_allocated: action.data.plan_budget.amount_allocated,
            amount_remaining: action.data.plan_budget.amount_remaining,
            amount_claimed: action.data.plan_budget.amount_claimed,
            amount_unclaimed: action.data.plan_budget.amount_unclaimed,
            amount_delivered: action.data.plan_budget.amount_delivered,
          });
          prevPlanBudgetFunding.data.splice(
            index,
            1,
            prevPlanBudgetFunding.data[index]
          );
        }
      }

      return state
        .setIn(["participantFunding", "planBudgetFunding"], {
          ...prevPlanBudgetFunding,
        })
        .setIn(["participantFunding", "removeServiceCategoryLoader"], false)
        .setIn(["participantFunding", "removeServiceCategoryError"], "")
        .setIn(["participantFunding", "serviceCategoryPlanBudget"], {
          ...spCategoryBudget,
        });
    }
    case ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_DELETE.ERROR: {
      return state
        .setIn(["participantFunding", "removeServiceCategoryLoader"], false)
        .setIn(["participantFunding", "removeServiceCategoryError"], action);
    }
    case ACTIONS.EXTERNAL_SP_LIST_FETCHED.PENDING: {
      return state.set("loading", true).set("error", "");
    }
    case ACTIONS.EXTERNAL_SP_LIST_FETCHED.SUCCESS: {
      return state
        .set("loading", false)
        .set("error", "")
        .set("externalSpList", action.data.external_sp);
    }
    case ACTIONS.EXTERNAL_SP_LIST_FETCHED.ERROR: {
      return state.set("loading", false).set("error", action);
    }

    case ACTIONS.EXTERNAL_SP_CREATED.PENDING: {
      return state
        .set("externalSpCreateLoader", true)
        .set("externalSpCreateError", "");
    }
    case ACTIONS.EXTERNAL_SP_CREATED.SUCCESS: {
      const prevExSpList = state.get("externalSpList") || {
        data: [],
        ...emptyPaginationObj,
      };
      if (prevExSpList && action.data) {
        prevExSpList.data.unshift({ ...action.data.external_sp });
      }
      return state
        .set("externalSpCreateLoader", false)
        .set("externalSpCreateError", "")
        .set("externalSpList", { ...prevExSpList });
    }
    case ACTIONS.EXTERNAL_SP_CREATED.ERROR: {
      return state
        .set("externalSpCreateLoader", false)
        .set("externalSpCreateError", action?.error ?? action);
    }

    case ACTIONS.FUNDING_NOTES_LIST.PENDING: {
      return state
        .setIn(
          ["participantFunding", "fetchFundingNotesLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "fetchFundingNotesError"], "");
    }
    case ACTIONS.FUNDING_NOTES_LIST.SUCCESS: {
      return state
        .setIn(["participantFunding", "fetchFundingNotesLoader"], false)
        .setIn(["participantFunding", "fetchFundingNotesError"], "")
        .setIn(["participantFunding", "fundingNotes"], action.data);
    }
    case ACTIONS.FUNDING_NOTES_LIST.ERROR: {
      return state
        .setIn(["participantFunding", "fetchFundingNotesLoader"], false)
        .setIn(["participantFunding", "fetchFundingNotesError"], action.error);
    }

    case ACTIONS.CREATE_FUNDING_NOTES.PENDING: {
      return state
        .setIn(
          ["participantFunding", "createFundingNotesLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "createFundingListError"], "");
    }
    case ACTIONS.CREATE_FUNDING_NOTES.SUCCESS: {
      const prevPlanBudgets =
        state.getIn(["participantFunding", "fundingNotes"]) || [];
      if (action.data.isUpdate) {
        const index = prevPlanBudgets.data.findIndex(
          (x) => x.uuid === action.data.funding_note.uuid
        );
        if (index !== -1) {
          prevPlanBudgets.data.splice(index, 1, {
            ...action.data.funding_note,
          });
        }
      } else {
        prevPlanBudgets.data.unshift({ ...action.data.funding_note });
      }

      return (
        state
          .setIn(["participantFunding", "createFundingNotesLoader"], false)
          .setIn(["participantFunding", "createFundingListError"], "")
          // .setIn(["participantFunding", "selectedFunding"], action.data.funding)
          .setIn(["participantFunding", "fundingNotes"], {
            ...prevPlanBudgets,
          })
      );
      // .setIn(["participantFunding", "selectedFunding"], action.data.funding);
    }
    case ACTIONS.CREATE_FUNDING_NOTES.ERROR: {
      return state
        .setIn(["participantFunding", "createFundingNotesLoader"], false)
        .setIn(["participantFunding", "createFundingListError"], action);
    }

    case ACTIONS.SERVICE_SUPPORT_BUDGET_CREATE.PENDING: {
      return state
        .setIn(
          ["participantFunding", "createServiceSupportLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "createServiceSupportError"], "");
    }

    case ACTIONS.SERVICE_SUPPORT_BUDGET_CREATE.SUCCESS: {
      const prevServiceSupport = state.getIn([
        "participantFunding",
        "serviceSupportBudget",
      ]) || { data: [], ...emptyPaginationObj };
      const prevServiceCategoryPlan = state.getIn([
        "participantFunding",
        "serviceCategoryPlanBudget",
      ]) || { data: [], ...emptyPaginationObj };
      if (action.data.sp_category_budget) {
        if (
          prevServiceCategoryPlan &&
          prevServiceCategoryPlan.data.length > 0
        ) {
          const index = prevServiceCategoryPlan.data.findIndex(
            (x) => action.data.sp_category_budget.uuid === x.uuid
          );
          if (index !== -1) {
            const obj = prevServiceCategoryPlan.data[index];
            Object.assign(obj, {
              amount_allocated: action.data.sp_category_budget.amount_allocated,
              amount_remaining: action.data.sp_category_budget.amount_remaining,
              amount_claimed: action.data.sp_category_budget.amount_claimed,
              amount_unclaimed: action.data.sp_category_budget.amount_unclaimed,
              amount_delivered: action.data.sp_category_budget.amount_delivered,
            });
            prevServiceCategoryPlan.data.splice(index, 1, { ...obj });
          }
        }
      }
      const prevPlanBudgetFunding = state.getIn([
        "participantFunding",
        "planBudgetFunding",
      ]) || { data: [], ...emptyPaginationObj };
      if (prevPlanBudgetFunding && prevPlanBudgetFunding.data.length > 0) {
        const index = prevPlanBudgetFunding.data.findIndex(
          (x) => action.data.plan_budget.uuid === x.uuid
        );
        if (index !== -1) {
          Object.assign(prevPlanBudgetFunding.data[index], {
            amount_allocated: action.data.plan_budget.amount_allocated,
            amount_remaining: action.data.plan_budget.amount_remaining,
            amount_claimed: action.data.plan_budget.amount_claimed,
            amount_unclaimed: action.data.plan_budget.amount_unclaimed,
            amount_delivered: action.data.plan_budget.amount_delivered,
          });
          prevPlanBudgetFunding.data.splice(
            index,
            1,
            prevPlanBudgetFunding.data[index]
          );
        }
      }
      const prevFunding =
        state.getIn(["participantFunding", "selectedFunding"]) || null;

      if (prevFunding) {
        Object.assign(prevFunding, {
          amount_allocated: action.data.funding.amount_allocated,
          amount_remaining: action.data.funding.amount_remaining,
          amount_claimed: action.data.funding.amount_claimed,
          amount_unclaimed: action.data.funding.amount_unclaimed,
          amount_delivered: action.data.funding.amount_delivered,
        });
      }
      if (prevServiceSupport && prevServiceSupport.data) {
        if (action.isUpdate) {
          const index = prevServiceSupport.data.findIndex(
            (x) => x.uuid === action.data.uuid
          );
          if (index !== -1) {
            prevServiceSupport.data.splice(
              index,
              1,
              action.service_support_budget
            );
          }
        } else {
          prevServiceSupport.data.unshift({ ...action.data });
        }
      }
      return state
        .setIn(["participantFunding", "createServiceSupportLoader"], false)
        .setIn(["participantFunding", "createServiceSupportError"], "")
        .setIn(["participantFunding", "serviceSupportBudget"], {
          ...prevServiceSupport,
        })
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudget"],
          prevServiceCategoryPlan
        )
        .setIn(
          ["participantFunding", "planBudgetFunding"],
          prevPlanBudgetFunding
        )
        .setIn(["participantFunding", "selectedFunding"], { ...prevFunding });
    }
    case ACTIONS.SERVICE_SUPPORT_BUDGET_CREATE.ERROR: {
      return state
        .setIn(["participantFunding", "createServiceSupportLoader"], false)
        .setIn(["participantFunding", "createServiceSupportError"], action);
    }

    case ACTIONS.SERVICE_SUPPORT_BUDGET_FETCH.PENDING: {
      return state
        .setIn(
          ["participantFunding", "fetchServiceSupportLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "fetchServiceSupportError"], "");
    }
    case ACTIONS.SERVICE_SUPPORT_BUDGET_FETCH.SUCCESS: {
      return state
        .setIn(["participantFunding", "fetchServiceSupportLoader"], false)
        .setIn(["participantFunding", "fetchServiceSupportError"], "")
        .setIn(["participantFunding", "serviceSupportBudget"], {
          ...action.data.forms,
        });
    }
    case ACTIONS.SERVICE_SUPPORT_BUDGET_FETCH.ERROR: {
      return state
        .setIn(["participantFunding", "fetchServiceSupportLoader"], false)
        .setIn(
          ["participantFunding", "fetchServiceSupportError"],
          action.error
        );
    }

    case ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE.PENDING: {
      return state

        .set("serviceSupportItemsCaseNotes", null)
        .setIn(
          ["participantFunding", "fetchServiceSupportItemsForDeliveredLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(
          ["participantFunding", "fetchServiceSupportItemsForDeliveredError"],
          ""
        );
    }

    case ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CASE_NOTES: {
      return state
        .setIn(
          ["participantFunding", "fetchServiceSupportItemsForDeliveredLoader"],
          false
        )
        .set("serviceSupportItemsCaseNotes", action.data.service_support_items);
    }

    case ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE.SUCCESS: {
      return state
        .setIn(
          ["participantFunding", "fetchServiceSupportItemsForDeliveredLoader"],
          false
        )
        .setIn(
          ["participantFunding", "fetchServiceSupportItemsForDeliveredError"],
          ""
        )
        .setIn(["participantFunding", "serviceSupportItems"], action.data);
    }
    case ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE.ERROR: {
      return state
        .setIn(
          ["participantFunding", "fetchServiceSupportItemsForDeliveredLoader"],
          false
        )
        .setIn(
          ["participantFunding", "fetchServiceSupportItemsForDeliveredError"],
          action.error
        );
    }
    case ACTIONS.CLEAR_SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CASE_NOTE: {
      return state
        .set("serviceSupportItemsCaseNotes", null)
        .set("singleSupportTract", null);
    }
    case ACTIONS.COS_PARTICIPANT_LIST.PENDING: {
      return state
        .setIn(
          ["participantFunding", "fetchCosParticipantLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "fetchCosParticipantError"], "");
    }
    case ACTIONS.COS_PARTICIPANT_LIST.SUCCESS: {
      return state
        .setIn(["participantFunding", "fetchCosParticipantLoader"], false)
        .setIn(["participantFunding", "fetchCosParticipantError"], "")
        .setIn(["participantFunding", "cosParticipant"], action.data);
    }
    case ACTIONS.COS_PARTICIPANT_LIST.ERROR: {
      return state
        .setIn(["participantFunding", "fetchCosParticipantLoader"], false)
        .setIn(
          ["participantFunding", "fetchCosParticipantError"],
          action.error
        );
    }

    case ACTIONS.SUPPORT_COORDINATORS.PENDING: {
      return state
        .setIn(
          ["participantFunding", "supportCoordinatorLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "supportCoordinatorError"], "");
    }
    case ACTIONS.SUPPORT_COORDINATORS.SUCCESS: {
      return state
        .setIn(["participantFunding", "supportCoordinatorLoader"], false)
        .setIn(["participantFunding", "supportCoordinatorError"], "")
        .setIn(["participantFunding", "supportCoordinator"], action.data);
    }
    case ACTIONS.SUPPORT_COORDINATORS.ERROR: {
      return state
        .setIn(["participantFunding", "supportCoordinatorLoader"], false)
        .setIn(["participantFunding", "supportCoordinatorError"], action.error);
    }

    case ACTIONS.FUNDING_NOTES_DELETED: {
      const prevFundingNotes =
        state.getIn(["participantFunding", "fundingNotes"] || null) || null;
      if (prevFundingNotes && prevFundingNotes.data) {
        const index = prevFundingNotes.data.findIndex(
          (x) => x.uuid === action.data.fundingNotesId
        );
        if (index !== -1) {
          prevFundingNotes.data.splice(index, 1);
          prevFundingNotes.total -= 1;
        }
        if (
          prevFundingNotes.data.length === 0 &&
          prevFundingNotes.total_pages >= prevFundingNotes.current_page &&
          prevFundingNotes.current_page > 1
        ) {
          prevFundingNotes.current_page -= 1;
          prevFundingNotes.total_pages -= 1;
        }
      }

      return state.setIn(["participantFunding", "fundingNotes"], {
        ...prevFundingNotes,
      });
    }

    case ACTIONS.DELIVERED_SERVICE_CREATE.PENDING: {
      return state
        .setIn(
          ["participantFunding", "deliveredServiceStoreLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "deliveredServiceStoreError"], "");
    }
    case ACTIONS.DELIVERED_SERVICE_CREATE.SUCCESS: {
      const prevDeliveredServices = state.getIn([
        "participantFunding",
        "deliveredServices",
      ]) || { data: [], ...emptyPaginationObj };
      if (prevDeliveredServices && prevDeliveredServices.data) {
        prevDeliveredServices.data.unshift({
          ...action.data.delivered_service,
        });
      }
      const prevServiceSupportItems = state.getIn([
        "participantFunding",
        "serviceSupportBudget",
      ]) || { data: [], ...emptyPaginationObj };
      if (prevServiceSupportItems && prevServiceSupportItems.data.length > 0) {
        const index = prevServiceSupportItems.data.findIndex(
          (x) => action.data.service_support_budget.uuid === x.uuid
        );
        if (index !== -1) {
          const obj = prevServiceSupportItems.data[index];
          Object.assign(obj, {
            quantity: action.data.service_support_budget.quantity,
            remain_quantity: action.data.service_support_budget.remain_quantity,
            total_hours: action.data.service_support_budget.total_hours,
            total_mint: action.data.service_support_budget.total_mint,
            remain_total_hours:
              action.data.service_support_budget.remain_total_hours,
            remain_total_mint:
              action.data.service_support_budget.remain_total_mint,
            amount_allocated:
              action.data.service_support_budget.amount_allocated,
            delivered_amount:
              action.data.service_support_budget.delivered_amount,
            remain_amount: action.data.service_support_budget.remain_amount,
          });
          prevServiceSupportItems.data.splice(index, 1, obj);
        }
      }
      const prevServiceCategoryPlan = state.getIn([
        "participantFunding",
        "serviceCategoryPlanBudget",
      ]) || { data: [], ...emptyPaginationObj };
      if (action.data.sp_category_budget) {
        if (
          prevServiceCategoryPlan &&
          prevServiceCategoryPlan.data.length > 0
        ) {
          const index = prevServiceCategoryPlan.data.findIndex(
            (x) => action.data.sp_category_budget.uuid === x.uuid
          );
          if (index !== -1) {
            const obj = prevServiceCategoryPlan.data[index];
            Object.assign(obj, {
              amount_allocated: action.data.sp_category_budget.amount_allocated,
              amount_remaining: action.data.sp_category_budget.amount_remaining,
              amount_claimed: action.data.sp_category_budget.amount_claimed,
              amount_unclaimed: action.data.sp_category_budget.amount_unclaimed,
              amount_delivered: action.data.sp_category_budget.amount_delivered,
            });
            prevServiceCategoryPlan.data.splice(index, 1, { ...obj });
          }
        }
      }
      const prevPlanBudgetFunding = state.getIn([
        "participantFunding",
        "planBudgetFunding",
      ]) || { data: [], ...emptyPaginationObj };
      if (prevPlanBudgetFunding && prevPlanBudgetFunding.data.length > 0) {
        const index = prevPlanBudgetFunding.data.findIndex(
          (x) => action.data.plan_budget.uuid === x.uuid
        );
        if (index !== -1) {
          Object.assign(prevPlanBudgetFunding.data[index], {
            amount_allocated: action.data.plan_budget.amount_allocated,
            amount_remaining: action.data.plan_budget.amount_remaining,
            amount_claimed: action.data.plan_budget.amount_claimed,
            amount_unclaimed: action.data.plan_budget.amount_unclaimed,
            amount_delivered: action.data.plan_budget.amount_delivered,
          });
          prevPlanBudgetFunding.data.splice(
            index,
            1,
            prevPlanBudgetFunding.data[index]
          );
        }
      }

      const prevFunding =
        state.getIn(["participantFunding", "selectedFunding"]) || null;

      if (prevFunding) {
        Object.assign(prevFunding, {
          amount_allocated: action.data.funding.amount_allocated,
          amount_remaining: action.data.funding.amount_remaining,
          amount_claimed: action.data.funding.amount_claimed,
          amount_unclaimed: action.data.funding.amount_unclaimed,
          amount_delivered: action.data.funding.amount_delivered,
        });
      }

      return state
        .setIn(["participantFunding", "deliveredServiceStoreLoader"], false)
        .setIn(["participantFunding", "deliveredServiceStoreError"], "")
        .setIn(
          ["participantFunding", "deliveredServices"],
          prevDeliveredServices
        )
        .setIn(
          ["participantFunding", "serviceSupportBudget"],
          prevServiceSupportItems
        )
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudget"],
          prevServiceCategoryPlan
        )
        .setIn(
          ["participantFunding", "planBudgetFunding"],
          prevPlanBudgetFunding
        )
        .setIn(["participantFunding", "selectedFunding"], { ...prevFunding });
    }
    case ACTIONS.DELIVERED_SERVICE_CREATE.ERROR: {
      return state
        .setIn(["participantFunding", "deliveredServiceStoreLoader"], false)
        .setIn(
          ["participantFunding", "deliveredServiceStoreError"],
          action.error
        );
    }

    case ACTIONS.MANUAL_DELIVERED_SERVICE_CREATE.PENDING: {
      return state
        .setIn(
          ["participantFunding", "manualDeliveredServiceStoreLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "manualDeliveredServiceStoreError"], "");
    }
    case ACTIONS.MANUAL_DELIVERED_SERVICE_CREATE.SUCCESS: {
      const prevDeliveredServices = state.getIn([
        "participantFunding",
        "manualDeliveries",
      ]) || { data: [], ...emptyPaginationObj };
      if (prevDeliveredServices) {
        prevDeliveredServices.data.unshift({
          ...action.data.delivered_service,
        });
      }
      const prevServiceSupportItems = state.getIn([
        "participantFunding",
        "serviceSupportBudget",
      ]) || { data: [], ...emptyPaginationObj };
      if (prevServiceSupportItems && action.data.service_support_budget) {
        const index = prevServiceSupportItems.data.findIndex(
          (x) => action.data.service_support_budget.uuid === x.uuid
        );
        if (index !== -1) {
          const obj = prevServiceSupportItems.data[index];
          Object.assign(obj, {
            quantity: action.data.service_support_budget.quantity,
            remain_quantity: action.data.service_support_budget.remain_quantity,
            total_hours: action.data.service_support_budget.total_hours,
            total_mint: action.data.service_support_budget.total_mint,
            remain_total_hours:
              action.data.service_support_budget.remain_total_hours,
            remain_total_mint:
              action.data.service_support_budget.remain_total_mint,
            amount_allocated:
              action.data.service_support_budget.amount_allocated,
            delivered_amount:
              action.data.service_support_budget.delivered_amount,
            remain_amount: action.data.service_support_budget.remain_amount,
          });
          prevServiceSupportItems.data.splice(index, 1, obj);
        }
      }
      const prevServiceCategoryPlan = state.getIn([
        "participantFunding",
        "serviceCategoryPlanBudget",
      ]) || { data: [], ...emptyPaginationObj };
      if (action.data.sp_category_budget) {
        if (
          prevServiceCategoryPlan &&
          prevServiceCategoryPlan.data.length > 0
        ) {
          const index = prevServiceCategoryPlan.data.findIndex(
            (x) => action.data.sp_category_budget.uuid === x.uuid
          );
          if (index !== -1) {
            const obj = prevServiceCategoryPlan.data[index];
            Object.assign(obj, {
              amount_allocated: action.data.sp_category_budget.amount_allocated,
              amount_remaining: action.data.sp_category_budget.amount_remaining,
              amount_claimed: action.data.sp_category_budget.amount_claimed,
              amount_unclaimed: action.data.sp_category_budget.amount_unclaimed,
              amount_delivered: action.data.sp_category_budget.amount_delivered,
            });
            prevServiceCategoryPlan.data.splice(index, 1, { ...obj });
          }
        }
      }
      const prevPlanBudgetFunding = state.getIn([
        "participantFunding",
        "planBudgetFunding",
      ]) || { data: [], ...emptyPaginationObj };
      if (prevPlanBudgetFunding && action.data.plan_budget) {
        const index = prevPlanBudgetFunding.data.findIndex(
          (x) => action.data.plan_budget.uuid === x.uuid
        );
        if (index !== -1) {
          Object.assign(prevPlanBudgetFunding.data[index], {
            total_budget_amount: action.data.plan_budget.total_budget_amount,
            amount_allocated: action.data.plan_budget.amount_allocated,
            amount_remaining: action.data.plan_budget.amount_remaining,
            amount_claimed: action.data.plan_budget.amount_claimed,
            amount_unclaimed: action.data.plan_budget.amount_unclaimed,
            amount_delivered: action.data.plan_budget.amount_delivered,
          });
          prevPlanBudgetFunding.data.splice(
            index,
            1,
            prevPlanBudgetFunding.data[index]
          );
        } else {
          prevPlanBudgetFunding.data.push({ ...action.data.plan_budget });
        }
      }

      const prevFunding =
        state.getIn(["participantFunding", "selectedFunding"]) || null;

      if (prevFunding) {
        Object.assign(prevFunding, {
          amount_allocated: action.data.funding.amount_allocated,
          amount_remaining: action.data.funding.amount_remaining,
          amount_claimed: action.data.funding.amount_claimed,
          amount_unclaimed: action.data.funding.amount_unclaimed,
          amount_delivered: action.data.funding.amount_delivered,
        });
      }

      return state
        .setIn(
          ["participantFunding", "manualDeliveredServiceStoreLoader"],
          false
        )
        .setIn(["participantFunding", "manualDeliveredServiceStoreError"], "")
        .setIn(
          ["participantFunding", "manualDeliveries"],
          prevDeliveredServices
        )
        .setIn(
          ["participantFunding", "serviceSupportBudget"],
          prevServiceSupportItems
        )
        .setIn(
          ["participantFunding", "serviceCategoryPlanBudget"],
          prevServiceCategoryPlan
        )
        .setIn(
          ["participantFunding", "planBudgetFunding"],
          prevPlanBudgetFunding
        )
        .setIn(["participantFunding", "selectedFunding"], { ...prevFunding });
    }
    case ACTIONS.MANUAL_DELIVERED_SERVICE_CREATE.ERROR: {
      return state
        .setIn(
          ["participantFunding", "manualDeliveredServiceStoreLoader"],
          false
        )
        .setIn(
          ["participantFunding", "manualDeliveredServiceStoreError"],
          action.error
        );
    }

    case ACTIONS.DELIVERED_SERVICE_FETCH.PENDING: {
      return state
        .setIn(
          ["participantFunding", "fetchDeliveredServiceLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "fetchDeliveredServiceError"], "");
    }
    case ACTIONS.DELIVERED_SERVICE_FETCH.SUCCESS: {
      return state
        .setIn(["participantFunding", "fetchDeliveredServiceLoader"], false)
        .setIn(["participantFunding", "fetchDeliveredServiceError"], "")
        .setIn(["participantFunding", "deliveredServices"], action.data);
    }
    case ACTIONS.DELIVERED_SERVICE_FETCH.ERROR: {
      return state
        .setIn(["participantFunding", "fetchDeliveredServiceLoader"], false)
        .setIn(
          ["participantFunding", "fetchDeliveredServiceError"],
          action.error
        );
    }

    case ACTIONS.MANUAL_DELIVEREIES_FETCH.PENDING: {
      return state
        .setIn(
          ["participantFunding", "fetchManualDeliveriesLoader"],
          action.status === FETCH_STATUS.LOADING
        )
        .setIn(["participantFunding", "fetchManualDeliveriesError"], "");
    }
    case ACTIONS.MANUAL_DELIVEREIES_FETCH.SUCCESS: {
      return state
        .setIn(["participantFunding", "fetchManualDeliveriesLoader"], false)
        .setIn(["participantFunding", "fetchManualDeliveriesError"], "")
        .setIn(["participantFunding", "manualDeliveries"], action.data);
    }
    case ACTIONS.MANUAL_DELIVEREIES_FETCH.ERROR: {
      return state
        .setIn(["participantFunding", "fetchManualDeliveriesLoader"], false)
        .setIn(
          ["participantFunding", "fetchManualDeliveriesError"],
          action.error
        );
    }
    case ACTIONS.COS_SETTING_CATEGORIES.PENDING: {
      return state
        .set(
          "cosSettingCategoriesLoader",
          action.status === FETCH_STATUS.LOADING
        )
        .set("cosSettingCategoriesError", "");
    }
    case ACTIONS.COS_SETTING_CATEGORIES.SUCCESS: {
      return state
        .set("cosSettingCategoriesLoader", false)
        .set("cosSettingCategoriesError", "")
        .set("cos_category_setting", action.data.client_task_category_list);
    }
    case ACTIONS.COS_SETTING_CATEGORIES.ERROR: {
      return state
        .set("cosSettingCategoriesLoader", false)
        .set("cosSettingCategoriesError", action.error)
        .set("cos_category_setting", []);
    }

    case ACTIONS.COS_SETTING_CONTACT.PENDING: {
      return state
        .set("cosSettingContactTypeLoader", true)
        .set("cosSettingContactTypeLError", "");
    }
    case ACTIONS.COS_SETTING_CONTACT.SUCCESS: {
      return state
        .set("cosSettingContactTypeLoader", false)
        .set("cosSettingContactTypeLError", "")
        .set("cos_contact_setting", action.data.contact_type_list);
    }
    case ACTIONS.COS_SETTING_CONTACT.ERROR: {
      return state
        .set("cosSettingContactTypeLoader", false)
        .set("cosSettingContactTypeLError", action.error)
        .set("cos_contact_setting", []);
    }

    case ACTIONS.COS_SETTING_PRIORITIES.PENDING: {
      return state
        .set("cosSettingPriorityLoader", action.status === FETCH_STATUS.LOADING)
        .set("cosSettingPriorityError", "");
    }
    case ACTIONS.COS_SETTING_PRIORITIES.SUCCESS: {
      return state
        .set("cosSettingPriorityLoader", false)
        .set("cosSettingPriorityError", "")
        .set("cos_priority_setting", action.data.cos_priority_list);
    }
    case ACTIONS.COS_SETTING_PRIORITIES.ERROR: {
      return state
        .set("cosSettingPriorityLoader", false)
        .set("cosSettingPriorityError", action.error)
        .set("cos_priority_setting", []);
    }
    default: {
      return state;
    }
  }
}
