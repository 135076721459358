import createSelector from "../../Utils/reselect";
import { DELIVERED_SERVICE_STATUS_OPTIONS } from "../../Constants/constant";
import { baseURLImage, getDifference, getNameProfile } from "../../Utils/utils";

const cosStateData = (state) => {
  const { cos } = state;
  return cos || {};
};

export const updateCaseNotesSuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateCaseNotesSuccess") || false
);

export const updateCaseNotesLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateCaseNotesLoading") || false
);

export const getCaseNoteDetailLoader = createSelector(
  cosStateData,
  (cos) => cos.get("getCaseNoteDetailLoading") || false
);

export const getCaseNoteDataSelector = createSelector(
  cosStateData,
  (cos) => cos.get("getCaseNoteData") || []
);

export const getCosSettingCategoriesLoader = createSelector(
  cosStateData,
  (cos) => cos.get("cosSettingCategoriesLoader") || false
);

export const getCosSettingContactTypeLoader = createSelector(
  cosStateData,
  (cos) => cos.get("cosSettingContactTypeLoader") || false
);

export const getCosSettingPriorityLoader = createSelector(
  cosStateData,
  (cos) => cos.get("cosSettingPriorityLoader") || false
);

export const DynamicBreadCrumbSelector = createSelector(
  cosStateData,
  (cos) => cos.get("DynamicBreadCrumb") || []
);

export const createNewFolderSuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewFolderSuccess") || false
);

export const createNewFolderLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewFolderLoading") || false
);

export const getRemoveFolder = createSelector(
  cosStateData,
  (cos) => cos.get("removeFolderSuccess") || ""
);

export const getRemoveFolderLoading = createSelector(
  cosStateData,
  (cos) => cos.get("createNewFolderLoading") || false
);

export const getRemoveFolderError = createSelector(
  cosStateData,
  (cos) => cos.get("removeFolderError") || ""
);

export const getFolderListForHRMLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("getFolderListForHRMLoading") || false
);

export const getFolderListForHRMSelector = createSelector(
  cosStateData,
  (cos) => cos.get("getFolderListForHRM") || []
);

export const getFileListForHRMSelector = createSelector(
  cosStateData,
  (cos) => cos.get("getFileListForHRM") || []
);

export const updateCategorySuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateCategorySuccess") || false
);

export const updatePrioritySuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updatePrioritySuccess") || false
);

export const updateContactSuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateContactSuccess") || false
);

export const reportLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("reportLoading") || false
);

export const getCaseNotesErrorSelector = createSelector(
  cosStateData,
  (cos) => cos.get("caseNoteerror") || false
);

export const newPeriorityListSelector = createSelector(
  cosStateData,
  (cos) => cos.getIn(["cosInvoice", "newPeriorityList"]) || false
);

export const createNewContactSelector = createSelector(
  cosStateData,
  (cos) => cos.getIn(["cosInvoice", "createNewContact"]) || false
);

export const createNewCategorySelector = createSelector(
  cosStateData,
  (cos) => cos.getIn(["cosInvoice", "createNewCategory"]) || false
);

export const updateCategoryLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateCategoryLoading") || false
);

export const updateCategoryErrorSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateCategoryLoading") || ""
);

export const updateContactLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateContactLoading") || false
);

export const updateContactErrorSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateContactError") || ""
);

export const updatePriorityLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updatePriorityLoading") || false
);

export const updatePriorityErrorSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updatePriorityError") || ""
);

export const getDashboardDataLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("getDashboardDataLoading") || false
);

export const getDashboardDataSelector = createSelector(
  cosStateData,
  (cos) => cos.get("getDashboardData") || []
);

export const createNewContactLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewContactLoading") || false
);

export const createNewContactErrorSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewContactError") || ""
);

export const createNewPriorityLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewPriorityLoading") || false
);

export const createNewPriorityErrorSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewPriorityError") || ""
);

export const createNewCategoryLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewCategoryLoading") || false
);

export const createNewCategoryErrorSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewCategoryError") || ""
);

export const fundingsForCosLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("fundingsForCosLoading") || false
);

export const fundingsForCosSelector = createSelector(cosStateData, (cos) => {
  const data = cos.get("fundingsForCos") || [];
  const newData = data.map((item) => {
    return { ...item, label: item.contract_id, value: item.uuid };
  });
  return newData;
});

export const clientTaskListLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createclientTaskListLoading") || false
);

export const updateClientTaskResponseSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateClientTaskResponse") || []
);

export const tableDataOfCaseNotesForReportingLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("tableDataOfCaseNotesForReportingLoading") || false
);

export const getTableDataOfCaseNotesForReportingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("tableDataForReportingForCaseNotes") || []
);

export const tableDataForReportingLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("tableDataForReportingLoading") || false
);

export const getTableDataForReportingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("tableDataForReporting") || []
);

export const getClientTaskResponseSelector = createSelector(
  cosStateData,
  (cos) => cos.get("clientTaskResponse") || []
);

export const getContactListOPTSelector = createSelector(cosStateData, (cos) => {
  const data = cos.get("ContactList") || [];
  const newData = data.map((item) => {
    return { ...item, label: item.title, value: item.uuid };
  });
  return newData;
});

export const getCatogeryListSelector = createSelector(cosStateData, (cos) => {
  const data = cos.get("CatogeryList") || [];
  const newData = data.map((item) => {
    return { ...item, label: item.title, value: item.id };
  });
  return newData;
});

export const getPeriorityListSelector = createSelector(cosStateData, (cos) => {
  const data = cos.get("periorityList") || [];
  const newData = data.map((item) => {
    return {
      ...item,
      label: item.title,
      value: item.id,
    };
  });
  return newData;
});

export const getContactListSelector = createSelector(
  cosStateData,
  (cos) => cos.get("ContactList") || []
);

export const caseNotesCreateLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("caseNotesCreateLoading") || false
);

export const cerateCaseNotesSuccessDataSelector = createSelector(
  cosStateData,
  (cos) => cos.get("cerateCaseNotesSuccessData") || []
);

export const changeCaseNotesInvoiceStatusSuccessSelector = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["cosInvoice", "changeCaseNotesInvoiceStatusSuccess"]) || false
);

export const changeNDISCaseNotesInvoiceStatusLoadingSelector = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["cosInvoice", "changeNDISCaseNotesInvoiceStatusLoading"]) ||
    false
);

export const changeNDISCaseNotesStatusLoadingSelector = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["cosInvoice", "changeNDISCaseNotesStatusLoading"]) || false
);

// export const caseNoteReviewLoadingSelector = createSelector(
//   cosStateData,
//   (cos) => cos.get("caseNoteReviewLoading") || false
// );

export const createdCosInvoiceReviewLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.getIn(["cosInvoice", "createdCosInvoiceReviewLoading"]) || false
);

export const createdCosReviewInvoiceDeliveryStatusSelector = createSelector(
  cosStateData,
  (cos) => {
    const data =
      cos.getIn(["cosInvoice", "createdCosReviewInvoiceDeliveryStatus"]) || [];
    const newList = [];
    data.length > 0 &&
      data.forEach((e) => {
        const val = { value: e.status_key };
        newList.push(val);
      });
    const res = getDifference(DELIVERED_SERVICE_STATUS_OPTIONS, newList);
    return res || [];
  }
);

export const createdCosReviewInvoiceClientsSelector = createSelector(
  cosStateData,
  (cos) => {
    const data =
      cos.getIn(["cosInvoice", "createdCosReviewInvoiceClients"]) || [];
    const updatedList =
      data &&
      data.map((item) => {
        return {
          ...item,
          value: item.uuid,
          label: item.first_name.concat(" ", item.last_name),
        };
      });
    return updatedList;
  }
);

export const getAddCaseNotesToListLoader = createSelector(
  cosStateData,
  (cos) => cos.get("addCaseNotesToListLoading") || false
);

export const createdCosInvoicesReviewLoader = createSelector(
  cosStateData,
  (cos) => cos.getIn(["cosInvoice", "createdCosInvoiceRiviewLoading"]) || false
);

export const getCreatedCosInvoiceLoaderSelector = createSelector(
  cosStateData,
  (cos) => {
    const data = cos.getIn(["cosInvoice", "createdCosInvoiceLoading"]) || null;
    return data;
  }
);

export const getCreatedCosInvoiceReviewSelector = createSelector(
  cosStateData,
  (cos) => {
    const data = cos.getIn(["cosInvoice", "createdCosReviewInvoice"]) || null;
    return data;
  }
);

export const createdCosInvoicesLoader = createSelector(
  cosStateData,
  (cos) => cos.getIn(["cosInvoice", "createdCosInvoiceLoading"]) || false
);

export const getCreatedCosInvoiceSelector = createSelector(
  cosStateData,
  (cos) => {
    const data = cos.get("createdCosInvoice") || null;
    return data;
  }
);

export const createCosInvoicesLoader = createSelector(
  cosStateData,
  (cos) => cos.getIn(["cosInvoice", "createCosInvoiceLoading"]) || false
);

export const getcosInvoiceSelector = createSelector(
  cosStateData,
  (cos) => cos.getIn(["cosInvoice", "cosInvoiceList"]) || null
);
// .setIn(["cosInvoice", "createCosInvoiceLoading"], false)

export const getCOSInvoiceLoaderSelector = createSelector(
  cosStateData,
  (cos) => cos.getIn(["cosInvoice", "cosInvoiceLoading"]) || false
);

export const getCaseNoteReviewLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("caseNoteReviewLoading") || false
);

// export const getCOSInvoiceLoaderSelector = createSelector(
//   cosStateData,
//   (cos) => cos.getIn(["COSInvoice", "fetchNdisClaimsCaseNotesLoader"]) || false
// );
export const getCaseNotesCreateLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("caseNotesCreateLoading") || false
);

export const changeStatusSuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("changeStatusSuccess") || null
);

export const getCaseNoteReviewSelector = createSelector(
  cosStateData,
  (cos) => cos.get("caseNoteReview") || null
);

export const getClaimedCaseNotesListLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("claimedCaseNotesListLoading") || null
);

export const getStatusFromNdisListSelector = createSelector(
  cosStateData,
  (cos) => {
    const data = cos.get("statusList") || [];

    const newList = [];
    data &&
      data.length > 0 &&
      data.forEach((e) => {
        const val = { value: e.status_key };
        newList.push(val);
      });
    const res = getDifference(DELIVERED_SERVICE_STATUS_OPTIONS, newList);
    return res || [];
  }
);

export const getSelectedClientsListSelector = createSelector(
  cosStateData,
  (cos) => {
    const data = cos.get("selectedClientList") || null;
    const updatedList =
      data &&
      data.map((item) => {
        return {
          ...item,
          value: item.id,
          label: item.first_name.concat(" ", item.last_name),
        };
      });
    return updatedList;
  }
);

export const getClaimedCaseNotesListSelector = createSelector(
  cosStateData,
  (cos) => {
    const data = cos.get("claimedCaseNotesList") || null;
    return data;
  }
);

export const getCaseNotesListForClaimLoaderSelector = createSelector(
  cosStateData,
  (cos) => cos.getIn(["ndisClaims", "fetchNdisClaimsCaseNotesLoader"]) || false
);

export const getCaseNotesListForClaimSelector = createSelector(
  cosStateData,
  (cos) => cos.getIn(["ndisClaims", "NdisClaimsCaseNotes"]) || null
);

export const getCaseNotesHistoryListLoaderSelector = createSelector(
  cosStateData,
  (cos) => cos.get("caseNotesHistoryLoading") || null
);

export const getCaseNotesHistoryListSelector = createSelector(
  cosStateData,
  (cos) => cos.get("caseNotesHistoryList") || []
);

export const getClienListSelector = createSelector(cosStateData, (cos) => {
  const data = cos.getIn(["ndisClaims", "clientsList"]) || null;
  if (data && data.length > 0) {
    const NewDat = data.map((item) => {
      return {
        ...item,
        label: item?.first_name.concat(" ", item?.last_name),
        value: item?.uuid,
      };
    });
    // NewDat.unshift({ label: "all", value: "all" });
    return NewDat;
  }
});

export const getClienListLoaderSelector = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["ndisClaims", "fetchNdisClaimCaseNoteClientsLoader"]) || false
);
export const getServiceSupportDropDownOptions = createSelector(
  cosStateData,
  (cos) => {
    const List = cos.get("serviceSupportItemsCaseNotes") || [];
    const myData = List.map((item) => {
      return {
        ...item,
        value: item?.uuid,
        label: item?.price_catalog?.support_item_name,
      };
    });
    return myData;
  }
);

export const getServiceSupportItemsCaseNotes = createSelector(
  cosStateData,
  (cos) => {
    const List = cos.get("serviceSupportItemsCaseNotes") || [];
    const myData = List.map((item) => {
      return {
        ...item,
        value: item?.id,
        label: item?.price_catalog?.support_item_number.concat(
          " ",
          item?.price_catalog?.support_item_name
        ),
      };
    });

    return myData;
  }
);

export const getSingleSupportTrackLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("singleSupportTrackLoading") || null
);

export const getSingleSupportTract = createSelector(
  cosStateData,
  (cos) => cos.get("singleSupportTract") || null
);

export const getFundingListData = createSelector(
  cosStateData,
  (cos) => cos.get("fundingList") || null
);
export const getFundingTableLoading = createSelector(
  cosStateData,
  (cos) => cos.get("fundingTableLoading") || false
);
export const getSupportTractList = createSelector(cosStateData, (cos) => {
  const List = cos.get("supportTractList") || [];
  const newData = List.map((item) => {
    return {
      ...item,
      value: item?.uuid,
      label: item?.delivered_service_number,
    };
  });
  return newData;
});

export const getReportDataSelector = createSelector(
  cosStateData,
  (cos) => cos.get("reportData") || null
);
export const getCliesntTaskList = createSelector(
  cosStateData,
  (cos) => cos.get("clientTaskList") || null
);

export const getCliesntTaskListLoading = createSelector(
  cosStateData,
  (cos) => cos.get("clientTaskListLoading") || null
);
export const getAllCatalogServices = createSelector(
  cosStateData,
  (cos) => cos.get("catalog_services") || null
);

export const getCosCaseNoteList = createSelector(
  cosStateData,
  (cos) => cos.get("casNotesList") || null
);
export const getCosCaseNoteListLoading = createSelector(
  cosStateData,
  (cos) => cos.get("casNotesListLoading") || null
);

export const getParticipantsProfile = createSelector(
  cosStateData,
  (cos) => cos.get("cosParticipantsProfile") || null
);

export const getCatalogVersions = createSelector(cosStateData, (cos) => {
  const catalog_versions = cos.get("catalog_versions") || [];
  if (catalog_versions.length > 0) {
    return catalog_versions.map((x) => {
      const obj = {
        label: x.title,
        value: x.id,
      };
      return obj;
    });
  }
  return catalog_versions;
});

export const getAllCatalogServicesSubCategory = createSelector(
  cosStateData,
  (cos) => cos.get("catalog_sub_category")
);

export const getServiceCatalogStates = createSelector(cosStateData, (cos) => {
  const states = cos.get("catalog_sub_category") || null;
  if (states) {
    return states.map((x) => {
      const obj = {
        value: x.id,
        label: x.name.toUpperCase(),
      };
      return obj;
    });
  }
  return states;
});
export const getcatalogServicesLoadingLoader = createSelector(
  cosStateData,
  (cos) => cos.get("catalogServicesLoading") || false
);

export const getCOSLoader = createSelector(
  cosStateData,
  (cos) => cos.get("loading") || false
);

export const getStoreFundingSetupLoading = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "storeFundingSetupLoading"]) || false
);

export const getStoreFundingSetupError = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "storeFundingSetupError"]) || ""
);

export const getParticipantFundingsList = createSelector(
  cosStateData,

  (cos) => {
    const List = cos.getIn(["participantFunding", "fundings"]) || null;
    let MyData = [];
    MyData = List?.data?.map((item) => {
      return { ...item, label: item?.contract_id, value: item?.uuid };
    });
    return MyData;
  }
);

export const getParticipantFundings = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "fundings"]) || null
);

export const getParticipantFundingsLoading = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "fundingsLoading"]) || false
);

export const getSelectedFundingLoading = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "selectedFundingLoading"]) || false
);

export const getSelectedFunding = createSelector(cosStateData, (cos) => {
  const funding = cos.getIn(["participantFunding", "selectedFunding"]) || null;
  const parseInJson = (data) => {
    if (data) {
      if (typeof data === "string") {
        Object.assign(funding, {
          funding_management: JSON.parse(data),
        });
        return typeof JSON.parse(data);
      } else {
        Object.assign(funding, {
          funding_management: data,
        });
        return typeof data;
      }
    }
    return null;
  };

  if (funding) {
    do {
      parseInJson(funding.funding_management);
    } while (parseInJson(funding.funding_management) === "string");
  }

  return funding;
});

export const getCreatePlanBudgetLoader = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "createPlanBudgetLoader"]) || false
);

export const getCreateServiceCategoryBudgetLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "createServiceCategoryBudgetLoader"]) ||
    false
);

export const getCreatePlanBudgetError = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "createPlanBudgetError"]) || ""
);

export const getPlanBudgets = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "planBudgetFunding"]) || null
);

export const getFetchPlanBudgetLoader = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "fetchPlanBudgetLoader"]) || false
);

export const getFetchServiceCategoryLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "serviceCategoryPlanBudgetFetchLoader"]) ||
    false
);

export const getRemovePlanBudgetLoader = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "removePlanBudgetLoader"]) || false
);

export const getRemoveServiceCategoryLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "removeServiceCategoryLoader"]) || false
);

export const getPlanCatagories = createSelector(cosStateData, (cos) => {
  const planCatagories = cos.get("planCatagories");
  if (planCatagories) {
    const subCatagories = {};
    const catagory = planCatagories
      .map((x) => {
        const obj = {
          value: x.id,
          label: x.name,
        };
        if (x.name === "core") {
          Object.assign(obj, { sequence: 1 });
        } else if (x.name === "capacity building") {
          Object.assign(obj, { sequence: 2 });
        } else {
          Object.assign(obj, { sequence: 3 });
        }
        Object.assign(subCatagories, {
          [x.name.replace(" ", "_")]: x.service_sub_categories.map((x) => {
            const obj = { value: x.id, label: x.title };
            return obj;
          }),
        });
        return obj;
      })
      .sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
    return {
      catagory,
      subCatagories,
    };
  }
  return null;
});

export const getServiceCatalogBudgetPlanCatAndSubCatOptions = createSelector(
  cosStateData,
  (cos) => {
    const planCatAndSubCat =
      cos.getIn(["participantFunding", "planBudgetCatAndSubCat"]) || {};
    if (
      Object.keys(planCatAndSubCat).length > 0 &&
      planCatAndSubCat.hasOwnProperty("service_cat")
    ) {
      const planBudgetCategories = [];
      const planBudgetSubCategories = {};

      planCatAndSubCat.service_cat.forEach((x) => {
        planBudgetCategories.push({
          created_at: x.created_at,
          id: x.id,
          name: x.name,
          updated_at: x.updated_at,
          uuid: x.uuid,
          label: x.name,
          value: x.id,
        });
        Object.assign(planBudgetSubCategories, {
          [x.name.replace(" ", "_")]: x.service_sub_categories.map((y) => {
            return { ...y, value: y.id, label: y.title };
          }),
        });
      });
      const sequencePlanCat = planBudgetCategories
        .map((x) => {
          const obj = x;
          if (x.name === "core") {
            Object.assign(obj, { sequence: 1 });
          } else if (x.name === "capacity building") {
            Object.assign(obj, { sequence: 2 });
          } else {
            Object.assign(obj, { sequence: 3 });
          }
          return obj;
        })
        .sort((a, b) => (a.sequence < b.sequence ? -1 : 1));
      return { planBudgetCategories: sequencePlanCat, planBudgetSubCategories };
    }
    return null;
  }
);

export const getExternalServiceProviders = createSelector(
  cosStateData,
  (cos) => {
    const planCatAndSubCat =
      cos.getIn(["participantFunding", "planBudgetCatAndSubCat"]) || {};
    if (
      Object.keys(planCatAndSubCat).length > 0 &&
      planCatAndSubCat.hasOwnProperty("external_sps")
    ) {
      const data = planCatAndSubCat.external_sps.map((x) => {
        return { ...x, value: x.id, label: x.name };
      });
      data.unshift({
        value: "add_service_provider",
        label: "Add New Service Provider",
      });
      return [...data];
    }
    return [];
  }
);

export const getStoreServiceCategoryPlanBudgetLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "serviceCategoryPlanBudgetLoader"]) ||
    false
);

export const getStoreServiceCategoryPlanBudgetError = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "serviceCategoryPlanBudgetError"]) || ""
);

export const getServiceCategoryPlanBudget = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "serviceCategoryPlanBudget"]) || null
);

export const getExternalSPLists = createSelector(
  cosStateData,
  (cos) => cos.get("externalSpList") || null
);

export const getExternalSPCreateLoader = createSelector(
  cosStateData,
  (cos) => cos.get("externalSpCreateLoader") || false
);

export const getExternalSPCreateError = createSelector(
  cosStateData,
  (cos) => cos.get("externalSpCreateError") || ""
);

export const getExternalSP = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "external_sp"]) || null
);

export const getFundingNotes = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "fundingNotes"]) || null
);

export const getFetchFundingNotesLoader = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "fetchFundingNotesLoader"]) || false
);

export const getCreateFundingNotesLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "createFundingNotesLoader"]) || false
);

export const getCreateFundingNotesError = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "createFundingListError"]) || ""
);

export const getRemoveFundingNotesLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "removeFundingNotesLoader"]) || false
);

export const getCreateServiceSupportLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "createServiceSupportLoader"]) || false
);

export const getCreateServiceSupportError = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "createServiceSupportError"]) || ""
);

export const getServiceSupportBudget = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "serviceSupportBudget"]) || null
);

export const getServiceSupportBudgetFetchLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "fetchServiceSupportLoader"]) || false
);

// export const getCaseNoteServiceSupportItems = createSelector(
//   cosStateData,
//   (cos) => {
//     const items =
//       cos.getIn(["participantFunding", "serviceSupportItems"]) || null;
//     if (items) {
//       return items.map((x) => {
//         return {
//           label: x.price_catalog.support_item_number,
//           value: x.id,
//         };
//       });
//     }
//     return items;
//   }
// );

export const getServiceSupportItems = createSelector(cosStateData, (cos) => {
  const items =
    cos.getIn(["participantFunding", "serviceSupportItems"]) || null;
  if (items) {
    return items.map((x) => {
      return {
        ...x,
        label: `${x.price_catalog.support_item_number} ${x.price_catalog.support_item_name}`,
        value: x.id,
      };
    });
  }
  return items;
});
export const getCosParticipantLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "fetchCosParticipantLoader"]) || false
);

export const getCosParticipant = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "cosParticipant"]) || null
);

export const getSupportCoordinator = createSelector(cosStateData, (cos) => {
  const data = cos.getIn(["participantFunding", "supportCoordinator"]) || [];
  const newData =
    data &&
    data.map((item) => {
      return {
        ...item,
        label: `${item?.first_name} ${item?.last_name}`,
        value: item?.uuid,
        icon: item.photo
          ? baseURLImage(item.photo)
          : getNameProfile(item.first_name, item.last_name || " "),
      };
    });
  return newData;
});

export const getSupportCoordinatorLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "supportCoordinatorLoader"]) || false
);

export const getDeliveredServiceStoreLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "deliveredServiceStoreLoader"]) || false
);

export const getDeliveredServiceStoreError = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "deliveredServiceStoreError"]) || ""
);

export const grtServiceSupportItemsForDeliveredLoading = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn([
      "participantFunding",
      "fetchServiceSupportItemsForDeliveredLoader",
    ]) || false
);

export const getManualDeliveredServiceStoreLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "manualDeliveredServiceStoreLoader"]) ||
    false
);

export const getManualDeliveredServiceStoreError = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "manualDeliveredServiceStoreError"]) || ""
);

export const getDeliveredServiceFetchLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "fetchDeliveredServiceLoader"]) || false
);

export const getManualDeliveriesFetchLoader = createSelector(
  cosStateData,
  (cos) =>
    cos.getIn(["participantFunding", "fetchManualDeliveriesLoader"]) || false
);

export const getDeliveredServices = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "deliveredServices"]) || null
);

export const getManualDeliveries = createSelector(
  cosStateData,
  (cos) => cos.getIn(["participantFunding", "manualDeliveries"]) || null
);

export const getCosCategorySetting = createSelector(
  cosStateData,
  (cos) => cos.get("cos_category_setting") || []
);

export const getCosContactSetting = createSelector(
  cosStateData,
  (cos) => cos.get("cos_contact_setting") || []
);

export const getCosPrioritySetting = createSelector(
  cosStateData,
  (cos) => cos.get("cos_priority_setting") || []
);
