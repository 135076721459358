import { connect } from "react-redux";
import {
  createFundingNotesRequest,
  getFundingNotesListRequest,
  fundingNotesRemoved,
} from "../../../../store/actions/cos.actions";
import {
  getFundingNotes,
  getFetchFundingNotesLoader,
  getSelectedFunding,
  getCreateFundingNotesLoader,
  getCreateFundingNotesError,
  getRemoveFundingNotesLoader,
} from "../../../../store/selector/cos.selector";
import { getSelectedParticipant } from "../../../../store/selector/participant.selector";
import FundingNotes from "./FundingNotes";

const mapDispatchToProps = {
  getFundingNotesList: getFundingNotesListRequest,
  createFundingNotes: createFundingNotesRequest,
  fundingNotesRemoved
};

const mapStateToProps = (state) => {
  const fundingNotes = getFundingNotes(state);
  const fundingNotesLoader = getFetchFundingNotesLoader(state);
  const selectedFunding = getSelectedFunding(state);
  const participant = getSelectedParticipant(state);
  const storeFundingNotesLoading = getCreateFundingNotesLoader(state);
  const storeFundingNotesError = getCreateFundingNotesError(state);
  const removeFundingNoteLoader = getRemoveFundingNotesLoader(state);

  return {
    fundingNotes,
    fundingNotesLoader,
    selectedFunding,
    participant,
    storeFundingNotesLoading,
    storeFundingNotesError,
    removeFundingNoteLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundingNotes);
