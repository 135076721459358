import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  timeSheetCommentCreateRequest,
  timeSheetCommentsFetchRequest,
} from "store/actions/rostering.actions";
import { getSpSetting } from "store/selector/sp.selector";
import {
  getSelectedTimeSheetComments,
  getTimeSheetCommentCreateLaoder,
  getTimesheetCommentsListLoader,
} from "store/selector/rostering.selector";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import LoaderButton from "components/common/LoaderButton/LoaderButton";
import CustomButton from "components/common/Button/CustomButton";
import {
  LOADER_STATUS,
  USER_ROLE,
  emptyPaginationObj,
} from "Constants/constant";
import { baseURLImage, getNameProfile, getPaginationObj } from "Utils/utils";
import { roleAccess } from "helpers/RolesPermission";
import { LoginContext } from "helpers/LoginContext";
// import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

const TimesheetComment = (props) => {
  const {
    timesheetCommentsListLoader,
    timeSheetCommentRequest,
    timeSheetUuid,
    timesheetComments,
    timeSheetCommentCreateLaoder,
    timeSheetCommentCreateCall,
    currentSpUser,
    isViewOnly = false,
    viewOnlyComments,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const myRef = useRef(null);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [reachedLast, setReachedLast] = useState(false);
  const [intialLaoder, setInitialLaoder] = useState(LOADER_STATUS.NA);
  const [scrollPaginationLoader, setScrollPaginationLoader] = useState(false);

  useEffect(() => {
    if (timesheetComments) {
      setPaginationObj({ ...getPaginationObj(timesheetComments) });
      setReachedLast(false);
    }
  }, [timesheetComments]);

  const timesheetCommentsToMap = useMemo(() => {
    if (timesheetComments && timesheetComments?.data) {
      // return [...timesheetComments?.data].reverse();
      return timesheetComments?.data;
    }
    return [];
  }, [timesheetComments]);

  useEffect(() => {
    if (!timeSheetCommentCreateLaoder) {
      if (intialLaoder !== LOADER_STATUS.NA) {
        setInitialLaoder(LOADER_STATUS.COMPLATE);
      }

      reset();
      scrollToBottom();
    }
  }, [timeSheetCommentCreateLaoder]);

  useEffect(() => {
    if (!timesheetCommentsListLoader) {
      setScrollPaginationLoader(false);
      setReachedLast(false);
    }
  }, [timesheetCommentsListLoader]);

  const scrollToBottom = () => {
    if (myRef.current) {
      myRef.current.scrollTop = myRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (reachedLast && paginationObj.totalPages > paginationObj.page) {
      const obj = paginationObj;
      obj.page = paginationObj.page + 1;
      setPaginationObj({ ...obj });
      setScrollPaginationLoader(true);
      timeSheetCommentRequest({
        timeSheetUuid,
        page: obj.page,
        per_page: 10,
        isFirstCall: false,
      });
    }
  }, [reachedLast]);

  useEffect(() => {
    scrollToRef(myRef);
  }, [myRef]);

  const scrollToRef = (ref) => {
    if (ref?.current) {
      ref.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (timeSheetUuid) {
      setInitialLaoder(LOADER_STATUS.PENDING);
      timeSheetCommentRequest({
        timeSheetUuid,
        page: 1,
        per_page: 10,
        isFirstCall: true,
      });
    }
  }, [timeSheetUuid]);

  const handleScroll = () => {
    if (myRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = myRef.current;
      if (
        scrollTop + clientHeight + 5 >= scrollHeight &&
        paginationObj.totalPages > paginationObj.page
      ) {
        setReachedLast(true);
      }
    }
  };

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (timeSheetUuid && data?.text) {
      const obj = {
        text: data.text,
        time_sheet_id: timeSheetUuid,
      };
      if (
        roleAccess([USER_ROLE.Sp_Admin]) &&
        currentSpUser &&
        currentSpUser?.id
      ) {
        Object.assign(obj, {
          commented_by: currentSpUser.id,
        });
      }
      timeSheetCommentCreateCall(obj);
    }
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  if (isViewOnly) {
    return (
      <div
        style={{ height: `531px` }}
        className={`flex flex-col y-overflow rounded-md`}
      >
        <div className='min-w-full'>
          <div className='block'>
            <table className='min-w-full bg-white'>
              <thead>
                <tr>
                  <th className='w-[25%] py-3.5 px-3 text-left text-sm font-semibold text-[#000] bg-[#EEEFF1] '>
                    Commented By
                  </th>
                  <th className='w-[15%] py-3.5 px-3 text-left text-sm font-semibold text-[#000] bg-[#EEEFF1]  '>
                    Posted Time
                  </th>
                  <th className='w-[60%] py-3.5 px-3 text-left text-sm font-semibold text-[#000] bg-[#EEEFF1] '>
                    Text
                  </th>
                </tr>
              </thead>
              <tbody className='border'>
                {viewOnlyComments.length > 0 ? (
                  viewOnlyComments.map((item, index) => (
                    <tr
                      key={`${item.id}-${index}`}
                      className='even:bg-white odd:bg-gray-50'
                    >
                      <td className='w-[30%] whitespace-nowrap py-4 pl-4 text-sm'>
                        <div className='flex items-center'>
                          <div className='h-10 w-10'>
                            <img
                              onError={(e) =>
                                handleImageError(
                                  e,
                                  item?.commented_by?.name,
                                  " "
                                )
                              }
                              className='w-11 h-11 bg-gray-300 rounded-full flex-shrink-0 object-cover'
                              src={
                                item?.commented_by?.photo
                                  ? baseURLImage(item?.commented_by?.photo)
                                  : getNameProfile(
                                      item?.commented_by?.name,
                                      " "
                                    )
                              }
                              alt='profile_photo'
                            />
                          </div>
                          <div className='ml-4 capitalize'>
                            <span className='capitalize font-medium text-gray-500 max-w-32'>
                              {item?.commented_by?.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='w-[15%] whitespace-nowrap py-4 pl-4 text-sm text-gray-500 '>
                        {item?.posted_time}
                      </td>
                      <td className='w-[45%] whitespace-nowrap py-4 pl-4 text-sm text-gray-500 '>
                        {item?.text}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className='py-6 text-center text-red-600'>
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col px-4'>
      <div
        className='h-[787px] overflow-y-auto'
        onScroll={handleScroll}
        ref={myRef}
      >
        {!scrollPaginationLoader &&
        intialLaoder !== LOADER_STATUS.NA &&
        timesheetCommentsListLoader ? (
          <div
            className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
          >
            <CircularLoader
              classes='flex justify-center items-center'
              classloader='loader-l'
            />
          </div>
        ) : timesheetCommentsToMap.length > 0 ? (
          timesheetCommentsToMap.map((comment, index) => (
            <div
              key={`${comment.id}-${index}`}
              className={`min-w-full sm:px-7 lg:px-6 py-6 rounded-lg mb-2 bg-[#F9FAFB]`}
            >
              <div className='flex justify-between w-full'>
                <div className='w-[10%]'>
                  <img
                    onError={(e) =>
                      handleImageError(e, comment?.commented_by?.name, " ")
                    }
                    className='w-11 h-11 bg-gray-300 rounded-full flex-shrink-0 object-cover'
                    src={
                      comment?.commented_by?.photo
                        ? baseURLImage(comment?.commented_by?.photo)
                        : getNameProfile(comment?.commented_by?.name, " ")
                    }
                    alt='profile_photo'
                  />
                </div>
                <div className='w-full pl-3'>
                  <div className='user flex items-center'>
                    <h3 className='pe-2 text-lg font-medium'>
                      {comment?.commented_by?.name}
                    </h3>
                    <p className='text-[#A7A9AE] text-xs leading-[25px] px-2 pl-4'>
                      {comment?.posted_time}
                    </p>
                  </div>
                  <p className='text-gray-600 text-sm leading-[20px]'>
                    {comment.text}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='flex justify-center text-red-600'>No data found!</div>
        )}
        {scrollPaginationLoader &&
          reachedLast &&
          paginationObj.totalPages >= paginationObj.page && (
            <div className='flex justify-center items-center'>
              <LoaderButton
                classes='flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-dark'
                svgClasses='inline mr-3 w-5 h-5 text-white animate-spin'
                textbutton='Loading More...'
              />
            </div>
          )}
      </div>
      <div className='row mt-4'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='w-full'>
            <textarea
              className='w-full h-25 px-4 py-2 rounded-lg border-gray-300'
              placeholder='Enter your Comments...'
              {...register("text", { required: true })}
            />
            {errors?.text && (
              <span className='text-red-600 text-sm'>
                This field is required!
              </span>
            )}
          </div>
          <div className='w-full'>
            <div className='text-end pt-2'>
              <CustomButton
                showLoading={timeSheetCommentCreateLaoder}
                isDisabled={
                  timeSheetCommentCreateLaoder || isUserGracePeriodOver
                }
                type='submit'
                label='Submit Comment'
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  timeSheetCommentRequest: timeSheetCommentsFetchRequest,
  timeSheetCommentCreateCall: timeSheetCommentCreateRequest,
};

const mapStateToProps = (state) => {
  const currentSpUser = getSpSetting(state);
  const timesheetComments = getSelectedTimeSheetComments(state);
  const timeSheetCommentCreateLaoder = getTimeSheetCommentCreateLaoder(state);
  const timesheetCommentsListLoader = getTimesheetCommentsListLoader(state);

  return {
    currentSpUser,
    timesheetCommentsListLoader,
    timeSheetCommentCreateLaoder,
    timesheetComments,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetComment);
