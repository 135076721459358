import React, { useState, useEffect } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import { useNavigate, useParams } from "react-router";
import "./ServiceProviderSetup.css";
import { toast } from "react-toastify";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { setExpireTime, setToken } from "../../../helpers/Tokens";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import LoginScreenSection from "../../../components/common/LoginScreenSection/LoginScreenSection";
import {
  EMAIL_REGEX_EXP,
  REGEX_PHONE_NUMBER,
  STATE_FULL_LABEL_OPTIONS,
  USER_ROLE,
} from "Constants/constant";
import { getSpSettingFetchRequest } from "store/actions/sp.actions";
import { useDispatch } from "react-redux";
import PasswordValidationBox from "components/common/PasswordValidationBox/PasswordValidationBox";
import { parseBooleanVal } from "Utils/utils";
import { loginAttempt } from "store/actions/auth.actions";
import Loader from "components/common/Loader/Loader";

const ServiceProviderSetup = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPassword, setconfirmPassword] = useState("password");
  const showPasswordHandler = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const showConfirmPasswordHandler = () => {
    confirmPassword === "password"
      ? setconfirmPassword("text")
      : setconfirmPassword("password");
  };

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: userInfo,
  });

  const watchFormData = watch();

  const fetchUserInfo = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiGetRequest(`get-link-info?id=${id}`);
      if (res.status === 226) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");
        throw new Error("Registration Already Completed!");
      }

      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        throw new Error("Invalid Credentials");
      }
      if (res.status === 200) {
        setUserInfo(res.data.data);
        reset(res.data.data);
        setRequest({ ...request, isRequested: false, isSuccess: true });
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      if (err.message ?? err === "The link is expire!") {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/");
      }
      toast.error(err.message ?? err);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const dataSubmitHandler = async (data) => {
    setRequest({ ...request, isRequested: true });

    try {
      let res = await apiPostRequest("account-setup", data);
      if (res.status !== 200) {
        console.log("Something Went Wrong");
        setRequest({ ...request, isRequested: false, isFailed: true });
      }
      if (res.status === 200) {
        if (res.data) {
          Object.assign(res.data, {
            user: res.data.data,
          });
        }

        if (
          res.data &&
          res.data.user &&
          res.data.user.hasOwnProperty("email_verified")
        ) {
          const encode = window.btoa(encodeURIComponent(data.email));
          navigate(`/email-verification-code/${encode}`);
          return;
        }
        localStorage.setItem("user", JSON.stringify(res.data.user));
        if (
          res.data &&
          "service_provider" in res.data.user &&
          res.data.user.service_provider &&
          res.data.user.userType === USER_ROLE.Sp_Admin
        ) {
          localStorage.setItem(
            "spSettings",
            JSON.stringify(res.data.user.service_provider)
          );
          dispatch(
            loginAttempt.success({
              ...res.data.user,
              name: res.data.user.service_provider.name,
            })
          );
        } else {
          dispatch(loginAttempt.success(res.data.user));
        }
        localStorage.setItem(
          "isOnBoarding",
          res.data.user.userType === USER_ROLE.Sp_Standard_User &&
            !res.data.user.on_boarding
            ? false
            : true
        );
        localStorage.setItem("userType", res.data.user.userType);
        if (
          res.data &&
          res.data.user &&
          res.data.user.hasOwnProperty("authentication_activity")
        ) {
          localStorage.setItem(
            "2FARequired",
            res.data.user.authentication_activity.otp_verified
          );
          localStorage.setItem("2FASetup", res.data.user.phone_2fa);
          localStorage.setItem(
            "authentication_uuid",
            res.data.user.authentication_activity.authentication_uuid
          );
        }

        const logInTime = moment().format("HH:mm");
        const warnTime = moment().add(120000, "milliseconds").format("HH:mm");
        const logoutTime = moment()
          .add(1200000, "milliseconds")
          .format("HH:mm");
        setExpireTime({ logInTime, warnTime, logoutTime });
        setToken(res.data.user.AccessToken);
        setRequest({ ...request, isRequested: false, isSuccess: true });

        if (parseBooleanVal(localStorage.getItem("2FARequired")) === false) {
          localStorage.setItem("step", "0.5");
          navigate("/select-otp");
        } else if (
          parseBooleanVal(localStorage.getItem("isOnBoarding")) === false
        ) {
          localStorage.setItem("step", false);
          navigate("/");
        } else {
          // alert("dash");
          localStorage.setItem("step", false);
          if (res.data && res.data.user.userType === USER_ROLE.Sp_Admin) {
            dispatch(getSpSettingFetchRequest());
          }
          navigate("/dashboard");
        }
      }
    } catch (err) {
      console.log("err", err);
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message ?? "Something went wrong!");
    }
  };

  const onSubmit = (data) => {
    if (pass && confrimPassword && pass !== confrimPassword) {
      return;
    }
    dataSubmitHandler(data);
  };

  // Password Validations
  const [pass, setPass] = useState("");
  const [confrimPassword, setConfirmPassword] = useState("");

  // When the user clicks on the password field, show the message box
  const passwordFocus = () => {
    document.getElementById("message").style.display = "block";
  };

  const passwordBluer = () => {
    document.getElementById("message").style.display = "none";
  };

  const CpasswordHanlder = (value) => {
    setConfirmPassword(value);
  };
  // When the user starts to type something inside the password field
  const passwordChangeHandler = (value) => {
    // Validate lowercase letters
    setPass(value);
    // props.setFieldValue('password', value);
  };

  // End Password Validation

  return (
    <div className='flex min-h-screen'>
      {request.isRequested && (
        <div className='absolute top-1/2 -translate-y-1/2 z-50 bg-blue-dark bg-opacity-20  flex  w-full flex-1  flex-col justify-center py-12 px-4 sm:px-6 lg:flex lg:justify-center lg:px-20 lg:w-1/2 xl:px-24 order-1 h-full'>
          <CircularLoader />
        </div>
      )}
      <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex lg:justify-center lg:px-20 lg:w-1/2 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div>
            <h2 className='mt-6 text-3xl font-semibold text-gray-900'>
              Welcome to Vertex 360
            </h2>
            <p className='text-sm text-gray-400 pl-1'>
              please provide the details to set up your account
            </p>
          </div>

          <div className='mt-8'>
            <form onSubmit={handleSubmit(onSubmit)} className=''>
              <div className='mt-1 did-floating-label-content'>
                <label className='did-floating-label'>
                  Service Provider Name
                </label>
                <input
                  {...register("name", {
                    required: true,
                    maxLength: 254,
                  })}
                  className='did-floating-input'
                  type='text'
                />

                {errors?.name?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
              <div className='mt-1 did-floating-label-content'>
                <label className='did-floating-label'>
                  Primary Contact Name
                </label>
                <input
                  {...register("primary_contact_name", {
                    required: true,
                    maxLength: 254,
                  })}
                  className='did-floating-input'
                  type='text'
                />

                {errors?.primary_contact_name?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
              <div className='mt-1 did-floating-label-content'>
                <label className='did-floating-label'>Contact No.</label>
                <input
                  type='number'
                  // minLength={10}
                  maxLength={10}
                  name='phone'
                  id='phone'
                  className='did-floating-input'
                  {...register("phone", {
                    // required: true,
                    // minLength: 10,
                    maxLength: 10,
                    pattern: REGEX_PHONE_NUMBER,
                    onChange: (e) => {
                      const sanitizedValue = e.target.value.replace(/\D/g, "");
                      const formattedValue = sanitizedValue.slice(0, 10);
                      e.target.value = formattedValue;
                    },
                  })}
                />
                {errors?.phone?.type === "required" ? (
                  errors.phone && (
                    <span className='text-red-500'>This field is required</span>
                  )
                ) : (
                  <>
                    {errors.phone?.type === "pattern" && (
                      <span className='text-red-500'>
                        {/* Phone number must start with 04, 1300 or 1800 */}
                        Please enter a valid phone number!
                      </span>
                    )}
                    {errors.phone?.type === "maxLength" && (
                      <span className='text-red-500'>
                        Please enter a valid phone number with the max length of
                        10
                      </span>
                    )}
                    {errors.phone?.type === "minLength" && (
                      <span className='text-red-500'>
                        Please enter a valid phone number with the min length of
                        10
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className='mt-1 did-floating-label-content'>
                <label className='did-floating-label'>Street Address</label>
                <input
                  {...register("street_address", {
                    required: true,
                    maxLength: 254,
                  })}
                  className='did-floating-input'
                  type='text'
                />
                {errors?.street_address?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
              <div className='flex space-x-2'>
                <div className='mt-1 did-floating-label-content w-1/2'>
                  <label className='did-floating-label'>State</label>
                  <select
                    className='did-floating-select truncate'
                    {...register("state", {
                      required: true,
                    })}
                  >
                    {STATE_FULL_LABEL_OPTIONS.map((option, state_index) => (
                      <option
                        key={`${state_index}-${option.value}`}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors?.state?.type === "required" && (
                    <span className='text-sm text-red-500'>
                      This field is required!
                    </span>
                  )}
                </div>
                <div className='mt-1 did-floating-label-content w-1/2'>
                  <label className='did-floating-label'>Suburb</label>
                  <input
                    {...register("suburb", {
                      required: true,
                      maxLength: 254,
                    })}
                    className='did-floating-input'
                    type='text'
                  />
                  {errors?.suburb?.type === "required" && (
                    <span className='text-sm text-red-500'>required!</span>
                  )}
                </div>
                <div className='mt-1 did-floating-label-content w-1/2'>
                  <label className='did-floating-label'>Postcode</label>
                  <input
                    {...register("postcode", {
                      required: true,
                      onChange: (e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4);
                      },
                    })}
                    className='did-floating-input'
                    type='number'
                  />
                  {errors?.postcode?.type === "required" && (
                    <span className='text-sm text-red-500'>required!</span>
                  )}
                </div>
              </div>
              <div className='mt-1 did-floating-label-content'>
                <label className='did-floating-label'>ABN</label>
                <input
                  className='did-floating-input'
                  {...register("abn", {
                    required: true,
                    maxLength: 11,
                    minLength: 11,
                    pattern: "[0-9]*",
                    onChange: (e) =>
                      (e.target.value = e.target.value.toString().slice(0, 11)),
                  })}
                />
                {errors?.abn?.type === "required" ? (
                  <span className='text-red-500'>This field is required</span>
                ) : errors.abn?.type === "pattern" ? (
                  <span className='text-red-500'>Please enter a valid ABN</span>
                ) : errors.abn?.type === "maxLength" ||
                  errors.abn?.type === "minLength" ? (
                  <span className='text-red-500'>
                    Please enter a valid ABN with 11 digits
                  </span>
                ) : null}
              </div>
              <div className='mt-1 did-floating-label-content'>
                <label className='did-floating-label'>ACN</label>
                <input
                  {...register("asn", {
                    // required: true,
                    pattern: "[0-9]*",
                  })}
                  className='did-floating-input'
                  type='number'
                />
                {errors?.asn?.type === "required" ? (
                  <span className='text-red-500'>This field is required</span>
                ) : errors.asn?.type === "pattern" ? (
                  <span className='text-red-500'>
                    Please enter a valid number
                  </span>
                ) : null}
              </div>
              <div className='mt-1 did-floating-label-content'>
                <label className='did-floating-label'>Email address</label>
                <input
                  {...register("email", {
                    required: true,
                    pattern: EMAIL_REGEX_EXP,
                  })}
                  className='did-floating-input'
                  type='email'
                />
                {errors?.email?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
                {errors?.email?.type === "pattern" && (
                  <span className='text-sm text-red-500'>
                    Please enter a valid email address!
                  </span>
                )}
              </div>
              <div className='space-y-4'>
                <div>
                  <div className='did-floating-label-content relative'>
                    <label
                      htmlFor='email-address'
                      className='did-floating-label'
                    >
                      Password
                    </label>
                    <input
                      {...register("password", {
                        required: true,
                        onChange: (e) => {
                          passwordChangeHandler(e.target.value);
                        },
                      })}
                      onFocus={passwordFocus}
                      onBlur={passwordBluer}
                      id='password'
                      type={passwordType}
                      pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}'
                      title='Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'
                      autoComplete='password'
                      className='did-floating-input'
                    />

                    <span
                      onClick={showPasswordHandler}
                      className='pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark pt-6'
                    >
                      {passwordType === "password" ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </span>
                  </div>
                  {errors?.password?.type === "required" && (
                    <span className='text-sm text-red-500'>
                      This field is required!
                    </span>
                  )}
                  <div id='message' style={{ display: "none" }}>
                    <PasswordValidationBox password={watchFormData?.password} />
                  </div>
                </div>
                <div className=''>
                  <div className='did-floating-label-content relative'>
                    <label
                      htmlFor='email-address'
                      className='did-floating-label'
                    >
                      Confirm Password
                    </label>
                    <input
                      {...register("password_confirmation", {
                        required: true,
                        onChange: (e) => {
                          CpasswordHanlder(e.target.value);
                        },
                      })}
                      id='confirm_password'
                      type={confirmPassword}
                      autoComplete='password'
                      className='did-floating-input'
                    />

                    <span
                      onClick={showConfirmPasswordHandler}
                      className='pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark pt-6'
                    >
                      {confirmPassword === "password" ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </span>
                  </div>
                  {errors?.password_confirmation?.type === "required" && (
                    <div className='-mt-4 mb-4 text-sm text-red-500'>
                      This field is required!
                    </div>
                  )}
                  {pass && confrimPassword && pass === confrimPassword ? (
                    <div className='-mt-4 mb-4 text-green-500'>
                      password matched!
                    </div>
                  ) : pass && confrimPassword && pass !== confrimPassword ? (
                    <div className='-mt-4 mb-4 text-red-500'>
                      password not matched!
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <button
                  disabled={request.isRequested}
                  type='submit'
                  className='
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      bg-blue-dark
                      hover:bg-blue-dark
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-dark
                    '
                >
                  {request.isRequested && (
                    <Loader cssClass='mt-[2px] text-black' isSmallView={true} />
                  )}
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <LoginScreenSection
        heading='Thank you for setting up your account!'
        name='Dale Florence'
        desc='NDIS Service Provider'
      />
    </div>
  );
};

export default ServiceProviderSetup;
