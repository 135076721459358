import React from "react";
import { Routes, Route } from "react-router-dom";
import OnBoardingForm from "pages/EmployeePortal/Pages/OnBoardingForm/OnBoardingFrom";
import GetItemFromLocalstorage from "Utils/GetItemFromLocalstorage";
import { getToken } from "helpers/Tokens";
import { USER_ROLE } from "Constants/constant";
import { DynamicRoutes } from "./Routes";
import ErrorPage2FA from "components/common/404Page2FA/404Page2FA";
import { getUserTypeFromLC, parseBooleanVal } from "Utils/utils";
import ErrorPage from "components/common/404Page/404Page";
import FANotEnable from "../pages/Authentication/TwoFactorAuth/TwoFANotEnabled/index";
import SignIn from "pages/Authentication/SignIn/SignIn";
import FAEnabled from "../pages/Authentication/TwoFactorAuth/TwoFAEnabled/index";
import FANotEnabledOTP from "../pages/Authentication/TwoFactorAuth/TwoFANotEnabledOTP/index";
import OTPSelectionScreen from "../pages/Authentication/TwoFactorAuth/OTPSelectionScreen/index";
import FANotEnabledOTPVarified from "../pages/Authentication/TwoFactorAuth/TwoFANotEnabledOTPVarified/index";
import {
  STRIPE_CHECK_SUBSCRIPTION_STATUS,
  checkSubscriptionStatus,
  roleAccess,
} from "helpers/RolesPermission";
import PermissionDenied from "components/common/404Permission/404Permission";
import SubscriptionDetailsDashboard from "pages/Stripe/Subscription/Dashboard";
import PricingTable from "pages/Stripe/Pricing/PricingTable";

export const UseRoute = ({ setIsDisplayBanner }) => {
  const userType = getUserTypeFromLC();
  const currentUser = GetItemFromLocalstorage("user");
  const isOnBoarding = parseBooleanVal(localStorage.getItem("isOnBoarding"));
  const step = localStorage.getItem("step") ?? "false";

  const isTwoFactorAuthenticate = () => {
    if (
      localStorage.getItem("2FASetup") &&
      localStorage.getItem("2FARequired") &&
      // localStorage.getItem("2FASetup") !== "null" &&
      localStorage.getItem("2FARequired") === "true" &&
      localStorage.getItem("step") === "false"
    ) {
      return true;
    } else if (
      !currentUser.hasOwnProperty("authentication_activity") &&
      !localStorage.getItem("authentication_uuid")
    ) {
      return true;
    }
    return false;
  };

  if (step && currentUser && userType && step !== "false") {
    const routeComponents = [<Route exact path='/' element={<SignIn />} />];
    let component = null;
    switch (step) {
      case "0.5":
        routeComponents.push(
          <Route exact path='/select-otp' element={<OTPSelectionScreen />} />
        );
        component = <OTPSelectionScreen />;
        break;
      case "1":
        routeComponents.push(
          <Route exact path='/setup-otp' element={<FANotEnable />} />
        );
        component = <FANotEnable />;
        break;
      case "2":
        routeComponents.push(
          <Route path='/confirm-otp' element={<FANotEnabledOTP />} />
        );
        component = <FANotEnabledOTP />;
        break;
      case "2.2":
        routeComponents.push(
          <Route path='/verify-otp' element={<FAEnabled />} />
        );
        component = <FAEnabled />;
        break;
      case "3":
        routeComponents.push(
          <Route
            exact
            path='/verified-otp'
            element={<FANotEnabledOTPVarified />}
          />
        );
        component = <FANotEnabledOTPVarified />;
        break;
      default:
        component = null;
        break;
    }
    setIsDisplayBanner(false);
    return (
      <Routes>
        {routeComponents}
        <Route
          path='*'
          element={component === null ? <ErrorPage2FA /> : component}
        />
      </Routes>
    );
  } else if (
    currentUser &&
    roleAccess([USER_ROLE.Sp_Standard_User]) &&
    !isOnBoarding &&
    !currentUser.on_boarding
  ) {
    setIsDisplayBanner(false);
    return (
      <Routes>
        <Route path='*' element={<OnBoardingForm />} />
      </Routes>
    );
  } else if (
    currentUser &&
    !parseBooleanVal(currentUser?.payment_setting?.wildcard) &&
    (checkSubscriptionStatus(currentUser) ===
      STRIPE_CHECK_SUBSCRIPTION_STATUS.TRAIL_EXPIRED ||
      checkSubscriptionStatus(currentUser) ===
        STRIPE_CHECK_SUBSCRIPTION_STATUS.PAYMENTFAILED) &&
    roleAccess([USER_ROLE.Sp_Admin])
  ) {
    setIsDisplayBanner(true);
    return (
      <Routes>
        <Route
          path='/subscription'
          element={<SubscriptionDetailsDashboard istrailExpired={true} />}
        />
        <Route
          path={"/subscription/plans"}
          element={<PricingTable istrailExpired={true} />}
        />
        <Route path='*' element={<PricingTable istrailExpired={true} />} />
      </Routes>
    );
  } else if (!getToken() || !currentUser || !userType) {
    setIsDisplayBanner(false);
    const routeComponents = DynamicRoutes["public"].map(
      ({ path, element }, key) => (
        <Route exact path={path} element={element} key={key} />
      )
    );
    return <Routes> {routeComponents} </Routes>;
  } else if (isTwoFactorAuthenticate() && currentUser && userType) {
    setIsDisplayBanner(true);
    const routeComponents = DynamicRoutes[userType]
      .filter((item) => {
        if (item?.isWildRoute && item?.role) {
          return (
            roleAccess(item.role) &&
            !parseBooleanVal(currentUser?.payment_setting?.wildcard)
          );
        } else if (item?.role) {
          return roleAccess(item.role);
        }

        return true;
      })
      .map(
        (
          { path, element, permissionName = null, permissionChild = null },
          key
        ) => (
          <Route
            exact
            path={path}
            element={
              !roleAccess([USER_ROLE.Sp_Admin]) &&
              permissionName &&
              permissionName !== null &&
              !roleAccess([], permissionName, permissionChild) ? (
                <PermissionDenied />
              ) : (
                element
              )
            }
            key={key}
          />
        )
      );

    return (
      <Routes>
        {routeComponents}
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    );
  } else {
    localStorage.clear();
    sessionStorage.clear();
    return (
      <Routes>
        <Route path='*' element={<SignIn />} />
      </Routes>
    );
  }
};
