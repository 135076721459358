import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CircularLoader from "../../common/CircularLoader/CircularLoader";
// import closeIcon from "../../assets/close-icon.png";

const PDFModalSkeleton = ({
  children,
  cssClass,
  openModall,
  printRef,
  dataForPrint,
  handlePrint,
}) => {
  useEffect(() => {
    if (dataForPrint && printRef && handlePrint) {
      handlePrint();
    }
  }, [dataForPrint]);

  return (
    <Transition.Root as={Fragment} show={dataForPrint ? true : false}>
      <Dialog as="div" className="relative z-20" onClose={() => {}}>
        <div ref={printRef}>
          <div className="mt-2">{children}</div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default PDFModalSkeleton;
