import { connect } from "react-redux";
import ServiceProviders from "./ServiceProviders";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviders);
