import React, { useState } from "react";
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { getNameProfile } from "Utils/utils";

const MultiSelectDropdown = ({
  data,
  cssClass,
  control,
  showIcon = false,
  setValue,
  name,
  watch,
  clearErrors,
  errorMessage,
  selectedOptions,
  isDisabled,
  calendarView,
  setSelectedOptions,
  watchFormData,
  selectedShift = "",
}) => {
  const values = watch(name);

  const defaultValues =
    calendarView &&
    values !== null &&
    values !== undefined &&
    Array.isArray(values)
      ? values?.map((item, index) => ({
          email: item.email,
          first_name: item.first_name,
          icon: item.icon || "",
          id: item.id,
          label: `${item.first_name} ${item.last_name}`,
          last_name: item?.last_name,
          profile_picture: item?.profile_picture || "",
          photo: item?.photo || "",
          uuid: item?.uuid,
          value: item?.uuid,
        }))
      : data?.find((item) => item.uuid === values);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const CheckboxOption = ({ innerProps, label, isSelected }) => (
    <div
      {...innerProps}
      className={`flex items-center justify-between mt-1 p-2 ${
        isSelected ? "bg-[#516CB8] " : "bg-white "
      }`}
    >
      <label
        className={`ml-2 block text-md capitalize ${
          isSelected ? "text-white" : "text-gray-900"
        }`}
      >
        {label}
      </label>
      <div
        className={`mx-2 h-6 w-6 rounded-full flex items-center cursor-pointer justify-center border ${
          isSelected ? "bg-white" : ""
        }`}
        onClick={() => handleOptionToggle(label)}
      >
        {isSelected && <FaCheck className='bg-white text-[#516cb8]' />}
      </div>
    </div>
  );

  const CustomSelectValue = (csprops) => {
    return (
      <components.SingleValue {...csprops}>
        <div className='flex items-center capitalize'>
          {csprops.data.label && csprops.data.icon && (
            <img
              onError={(e) => handleImageError(e, csprops.data.label, " ")}
              className='w-7 h-7 bg-gray-300 rounded-full flex-shrink-0 object-cover mr-2'
              src={getIcon(csprops.data.icon || csprops.data.value)}
              alt=''
            />
          )}
          {csprops.data.label}
        </div>
      </components.SingleValue>
    );
  };
  const handleImageError = (e, fname, lname) => {
    // color=fff
    return (e.target.src = getNameProfile(fname, lname, "0D8ABC"));
  };
  const getIcon = (type) => {
    return type;
  };
  const CustomOption = (props) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div className='flex items-center capitalize'>
          {data.label && data.icon && (
            <img
              className='w-7 h-7 bg-gray-300 rounded-full flex-shrink-0 object-cover mr-2'
              src={getIcon(data.icon || data.value)}
              alt=''
            />
          )}
          {data.label}
        </div>
      </components.Option>
    );
  };
  const handleOptionToggle = (optionLabel) => {
    const selectedOption = data.find((option) => option.label === optionLabel);
    if (!selectedOption) return;

    const selectedOptions = watch(name) || [];
    const isSelected = selectedOptions.some(
      (opt) => opt.value === selectedOption.value
    );

    if (isSelected) {
      setValue(
        name,
        selectedOptions.filter((opt) => opt.value !== selectedOption.value)
      );
    } else {
      setValue(name, [...selectedOptions, selectedOption]);
    }
  };

  const customStyles = {
    multiValueRemove: (base, state) => ({
      ...base,
      display: "block",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menuList: (base) => ({
      ...base,
      maxHeight: "200px !important",
      overflowY: "auto !important",
      scrollbarWidth: "thin",
    }),
    // input: (provided) => ({
    //   ...provided,
    //   width: "100%",
    //   minWidth: "2px",
    // }),
  };

  // console.log("defaultValues", defaultValues);
  // console.log(
  //   "sfsfsd",
  //   calendarView
  //     ? defaultValues !== undefined
  //       ? defaultValues
  //       : null
  //     : watch(name)
  // );

  return (
    <>
      {showIcon ? (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValues || []}
          // rules={{ validate: value => value.length > 0 || "Please select at least one option" }}
          render={({ field }) => (
            <>
              <Select
                isMulti
                isDisabled={isDisabled}
                options={data}
                value={
                  calendarView
                    ? defaultValues !== undefined
                      ? defaultValues
                      : null
                    : watch(name)
                }
                closeMenuOnSelect={false}
                components={{
                  Option: CustomOption,
                  SingleValue: CustomSelectValue,
                }}
                isClearable={true}
                clearableValue={true}
                onChange={(c) => {
                  setValue(name, c);
                  clearErrors(name, "");
                }}
                styles={customStyles}
                menuPortalTarget={document.body}
                className={`basic-multi-select w-100 roleSelector scrollOptions ${cssClass}`}
              />
              {errorMessage && <p className='text-red-500'>{errorMessage}</p>}
            </>
          )}
        />
      ) : (
        <Select
          isMulti
          options={data}
          value={selectedOptions}
          components={{ Option: CheckboxOption }}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          onMenuBlur={() => setMenuIsOpen(false)}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          clearableValue={false}
          styles={customStyles}
          className={`basic-multi-select w-100 roleSelector scrollOptions ${cssClass}`}
        />
      )}
    </>
  );
};

export default MultiSelectDropdown;
