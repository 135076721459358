import React, { useEffect, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";

const PopoverStickOnHover = (props) => {
  const { delay, onMouseEnter, children, item } = props;
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef(null);
  let setTimeoutConst = null;
  useEffect(() => () => {
    if (setTimeoutConst) {
      clearTimeout(setTimeoutConst);
    }
  });

  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
      if (onMouseEnter) onMouseEnter();
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  const handleClick = () => {
    setShowPopover(true);
  };

  const component = () => {
    return item;
  };

  const displayChild = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onClick: handleClick,
      ref: (node) => {
        childNode.current = node;
        const { ref } = child;
        if (typeof ref === "function") {
          ref(node);
        }
      },
    })
  )[0];

  return (
    <>
      {displayChild}
      <Overlay show={showPopover} placement='bottom' target={childNode}>
        <Popover
          onMouseEnter={() => {
            setShowPopover(true);
          }}
          onMouseLeave={handleMouseLeave}
          id='popover'
        >
          {component()}
        </Popover>
      </Overlay>
    </>
  );
};

PopoverStickOnHover.defaultProps = {
  delay: 0,
  onMouseEnter: () => {},
};

export default PopoverStickOnHover;
