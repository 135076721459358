import createSelector from "../../Utils/reselect";

const cosStateData = (state) => {
  const { cos } = state;
  return cos || {};
};

const employeeStateData = (state) => {
  const { employee } = state;
  return employee || {};
};

export const DynamicBreadCrumbSelector = createSelector(
  cosStateData,
  (cos) => cos.get("DynamicBreadCrumb") || []
);

export const createNewFolderSuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewFolderSuccess") || false
);

export const createNewFolderLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("createNewFolderLoading") || false
);

export const getRemoveFolder = createSelector(
  cosStateData,
  (cos) => cos.get("removeFolderSuccess") || ""
);

export const getRemoveFolderLoading = createSelector(
  cosStateData,
  (cos) => cos.get("createNewFolderLoading") || false
);

export const getRemoveFolderError = createSelector(
  cosStateData,
  (cos) => cos.get("removeFolderError") || ""
);

export const getFolderListForHRMLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("removeFolderLoading") || false
);

export const getFolderListForHRMSelector = createSelector(
  cosStateData,
  (cos) => cos.get("getFolderListForHRM") || []
);

export const getFileListForHRMSelector = createSelector(
  cosStateData,
  (cos) => cos.get("getFileListForHRM") || []
);

export const updateCategorySuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateCategorySuccess") || false
);

export const updatePrioritySuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updatePrioritySuccess") || false
);

export const updateContactSuccessSelector = createSelector(
  cosStateData,
  (cos) => cos.get("updateContactSuccess") || false
);

export const reportLoadingSelector = createSelector(
  cosStateData,
  (cos) => cos.get("reportLoading") || false
);

export const getHrmFormCatsLoader = createSelector(
  employeeStateData,
  (employee) => employee.get("getHrmFormCatsLoader") || false
);

export const getHrmFormCategories = createSelector(
  employeeStateData,
  (employee) => {
    const formCats = employee.get("hrmFormCategories") || null;
    if (formCats && formCats.length > 0) {
      return formCats.map((x) => {
        const obj = x;
        Object.assign(obj, {
          value: x.uuid,
        });
        return obj;
      });
    }
    return [];
  }
);

export const getHrmSubmittedFormListLoader = createSelector(
  employeeStateData,
  (employee) => employee.get("getHrmSubmittedFormListLoader") || false
);

export const getHrmSubmittedFormsList = createSelector(
  employeeStateData,
  (employee) => employee.get("hrmSubmittedFormsList") || null
);
