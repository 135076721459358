import React, { useContext, useState } from "react";
import { LoginContext } from "helpers/LoginContext";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import RolespermissionsList from "./RolesPermissionsList";
import Header from "components/common/Header/Header";
import { BiPlus } from "react-icons/bi";
import RoleAndPermissionModal from "./RoleAndPermissionModal.jsx/RoleAndPermissionModal";
import { portalTypeOptions } from "Constants/constant";

const RolesAndPermissionSetting = (props) => {
  const {
    roleAndPermisisonCreateOrUpdateRequest,
    roleAndPermissionsCreateOrUpdateLoader,
    roleAndPermissionsList,
    rolesAndPermissionslistRequest,
    defaultRoleAndPerLoader,
    defaultRoleAndPermissions,
    defaultRolesAndPermissionsRequest,
    listingRoleAndPerLoader,
    resetDefaultRolesPermissionLocally,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedPermissionUUID, setSelectedPermissionUUID] = useState(null);
  const [selectedPortalFilter, setSelectedPortalFilter] = useState(
    portalTypeOptions[0]
  );

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <main className='h-screen flex-1 bg-white'>
            <Header
              title='Roles & Permissions'
              btnLabel='Add New Role'
              icon={<BiPlus />}
              handleClick={() => setIsOpenModal(true)}
              isButton
            />
            <RolespermissionsList
              listingRoleAndPerLoader={listingRoleAndPerLoader}
              rolesAndPermissionslistRequest={rolesAndPermissionslistRequest}
              roleAndPermissionsList={roleAndPermissionsList}
              setSelectedPermissionUUID={setSelectedPermissionUUID}
              setIsOpenModal={setIsOpenModal}
              selectedPortalFilter={selectedPortalFilter}
              setSelectedPortalFilter={setSelectedPortalFilter}
            />
          </main>
        </div>
      </div>
      {isOpenModal && (
        <RoleAndPermissionModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          resetDefaultRolesPermissionLocally={
            resetDefaultRolesPermissionLocally
          }
          defaultRolesAndPermissionsRequest={defaultRolesAndPermissionsRequest}
          defaultRoleAndPerLoader={defaultRoleAndPerLoader}
          defaultRoleAndPermissions={defaultRoleAndPermissions}
          roleAndPermissionsCreateOrUpdateLoader={
            roleAndPermissionsCreateOrUpdateLoader
          }
          roleAndPermisisonCreateOrUpdateRequest={
            roleAndPermisisonCreateOrUpdateRequest
          }
          selectedPermissionUUID={selectedPermissionUUID}
          isOpen={isOpenModal}
          setIsOpenModal={(val) => {
            setIsOpenModal(val);
            setSelectedPermissionUUID(null);
          }}
          selectedPortalFilter={selectedPortalFilter}
        />
      )}
    </>
  );
};

export default RolesAndPermissionSetting;
