import React from "react";
import CardItem from "./Carditem";
import { connect } from "react-redux";
import {
  getStripeListAllCards,
  getStripeListAllCardsLoader,
} from "store/selector/sp.selector";
import Loader from "components/common/Loader/Loader";
import { getUser } from "store/selector/auth.selector";

const CardList = (props) => {
  const { listCardLoader, cards, currentUser } = props;

  return (
    <>
      {listCardLoader ? (
        <Loader cssClass={"text-black"} isSmallView={true} />
      ) : listCardLoader === false && cards.length <= 0 ? (
        <div className='text-red-500 text-center'>No Data Found!</div>
      ) : (
        <div className='grid gap-6 grid-cols-3'>
          {cards.map((card, index) => (
            <CardItem
              isDefaultCard={currentUser?.is_default_card === card.id}
              card={card}
              key={index}
            />
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const listCardLoader = getStripeListAllCardsLoader(state);
  const currentUser = getUser(state);

  return {
    listCardLoader,
    currentUser,
  };
};

export default connect(mapStateToProps, null)(CardList);
