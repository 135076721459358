import React, { useEffect, useState } from "react";
import Tabs from "../../../pages/HRM/Components/Tabs/Tabs";
import FileUploadInput from "../../../components/common/FileUpload/FileUploadInput";
import NotificationDropDown from "../../../components/common/NotificationDropDown/NotificationDropDown";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import {
  EXTERNAL_SERVICE_PROVIDER_TABS,
  EXTERNAL_SERVICE_PROVIDER_TABS_NAME,
} from "../../../Constants/constant";
import { LoginContext } from "../../../helpers/LoginContext";
import "./external-participant-profile.css";
import CaseNotesList from "./CaseNotesList";
import { useForm } from "react-hook-form";
import moment from "moment";

const ExternalParticipantProfile = () => {
  const { sideBarPadding } = React.useContext(LoginContext);
  const [file, setFile] = useState("");
  const [selectedTab, setSelectedTab] = useState(
    EXTERNAL_SERVICE_PROVIDER_TABS_NAME.CASE_NOTES_LIST
  );
  const [errorsInt, setErrors] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  const getError = (value) => {
    if (!value) return "";
    return errorsInt[value] && errorsInt[value].length > 0
      ? "The Field is required!"
      : "";
  };

  useEffect(() => {
    if (file && getError("File")) {
      setErrors({ ...errorsInt, File: null });
    }
  }, [file]);

  const tabToRender = () => {
    switch (selectedTab) {
      case EXTERNAL_SERVICE_PROVIDER_TABS_NAME.CASE_NOTES_LIST:
        return <CaseNotesList />;
      case EXTERNAL_SERVICE_PROVIDER_TABS_NAME.CASE_NOTES:
        return <CaseNotesList />;
      default:
        return <></>;
    }
  };

  const isValidInputs = () => {
    const fnError = errorsInt;

    if (!file || file.length === 0) {
      Object.assign(fnError, { File: "File is Required" });
    }

    return false;
  };

  const onSubmit = (data) => {
    if (isValidInputs()) return;
  };

  return (
    <div className="min-h-screen">
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className="flex-1 bg-white">
          <div className="items-center flex mb-5 border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 sticky top-0 z-[9] bg-white">
            <div className="flex-1 min-w-0">
              <h1 className="text-lg font-bold leading-6 text-gray-900 sm:truncate">
                Participant Profile
              </h1>
            </div>
            <div className="mt-4 flex justify-space-between sm:mt-0 space-x-2">
              <div className="flex justify-end items-center">
                <div className="mt-4 flex sm:mt-0 sm:ml-4 mr-3">
                  <NotificationDropDown />
                </div>
              </div>
            </div>
          </div>

          <div className="px-8">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
              Participant Profile
            </h1>
            <p className="text-sm font-normal text-gray-500">
              Update your personal details here
            </p>
          </div>

          <div className="serv mx-8 bg-blue-100 rounded-lg p-5 my-4">
            <ul>
              <li>
                <div className="mx-5 my-3">
                  <p className="text-sm text-gray-500">NDIS Number</p>
                  <h3 className="text-gray-900">433333333</h3>
                </div>
              </li>
              <li>
                <div className="mx-5 my-3">
                  <p className="text-sm text-gray-500">Date of Birth</p>
                  <h3 className="text-gray-900">Sept 2, 1999</h3>
                </div>
              </li>
              <li>
                <div className="mx-5 my-3">
                  <p className="text-sm text-gray-500">Gender</p>
                  <h3 className="text-gray-900">Male</h3>
                </div>
              </li>
              <li>
                <div className="mx-5 my-3">
                  <p className="text-sm text-gray-500">Email</p>
                  <h3 className="text-gray-900">amaar@bearplex.com</h3>
                </div>
              </li>
              <li>
                <div className="mx-5 my-3">
                  <p className="text-sm text-gray-500">Phone</p>
                  <h3 className="text-gray-900">+92 3010443803</h3>
                </div>
              </li>
            </ul>
          </div>

          <div className="ml-8 mr-8 px-5 pt-0 mb-3 sm:px-4 lg:px-4 bg-white rounded-md shadow-md py-4 custom-scroll11 overflow-y-auto11 allApplicantHeight11">
            <div className="px-4 sm:px-0 mt-4 bg-white sticky top-[69px] z-10 pt-1">
              <Tabs
                tabs={EXTERNAL_SERVICE_PROVIDER_TABS}
                handleChange={handleTabChange}
                selectedTab={selectedTab}
              />
            </div>
            <div className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0 ">
              {tabToRender()}
            </div>
          </div>

          <div className="mx-8 my-2">
            <div className="flex justify-between items-center p-5 bg-gray-100 rounded-lg border-1 border-black">
              <h1 className="text-lg font-bold leading-6 text-gray-900 sm:truncate">
                Case Notes
              </h1>
              <button className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-offset-2 sm:w-auto">
                Create KM Claim
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="serv my-4">
                <ul>
                  <li className="px-2">
                    <div>
                      <label
                        htmlFor="supportItemName"
                        className="capitalize block text-sm font-medium text-gray-700"
                      >
                        Support Item
                      </label>
                      <div className="mt-1">
                        <input
                          type="number"
                          name="supportItemName"
                          id="supportItemName"
                          className="did-floating-input capitalize w-full"
                          {...register("supportItemName", { required: true })}
                        />
                        {errors.supportItemName && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="px-2">
                    <div>
                      <label
                        htmlFor="deliveredDate"
                        className="capitalize block text-sm font-medium text-gray-700"
                      >
                        Delivered Date
                      </label>
                      <div className="mt-1">
                        <input
                          min={moment().format("DD-MM-YYYY")}
                          type="date"
                          name="deliveredDate"
                          id="deliveredDate"
                          className="did-floating-input capitalize w-full"
                          {...register("deliveredDate", { required: true })}
                        />
                        {errors.deliveredDate && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="px-2">
                    <div>
                      <label
                        htmlFor="billingType"
                        className="capitalize block text-sm font-medium text-gray-700"
                      >
                        Billing Type
                      </label>
                      <div className="mt-1">
                        <input
                          type="number"
                          name="billingType"
                          id="billingType"
                          className="did-floating-input capitalize w-full"
                          {...register("billingType", { required: true })}
                        />
                        {errors.billingType && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="px-2 mt-2">
                    <div>
                      <label
                        htmlFor="contactType"
                        className="capitalize block text-sm font-medium text-gray-700"
                      >
                        Contact Type
                      </label>
                      <div className="mt-1">
                        <input
                          type="number"
                          name="contactType"
                          id="contactType"
                          className="did-floating-input capitalize w-full"
                          {...register("contactType", { required: true })}
                        />
                        {errors.contactType && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="px-2 mt-2">
                    <div>
                      <label
                        htmlFor="duration"
                        className="capitalize block text-sm font-medium text-gray-700"
                      >
                        Duration
                      </label>
                      <div className="mt-1">
                        <input
                          min={moment().format("DD-MM-YYYY")}
                          type="date"
                          name="duration"
                          id="duration"
                          className="did-floating-input capitalize w-full"
                          {...register("duration", { required: true })}
                        />
                        {errors.duration && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                  <li className="px-2 mt-2">
                    <div>
                      <label
                        htmlFor="subjectLine"
                        className="capitalize block text-sm font-medium text-gray-700"
                      >
                        Subject Line
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="subjectLine"
                          id="subjectLine"
                          className="did-floating-input capitalize w-full"
                          {...register("subjectLine", {
                            required: true,
                            maxLength: 254,
                          })}
                        />
                        {errors.subjectLine && (
                          <span className="text-red-500">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="flex">
                <div className="w-1/2 mr-3">
                  <label
                    htmlFor="caseNotes"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Case Notes
                  </label>
                  <div className="mt-1">
                    <textarea
                      rows={3}
                      name="caseNotes"
                      id="caseNotes"
                      className="overflow-y-auto px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      {...register("caseNotes", { required: true })}
                    />
                    {errors.caseNotes && (
                      <span className="text-red-500">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="mt-6">
                    <FileUploadInput
                      type="any"
                      handleFile={(e) => setFile(e)}
                      size="1"
                      file={file}
                      handleDelete={() => setFile("")}
                    />
                    {getError("File") && (
                      <span className="errorMessage ">{getError("File")}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex mt-3 ">
                <button
                  className="bg-white text-black rounded-lg px-4 py-1.5 flex items-center space-x-1 text-sm"
                  style={{ border: "1px solid gray" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex items-center justify-center rounded-md border border-transparent bg-gradient px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-offset-2 sm:w-auto"
                >
                  Save
                </button>
              </div>
            </form>
          </div>

          <div className="mx-8 my-2 flex">
            <div className="w-1/2 mr-5">
              <div className="flex justify-between items-center p-5 bg-gray-100 rounded-lg border-1 border-black">
                <h1 className="text-lg font-bold leading-6 text-gray-900 sm:truncate">
                  Support TimeLine
                </h1>
              </div>
              <div>
                <table className=" divide-gray-300">
                  <thead className="bg-gray-50 capitalize">
                    <tr className="pt-2 sticky top-[8rem]">
                      <th
                        scope="col"
                        className="bg-blue-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Support Category
                      </th>

                      <th
                        scope="col"
                        className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Budget
                      </th>
                      <th
                        scope="col"
                        className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Delivered
                      </th>
                      {/* <th
                        scope="col"
                        className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      ></th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white overflow-y-auto">
                    <tr>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        Coordation of Supports VC
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        $5207.28
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        $5207.28
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="w-64 truncate flex space-x-4 items-center"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="w-1/2 mr-5">
              <div className="flex justify-between items-center p-5 bg-gray-100 rounded-lg border-1 border-black">
                <h1 className="text-lg font-bold leading-6 text-gray-900 sm:truncate">
                  Funding Overview
                </h1>
              </div>
              <div>
                <table className=" divide-gray-300">
                  <thead className="bg-gray-50 capitalize">
                    <tr className="pt-2 sticky top-[8rem]">
                      <th
                        scope="col"
                        className="bg-blue-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Support Category
                      </th>

                      <th
                        scope="col"
                        className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Budget
                      </th>
                      <th
                        scope="col"
                        className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Delivered
                      </th>
                      {/* <th
                        scope="col"
                        className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      ></th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white overflow-y-auto">
                    <tr>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        Coordation of Supports VC
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        $5207.28
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        $5207.28
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="w-64 truncate flex space-x-4 items-center"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="mx-8 my-2">
            <div className="flex justify-between items-center p-5 bg-gray-100 rounded-lg border-1 border-black">
              <h1 className="text-lg font-bold leading-6 text-gray-900 sm:truncate">
                Support Track
              </h1>
            </div>
            <div>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 capitalize">
                  <tr className="pt-2 sticky top-[8rem]">
                    <th
                      scope="col"
                      className="bg-blue-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Support Category
                    </th>

                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Budget
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Delivered
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Remaning
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Remaning Hrs
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Projected End Date
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white overflow-y-auto">
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      Coordation of Supports VC
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      $5207.28
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      $5207.28
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      $5207.28
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      35:55
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      28/07/2022
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="w-64 truncate flex space-x-4 items-center"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="mx-8 my-2">
            <div className="flex justify-between items-center p-5 bg-gray-100 rounded-lg border-1 border-black">
              <h1 className="text-lg font-bold leading-6 text-gray-900 sm:truncate">
                Inactive Plans
              </h1>
            </div>
            <div>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 capitalize">
                  <tr className="pt-2 sticky top-[8rem]">
                    <th
                      scope="col"
                      className="bg-blue-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Support Category
                    </th>

                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Budget
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Delivered
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Remaning
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Remaning Hrs
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Projected End Date
                    </th>
                    <th
                      scope="col"
                      className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white overflow-y-auto">
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      Coordation of Supports VC
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      $5207.28
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      $5207.28
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      $5207.28
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      35:55
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      28/07/2022
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div className="w-64 truncate flex space-x-4 items-center"></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ExternalParticipantProfile;
