import { connect } from "react-redux";
import { getCatalogServicesSubCategoryRequest } from "store/actions/cos.actions";
import {
  participantQuickSnapshotCall,
  participantSettingUpdateCall,
} from "store/actions/participant.actions";
import { getSPWorkerRequest } from "store/actions/sp.actions";
import { getServiceCatalogStates } from "store/selector/cos.selector";
import {
  getLoading,
  getParticipantQuickSnapshot,
  getQuickSnapshotLoading,
  getSelectedParticipant,
  getSettingLoading,
} from "store/selector/participant.selector";
import {
  getSpWorkers,
  getSpSupportCoordinators,
} from "store/selector/sp.selector";
import Setting from "./Settings";

const mapDispatchToProps = {
  fetchSettingsData: participantQuickSnapshotCall,
  fetchWorkers: getSPWorkerRequest,
  updateSettingRequest: participantSettingUpdateCall,
  getCatalogServicesSubCategory: getCatalogServicesSubCategoryRequest,
};

const mapStateToProps = (state) => {
  // const settingData = getParticipantQuickSnapshot(state);
  const settingData = getSelectedParticipant(state);
  const workersList = getSpWorkers(state);
  const supportCoordinatorsList = getSpSupportCoordinators(state);
  const settingLoading = getQuickSnapshotLoading(state);
  const isLoading = getSettingLoading(state);
  const catalogStates = getServiceCatalogStates(state);

  return {
    settingData,
    workersList,
    isLoading,
    settingLoading,
    supportCoordinatorsList,
    catalogStates,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
