import React, { useEffect, useState } from "react";
import anglePendingDown from "assets/svg/angle-down-warning.svg";
import anglePendingRight from "assets/svg/angle-right-warning.svg";
import angleApproveDown from "assets/svg/angle-down-approve.svg";
import angleApproveRight from "assets/svg/angle-right-approve.svg";
import TimeSheetCardItem from "./TimeSheetCardItem";
import { TIMESHEET_STATUS } from "Constants/constant";

const TimeSheetListItem = (props) => {
  const {
    timeSheet,
    selectedTimeSheet,
    setSelectedTimeSheetLocally,
    isLieInTimeSheet,
  } = props;

  const [isPending, setIsPending] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isOther, setIsOther] = useState(false);

  const handleSelectTimeSheet = (item) => {
    // console.log("item", item);
    setSelectedTimeSheetLocally(item);
  };

  useEffect(() => {
    if (selectedTimeSheet && isLieInTimeSheet) {
      if (selectedTimeSheet.status === TIMESHEET_STATUS.PENDING && !isPending) {
        setIsPending(true);
        setIsApproved(false);
        setIsOther(false);
      } else if (
        selectedTimeSheet.status === TIMESHEET_STATUS.APPROVED &&
        !isApproved
      ) {
        setIsPending(false);
        setIsApproved(true);
        setIsOther(false);
      } else if (
        selectedTimeSheet.status !== TIMESHEET_STATUS.PENDING &&
        selectedTimeSheet.status !== TIMESHEET_STATUS.APPROVED &&
        !isOther
      ) {
        setIsPending(false);
        setIsApproved(false);
        setIsOther(true);
      }
    }
  }, [selectedTimeSheet]);

  return (
    <>
      <div
        className={`${
          timeSheet.pendingTimeSheets.length ? "cursor-pointer" : ""
        } border border-orange-700 bg-orange-50 rounded-2xl py-0.5 px-2.5 flex items-center justify-between w-[85%] m-w-[70%] mx-auto p-3`}
        onClick={() =>
          timeSheet.pendingTimeSheets.length > 0 && setIsPending(!isPending)
        }
      >
        <span className='text-orange-700'>
          {`${timeSheet.pendingTimeSheets.length} Pending Timesheets`}
        </span>
        {timeSheet.pendingTimeSheets.length > 0 && (
          <img
            className='cursor-pointer'
            src={isPending ? anglePendingDown : anglePendingRight}
            alt='carotIcon'
          />
        )}
      </div>
      {isPending && (
        <div>
          {timeSheet.pendingTimeSheets.length > 0 &&
            timeSheet.pendingTimeSheets.map((item, index) => (
              <TimeSheetCardItem
                handleSelectTimeSheet={handleSelectTimeSheet}
                selectedTimeSheet={selectedTimeSheet}
                item={item}
                key={index}
                index={index}
              />
            ))}
        </div>
      )}

      <div
        className={`${
          timeSheet.approvedTimeSheets.length ? "cursor-pointer" : ""
        } border border-green-700 bg-green-50 rounded-2xl py-0.5 px-2.5 flex items-center justify-between w-[85%] m-w-[70%] mx-auto p-3 mt-3`}
        onClick={() =>
          timeSheet.approvedTimeSheets.length > 0 && setIsApproved(!isApproved)
        }
      >
        <span className='text-green-700'>
          {`${timeSheet.approvedTimeSheets.length} Approved Timesheets`}
        </span>
        {timeSheet.approvedTimeSheets.length > 0 && (
          <img
            className='cursor-pointer'
            src={isApproved ? angleApproveDown : angleApproveRight}
            alt='carotIcon'
          />
        )}
      </div>
      {isApproved && (
        <div>
          {timeSheet.approvedTimeSheets.length > 0 &&
            timeSheet.approvedTimeSheets.map((item, index) => (
              <TimeSheetCardItem
                selectedTimeSheet={selectedTimeSheet}
                handleSelectTimeSheet={handleSelectTimeSheet}
                item={item}
                key={index}
                index={index}
              />
            ))}
        </div>
      )}

      <div
        className={`${
          timeSheet.otherTimeSheets.length ? "cursor-pointer" : ""
        } border border-blue-700 bg-blue-50 rounded-2xl py-0.5 px-2.5 flex items-center justify-between w-[85%] m-w-[70%] mx-auto p-3 mt-3`}
        onClick={() =>
          timeSheet.otherTimeSheets.length > 0 && setIsOther(!isOther)
        }
      >
        <span className='text-blue-700'>
          {`${timeSheet.otherTimeSheets.length} Other Timesheets`}
        </span>
        {timeSheet.otherTimeSheets.length > 0 && (
          <img
            className='cursor-pointer'
            src={isOther ? angleApproveDown : angleApproveRight}
            alt='carotIcon'
          />
        )}
      </div>
      {isOther && (
        <div>
          {timeSheet.otherTimeSheets.length > 0 &&
            timeSheet.otherTimeSheets.map((item, index) => (
              <TimeSheetCardItem
                selectedTimeSheet={selectedTimeSheet}
                handleSelectTimeSheet={handleSelectTimeSheet}
                item={item}
                key={index}
                index={index}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default TimeSheetListItem;
