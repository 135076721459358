

import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";

import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  REPORTING_LIMIT,
  SHIFT_STATUS_LIST,
  SORT_TYPE,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_ROC_REPORTS } from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";
import _ from "lodash";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { toast } from "react-toastify";

import { exportTOExcel } from "Utils/utils";

const ProgressReports = ({
  getTeamManagmentListCall,
  teamManagementList,
  getParticipantList,
  spParticipants,
}) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedWorker, setSelectedWorker] = useState(null);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };
    getParticipantList({ filters, bodyFilter: null });
    getTeamManagmentListCall({ filters, bodyFilter: null });
  }, []);

  const [workerList, setWorkerList] = useState([
    { label: "All", value: "all" },
  ]);
  const [participantList, setParticipant] = useState([
    { label: "All", value: "all" },
  ]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [status, setStatus] = useState(null);
  const [shiftDate, setShiftDate] = useState(null);
const [search, setSearch] = useState(null);

  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [isOverTime, setIsOverTime] = useState(null);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    fetchListReports();
  }, [page]);

  useEffect(() => {
    const Workers =
      teamManagementList?.employees?.data?.map((item) => ({
        label: `${item?.first_name} ${item?.last_name}`,
        value: item?.id,
      })) || [];
    setWorkerList((prev) => [...prev, ...Workers]);
    const participants =
      spParticipants?.participant?.data?.map((item) => ({
        label: `${item?.first_name} ${item?.last_name}`,
        value: item?.id,
      })) || [];
    setParticipant((prev) => [...prev, ...participants]);
  }, [teamManagementList]);

  const handleCancelButton = () => {
    setShiftDate(null);
  };

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(() => fetchListReports(), 600);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    setLoader(true);
    let url = `${URL_REPORTING_ROC_REPORTS}?per_page=10&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_ROC_REPORTS}?per_page=10&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",
      participant: isClearAll
        ? "all"
        : selectedParticipant
        ? selectedParticipant?.value
        : "all",
      worker: isClearAll
        ? "all"
        : selectedWorker
        ? selectedWorker?.value
        : "all",
      status: isClearAll ? "all" : status ? status.value : "all",

      schedule_shift_date: isClearAll ? "all" : shiftDate ? shiftDate : "all",
      over_time: isClearAll ? "all" : isOverTime ? isOverTime.value : "all",
    };
    if (report) {
      payload.report = report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            window.open(`${DOC_DOWNLOAD_URL}${res?.data?.data}`, "_blank");
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.shifts?.total,
              totalPages: Math.ceil(
                res?.data?.data?.shifts?.total /
                  res?.data?.data?.shifts?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
        console.log({ error });
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    if (e === "xlsx") {
      exportTOExcelFile();
    } else {
      fetchListReports(false, e);
    }
  };

  const exportTOExcelFile = () => {
    const transformedData =
      reportList &&
      reportList.shifts &&
      reportList?.shifts?.data?.map((item) => {
        const participantsString =
          item.participants && item?.participants?.length > 0
            ? item.participants
                .map(
                  (participant) =>
                    `${participant.first_name} ${participant.last_name}`
                )
                .join(", ")
            : "";
        const workers = `${item.worker.first_name} ${item.worker.last_name}`;
        return {
          shift_date: item.shift_date,
          status: item.status,
          overtime: item.overtime,
          shift_length: item?.shift_length,

          participants: participantsString,
          workers: workers,
        };
      });
    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const handleFetch = (obj) => {
    fetchListReports(false, null, { ...obj });
  };

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  const OVERTIME_10H = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
  ];

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='Progress Reports' />
          <div className='max-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between py-1'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.ROC_REPORTS
                )}
              />
              <div>
                <SelectBox
                  isUserGracePeriodOver={isUserGracePeriodOver}
                  isLoading={loader}
                  setReportType={(e) => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    handleFileDownLoad(e);
                  }}
                />
              </div>
            </div>
            <div className='p-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <div className='w-full'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search
                  </label>
                  <Search setSearch={setSearch} search={search} />
                </div>
                <section>
                  <div className='flex py-4 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Participant Name
                        </label>
                        <SelectOption
                          name='pt_id'
                          isSearch={true}
                          data={participantList || []}
                          selectedOption={selectedParticipant}
                          handleChange={(e) => {
                            setSelectedParticipant(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          Support Worker
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={workerList || []}
                          selectedOption={selectedWorker}
                          handleChange={(e) => {
                            setSelectedWorker(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block  text-sm font-medium text-gray-700 text-start'>
                          Status
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={SHIFT_STATUS_LIST || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className='flex py-4 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label
                          htmlFor='from-date-to-to-date'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Scheduled Shift Date
                        </label>
                        <DateRangePickerNew
                          isSingleDate
                          isClearable
                          handleCancel={handleCancelButton}
                          handleChange={(obj) => {
                            setShiftDate(obj);
                          }}
                          singleSelectedDate={shiftDate && new Date(shiftDate)}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>

                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                          OverTime 10H
                        </label>
                        <SelectOption
                          name='overtime'
                          isSearch={true}
                          data={OVERTIME_10H || []}
                          selectedOption={isOverTime}
                          handleChange={(e) => {
                            setIsOverTime(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end pt-4 space-x-2'
                        style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() => fetchListReports(false)}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || false}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setShiftDate(null);

                            setStatus(null);
                            setSelectedWorker(null);

                            setSearch(null);
                            setReports([]);

                            setSelectedParticipant(null);
                            // fetchListReports(true);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='min-w-full  flex justify-center overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader
                        sortName='participant'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Participant"}
                      />

                      <TableHeader
                        sortName='worker'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Worker"}
                      />

                      <TableHeader
                        sortName='schedule_date'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Scheduled Shift Date"}
                      />
                      <TableHeader
                        sortName='shift_start_time'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Shift Start Time"}
                      />

                      <TableHeader
                        sortName='shift_end_time'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Shift End Time"}
                      />
                      <TableHeader
                        sortName='shift_length'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Shift length"}
                      />
                      <TableHeader
                        sortName='overtime'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"OverTime 10H"}
                      />
                      <TableHeader
                        sortName='status'
                        flipSort={flipSort}
                        sortBy={sortBy}
                        sortDescending={sortDescending}
                        label={"Status"}
                      />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={8} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.shifts?.data?.length > 0 ? (
                      reportList?.shifts?.data?.map((shift, index) => (
                        <tr
                          key={index}
                          className='even:bg-white odd:bg-gray-50'
                        >
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div>
                              {(shift?.participants &&
                                _.isArray(shift?.participants) && (
                                  <AvatarGroup
                                    type='participant'
                                    arrayData={shift?.participants || []}
                                    cssClass='mt-0'
                                    maxLength={10}
                                  />
                                )) ||
                                "-"}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            {`${shift?.worker?.first_name} ${shift?.worker?.last_name}`}
                          </td>
                          <td className='px-2 py-[11px] text-sm text-gray-500'>
                            {shift?.shift_date
                              ? moment(shift.shift_date).format("DD-MM-YYYY")
                              : "-"}
                          </td>
                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            {shift?.start_time || "-"}
                          </td>
                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            {shift?.end_time || "-"}
                          </td>

                          <td className='px-2 py-[11px] text-sm text-gray-500'>
                            {shift.shift_length || "-"}
                          </td>

                          <td className='w-40 truncate py-4  px-3 text-sm'>
                            {shift?.overtime || "-"}
                          </td>
                          <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                            {shift.status || "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={8}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='mt-3'>
                <PaginationComponent
                  page={paginationObj?.page || 1}
                  activeListLength={reportList?.shifts?.data || []}
                  limit={REPORTING_LIMIT}
                  totalList={paginationObj?.total}
                  handlePagination={(e) => {
                    setPage(e);
                    const obj = paginationObj;
                    obj.page = e;
                    setPaginationObj({ ...obj });
                  }}
                  totalPages={paginationObj?.totalPages}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProgressReports;
