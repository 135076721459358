import React, { useMemo } from "react";
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
  const {
    page,
    totalPages,
    handlePagination,
    limit,
    activeListLength,
    totalList,
  } = props;
  const lengthToMap = useMemo(() => activeListLength, [activeListLength]);

  return (
    <div className='flex sm:justify-between justify-end'>
      <div className='hidden sm:flex'>
        <div>
          <p className='text-sm text-gray-700'>
            <span className='font-medium'>
              {`Showing ${
                page === 1 && lengthToMap?.length > 0
                  ? "1"
                  : (page - 1) * limit + 1
              } to `}
            </span>
            <span className='font-medium'>
              {lengthToMap && lengthToMap?.length > 0 ? (
                <>
                  {totalList === 0 && lengthToMap?.length > 0
                    ? lengthToMap?.length
                    : page === totalPages
                    ? totalList
                    : parseInt(page) * parseInt(limit)}
                </>
              ) : (
                0
              )}
            </span>
            <span className='font-medium'>{` of ${totalList} results`}</span>
          </p>
        </div>
      </div>
      <ReactPaginate
        forcePage={
          totalList === 0 ? undefined : parseInt((page === 0 ? 1 : page) - 1)
        }
        disableInitialCallback={true}
        // initialPage={
        //   totalList === 0 ? undefined : parseInt((page === 0 ? 1 : page) - 1)
        // }
        disabledClassName='cursor-not-allowed'
        disabledLinkClassName='cursor-not-allowed'
        previousLabel=''
        nextLabel=''
        breakLabel='...'
        breakClassName='break-me'
        pageCount={totalPages === undefined ? 0 : totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(e) => {
          handlePagination(e.selected + 1);
        }}
        containerClassName='pagination flex'
        activeClassName='active'
      />
    </div>
  );
};

export default Pagination;
