import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import greenCircleIcon from "assets/svg/check-green-circle.svg";
import CheckBox from "components/common/checkBox/checkbox";
import {
  baseURLImage,
  filterNonPayrollRecords,
  getHrAndMinsFormatting,
  getNameProfile,
  getTimesheetProgressLabel,
  parseBooleanVal,
  textTruncate,
  checkWorkerAllTimesheetsSuccess,
  getAllNonSuccessTimesheets,
  hasCheckTimesheetStatus,
  checkWorkerAnyTimesheetsFailedOrError,
} from "Utils/utils";
import ViewBreakdownTimeSheetModal from "./ViewBreakdownTimeSheetModal";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { PAYROLL_EXPORT_STATUS } from "Constants/constant";
import { GrInProgress } from "react-icons/gr";
import PayrollTimesheetStatusPopover from "../PayrollTimesheetStatusPopover";
import AvatarGroup from "components/common/Avatar/AvatarGroup";

const ExportSelectedTimesheetItem = (props) => {
  const {
    isAuthenticateForPayrolls,
    workerTimeSheets,
    handleChildItemTimeSheet,
    singleTimesheetDetailsRequest,
    singleTimesheetDetailsLoader,
    isUserGracePeriodOver,
    singleTimesheetDetails,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedViewBreakdown, setSelectedViewBreakdown] = useState(null);
  const [export_status_key, setExportStatusKey] = useState(
    // `${isAuthenticateForPayroll}_export_status`
    ""
  );

  useEffect(() => {
    if (isAuthenticateForPayrolls !== null) {
      const isTrueAny = Object.keys(isAuthenticateForPayrolls || {})?.find(
        (x) => {
          if (parseBooleanVal(isAuthenticateForPayrolls[`${x}`])) {
            return x;
          }
          return false;
        }
      );
      if (isTrueAny) {
        setExportStatusKey(`${isTrueAny}_export_status`);
      }
    }
  }, [isAuthenticateForPayrolls]);

  useEffect(() => {
    setSelectAll(false);
  }, []);

  const isAllSuccess = useMemo(() => {
    return (
      checkWorkerAllTimesheetsSuccess(workerTimeSheets?.timeSheet).length ===
      workerTimeSheets?.timeSheet?.length
    );
  }, [workerTimeSheets?.timeSheet]);

  const isAnyFailed = useMemo(() => {
    return checkWorkerAnyTimesheetsFailedOrError(workerTimeSheets?.timeSheet)
      .length > 0
      ? true
      : false;
  }, [workerTimeSheets?.timeSheet]);

  useEffect(() => {
    setSelectAllCheckBox();
  }, [workerTimeSheets?.children]);

  const setSelectAllCheckBox = () => {
    if (isAllSuccess) {
      setSelectAll(true);
    } else if (
      workerTimeSheets?.timeSheet?.length === workerTimeSheets?.children?.length
    ) {
      setSelectAll(true);
    } else if (
      workerTimeSheets?.children.length > 0 &&
      workerTimeSheets?.children?.length ===
        getAllNonSuccessTimesheets(workerTimeSheets?.timeSheet).length
    ) {
      setSelectAll(true);
    } else setSelectAll(false);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const getExportPayrollIcon = (item) => {
    return hasCheckTimesheetStatus(item, PAYROLL_EXPORT_STATUS.SUCCESS) ? (
      <AiOutlineCheckCircle className='w-5 h-5' color={"green"} />
    ) : hasCheckTimesheetStatus(item, PAYROLL_EXPORT_STATUS.FAILED) ||
      hasCheckTimesheetStatus(item, PAYROLL_EXPORT_STATUS.ERROR) ? (
      <AiOutlineCloseCircle className='w-5 h-5' color={"red"} />
    ) : hasCheckTimesheetStatus(item, PAYROLL_EXPORT_STATUS.INITIATED) ? (
      <GrInProgress className='w-5 h-5' color={"yellow"} />
    ) : (
      "-"
    );
  };

  return (
    <div className='py-2'>
      <table className='border w-full divide-y divide-gray-200'>
        <thead>
          <tr>
            <th className='flex p-3 w-[10%] text-start space-x-4'>
              {isCollapsed && (
                <CheckBox
                  checked={selectAll}
                  isDisabled={isAllSuccess || isUserGracePeriodOver}
                  handleChange={() => {
                    if (isUserGracePeriodOver) {
                      return;
                    }
                    handleChildItemTimeSheet(
                      workerTimeSheets.uuid,
                      null,
                      null,
                      null,
                      !selectAll
                    );
                    setSelectAll(!selectAll);
                  }}
                  cssClass='border-gray-500 rounded-sm'
                />
              )}
              <button className='w-2 h-2' onClick={toggleCollapse}>
                {!isCollapsed ? "▶" : "▼"}
              </button>
            </th>
            <th
              title={`${workerTimeSheets.first_name} ${workerTimeSheets.last_name}`}
              className='w-[30%] p-3 capitalize text-base font-normal text-start'
            >
              <div className='flex space-x-2'>
                <span>
                  {textTruncate(
                    `${workerTimeSheets.first_name} ${workerTimeSheets.last_name}`,
                    23,
                    22
                  )}
                </span>
                <span>
                  <PayrollTimesheetStatusPopover
                    isAllSuccess={isAllSuccess}
                    isAnyFailed={isAnyFailed}
                    item={workerTimeSheets}
                    isPayrollIdExists={filterNonPayrollRecords(
                      workerTimeSheets
                    )}
                    payrollType={workerTimeSheets.payroll_platform}
                  />
                </span>
              </div>
            </th>
            <th className='p-3 w-[50%] text-start'>
              {`Total Hours: ${getHrAndMinsFormatting(
                workerTimeSheets.ttl_timesheet_length
              )}`}
            </th>
            <th className='w-[5%] p-3'>Progress</th>
            <th className='p-3 w-[5%]'>Exported</th>
          </tr>
        </thead>
        {isCollapsed && (
          <tbody className='transition ease-linear transition-all duration-300'>
            {workerTimeSheets.timeSheet.map((item, index) => (
              <tr
                key={index}
                className='hover:bg-blue-100 hover:rounded-lg bg-transparent rounded-lg border-t-0 border border-solid border-gray-200'
              >
                <td className='align-middle w-[10%] p-3 py-5'>
                  <CheckBox
                    handleChange={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleChildItemTimeSheet(
                        workerTimeSheets.uuid,
                        e,
                        item.uuid,
                        item
                      );
                    }}
                    isDisabled={
                      hasCheckTimesheetStatus(
                        item,
                        PAYROLL_EXPORT_STATUS.SUCCESS
                      ) || isUserGracePeriodOver
                    }
                    checked={
                      hasCheckTimesheetStatus(
                        item,
                        PAYROLL_EXPORT_STATUS.SUCCESS
                      ) ||
                      (workerTimeSheets?.children?.find(
                        (x) => x.uuid === item.uuid
                      )
                        ? true
                        : false)
                    }
                    cssClass='border-gray-500 rounded-sm'
                  />
                </td>
                <td className='w-[30%] p-3 py-5'>
                  <div className='flex gap-x-3'>
                    <AvatarGroup
                      type='worker'
                      arrayData={item?.participants || []}
                      cssClass='mt-0'
                      maxLength={10}
                      isForRostering
                    />
                    {/* <img
                      onError={(e) =>
                        handleImageError(
                          e,
                          item?.participants?.first_name,
                          item?.participants?.last_name
                        )
                      }
                      src={
                        item?.participants?.profile_picture
                          ? baseURLImage(item.participants?.profile_picture)
                          : getNameProfile(
                              item.participants?.first_name,
                              item.participants?.last_name || " "
                            )
                      }
                      alt='participant_profile_picture'
                      className='w-12 h-12 object-cover inline-block rounded-full'
                    /> */}
                    {/* <div className=''>
                      <h5
                        title={`${item.participants?.first_name} ${item.participants?.last_name}`}
                        className='text-blue-800 font-bold leading-20 text-sm capitalize'
                      >
                        {textTruncate(
                          `${item.participants?.first_name} ${item.participants?.last_name}`,
                          21,
                          20
                        )}
                      </h5>
                      <p
                        title={item?.participant?.email}
                        className='mb-0 text-sm text-gray-500 font-normal leading-7'
                      > */}
                    {/* [150] Domestic Assistance */}
                    {/* {textTruncate(item?.participant?.email, 18, 17)}
                      </p>
                    </div> */}
                  </div>
                </td>
                <td className='w-[50%] p-3 py-5'>
                  <div
                    onClick={() => setSelectedViewBreakdown(item.uuid)}
                    className='cursor-pointer text-left underline text-sm font-normal text-[#3B5894] leading-20'
                  >
                    View Breakdown
                  </div>
                  <div className='flex justify-start mt-1'>
                    <img src={greenCircleIcon} alt='green circle' />
                    <span className='ml-1 text-gray-500 text-sm font-normal leading-20'>
                      {moment(item.time_sheet_date).format("ddd, D/MM ")}
                      {moment(item.start_time).format("| hh:mm a ")}
                      {moment(item.end_time).format("- hh:mm a | ")}
                      {item.timesheet_length &&
                        item.timesheet_length.includes(":") &&
                        `${item.timesheet_length.split(":")[0]}hr ${
                          item.timesheet_length.split(":")[1]
                        }mins`}
                    </span>
                  </div>
                </td>
                <td className='w-[5%] p-3 py-5 capitalize'>
                  {getTimesheetProgressLabel(item.progress)}
                </td>
                <td className='w-[5%] p-3 py-5'>
                  <div className='w-full flex justify-center'>
                    {getExportPayrollIcon(item)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {selectedViewBreakdown !== null && (
        <ViewBreakdownTimeSheetModal
          singleTimesheetDetails={singleTimesheetDetails}
          singleTimesheetDetailsLoader={singleTimesheetDetailsLoader}
          singleTimesheetDetailsRequest={singleTimesheetDetailsRequest}
          isShow={selectedViewBreakdown !== null}
          handleClose={() => {
            setSelectedViewBreakdown(null);
          }}
          selectedUUID={selectedViewBreakdown}
        />
      )}
    </div>
  );
};

export default ExportSelectedTimesheetItem;
