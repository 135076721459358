import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import SelectOption from "components/common/Select/Select";
import SwitchInput from "components/common/Input/SwitchInput";
import ServiceSearch from "./ServiceSearch";
import {
  DUMMY_ADD_SERVICE_PROVIDER_VALUE,
  DUMMY_REGEX_EXP,
  LOADER_STATUS,
  REGEX_HHH_MM,
  planManagedByOptions,
} from "Constants/constant";
import api from "store/services/cos.services";
import { useNavigate } from "react-router";
import { convertStringyfyIntoJSON, gethoursAndMins } from "Utils/utils";
import { NumericFormat } from "react-number-format";
import { isArray } from "lodash";
import { LoginContext } from "helpers/LoginContext";

const ServiceSupportModal = (props) => {
  const {
    openModal,
    closeModal,
    serviceSupporitemEdit,
    funding,
    externalSps,
    participant,
    catalogStates,
    serviceSupportCreate,
    createServiceSupportLoader,
    createServiceSupportError,
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [catalogState, setCatalogState] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [managementStyle, setManagementStyle] = useState(null);
  const [alternativeRates, setAlternativeRates] = useState(false);
  const [externalServiceProvider, setExternalServiceProvider] = useState(null);
  const [ndisRate, setNdisRate] = useState(null);
  const [rateAmount, setRateAmount] = useState(null);
  const [alternativeServices, setAlternativeServices] = useState(null);
  const [alternativeServicesRateLoader, setAlternativeServicesRateLoader] =
    useState(false);
  const [createLoader, setCreateLoader] = useState(LOADER_STATUS.NA);

  const navigate = useNavigate();

  // console.log("serviceSupporitemEdit", serviceSupporitemEdit);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const watchFormData = watch();

  // console.log("watchFormData", watchFormData);
  // console.log("erros", errors);

  useEffect(() => {
    if (
      createLoader !== LOADER_STATUS.NA &&
      !createServiceSupportLoader &&
      createServiceSupportError.length === 0
    ) {
      setCreateLoader(LOADER_STATUS.COMPLATE);
      handleClose();
      reset();
      clearErrors();
    }
  }, [createServiceSupportLoader]);

  useEffect(() => {
    if (serviceSupporitemEdit) {
      clearErrors();
      setAllEditableServiceSupport();
    }
  }, [serviceSupporitemEdit]);

  useEffect(() => {
    if (
      serviceSupporitemEdit === null &&
      participant.state_id &&
      catalogStates &&
      alternativeRates === false
    ) {
      const stateObj = catalogStates.find(
        (x) => x.value === participant.state_id
      );
      setCatalogState({ ...stateObj });
      setValue("state_id", participant.state_id);
      clearErrors("state_id");
    }
  }, [participant, catalogStates]);

  useEffect(() => {
    if (externalServiceProvider && externalServiceProvider.value) {
      setValue("external_sp_id", externalServiceProvider.value);
      clearErrors("external_sp_id");
    }
  }, [externalServiceProvider]);

  useEffect(() => {
    if (serviceSupporitemEdit === null) {
      const parsePlanBy = convertStringyfyIntoJSON(funding.funding_management);
      setManagementStyle(
        isArray(parsePlanBy) && parsePlanBy.length > 0
          ? parsePlanBy[0]
          : parsePlanBy
      );
    }
  }, [funding]);

  const isExternalSpCatBudget = () => {
    if (
      serviceSupporitemEdit === null &&
      selectedService &&
      selectedService.service_sub_category &&
      selectedService.service_sub_category.sp_category_budgets &&
      selectedService.service_sub_category.sp_category_budgets.length > 0
    ) {
      if (
        selectedService.service_sub_category.sp_category_budgets.length === 1
      ) {
        return {
          label:
            selectedService.service_sub_category.sp_category_budgets[0]
              .external_sp.name,
          value:
            selectedService.service_sub_category.sp_category_budgets[0]
              .external_sp.id,
        };
      }
      return false;
    } else if (
      serviceSupporitemEdit &&
      serviceSupporitemEdit !== null &&
      serviceSupporitemEdit.external_sp
    ) {
      return {
        label: serviceSupporitemEdit.external_sp.name,
        value: serviceSupporitemEdit.external_sp.id,
      };
    }
    return false;
  };

  const getSelectedServiceExternalSpsData = () => {
    if (
      selectedService &&
      selectedService.service_sub_category &&
      selectedService.service_sub_category.sp_category_budgets &&
      selectedService.service_sub_category.sp_category_budgets.length > 0
    ) {
      return selectedService.service_sub_category.sp_category_budgets.map(
        (x) => {
          return {
            label: x.external_sp.name,
            value: x.external_sp.id,
          };
        }
      );
    }
    return [];
  };

  const setAllEditableServiceSupport = () => {
    if (serviceSupporitemEdit) {
      if (serviceSupporitemEdit.price_catalog) {
        setSelectedService(serviceSupporitemEdit.price_catalog);
      }

      const parsePlanBy = convertStringyfyIntoJSON(
        serviceSupporitemEdit.manged_by
      );
      setManagementStyle(
        isArray(parsePlanBy) && parsePlanBy.length > 0
          ? parsePlanBy[0]
          : parsePlanBy
      );
      const stateObj = catalogStates.find(
        (x) => x.value === serviceSupporitemEdit.state_id
      );
      setCatalogState({ ...stateObj });
      setValue("state_id", serviceSupporitemEdit.state_id);
      clearErrors("state_id");
      setAlternativeRates(serviceSupporitemEdit.is_alternative_rate);

      setNdisRate(
        serviceSupporitemEdit.alternative_rate &&
          parseInt(serviceSupporitemEdit.alternative_rate) > 0
          ? {
              label: "Apply alternative Rate",
              value: "Apply alternative Rate",
            }
          : {
              label: `$${parseFloat(
                serviceSupporitemEdit.original_rate
              ).toFixed(2)}`,
              value: serviceSupporitemEdit.original_rate,
            }
      );

      if (serviceSupporitemEdit.external_sp) {
        setValue("external_sp_id", serviceSupporitemEdit.external_sp.id);
      }

      if (serviceSupporitemEdit && serviceSupporitemEdit.unit === "H") {
        setValue(
          "quantity",
          gethoursAndMins(
            serviceSupporitemEdit.total_hours,
            serviceSupporitemEdit.total_mint
          )
        );
      } else {
        setValue("quantity", serviceSupporitemEdit.quantity);
      }

      setRateAmount(
        serviceSupporitemEdit.alternative_rate &&
          parseInt(serviceSupporitemEdit.alternative_rate) > 0
          ? parseFloat(serviceSupporitemEdit.alternative_rate)
          : parseFloat(serviceSupporitemEdit.original_rate)
      );

      setValue("note", serviceSupporitemEdit.note);
    }
  };

  useEffect(() => {
    if (selectedService) {
      setValue("price_catalog_id", selectedService.id);
      setValue("unit", selectedService.unit || "E");
      if (isExternalSpCatBudget() !== false) {
        setExternalServiceProvider(isExternalSpCatBudget());
      }
      clearErrors("price_catalog_id");
      clearErrors("unit");
    } else {
      if (serviceSupporitemEdit === null) {
        setAlternativeRates(false);
        setValue("ndis_rate", null);
        setNdisRate(null);
        setExternalServiceProvider(null);
      }
    }
  }, [selectedService]);

  const calculateRateAmount = () => {
    if (
      ndisRate &&
      ndisRate !== null &&
      ndisRate.value !== "Apply alternative Rate"
    ) {
      setValue("ndis_rate", parseFloat(ndisRate.value));
      setRateAmount(parseFloat(ndisRate.value));
      clearErrors("ndis_rate");
    } else if (
      ndisRate &&
      ndisRate !== null &&
      ndisRate.value === "Apply alternative Rate"
    ) {
      setValue("ndis_rate", -1);
      clearErrors("ndis_rate");
      // setValue("rate_amount", null);
      // setRateAmount(null);
    }
  };

  useEffect(() => {
    calculateRateAmount();
  }, [ndisRate]);

  useEffect(() => {
    if (managementStyle) {
      setValue("management_style", managementStyle);
      clearErrors("management_style");
    } else if (
      !managementStyle ||
      (isArray(managementStyle) && managementStyle.length === 0)
    ) {
      setValue("management_style", null);
    }
  }, [managementStyle]);

  useEffect(() => {
    calculateRateAmount();
    if (
      (parseInt(watchFormData.quantity, 10) > 0 &&
        rateAmount &&
        selectedService) ||
      (selectedService && selectedService.unit === "H")
    ) {
      const { unit } = selectedService;
      if (
        unit === "E" ||
        unit === "W" ||
        unit === "D" ||
        unit === "M" ||
        unit === "YR"
      ) {
        setValue(
          "total_amount",
          `$${parseFloat(
            parseFloat(watchFormData.quantity) * rateAmount
          ).toFixed(2)}`
        );
      } else if (
        watchFormData.quantity &&
        unit === "H" &&
        REGEX_HHH_MM.test(watchFormData.quantity)
      ) {
        const time = watchFormData.quantity.split(":");
        const hoursRate = parseFloat(rateAmount * parseInt(time[0], 10));
        const minutes = parseFloat(parseInt(time[1], 10) / 60);
        const minutesRate = parseFloat(minutes * rateAmount);
        setValue(
          "total_amount",
          `$${parseFloat(hoursRate + minutesRate).toFixed(2)}`
        );
      } else {
        setValue("total_amount", "$0");
      }
    } else if (!watchFormData.quantity || watchFormData.quantity.length === 0) {
      setValue("total_amount", "$0");
    } else setValue("total_amount", "$0");
    setValue("rate_amount", rateAmount);
    clearErrors("rate_amount");

    const isValid = getValidionQuantity() instanceof RegExp;
    if (!isValid) clearErrors("quantity");
  }, [watchFormData.quantity, rateAmount, selectedService]);

  const NdisRateOptions = () => {
    if (selectedService && alternativeServices === null) {
      return [
        {
          label: "Apply alternative Rate",
          value: "Apply alternative Rate",
        },
        {
          label: `$${parseFloat(selectedService.price).toFixed(2)}`,
          value: selectedService.price,
        },
      ];
    } else if (selectedService && alternativeServices) {
      return [
        {
          label: "Apply alternative Rate",
          value: "Apply alternative Rate",
        },
        {
          label: `$${parseFloat(alternativeServices.price).toFixed(2)}`,
          value: alternativeServices.price,
        },
      ];
    }
    return [];
  };

  useEffect(() => {
    if (participant.state_id && catalogStates && alternativeRates === false) {
      const stateObj = catalogStates.find(
        (x) => x.value === participant.state_id
      );
      setCatalogState({ ...stateObj });
      setAlternativeRates(null);
      setValue("alternative_rate", null);
    } else setValue("alternative_rate", alternativeRates);
  }, [alternativeRates]);

  const handleClose = () => {
    closeModal();
  };

  const getValidionQuantity = () => {
    const quantity = parseInt(watchFormData.quantity, 10);
    if (selectedService && watchFormData.quantity) {
      switch (selectedService.unit) {
        case "E":
        case "W":
          return /^[1-9]\d*$/i.test(quantity) ? false : DUMMY_REGEX_EXP;
        case "D":
          return /^(?!0)(?!\d{4}$)(?![4-9]\d{2}$)(?!3[7-9]\d$)(?!36[6-9]$)\d*$/i.test(
            quantity
          )
            ? false
            : DUMMY_REGEX_EXP;
        case "M":
          return /(^0?[1-9]$)|(^1[0-2]$)/i.test(quantity)
            ? false
            : DUMMY_REGEX_EXP;
        // case "W":
        //   return /^[1-7]$/i.test(quantity) ? false : DUMMY_REGEX_EXP;
        case "YR":
          return /(^0?[1-9]$)|(^1[0]$)/i.test(quantity)
            ? false
            : DUMMY_REGEX_EXP;
        case "H":
          return REGEX_HHH_MM.test(watchFormData.quantity)
            ? false
            : DUMMY_REGEX_EXP;
        default:
          return DUMMY_REGEX_EXP;
      }
    }
    return DUMMY_REGEX_EXP;
  };

  const getQuantityInputType = () => {
    if (selectedService) {
      const unit = selectedService.unit || "E";
      if (
        unit === "E" ||
        unit === "W" ||
        unit === "D" ||
        unit === "M" ||
        unit === "YR"
      ) {
        return "number";
      } else if (unit === "H") return "text";
    }
    return "number";
  };

  const getQuantityPlaceHolder = () => {
    if (!selectedService) return "";
    const { unit } = selectedService;
    switch (unit) {
      case "E":
      case "W":
        return "123...";
      case "D":
        return "0-365";
      // case "W":
      //   return "1-7";
      case "M":
        return "1-12";
      case "YR":
        return "1-10";
      case "H":
        return "HH:MM";
      default:
        return "";
    }
  };

  const getQuantityValidationError = () => {
    if (selectedService) {
      switch (selectedService.unit) {
        case "E":
        case "W":
          return "quantity should be integer number";
        case "M":
          return "quantity should be 1-12 number";
        // case "W":
        //   return "quantity should be 1-7 number";
        case "D":
          return "quantity should be 1-365 number";
        case "YR":
          return "quantity should be 1-10 number";
        case "H":
          return /^(?:0{0,2}:)?0{0,2}$/i.test(watchFormData.quantity)
            ? "quantity must be greater than (000:00 - 00:00 - 0:00)"
            : "quanity should be valid format (HHH:MM - HH:MM - H:MM)";
        default:
          return "field is required";
      }
    }
    return "field is required";
  };

  useEffect(() => {
    if (catalogState && alternativeRates === true && selectedService) {
      getServicePriceAgainstState();
    }
  }, [catalogState]);

  const getServicePriceAgainstState = async () => {
    if (funding && catalogState && selectedService) {
      try {
        setAlternativeServicesRateLoader(true);
        const payload = {
          funding_id: funding.uuid,
          state_id: catalogState.value,
          support_item_number: selectedService.support_item_number,
        };
        if (participant) {
          Object.assign(payload, {
            pt_id: participant.id,
          });
        }
        const response = await api.getServicePriceAgainstState(payload);
        if (response.price_catalog) {
          setAlternativeServices({ ...response.price_catalog });
        }
        setAlternativeServicesRateLoader(false);
      } catch (err) {
        setAlternativeServicesRateLoader(false);
        console.log("err", err);
      }
    }
  };

  useEffect(() => {
    if (
      watchFormData.total_amount &&
      parseFloat(watchFormData.total_amount.replace("$", "")).toString() > 0
    ) {
      clearErrors("total_amount");
    }
  }, [watchFormData.total_amount]);

  useEffect(() => {
    const isValid = getValidionQuantity() instanceof RegExp;
    if (!isValid) clearErrors("quantity");
  }, [getValidionQuantity()]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    Object.assign(data, {
      management_style: isArray(data.management_style)
        ? JSON.stringify(data.management_style)
        : JSON.stringify([data.management_style]),
      alternative_rate:
        data.alternative_rate === null ? false : data.alternative_rate,
      funding_id: funding.id,
      total_amount: data.total_amount.replace("$", ""),
      original_price: selectedService.price,
    });
    if (serviceSupporitemEdit) {
      Object.assign(data, {
        service_support_id: serviceSupporitemEdit.uuid,
      });
    }
    if (participant && participant?.id) {
      Object.assign(data, {
        pt_id: participant.id,
      });
    }
    setCreateLoader(LOADER_STATUS.PENDING);
    serviceSupportCreate({
      data,
      isUpdate: serviceSupporitemEdit ? true : false,
    });
  };

  const getUnitAbb = () => {
    if (!selectedService) return "Quantity";
    const { unit } = selectedService;
    switch (unit) {
      case "E":
        return "Quantity";
      case "D":
        return "Days";
      case "W":
        return "Weeks";
      case "M":
        return "Months";
      case "YR":
        return "Years";
      case "H":
        return "Hours";
      default:
        return "Quantity";
    }
  };

  const modalFooter = () => (
    <CustomButton
      showLoading={createServiceSupportLoader}
      isDisabled={
        alternativeServicesRateLoader ||
        createServiceSupportLoader ||
        isUserGracePeriodOver
      }
      label={serviceSupporitemEdit ? "Update" : "Submit"}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("serviceSupportButtonModal");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  const isDisabledAfterDelivered = () => {
    if (
      serviceSupporitemEdit &&
      parseFloat(serviceSupporitemEdit.remain_amount) !==
        parseFloat(serviceSupporitemEdit.total_budget_amount)
    ) {
      return true;
    }
    return false;
  };

  return (
    <ModalSkeleton
      modalFooter={modalFooter()}
      isOpen={openModal}
      closeModal={handleClose}
      cssClass='w-[60%]'
      title={
        serviceSupporitemEdit
          ? "Update Service Support Budget"
          : "Create New Service Support Budget"
      }
    >
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500 mb-2'>
          Please enter the following details to
          {serviceSupporitemEdit ? " update" : " create"} service support
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Funding ID
              </label>
              <input
                {...register("funding_id", {
                  required: true,
                  value: funding.contract_id,
                })}
                style={{
                  height: "38px",
                  backgroundColor: "#4b6bb710",
                }}
                value={funding.contract_id}
                disabled
                readOnly
                name='funding_id'
                id=''
                className='did-floating-input mt-1 bg-[#4b6bb710]'
              />
            </div>

            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Additional Rates
              </label>
              <div className='flex justify-between w-full'>
                <div className='w-[85%]'>
                  <input
                    {...register("alternative_rate")}
                    style={{
                      height: "38px",
                      backgroundColor: "#4b6bb710",
                    }}
                    value='Apply Additional Rates'
                    disabled
                    readOnly
                    className='did-floating-input mt-1 bg-[#4b6bb710]'
                  />
                </div>
                <div className='w-[15%] mt-1 ml-1'>
                  <SwitchInput
                    isDisabled={!selectedService || isDisabledAfterDelivered()}
                    cssClass='mt-1'
                    handleChange={setAlternativeRates}
                    checked={alternativeRates}
                    label=''
                    subLabel=''
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <div className='hidden'>
                <input
                  {...register("price_catalog_id", {
                    required: true,
                  })}
                  name='price_catalog_id'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Search NDIS Price Catalog
              </label>
              <ServiceSearch
                setSelectedService={(e) => {
                  if (e !== null) {
                    setSelectedService(e);
                  } else {
                    setSelectedService(null);
                    setValue("price_catalog_id", null);
                  }
                }}
                placeHolder='Search NDIS Price Catalog by typing here...'
                serviceSupporitemEdit={serviceSupporitemEdit}
                funding={funding}
                state_id={catalogState ? catalogState.value : null}
              />
              {errors?.price_catalog_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("management_style", {
                    required: true,
                  })}
                  name='management_style'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Management Style
              </label>
              <SelectOption
                isDisabled={serviceSupporitemEdit === null ? false : true}
                cssClass='mt-1'
                isSearch={false}
                data={planManagedByOptions}
                selectedOption={managementStyle}
                handleChange={setManagementStyle}
                // placeHolder="Select Management Style..."
              />
              {errors.management_style && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("external_sp_id", {
                    // required: true,
                    required: false,
                  })}
                  name='external_sp_id'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Service Provider
              </label>
              <SelectOption
                isDisabled={
                  isExternalSpCatBudget() === false && selectedService
                    ? false
                    : true
                }
                cssClass='mt-1'
                isSearch={true}
                data={
                  getSelectedServiceExternalSpsData().length > 0
                    ? getSelectedServiceExternalSpsData()
                    : externalSps
                }
                selectedOption={externalServiceProvider}
                handleChange={(e) => {
                  if (e.value === DUMMY_ADD_SERVICE_PROVIDER_VALUE) {
                    navigate("/cos/external-service-provider");
                  } else {
                    setExternalServiceProvider(e);
                  }
                }}
                placeHolder={
                  isExternalSpCatBudget() === false && selectedService
                    ? "Service Provider Not Selected!"
                    : "Select..."
                }
              />
              {errors?.external_sp_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className='hidden'>
                <input
                  {...register("state_id", {
                    value: false,
                    required: true,
                  })}
                  name='state_id'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                State
              </label>
              <SelectOption
                isDisabled={!alternativeRates}
                isSearch={false}
                data={catalogStates}
                selectedOption={catalogState}
                handleChange={setCatalogState}
                cssClass='mt-1'
                placeHolder='Select Service Catalogue State...'
              />
              {errors.state_id && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <div className='hidden'>
                <input
                  {...register("ndis_rate", {
                    required: true,
                  })}
                  name='ndis_rate'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                NDIS Rate
              </label>
              <SelectOption
                isDisabled={
                  !selectedService ||
                  alternativeServicesRateLoader ||
                  isDisabledAfterDelivered()
                }
                isSearch={false}
                data={NdisRateOptions()}
                selectedOption={ndisRate}
                handleChange={setNdisRate}
                cssClass='mt-1'
                placeHolder={`${
                  alternativeServicesRateLoader
                    ? "Fetching Rates..."
                    : "Select NDIS Rate..."
                } `}
              />
              {errors.ndis_rate && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className='hidden'>
                <input
                  className='hidden'
                  {...register("rate_amount", { required: true })}
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Rate Amount
              </label>
              <div className='mt-1'>
                <input
                  disabled={
                    ndisRate && ndisRate.value === "Apply alternative Rate"
                      ? false
                      : true
                  }
                  value={rateAmount ? `$${rateAmount}` : ""}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    const numericVal = inputVal.replace(/[^\d.]/g, "");
                    if (/^\d*\.?\d*$/i.test(numericVal)) {
                      setRateAmount(numericVal);
                    }
                  }}
                  type='text'
                  className={`focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md
      ${
        ndisRate && ndisRate.value === "Apply alternative Rate"
          ? ""
          : "bg-[#4b6bb710]"
      }`}
                />

                {/* <NumericFormat
                  type="text"
                  value={rateAmount}
                  disabled={
                    ndisRate && ndisRate.value === "Apply alternative Rate"
                      ? false
                      : true
                  }
                  className={`focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md
                  ${
                    ndisRate && ndisRate.value === "Apply alternative Rate"
                      ? ""
                      : "bg-[#4b6bb710]"
                  }`}
                  prefix={"$"}
                  onChange={(e) => {
                    if (/^\d*\.?\d*$/i.test(e.target.value)) {
                      setRateAmount(e.target.value);
                    }
                  }}
                /> */}
                {errors?.rate_amount?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                {getUnitAbb()}
              </label>
              <div className='mt-1'>
                <input
                  {...register("quantity", {
                    required: true,
                    pattern: getValidionQuantity(),
                    onChange: (e) => {
                      if (getQuantityInputType() === "number") {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value, 10)
                        );
                      }
                    },
                  })}
                  value={watchFormData.quantity}
                  placeholder={getQuantityPlaceHolder()}
                  type={getQuantityInputType()}
                  name='quantity'
                  id='quantity'
                  disabled={
                    selectedService === null || isDisabledAfterDelivered()
                  }
                  className={`${
                    (selectedService === null) | isDisabledAfterDelivered() &&
                    "bg-[#4b6bb710]"
                  } focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                />
                {getValidionQuantity() instanceof RegExp &&
                  errors?.quantity?.type === "pattern" && (
                    <span className='text-sm text-red-500'>
                      {getQuantityValidationError()}
                    </span>
                  )}
                {errors?.quantity?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Total Amount
              </label>
              <div className='mt-1'>
                <input
                  {...register("total_amount", {
                    required: true,
                    validate: (value) =>
                      parseFloat(value.replace("$", "")).toString() > 0,
                  })}
                  // placeholder="0.00"
                  disabled
                  readOnly
                  className='bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                />
                {errors?.total_amount?.type === "pattern" && (
                  <span className='text-sm text-red-500'>
                    Please enter a valid amount
                  </span>
                )}
                {errors?.total_amount?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
                {errors?.total_amount?.type === "validate" && (
                  <span className='text-sm text-red-500'>
                    Total amount must be greater than zero!
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <label className='block text-sm font-medium text-gray-700'>
                Notes
              </label>
              <div className='mt-1'>
                <textarea
                  {...register("note", {
                    required: false,
                  })}
                  name='note'
                  rows={3}
                  className='resize-none overflow-y-auto focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                />
              </div>
              {errors?.notes?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <button
            type='submit'
            className='hidden sr-only'
            id='serviceSupportButtonModal'
          />
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default ServiceSupportModal;
