import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { apiPutRequest } from "../../../../helpers/Requests";
import { toast } from "react-toastify";
import RiskRatingModal from "./RiskRatingModal";
import SelectOption from "../../../common/Select/Select";
import {
  RISK_IMPACT_OPTIONS,
  RISK_LIKELEHOOD_OPTIONS,
  USER_ROLE,
} from "../../../../Constants/constant";
import _ from "lodash";
import Loader from "../../../common/Loader/Loader";
import { roleAccess } from "helpers/RolesPermission";

const RadioComponent = (props) => {
  const {
    index,
    field,
    viewFormData,
    formUUID,
    fieldIndex,
    sectionIndex,
    subSectionIndex,
    handleChangeFormData,
    isReadOnly = false,
    isUserGracePeriodOver,
  } = props;
  const [open, setOpen] = useState(false);
  const [impact, setImpact] = useState(null);
  const [likleHood, setLikleHood] = useState(null);
  const [riskRating, setRiskRating] = useState(
    field.hasOwnProperty("riskRating") ? field.riskRating : ""
  );

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const checkRiskMitigated = () => {
    return (
      field.hasOwnProperty("mitigationStratgey") &&
      field.mitigationStratgey &&
      field.mitigationStratgey.riskMigrated
    );
  };

  useEffect(() => {
    if (!impact || !impact.value || !likleHood || !likleHood.value) {
      return;
    }
    if (
      (impact.value === "Negligible" && likleHood.value === "Very unlikely") ||
      (impact.value === "Minor" && likleHood.value === "Very unlikely") ||
      (impact.value === "Negligible" && likleHood.value === "Unlikely") ||
      (impact.value === "Negligible" && likleHood.value === "Possible") ||
      (impact.value === "Negligible" && likleHood.value === "Likely")
    ) {
      setRiskRating("Low");
    } else if (
      (impact.value === "Moderate" && likleHood.value === "Very unlikely") ||
      (impact.value === "Minor" && likleHood.value === "Unlikely") ||
      (impact.value === "Moderate" && likleHood.value === "Unlikely") ||
      (impact.value === "Minor" && likleHood.value === "Possible") ||
      (impact.value === "Minor" && likleHood.value === "Likely") ||
      (impact.value === "Negligible" && likleHood.value === "Very Likely")
    ) {
      setRiskRating("Low Medium");
    } else if (
      (impact.value === "Significant" && likleHood.value === "Very unlikely") ||
      (impact.value === "Sever" && likleHood.value === "Very unlikely") ||
      (impact.value === "Significant" && likleHood.value === "Unlikely") ||
      (impact.value === "Moderate" && likleHood.value === "Possible") ||
      (impact.value === "Moderate" && likleHood.value === "Likely") ||
      (impact.value === "Minor" && likleHood.value === "Very Likely")
    ) {
      setRiskRating("Medium");
    } else if (
      (impact.value === "Sever" && likleHood.value === "Unlikely") ||
      (impact.value === "Significant" && likleHood.value === "Possible") ||
      (impact.value === "Sever" && likleHood.value === "Possible") ||
      (impact.value === "Significant" && likleHood.value === "Likely") ||
      (impact.value === "Moderate" && likleHood.value === "Very Likely")
    ) {
      setRiskRating("Medium High");
    } else if (
      (impact.value === "Sever" && likleHood.value === "Likely") ||
      (impact.value === "Significant" && likleHood.value === "Very Likely") ||
      (impact.value === "Sever" && likleHood.value === "Very Likely")
    ) {
      setRiskRating("High");
    }
  }, [likleHood, impact]);

  useEffect(() => {
    if (field.hasOwnProperty("impact")) {
      setImpact(field.impact && field.impact.value ? field.impact : null);
    }
    if (field.hasOwnProperty("likleHood")) {
      setLikleHood(
        field.likleHood && field.likleHood.value ? field.likleHood : null
      );
    }
    if (field.hasOwnProperty("riskRating")) {
      setRiskRating(field.riskRating ? field.riskRating : "");
    }
  }, [field]);

  useEffect(() => {
    if (
      riskRating &&
      field.hasOwnProperty("riskRating") &&
      riskRating === field.riskRating
    ) {
      return;
    }
    if (impact && impact.value && likleHood && likleHood.value) {
      const obj = _.cloneDeep(viewFormData);
      if (subSectionIndex !== null) {
        Object.assign(
          obj[sectionIndex].input_fields[fieldIndex].sub_fields[
            subSectionIndex
          ],
          { riskRating: riskRating },
          { impact },
          { likleHood }
        );
        updateRiskRating([...obj]);
      } else {
        Object.assign(
          obj[sectionIndex].input_fields[fieldIndex],
          { riskRating: riskRating },
          { impact },
          { likleHood }
        );
        updateRiskRating([...obj]);
      }
      handleChangeFormData([...obj]);
    }
  }, [riskRating]);

  const colorChange = (risk) => {
    if (risk === "High") {
      return "text-red-500";
    } else if (risk === "Medium High") {
      return "text-orange-500";
    } else if (risk === "Medium") {
      return "text-amber-500";
    } else if (risk === "Low Medium") {
      return "text-lime-500";
    } else if (risk === "Low") {
      return "text-green-500";
    }
  };

  const backColorChange = (risk) => {
    if (risk === "High") {
      return "bg-red-50";
    } else if (risk === "Medium High") {
      return "bg-orange-50";
    } else if (risk === "Medium") {
      return "bg-amber-50";
    } else if (risk === "Low Medium") {
      return "bg-lime-50";
    } else if (risk === "Low") {
      return "bg-green-50";
    }
  };

  const statusBackGroundColorChange = (field) => {
    if (checkRiskMitigated()) {
      return "bg-green-50";
    } else if (
      field.hasOwnProperty("mitigationStratgey") &&
      field.mitigationStratgey &&
      !field.mitigationStratgey.riskMigrated
    ) {
      return "bg-yellow-50";
    }
    return "bg-red-50";
  };

  const statusTextColorChange = (field) => {
    if (checkRiskMitigated()) {
      return "text-green-500";
    } else if (
      field.hasOwnProperty("mitigationStratgey") &&
      field.mitigationStratgey &&
      !field.mitigationStratgey.riskMigrated
    ) {
      return "text-yellow-500";
    }
    return "text-red-500";
  };

  const updateRiskRating = async (data) => {
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiPutRequest(
        `sp/participant/submitted-form-update?form_id=${formUUID}`,
        {
          template: data,
        }
      );
      if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        toast.success("Risk Rating updated Successfully!");
        return;
      }
      if (res.status !== 201) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      }
    } catch (err) {
      console.log(err);
      setRequest({ ...request, isRequested: false, isFailed: true });
    }
  };

  return (
    <>
      <tr key={index}>
        <td className='whitespace-nowrap11 py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6'>
          <div title={field.lable} className='space-y-2'>
            <p className='font-medium line-clamp-2'>{field.lable}</p>
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 pl-6 text-sm '>
          <button className=''>
            <span
              className={`text-sm inline-flex items-center px-3 py-0.5 rounded-full font-medium ${statusBackGroundColorChange(
                field
              )}`}
            >
              <div
                className={`${statusTextColorChange(
                  field
                )} flex items-center space-x-1`}
              >
                {checkRiskMitigated() ? (
                  <span>Closed</span>
                ) : field.hasOwnProperty("mitigationStratgey") &&
                  field.mitigationStratgey &&
                  !field.mitigationStratgey.riskMigrated ? (
                  <span>Mitigation Strategy</span>
                ) : (
                  <span>Open</span>
                )}
              </div>
            </span>
          </button>
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <div>
            <SelectOption
              selectedOption={likleHood}
              isSearch={false}
              data={RISK_LIKELEHOOD_OPTIONS}
              handleChange={(e) => setLikleHood(e)}
              isDisabled={
                (checkRiskMitigated() === true ? false : false) ||
                isReadOnly ||
                isUserGracePeriodOver
              }
            />
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <div>
            <SelectOption
              isSearch={false}
              data={RISK_IMPACT_OPTIONS}
              handleChange={(e) => setImpact(e)}
              selectedOption={impact}
              isDisabled={
                (checkRiskMitigated() === true ? false : false) ||
                isReadOnly ||
                isUserGracePeriodOver
              }
            />
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 pl-6 text-sm '>
          <button className=''>
            {request.isRequested ? (
              <div className='flex items-center'>
                <Loader isSmallView cssClass='text-black' />
              </div>
            ) : (
              <span
                className={`text-sm inline-flex items-center px-3 py-0.5 rounded-full font-medium ${backColorChange(
                  riskRating
                )}`}
              >
                <div
                  className={`${colorChange(
                    riskRating
                  )} flex items-center space-x-1`}
                >
                  <span>{riskRating}</span>
                </div>
              </span>
            )}
          </button>
        </td>
        {!roleAccess([USER_ROLE.Sp_Standard_User]) && (
          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
            <button
              onClick={() => {
                if (likleHood && impact && likleHood.value && impact.value) {
                  setOpen(true);
                } else {
                  toast("First Select Impact & Likelihood");
                }
              }}
            >
              <BsThreeDotsVertical />
            </button>
          </td>
        )}
      </tr>

      {open && (
        <RiskRatingModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          isReadOnly={isReadOnly}
          fieldIndex={fieldIndex}
          sectionIndex={sectionIndex}
          subSectionIndex={subSectionIndex}
          previousData={
            field.hasOwnProperty("mitigationStratgey")
              ? field.mitigationStratgey
              : null
          }
          show={open}
          setOpen={setOpen}
          fieldLabel={field.lable}
          fieldValue={field.value ? field.value : ""}
          viewFormData={viewFormData}
          formUUID={formUUID}
          handleChangeFormData={handleChangeFormData}
          isDisabled={checkRiskMitigated() === true ? false : false}
        />
      )}
    </>
  );
};

export default RadioComponent;
