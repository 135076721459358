import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { textTruncate } from "../../../Utils/utils";
import "./slider.css";

const FilterSlider = (props) => {
  const { filterCatlog, handleFetch, setVersionId, versionId, stateId } = props;

  const [isActive, setIsActive] = useState(versionId);

  useEffect(() => setIsActive(versionId), [versionId]);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, right: 0 }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          left: 0,
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleCatlogVersion = (e) => {
    setIsActive(e);
    setVersionId(e);
    handleFetch({ page: 1, version_id: e, state_id: stateId });
  };

  return (
    <Slider {...settings}>
      {filterCatlog && filterCatlog.length > 1
        ? filterCatlog.map((catlog, index) => (
            <div
              className="flex justify-center items-center font-normal "
              key={index}
              title={catlog?.label}
            >
              <button
                className={`${
                  isActive === catlog.value ? "bg-blue-300" : "bg-blue-200"
                } text-blue-800 py-2 px-2 text-xs font-normal leading-none border border-gray-30 flex-initial rounded-lg`}
                onClick={() => handleCatlogVersion(catlog?.value)}
              >
                {textTruncate(catlog?.label, 35, 34)}
              </button>
            </div>
          ))
        : ""}
    </Slider>
  );
};

export default FilterSlider;
