import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import CheckBox from "components/common/checkBox/checkbox";
import { getWorkerTimeSheetsList } from "store/selector/rostering.selector";
import { TIMESHEET_STATUS } from "Constants/constant";
import ApproveTimesheetItem from "./ApproveTimeSheetItem";
import RosteringAPI from "store/services/rostering.services";
import SweetAlertConfirmationModal from "components/common/SweetAlertConfirmation/SweetAlertConfirmation";
import { timeSheetStatusChanged } from "store/actions/rostering.actions";
import SelectOption from "components/common/Select/Select";
import { LoginContext } from "helpers/LoginContext";

const ApproveTimeSheetModal = (props) => {
  const { isShow, handleClose } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const dispatch = useDispatch();

  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [workerOptions, setWorkerOptions] = useState([]);
  const [selectedWorkerOption, setSelectedWorkerOption] = useState({
    value: "all",
    label: "All",
  });

  const data = useSelector((state) => getWorkerTimeSheetsList(state));

  const pendingTimesheets = useMemo(() => {
    if (data.ttlPendingTimeSheets > 0) {
      let workerArray = [];

      const reduceData = data.timeSheets.reduce((obj, item) => {
        if (selectedWorkerOption.value === "all") {
          Object.assign(obj, {
            allPendingTimeSheets: obj["allPendingTimeSheets"]
              ? [
                  ...obj.allPendingTimeSheets,
                  ...item.timeSheet.pendingTimeSheets,
                ]
              : [...item.timeSheet.pendingTimeSheets],
          });
        } else if (
          selectedWorkerOption.value !== "all" &&
          selectedWorkerOption.value === item.uuid
        ) {
          Object.assign(obj, {
            allPendingTimeSheets: obj["allPendingTimeSheets"]
              ? [
                  ...obj.allPendingTimeSheets,
                  ...item.timeSheet.pendingTimeSheets,
                ]
              : [...item.timeSheet.pendingTimeSheets],
          });
        }

        if (item.timeSheet.pendingTimeSheets.length > 0) {
          workerArray.push({
            value: item.uuid,
            label: `${item.first_name} ${item.last_name}`,
          });
        }
        return obj;
      }, {});
      setWorkerOptions([{ value: "all", label: "All" }, ...workerArray]);
      return reduceData?.allPendingTimeSheets || [];
    }
    return [];
  }, [data, selectedWorkerOption]);

  const handleApproveTimeSheet = async () => {
    if (checkboxes.length === 0) {
      toast("No any timesheet selected!");
      return;
    }
    const payload = {
      uuid: checkboxes.map((x) => x.uuid),
      status: TIMESHEET_STATUS.APPROVED,
    };

    try {
      setIsLoading(true);
      const response = await RosteringAPI.changeTimesheetStatus(payload);
      if (response.message) {
        dispatch(
          timeSheetStatusChanged.success({
            data: {
              approvalSheets: checkboxes.map((x) => ({
                uuid: x.uuid,
                parent_uuid: x.parent_uuid,
              })),
              status: TIMESHEET_STATUS.APPROVED,
            },
            approveAll: true,
          })
        );
        toast.success(`Timesheet Approved Successfully!`);
      }
      setIsLoading(false);
      handleClose();
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
      toast.error(err?.message ?? "Something went wrong!");
    }
  };

  const toggleCheckboxes = (val) => {
    val ? setCheckboxes(pendingTimesheets.map((s) => s)) : setCheckboxes([]);
  };

  useEffect(() => {
    if (
      pendingTimesheets &&
      checkboxes &&
      checkboxes.length === pendingTimesheets.length &&
      pendingTimesheets.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);

  const handleCheckboxChange = (e, uuid, item) => {
    const filteredList = checkboxes.filter((c) => c.uuid !== uuid);
    if (e) {
      filteredList.push({ ...item });
    }
    setCheckboxes([...filteredList]);
  };

  const modalFooter = () => (
    <>
      <CustomButton
        variant='danger'
        clickHandler={handleClose}
        label='Cancel'
      />
      <CustomButton
        clickHandler={() =>
          !isUserGracePeriodOver && checkboxes.length > 0 && setIsConfirm(true)
        }
        showLoading={isLoading}
        isDisabled={
          isLoading ||
          pendingTimesheets.length === 0 ||
          checkboxes.length === 0 ||
          isUserGracePeriodOver
        }
        label='Approve'
      />
    </>
  );

  const ApproveTimeSheetModalContent = () => (
    <div className='min-w-full p-4 py-0'>
      {pendingTimesheets && pendingTimesheets.length > 0 && (
        <div className='py-4 flex justify-between w-full'>
          <div>
            <h4>
              <b>{`Total Timesheet${
                pendingTimesheets.length > 0 ? "s" : ""
              }: `}</b>
              {pendingTimesheets.length}
            </h4>
          </div>
          <div className='w-1/3'>
            <SelectOption
              isSearch={true}
              selectedOption={selectedWorkerOption}
              handleChange={setSelectedWorkerOption}
              data={workerOptions}
            />
          </div>
        </div>
      )}
      <div className='overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-300 border'>
          <thead className='capitalize'>
            <tr>
              <th
                scope='col'
                className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50 w-[10%]`}
              >
                <CheckBox
                  checked={allSelected}
                  handleChange={(e) => {
                    if (pendingTimesheets && pendingTimesheets.length > 0) {
                      setAllSelected(e);
                      toggleCheckboxes(e);
                    }
                  }}
                />
              </th>
              <TableHeader isSort={false} label={"Worker name"} />
              <TableHeader
                cssClass='text-center'
                isSort={false}
                label={"Timesheet date"}
              />
              <TableHeader
                cssClass='text-center'
                isSort={false}
                label={"Shift length"}
              />
              <TableHeader
                cssClass='text-center'
                isSort={false}
                label={"Start time - End time"}
              />
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {false ? (
              <tr>
                <td colSpan={6} className='bg-gray-50 py-2'>
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                </td>
              </tr>
            ) : pendingTimesheets && pendingTimesheets.length > 0 ? (
              pendingTimesheets.map((timeSheet, index) => (
                <ApproveTimesheetItem
                  timeSheet={timeSheet}
                  handleCheckboxChange={(e) =>
                    handleCheckboxChange(e, timeSheet.uuid, timeSheet)
                  }
                  checkboxes={checkboxes}
                  key={index}
                />
              ))
            ) : (
              pendingTimesheets.length === 0 && (
                <tr>
                  <td colSpan={7} className='py-6 text-center text-red-600'>
                    No Data Found !
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div>
      {isShow && (
        <ModalSkeleton
          title='Timesheet Approval'
          isOpen={isShow}
          cssClass='w-[55%]'
          bodyPadding='px-0'
          closeModal={handleClose}
          modalFooter={modalFooter()}
        >
          {false ? (
            <div
              className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
            >
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            ApproveTimeSheetModalContent()
          )}
        </ModalSkeleton>
      )}
      {isConfirm && (
        <SweetAlertConfirmationModal
          isOpen={isConfirm}
          title='Timesheet approval'
          content={`Are you sure you want to approve timesheet${
            checkboxes.length > 1 ? "s" : ""
          }?`}
          dangerMode={true}
          setIsConfirm={(value) => {
            if (value === true) {
              setIsConfirm(false);
              handleApproveTimeSheet();
            } else {
              setIsConfirm(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default ApproveTimeSheetModal;
