// import React, { useEffect } from "react";
// import swal from "sweetalert";

// const SweetAlertConfirmationModal = (props) => {
//   const {
//     title,
//     content,
//     dangerMode = false,
//     setIsConfirm,
//     isOpen = false,
//     info = "",
//   } = props;

//   const handleChangeAction = () => {
//     swal({
//       title: title,
//       text: content,

//       icon: info,
//       buttons: ["No, take me back!", "Yes, go ahead!"],
//       className: "",
//       cancel: {
//         text: "No, take me back!",
//         value: null,
//         visible: false,
//         className: "",
//         closeModal: true,
//       },
//       confirm: {
//         text: "Yes, go ahead!",
//         value: true,
//         visible: true,
//         // className: `${
//         //   updatedAction === "cancelled" ? "bg-blue-600" "
//         // }`,
//         closeModal: true,
//       },
//       dangerMode: dangerMode,
//     }).then((willDelete) => {
//       if (willDelete) {
//         setIsConfirm(true);
//       } else {
//         setIsConfirm(false);
//       }
//     });
//   };

//   useEffect(() => {
//     if (isOpen) {
//       handleChangeAction();
//     }
//   }, [isOpen]);

//   return <></>;
// };

// export default SweetAlertConfirmationModal;

import ReactDOM from "react-dom";
import swal from "sweetalert";
import RecurringShiftSwitch from "pages/Rostering/WorkerShift/RecurringShiftSwitch";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

const SweetAlertConfirmationModal = (props) => {
  const {
    title,
    dangerMode = false,
    setIsConfirm,
    isOpen = false,
    shiftData = null,
    content,
    setIsRecurringShift,
    onlyOkBtn = null,
  } = props;

  const {
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const watchFormData = watch();

  const [recurringEffectedShiftCount, setRecurringEffectedShiftCount] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const handleChangeAction = () => {
    if (content !== null) {
      swal({
        title: title,
        text: content,
        buttons: onlyOkBtn
          ? onlyOkBtn
          : ["No, take me back!", "Yes, go ahead!"],
        dangerMode: dangerMode,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        setIsConfirm(!!willDelete);
      });
    } else {
      const container = document.createElement("div");

      ReactDOM.render(
        <div>
          <RecurringShiftSwitch
            control={control}
            watchFormData={watchFormData}
            selectedShift={shiftData}
            setValue={setValue}
            recurringEffectedShiftCount={recurringEffectedShiftCount}
            setRecurringEffectedShiftCount={setRecurringEffectedShiftCount}
            setIsRecurringShift={setIsRecurringShift}
            setLoading={setLoading}
          />
          {recurringEffectedShiftCount !== null &&
            recurringEffectedShiftCount !== undefined && (
              <p className='mt-5 font-bold text-center'>
                {loading
                  ? "Loading..."
                  : recurringEffectedShiftCount > 0
                  ? `${recurringEffectedShiftCount} shift(s) will be deleted`
                  : "There are no other recurring shifts associated with this shift."}
              </p>
            )}
        </div>,
        container
      );

      swal({
        title: title,
        content: container,
        buttons: ["No, take me back!", "Yes, go ahead!"],
        dangerMode: dangerMode,
      }).then((willDelete) => {
        setIsConfirm(!!willDelete);
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleChangeAction();
    }
  }, [
    isOpen,
    recurringEffectedShiftCount,
    loading,
    watchFormData?.recurring_impact,
  ]);

  return null;
};

export default SweetAlertConfirmationModal;
