import React, { useEffect, useState, useContext } from "react";
import { BiPlus } from "react-icons/bi";
import { HiOutlineEye } from "react-icons/hi";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import Header from "components/common/Header/Header";
import SideBar from "components/common/SideBar/SideBarNew";
import TableHeader from "components/common/Table/TableHeader";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import { formatDateIntoDMYToDisplay, textTruncate } from "Utils/utils";

const NdisClaim = (props) => {
  const {
    claimedCaseNotesList,
    claimedCaseNotesListLoading,
    getClaimCaseNotesRequest,
  } = props;

  const [claimedList, setClaimedList] = useState([]);
  const { sideBarPadding } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    getClaimCaseNotesRequest();
  }, []);

  useEffect(() => {
    if (claimedCaseNotesList && claimedCaseNotesList) {
      setClaimedList(claimedCaseNotesList);
    }
  }, [claimedCaseNotesList]);

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className='h-screen flex-1 bg-white'>
          <Header
            title='NDIS Claims'
            handleClick={() => navigate("/cos/ndis-claims-new")}
            btnLabel='NDIS Claim'
            icon={<BiPlus />}
            isButton
          />
          <div className='sm:px-6 lg:px-8 py-4 max-w-7xl mx-auto'>
            <Breadcrumb
              pages={COS_BREADCRUMB(COS_BREADCRUMB_PAGES.CREATED_NDIS_CLAIMS)}
            />
          </div>
          <div className='max-w-7xl mx-auto py-3 bg-white'>
            <div className='flex flex-col sm:px-6 lg:px-8'>
              <div className='-my-2 -mx-4 11overflow:hidden sm:-mx-6 lg:-mx-8'>
                <div className='11inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                    <div className='table-wrp block'>
                      <table className='min-w-full divide-y divide-gray-300'>
                        <thead
                          className='bg-blue-50 sticky z-[5] capitalize'
                          style={{ top: "80px" }}
                        >
                          <tr>
                            <TableHeader
                              sortName='reference'
                              isSort={false}
                              label={"Reference"}
                            />
                            <TableHeader
                              isSort={false}
                              sortName='created_by'
                              label={"Created By"}
                            />
                            <TableHeader
                              isSort={false}
                              sortName='created_date'
                              label={"Created Date"}
                            />
                            <TableHeader
                              isSort={false}
                              sortName='delivered_date'
                              label={"Delivered From"}
                            />
                            <TableHeader
                              isSort={false}
                              sortName='delivered_to'
                              label={"Delivered To"}
                            />
                            <TableHeader
                              isSort={false}
                              sortName='case_notes'
                              label={"Case Notes"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              sortName='total_amount'
                              label={"Total Amount"}
                              cssClass='text-center'
                            />
                            <TableHeader
                              isSort={false}
                              sortName='status'
                              label={"Action"}
                              cssClass='text-center'
                            />
                            <th>
                              <div className='sr-only'>View</div>
                            </th>
                          </tr>
                        </thead>

                        <tbody className='divide-y divide-gray-200 bg-white'>
                          {claimedCaseNotesListLoading ? (
                            <tr>
                              <td
                                className='w-full text-center py-4 bg-gray-50'
                                colSpan='9'
                              >
                                <CircularLoader
                                  classes='flex justify-center items-center'
                                  classloader='loader-l'
                                />
                              </td>
                            </tr>
                          ) : !claimedList || claimedList.length === 0 ? (
                            <tr>
                              <td
                                colSpan={9}
                                className='py-6 text-center text-red-600'
                              >
                                No Data Found !
                              </td>
                            </tr>
                          ) : (
                            claimedList &&
                            claimedList.map((items, index) => (
                              <tr
                                key={index}
                                className='text-left even:bg-white odd:bg-gray-50'
                              >
                                <td className=' px-3 py-4 text-sm text-gray-500'>
                                  {items?.ref_no}
                                </td>
                                <td
                                  title={items?.created_by}
                                  className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize'
                                >
                                  {textTruncate(items?.created_by, 30, 29)}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {formatDateIntoDMYToDisplay(
                                    items.created_date
                                  )}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {formatDateIntoDMYToDisplay(
                                    items.delivered_from
                                  )}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {formatDateIntoDMYToDisplay(
                                    items.delivered_to
                                  )}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                                  {items?.case_notes}
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                                  {`$${
                                    parseFloat(items?.total_amount).toFixed(
                                      2
                                    ) || 0
                                  }`}
                                </td>
                                <td className='px-3 py-4 text-sm text-center text-gray-500'>
                                  <div>
                                    <Link
                                      className='icon-eye-wrapper inline-block'
                                      to={`/cos/ndis-claims-review/${items.uuid}`}
                                      title='View'
                                    >
                                      <HiOutlineEye className='icon-size' />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NdisClaim;
