import { apiCall } from "./service";
import {
  URL_HRM_FOLDER_LIST,
  URL_EMPLOYEE_HRM_FORM_CATEGORIES,
  URL_EMPLOYEE_HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY,
  URL_QUICK_BOOKS_EMPLOYEE,
  URL_QUICK_BOOKS_SYNC_EMPLOYEE,
} from "./URL";

const token = localStorage.getItem("accessToken");
export const api = {
  getFolderLIstForHrm(params) {
    return apiCall.get({
      url:
        params && params.folder_uuid
          ? `${URL_HRM_FOLDER_LIST}?worker_uuid=${params.uuid}&folder_uuid=${params.folder_uuid}`
          : `${URL_HRM_FOLDER_LIST}?worker_uuid=${params.uuid}`,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },
  hrmFormCategoriesList() {
    return apiCall.get({
      url: `${URL_EMPLOYEE_HRM_FORM_CATEGORIES}`,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  hrmSubmittedFormsListByCategory(params) {
    return apiCall.post({
      url: `${URL_EMPLOYEE_HRM_SUBMITTED_FORMS_LIST_BY_CATEGORY}?c_id=${params.c_id}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  quickBooksEmployeeList() {
    return apiCall.get({
      url: URL_QUICK_BOOKS_EMPLOYEE,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
  syncQuickBooksEmployees() {
    return apiCall.get({
      url: URL_QUICK_BOOKS_SYNC_EMPLOYEE,
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },
};

export default api;
