import createSelector from "../../Utils/reselect";

const generalStateData = (state) => {
  const { general } = state;
  return general || {};
};

export const getMessageBoxObj = createSelector(
  generalStateData,
  (general) => general.get("showMessageBox") || {}
);
export const getConfirmationMessageBoxObj = createSelector(
  generalStateData,
  (general) => general.get("showConfirmationMessageBox") || {}
);
export const getMessageBoxOnExpiryObj = createSelector(
  generalStateData,
  (general) => general.get("showMessageBoxForTimeExpiryData") || {}
);

export const getToastList = createSelector(
  generalStateData,
  (general) => general.get("toastList") || null
);
