import ViewFromTemplate from "./ViewFormTemplate";
import { connect } from "react-redux";
import { getSelectedParticipant } from "../../../store/selector/participant.selector";
import {
  getParticipantCall,
  resetSelectedParticipant,
  submittedFormUpdatePtPercentageLocally,
} from "../../../store/actions/participant.actions";
import { getUser } from "../../../store/selector/auth.selector";

const mapStateToProps = (state) => {
  const selectedParticipant = getSelectedParticipant(state);
  const currentLoginUser = getUser(state);
  return { selectedParticipant, currentLoginUser };
};
const mapDispatchToProps = {
  getParticipant: getParticipantCall,
  resetSelectedParticipant,
  submittedFormUpdatePtPercentageLocally,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewFromTemplate);
