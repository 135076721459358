import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginScreenSection from "components/common/LoginScreenSection/LoginScreenSection";
import { PhoneIcon } from "@heroicons/react/outline";
import { MdEmail } from "react-icons/md";
import LoaderButton from "components/common/LoaderButton/LoaderButton";
import { AiOutlineArrowLeft } from "react-icons/ai";

const TwoFAEnabledPage = (props) => {
  const { sendOTPLoading, sendOTPRequest, sendOTPSuccess } = props;
  const navigate = useNavigate();
  const [selectionButton, setSelectionButton] = useState("");
  const [sendVia, setSendVia] = useState("");

  useEffect(() => {
    if (sendOTPSuccess) {
      setSendVia("");
      if (localStorage.getItem("2FASetup") === "null") {
        localStorage.setItem("step", "1");
        navigate(`/setup-otp`);
      } else if (
        localStorage.getItem("2FASetup") !== "null" &&
        localStorage.getItem("2FARequired") === "false"
      ) {
        localStorage.setItem("step", "2.2");
        navigate("/verify-otp");
      } else {
        localStorage.setItem("step", false);
        navigate("/dashboard");
      }
    }
  }, [sendOTPSuccess]);

  const handleLogoutClick = () => {
    // localStorage.clear();
    // sessionStorage.clear();
    // navigate("/");
  };

  const handleOTPsendClick = (via) => {
    setSendVia(via);
    localStorage.setItem("via", via);
    setSelectionButton(via);
    if (
      via === "email" ||
      (via === "phone" && localStorage.getItem("2FASetup") !== "null")
    ) {
      sendOTPRequest({
        navigate,
        via,
        authentication_uuid: localStorage.getItem("authentication_uuid"),
      });
    } else {
      localStorage.setItem("step", "1");
      navigate("/setup-otp");
    }
  };

  return (
    <div className='min-h-screen flex'>
      <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 lg:w-1/2 xl:px-24'>
        <div className='mx-auto w-full max-w-sm lg:w-96'>
          <div>
            <h2 className='mt-6 text-3xl font-semibold text-gray-900 flex justify-center'>
              OTP Method Verification
            </h2>
            <p className='mt-2 text-sm text-gray-500 flex justify-center'>
              How to send OTP via Phone or Email
            </p>
          </div>
          <div className='mt-8'>
            <div className='mt-6 flex justify-between space-x-4 px-12'>
              {sendOTPLoading && selectionButton === "phone" ? (
                <LoaderButton
                  classes='w-full
                      whitespace-nowrap
                        flex
                        justify-center
                        items-center
                        py-2
                        px-4
                        border border-transparent
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-white
                        bg-blue-dark
                        hover:bg-blue-dark
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-blue-dark'
                  svgClasses='inline mr-3 w-5 h-5 text-white animate-spin'
                  textbutton='Loading...'
                />
              ) : (
                <button
                  disabled={selectionButton === "email" && true}
                  onClick={() => handleOTPsendClick("phone")}
                  type='submit'
                  className={`
                      
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      
                      
                      ${
                        selectionButton === "email"
                          ? "bg-gray-400"
                          : "bg-blue-dark"
                      }

                      hover:bg-blue-dark
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-dark
                      
                      `}
                >
                  <span className='flex justify-center space-x-1 items-center'>
                    <PhoneIcon className='h-4 w-4' /> <p>Phone</p>
                  </span>
                </button>
              )}

              <p className='text-blue-800 mt-2'>Or</p>
              {sendOTPLoading && selectionButton === "email" ? (
                <LoaderButton
                  classes='w-full
                      whitespace-nowrap
                        flex
                        justify-center
                        items-center
                        py-2
                        px-4
                        border border-transparent
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-white
                        bg-blue-dark
                        hover:bg-blue-dark
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-blue-dark'
                  svgClasses='inline mr-3 w-5 h-5 text-white animate-spin'
                  textbutton='Loading...'
                />
              ) : (
                <button
                  disabled={selectionButton === "phone" && true}
                  // disabled
                  onClick={() => handleOTPsendClick("email")}
                  type='submit'
                  className={`
                    
                    ${
                      selectionButton === "phone"
                        ? "bg-gray-400"
                        : "bg-blue-dark"
                    }
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      hover:bg-blue-dark
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-dark
                      `}
                >
                  <span className='flex justify-center space-x-1 items-center'>
                    <MdEmail className='h-4 w-4' /> <p>Email</p>
                  </span>
                </button>
              )}
            </div>
            <div
              className='text-sm  flex justify-center items-center mt-6 space-x-3'
              // onClick={handleLogoutClick}
            >
              <img src={""} alt='' className='' />
              <div className='relative top-[2px]'>
                <AiOutlineArrowLeft className=' text-gray-500 font-semibold' />
              </div>
              <div className='font-semibold text-gray-500 text-sm'>
                <span
                  className='cursor-pointer'
                  onClick={() => {
                    if (sendOTPLoading === false) {
                      localStorage.clear();
                      sessionStorage.clear();
                      navigate("/");
                    }
                  }}
                >
                  Back to log in
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginScreenSection
        heading={`Vertex360 has streamlined our NDIS compliance to an extent that we look forward to coming to work now !`}
        userImg='use image here'
        name='Dale Florence'
        desc='NDIS Service Provider'
      />
    </div>
  );
};

export default TwoFAEnabledPage;
