import React from "react";
import birthday from "../../../../assets/dashboard/birthday.jpg";
import { baseURLImage, getNameProfile,formatDateToAustralian } from "Utils/utils";

const BirthdayCard = ({ item }) => {
 
  const backgroundImageStyle = {
    backgroundImage: `url(${birthday})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundRepeat: "no-repeat", // Adjust as needed
  
  };

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth();
  };

  // Usage example:
  const dob = new Date(item?.dob);
  const isBirthdayToday = isToday(dob);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <div
      className={`h-full w-full p-6 border `}
      style={backgroundImageStyle}
    >
      <div className="h-full flex flex-col justify-between">
        <div>
          <div className="flex items-center">
            <div className="h-12 w-12 flex-shrink-0">
              <img
                onError={(e) => handleImageError(e, item?.name, item?.name)}
                className="object-cover h-12 w-12 rounded-full"
                src={
                  item?.photo
                    ? baseURLImage(item?.photo)
                    : getNameProfile(item.name, item?.name || " ")
                }
                alt=""
              />
            </div>
            <div className="ml-4">
              <div className="font-medium font-bold text-gray-900 capitalize">
                {item?.name} <span className="lowercase text-sm">({item?.type})</span> 
              </div>
              <p className={`text-xs   } `}>
                Has Birthday {isBirthdayToday?"":"on"} {isBirthdayToday ? "Today" :  formatDateToAustralian(item?.dob)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthdayCard;
