import React from "react";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import { BiMinus } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";

const PermissionItems = (props) => {
  const { index, handleRowClick, expandedRows, item, handleSwitchChange } =
    props;

  return (
    <React.Fragment key={index}>
      <tr className='even:bg-white odd:bg-gray-50'>
        <td
          className='whitespace-nowrap  py-4 px-3  text-sm sm:pl-3 w-[40%] '
          onClick={() => item.children.length > 0 && handleRowClick(item.id)}
        >
          <div className='flex align-center cursor-pointer '>
            {item.children.length > 0 ? (
              expandedRows.includes(item.id) ? (
                <BiMinus className='mt-1 text-gray-600' />
              ) : (
                <BsPlusLg className='h-3 w-3 mt-1 text-gray-600' />
              )
            ) : (
              <></>
            )}
            <span className='ml-3'> {item.description}</span>
          </div>
        </td>
        <td className='text-center border'>
          <SwitchWithIcon
            onChange={(value) => handleSwitchChange(index, value)}
            checked={item.isChecked}
          />
        </td>
      </tr>
      {expandedRows.includes(item.id) &&
        item?.children?.map((el, childIndex) => {
          return (
            <tr className='even:bg-white odd:bg-gray-50' key={childIndex}>
              <td className='whitespace-nowrap  border py-4 px-3 text-sm sm:pl-3 w-[40%]'>
                <div className='flex align-center cursor-pointer '>
                  <span className='ml-9'> {el.description}</span>
                </div>
              </td>
              <td className='text-center border'>
                <SwitchWithIcon
                  checked={el.isChecked}
                  isSmall={true}
                  onChange={(value) =>
                    handleSwitchChange(index, value, childIndex)
                  }
                />
              </td>
            </tr>
          );
        })}
    </React.Fragment>
  );
};

export default PermissionItems;
