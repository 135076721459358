import Appointment from ".";

import TwoFAEnabledPage from "./TwoFAEnabledPage";
import { connect } from "react-redux";
// import {} from "../../../../store/actions/participant.actions";
// import {} from "../../../../store/selector/participant.selector";

import {
  sendOTPForConfirmationRequest,
  reSendOTPRequest,
  sendOTPRequest,
} from "store/actions/auth.actions";
import {
  confirmOtpErrorSelector,
  confirmOtpSuccessSelector,
} from "store/selector/auth.selector";

const mapDispatchToProps = {
  sendOTPForConfirmationRequest,
  sendOTPRequest,
  reSendOTPRequest,
};

const mapStateToProps = (state) => {
  const confirmOtpError = confirmOtpErrorSelector(state);
  const confirmOtpSuccess = confirmOtpSuccessSelector(state);

  return { confirmOtpError, confirmOtpSuccess };
};
export default connect(mapStateToProps, mapDispatchToProps)(TwoFAEnabledPage);
