import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import { TOAST_TYPE } from "../../Constants/constant";
import { cosActions, generalActions } from "../actions";
import ACTIONS from "../constants";
import api from "../services/cos.services";

function* updateCaseNotesSaga(state) {
  try {
    yield put(cosActions.updateCaseNotesAction.pending);
    // alert("api");
    const response = yield call(api.updateCaseNotesApi, state.payload);
    if (response) {
      yield put(cosActions.updateCaseNotesAction.success(response.data));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Case Note Updated Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(cosActions.updateCaseNotesAction.error(error));
  }
}

function* getCosCaseNoteDetailSaga(state) {
  try {
    yield put(cosActions.getCaseNoteDetailsAction.pending);
    const response = yield call(api.getCaseNotesDetails, state.payload);
    if (response) {
      yield put(cosActions.getCaseNoteDetailsAction.success(response.data));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(cosActions.getCaseNoteDetailsAction.error(error));
  }
  // getCseNotesDetails
}

function* getFolderListForHRMSaga(state) {
  try {
    yield put(cosActions.getFolderListForHRMAction.pending);
    const response = yield call(api.getFolderLIstForHrm, state.payload);
    if (response) {
      yield put(cosActions.getFolderListForHRMAction.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(cosActions.getFolderListForHRMAction.error(error));
  }
}

function* createNewFolderSaga(state) {
  try {
    yield put(cosActions.createFolderAction.pending);
    const response = yield call(api.createNewFolderApi, state.payload);
    if (response) {
      yield put(cosActions.createFolderAction.success(response));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Folder Created Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(cosActions.createFolderAction.error(error));
  }
}

function* updateFolder(state) {
  try {
    yield put(cosActions.updateFolder.pending);
    const response = yield call(api.updateFolderApi, state.payload);
    if (response) {
      yield put(cosActions.updateFolder.success(response));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Folder Updated Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(cosActions.updateFolder.error(error));
  }
}

function* removeFolder(state) {
  try {
    yield put(cosActions.removeFolder.pending);
    const response = yield call(api.removeFolderApi, state.payload);
    if (response) {
      yield put(cosActions.removeFolder.success(response));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Folder Deleted Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(cosActions.removeFolder.error(error));
  }
}

function* removeFile(state) {
  try {
    yield put(cosActions.removeFile.pending);
    const response = yield call(api.removeFileApi, state.payload);
    if (response) {
      yield put(
        cosActions.removeFile.success({ response, removedId: state.payload })
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "File Deleted Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(cosActions.removeFile.error(error));
  }
}

function* updatePriority(state) {
  try {
    yield put(cosActions.updatePriority.pending);
    const response = yield call(api.updatePriorityApi, state.payload);
    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Task Priority Updated Successfully!",
        })
      );
      yield put(cosActions.updatePriority.success(response.cos_priority));
    }
  } catch (error) {
    yield put(cosActions.updatePriority.error(error));
  }
}

function* updateContact(state) {
  try {
    yield put(cosActions.updateContact.pending);
    const response = yield call(api.updateContactApi, state.payload);

    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Note Contact Type Updated Successfully!",
        })
      );
      yield put(cosActions.updateContact.success(response.contact_type));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something Went Wrong!",
      })
    );
    yield put(cosActions.updateContact.error(error));
  }
}

function* updateCategory(state) {
  try {
    yield put(cosActions.updateCategory.pending);
    const response = yield call(api.updateCategoryApi, state.payload);

    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Task Category Updated Successfully!",
        })
      );
      yield put(
        cosActions.updateCategory.success(response.client_task_category)
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something went wrong!",
      })
    );
    yield put(cosActions.updateCategory.error(error));
  }
}

function* getDashboardData(state) {
  try {
    yield put(cosActions.getDashboardData.pending);
    const response = yield call(api.getDashboardDataApi, state.payload);

    if (response) {
      yield put(cosActions.getDashboardData.success(response));
    }
  } catch (error) {
    yield put(cosActions.getDashboardData.error(error));
  }
}

function* getFundingSetupParticipantForCos(state) {
  try {
    yield put(cosActions.fundingSetupParticipantFosCos.pending);
    const response = yield call(
      api.getFundingSetupParticipantFosCos,
      state.payload
    );

    if (response) {
      yield put(cosActions.fundingSetupParticipantFosCos.success(response));
    }
  } catch (error) {
    yield put(
      cosActions.fundingSetupParticipantFosCos.error(error.message || error)
    );
  }
}

function* getCosReportingFundingTableData(state) {
  try {
    yield put(cosActions.getCosReportingFundingTableData.pending);
    const response = yield call(
      api.getCosReportingFundingTableDataApi,
      state.payload
    );

    if (response) {
      if (state.payload.type === "funding") {
        yield put(cosActions.getCosReportingFundingTableData.success(response));
      } else {
        yield put(
          cosActions.getCosReportingForCaseNotesFundingTableData.success(
            response
          )
        );
      }
    }
  } catch (error) {
    yield put(cosActions.getCosReportingFundingTableData.error(error));
  }
}

function* getPeriorityList(state) {
  try {
    yield put(cosActions.getPeriorityList.pending);
    const response = yield call(api.getPeriorityListApi, state.payload);

    if (response) {
      yield put(cosActions.getPeriorityList.success(response));
    }
  } catch (error) {
    yield put(cosActions.getPeriorityList.error(error));
  }
}

function* getContactList(state) {
  try {
    yield put(cosActions.getContactList.pending);
    const response = yield call(api.cosContactSettingFetch, state.payload);

    if (response) {
      yield put(cosActions.getContactList.success(response));
    }
  } catch (error) {
    yield put(cosActions.getContactList.error(error));
  }
}

function* getCatogeryList(state) {
  try {
    yield put(cosActions.getCatogeryList.pending);
    const response = yield call(api.cosCategorySettingFetch, state.payload);

    if (response) {
      yield put(cosActions.getCatogeryList.success(response));
    }
  } catch (error) {
    yield put(cosActions.getCatogeryList.error(error));
  }
}

function* createNewContact(state) {
  try {
    yield put(cosActions.createNewContact.pending);
    const response = yield call(api.createNewContactApi, state.payload);

    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Note Contact Type Added Successfully!",
        })
      );
      yield put(cosActions.createNewContact.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something went wrong!",
      })
    );
    yield put(cosActions.createNewContact.error(error));
  }
}

function* createNewCategory(state) {
  try {
    yield put(cosActions.createNewCategory.pending);
    const response = yield call(api.createNewCategoryApi, state.payload);

    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Task Category Added Successfully!",
        })
      );
      yield put(cosActions.createNewCategory.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something went wrong!",
      })
    );
    yield put(cosActions.createNewCategory.error(error));
  }
}

function* createNewPeriority(state) {
  try {
    yield put(cosActions.createNewPeriority.pending);
    const response = yield call(api.createNewPeriorityApi, state.payload);
    if (response) {
      yield put(cosActions.createNewPeriority.success(response));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Task Priority Added Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something went wrong!",
      })
    );
    yield put(cosActions.createNewPeriority.error(error));
  }
}

function* changeNDISClaimInvoiceActions(state) {
  try {
    yield put(cosActions.changeNDISClaimInvoiceActions.pending);
    const response = yield call(
      api.changeActionsClaimInvoiceApi,
      state.payload
    );

    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Action Updated Successfully!",
        })
      );
      yield put(cosActions.changeNDISClaimInvoiceActions.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(cosActions.changeNDISClaimInvoiceActions.error(error));
  }
}

function* changeNDISClaimActions(state) {
  try {
    yield put(cosActions.changeNDISClaimActions.pending);
    const response = yield call(api.changeNDISClaimActionsApi, state.payload);
    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Action Updated Successfully!",
        })
      );
      yield put(cosActions.changeNDISClaimActions.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(cosActions.changeNDISClaimActions.error(error));
  }
}

function* getCreatedCosInvoicesRviews(state) {
  try {
    yield put(cosActions.getCreatedCosInvoicesRviews.pending);
    const response = yield call(
      api.getCreatedCosInvoicesReviewsApi,
      state.payload
    );

    if (response) {
      yield put(cosActions.getCreatedCosInvoicesRviews.success(response));
    }
  } catch (error) {
    yield put(cosActions.getCreatedCosInvoicesRviews.error(error));
  }
}

function* getCreatedCosInvoices(state) {
  try {
    yield put(cosActions.getCreatedCosInvoices.pending);
    const response = yield call(api.getCreatedCosInvoicesApi, state.payload);

    if (response) {
      yield put(
        cosActions.getCreatedCosInvoices.success(response.created_cos_invoice)
      );
    }
  } catch (error) {
    yield put(cosActions.getCreatedCosInvoices.error(error));
  }
}

function* createCosInvoice(state) {
  try {
    yield put(cosActions.createCosInvoice.pending);
    const response = yield call(api.createCosInvoicesApi, state.payload);
    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Invoice Created Successfully!",
        })
      );
      if (response && response?.data?.download_url) {
        yield window.open(response.data.download_url, "_blank");
      }

      yield put(cosActions.createCosInvoice.success(response.data.cos_invoice));
      state.payload.navigate("/cos/invoice");
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message ?? "Something Went Wrong!",
      })
    );
    yield put(cosActions.createCosInvoice.error(error));
  }
}

function* getCoseInvoices(state) {
  try {
    yield put(cosActions.getCoseInvoices.pending);
    if (state.payload.report) {
      const response = yield call(api.getCosInvoicesReportApi, state.payload);
      yield window.open(response.report.download_url, "_blank");
      yield put(cosActions.getCoseInvoices.success(response));
    } else {
      const response = yield call(api.getCosInvoicesApi, state.payload);
      if (response) {
        yield put(cosActions.getCoseInvoices.success(response.cos_invoices));
      }
    }
  } catch (error) {
    yield put(cosActions.getCoseInvoices.error(error));
  }
}

function* getCaseNotesReview(state) {
  try {
    yield put(cosActions.getCaseNotesReview.pending);
    const response = yield call(api.getCaseNotesReviewApi, state.payload);
    if (response) {
      yield put(cosActions.getCaseNotesReview.success(response));
    }
  } catch (error) {
    yield put(cosActions.getCaseNotesReview.error(error));
  }
}

function* getClaimedCaseNotesList(state) {
  try {
    yield put(cosActions.getClaimedCaseNotesList.pending);
    const response = yield call(api.getClaimedCaseNotesListApi, state.payload);
    if (response) {
      yield put(
        cosActions.getClaimedCaseNotesList.success(
          response.created_cos_ndis_claims
        )
      );
    }
  } catch (error) {
    yield put(cosActions.getClaimedCaseNotesList.error(error));
  }
}
/////////
function* addCaseNotesToClaimList(state) {
  try {
    yield put(cosActions.addCaseNotesToClaimList.pending);
    const response = yield call(api.addToClaimApi, state.payload);
    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Claimed Successfully!",
        })
      );
      if (response && response?.data?.download_url) {
        yield window.open(response.data.download_url, "_blank");
      }
      yield put(
        cosActions.addCaseNotesToClaimList.success(response.data.cos_ndis_claim)
      );
      state.payload.navigate("/cos/ndis-claims");
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message ?? "Something went wrong!",
      })
    );
    yield put(cosActions.addCaseNotesToClaimList.error(error));
  }
}

function* getCaseNotesListForClaim(state) {
  try {
    yield put(cosActions.getCaseNotesListForClaim.pending);
    if (state.payload.report) {
      const response = yield call(
        api.getCaseNotesForClaimListReportApi,
        state.payload
      );
      yield put(cosActions.getCaseNotesListForClaim.success(response));
      yield window.open(response.report.download_url, "_blank");
    } else {
      const response = yield call(
        api.getCaseNotesForClaimListApi,
        state.payload
      );
      yield put(cosActions.getCaseNotesListForClaim.success(response));
      if (response) {
        yield put(
          cosActions.getCaseNotesListForClaim.success(response.cos_ndis_claims)
        );
      }
    }
  } catch (error) {
    yield put(cosActions.getCaseNotesListForClaim.error(error));
  }
}

function* getCaseNotesHistoryList(state) {
  try {
    yield put(cosActions.getCaseNotesHistoryList.pending);
    const response = yield call(api.getCaseNotesHistoryListApi, state.payload);

    if (response) {
      yield put(
        cosActions.getCaseNotesHistoryList.success(response.case_notes_history)
      );
    }
  } catch (error) {
    yield put(cosActions.getCaseNotesHistoryList.error(error));
  }
}

function* getCaseNotesClients(state) {
  try {
    yield put(cosActions.getCaseNotesClients.pending);
    const response = yield call(api.getCaseNotesClients, state.payload);
    if (response) {
      yield put(cosActions.getCaseNotesClients.success(response.clients));
    }
  } catch (error) {
    yield put(cosActions.getCaseNotesClients.error(error));
  }
}

function* getSingleSupportTrack(state) {
  try {
    yield put(cosActions.getSingleSupportTrack.pending);
    const response = yield call(
      api.getSingleSupportTrackListApi,
      state.payload
    );

    if (response) {
      yield put(cosActions.getSingleSupportTrack.success(response));
    }
  } catch (error) {
    yield put(cosActions.getSingleSupportTrack.error(error));
  }
}

function* getSupportTrackList(state) {
  try {
    yield put(cosActions.getSupportTrackList.pending);
    const response = yield call(api.getSupportTrackListApi, state.payload);

    if (response) {
      yield put(cosActions.getSupportTrackList.success(response.case_notes));
    }
  } catch (error) {
    yield put(cosActions.getSupportTrackList.error(error));
  }
}

function* getReportData(state) {
  try {
    yield put(cosActions.getReportData.pending);
    const response = yield call(api.reportingCaseNotesHistory, state.payload);

    if (response) {
      if (state.payload.file_type === "pdf") {
        yield window.open(response.report_data.download_url, "_blank");
        yield put(cosActions.getReportData.success(response));
      } else {
        yield window.open(response.report_data.download_url, "_blank");
        yield put(cosActions.getReportData.success(response.report_data));
      }
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );

    yield put(cosActions.getReportData.error(error));
  }
}

function* deleteClientTasksListRequest(state) {
  try {
    yield put(cosActions.deleteClientTask.pending);
    const response = yield call(api.clientTaskDelete, state.payload);
    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Client Task deleted Successfully!",
        })
      );
      yield put(cosActions.deleteClientTask.success(response));
    }
  } catch (error) {
    yield put(cosActions.deleteClientTask.error(error));
  }
}

function* updateClientTasksListRequest(state) {
  try {
    yield put(cosActions.updateClientTask.pending);
    const response = yield call(api.updateClientTask, state.payload);
    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Client Task Updated Successfully!",
        })
      );
      yield put(cosActions.updateClientTask.success(response));
    }
  } catch (error) {
    yield put(cosActions.updateClientTask.error(error));
  }
}

function* getClientTasksListRequest(state) {
  try {
    yield put(cosActions.getClientTaskList.pending);
    const response = yield call(api.getClientTaskList, state.payload);
    if (response) {
      yield put(cosActions.getClientTaskList.success(response));
    }
  } catch (error) {
    yield put(cosActions.getClientTaskList.error(error));
  }
}
function* clientTaskCreate(state) {
  try {
    yield put(cosActions.createClientTask.pending);
    const response = yield call(api.createClientTask, state.payload);
    if (response) {
      yield put(cosActions.createClientTask.success(response));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Client Task Created Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(cosActions.createClientTask.error(error.message || error));
  }
}

function* caseNotesCreate(state) {
  try {
    yield put(cosActions.caseNotesCreate.pending);
    const response = yield call(api.createCaseNotes, state.payload.formData);
    if (response) {
      yield put(cosActions.caseNotesCreate.success(response));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Case Notes Created Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(cosActions.caseNotesCreate.error(error.message || error));
  }
}

function* getCaseNotesRequest(state) {
  try {
    yield put(cosActions.getCaseNotes.pending);

    const response = yield call(api.getCosCaseNote, state.payload);
    if (response.cos_case_notes.data) {
      yield put(cosActions.getCaseNotes.success(response.cos_case_notes));
    }
  } catch (error) {
    yield put(cosActions.getCaseNotes.error(error));
  }
}

function* getCosParticipantProfile(state) {
  try {
    yield put(cosActions.getCOSParticipantsProfile.pending);
    const response = yield call(api.getCosParticipantProfile, state.payload);

    if (response.participant) {
      yield put(
        cosActions.getCOSParticipantsProfile.success(response.participant)
      );
    }
  } catch (error) {
    yield put(cosActions.getCOSParticipantsProfile.error(error));
  }
}

function* getCatalogVersions(state) {
  try {
    yield put(cosActions.catalogVersions.pending);
    const response = yield call(api.getCatalogVersions);

    if (response) {
      if (
        state.isServiceCall &&
        response.success &&
        response.success.length > 0
      ) {
        const res = yield call(api.getAllCatalogServices, {
          page: 1,
          per_page: 100,
          version_id: response.success[response.success.length - 1]?.id,
        });
        if (res.data) {
          yield put(cosActions.getAllCatalogServices.success(res.data));
        }
      }

      yield put(cosActions.catalogVersions.success(response.success));
    }
  } catch (error) {
    yield put(cosActions.catalogVersions.error(error));
  }
}

function* getAllCatalogServices(state) {
  try {
    yield put(cosActions.getAllCatalogServices.pending);
    const response = yield call(api.getAllCatalogServices, state.payload);
    if (response.data) {
      yield put(cosActions.getAllCatalogServices.success(response.data));
    }
  } catch (error) {
    yield put(cosActions.getAllCatalogServices.error(error));
  }
}

function* storeAndUpdateFundSetup(state) {
  try {
    yield put(cosActions.storeAndUpdateFundingSetup.pending);
    const response = yield call(
      state.isUpdate ? api.updateFundingSetup : api.storeFundingSetup,
      state.payload
    );
    if (response) {
      yield put(
        cosActions.storeAndUpdateFundingSetup.success({
          funding: response.funding,
          isUpdate: state.isUpdate,
        })
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: state.isUpdate
            ? "Funding Updated Successfully!"
            : "Funding Created Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message ? error.message : "Something went wrong!",
      })
    );
    yield put(
      cosActions.storeAndUpdateFundingSetup.error(error.message || error)
    );
  }
}

function* getFundingSetupParticipant(state) {
  try {
    yield put(cosActions.fundingSetupParticipant.pending);
    const response = yield call(api.getFundingSetupParticipant, state.payload);

    if (response) {
      yield put(cosActions.fundingSetupParticipant.success(response));
    }
  } catch (error) {
    yield put(cosActions.fundingSetupParticipant.error(error.message || error));
  }
}

function* getAllCatalogServicesSubCategory(state) {
  try {
    yield put(cosActions.getAllCatalogServicesSubCategory.pending);
    const response = yield call(api.getCatalogServicesSubCategoryApi);

    if (response) {
      yield put(
        cosActions.getAllCatalogServicesSubCategory.success(response.states)
      );
    }
  } catch (error) {
    yield put(cosActions.getAllCatalogServicesSubCategory.error(error));
  }
}

function* getSelectedFundingSetup(state) {
  try {
    yield put(cosActions.selectedFundingSetup.pending);
    const response = yield call(api.getSelectedFundingSetup, state.payload);
    if (response) {
      yield put(cosActions.selectedFundingSetup.success(response));
    }
  } catch (error) {
    yield put(cosActions.selectedFundingSetup.error(error.message || error));
  }
}

function* getPlanCatagories(state) {
  try {
    yield put(cosActions.planCatagories.pending);
    const response = yield call(api.getPlanCatagories, state.payload);
    if (response.service_cats) {
      yield put(cosActions.planCatagories.success(response.service_cats));
    }
  } catch (error) {
    yield put(cosActions.planCatagories.error(error.message || error));
  }
}

function* storePlanBudget(state) {
  try {
    yield put(cosActions.planBudgetCreated.pending);
    const response = yield call(
      state.payload.isUpdate ? api.updatePlanBudget : api.storePlanBudget,
      state.payload.data
    );
    if (response.data) {
      yield put(
        cosActions.planBudgetCreated.success({
          ...response.data,
          isUpdate: state.payload.isUpdate,
        })
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Plan Budget ${
            state.payload.isUpdate ? "Updated" : "Created"
          } Successfully!`,
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(cosActions.planBudgetCreated.error(error.message || error));
  }
}

function* planBudgetList(state) {
  try {
    yield put(cosActions.planBudgetList.pending);
    const response = yield call(api.planBudgetList, state.payload);
    if (response.data) {
      yield put(cosActions.planBudgetList.success(response.data.plan_budgets));
    }
  } catch (error) {
    yield put(cosActions.planBudgetList.error(error.message || error));
  }
}

function* serviceCategoryRemove(state) {
  try {
    yield put(cosActions.serviceCategoryRemove.pending);
    const response = yield call(api.serviceCategoryRemove, state.payload);
    if (response) {
      yield put(
        cosActions.serviceCategoryRemove.success({
          sp_category_budget_id: state.payload.id,
          plan_budget: response.plan_budget,
        })
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Service Category Successfully Deleted!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message || "Something went wrong!",
      })
    );
    yield put(cosActions.serviceCategoryRemove.error(error.message || error));
  }
}

function* planBudgetCatAndSubCat(state) {
  try {
    yield put(cosActions.planBudgetCatAndSubCat.pending);
    const response = yield call(api.planBudgetCatAndSubCat, state.payload);
    if (response.data) {
      yield put(cosActions.planBudgetCatAndSubCat.success(response.data));
    }
  } catch (error) {
    yield put(cosActions.planBudgetCatAndSubCat.error(error.message || error));
  }
}

function* storeServiceCategoryPlanBudget(state) {
  try {
    yield put(cosActions.serviceCategoryPlanBudgetStored.pending);
    const response = yield call(
      state.payload.isUpdate
        ? api.updateSeriveCategoryPlanBudget
        : api.storeSeriveCategoryPlanBudget,
      state.payload.data
    );
    if (response) {
      yield put(
        cosActions.serviceCategoryPlanBudgetStored.success({
          response,
          isUpdate: state.payload.isUpdate,
        })
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Service Provider Category Budget ${
            state.payload.isUpdate ? "Updated" : "Created"
          } Successfully!`,
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(
      cosActions.serviceCategoryPlanBudgetStored.error(error.message || error)
    );
  }
}

function* fetchServiceCategoryPlanBudget(state) {
  try {
    yield put(cosActions.serviceCategoryPlanBudgetFetched.pending);
    const response = yield call(
      api.fetchSeriveCategoryPlanBudget,
      state.payload
    );
    if (response) {
      yield put(cosActions.serviceCategoryPlanBudgetFetched.success(response));
    }
  } catch (error) {
    yield put(
      cosActions.serviceCategoryPlanBudgetFetched.error(error.message || error)
    );
  }
}

function* getExternalSpRequest(state) {
  try {
    yield put(cosActions.getExternalSPListFetched.pending);
    const response = yield call(api.getExternalSPListsCall, state.payload);
    if (response) {
      yield put(cosActions.getExternalSPListFetched.success(response));
    }
  } catch (error) {
    yield put(
      cosActions.getExternalSPListFetched.error(error.message || error)
    );
  }
}

function* externalSpCreate(state) {
  try {
    yield put(cosActions.createExternalSP.pending);
    const response = yield call(api.postServiceProvider, state.payload);
    if (response) {
      yield put(cosActions.createExternalSP.success(response));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "External SP created successfully",
        })
      );
    }
  } catch (error) {
    yield put(cosActions.createExternalSP.error(error ?? error.message));
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title:
          error && error?.message ? error.message : "Something went Wrong!",
      })
    );
  }
}

function* fundingNotesList(state) {
  try {
    yield put(cosActions.fundingNotesList.pending);
    const response = yield call(api.fundingNotesListCall, state.payload);
    if (response) {
      yield put(cosActions.fundingNotesList.success(response.funding_notes));
    }
  } catch (error) {
    yield put(cosActions.fundingNotesList.error(error.message || error));
  }
}

function* storeFundingNotes(state) {
  try {
    yield put(cosActions.fundingNotesCreated.pending);
    const response = yield call(
      state.payload.isUpdate ? api.updateFundingNotes : api.storeFundingNotes,
      state.payload.formData
    );
    if (response) {
      yield put(
        cosActions.fundingNotesCreated.success({
          ...response,
          isUpdate: state.payload.isUpdate,
        })
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: `Funding Notes ${
            state.payload.isUpdate ? "Updated" : "Created"
          } Successfully!`,
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(cosActions.fundingNotesCreated.error(error.message || error));
  }
}

// function* fundingNotesRemove(state) {
//   try {
//     yield put(cosActions.fundingNotesRemove.pending);
//     const response = yield call(api.fundingNotesRemove, state.payload);
//     if (response) {
//       yield put(
//         cosActions.fundingNotesRemove.success({
//           fundingNotesId: state.payload.fundingNotesId,
//           funding: response.funding_notes,
//         })
//       );
//       yield put(
//         generalActions.showToast({
//           type: TOAST_TYPE.SUCCESS,
//           title: "Funding Notes Successfully Deleted!",
//         })
//       );
//     }
//   } catch (error) {
//     yield put(cosActions.fundingNotesRemove.error(error.message || error));
//   }
// }

function* serviceSupportCreate(state) {
  try {
    yield put(cosActions.serviceSupportBudgetCreated.pending);
    const response = yield call(
      state.payload.isUpdate
        ? api.updateServiceSupport
        : api.storeServiceSupport,
      state.payload.data
    );
    if (response) {
      const serviceSupportBudget =
        response.service_support_budget.service_support_budget;
      Object.assign(serviceSupportBudget, {
        plan_budget: response.service_support_budget.plan_budget,
        funding: response.service_support_budget.funding,
        sp_category_budget: response.service_support_budget.sp_category_budget,
        price_catalog: response.service_support_budget.price_catalog,
        external_sp: response.service_support_budget.external_sp,
      });
      yield put(
        cosActions.serviceSupportBudgetCreated.success({
          serviceSupportBudget: serviceSupportBudget,
          service_support_budget:
            response.service_support_budget.service_support_budget,
          isUpdate: state.payload.isUpdate,
        })
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: state.payload.isUpdate
            ? "Service Support Budget Successfully Updated!"
            : "Service Support Budget Successfully Created!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message ? error.message : "Something went wrong!",
      })
    );
    yield put(
      cosActions.serviceSupportBudgetCreated.error(error.message || error)
    );
  }
}

function* serviceSupportFetch(state) {
  try {
    yield put(cosActions.serviceSupportBudgetFetched.pending);
    const response = yield call(api.fetchServiceSupport, state.payload);
    if (response.data) {
      yield put(cosActions.serviceSupportBudgetFetched.success(response.data));
    }
  } catch (error) {
    yield put(
      cosActions.serviceSupportBudgetFetched.error(error.message || error)
    );
  }
}

function* fetchServiceSupportsForDeliveredService(state) {
  try {
    yield put(cosActions.serviceSupportsForDeliveredServiceFetched.pending);
    const response = yield call(
      api.fetchServiceSupportsForDeliveredServices,
      state.payload
    );
    if (response) {
      if (state.payload.cos_case_notes === true) {
        yield put(
          cosActions.serviceSupportsForDeliveredServiceCaseNotesFetched(
            response
          )
        );
      } else {
        yield put(
          cosActions.serviceSupportsForDeliveredServiceFetched.success(
            response.service_support_items
          )
        );
      }
    }
  } catch (error) {
    // yield put(
    //   generalActions.showToast({
    //     type: TOAST_TYPE.ERROR,
    //     title: error.message ? error.message : "No Plan Budget Found!",
    //   })
    // );
    yield put(
      cosActions.serviceSupportsForDeliveredServiceFetched.error(
        error.message || error
      )
    );
  }
}
function* CosParticipantList(state) {
  try {
    yield put(cosActions.cosParticipant.pending);
    const response = yield call(api.cosParticipantListCall, state.payload);
    if (response) {
      yield put(cosActions.cosParticipant.success(response.data));
    }
  } catch (error) {
    yield put(cosActions.cosParticipant.error(error.message || error));
  }
}

function* supportCoordinator() {
  try {
    yield put(cosActions.supportCoordinators.pending);
    const response = yield call(api.supportCoordinatorCall);
    if (response) {
      yield put(
        cosActions.supportCoordinators.success(response.support_coordinate)
      );
    }
  } catch (error) {
    yield put(cosActions.supportCoordinators.error(error.message || error));
  }
}

function* deliveredServiceStore(state) {
  try {
    yield put(cosActions.deliveredServiceCreated.pending);
    const response = yield call(
      state.payload.isUpdate
        ? api.deliveredServiceStore
        : api.deliveredServiceStore,
      state.payload.data
    );
    if (response) {
      yield put(cosActions.deliveredServiceCreated.success(response.data));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Delivered Service Created Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message ? error.message : "Something went wrong!",
      })
    );
    yield put(cosActions.deliveredServiceCreated.error(error.message || error));
  }
}

function* manualDeliveredServiceStore(state) {
  try {
    yield put(cosActions.manualDeliveredServiceCreated.pending);
    const response = yield call(api.manualDeliveredServiceStore, state.payload);
    if (response) {
      if (response.data) {
        const planBudget = {
          ...response.data.plan_budget,
          service_sub_category: response.data.service_sub_category,
        };
        const deliveredService = {
          ...response.data.delivered_service,
          service_category: response.data.service_category,
        };
        Object.assign(response.data, {
          plan_budget: { ...planBudget },
          delivered_service: { ...deliveredService },
        });
      }
      yield put(
        cosActions.manualDeliveredServiceCreated.success(response.data)
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Manual Delivered Service Created Successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message ? error.message : "Something went wrong!",
      })
    );
    yield put(
      cosActions.manualDeliveredServiceCreated.error(error.message || error)
    );
  }
}

function* deliveredServiceFetch(state) {
  try {
    yield put(cosActions.deliveredServiceFetched.pending);
    const response = yield call(api.deliveredServiceFetch, state.payload);
    if (response) {
      yield put(
        cosActions.deliveredServiceFetched.success(response.delivered_service)
      );
    }
  } catch (error) {
    yield put(cosActions.deliveredServiceFetched.error(error.message || error));
  }
}

function* manualDeliveriesFetch(state) {
  try {
    yield put(cosActions.manualDeliveriesFetched.pending);
    const response = yield call(api.manualDeliveriesFetch, state.payload);
    if (response) {
      yield put(
        cosActions.manualDeliveriesFetched.success(response.delivered_service)
      );
    }
  } catch (error) {
    yield put(cosActions.manualDeliveriesFetched.error(error.message || error));
  }
}

function* cosSettingCategoryFetch(state) {
  try {
    yield put(cosActions.getCategorySettingData.pending);
    const response = yield call(api.cosCategorySettingFetch, state.payload);
    if (response) {
      yield put(cosActions.getCategorySettingData.success(response));
    }
  } catch (error) {
    yield put(cosActions.getCategorySettingData.error(error.message || error));
  }
}

function* cosSettingContactFetch(state) {
  try {
    yield put(cosActions.getContactSettingData.pending);
    const response = yield call(api.cosContactSettingFetch, state.payload);
    if (response) {
      yield put(cosActions.getContactSettingData.success(response));
    }
  } catch (error) {
    yield put(cosActions.getContactSettingData.error(error.message || error));
  }
}

function* cosSettingPriorityFetch(state) {
  try {
    yield put(cosActions.getPrioritySettingData.pending);
    const response = yield call(api.cosPrioritySettingFetch, state.payload);
    if (response) {
      yield put(cosActions.getPrioritySettingData.success(response));
    }
  } catch (error) {
    yield put(cosActions.getPrioritySettingData.error(error.message || error));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ACTIONS.UPDATE_CASE_NOTE_DETAIL_CALL, updateCaseNotesSaga),
    takeLatest(ACTIONS.GET_CASE_NOTE_DETAIL_CALL, getCosCaseNoteDetailSaga),
    takeLatest(ACTIONS.GET_FOLDER_LIST_FOR_HRM_CALL, getFolderListForHRMSaga),
    takeLatest(ACTIONS.CREATE_NEWFOLDER_CALL, createNewFolderSaga),
    takeLatest(ACTIONS.UPDATE_FOLDER_CALL, updateFolder),
    takeLatest(ACTIONS.REMOVE_FOLDER_CALL, removeFolder),
    takeLatest(ACTIONS.REMOVE_FILE_CALL, removeFile),
    takeLatest(ACTIONS.UPDATE_PRIORITY_CALL, updatePriority),
    takeLatest(ACTIONS.UPDATE_CONTACT_CALL, updateContact),
    takeLatest(ACTIONS.UPDATE_CATEGORY_CALL, updateCategory),
    takeLatest(ACTIONS.GET_CATOGERY_LIST_CALL, getCatogeryList),
    takeLatest(
      ACTIONS.GET_COS_REPORTING_TABLEDATA_CALL,
      getCosReportingFundingTableData
    ),
    takeLatest(ACTIONS.CREATE_NEW_PERIORITY_CALL, createNewPeriority),
    takeLatest(ACTIONS.GET_CONTACT_LIST_CALL, getContactList),
    takeLatest(ACTIONS.GET_PERIORITY_LIST_CALL, getPeriorityList),
    takeLatest(
      ACTIONS.CHANGE_INVOICE_STATUS_CALL,
      changeNDISClaimInvoiceActions
    ),

    takeLatest(ACTIONS.CREATE_NEW_CONTACT_CALL, createNewContact),
    takeLatest(ACTIONS.CREATE_NEW_CATEGORY_CALL, createNewCategory),
    takeLatest(ACTIONS.CHANGE_NDIS_CLAIM_STATUS_CALL, changeNDISClaimActions),
    takeLatest(ACTIONS.GET_CREATED_COS_INVOICES_CALL, getCreatedCosInvoices),
    takeLatest(
      ACTIONS.GET_CLAIMED_CASE_NOTES_LIST_CALL,
      getClaimedCaseNotesList
    ),
    takeLatest(
      ACTIONS.GET_CREATED_COS_INVOICES_RIVIEW_CALL,
      getCreatedCosInvoicesRviews
    ),
    takeLatest(ACTIONS.CREATE_COS_INVOICE_CALL, createCosInvoice),
    takeLatest(ACTIONS.GET_COS_INVOICES_CALL, getCoseInvoices),
    takeLatest(ACTIONS.GET_CASE_NOTES_REVIEW_CALL, getCaseNotesReview),
    takeLatest(ACTIONS.GET_CASE_NOTES_HISTROY_CALL, getCaseNotesHistoryList),
    takeLatest(
      ACTIONS.UPDATE_CLIENT_TASKS_LIST_CALL,
      updateClientTasksListRequest
    ),
    takeLatest(
      ACTIONS.GET_CASE_NOTES_LIST_FOR_CLAIM_CALL,
      getCaseNotesListForClaim
    ),
    takeLatest(
      ACTIONS.ADD_CASE_NOTES_TO_CLAIM_LIST_CALL,
      addCaseNotesToClaimList
    ),
    takeLatest(ACTIONS.GET_CASE_NOTES_CLIENT_CALL, getCaseNotesClients),
    takeLatest(ACTIONS.GET_SINGLE_SUPPORT_TRACT_CALL, getSingleSupportTrack),
    takeLatest(ACTIONS.GET_SUPPORT_TRACT_LIST_CALL, getSupportTrackList),
    takeLatest(ACTIONS.GET_REPORT_DATA_CALL, getReportData),
    takeLatest(ACTIONS.DELETE_CLIENT_TASKS_CALL, deleteClientTasksListRequest),
    takeLatest(ACTIONS.GET_CLIENT_TASKS_LIST_CALL, getClientTasksListRequest),
    takeLatest(ACTIONS.CREATE_CLIENT_TASK_CALL, clientTaskCreate),
    takeLatest(ACTIONS.GET_CASENOTES_LIST_CALL, getCaseNotesRequest),
    takeLatest(ACTIONS.CASENOTES_CREATE_CALL, caseNotesCreate),
    takeLatest(ACTIONS.COS_PARTICIPANTS_PROFILE_CALL, getCosParticipantProfile),
    takeLatest(ACTIONS.CATALOG_VERSIONS_CALL, getCatalogVersions),
    takeLatest(ACTIONS.CATALOG_SERVICES_CALL, getAllCatalogServices),
    takeLatest(
      ACTIONS.STORE_AND_UPDATE_FUNDING_SETUP_CALL,
      storeAndUpdateFundSetup
    ),
    takeLatest(
      ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_CALL,
      getFundingSetupParticipant
    ),
    takeLatest(
      ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS_CALL,
      getFundingSetupParticipantForCos
    ),

    takeLatest(
      ACTIONS.CATALOG_SERVICES_SUB_CATEGORY_CALL,
      getAllCatalogServicesSubCategory
    ),
    takeLatest(ACTIONS.SELCETED_FUNDING_SETUP_CALL, getSelectedFundingSetup),
    takeLatest(ACTIONS.GET_DASHBOARD_DATA_CALL, getDashboardData),
    takeLatest(ACTIONS.PLAN_CATAGORIES_CALL, getPlanCatagories),
    takeLatest(ACTIONS.CREATE_PLAN_BUDGET_CALL, storePlanBudget),
    takeEvery(ACTIONS.PLAN_BUDGET_LIST_CALL, planBudgetList),
    takeLatest(
      ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_DELETE_CALL,
      serviceCategoryRemove
    ),
    takeEvery(ACTIONS.PLAN_BUDGET_CAT_AND_SUB_CAT_CALL, planBudgetCatAndSubCat),
    takeEvery(
      ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_STORE_CALL,
      storeServiceCategoryPlanBudget
    ),
    takeLatest(
      ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_FETCH_CALL,
      fetchServiceCategoryPlanBudget
    ),
    takeEvery(ACTIONS.EXTERNAL_SP_CREATE_CALL, externalSpCreate),
    takeLatest(ACTIONS.EXTERNAL_SP_LIST_FETCH_CALL, getExternalSpRequest),

    takeEvery(ACTIONS.FUNDING_NOTES_LIST_CALL, fundingNotesList),
    takeEvery(ACTIONS.CREATE_FUNDING_NOTES_CALL, storeFundingNotes),
    // takeEvery(ACTIONS.FUNDING_NOTES_DELETE_CALL, fundingNotesRemove),
    takeLatest(
      ACTIONS.SERVICE_SUPPORT_BUDGET_CREATE_CALL,
      serviceSupportCreate
    ),
    takeLatest(ACTIONS.SERVICE_SUPPORT_BUDGET_FETCH_CALL, serviceSupportFetch),
    takeEvery(
      ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CALL,
      fetchServiceSupportsForDeliveredService
    ),
    takeLatest(ACTIONS.COS_PARTICIPANT_LIST_CALL, CosParticipantList),
    takeLatest(ACTIONS.SUPPORT_COORDINATORS_CALL, supportCoordinator),
    takeLatest(ACTIONS.DELIVERED_SERVICE_CREATE_CALL, deliveredServiceStore),
    takeLatest(
      ACTIONS.MANUAL_DELIVERED_SERVICE_CREATE_CALL,
      manualDeliveredServiceStore
    ),
    takeLatest(ACTIONS.DELIVERED_SERVICE_FETCH_CALL, deliveredServiceFetch),
    takeLatest(ACTIONS.MANUAL_DELIVEREIES_FETCH_CALL, manualDeliveriesFetch),
    takeEvery(ACTIONS.COS_SETTING_CATEGORIES_CALL, cosSettingCategoryFetch),
    takeEvery(ACTIONS.COS_SETTING_CONTACT_CALL, cosSettingContactFetch),
    takeEvery(ACTIONS.COS_SETTING_PRIORITIES_CALL, cosSettingPriorityFetch),
  ]);
}
