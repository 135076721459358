import React, { useCallback, useEffect, useState } from "react";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import { TiExportOutline } from "react-icons/ti";
import { toast } from "react-toastify";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_ROSTERING_WORKERS_TIMESHEET_EXPORT_LIST } from "store/services/URL";
import ExportCSVItem from "./ExportCSVItems";
import { getTimesheetLength, getWorkerTimesheetLength } from "Utils/utils";

const ExportCSVModal = (props) => {
  const { isShow, handleClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [exportDate, setExportDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [displayShiftsData, setDisplayShiftData] = useState([]);

  const transformObject = useCallback((obj) => {
    const transformedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        let value = obj[key];
        if (value === "N/A") {
          value = null;
        }
        const newKey = key.toLowerCase().replace(/\s+/g, "_");
        transformedObj[newKey] = value;
      }
    }
    return transformedObj;
  }, []);

  useEffect(() => {
    if (moment(exportDate.startDate).isAfter(exportDate.endDate)) {
      setIsLoading(false);
      toast.error("Invalid date range. Please select a valid date range.", {
        toastId: "invalidDateToast",
      });
      return;
    }

    if (
      exportDate.startDate &&
      exportDate.endDate &&
      !moment(exportDate.startDate).isAfter(exportDate.endDate)
    ) {
      setIsLoading(true);
      const start_date = exportDate?.startDate;
      const end_date = exportDate?.endDate;

      apiPostRequest(URL_ROSTERING_WORKERS_TIMESHEET_EXPORT_LIST, {
        start_date,
        end_date,
      })
        .then((res) => {
          const data = res?.data?.data;

          const dataset = [];
  
          data?.forEach((timeSheet) => {
            const workerName =
              timeSheet?.first_name + " " + timeSheet?.last_name;
            timeSheet?.timeSheet?.forEach((timesheetItem) => {
              const participantNames = timesheetItem?.participants
                .map(
                  (participant) =>
                    participant?.first_name + " " + participant?.last_name
                )
                .join(", ");
                const shiftNotes = timesheetItem.shift_notes?.length > 0
                ? timesheetItem.shift_notes
                    .filter(note => note) 
                    .map(
                      (note) =>
                        `Shift Notes for (${note?.first_name || ""} ${
                          note?.last_name || ""
                        }): ${note.notes.replace(/\n/g, " ").replace(/,/g, "")}`
                    )
                    .join(" | ")
                : "N/A";
              // const shiftNotes = timesheetItem?.shift_notes?.map((el)=>`Shift Notes For (${el?.first_name} ${el?.last_name})`) || "";
              const shiftLength = timesheetItem?.timesheet_length;
              const shiftStartTime = timesheetItem?.start_time;
              const shiftEndTime = timesheetItem?.end_time;
              const shiftDate = timesheetItem?.time_sheet_date;
              const workerStartTime = timesheetItem?.worker_start_time;
              const workerEndTime = timesheetItem?.worker_end_time;
              const shiftKm = timesheetItem?.km;

              dataset.push({
                "Participant Name": participantNames,
                "Worker Name": workerName,
                "Shift Notes": shiftNotes ? shiftNotes : "N/A",
                "Shift Date": shiftDate ? shiftDate : "N/A",
                "Shift Length": shiftLength,
                "Shift Start Time": shiftStartTime ? shiftStartTime : "N/A",
                "Shift End Time": shiftEndTime ? shiftEndTime : "N/A",
                "Worker Start Time": workerStartTime ? workerStartTime : "N/A",
                "Worker End Time": workerEndTime ? workerEndTime : "N/A",
                "Worker Total Time":
                  workerStartTime && workerEndTime
                    ? getTimesheetLength(workerStartTime, workerEndTime)
                    : 0,
                "Travel KM": shiftKm ? shiftKm : 0,
              });
            });
          });

          const transformedArray = dataset.map((obj) => transformObject(obj));
          setDisplayShiftData(transformedArray);
          setCsvData(dataset);
          setIsLoading(false);
        })
        .catch((error) => console.log({ error }));
    }
  }, [exportDate.startDate, exportDate.endDate]);

  const handleExportCSV = () => {
    if (moment(exportDate.startDate).isAfter(exportDate.endDate)) {
      setIsLoading(false);
      toast.error("Invalid date range. Please select a valid date range.", {
        toastId: "invalidDateToast",
      });
      return;
    }

    if (csvData?.length === 0) {
      setIsLoading(false);
      toast.warning("No timesheet data to export", {
        toastId: "noTimesheetToast",
      });
      return;
    }

    if (exportDate.startDate && exportDate.endDate) {
      const csvLink = document.getElementById("export_csv");
      csvLink.click();
      setExportDate({
        startDate: null,
        endDate: null,
      });
      handleClose();
      setIsLoading(false);
    }
  };

  const handleCancelButton = (date) => {
    if (date === "startDate") {
      setExportDate((prev) => ({
        ...prev,
        startDate: null,
      }));
    } else {
      setExportDate((prev) => ({
        ...prev,
        endDate: null,
      }));
    }
    setDisplayShiftData([]);
  };
  const modalFooter = () => (
    <>
      <CustomButton
        variant='danger'
        clickHandler={() => {
          handleClose();
        }}
        label='Cancel'
      />
      <CustomButton
        clickHandler={handleExportCSV}
        showLoading={isLoading}
        isDisabled={!exportDate.startDate || !exportDate.endDate}
        label={"Export"}
        id='export_csv'
        Icon={<TiExportOutline className='h-5 w-5' />}
      />
    </>
  );

  const ExportContent = () => (
    <>
      <div className='min-w-full  p-4 py-2 flex gap-x-8 '>
        <div className='w-[40%] '>
          <label
            // htmlFor='from-date-to-to-date'
            className='block text-sm font-medium text-gray-700'
          >
            From
          </label>

          <DateRangePickerNew
            isClearable={true}
            isSingleDate
            handleChange={(obj) => {
              setExportDate((prev) => ({
                ...prev,
                startDate: obj,
              }));
            }}
            singleSelectedDate={
              exportDate.startDate && new Date(exportDate.startDate)
            }
            handleCancel={() => handleCancelButton("startDate")}
          />
        </div>
        <div className='w-[40%]'>
          <label
            // htmlFor='from-date-to-to-date'
            className='block text-sm font-medium text-gray-700'
          >
            To
          </label>

          <DateRangePickerNew
            isClearable={true}
            isSingleDate
            handleChange={(obj) => {
              setExportDate((prev) => ({
                ...prev,
                endDate: obj,
              }));
            }}
            singleSelectedDate={
              exportDate.endDate && new Date(exportDate.endDate)
            }
            handleCancel={() => handleCancelButton("endDate")}
          />
        </div>

        <CSVLink
          data={csvData}
          id='export_csv'
          className='hidden btn'
          filename={"Export TimeSheet"}
        ></CSVLink>
      </div>
      <div className='p-4 mt-3'>
        {displayShiftsData && displayShiftsData?.length > 0 && (
          <div className='py-2'>
            <h4>
              <b>Total Timesheets:</b> {displayShiftsData?.length}
            </h4>
          </div>
        )}
        <div className=' shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300 border '>
            <thead className='capitalize '>
              <tr>
                <TableHeader
                  isSort={false}
                  label={"Participant(s)  name"}
                  cssClass='normal-case'
                />
                <TableHeader isSort={false} label={"Worker name"} />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Shift date"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Shift length"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Shift start time"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Shift end time"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Worker Start Time"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Worker End Time"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Worker Total Time"}
                />
                <TableHeader
                  cssClass='text-center'
                  isSort={false}
                  label={"Travel KM"}
                />
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white '>
              {isLoading ? (
                <tr>
                  <td colSpan={10} className='bg-gray-50 py-2'>
                    <CircularLoader
                      classes='flex justify-center items-center'
                      classloader='loader-l'
                    />
                  </td>
                </tr>
              ) : displayShiftsData && displayShiftsData?.length > 0 ? (
                displayShiftsData?.map((shift, index) => (
                  <ExportCSVItem shift={shift} index={index} />
                ))
              ) : (
                displayShiftsData?.length === 0 && (
                  <tr>
                    <td colSpan={10} className='py-6 text-center text-red-600'>
                      No Data Found !
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );

  return (
    <div>
      {isShow && (
        <ModalSkeleton
          title='Export Timesheet'
          isOpen={isShow}
          cssClass='w-[60%] '
          bodyPadding='px-0'
          closeModal={() => {
            handleClose();
          }}
          modalFooter={modalFooter()}
        >
          {false ? (
            <div
              className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
            >
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            <div className='max-h-[600px] custom-scroll'>
              {" "}
              {ExportContent()}
            </div>
          )}
        </ModalSkeleton>
      )}
    </div>
  );
};

export default ExportCSVModal;
