import Header from "components/common/Header/Header";
import { LoginContext } from "helpers/LoginContext";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "components/common/Breadcrumb";
import {
  SETTINGS_BREADCRUMB_PAGES,
  SP_SETTING_BREADCRUMB,
} from "Constants/BreadcrumbConstant";
import { BiPlus } from "react-icons/bi";
import AddRegion from "./AddRegion";
import RegionTable from "./RegionTable";

const RegionSettings = () => {
  const { sideBarPadding } = useContext(LoginContext);
  const [openModal, setOpenModal] = useState(false);
  const [isRefresh, setRefresh] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
  
        <Header
          title='Region Settings'
          handleClick={() => setOpenModal(true)}
          btnLabel='Add New Region'
          icon={<BiPlus />}
          isButton
        />
        <section className='container mx-auto sm:px-6 lg:px-8 mt-3'>
          <div className='mb-5 border-gray-200 py-3'>
            <Breadcrumb
              pages={SP_SETTING_BREADCRUMB(
                SETTINGS_BREADCRUMB_PAGES.REGION_SETTING
              )}
            />
          </div>
          <div>
            <RegionTable isRefresh={isRefresh} setRefresh={setRefresh} />
          </div>
        </section>
      </div>

      {openModal && (
        <AddRegion
          openModal={openModal}
          handleClose={handleClose}
          setOpenModal={setOpenModal}
          setRefresh={setRefresh}
          isRefresh={isRefresh}
        />
      )}
    </div>
  );
};

export default RegionSettings;
