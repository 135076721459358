import React from "react";
import ModalSkeleton from "../../../../../components/Modals/ModalSkeleton";
import CircularLoader from "../../../../../components/common/CircularLoader/CircularLoader";
import { PAYROLL_PLATFORMS } from "store/constants";
import { capitalizeFirstLetter } from "Utils/utils";
import QuickbookGuide from "assets/images/quick-books-worker-id.png";

const ImageDisplayModal = ({
  isOpen,
  setOpen,
  type,
  forCall,
  customTitle = null,
}) => {
  const getImage = () => {
    switch (type) {
      case PAYROLL_PLATFORMS.XERO:
        return "https://docs.vertex360.io/assets/branding/xero_worker.png";
      case PAYROLL_PLATFORMS.QUICKBOOKS:
        return QuickbookGuide;
      default:
        return "";
    }
  };
  return (
    <ModalSkeleton
      isOpen={isOpen}
      title={
        customTitle
          ? customTitle
          : `${capitalizeFirstLetter(type?.replace("_", ""))} Worker Id`
      }
      cssClass={"w-[47%] bg-red-400"}
      closeModal={() => setOpen(false)}
    >
      {isOpen ? (
        <img src={getImage()} alt={type} />
      ) : (
        <div
          className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
        >
          <CircularLoader
            classes='flex justify-center items-center'
            classloader='loader-l'
          />
        </div>
      )}
    </ModalSkeleton>
  );
};

export default ImageDisplayModal;
