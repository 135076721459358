import React, { useEffect, useState, useContext } from "react";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import {
  addFiltersAndPagination,
  apiFormDataRequest,
  apiGetRequest,
  apiPostRequest,
} from "../../../helpers/Requests";
import { LoginContext } from "../../../helpers/LoginContext";
import {
  baseURLImage,
  getExtension,
  getPaginationObj,
  hasBlankSpaces,
} from "../../../Utils/utils";
import Search from "../../../components/common/Search/Search";
import {
  emptyPaginationObj,
  POLICIES_LIST_LIMIT,
  SORT_TYPE,
} from "../../../Constants/constant";
import PaginationComponent from "../../../components/common/Pagination/index";
import CustomButton from "../../../components/common/Button/CustomButton";
import FileUploadInput from "../../../components/common/FileUpload/FileUploadInput";
import InputField from "../../../components/common/Input/Input";
import TableHeader from "../../../components/common/Table/TableHeader";
import {
  HiOutlineDownload,
  HiOutlineEye,
  HiOutlineTrash,
} from "react-icons/hi";
import api from "../../../store/services/sp.services";
import Loader from "../../../components/common/Loader/Loader";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { saveAs } from "file-saver";
import ModalSkeleton from "../../../components/Modals/ModalSkeleton";
import Header from "../../../components/common/Header/Header";
import { BiPlus } from "react-icons/bi";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { roleAccess } from "helpers/RolesPermission";

export default function SpPolicies() {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [search, setSearch] = useState(null);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [isAddDocument, setIsAddDocument] = useState(false);
  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [userDocument, setUserDocument] = useState([]);
  const [stopMultiUpload, setStopMultiUpload] = useState(false);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [deleteLoader, setDeleteLoader] = useState(null);
  const [isFileUploadToS3, setIsFileUploadToS3] = useState(false);

  const [isUploadLoader, setIsUploadLoader] = useState(false);

  const getSpPolicies = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: getPaginationObj.page,
      });
    }
    Object.assign(filters, { per_page: POLICIES_LIST_LIMIT });
    try {
      setIsLoading(true);
      let res = await apiGetRequest(
        addFiltersAndPagination("sp/get-policies", filters)
      );
      if (res.status === 200) {
        setDocuments(res?.data?.data?.employee_documents);
        setPaginationObj(
          getPaginationObj(
            res?.data?.data?.employee_documents,
            res?.data?.data?.query_params
          )
        );
      }
      if (res.status !== 200) {
        console.log("error in fetching");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err.message);
    }
  };

  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order)
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
  }, [paginationObj]);

  useEffect(() => {
    const obj = {};
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (!search) handleFetch({ ...obj });
  }, [paginationObj.page]);

  const handleFetch = (obj) => {
    if (paginationObj.page) Object.assign(obj, { page: paginationObj.page });
    if (paginationObj.filter && !obj.hasOwnProperty("status")) {
      Object.assign(obj, { filter: paginationObj.filter });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status],
        },
      });
    }
    getSpPolicies({ ...obj });
  };

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
        }),
      600
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  const getError = (value) => {
    if (!value) return "";
    // return errors[value] && errors[value].length > 0 ? errors[value] : "";
    return errors[value] && errors[value].length > 0
      ? "The Field is required!"
      : "";
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({ ...errors, [e.target.name]: `${name} is Required` });
    } else if (errors[name] && !hasBlankSpaces(value) && value.length > 0) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const isValidInputs = () => {
    const fnError = errors;
    if (!fileName || fileName.length === 0) {
      Object.assign(fnError, { FileName: "File Name is Required" });
    }
    if (!file || file.length === 0) {
      Object.assign(fnError, { File: "File is Required" });
    }
    // if (!selectedFileType || Object.keys(selectedFileType).length <= 0) {
    //   Object.assign(fnError, { FileType: "Category is Required" });
    // }
    if (Object.values(fnError).filter((x) => x !== null).length > 0) {
      setErrors({ ...fnError });
      return true;
    }
    setDisabledButton(false);
    return false;
  };

  const handleError = () => {
    setIsUploadLoader(false);
  };

  const handleFileUploadBaseVapour = async (data) => {
    Object.assign(data, {
      name: fileName,
      // description: fileName,
    });
    setStopMultiUpload(true);
    try {
      setIsUploadLoader(true);
      const res = await apiPostRequest("sp/upload-policy", data);
      if (res) {
        setIsFileUploadToS3(false);
        if (res.status === 200) {
          handleClear();
          setIsUploadLoader(false);
          const obj = userDocument;
          obj.unshift(res.data.document);
          setUserDocument([...obj]);
          handleCloseAddDocument();

          const payload = {
            // sort_field: sortBy,
            // sort_order: sortDescending ? SORT_TYPE.ASC : SORT_TYPE.DESC,
          };

          if (search !== null) {
            Object.assign(payload, {
              search,
              page: 1,
            });
          }
          handleFetch(payload);
          // toast.success("Document uploaded successfully");
          setDisabledButton(true);
          setStopMultiUpload(false);
        }
      }
      setIsUploadLoader(false);
    } catch (err) {
      console.log(err);
      setIsUploadLoader(false);
      setIsFileUploadToS3(false);
    }
  };

  const handleSubmit = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (isValidInputs()) return;
    setIsUploadLoader(true);
    setIsFileUploadToS3(true);
  };

  const handleCloseAddDocument = () => {
    setIsAddDocument(false);
  };

  const handleClear = () => {
    setFile("");
    setFileName("");
  };

  useEffect(() => {
    if (Object.values(errors).filter((x) => x !== null).length > 0) {
      setDisabledButton(true);
    } else if (file && fileName) setDisabledButton(false);
  }, [errors, disabledButton, file, fileName]);

  const handleDelete = async (uuid) => {
    setDeleteLoader(uuid);
    try {
      const res = await api.deleteSpPolicy({ uuid });
      if (res) {
        toast.success(res.message);
        const objDocuments = documents.data;
        const index = objDocuments.findIndex((x) => x.uuid === uuid);
        if (index !== -1) {
          objDocuments.splice(index, 1);
          setDocuments(documents, { data: objDocuments });
        }
      }
      setDeleteLoader(null);
    } catch (err) {
      setDeleteLoader(null);
      console.log("err", err);
    }
  };

  const documentsToMap = useMemo(() => {
    if (documents.data) {
      return documents.data;
    }
    return [];
  }, [documents]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginationObj.page]);

  const modalFooter = () => {
    return (
      <CustomButton
        isDisabled={disabledButton || stopMultiUpload || isUserGracePeriodOver}
        showLoading={isLoading}
        clickHandler={handleSubmit}
        label='Upload Document'
      />
    );
  };

  return (
    <div className='min-h-full'>
      <SideBar />
      <main>
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300 flex flex-col"
              : "lg:pl-64 duration-300 flex flex-col"
          }`}
        >
          <Header
            title='Policies'
            {...(roleAccess(
              [],
              PERMISSION_KEYS.POLICy,
              PERMISSION_KEYS_CHILDREN.POLICY.CREATE
            ) && {
              handleClick: () => setIsAddDocument(true),
              btnLabel: "Policy",
              icon: <BiPlus />,
              isButton: true,
            })}
          />
          <div>
            <main className='flex-1 max-w-7xl mx-auto'>
              <div className='pb-2'>
                <div className='flex flex-col'>
                  <div className='px-4 sm:px-6 lg:px-8 flex justify-between items-center py-3 sticky top-[75px] bg-white'>
                    <div className='w-1/3 md:w-2/12 lg:w-2/12' />
                    <Search setSearch={setSearch} search={search} />
                  </div>
                  <div className='px-4 sm:px-6 lg:px-8 overflow:hidden22'>
                    <div className='inline-block min-w-full py-2 align-middle'>
                      <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <div className='table-wrp block'>
                          <table className='min-w-full divide-y divide-gray-300 table-auto capitalize'>
                            <thead className='sticky top-[137px] bg-gray-50 capitalize'>
                              <tr>
                                <TableHeader
                                  sortName='employee_name'
                                  flipSort={flipSort}
                                  sortBy={sortBy}
                                  sortDescending={sortDescending}
                                  label={"Policies"}
                                />
                                <th
                                  scope='col'
                                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-blue-50'
                                >
                                  Category
                                </th>
                                <th
                                  scope='col'
                                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-blue-50'
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className='bg-white divide-y divide-gray-200'>
                              {isLoading ? (
                                <tr>
                                  <td colSpan={3} className='bg-gray-50 py-2'>
                                    <CircularLoader
                                      classes='flex justify-center items-center'
                                      classloader='loader-l'
                                    />
                                  </td>
                                </tr>
                              ) : documentsToMap &&
                                documentsToMap.length > 0 ? (
                                documentsToMap.map((document, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className='even:bg-white odd:bg-gray-50'
                                    >
                                      <td className='capitalize whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-500'>
                                        {document.name}
                                      </td>
                                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                        {/* {document.file_type} */}
                                        {document.category_name}
                                      </td>
                                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex items-center gap-x-3'>
                                        {document.link && (
                                          <div className='gap-x-3 flex'>
                                            <a
                                              href={`${baseURLImage(
                                                document.link
                                              )}`}
                                              without={"true"}
                                              rel='noopener noreferrer'
                                              target='_blank'
                                              className='icon-eye-wrapper'
                                              title='View'
                                            >
                                              <HiOutlineEye
                                                className='icon-size'
                                                title='View'
                                              />
                                            </a>
                                            <span
                                              onClick={() =>
                                                saveAs(
                                                  `${baseURLImage(
                                                    document.link
                                                  )}`,
                                                  `${
                                                    document.name
                                                      ? document.name
                                                      : getExtension(
                                                          document.link
                                                        )
                                                  }.`
                                                )
                                              }
                                              className='cursor-pointer icon-download-wrapper'
                                              title='Download'
                                            >
                                              <HiOutlineDownload className='icon-size' />
                                            </span>
                                          </div>
                                        )}

                                        {roleAccess(
                                          [],
                                          PERMISSION_KEYS.POLICy,
                                          PERMISSION_KEYS_CHILDREN.POLICY.DELETE
                                        ) && (
                                          <>
                                            {deleteLoader &&
                                            deleteLoader === document.uuid ? (
                                              <Loader
                                                isSmallView
                                                cssClass='text-black'
                                              />
                                            ) : (
                                              <span
                                                role='button'
                                                tabIndex={0}
                                                onClick={() => {
                                                  if (isUserGracePeriodOver) {
                                                    return;
                                                  }
                                                  handleDelete(document.uuid);
                                                }}
                                                className='inline-block cursor-pointer icon-delete-wrapper'
                                                title='Delete'
                                              >
                                                <HiOutlineTrash className='icon-size' />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : !isLoading && documentsToMap.length === 0 ? (
                                <tr>
                                  <td
                                    colSpan={3}
                                    className='py-6 text-center text-red-600'
                                  >
                                    No data found !
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {documentsToMap.length > 0 && (
                <div className='bg-white px-4 sm:px-6 lg:px-8 max-w-7xl pb-10'>
                  <PaginationComponent
                    page={paginationObj.page}
                    activeListLength={documentsToMap}
                    limit={POLICIES_LIST_LIMIT}
                    totalList={paginationObj.total}
                    handlePagination={(e) => {
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                      handleFetch({}, sortBy);
                    }}
                    totalPages={paginationObj.totalPages}
                  />
                </div>
              )}
            </main>
          </div>
          <ModalSkeleton
            modalFooter={modalFooter()}
            isOpen={isAddDocument}
            closeModal={() => {
              // setIsAddDocument(false)
              handleCloseAddDocument();
              setErrors([]);
              handleClear();
            }}
            cssClass='w-[50%]'
            title='Add Policy'
          >
            <div className='space-y-2'>
              <div>
                <FileUploadInput
                  type='any'
                  handleFile={(e) => setFile(e)}
                  size='10'
                  file={file}
                  handleDelete={() => setFile("")}
                  isDisabled={isLoading}
                  isVapourFileUpload={true}
                  isFileUploadToS3={isFileUploadToS3}
                  handleFileUploadBaseVapour={handleFileUploadBaseVapour}
                  handleError={handleError}
                />
                {getError("File") && (
                  <span className='errorMessage '>{getError("File")}</span>
                )}
              </div>
              <div className='mt-4 flex flex-col items-start justify-start'>
                <div className='did-floating-label-content w-1/2'>
                  <InputField
                    value={fileName}
                    name='FileName'
                    blurHandler={handleBlur}
                    label='File Name'
                    type='text'
                    // placeHolder="File Name"
                    handleChange={setFileName}
                    ErrorMessage={getError("FileName")}
                  />
                </div>
              </div>
            </div>
          </ModalSkeleton>
        </div>
      </main>
    </div>
  );
}
