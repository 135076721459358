import React, { useState, useEffect } from "react";
import { BiPencil } from "react-icons/bi";
import SelectOption from "components/common/Select/Select";
import TableHeader from "components/common/Table/TableHeader";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { portalTypeOptions } from "Constants/constant";

const RolesPermissionsList = (props) => {
  const {
    setSelectedPermissionUUID,
    setIsOpenModal,
    roleAndPermissionsList,
    rolesAndPermissionslistRequest,
    listingRoleAndPerLoader,
    selectedPortalFilter,
    setSelectedPortalFilter,
  } = props;

  const [userRoles, setUserRoles] = useState([...roleAndPermissionsList]);

  useEffect(() => {
    if (roleAndPermissionsList) {
      setUserRoles([...roleAndPermissionsList]);
    }
  }, [roleAndPermissionsList]);

  useEffect(
    () => rolesAndPermissionslistRequest({ type: selectedPortalFilter.value }),
    [selectedPortalFilter]
  );

  return (
    <div className='max-w-7xl mx-auto'>
      <div
        style={{ paddingTop: "15px", top: "70px" }}
        className='sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center sticky py-2 bg-white z-[6]'
      >
        <div className='w-full sm:w-1/3 md:w-2/12 lg:w-2/12 mb-3 sm:mb-0'>
          <SelectOption
            placeHolder={"Portal Type"}
            isSearch={false}
            cssClass='z-20'
            data={portalTypeOptions}
            handleChange={setSelectedPortalFilter}
            selectedOption={selectedPortalFilter}
          />
        </div>
      </div>
      <div className='sm:px-6 lg:px-8'>
        <div className='flex flex-col'>
          <div className='min-w-full'>
            <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <div className='table-wrp block'>
                {listingRoleAndPerLoader ? (
                  <div
                    className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                  >
                    <CircularLoader
                      classes='flex justify-center items-center'
                      classloader='loader-l'
                    />
                  </div>
                ) : (
                  <table className='min-w-full divide-y divide-gray-300 capitalize'>
                    <thead
                      style={{ top: "130px" }}
                      className='bg-gray-50 sticky z-[5] capitalize'
                    >
                      <tr>
                        <TableHeader
                          isSort={false}
                          sortName='title'
                          label={"Role Title"}
                        />
                        <TableHeader
                          cssClass='sm:pl-3 '
                          isSort={false}
                          label={"Portal Type"}
                        />

                        <th
                          scope='col'
                          className='text-gray-900 py-3.5 pl-3 pr-4 text-left bg-blue-50 text-sm font-semibold'
                        >
                          Actions
                          <span className='sr-only'>Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                      {userRoles !== null &&
                        userRoles
                          ?.filter(
                            (x) =>
                              selectedPortalFilter &&
                              selectedPortalFilter.value === x.type
                          )
                          ?.map((item, index) => (
                            <tr
                              key={index}
                              className='even:bg-white odd:bg-gray-50'
                            >
                              <td className='whitespace-nowrap py-4 px-3 text-sm'>
                                {item?.name}
                              </td>
                              <td className='whitespace-nowrap py-4 px-3 text-sm'>
                                {
                                  portalTypeOptions.find(
                                    (x) => x.value === item.type
                                  ).label
                                }
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <button
                                  className='bg-white hover:bg-green-500 hover:text-white text-green-600 rounded-md'
                                  onClick={() => {
                                    setSelectedPermissionUUID(item.id);
                                    setIsOpenModal(true);
                                  }}
                                >
                                  <div
                                    className='icon-pencil-wrapper'
                                    title='Edit'
                                  >
                                    <BiPencil className='icon-size' />
                                  </div>
                                </button>
                              </td>
                            </tr>
                          ))}
                      {userRoles?.length === 0 && (
                        <tr>
                          <td
                            colSpan={7}
                            className='py-6 text-center text-red-600'
                          >
                            No data found !
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesPermissionsList;
