import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import SelectOption from "components/common/Select/Select";
import CustomButton from "components/common/Button/CustomButton";
import SwitchInput from "components/common/Input/SwitchInput";
import {
  CLAIM_TYPE_OPTIONS,
  DELIVERED_SERVICE_STATUS_OPTIONS,
  DUMMY_REGEX_EXP,
  LOADER_STATUS,
  REGEX_HHH_MM,
} from "Constants/constant";
import { NumericFormat } from "react-number-format";
import { LoginContext } from "helpers/LoginContext";

const DeliveredServiceModal = (props) => {
  const {
    openModal,
    closeModal,
    serviceSupportItems,
    funding,
    createDeliveredService,
    storeDeliveredServiceLoader,
    participant,
    storeDeliveredServiceError,
    editDeliveredService,
    fetchServicesLoader,
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [serviceSupportItem, setServiceSupportItem] = useState(null);
  const [status, setStatus] = useState(DELIVERED_SERVICE_STATUS_OPTIONS[9]);
  const [claimType, setClaimType] = useState(null);
  const [approvedByClient, setApprovedByClient] = useState(false);
  const [storeRenderLoader, setStoreRenderLoader] = useState(LOADER_STATUS.NA);

  useEffect(() => {
    if (status !== null) {
      setValue("status", status.value);
    } else {
      clearErrors("status");
    }
  }, [status]);

  useEffect(() => {
    if (claimType !== null) {
      setValue("claim_type", claimType.value);
      clearErrors("claim_type");
    } else {
      clearErrors("claim_type");
    }
  }, [claimType]);

  useEffect(() => {
    if (
      !storeDeliveredServiceLoader &&
      storeRenderLoader !== LOADER_STATUS.NA &&
      storeDeliveredServiceError.length <= 0
    ) {
      handleClose();
    }
  }, [storeDeliveredServiceLoader]);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const watchFormData = watch();

  useEffect(() => {
    if (serviceSupportItem) {
      clearErrors("service_support_id");
      setValue("service_support_id", serviceSupportItem.id);
      setValue(
        "service_category_id",
        serviceSupportItem?.service_sub_category?.service_category?.name || ""
      );
      setValue(
        "service_sub_category_id",
        serviceSupportItem?.service_sub_category?.title || ""
      );
      setValue(
        "external_sp_id",
        serviceSupportItem?.external_service_provider?.name || "Not Assigned"
      );
      setValue(
        "total_quantity",
        serviceSupportItem && serviceSupportItem.unit === "H"
          ? `${
              serviceSupportItem.remain_total_hours < 10
                ? `0${serviceSupportItem.remain_total_hours}`
                : serviceSupportItem.remain_total_hours
            }:${
              serviceSupportItem.remain_total_mint < 10
                ? `0${serviceSupportItem.remain_total_mint}`
                : serviceSupportItem.remain_total_mint
            }`
          : parseInt(serviceSupportItem?.remain_quantity || 0)
      );
      setValue(
        "rate_amount",
        `$${
          serviceSupportItem.alternative_rate &&
          parseInt(serviceSupportItem.alternative_rate) > 0
            ? serviceSupportItem.alternative_rate
            : serviceSupportItem.original_rate
        }`
      );
      setValue("total_amount", "$0");
      clearErrors([
        "total_quantity",
        "rate_amount",
        "total_amount",
        "service_sub_category_id",
        "service_category_id",
      ]);
    } else {
      setValue("service_support_id", null);
    }
  }, [serviceSupportItem]);

  useEffect(() => {
    if (editDeliveredService) {
      reset({
        total_budget_amount: editDeliveredService.total_budget_amount,
        funding_id: funding.contract_id,
      });
    }
  }, [editDeliveredService]);

  // useEffect(() => {
  //   if (watchFormData.delivered_date) {
  //     clearErrors(["delivered_to", "delivered_from"]);
  //   }
  //   if (watchFormData.delivered_to && watchFormData.delivered_from) {
  //     clearErrors(["delivered_date"]);
  //   }
  // }, [
  //   watchFormData.delivered_date,
  //   watchFormData.delivered_to,
  //   watchFormData.delivered_from,
  // ]);

  const handleClose = () => {
    reset();
    setServiceSupportItem(null);
    // setSubCatagory(null);
    closeModal();
  };

  const getValidionQuantity = () => {
    const quantity = parseInt(watchFormData.delivered_quantity, 10);
    if (serviceSupportItem && watchFormData.delivered_quantity) {
      switch (serviceSupportItem.unit) {
        case "E":
          return /^[1-9]\d*$/i.test(quantity) ? false : DUMMY_REGEX_EXP;
        case "D":
          return /^(?!0)(?!\d{4}$)(?![4-9]\d{2}$)(?!3[7-9]\d$)(?!36[6-9]$)\d*$/i.test(
            quantity
          )
            ? false
            : DUMMY_REGEX_EXP;
        case "M":
          return /(^0?[1-9]$)|(^1[0-2]$)/i.test(quantity)
            ? false
            : DUMMY_REGEX_EXP;
        case "W":
          return /^[1-7]$/i.test(quantity) ? false : DUMMY_REGEX_EXP;
        case "YR":
          return /(^0?[1-9]$)|(^1[0]$)/i.test(quantity)
            ? false
            : DUMMY_REGEX_EXP;
        case "H":
          return REGEX_HHH_MM.test(watchFormData.delivered_quantity)
            ? false
            : DUMMY_REGEX_EXP;
        default:
          return DUMMY_REGEX_EXP;
      }
    }
    return DUMMY_REGEX_EXP;
  };

  const getQuantityInputType = () => {
    if (serviceSupportItem) {
      const unit = serviceSupportItem.unit || "E";
      if (
        unit === "E" ||
        unit === "W" ||
        unit === "D" ||
        unit === "M" ||
        unit === "YR"
      ) {
        return "number";
      } else if (unit === "H") return "text";
    }
    return "number";
  };

  const getQuantityValidationError = () => {
    if (serviceSupportItem) {
      switch (serviceSupportItem.unit) {
        case "E":
          return "quantity should be integer number";
        case "M":
          return "quantity should be 1-12 number";
        case "W":
          return "quantity should be 1-7 number";
        case "D":
          return "quantity should be 1-365 number";
        case "YR":
          return "quantity should be 1-10 number";
        case "H":
          return /^(?:0{0,2}:)?0{0,2}$/i.test(watchFormData.delivered_quantity)
            ? "hours/mins must be greater than (000:00 - 00:00 - 0:00)"
            : "Hours/Mins should be in valid format (HHH:MM - HH:MM - H:MM) with max limit of (200:59)";
        default:
          return "This field is required!";
      }
    }
    return "This field is required!";
  };

  useEffect(() => {
    if (serviceSupportItem && watchFormData.delivered_quantity) {
      const { unit } = serviceSupportItem;
      const rateAmount =
        serviceSupportItem.alternative_rate &&
        parseInt(serviceSupportItem.alternative_rate) > 0
          ? serviceSupportItem.alternative_rate
          : serviceSupportItem.original_rate;
      if (
        unit === "E" ||
        unit === "W" ||
        unit === "D" ||
        unit === "M" ||
        unit === "YR"
      ) {
        if (watchFormData.total_quantity >= watchFormData.delivered_quantity) {
          setValue(
            "total_amount",
            `$${parseFloat(
              parseFloat(rateAmount) *
                parseInt(watchFormData.delivered_quantity)
            ).toFixed(2)}`
          );
        } else {
          setValue("total_amount", "$0");
        }
      } else if (
        watchFormData.delivered_quantity &&
        unit === "H" &&
        REGEX_HHH_MM.test(watchFormData.delivered_quantity) &&
        getMaxValidation() !== watchFormData.delivered_quantity.length - 1
      ) {
        const time = watchFormData.delivered_quantity.split(":");
        const hoursRate = parseFloat(rateAmount * parseInt(time[0], 10));
        const minutes = parseFloat(parseFloat(time[1]) / 60);
        const minutesRate = parseFloat(minutes * rateAmount);
        setValue(
          "total_amount",
          `$${parseFloat(hoursRate + minutesRate).toFixed(2)}`
        );
      } else {
        setValue("total_amount", "$0");
      }
      setValue("rate_amount", rateAmount);
    } else if (
      !watchFormData.delivered_quantity ||
      watchFormData.delivered_quantity.length === 0
    ) {
      setValue("total_amount", "$0");
    } else setValue("total_amount", "$0");

    const isValid = getValidionQuantity() instanceof RegExp;
    if (!isValid) clearErrors("delivered_quantity");
  }, [watchFormData.delivered_quantity]);

  const getUnitAbb = () => {
    if (serviceSupportItem && serviceSupportItem.unit === "H") return " Hours ";
    return " Quantity ";
  };

  const getMaxValidation = () => {
    if (getUnitAbb() === " Hours ") {
      if (
        watchFormData.hasOwnProperty("total_quantity") &&
        watchFormData.total_quantity &&
        watchFormData.hasOwnProperty("delivered_quantity") &&
        watchFormData.delivered_quantity &&
        REGEX_HHH_MM.test(watchFormData.total_quantity) &&
        REGEX_HHH_MM.test(watchFormData.delivered_quantity)
      ) {
        const arr = watchFormData.total_quantity.split(":");
        const hours = parseInt(arr[0]);
        const mins = parseInt(arr[1]);
        const arr1 = watchFormData.delivered_quantity.split(":");
        const hours1 = parseInt(arr1[0]);
        const mins1 = parseInt(arr1[1]);
        if (hours1 > hours) {
          return watchFormData.delivered_quantity.length - 1;
        } else if (hours === hours1 && mins1 > mins) {
          return watchFormData.delivered_quantity.length - 1;
        }
        return watchFormData.delivered_quantity.length;
      } else {
        return "100000000000";
      }
    } else if (
      watchFormData.hasOwnProperty("total_quantity") &&
      watchFormData.total_quantity &&
      watchFormData.hasOwnProperty("delivered_quantity") &&
      watchFormData.delivered_quantity
    ) {
      return watchFormData.delivered_quantity > watchFormData.total_quantity
        ? watchFormData.delivered_quantity.length - 2
        : "100000000000";
    }
    return "100000000000";
  };

  const checkTotalAmount = () => {
    // if (serviceSupportItem && watchFormData.total_amount) {
    //   const ttlAmount = watchFormData.total_amount.replace("$", "");
    //   if (
    //     parseFloat(serviceSupportItem.amount_allocated) < parseFloat(ttlAmount)
    //   ) {
    //     return regex;
    //   }
    //   return false;
    // }
    return false;
  };

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (editDeliveredService) {
      Object.assign(data, {
        plan_budget_uuid: editDeliveredService.uuid,
      });
    }
    Object.assign(data, {
      funding_id: funding.uuid,
      pt_id: participant ? participant.id : "1",
    });
    if (serviceSupportItem) {
      Object.assign(data, {
        service_category_id:
          serviceSupportItem?.service_sub_category?.service_category?.id || "",
        service_sub_category_id:
          serviceSupportItem?.service_sub_category?.id || "",
        external_sp_id:
          serviceSupportItem?.external_service_provider?.id || null,
        total_amount: parseFloat(data.total_amount.replace("$", "")),
        rate_amount: parseFloat(
          serviceSupportItem.alternative_rate &&
            parseInt(serviceSupportItem.alternative_rate) > 0
            ? serviceSupportItem.alternative_rate
            : serviceSupportItem.original_rate
        ),
      });
    }
    setStoreRenderLoader(LOADER_STATUS.PENDING);
    createDeliveredService({
      data,
      isUpdate: editDeliveredService ? true : false,
    });
  };

  const modalFooter = () => (
    <CustomButton
      showLoading={storeDeliveredServiceLoader}
      isDisabled={storeDeliveredServiceLoader || isUserGracePeriodOver}
      label={editDeliveredService ? "Update" : "Submit"}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById(
          "deliveredServicesButtonModal"
        );
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <ModalSkeleton
      title={
        editDeliveredService
          ? "Update Delivered Service"
          : "Create New Delivered Service"
      }
      modalFooter={modalFooter()}
      isOpen={openModal}
      closeModal={handleClose}
      cssClass='w-[55%]'
    >
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500 mb-2'>
          Please enter the following details to
          {editDeliveredService ? " update" : " create"} Delivered Service
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Funding ID
              </label>
              <input
                {...register("funding_id", {
                  required: true,
                  value: funding.contract_id,
                })}
                style={{
                  backgroundColor: "#4b6bb710",
                }}
                value={funding.contract_id}
                disabled
                readOnly
                name='funding_id'
                id=''
                className='did-floating-input mt-1'
              />
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='name'
                className='block text-sm font-medium text-gray-700'
              >
                Name
              </label>
              <div className='mt-1'>
                <input
                  disabled
                  readOnly
                  style={{ backgroundColor: "#4b6bb710" }}
                  value={`${participant.first_name} ${participant.last_name}`}
                  type='text'
                  name='name'
                  id='name'
                  className='capitalize did-floating-input bg-[#4b6bb710]'
                />
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <div className='hidden'>
                <select
                  {...register("service_support_id", {
                    required: true,
                  })}
                  defaultValue={null}
                  name='service_support_id'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Service Support Item
              </label>
              <SelectOption
                // isClearable={true}
                cssClass='mt-1'
                isSearch={false}
                data={
                  serviceSupportItems && !fetchServicesLoader
                    ? serviceSupportItems ?? []
                    : []
                }
                selectedOption={serviceSupportItem}
                handleChange={(e) => {
                  if (e !== null) {
                    setServiceSupportItem(e);
                  } else {
                    setServiceSupportItem(null);
                    setValue("service_support_id", null);
                  }
                }}
                placeHolder={fetchServicesLoader ? "Loading..." : ""}
                loading={fetchServicesLoader}
              />
              {errors?.service_support_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Plan Catagory
              </label>
              <input
                {...register("service_category_id", {
                  required: true,
                  disabled: true,
                  readOnly: true,
                })}
                style={{ backgroundColor: "#4b6bb710" }}
                disabled
                readOnly
                name='service_category_id'
                id=''
                className='did-floating-input mt-1 capitalize'
              />
              {errors?.service_category_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Plan Subcatagory
              </label>
              <input
                {...register("service_sub_category_id", {
                  required: true,
                  disabled: true,
                  readOnly: true,
                })}
                style={{ backgroundColor: "#4b6bb710" }}
                disabled
                readOnly
                name='service_sub_category_id'
                id=''
                className='did-floating-input mt-1 capitalize'
              />
              {errors?.service_sub_category_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='total_budget_amount'
                className='block text-sm font-medium text-gray-700'
              >
                Service Provider
              </label>
              <div className='mt-1'>
                <input
                  {...register("external_sp_id", {
                    required: true,
                    disabled: true,
                    readOnly: true,
                  })}
                  style={{ backgroundColor: "#4b6bb710" }}
                  readOnly
                  disabled
                  type='text'
                  name='external_sp_id'
                  id='external_sp_id'
                  className='did-floating-input mt-1 bg-[#4b6bb710] capitalize'
                />
                {errors?.external_sp_id?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("status", {
                    required: true,
                  })}
                  name='status'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Status
              </label>
              <SelectOption
                isDisabled={true}
                cssClass='mt-1'
                isSearch={false}
                data={DELIVERED_SERVICE_STATUS_OPTIONS}
                selectedOption={status}
                handleChange={setStatus}
                // placeHolder="Select status..."
              />
              {errors?.service_category_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Total {getUnitAbb()}
              </label>
              <input
                {...register("total_quantity", {
                  required: true,
                })}
                style={{
                  backgroundColor: "#4b6bb710",
                }}
                disabled
                readOnly
                name='total_quantity'
                id=''
                className='did-floating-input mt-1'
              />
              {/* {errors?.total_quantity?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required
                </span>
              )} */}
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='delivered_quantity'
                className='block text-sm font-medium text-gray-700'
              >
                Delivered {getUnitAbb()}
              </label>
              <div className='mt-1'>
                <input
                  {...register("delivered_quantity", {
                    required: true,
                    pattern: getValidionQuantity(),
                    onChange: (e) => {
                      if (getQuantityInputType() === "number") {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value, 10)
                        );
                      }
                    },
                    maxLength: getMaxValidation(),
                  })}
                  //   maxLength={getMaxValidation()}
                  type={getQuantityInputType()}
                  name='delivered_quantity'
                  id='delivered_quantity'
                  disabled={!serviceSupportItem}
                  className={`${
                    !serviceSupportItem && "bg-[#4b6bb710]"
                  } did-floating-input`}
                />
                {getValidionQuantity() instanceof RegExp &&
                  errors?.delivered_quantity?.type === "pattern" && (
                    <span className='text-sm text-red-500'>
                      {getQuantityValidationError()}
                    </span>
                  )}
                {errors?.delivered_quantity?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
                {errors?.delivered_quantity?.type === "maxLength" && (
                  <span className='text-sm text-red-500'>
                    Delivered {getUnitAbb()}
                    should be Equal or less than Total
                    {getUnitAbb()}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Rate
              </label>
              <input
                {...register("rate_amount", {
                  required: true,
                })}
                className='hidden'
              />
              <NumericFormat
                type='text'
                value={watchFormData.rate_amount}
                disabled={true}
                className={`did-floating-input mt-1`}
                prefix={"$"}
                readOnly={true}
                style={{
                  backgroundColor: "#4b6bb710",
                }}
              />
              {errors?.rate_amount?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='total_budget_amount'
                className='block text-sm font-medium text-gray-700'
              >
                Total Amount
              </label>
              <div className='mt-1'>
                <input
                  {...register("total_amount", {
                    required: true,
                    pattern: checkTotalAmount(),
                    // setValueAs: (value) =>
                    //   `$${parseFloat(value.replace("$", "")).toFixed(2)}`,
                    // // value ? value.toFixed(2) : null,
                  })}
                  style={{
                    backgroundColor: "#4b6bb710",
                  }}
                  disabled
                  readOnly
                  className='did-floating-input'
                />
                {errors?.total_amount?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
                {errors?.total_amount?.type === "pattern" && (
                  <span className='text-sm text-red-500'>
                    Service Support amount exceed!
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("claim_type", {
                    required: true,
                  })}
                  name='claim_type'
                  id='claim_type'
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Claim Type
              </label>
              <SelectOption
                cssClass='mt-1'
                isSearch={false}
                data={CLAIM_TYPE_OPTIONS}
                selectedOption={claimType}
                handleChange={setClaimType}
                // placeHolder="Select Claim Type..."
              />
              {errors?.claim_type?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Delivered Date
              </label>
              <input
                {...register("delivered_date", {
                  required: true,
                  // watchFormData &&
                  // Object.keys(watchFormData).length > 0 &&
                  // watchFormData.delivered_from.length === 0 &&
                  // watchFormData.delivered_to.length === 0,
                })}
                type='date'
                min={moment().format("DD-MM-YYYY")}
                name='delivered_date'
                id=''
                className='did-floating-input mt-1'
              />
              {errors?.delivered_date?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Provider Invoice Number
              </label>
              <input
                {...register("provider_invoice_number", {
                  required: false,
                  pattern: "[0-9]*",
                  onChange: (e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value, 10));
                  },
                })}
                type='number'
                name='provider_invoice_number'
                id=''
                className='did-floating-input mt-1'
              />
              {errors?.provider_invoice_number?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='total_budget_amount'
                className='block text-sm font-medium text-gray-700'
              >
                Provider Invoice Date
              </label>
              <div className='mt-1'>
                <input
                  {...register("provider_invoice_date", {
                    required: false,
                  })}
                  // placeholder="0.00"
                  type='date'
                  min={moment().format("DD-MM-YYYY")}
                  className='did-floating-input'
                />
                {errors?.provider_invoice_date?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='total_budget_amount'
                className='block text-sm font-medium text-gray-700'
              >
                NDIS Claim Date
              </label>
              <div className='mt-1'>
                <input
                  {...register("ndis_claim_date", {
                    required: false,
                  })}
                  type='date'
                  min={moment().format("DD-MM-YYYY")}
                  name='ndis_claim_date'
                  id='ndis_claim_date'
                  className='did-floating-input mt-1 bg-[#4b6bb710]'
                />
                {errors?.ndis_claim_date?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
            {/* <div className="col-span-1">
              <div className="grid grid-cols-2 gap-x-4">
                <div className="col-span-1">
                  <label
                    htmlFor="total_budget_amount"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Delivered From
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("delivered_from", {
                        required:
                          watchFormData &&
                          Object.keys(watchFormData).length > 0 &&
                          watchFormData.delivered_date.length === 0,
                      })}
                      type="date"
                      min={moment().format("DD-MM-YYYY")}
                      className="did-floating-input"
                    />
                    {errors?.delivered_from?.type === "required" && (
                      <span className="text-sm text-red-500">
                        This field is required!
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="total_budget_amount"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Delivered To
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("delivered_to", {
                        required:
                          watchFormData &&
                          Object.keys(watchFormData).length > 0 &&
                          watchFormData.delivered_date.length === 0,
                      })}
                      type="date"
                      min={moment().format("DD-MM-YYYY")}
                      className="did-floating-input"
                    />
                    {errors?.delivered_to?.type === "required" && (
                      <span className="text-sm text-red-500">
                        This field is required!
                      </span>
                    )}
                  </div>{" "}
                </div>
              </div>
            </div> */}
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <label className='block text-sm font-medium text-gray-700'>
                Notes
              </label>
              <textarea
                rows={3}
                name='note'
                id='note'
                className='overflow-y-auto px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register("note", { required: false })}
              />
              {errors?.note?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <SwitchInput
                handleChange={setApprovedByClient}
                checked={approvedByClient}
                label='Approved By client'
                subLabel=''
              />
            </div>
          </div>

          <button
            type='submit'
            className='hidden sr-only'
            id='deliveredServicesButtonModal'
          />
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default DeliveredServiceModal;
