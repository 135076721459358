import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  stripeDefaultPaymentMethod,
  stripeListAllCardRequest,
} from "store/actions/sp.actions";
import { getPaymentSettings, getUser } from "store/selector/auth.selector";
import {
  getStripeGetDefaultPaymentMethodLoader,
  getStripeListAllCards,
} from "store/selector/sp.selector";

const useDefaultPaymentMethod = () => {
  const stripeDefaultPaymentMethodLaoder = useSelector(
    getStripeGetDefaultPaymentMethodLoader
  );
  const paymentSettings = useSelector(getPaymentSettings);
  const cardsListData = useSelector(getStripeListAllCards);
  const currentLoggedInUser = useSelector(getUser);
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
     if(currentLoggedInUser.userType==="sp_admin"){
      dispatch(stripeListAllCardRequest());
       dispatch(stripeDefaultPaymentMethod());
     
     }
    }
  }, []);

  const getDefaultCardDetails = () => {
    if (
      paymentSettings == null ||
      !paymentSettings ||
      paymentSettings.default_payment_method_id === null
    ) {
      return null;
    }

    const data = cardsListData.find(
      (x) => x.id === paymentSettings.default_payment_method_id
    );
    if (data) {
      return { ...data.card, card_name: data.metadata?.card_name || "" };
    }
    return null;
  };

  return {
    stripeDefaultPaymentMethodLaoder,
    getDefaultCardDetails: getDefaultCardDetails(),
    paymentSettings,
    cardsListData,
  };
};

export default useDefaultPaymentMethod;
