import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../common/Button/CustomButton";
import ModalSkeleton from "../../../Modals/ModalSkeleton";
import { LOADER_STATUS } from "../../../../Constants/constant";
import { getCreateAppointmentLoading } from "../../../../store/selector/participant.selector";
import { participantAppointmentCreateCall } from "../../../../store/actions/participant.actions";

const Appointment = ({
  isOpen,
  closeModal,
  participantInfo,
  appointment,
  isUpdate = false,
  updateAppointment,
  isUserGracePeriodOver,
}) => {
  const pt_id = participantInfo.id;
  const dispatch = useDispatch();
  const isLoading = useSelector(getCreateAppointmentLoading);
  const [createLoader, setCreateLoader] = useState(LOADER_STATUS.NA);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm();

  const watchFormData = watch();

  useEffect(() => {
    if (!isLoading && createLoader === LOADER_STATUS.PENDING) {
      setCreateLoader(LOADER_STATUS.COMPLATE);
      reset();
      clearErrors("date");
      clearErrors("time");
      clearErrors("location");
      clearErrors("notes");
      closeModal();
    }
  }, [isLoading]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    Object.assign(data, { pt_id });
    setCreateLoader(LOADER_STATUS.PENDING);
    if (isUpdate && updateAppointment) {
      updateAppointment({ ...data, appointment_id: appointment.id });
    } else {
      dispatch(participantAppointmentCreateCall(data));
    }
  };

  useEffect(() => {
    if (isOpen) {
      clearErrors("date");
      clearErrors("time");
      clearErrors("location");
      clearErrors("notes");
      reset();
    }
    if (isOpen && appointment && isUpdate) {
      Object.assign(appointment, {
        date: moment(appointment.date).format("YYYY-MM-DD"),
      });
      reset({
        ...appointment,
      });
    }
  }, [isOpen]);

  const modalFooter = () => (
    <CustomButton
      isDisabled={isLoading || isUserGracePeriodOver}
      showLoading={isLoading}
      label={isUpdate ? "Update" : "Submit"}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("appointmentButtonModal");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <ModalSkeleton
      isOpen={isOpen}
      closeModal={closeModal}
      modalFooter={modalFooter()}
      cssClass='w-[50%]'
      title={`${isUpdate ? "Update " : "Book "}an Appointment`}
    >
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500 mb-2'>
          {`Please enter the following details to ${
            isUpdate ? "update " : "book "
          }an appointment`}
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
          <div>
            <label
              htmlFor='date'
              className='block text-sm font-medium text-gray-700'
            >
              Date
            </label>
            <div className='mt-1'>
              <input
                {...register("date", {
                  required: true,
                  value: watchFormData.date,
                })}
                type='date'
                name='date'
                id='date'
                min={moment().format("DD-MM-YYYY")}
                className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
              {errors?.date?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor='time'
              className='block text-sm font-medium text-gray-700'
            >
              Time
            </label>
            <div className='mt-1'>
              <input
                {...register("time", {
                  required: true,
                })}
                type='time'
                name='time'
                id='time'
                // min={moment().format("HH:MM")}
                className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
              {errors?.time?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor='address'
              className='block text-sm font-medium text-gray-700'
            >
              Address
            </label>
            <div className='mt-1'>
              <input
                {...register("location", {
                  required: true,
                  maxLength: 254,
                })}
                type='text'
                name='location'
                id='location'
                className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
              {errors?.location?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor='notes'
              className='block text-sm font-medium text-gray-700'
            >
              Appointment Notes
            </label>
            <div className='mt-1'>
              <textarea
                {...register("notes", {
                  required: true,
                })}
                rows={3}
                name='notes'
                id='notes'
                className='focus:bg-[#4b6bb710] overflow-y-auto border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
              />
              {errors?.notes?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <button
            type='submit'
            className='hidden sr-only'
            id='appointmentButtonModal'
          />
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default Appointment;
