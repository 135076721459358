import React, { Fragment, useState, useMemo, useEffect } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon, UsersIcon } from "@heroicons/react/solid";
import { FiSearch } from "react-icons/fi";
import { apiGetRequest, apiPostRequest } from "../../../helpers/Requests";
import { baseURLImage, classNames, getNameProfile } from "../../../Utils/utils";
import Loader from "../Loader/Loader";
import { LOADER_STATUS, USER_ROLE } from "../../../Constants/constant";
import { useNavigate } from "react-router";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const SpotLightSearch = ({ handleClose, sportCoordination = [] }) => {
  const [query, setQuery] = useState(null);
  const [open, setOpen] = useState(true);
  const [filteredPeople, setFilteredPeople] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(LOADER_STATUS.NA);
  const navigate = useNavigate();

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSeach(query), 1000);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const handleSeach = async (search = null) => {
    try {
      setIsLoading(true);
      if (fetchLoader === LOADER_STATUS.NA) {
        setFetchLoader(LOADER_STATUS.PENDING);
      }
      let res = [];
      const url = roleAccess([USER_ROLE.Sp_Standard_User])
        ? `${
            search
              ? `hrm/employee-participants?page=1&per_page=30&search=${search}`
              : "hrm/employee-participants?page=1&per_page=30"
          }`
        : `${
            search
              ? `sp/participants-list?page=1&per_page=30&search=${search}`
              : "sp/participants-list?page=1&per_page=30"
          }`;
      if (sportCoordination.length === 0) {
        res = roleAccess([USER_ROLE.Sp_Standard_User])
          ? await apiGetRequest(url)
          : await apiPostRequest(url);
      } else {
        res = sportCoordination;
      }

      if (sportCoordination.length === 0) {
        if (res.data) {
          setFilteredPeople(res.data.data?.participant?.data || []);
        }
      } else {
        setFilteredPeople(res || []);
      }

      if (fetchLoader === LOADER_STATUS.PENDING) {
        setFetchLoader(LOADER_STATUS.COMPLATE);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err.message);
    }
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const participantToMap = useMemo(() => {
    if (filteredPeople.length > 0) {
      return [...filteredPeople];
    }
    return [];
  }, [filteredPeople]);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog
        as='div'
        className='relative z-50'
        onClose={() => {
          setOpen(false);
          handleClose(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-50 overflow-y-auto p-4 sm:p-6 md:p-20'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'>
              <Combobox
                onChange={(participant) => {
                  if (
                    roleAccess(
                      [],
                      PERMISSION_KEYS.PARTICIPANT,
                      PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                    )
                  ) {
                    navigate(
                      roleAccess([USER_ROLE.Sp_Standard_User])
                        ? `/participant-profile/${participant.uuid}`
                        : `/sp-profile/${participant.uuid}`
                    );
                  }
                }}
              >
                {({ activeOption }) => (
                  <>
                    <div className='relative'>
                      {isLoading ? (
                        <Loader
                          cssClass='pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-black'
                          isSmallView
                        />
                      ) : (
                        <FiSearch
                          className='pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400'
                          aria-hidden='true'
                        />
                      )}

                      <Combobox.Input
                        className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm'
                        // placeholder="Search..."
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </div>

                    {(query === "" || filteredPeople.length > 0) && (
                      <Combobox.Options
                        as='div'
                        static
                        hold
                        className='flex divide-x divide-gray-100 '
                      >
                        <div
                          className={classNames(
                            "max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4",
                            activeOption && "sm:h-96"
                          )}
                        >
                          {/* {query === "" && (
                            <h2 className="mt-2 mb-4 text-xs font-semibold text-gray-500">
                              Recent searches
                            </h2>
                          )} */}
                          <div className='-mx-2 text-sm text-gray-700'>
                            {participantToMap.map((person) => (
                              <Combobox.Option
                                as='div'
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                  classNames(
                                    "flex cursor-pointer select-none items-center rounded-md p-2",
                                    active && "bg-gray-100 text-gray-900"
                                  )
                                }
                              >
                                {({ active }) => (
                                  <>
                                    <img
                                      onError={(e) =>
                                        handleImageError(
                                          e,
                                          person.first_name,
                                          person.last_name
                                        )
                                      }
                                      src={
                                        person.profile_picture
                                          ? baseURLImage(person.profile_picture)
                                          : getNameProfile(
                                              person.first_name,
                                              person.last_name || " "
                                            )
                                      }
                                      alt=''
                                      className='h-6 w-6 flex-none rounded-full'
                                    />
                                    <span className='ml-3 flex-auto truncate capitalize'>
                                      {`${person.first_name} ${person.last_name}`}
                                    </span>
                                    {active && (
                                      <ChevronRightIcon
                                        className='ml-3 h-5 w-5 flex-none text-gray-400'
                                        aria-hidden='true'
                                      />
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </div>
                        </div>
                        {activeOption && (
                          <div className='hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex'>
                            <div className='flex-none p-6 text-center'>
                              <img
                                onError={(e) =>
                                  handleImageError(
                                    e,
                                    activeOption.first_name,
                                    activeOption.last_name
                                  )
                                }
                                src={
                                  activeOption.profile_picture
                                    ? baseURLImage(activeOption.profile_picture)
                                    : getNameProfile(
                                        activeOption.first_name,
                                        activeOption.last_name || " "
                                      )
                                }
                                alt=''
                                className='mx-auto h-16 w-16 rounded-full'
                              />
                              <h2 className='mt-3 font-semibold text-gray-900 capitalize'>
                                {`${activeOption.first_name} ${activeOption.last_name}`}
                              </h2>
                              {/* <p className="text-sm leading-6 text-gray-500">
                                {activeOption.role}
                              </p> */}
                            </div>
                            <div className='flex flex-auto flex-col justify-between p-6'>
                              <dl className='grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700'>
                                <dt className='col-end-1 font-semibold text-gray-900'>
                                  Status
                                </dt>
                                <dd>
                                  <span
                                    className={`${
                                      activeOption.status === "active"
                                        ? "bg-green-100 text-green-700"
                                        : "bg-red-100 text-red-700"
                                    } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize`}
                                  >
                                    {activeOption.status}
                                  </span>
                                </dd>
                                {roleAccess(
                                  [],
                                  PERMISSION_KEYS.PARTICIPANT,
                                  PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                    .PRIMARY_PHONE
                                ) && (
                                  <>
                                    <dt className='col-end-1 font-semibold text-gray-900'>
                                      Phone
                                    </dt>
                                    <dd>{activeOption.primary_no}</dd>
                                  </>
                                )}

                                {/* <dt className="col-end-1 font-semibold text-gray-900">
                                  URL
                                </dt> */}
                                {/* <dd className="truncate">
                                  <a
                                    href={activeOption.url}
                                    className="text-indigo-600 underline"
                                  >
                                    {activeOption.url}
                                  </a>
                                </dd> */}
                                {roleAccess(
                                  [],
                                  PERMISSION_KEYS.PARTICIPANT,
                                  PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                    .PRIMARY_EMAIL
                                ) && (
                                  <>
                                    <dt className='col-end-1 font-semibold text-gray-900'>
                                      Email
                                    </dt>
                                    <dd className='truncate'>
                                      <p
                                        // href={`mailto:${activeOption.email}`}
                                        className='text-indigo-600 underline'
                                      >
                                        {activeOption.email}
                                      </p>
                                    </dd>
                                  </>
                                )}
                              </dl>
                              {/* <button
                                type="button"
                                className="mt-6 w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Send message
                              </button> */}
                            </div>
                          </div>
                        )}
                      </Combobox.Options>
                    )}

                    {fetchLoader === LOADER_STATUS.COMPLATE &&
                      filteredPeople.length === 0 && (
                        <div className='py-14 px-6 text-center text-sm sm:px-14'>
                          <UsersIcon
                            className='mx-auto h-6 w-6 text-gray-400'
                            aria-hidden='true'
                          />
                          <p className='mt-4 font-semibold text-gray-900'>
                            No Participant found
                          </p>
                          <p className='mt-2 text-gray-500'>
                            We couldn’t find anything with that term. Please try
                            again.
                          </p>
                        </div>
                      )}
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SpotLightSearch;
