import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import LoginScreenSection from "components/common/LoginScreenSection/LoginScreenSection";
import { useDispatch } from "react-redux";
import { callLogout } from "store/actions/auth.actions";
import Email from "../../../../assets/email.png";
import CustomButton from "components/common/Button/CustomButton";
import PhoneNumberInput from "./NumberInput";
import Phone from "../../../../assets/phonet.png";

import "./Style.css";
import swal from "sweetalert";
import { LoginContext } from "helpers/LoginContext";

const TwoFANotEnabledPage = (props) => {
  const { sendOTPRequest, reSendOTPRequest, sendOTPLoading } = props;
  const [phone, setPhone] = useState("");
  const [disable, setDisable] = useState(false);
  const via = localStorage.getItem("via");

  const { unSubscribeChannels } = useContext(LoginContext);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = () => {
    swal({
      title: "Please confirm phone number?",
      text: `${phone}`,
      icon: "",
      buttons: ["No, take me back!", "Yes, go ahead!"],
      className: "swal-text",
      cancel: {
        text: "No, take me back!",
        value: null,
        visible: false,
        className: "",
        closeModal: true,
      },
      confirm: {
        text: "Yes, go ahead!",
        value: true,
        visible: true,
        // className: `${
        //   updatedAction === "cancelled" ? "bg-blue-600" "
        // }`,
        closeModal: true,
      },
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        sendOTPRequest({
          navigate,
          // phone: "923067907565",
          via: "phone",
          phone: phone,
          authentication_uuid: localStorage.getItem("authentication_uuid"),
        });
        // if (checkboxes.length > 0) {
        // changeStatus({
        //   status: updatedAction,
        //   cos_case_notes_ids: checkboxes,
        //   navigate,
        // });
        // setCheckboxes([]);
        // setUpdatedAction("");
        // }
        // } else {
        // setCheckboxes([]);
        // setUpdatedAction("");
      }
    });
    // sendOTPRequest({
    //   navigate,
    //   // phone: "923067907565",
    //   phone: phone,
    //   authentication_uuid: localStorage.getItem("authentication_uuid")
    // });
  };

  const onsubmitButtonClick = () => {
    onSubmit();
  };

  const handleLogoutClick = () => {
    unSubscribeChannels();
    dispatch(callLogout());
  };
  // console.log(sendOTPLoading, phone);
  return (
    <div className=''>
      <div className='min-h-screen flex'>
        <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 lg:w-1/2 xl:px-24'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <div>
              <h2 className='mt-6 flex justify-center mb-6'>
                <div className='rounded-full bg-[#D1E9FF] w-16 h-16'>
                  <div className='rounded-full bg-blue-200 w-12 h-12 relative top-[8px] left-[8px]'>
                    <img
                      src={via === "email" ? Email : Phone}
                      alt=''
                      className='inline px-4 relative top-[10px]'
                    />
                  </div>
                </div>
              </h2>
              <div className='text-center text-gray-900 text-3xl font-semibold mt-2'>
                Set Up 2FA
              </div>
              <div className='text-center text-gray-500 font-normal text-base mt-2'>
                <p>
                  2FA Alert! Your account needs an extra layer of security like
                  a superhero needs a cape.
                </p>
              </div>

              <div className='mt-4'>
                <label
                  for='phone-number'
                  className='block text-sm font-semibold text-gray-700'
                >
                  Enter Phone Number
                </label>
                <div className='relative mt-1 rounded-md shadow-sm'>
                  <PhoneNumberInput
                    setPhone={setPhone}
                    setDisable={setDisable}
                    phone={phone}
                  />
                </div>
              </div>
              <div className='grid-cols-1 gap-2 flex mt-6 '>
                <div className='w-full'>
                  <CustomButton
                    showLoading={sendOTPLoading}
                    isDisabled={sendOTPLoading || phone === "" || disable}
                    // sendOTPLoading
                    clickHandler={onsubmitButtonClick}
                    label='Send code'
                    cssClass='w-full
                        flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-white
                        bg-blue-dark
                        hover:bg-blue-dark
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-blue-dark'
                  />
                </div>
              </div>
              {/* <div className="text-sm flex justify-center mt-6">
                  <Link
                    to={"/"}
                    className="
                        cursor-pointer
                        font-medium
                        text-blue-dark
                        hover:text-blue-dark
                        text-sm
                      "
                  >
                    Skip this step
                  </Link>
                </div> */}
            </div>
            <div
              className='text-sm  flex justify-center items-center mt-6 space-x-3'
              onClick={handleLogoutClick}
            >
              <img src={""} alt='' className='' />
              <div className='relative top-[2px]'>
                <AiOutlineArrowLeft className=' text-gray-500 font-semibold' />
              </div>
              <div className='font-semibold text-gray-500 text-sm'>
                <span
                  className='cursor-pointer'
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                  }}
                >
                  Back to log in
                </span>
              </div>
            </div>
          </div>
        </div>
        <LoginScreenSection
          heading={`Vertex360 has streamlined our NDIS compliance to an extent that we look forward to coming to work now !`}
          userImg='use image here'
          name='Dale Florence'
          desc='NDIS Service Provider'
        />
      </div>
    </div>
  );
};

export default TwoFANotEnabledPage;
