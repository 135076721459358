import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "../../../../components/common/Button/CustomButton";
import DeliveredServiceModal from "./DeliveredServiceModal";
import DeliveredServiceTable from "./DeliveredServiceTable";

const DeliveredService = (props) => {
  const {
    selectedFunding,
    participant,
    getServiceSupportsItem,
    serviceSupportItems,
    createDeliveredService,
    storeDeliveredServiceLoader,
    storeDeliveredServiceError,
    fetchServicesLoader,
    getDeliveredServices,
    fetchDeliveredServiceLoader,
    deliveredServices,
  } = props;

  const [catalogState, setCatalogState] = useState(null);
  const [editDeliveredService, setEditDeliveredService] = useState(null);

  useEffect(() => {
    if (editDeliveredService) {
      setOpenModal(true);
    }
  }, [editDeliveredService]);

  useEffect(() => {
    if (selectedFunding && participant) {
      handleFetchSupportItems();
    }
  }, []);

  const handleFetchSupportItems = () => {
    if (selectedFunding && participant) {
      getServiceSupportsItem({
        pt_id: participant.id,
        funding_id: selectedFunding.uuid,
        cos_case_notes: false,
      });
    }
  };

  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="max-w-7xl mx-auto py-3 bg-white">
        <div>
         <div className="flex justify-between items-center px-6 bg-gray-25 border md:rounded-t-lg font-bold text-lg text-gray-900 py-4">
          <div>Delivered Services</div>
          <CustomButton
              label="Delivered Service"
              clickHandler={() => setOpenModal(true)}
              Icon={<BiPlus />}
              cssClass="border-0"
            />
         </div>
          
        <div>
          <DeliveredServiceTable
            fetchLoader={fetchDeliveredServiceLoader}
            funding={selectedFunding}
            participant={participant}
            deliveredServices={deliveredServices}
            getDeliveredServiceList={getDeliveredServices}
          />
        </div>
      </div>

      {openModal && (
        <DeliveredServiceModal
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
            setEditDeliveredService(null);
            handleFetchSupportItems();
          }}
          serviceSupportItems={serviceSupportItems}
          fetchServicesLoader={fetchServicesLoader}
          funding={selectedFunding}
          catalogState={catalogState}
          createDeliveredService={createDeliveredService}
          storeDeliveredServiceLoader={storeDeliveredServiceLoader}
          storeDeliveredServiceError={storeDeliveredServiceError}
          participant={participant}
          editDeliveredService={editDeliveredService}
        />
      )}
    </div>
  );
};

export default DeliveredService;
