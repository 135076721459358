import React from "react";
import { PAYROLL_EXPORT_STATUS } from "Constants/constant";
import PopoverContainer from "components/common/Popover/PopoverContainer";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineWarning,
} from "react-icons/ai";
import { capitalizeAllLetters, classNames, parseBooleanVal } from "Utils/utils";
import { useNavigate } from "react-router";
import { PAYROLL_PLATFORMS } from "store/constants";
import _, { isEmpty } from "lodash";

const PayrollTimesheetStatusPopover = (props) => {
  const {
    item,
    isPayrollIdExists,
    payrollType,
    isAllSuccess = null,
    isAnyFailed = null,
    isConsoleLog = false,
  } = props;

  const navigate = useNavigate();

  const payrollStatus = item[`${payrollType}_time_sheets`]
    ? item?.[`${payrollType}_time_sheets`]?.[`${payrollType}_status`]
    : null;

  const payrollMessage = item[`${payrollType}_time_sheets`]
    ? item?.[`${payrollType}_time_sheets`]?.message
    : null;

  const getPayrollProperty = (item, payrollType, property) => {
    if (item[`${payrollType}_time_sheets`] === null) {
      return null;
    } else if (
      _.isArray(item[`${payrollType}_time_sheets`]) &&
      item[`${payrollType}_time_sheets`].length > 0
    ) {
      const checkAnyTimesheetFailedOrError = (data = []) => {
        return data.filter(
          (item) =>
            item[`${payrollType}_export_status`] ===
              PAYROLL_EXPORT_STATUS.FAILED ||
            item[`${payrollType}_export_status`] === PAYROLL_EXPORT_STATUS.ERROR
        );
      };
      const isCheck = checkAnyTimesheetFailedOrError(
        item[`${payrollType}_time_sheets`]
      );
      if (
        isCheck &&
        isCheck.length > 0 &&
        isAnyFailed !== null &&
        parseBooleanVal(isAnyFailed)
      ) {
        if (property === "message") {
          return !isEmpty(isCheck[0]?.[property])
            ? isCheck[0]?.[property]
            : "Something went wrong!";
        } else if (property === `${payrollType}_status`) {
          return !isEmpty(isCheck[0]?.[property])
            ? isCheck[0]?.[property]
            : "error";
        }
      }

      return item[`${payrollType}_time_sheets`][0]?.[property] ?? null;
    }
    return item[`${payrollType}_time_sheets`]?.[property] ?? null;
  };

  const payrollStatuses = Object.values(PAYROLL_PLATFORMS).map(
    (payrollType) => ({
      [`${payrollType}_payroll_status`]: getPayrollProperty(
        item,
        payrollType,
        `${payrollType}_status`
      ),
    })
  );

  const payrollMessages = Object.values(PAYROLL_PLATFORMS).map(
    (payrollType) => ({
      [`${payrollType}_payroll_message`]:
        getPayrollProperty(item, payrollType, "message") || null,
    })
  );

  const getIcon = () => {
    if (!isPayrollIdExists) {
      return <AiOutlineWarning className='w-5 h-5 text-yellow-500' />;
    }
    if (isAllSuccess !== null && parseBooleanVal(isAllSuccess)) {
      return <AiOutlineCheckCircle className='w-5 h-5' color={"green"} />;
    }
    if (isAnyFailed !== null && parseBooleanVal(isAnyFailed)) {
      return <AiOutlineCloseCircle className='w-5 h-5' color={"red"} />;
    }
    return "";

    // switch (payrollStatus) {
    //   case PAYROLL_EXPORT_STATUS.SUCCESS:
    //     return <AiOutlineCheckCircle className='w-5 h-5' color={"green"} />;
    //   case PAYROLL_EXPORT_STATUS.ERROR:
    //     return <AiOutlineCloseCircle className='w-5 h-5' color={"red"} />;
    //   default:
    //     return "";
    // }
  };

  const getPlatformBadge = (type = payrollType, status = payrollStatus) => {
    if (
      !isPayrollIdExists ||
      status === undefined ||
      status === null ||
      !status
    ) {
      return "";
    }

    if (type) {
      const payType = Object.values(PAYROLL_PLATFORMS).find((x) => x === type);
      return (
        <span className='inline-flex items-center rounded-md bg-purple-50 px-1 py-0.5 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700'>
          {capitalizeAllLetters(payType?.replace("_", " "))}
        </span>
      );
    }
    return "";
  };

  const getTextAndCss = (
    key = "text",
    status = payrollStatus,
    message = payrollMessage
  ) => {
    if (!isPayrollIdExists) {
      return key === "text"
        ? "Payroll employee id not found!"
        : "bg-yellow-50 border-yellow-400";
    }
    switch (status) {
      case PAYROLL_EXPORT_STATUS.SUCCESS:
        return key === "text"
          ? !isEmpty(message)
            ? message
            : "Timesheet exported successfully!"
          : "bg-green-50 border-green-400";
      case PAYROLL_EXPORT_STATUS.ERROR:
      case PAYROLL_EXPORT_STATUS.FAILED:
        return key === "text"
          ? !isEmpty(message)
            ? message
            : "Timesheet can't be exported!"
          : "bg-red-50 border-red-400";
      default:
        return "border-black";
    }
  };

  const getStatusAndMessageByPlatform = (payrollType) => {
    const keys = {
      status: `${payrollType}_payroll_status`,
      message: `${payrollType}_payroll_message`,
    };
    const getValue = (array, key) => {
      const obj = array.find((item) => item.hasOwnProperty(key));
      return obj ? obj[key] : null;
    };
    let status = getValue(payrollStatuses, keys.status);
    let message = getValue(payrollMessages, keys.message);
    // if (isAnyFailed === true && (status === "success" || status === null)) {
    //   status = "error";
    //   message = "Error";
    // }

    // if (
    //   status === "success" &&
    //   isAllSuccess === true &&
    //   isAnyFailed === false
    // ) {
    //   message = "Timesheet exported successfully!";
    // }

    return { status, message };
  };

  // if (isConsoleLog) {
  //   console.log("item", item);
  //   console.log("isPayrollIdExists", isPayrollIdExists);
  //   console.log("payrollType", payrollType);
  //   console.log("isAllSuccess", isAllSuccess);
  //   console.log("isAnyFailed", isAnyFailed);
  // }

  return (
    <PopoverContainer
      isHover={true}
      placement={"right"}
      itemComponent={() => (
        <div className='ml-1 mr-1 rounded border bg-white text-sm min-w-[260px] max-w-[350px]'>
          {!isPayrollIdExists ? (
            <div
              className={classNames(
                "rounded flex justify-between gap-2 items-center py-0.5 px-3",
                getTextAndCss("css")
              )}
            >
              Payroll employee id not found!
            </div>
          ) : (
            <>
              {Object.values(PAYROLL_PLATFORMS)
                .filter((fil) => {
                  const { status } = getStatusAndMessageByPlatform(fil);
                  return !isEmpty(status);
                })
                .map((payrollType, index) => {
                  const { status, message } =
                    getStatusAndMessageByPlatform(payrollType);
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={classNames(
                          "rounded flex justify-between gap-2 items-center py-0.5 px-3",
                          getTextAndCss("css", status, message)
                        )}
                      >
                        <span
                          className='flex flex-wrap whitespace-pre-line'
                          style={{ wordBreak: "break-word" }}
                        >
                          {getTextAndCss("text", status, message)}
                        </span>
                        {getPlatformBadge(payrollType, status)}
                      </div>
                      <div className='w-full bg-gray-300 p-[0.5px]' />
                    </React.Fragment>
                  );
                })}
            </>
          )}
        </div>
      )}
    >
      <span
        className={classNames(!isPayrollIdExists && "cursor-pointer")}
        role='button'
        tabIndex={0}
        onClick={() =>
          !isPayrollIdExists &&
          item?.uuid &&
          navigate(`/worker-profile/${item.uuid}`)
        }
      >
        {getIcon()}
      </span>
    </PopoverContainer>
  );
};

export default PayrollTimesheetStatusPopover;
