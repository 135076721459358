import React from "react";

const CheckBox = (props) => {
  const {
    label,
    handleChange,
    checked,
    cssClass,
    name = "",
    isDisabled,
  } = props;

  return (
    <>
      <input
        disabled={isDisabled}
        name={name}
        onChange={(e) => handleChange(e.target.checked)}
        type='checkbox'
        checked={checked}
        defaultChecked={checked}
        className={` ${cssClass} ${isDisabled && "disabled:opacity-25"}`}
      />
      {label && (
        <label htmlFor={name} className='did-floating-label'>
          {label}
        </label>
      )}
    </>
  );
};

export default CheckBox;
