import React from "react";

const ReadOnlyStarRating = ({ label, value }) => {
 

  return (
    <div className="flex items-start space-x-2">
      <span className="w-40 text-left pr-2 whitespace-no-wrap">{label}</span>
      <div className="flex items-center">
        {renderReadOnlyStars(value)}
        {/* <span className="ml-2 text-gray-400">{value ? value : 0}/5</span> */}
      </div>
    </div>
  );
};

const renderReadOnlyStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    const starClasses =
      "fill-current " + (i <= rating ? "text-yellow-400" : "text-gray-200");
    stars.push(
      <svg
        key={i}
        className={`w-6 h-6 ${starClasses}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M6.379 17.615a1 1 0 0 1-1.488-1.054l.848-4.946-3.568-3.47a1 1 0 0 1 .552-1.705l4.94-.717 2.208-4.471a1 1 0 0 1 1.79 0l2.208 4.47 4.941.716a1 1 0 0 1 .552 1.705l-3.569 3.47.849 4.946a1 1 0 0 1-1.488 1.054l-4.169-2.193-4.168 2.193z"
        />
      </svg>
    );
  }
  return stars;
};

export default ReadOnlyStarRating;
