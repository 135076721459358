import CircularLoader from "components/common/CircularLoader/CircularLoader";
import React, { useState } from "react";

const NdisPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  // Function to handle the iframe load event
  const handleIframeLoad = () => {
    setIsLoaded(true); // Set isLoaded to true when the iframe has loaded
  };

  return (
    <div className="w-full">
      {!isLoaded && (
        <div
          className="flex flex-col w-full items-center justify-center"
          style={{ width: "100%", height: "450px" }} // You can adjust the height as needed
        >
          <CircularLoader
            className="flex justify-center items-center"
            classloader="loader-l"
          />
        </div>
      )}
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F442479916300564%3Fref%3Dembed_page&tabs=timeline"
        className="w-full"
        height="450" // Adjust the height as needed
        width="100%"
        style={{ border: "none", overflow: "hidden" }}
        allowFullScreen={true}
        title="ndis"
        onLoad={handleIframeLoad} // Attach the onLoad event handler
      ></iframe>
    </div>
  );
};

export default NdisPage;
