import { connect } from "react-redux";
import AskQuestion from "./AskaQuestion";
import { getQuestionsRequests } from "../../../store/actions/sp.actions";
import {
  getLoading,
  getSPQuestions,
} from "../../../store/selector/sp.selector";

const mapDispatchToProps = {
  //actions
  getQuestionList: getQuestionsRequests,
};

const mapStateToProps = (state) => {
  //selectors
  //console.log(getSPQuestions)
  const questionObj = getSPQuestions(state);
  const loading = getLoading(state);
  return { questionObj, loading };
};

export default connect(mapStateToProps, mapDispatchToProps)(AskQuestion);
