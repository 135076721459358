import { connect } from "react-redux";
import CatalogServices from "./CatalogServices";

import {
  getCatalogServicesRequest,
  catalogVersionRequest,
  getCatalogServicesSubCategoryRequest,
} from "../../../store/actions/cos.actions";
import {
  getAllCatalogServices,
  getCOSLoader,
  getAllCatalogServicesSubCategory,
  getCatalogVersions,
  getcatalogServicesLoadingLoader,
} from "../../../store/selector/cos.selector";

const mapDispatchToProps = {
  getCOSCatlogServicesRequest: getCatalogServicesRequest,
  getCOSCatlogServiceSubCategory: getCatalogServicesSubCategoryRequest,
  getCOSCatlogServiceFilter: catalogVersionRequest,
};

const mapStateToProps = (state) => {
  const catlogServices = getAllCatalogServices(state);
  const catalogVersions = getCatalogVersions(state);
  const catalogServicesLoading = getcatalogServicesLoadingLoader(state);
  const catlogServiceSubCategory = getAllCatalogServicesSubCategory(state);
  const fetchCatalogServicesLoader = getCOSLoader(state);

  return {
    catlogServices,
    catalogServicesLoading,
    catalogVersions,
    catlogServiceSubCategory,
    fetchCatalogServicesLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogServices);
