import { BASE_URL } from "store/services/URL";
import parseError from "Utils/ErrorParse";
import axios from "../store/services/axios";
import { getToken } from "./Tokens";

export const addFiltersAndPagination = (url, params) => {
  let str = "";
  if (params.page) {
    str = `page=${params.page}`;
  }
  if (params.per_page) {
    str = `${str}${str ? "&" : ""}per_page=${params.per_page}`;
  }
  if (params.search) {
    str = `${str}${str ? "&" : ""}search=${params.search}`;
  }
  if (params.sort_order) {
    str = `${str}${str ? "&" : ""}sort_order=${params.sort_order}`;
  }
  if (params.sort_field) {
    str = `${str}${str ? "&" : ""}sort_field=${params.sort_field}`;
  }
  if (params.type) {
    str = `${str}${str ? "&" : ""}type=${params.type}`;
  }
  return str ? `${url}?${str}` : url;
};

export const apiGetRequest = (endpoint, token = null, props = {}) => {
  return apiRequest("GET", endpoint, token, props);
};

export const apiPostRequest = (endpoint, payload, token = null) => {
  return apiRequest("POST", endpoint, token, { data: payload });
};

export const apiPatchRequest = (endpoint, payload, token = null) => {
  return apiRequest("PATCH", endpoint, token, { data: payload });
};

export const apiPutRequest = (endpoint, payload, token = null) => {
  return apiRequest("PUT", endpoint, token, { data: payload });
};

export const apiFormDataRequest = (endpoint, payload, token = null) => {
  return apiFormRequest("POST", endpoint, token, { data: payload });
};

export const apiDeleteRequest = (endpoint, payload, token = null) => {
  return apiFormRequest("DELETE", endpoint, token, { data: payload });
};

export const apiRequest = (method, endpoint, token = null, props = {}) => {
  if (!token) {
    token = getToken();
  }
  const params = {
    method: method,
    // baseURL: process.env.REACT_APP_BASE_URL,
    baseURL: BASE_URL,
    url: endpoint,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  Object.assign(params, props);
  if (token) {
    params.headers.Authorization = `Bearer ${token}`;
  }

  return axios(params).catch((err) => parseError(err));
};

export const apiFormRequest = (method, endpoint, token = null, props = {}) => {
  if (!token) {
    token = getToken();
  }
  const params = {
    method: method,
    // baseURL: process.env.REACT_APP_BASE_URL,
    baseURL: BASE_URL,
    url: endpoint,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  Object.assign(params, props);
  if (token) {
    params.headers.Authorization = `Bearer ${token}`;
  }

  return axios(params).catch((err) => parseError(err));
};
