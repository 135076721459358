import React from "react";
import { formatDateIntoDMYToDisplay, gethoursAndMins } from "Utils/utils";
import CheckBox from "components/common/checkBox/checkbox";
import { DELIVERED_SERVICE_STATUS_OPTIONS } from "Constants/constant";

const COSInvoicePreviewTabel = (props) => {
  const { item, checkboxes, handleCheckboxChange, isUserGracePeriodOver } =
    props;

  return (
    <tr className='text-center even:bg-white odd:bg-gray-50'>
      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          <CheckBox
            isDisabled={isUserGracePeriodOver}
            handleChange={handleCheckboxChange}
            checked={checkboxes.indexOf(item.id) !== -1}
          />
        </div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{item.delivered_service_number}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500 capitalize'>{item.created_by}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500 capitalize'>{item.participant}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {item.manged_by ? JSON.parse(item.manged_by)[0].label : "-"}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500 capitalize'>{item.support_item_name}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{item.support_item_number}</div>
      </td>
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">{item.cos}</div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">{"not added"}</div>
      </td> */}
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        {
          DELIVERED_SERVICE_STATUS_OPTIONS.find((arr) => {
            return item.invoice_status === arr.value;
          }).label
        }
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {item.delivered_from && item.delivered_to_date
            ? `${formatDateIntoDMYToDisplay(
                item.delivered_from
              )} - ${formatDateIntoDMYToDisplay(item.delivered_to_date)}`
            : item.delivered_date
            ? formatDateIntoDMYToDisplay(item.delivered_date)
            : "-"}
        </div>
      </td>
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">{"Not added yet"}</div>
      </td> */}
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {gethoursAndMins(item?.hours, item?.mints)}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{`$${item.original_rate || 0}`}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{`$${item.total_amount || 0}`}</div>
      </td>
    </tr>
  );
};

export default COSInvoicePreviewTabel;
