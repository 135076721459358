import { connect } from "react-redux";
import NdisClaimNew from "./NdisClaimNew";
import {
  addCaseNotesToClaimListRequest,
  getCaseNotesListForClaimsRequest,
  getCaseNotesReviewRequest,
  getClientsRequest,
  RemoveReviewCaseNoteList,
  supportCoordinatorsRequest,
} from "store/actions/cos.actions";
import {
  // addCaseNotesToListLoadingSelector,
  changeNDISCaseNotesStatusLoadingSelector,
  getAddCaseNotesToListLoader,
  getCaseNoteReviewSelector,
  getCaseNotesListForClaimLoaderSelector,
  getCaseNotesListForClaimSelector,
  getClienListLoaderSelector,
  getClienListSelector,
  getSupportCoordinator,
  getSupportCoordinatorLoader,
} from "store/selector/cos.selector";

const mapDispatchToProps = {
  getClientsRequest,
  supportCoordinatorsRequest,
  getCaseNotesRequest: getCaseNotesListForClaimsRequest,
  createClaim: addCaseNotesToClaimListRequest,
  emptyTableData: RemoveReviewCaseNoteList,
};

const mapStateToProps = (state) => {
  const caseNoteReview = getCaseNoteReviewSelector(state);
  const clientsList = getClienListSelector(state);
  const clientsListLoader = getClienListLoaderSelector(state);

  const supportCoordinator = getSupportCoordinator(state);
  const caseNotesListForClaim = getCaseNotesListForClaimSelector(state);
  // const addCaseNotesToListLoading = addCaseNotesToListLoadingSelector(state);

  const changeNDISCaseNotesStatusLoading =
    changeNDISCaseNotesStatusLoadingSelector(state);

  const caseNotesListForClaimLoader =
    getCaseNotesListForClaimLoaderSelector(state);

  const addCaseNotesToListLoading = getAddCaseNotesToListLoader(state);

  return {
    clientsList,
    supportCoordinator,
    caseNotesListForClaim,
    caseNoteReview,
    caseNotesListForClaimLoader,
    clientsListLoader,
    changeNDISCaseNotesStatusLoading,
    addCaseNotesToListLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NdisClaimNew);
