import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";
import AutoShiftBoxes from "../../../components/common/AutoShiftBoxes/AutoShiftBoxes";
import { apiPostRequest } from "../../../helpers/Requests";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import LoaderButton from "../../../components/common/LoaderButton/LoaderButton";
import { connect } from "react-redux";
import { resendEmailVerificatioCodeRequest } from "store/actions/auth.actions";
import { getResendEmailCodeLoader } from "store/selector/auth.selector";
import { classNames } from "Utils/utils";
import Loader from "components/common/Loader/Loader";

const Emailcode = (props) => {
  const { resendEmailVerificatioCode, resendEmailCodeLoader } = props;

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const { encodedEmail } = useParams();
  const email = decodeURIComponent(window.atob(encodedEmail));
  const navigate = useNavigate();
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const [timer, setTimer] = useState(120);
  const timerRef = useRef(null);

  const startTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerRef.current);
          timerRef.current = null;
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    startTimer();
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [startTimer]);

  const emailVerificationHandler = async () => {
    const data = {
      email,
    };
    data.confirmation_code = confirmationCode;
    setRequest({ ...request, isRequested: true });

    try {
      let res = await apiPostRequest("confirmation-email", data);
      if (res.status !== 200) {
        toast.error("Wrong Code");
        setRequest({ ...request, isRequested: false, isFailed: true });
      }
      if (res.status === 200) {
        // console.log(res.data);
        toast.success("Account Verified");
        // await api.spAllFormRequest();
        setRequest({ ...request, isRequested: false, isSuccess: true });
        navigate("/");
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err?.message ?? "Something Went Wrong!");
    }
  };

  const handleResendCode = () => {
    resendEmailVerificatioCode({ email: email }, () => {
      setTimer(120);
      startTimer();
    });
  };

  return (
    <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <div className='flex justify-center'>
            <div className='rounded-full bg-blue-dark/10 h-20 w-20 flex items-center justify-center'>
              <div className='h-14 w-14 rounded-full bg-blue-dark/20 flex items-center justify-center'>
                <FiMail className='h-8 w-8 text-blue-dark' />
              </div>
            </div>
          </div>
          <h2 className='mt-4 text-center text-3xl font-semibold text-gray-900'>
            Verify Your Email
          </h2>
          <p className='text-center text-gray-500 mt-3 mb-6'>
            Verification Code sent to your Email
            <br />
          </p>
          <div className='flex justify-center'>
            <AutoShiftBoxes
              setConfirmationCode={setConfirmationCode}
              length={6}
              label='Code Label'
              onComplete={setIsDisabled}
              classes={`border h-10 sm:h-12 w-10 sm:w-12 mx-1 text-center border-blue-dark rounded-lg text-blue-dark text-sm sm:text-xl focus-visible:outline-none focus-visible:border-2 ${
                request.isFailed ? "borderRed" : "border"
              }`}
            />
          </div>
        </div>
        <div className='space-y-4'>
          {request.isRequested ? (
            <LoaderButton
              classes='w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
              svgClasses='inline mr-3 w-5 h-5 text-white animate-spin'
              textbutton='Loading...'
            />
          ) : (
            <button
              disabled={!isDisabled || resendEmailCodeLoader}
              onClick={emailVerificationHandler}
              className='w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark'
            >
              Continue
            </button>
          )}
          <p className='text-gray-500 text-center mt-4'>
            {`Didn’t receive the email? `}
            {timer === 0 ? (
              resendEmailCodeLoader ? (
                <Loader isSmallView={true} cssClass={"text-black m-0"} />
              ) : (
                <span
                  role='button'
                  onClick={() => {
                    if (resendEmailCodeLoader) return;
                    handleResendCode();
                  }}
                  className={classNames(
                    "text-blue-dark font-semibold cursor-pointer"
                  )}
                >
                  click to resend
                </span>
              )
            ) : (
              <span className='text-blue-dark font-semibold'>
                {/* Please wait  */}
                {Math.floor(timer / 60)}:{`0${timer % 60}`.slice(-2)}
                {/* before resending */}
              </span>
            )}
          </p>

          <Link
            to='/setpassword'
            className='w-full flex justify-center items-center space-x-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-500'
          >
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M11 17l-5-5m0 0l5-5m-5 5h12'
                />
              </svg>
            </span>
            <span>Back to log in</span>
          </Link>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

const mapDispatchToProps = {
  resendEmailVerificatioCode: resendEmailVerificatioCodeRequest,
};

const mapStateToProps = (state) => {
  const resendEmailCodeLoader = getResendEmailCodeLoader(state);
  return { resendEmailCodeLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(Emailcode);
