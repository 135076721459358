import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import api from "../../../../store/services/cos.services";

function CatalogServicesSelect(props) {
  const {
    funding,
    state_id,
    setSelectedService,
    serviceSupporitemEdit,
    isDisabled = false,
    placeHolder = "",
  } = props;

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (serviceSupporitemEdit && serviceSupporitemEdit.price_catalog) {
      setSelectedValue(serviceSupporitemEdit.price_catalog);
    }
  }, [serviceSupporitemEdit]);

  useEffect(() => setSelectedService(selectedValue), [selectedValue]);

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const handleFetch = async (inputValue) => {
    if (funding && state_id) {
      try {
        setRequest({ ...request, isRequested: true });
        const response = await api.getCatalogServicesSearch({
          funding_id: funding.uuid,
          state_id,
          search: inputValue,
        });

        if (response) {
          setRequest({ ...request, isSuccess: true, isRequested: false });
          //   setServicesOptions([...response.price_catalog]);
          return [...response.price_catalog];
        }
        setRequest({ ...request, isSuccess: true, isRequested: false });
        return [];
      } catch (err) {
        console.log("err", err);
        setRequest({ ...request, isFailed: true, isRequested: false });
      }
    }
  };

  //   load options using API call
  const loadOptions = async (inputValue) => {
    try {
      const data = await handleFetch(inputValue);
      return data;
    } catch (err) {
      return [];
    }
  };

  return (
    <AsyncSelect
      className="css-2613qy-menu react-select-7-listbox"
      placeholder={placeHolder}
      // isLoading={request.isRequested}
      requestSucceed={request.isSuccess}
      defaultOptions
      value={selectedValue}
      getOptionLabel={(e) => `${e.support_item_number} ${e.support_item_name}`}
      getOptionValue={(e) => e.id}
      loadOptions={loadOptions}
      //   onInputChange={(e) => setInputValue(e)}
      onChange={setSelectedValue}
      isClearable={true}
      isDisabled={isDisabled || serviceSupporitemEdit}
    />
  );
}

export default CatalogServicesSelect;
