import React, { useState } from "react";
import { roleAccess } from "helpers/RolesPermission";
import HumanBodyModal from "components/Modals/HumanBody/HumanBodyModal";
import { USER_ROLE } from "Constants/constant";
import { FiArrowUpRight } from "react-icons/fi";
import { parseBooleanVal } from "Utils/utils";
import CustomButton from "../Button/CustomButton";

const SupportPlanItem = (props) => {
  const { item, viewInitialAssesmentForm, form_uuid } = props;

  const [openBodyModal, setOpenBodyModal] = useState(false);
  const [selectedBody, setSelectedBody] = useState("");

  const getValue = () => {
    const { value, type } = item;
    if (value === null || value === "") {
      return (
        <>
          {roleAccess([USER_ROLE.Sp_Standard_User]) ? (
            <div className='flex items-center space-x-2'>
              <span className='text-red-500'>No Data found!</span>
            </div>
          ) : (
            <div
              onClick={() => viewInitialAssesmentForm(form_uuid)}
              tabIndex={0}
              role='button'
              className='flex items-center space-x-2'
            >
              <span className='text-blue-700'>Add information</span>
              <span className='text-blue-600'>
                <FiArrowUpRight />
              </span>
            </div>
          )}
        </>
      );
    }

    if (
      item?.isBodyModal &&
      parseBooleanVal(item.isBodyModal) &&
      type === "text"
    ) {
      return (
        <CustomButton
          label='View Human Body'
          type='button'
          clickHandler={() => {
            setSelectedBody(value);
            setOpenBodyModal(true);
          }}
        />
      );
    }

    if (value) return value;

    return (
      <>
        {roleAccess([USER_ROLE.Sp_Standard_User]) ? (
          <div className='flex items-center space-x-2'>
            <span className='text-red-500'>No Data found!</span>
          </div>
        ) : (
          <div
            onClick={() => viewInitialAssesmentForm(form_uuid)}
            tabIndex={0}
            role='button'
            className='flex items-center space-x-2'
          >
            <span className='text-blue-700'>Add information</span>
            <span className='text-blue-600'>
              <FiArrowUpRight />
            </span>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className='sm:col-span-1'>
        <dt className='text-sm font-bold text-gray-500 capitlize'>
          {item.lable}
        </dt>
        <dd className='break-all mt-1 text-sm text-gray-900 '>{getValue()}</dd>
      </div>
      {openBodyModal && (
        <HumanBodyModal
          title={"View Human Body"}
          view={true}
          selectedValue={selectedBody}
          isOpen={openBodyModal}
          closeModal={() => setOpenBodyModal(false)}
        />
      )}
    </>
  );
};

export default SupportPlanItem;
