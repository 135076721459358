import React from "react";
import TableHeader from "../../../../components/common/Table/TableHeader";
import { DELIVERED_SERVICE_STATUS_OPTIONS } from "../../../../Constants/constant";
import {
  formatDateIntoDMYToDisplay,
  gethoursAndMins,
  textTruncate,
} from "../../../../Utils/utils";

const CaseNotesHistoryTable = (props) => {
  const { printData } = props;
  return (
    <div className='bg-white relative shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg'>
      <table className='w-full table-fixed divide-y divide-gray-300 bg-white'>
        <thead
          className='bg-red-50 sticky z-[2] capitalize'
          style={{ top: "157px" }}
        >
          <tr>
            <TableHeader label={"Participant"} isSort={false} />
            <TableHeader label={"Note Contact Type"} isSort={false} />
            <TableHeader label={"Created Date"} isSort={false} />
            <TableHeader label={"Created by"} isSort={false} />
            <TableHeader label={"Delivered Date"} isSort={false} />
            <TableHeader label={"Duration"} isSort={false} />
            <TableHeader label={"Status"} isSort={false} />
            <TableHeader
              isSort={false}
              label={"Service Region"}
              cssClass='text-center whitespace-nowrap'
            />
            <TableHeader isSort={false} label={"Note"} cssClass='text-end' />
            <th className='  text-gray-900 bg-blue-50'></th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200 bg-white'>
          {printData && printData.length > 0
            ? printData.map((data, index) => (
                <tr key={index} className='even:bg-white odd:bg-gray-50'>
                  <td className='whitespace-nowrap px-3 py-4 text-start text-sm text-gray-500 truncate capitalize'>
                    {`${data.participant?.first_name} ${data.participant?.last_name}`}
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-start  text-gray-500 truncate'>
                    {textTruncate(data?.cos_contact_type?.title, 30, 29)}
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-start  text-gray-500 truncate'>
                    {formatDateIntoDMYToDisplay(data?.created_at)}
                  </td>

                  <td className='whitespace-nowrap px-3 py-4 text-sm text-start  text-gray-500 truncate capitalize'>
                    {textTruncate(data?.created_by, 30, 29)}
                  </td>

                  <td className='whitespace-nowrap px-3 py-4 text-sm text-start  text-gray-500 truncate '>
                    {formatDateIntoDMYToDisplay(data?.delivered_date)}
                  </td>

                  <td className='whitespace-nowrap px-3 py-4 text-sm text-start  text-gray-500 truncate'>
                    {gethoursAndMins(data?.hours, data?.mints)}
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-start  text-gray-500 truncate'>
                    {data?.status === null
                      ? DELIVERED_SERVICE_STATUS_OPTIONS.find((arr) => {
                          return data?.invoice_status === arr.value;
                        }).label
                      : DELIVERED_SERVICE_STATUS_OPTIONS.find((arr) => {
                          return data?.status === arr.value;
                        }).label}
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 truncate uppercase'>
                    {data?.state?.name}
                  </td>
                  <td className='whitespace-nowrap px-3 text-end'>
                    <span
                      title={data?.notes}
                      className='relative pl-16 text-sm text-center text-gray-500'
                    >
                      {textTruncate(data?.notes, 13, 12)}
                    </span>
                  </td>
                  <td className=''></td>
                </tr>
              ))
            : (!printData || printData.length === 0) && (
                <tr>
                  <td colSpan={10} className='py-6 text-center text-red-600'>
                    <div>No data found !</div>
                  </td>
                </tr>
              )}
        </tbody>
      </table>
    </div>
  );
};
export default CaseNotesHistoryTable;
