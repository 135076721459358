import ModalSkeleton from "components/Modals/ModalSkeleton";
import React from "react";
import CustomButton from "../Button/CustomButton";

function ConfirmationModal(props) {
  const {
    showModal,
    submitHandler,
    closeModalHandler,
    title = "Confirmation",
    heading = "Are you Sure you want to Delete?",
    isNew = false,
    cancelBtnLabel = "Close",
    confirmBtnLabel = "Ok",
  } = props;

  if (isNew) {
    const modalConfirmFooterButton = () => (
      <div className='flex space-x-2'>
        <CustomButton
          clickHandler={closeModalHandler}
          type='button'
          label={cancelBtnLabel}
          variant='secondary'
        />
        <CustomButton
          clickHandler={submitHandler}
          type='button'
          label={confirmBtnLabel}
          variant='danger'
        />
      </div>
    );

    return (
      <div>
        <ModalSkeleton
          isOpen={showModal}
          title={title}
          closeModal={closeModalHandler}
          cssClass='w-[40%]'
          modalFooter={modalConfirmFooterButton()}
        >
          <div>
            <p>{heading}</p>
          </div>
        </ModalSkeleton>
      </div>
    );
  }

  return (
    <div>
      {showModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-full my-6 mx-auto max-w-3xl max-h-screen overflow-y-auto custom-scroll'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t'>
                  <h3 className='text-3xl font-semibold'>{title}</h3>
                  <button
                    className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                    onClick={closeModalHandler}
                  >
                    <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <h4 className='px-6 py-4'>{heading}</h4>
                {/*footer*/}
                <div className='flex items-center justify-end p-6 rounded-b'>
                  <button
                    className='text-red-500 border-gray-500 hover:bg-red-600 hover:text-white font-medium uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={closeModalHandler}
                  >
                    No
                  </button>
                  <button
                    className='bg-blue-dark text-white hover:bg-blue-light font-medium uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    onClick={submitHandler}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </div>
  );
}

export default ConfirmationModal;
