import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import { apiGetRequest } from "helpers/Requests";
import React, { useContext, useEffect, useState } from "react";
import { FcSupport } from "react-icons/fc";
import { IoIosRocket } from "react-icons/io";
import { SiProgress } from "react-icons/si";
import CircularLoader from "components/common/CircularLoader/CircularLoader";

const ReleaseNotes = () => {
  const { sideBarPadding } = useContext(LoginContext);
  const [versionList, setVersionList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [versionDetails, setVersionDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVersionList = async () => {
    await apiGetRequest("/version/list")
      .then((res) => {
        if (res.status === 200) {
          const rolesData = res.data?.data?.versions;

          const roles = rolesData?.map((item) => {
            const obj = {
              label: item?.version,
              value: item?.id,
            };
            return obj;
          });
          setVersionList(roles);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getVersionList();
  }, []);

  const getVersion = async () => {
    setLoading(true);
    await apiGetRequest(`/version/${selectedStatus?.value}`)
      .then((res) => {
        if (res.status === 200) {
          setVersionDetails(res.data?.data?.version);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (versionList.length > 0) {
      setSelectedStatus(versionList[0]); // Auto-select the first item in the dropdown
    }
  }, [versionList]);

  useEffect(() => {
    if (selectedStatus) {
      getVersion();
    }
  }, [selectedStatus]);
  let whatsNewNumber = 1;
  let enhancementCount = 1;
  return (
    <div className="min-full">
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        } flex flex-col`}
      >
        <main className="flex-1 bg-gray-50">
          <div className="w-full">
            <div className="min-h-full">
              <main className="flex-1">
                <Header title="Release Notes" />
                <main className="px-6 max-w-4xl mx-auto">
                  <div className="py-8">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <label className="text-md font-bold">
                          Release Notes:{" "}
                        </label>
                        <p>{versionDetails?.version}</p>
                      </div>
                      <SelectOption
                        data={versionList}
                        placeHolder="Select Version"
                        selectedOption={selectedStatus}
                        handleChange={(e) => {
                          setSelectedStatus(e);
                        }}
                        isSearch={false}
                        cssClass="z-8"
                      />
                    </div>
                    {loading ? (
                      <div
                        className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                      >
                        <CircularLoader
                          classes="flex justify-center items-center"
                          classloader="loader-l"
                        />
                      </div>
                    ) : (
                      <div className="my-4">
                        {versionDetails?.notes?.length > 0 ? (
                          <div>
                            <Accordion title="What's New">
                              {versionDetails?.notes?.some(
                                (note) => note.note_type === "feature"
                              ) ? (
                                <div>
                                  {versionDetails.notes.map((note, index) => {
                                    if (note.note_type === "feature") {
                                      return (
                                        <div key={index} className="mt-3">
                                          <p className="text-sm font-semibold">
                                            {whatsNewNumber++}. {note.title}
                                          </p>
                                          <p className="text-sm text-gray-500">
                                            {note.description}
                                          </p>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              ) : (
                                <p className="text-center  mt-3">
                                  No new feature added
                                </p>
                              )}

                              {/* {versionDetails &&
                                versionDetails?.notes &&
                                versionDetails.notes.map((note, index) => {
                                  if (note.note_type === "feature") {
                                    return (
                                      <div key={index} className="mt-3">
                                        <p className="text-sm font-semibold">
                                          {whatsNewNumber++}. {note.title}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                          {note.description}
                                        </p>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              {versionDetails?.notes?.some(
                                (note) => note.note_type === "feature"
                              ) ? null : (
                                <p className="text-center">
                                  No new feature added
                                </p>
                              )} */}
                            </Accordion>
                            <br />

                            <Accordion title="Enhancement">
                            {versionDetails?.notes?.some(
                                (note) => note.note_type === "enhancement"
                              ) ? (
                                <div>
                                  {versionDetails.notes.map((note, index) => {
                                    if (note.note_type === "enhancement") {
                                      return (
                                        <div key={index} className="mt-3">
                                        <p className="text-sm font-semibold">
                                          {enhancementCount++}. {note.title}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                          {note.description}
                                        </p>
                                      </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              ) : (
                                <p className="text-center  mt-3">
                                  No new enhancement
                                </p>
                              )}
                              {/* {versionDetails &&
                                versionDetails?.notes &&
                                versionDetails?.notes?.map((note, index) => {
                                  if (note?.note_type === "enhancement") {
                                    return (
                                      <div key={index} className="mt-3">
                                        <p className="text-sm font-semibold">
                                          {enhancementCount++}. {note.title}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                          {note.description}
                                        </p>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              {versionDetails?.notes?.some(
                                (note) => note.note_type === "enhancement"
                              ) ? null : (
                                <p className="text-center">
                                  No new enhancement
                                </p>
                              )} */}
                            </Accordion>
                            <br />

                            <Accordion title="Bug Fixes">
                            {versionDetails?.notes?.some(
                                (note) => note.note_type === "bug"
                              ) ? (
                                <div>
                                  {versionDetails.notes.map((note, index) => {
                                    if (note?.note_type === "bug") {
                                      return (
                                        <div key={index} className="mt-3">
                                        <p className="text-sm font-semibold">
                                          Bug #: {note.title}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                          {note.description}
                                        </p>
                                      </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              ) : (
                                <p className="text-center  mt-3">
                                  No new bug fixes
                                </p>
                              )}
                              {/* {versionDetails &&
                                versionDetails?.notes &&
                                versionDetails.notes.map((note, index) => {
                                  if (note?.note_type === "bug") {
                                    return (
                                      <div key={index} className="mt-3">
                                        <p className="text-sm font-semibold">
                                          Bug #: {note.title}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                          {note.description}
                                        </p>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              {versionDetails?.notes?.some(
                                (note) => note.note_type === "bug"
                              ) ? null : (
                                <p className="text-center">No new bugs</p>
                              )} */}
                            </Accordion>
                          </div>
                        ) : (
                          <p className="text-center"> No details available</p>
                        )}
                      </div>
                    )}
                  </div>
                </main>
              </main>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const icon =
    title === "What's New" ? (
      <IoIosRocket className="w-4 h-4 mr-2" />
    ) : title === "Enhancement" ? (
      <SiProgress className="w-4 h-4 mr-2" />
    ) : title === "Bug Fixes" ? (
      <FcSupport className="w-4 h-4 mr-2" />
    ) : null;
  const textColorClass =
    title === "What's New"
      ? "text-blue-500"
      : title === "Enhancement"
      ? "text-green-500"
      : title === "Bug Fixes"
      ? "text-red-500"
      : "text-gray-500";

  return (
    <div className="mt-4">
      <button
        className={`w-full text-bold text-xl text-left bg-gray-200 p-2 hover:bg-gray-300 ${textColorClass}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center justify-between">
          <div className="font-bold flex items-center">
            {icon}
            {title}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-4 w-4 float-right transform transition-transform ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 5.293a1 1 0 011.414 0L10 10.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      {isOpen && children}
    </div>
  );
};

export default ReleaseNotes;

