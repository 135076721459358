import React from "react";
import { Link } from "react-router-dom";
import { RiKey2Line } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderButton from "components/common/LoaderButton/LoaderButton";
import { apiPostRequest } from "helpers/Requests";
import { EMAIL_REGEX_EXP } from "Constants/constant";

const ForgetPassword = () => {
  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const forgetPasswordHandler = async (data) => {
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiPostRequest("forget-password", data);
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        throw new Error("Invalid Credentials");
      }
      if (res.status === 200) {
        localStorage.setItem("email", data.email);
        console.log(res.data);
        navigate("/reset-password");
        setRequest({ ...request, isRequested: false, isSuccess: true });
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message);
    }
  };

  const onSubmit = (data) => {
    forgetPasswordHandler(data);
  };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="">
          <div className="flex justify-center">
            <div className="rounded-full bg-layer/40 h-20 w-20 flex items-center justify-center">
              <div className="h-14 w-14 rounded-full bg-blue-200/60 flex items-center justify-center">
                <RiKey2Line className="h-8 w-8 text-blue-dark" />
              </div>
            </div>
          </div>
          <h2 className="mt-6 text-center text-xl sm:text-3xl font-semibold text-gray-900">
            Forgot password?
          </h2>
          <p className="mt-2 text-center text-md text-gray-500 font-normal">
            No worries, we’ll send you reset instructions.
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="did-floating-label-content">
              <label
                htmlFor="email-address"
                className="block text-sm font-medium text-gray-700 mb-[3px]"
              >
                Enter Your Email
              </label>
              <input
                {...register("email", {
                  required: true,
                  pattern: EMAIL_REGEX_EXP,
                })}
                id="email-address"
                type="email"
                autoComplete="email"
                className={`did-floating-input ${
                  request.isFailed ? "borderRed" : "border"
                }`}
                // placeholder="Email address"
              />

              {errors?.email?.type === "required" && (
                <span className="text-sm text-red-500">
                  This field is required!
                </span>
              )}
              {errors?.email?.type === "pattern" && (
                <span className="text-sm text-red-500">
                  Please enter a valid email address!
                </span>
              )}
            </div>
          </div>

          <div className="space-y-4">
            {request.isRequested ? (
              <LoaderButton
                classes="w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
                svgClasses="inline mr-3 w-5 h-5 text-white animate-spin"
                textbutton="Loading..."
              />
            ) : (
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
              >
                Submit
              </button>
            )}

            <Link
              to="/"
              className="w-full flex justify-center items-center space-x-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-500"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 17l-5-5m0 0l5-5m-5 5h12"
                  />
                </svg>
              </span>
              <span>Back to log in</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
