import { connect } from "react-redux";
import Complaints from "./Complaints";
import { getComplaintsRequests } from "../../../store/actions/sp.actions";
import {
  getLoading,
  getSPComplaints,
} from "../../../store/selector/sp.selector";

const mapDispatchToProps = {
  //actions
  getFeedbackList: getComplaintsRequests,
};

const mapStateToProps = (state) => {
  //selectors
  const feedbackObj = getSPComplaints(state);
  const loading = getLoading(state);
  return { feedbackObj, loading };
};

export default connect(mapStateToProps, mapDispatchToProps)(Complaints);
