import React, { useContext } from "react";
import { useNavigate } from "react-router";
import SideBar from "components/common/SideBar/SideBarNew";
import Header from "components/common/Header/Header";
import { LoginContext } from "helpers/LoginContext";
import WelcomeBanner from "pages/SupportCoordination/Participant/Dashboard/partials/dashboard/WelcomeBanner";
import { USER_ROLE } from "Constants/constant";
import { classNames } from "Utils/utils";
import { FiUsers } from "react-icons/fi";
import { FcOvertime } from "react-icons/fc";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { roleAccess } from "helpers/RolesPermission";

const RosteringDashboard = () => {
  const { sideBarPadding } = useContext(LoginContext);

  const navigate = useNavigate();

  const cards = [
    {
      name: "Schedule",
      role: "All Worker Shifts in the sheduling calendar",
      href: "rostering/schedule",
      icon: FiUsers,
      bgColor: "#8C198F",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      for: "schedule",
      permissionChild: PERMISSION_KEYS_CHILDREN.ROSTERING.SCHEDULE.SHOW,
    },
    {
      name: "Timesheet",
      role: "All Timesheets",
      href: "rostering/timesheet",
      icon: FcOvertime,
      bgColor: "#00D1B7",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      for: "timesheet",
      permissionChild: PERMISSION_KEYS_CHILDREN.ROSTERING.TIMESHEET.SHOW,
    },
    {
      name: "Timesheet Export",
      role: "Export Timesheet",
      href: "rostering/timesheetexport",
      icon: FcOvertime,
      bgColor: "#4969B2",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      for: "timesheetexport",
      permissionChild: PERMISSION_KEYS_CHILDREN.ROSTERING.TIMESHEET_EXPORT.SHOW,
    },
  ];

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`duration-300 flex flex-col ${
          sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
        }`}
      >
        <main className='flex-1 relative'>
          <Header title='Rostering Dashboard' />
          <div className='min-full'>
            <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-6 py-8'>
              <WelcomeBanner />
              <div
                className={`grid gap-3 p-6 bg-gray-50 md:rounded-lg  ${"grid-cols-3"}`}
              >
                {cards
                  .filter((x) =>
                    roleAccess([], PERMISSION_KEYS.ROSTERING, x.permissionChild)
                  )
                  .map((card, index) => (
                    <div
                      onClick={() => {
                        if (card.href) navigate(`/${card.href}`);
                      }}
                      role='button'
                      tabIndex={0}
                      key={index}
                      className='cursor-pointer relative rounded-lg border border-gray-300 bg-white p-1 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 capitalize'
                    >
                      <div
                        className={classNames(
                          "flex-shrink-0 flex items-center justify-center w-16 h-12 py-8 text-white text-sm font-medium rounded-md"
                        )}
                        style={{ backgroundColor: `${card.bgColor}` }}
                      >
                        <card.icon
                          className={classNames(
                            "flex-shrink-0 h-5 w-5 text-white"
                          )}
                          aria-hidden='true'
                        />
                      </div>
                      <div className='flex-1 min-w-0'>
                        <div title={card.role} className='focus:outline-none'>
                          <span
                            className='absolute inset-0'
                            aria-hidden='true'
                          />
                          <p className='text-sm font-medium text-gray-900 mt-3'>
                            {card.name}
                          </p>
                          <p
                            title={card.role}
                            className='text-sm text-gray-500 truncate mb-3'
                          >
                            {card.role}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default RosteringDashboard;
