import React, { useEffect, useState, useMemo } from "react";
import {
  emptyPaginationObj,
  LOADER_STATUS,
  SERVICE_SUPPORT_LIMIT,
} from "../../../../Constants/constant";
import { gethoursAndMins, getPaginationObj } from "../../../../Utils/utils";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import PaginationComponent from "../../../../components/common/Pagination/index";
import TableHeader from "../../../../components/common/Table/TableHeader";
import { BiPencil } from "react-icons/bi";

const ServiceSupportTable = (props) => {
  const {
    participant,
    fetchLoader,
    removeLoader = false,
    funding,
    getServiceSupportList,
    serviceSupportBudgets,
    setServiceSupportItem,
    // removeServiceCategory,
  } = props;
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [paginationObj, setPaginationObj] = useState(
    serviceSupportBudgets
      ? getPaginationObj(serviceSupportBudgets)
      : emptyPaginationObj
  );
  const [selectedId, setSelectedId] = useState(null);

  //   useEffect(() => {
  //     if (selectedId && !removeLoader) {
  //       setSelectedId(null);
  //     }
  //   }, [removeLoader]);

  useEffect(() => {
    if (serviceSupportBudgets) {
      setPaginationObj({
        ...getPaginationObj(serviceSupportBudgets),
      });
    }
  }, [serviceSupportBudgets]);

  useEffect(() => {
    if (
      !serviceSupportBudgets ||
      (serviceSupportBudgets &&
        Object.keys(serviceSupportBudgets).length > 0 &&
        serviceSupportBudgets.hasOwnProperty("data") &&
        serviceSupportBudgets.data.length === 0)
    ) {
      fetchServiceCategory({ page: paginationObj.page });
    }
  }, []);

  useEffect(() => {
    if (
      serviceSupportBudgets &&
      serviceSupportBudgets.current_page !== paginationObj.page
    ) {
      fetchServiceCategory({ page: paginationObj.page }).then();
    }
  }, [paginationObj.page]);

  const serviceSupportBudgetToMap = useMemo(() => {
    if (serviceSupportBudgets) {
      return serviceSupportBudgets.data;
    }
    return [];
  }, [serviceSupportBudgets]);

  const fetchServiceCategory = async (filters) => {
    if (!filters) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: SERVICE_SUPPORT_LIMIT });
    Object.assign(filters, {
      pt_id: participant.uuid || 1,
      funding_id: funding.id || 1,
      funding_reference_id: funding.contract_id,
    });
    if (renderLoading === LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.PENDING);
    }
    getServiceSupportList(filters);
  };

  const handleDelete = (serviceCategoryId) => {
    setSelectedId(serviceCategoryId);
    // removeServiceCategory({ id: serviceCategoryId });
  };

  return (
    <>
      <div className='flex flex-col'>
        <div className='min-w-full align-middle'>
          <div className='overflow-x-auto border'>
            <table className='min-w-full divide-y divide-gray-300 table-fixed capitalize'>
              <thead className='bg-gray-50 capitalize'>
                <tr>
                  <TableHeader
                    isSort={false}
                    sortName='support_item_name'
                    label={"Support Item"}
                    cssClass='w-[40%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='support_item_number'
                    label={"Support Item Number"}
                    cssClass='w-[15%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='unit'
                    label={"Unit"}
                    cssClass='w-[10%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='quantity'
                    label={"Total Quantity"}
                    cssClass='w-[10%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='total_budget_amount'
                    label={"Total Funding"}
                    cssClass='w-[10%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='total_delivered'
                    label={"Total Delivered"}
                    cssClass='w-[10%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='rate_amount'
                    label={"Rate"}
                    cssClass='w-[10%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='remain_quantity'
                    label={"Remaining Quantity"}
                    cssClass='w-[10%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='remain_amount'
                    label={"Remaining Amount"}
                    cssClass='w-[10%]'
                  />

                  <TableHeader
                    isSort={false}
                    sortName='Actions'
                    label={"Actions"}
                  />
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {fetchLoader ? (
                  <tr>
                    <td colSpan={11} className='bg-gray-50 py-2'>
                      <CircularLoader
                        classes='flex justify-center items-center'
                        classloader='loader-l'
                      />
                    </td>
                  </tr>
                ) : serviceSupportBudgetToMap &&
                  serviceSupportBudgetToMap.length > 0 ? (
                  serviceSupportBudgetToMap.map((fund, index) => (
                    <tr key={index} className='text-left hover:bg-blue-50'>
                      <td className=' px-3 py-4 text-sm text-gray-500'>
                        <div
                          title={fund.price_catalog.support_item_name}
                          className='text-gray-500 '
                        >
                          {fund.price_catalog.support_item_name}
                        </div>
                        {fund.external_sp && (
                          <div className='text-gray-800 font-bold capitalize'>
                            {fund.external_sp.name}
                          </div>
                        )}
                      </td>
                      <td
                        title={fund.price_catalog.support_item_number}
                        className=' px-3 py-4 text-sm text-gray-500'
                      >
                        <div className='text-gray-500'>
                          {fund.price_catalog.support_item_number}
                        </div>
                      </td>
                      <td className=' px-3 py-4 text-sm text-gray-500'>
                        <div className='text-gray-500'>{fund.unit}</div>
                      </td>
                      <td className=' px-3 py-4 text-sm text-gray-500'>
                        <div className='text-gray-500'>
                          {fund.unit === "H"
                            ? gethoursAndMins(fund.total_hours, fund.total_mint)
                            : fund.quantity}
                        </div>
                      </td>
                      <td className=' px-3 py-4 text-sm text-gray-500'>
                        <div className='text-gray-500'>
                          ${fund.total_budget_amount}
                        </div>
                      </td>
                      <td className=' px-3 py-4 text-sm text-gray-500'>
                        <div className='text-gray-500'>
                          {`$${
                            fund.delivered_amount
                              ? parseFloat(fund.delivered_amount).toFixed(2)
                              : 0
                          }`}
                        </div>
                      </td>
                      <td className='px-3 py-4 text-sm text-gray-500'>
                        <div className='text-gray-500'>
                          {`$${
                            fund.alternative_rate &&
                            parseInt(fund.alternative_rate) > 0
                              ? fund.alternative_rate
                              : fund.original_rate
                          }`}
                        </div>
                      </td>
                      <td className='px-3 py-4 text-sm text-gray-500'>
                        <div className='text-gray-500'>
                          {fund.unit === "H"
                            ? gethoursAndMins(
                                fund.remain_total_hours,
                                fund.remain_total_mint
                              )
                            : fund.remain_quantity ?? 0}
                        </div>
                      </td>
                      <td className='px-3 py-4 text-sm text-gray-500'>
                        <div className='text-gray-500'>
                          {parseInt(fund.remain_amount ?? 0) <= 0
                            ? "---"
                            : `$${parseFloat(fund.remain_amount ?? 0).toFixed(
                                2
                              )}`}
                        </div>
                      </td>
                      <td className='relative  px-3 py-4 text-sm text-gray-500'>
                        <div className='flex justify-between space-x-2'>
                          <span
                            className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
                            onClick={() => setServiceSupportItem(fund)}
                          >
                            <div className='icon-pencil-wrapper' title='Edit'>
                              <BiPencil className='icon-size' />
                            </div>
                          </span>
                          {/* <button
                              onClick={() => {
                                // setOpen(true);
                                // setCosModalData(service);
                              }}
                              className="bg-white hover:bg-purple-500 hover:text-white text-purple-500 rounded-md"
                            >
                              <div className="border border-purple-500 p-[3px] rounded-md">
                                <HiEye className="rounded-md text-center h-4 w-4" />
                              </div>
                            </button>  */}
                          {/* {selectedId === fund.uuid && removeLoader ? (
                                <Loader
                                  isSmallView
                                  cssClass="mt-1 text-black"
                                />
                              ) : (
                                <button
                                  onClick={() => handleDelete(fund.uuid)}
                                  className="bg-white hover:bg-red-500 hover:text-white text-red-500 rounded-md"
                                >
                                  <div className="border border-red-400 p-[3px] rounded-md">
                                    <MdDelete className="rounded-md text-center h-4 w-4 hover:text-white" />
                                  </div>
                                </button>
                              )}  */}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  renderLoading !== LOADER_STATUS.NA &&
                  serviceSupportBudgetToMap.length === 0 && (
                    <tr>
                      <td
                        colSpan={11}
                        className='py-6 text-center text-red-600'
                      >
                        No Data found!
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='bg-white py-4'>
        <PaginationComponent
          page={paginationObj.page}
          activeListLength={serviceSupportBudgetToMap}
          limit={SERVICE_SUPPORT_LIMIT}
          totalList={
            paginationObj.totalPages === 1
              ? serviceSupportBudgetToMap && serviceSupportBudgetToMap.length
              : paginationObj.total
          }
          handlePagination={(e) => {
            const obj = paginationObj;
            obj.page = e;
            setPaginationObj({ ...obj });
          }}
          totalPages={paginationObj.totalPages}
        />
      </div>
    </>
  );
};

export default ServiceSupportTable;
