import React, { useState } from "react";
import axios from "axios";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { apiDeleteRequest } from "helpers/Requests";
import { getUrlRole } from "Utils/utils";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
//import uuid from "react-tabs/lib/helpers/uuid";

const DeleteParticipantModal = (props) => {
  const { isShow, handleClose, participantId, isUserGracePeriodOver } = props;
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const handleDelete = async () => {
    if (isUserGracePeriodOver) {
      return;
    }

    setRequest({ ...request, isRequested: true });

    try {
      const response = await apiDeleteRequest(
        `/${getUrlRole()}/participant-delete/${uuid}`
      );
      if (response.status === 204) {
        setRequest({ isRequested: false, isSuccess: true, isFailed: false });
        toast.success("Participant deleted successfully!");
        handleClose();
        navigate(-1);
      }
    } catch (error) {
      setRequest({ isRequested: false, isSuccess: false, isFailed: true });
      console.error("Failed to delete participant:", error);
    }
  };

  const modalFooter = () => (
    <>
      <CustomButton
        showLoading={request.isRequested}
        isDisabled={request.isRequested || isUserGracePeriodOver}
        label='Yes, delete participant'
        clickHandler={handleDelete}
        variant='danger'
      />

      <CustomButton
        clickHandler={handleClose}
        label='No, cancel changes'
      />
    </>
  );

  return (
    <>
      {isShow && (
        <ModalSkeleton
          title='Delete Participant'
          cssClass='w-[50%]'
          isOpen={isShow}
          closeModal={handleClose}
          modalFooter={modalFooter()}
        >
          <p className='text-gray-700'>
            Are you sure you want to delete this participant? <br />Deleting a
            participant will permanently erase all associated data, including
            forms, rostering, reports etc. This action is
            irreversible.
          </p>
        </ModalSkeleton>
      )}
    </>
  );
};

export default DeleteParticipantModal;
