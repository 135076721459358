import React from "react";
import { BiFileBlank } from "react-icons/bi";

const FormsList = ({ background, textColor }) => {
  const formsLIst = [
    { name: "ID Copy.pdf", size: "200 KB" },
    { name: "Drivers license 2022.pdf", size: "100 KB" },
    { name: "Credit Card Application.pdf", size: "150 KB" },
    { name: "Spouse Registration Document.pdf", size: "10.10 KB" },
  ];
  return (
    <div className={`rounded-md px-4 pt-4 pb-6 my-4 shadow ${background}`}>
      <p className={`font-inter pb-0 ${textColor}`}>Incident Reporting Forms</p>
      <p className={`text-sm font-thin ${textColor}`}>
        All documents uploaded since December 2020
      </p>
      <div className="grid grid-cols-1 gap-4 py-6">
        {formsLIst.flatMap((item) => (
          <div className="col-span-1 bg-white rounded-md flex justify-between items-center py-2 px-4 border shadow">
            <div className="flex space-x-2">
              <div className="rounded-full bg-purple-50 h-12 w-12 flex items-center justify-center">
                <div className="h-8 w-8 rounded-full bg-purple-100 flex items-center justify-center">
                  <BiFileBlank className="h-5 w-5 text-gradient" />
                </div>
              </div>
              <div className="leading-1">
                <span className="text-sm font-medium">{item.name}</span>
                <p className="text-sm">{item.size}</p>
              </div>
            </div>
            <div className="space-x-2">
              <button className="rounded-md px-4 py-1 border shadow">
                Edit
              </button>
              <button className="rounded-md px-4 py-1 border shadow bg-gradient text-white">
                View
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormsList;
