import React, { useContext } from "react";
import { parseBooleanVal } from "../../../../Utils/utils";
import RadioComponent from "./RadioComponent";
import { LoginContext } from "helpers/LoginContext";

const RiskRatingForm = (props) => {
  const {
    form,
    index,
    formUUID,
    viewFormData,
    handleChangeFormData,
    isReadOnly = false,
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  return (
    <>
      {form?.input_fields.length > 0 &&
        form?.input_fields.map((field, i) => {
          if (field.type === "sub_section") {
            return (
              <>
                {field?.sub_fields &&
                  field?.sub_fields.length > 0 &&
                  field?.sub_fields.map((item, sf) => {
                    if (
                      parseBooleanVal(item.isRisk) &&
                      item.hasOwnProperty("riskPositive") &&
                      item.value ===
                        item.options[parseInt(item.riskPositive)].lable
                    ) {
                      if (
                        item.type === "radio" &&
                        parseBooleanVal(item.isRisk)
                      ) {
                        return (
                          <RadioComponent
                            isUserGracePeriodOver={isUserGracePeriodOver}
                            handleChangeFormData={handleChangeFormData}
                            key={`${index}-${item.lable}-${sf}`}
                            index={`${item.lable}-${sf}`}
                            fieldIndex={i}
                            sectionIndex={index}
                            subSectionIndex={sf}
                            field={item}
                            viewFormData={viewFormData}
                            formUUID={formUUID}
                          />
                        );
                      }
                    }
                  })}
              </>
            );
          } else if (
            parseBooleanVal(field.isRisk) &&
            field.hasOwnProperty("riskPositive") &&
            field.value === field.options[parseInt(field.riskPositive)].lable
          ) {
            if (field.type === "radio" && parseBooleanVal(field.isRisk)) {
              return (
                <RadioComponent
                  isUserGracePeriodOver={isUserGracePeriodOver}
                  isReadOnly={isReadOnly}
                  key={`${index}-${field.lable}-${i}`}
                  index={`${field.lable}-${i}`}
                  sectionIndex={index}
                  fieldIndex={i}
                  field={field}
                  viewFormData={viewFormData}
                  formUUID={formUUID}
                  subSectionIndex={null}
                  handleChangeFormData={handleChangeFormData}
                />
              );
            }
          }
          return <></>;
        })}
    </>
  );
};
export default RiskRatingForm;
