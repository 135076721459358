import React from "react";
import CustomButton from "../../../components/common/Button/CustomButton";
import ModalSkeleton from "../../../components/Modals/ModalSkeleton";

const CSModal = (props) => {
  const { open, setOpen, cosModalData, version, state } = props;

  const modalFooterButton = () => (
    <CustomButton
      clickHandler={() => setOpen(false)}
      label="Close"
      variant="danger"
    />
  );

  const getState = (val) => {
    if (!val) return "";
    const data =
      state?.find((ver) => ver?.id === cosModalData?.state_id)?.name || null;
    if (data) {
      return data.toUpperCase();
    }

    return cosModalData?.state_id.toUpperCase();
  };

  return (
    <ModalSkeleton
      title={cosModalData?.support_item_name}
      cssClass="w-[60%]"
      modalFooter={modalFooterButton()}
      closeModal={() => setOpen(false)}
      isOpen={open}
    >
      <div>
        <div className="text-sm text-gray-500 mb-5">
          {cosModalData?.support_category_name}, ID{" "}
          {cosModalData?.support_item_number}
        </div>

        <div className="flex border-t-2 border-b-2 border-gray-200">
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            Registeration Number
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {cosModalData?.registration_group_number}
          </div>
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            Registeration Name
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {cosModalData?.registration_group_name}
          </div>
        </div>

        <div className="flex border-b-2 border-gray-200">
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            Support Number
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {cosModalData?.support_item_number}
          </div>
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            Unit
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {cosModalData?.unit}
          </div>
        </div>

        <div className="flex border-b-2 border-gray-200">
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            Start Date
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {cosModalData?.start_date}
          </div>
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            End Date
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {cosModalData?.end_date}
          </div>
        </div>

        <div className="flex border-b-2 border-gray-200">
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            Version
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {version?.find(
              (ver) => ver?.value === cosModalData?.price_catalog_version_id
            )?.label || cosModalData?.price_catalog_version_id}
          </div>
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            State
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {getState(cosModalData?.state_id)}
          </div>
        </div>

        <div className="flex border-b-2 border-gray-200">
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            Quote
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {cosModalData?.quote}
          </div>
          <div className="bg-gray-100 p-5 w-1/4 text-sm flex flex-col justify-center">
            Price
          </div>
          <div className=" p-5 w-1/4 text-gray-500 text-sm">
            {cosModalData?.price}
          </div>
        </div>
      </div>
    </ModalSkeleton>
  );
};
export default CSModal;
