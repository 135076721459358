import NumberFormatter from "components/common/NumberFormatter/NumberFormatter";
import React from "react";
import ArrowUp from "../../../../assets/ArrowUp.svg";

const CosStatsCard = (props) => {
  const { item, index, cssClass } = props;
  const {
    name,
    color,
    dollar,
    Hours,
    totalhours,
    title,
    week,
    isAmount,
    badge
  } = item;

  return (
    <div
      key={index}
      style={{ borderLeft: `4px solid ${color}` }}
      className={`mt-4 grid-span-1 overflow-hidden rounded-lg py-5 pl-4 bg-white border transition ease-in-out duration-150 hover:shadow-lg ${cssClass}`}
    >
      <div className="text-gray-500">{name}</div>
      <div className="flex items-center gap-2">
        <div className="text-gray-900 font-semibold text-5xl mt-3 flex">
          {Hours}
        </div>
        <div>
          {badge && (
            <span className="gap-2 inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-[#027A48]">
              <img src={ArrowUp} alt="" />
              {badge}
            </span>
          )}
        </div>
      </div>

      <div className="mt-4">
        {/* <div className="flex gap-4 items-center relative top-[9px]">
          <div className="text-gray-500 whitespace-nowrap">{title}</div>
          <div className="text-gray-900 font-semibold text-sm">
            {totalhours}
          </div>
        </div> */}
        <div className="flex items-center">
          <div className="text-gray-500 whitespace-nowrap">{week}</div>
          <div className="text-gray-900 font-semibold text-sm">
            {isAmount ? (
              <NumberFormatter
                cssClass="text-gray-900 font-semibold text-sm"
                prefix={"$"}
                value={totalhours}
              />
            ) : (
              dollar
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CosStatsCard;
