import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import CircularLoader from "../../../common/CircularLoader/CircularLoader";
import { BiCalendar, BiPlus } from "react-icons/bi";
import { LOADER_STATUS } from "../../../../Constants/constant";
import AppointmentModal from "./AppointmentsModal";
import CustomButton from "../../../common/Button/CustomButton";
import AppointmentItem from "./AppointmentItem";
import { LoginContext } from "helpers/LoginContext";

const Appointments = (props) => {
  const {
    participantInfo,
    getAppointments,
    appointmentList,
    isLoading,
    type,
    updateAppointment,
    participantAppointmentDeleteLocally,
  } = props;
  const { uuid } = useParams();

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [participantAppointment, setParticipantAppointment] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(LOADER_STATUS.NA);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  useEffect(() => {
    if (appointmentList) {
      setParticipantAppointment(appointmentList);
    }
  }, [appointmentList]);

  useEffect(() => {
    if (!isLoading && fetchLoader === LOADER_STATUS.PENDING) {
      setFetchLoader(LOADER_STATUS.COMPLATE);
    }
  }, [isLoading]);

  useEffect(() => {
    if (uuid && appointmentList.length === 0 && type === "page_render") {
      setFetchLoader(LOADER_STATUS.PENDING);
      getAppointments({ uuid });
    }
  }, []);

  if (type && type === "header_button") {
    return (
      <>
        <CustomButton
          clickHandler={() => setIsOpen(true)}
          label='Appointment'
          Icon={<BiPlus />}
        />
        {isOpen && (
          <AppointmentModal
            isUserGracePeriodOver={isUserGracePeriodOver}
            isOpen={isOpen}
            closeModal={() => setIsOpen(false)}
            participantInfo={participantInfo}
          />
        )}
      </>
    );
  } else if (type === "page_render") {
    return (
      <>
        <div className='px-4'>
          <h2 className='text-lg font-semibold text-gray-900'>
            Upcoming Meetings
          </h2>
          <div className='lg:grid lg:grid-cols-12 lg:gap-x-16'>
            {isLoading ? (
              <div className='flex justify-center items-center w-full bg-gray-50 lg:col-span-12 xl:col-span-12'>
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <ol className='mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-12 xl:col-span-12'>
                {participantAppointment.map((meeting, index) => (
                  <AppointmentItem
                    key={index}
                    index={index}
                    meeting={meeting}
                    setSelectedAppointment={setSelectedAppointment}
                    setIsOpen={setIsOpen}
                    participantAppointmentDeleteLocally={
                      participantAppointmentDeleteLocally
                    }
                    isUserGracePeriodOver={isUserGracePeriodOver}
                  />
                ))}
              </ol>
            )}
            {fetchLoader === LOADER_STATUS.COMPLATE &&
              participantAppointment.length === 0 && (
                <div className=' col-span-12 py-6 space-x-2 flex justify-center items-center'>
                  <div className='flex justify-center items-center space-x-4'>
                    <BiCalendar className='h-6 w-6 text-red-500' />
                    <p className='font-medium text-red-500'>No Data Found!</p>
                  </div>
                </div>
              )}
          </div>
        </div>
        {isOpen && selectedAppointment && (
          <AppointmentModal
            isUserGracePeriodOver={isUserGracePeriodOver}
            isUpdate={true}
            appointment={selectedAppointment}
            isOpen={isOpen}
            closeModal={() => setIsOpen(false)}
            participantInfo={participantInfo}
            updateAppointment={updateAppointment}
          />
        )}
      </>
    );
  }
};

export default Appointments;
