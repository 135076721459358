import React from "react";
import { classNames } from "Utils/utils";

const TimeSheetTabs = (props) => {
  const { tabs, handleChange, selectedTab } = props;

  return (
    <>
      {/* <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          onChange={(e) => handleChange(e.target.value)}
          id="tabs"
          name="tabs"
          className="mt-4 block w-full pl-3 pr-10 py-1 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          defaultValue={
            tabs.find((tab) => tab.current)
              ? tabs.find((tab) => tab.current).name
              : ""
          }
        >
          {tabs.map((tab, index) => (
            <option key={index} role="button" tabIndex={0} value={tab.value}>
              {tab.name}
            </option>
          ))}
        </select>
      </div> */}
      <div className="hidden sm:block bg-gray-200 px-2 rounded-3xl">
        <div className="py-2">
          <nav className="flex space-x-3" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <span
                onClick={() => handleChange(tab)}
                role="button"
                tabIndex={0}
                key={index}
                style={{ border: "1px solid #4B6CB7" }}
                className={classNames(
                  tab.value === selectedTab.value
                    ? "bg-[#4B6CB7] text-white"
                    : "text-[#667585] bg-white hover:text-white hover:bg-[#4B6CB7]",
                  "whitespace-nowrap font-medium text-sm rounded-full py-1 px-2"
                )}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
};

export default TimeSheetTabs;
