import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import {
  EMAIL_REGEX_EXP,
  REGEX_NDIS_NUMBER,
  REGEX_PHONE_NUMBER,
  STATES_LIST,
} from "Constants/constant";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { apiPutRequest } from "helpers/Requests";
import { toast } from "react-toastify";

const EditExternalServiceProviderModal = (props) => {
  const {
    isUserGracePeriodOver,
    handleClose,
    open,
    setOpen,
    selectedServiceProvider,
    refreshServiceProviderList,
  } = props;
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const {
    uuid, // Assuming uuid is part of selectedServiceProvider
    name,
    email,
    phone,
    street,
    state,
    suburb,
    postcode,
    billing_contact,
    abn,
    ndis_regis_no,
    bsb_no,
    account_title,
    //account_name,
    account_number,
    provider_no,
    send_remittance,
  } = selectedServiceProvider;

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const handleEdit = async (data) => {
    setRequest({ isRequested: true, isSuccess: false, isFailed: false });
    try {
      const response = await apiPutRequest(
        `/cos/external-sp-update/${uuid}`,
        data
      );
      setRequest({ isRequested: false, isSuccess: true, isFailed: false });

      reset({
        name: data.name,
        email: data.email,
        phone: data.phone,
        street: data.street,
        state: data.state,
        suburb: data.suburb,
        postcode: data.postcode,
        billing_contact: data.billing_contact,
        abn: data.abn,
        ndis_regis_no: data.ndis_regis_no,
        bsb_no: data.bsb_no,
        account_title: data.account_title,
        //account_name: data.account_name,
        account_number: data.account_number,
        provider_no: data.provider_no,
      });

      if (response.status === 200) {
        toast.success("External Sp updated successfully!");
        refreshServiceProviderList();
        handleClose();
      }
    } catch (error) {
      setRequest({ isRequested: false, isSuccess: false, isFailed: true });
      console.error("Update failed:", error);
    }
  };

  const modalFooter = () => (
    <>
      <CustomButton
        showLoading={request.isRequested}
        isDisabled={request.isRequested || isUserGracePeriodOver}
        label='Update'
        clickHandler={handleSubmit(handleEdit)}
      />
      <CustomButton variant='danger' label='Close' onClick={handleClose} />
    </>
  );

  useEffect(() => {
    reset({
      name,
      email,
      phone,
      street,
      state,
      suburb,
      postcode,
      billing_contact,
      abn,
      ndis_regis_no,
      bsb_no,
      account_title,
      //account_name,
      account_number,
      provider_no,
    });
  }, [selectedServiceProvider, reset]);

  return (
    <>
      <ModalSkeleton
        modalFooter={modalFooter()}
        isOpen={open}
        closeModal={() => setOpen(false)}
        cssClass='w-[55%]'
        title={"Edit External Service Provider Details"}
      >
        {/* <div>
          <p>Please Enter details to update External Service Provider.</p>
        </div> */}
        <form onSubmit={handleSubmit(handleEdit)} className='mt-3 space-y-2'>
          {/* Form Fields */}
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='firstName'
                className='block text-sm font-medium text-gray-700'
              >
                Name
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='name'
                  id='firstName'
                  className='did-floating-input capitalize w-full'
                  {...register("name", { required: true, maxLength: 254 })}
                />
                {errors.name && (
                  <span className='text-sm text-red-500'>Name is required</span>
                )}
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='phone'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Phone
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='phone'
                  id='phone'
                  className='did-floating-input capitalize w-full'
                  {...register("phone", {
                    required: true,
                    pattern: REGEX_PHONE_NUMBER,
                    onChange: (e) => {
                      const sanitizedValue = e.target.value.replace(/\D/g, "");
                      const formattedValue = sanitizedValue.slice(0, 10);
                      e.target.value = formattedValue;
                    },
                  })}
                />
                {errors.phone && (
                  <span className='text-sm text-red-500'>
                    Phone number is required!
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='email'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Email
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='email'
                  id='email'
                  className='did-floating-input w-full'
                  {...register("email", {
                    required: true,
                    pattern: EMAIL_REGEX_EXP,
                  })}
                />
                {errors?.email?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    Email is required!
                  </span>
                )}
                {errors?.email?.type === "pattern" && (
                  <span className='text-sm text-red-500'>
                    Please enter a valid email address!
                  </span>
                )}
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='billing_contact'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Billing Contact
              </label>
              <div className='mt-1'>
                <input
                  type='number'
                  name='billing_contact'
                  id='billing_contact'
                  className='did-floating-input capitalize w-full'
                  {...register("billing_contact", {
                    pattern: /^\d+$/,
                    onChange: (e) => {
                      e.target.value = e.target.value.toString().slice(0, 10);
                    },
                  })}
                />
                {errors?.billing_contact?.type === "minLength" && (
                  <span className='text-sm text-red-500'>
                    Phone number must have 10 digits
                  </span>
                )}
                {errors?.billing_contact?.type === "pattern" && (
                  <span className='text-sm text-red-500'>
                    Please enter a valid phone number!
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='street'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Street
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='street'
                  id='street'
                  className='did-floating-input capitalize w-full'
                  {...register("street", {
                    maxLength: 254,
                  })}
                />
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='suburb'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Suburb
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='suburb'
                  id='suburb'
                  className='did-floating-input capitalize w-full'
                  {...register("suburb", {
                    maxLength: 254,
                  })}
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className=''>
                <label className='block text-sm font-medium text-gray-700'>
                  State
                </label>
                <select
                  className='mt-1 did-floating-select'
                  {...register("state", {})}
                >
                  {STATES_LIST.map((item) => {
                    return (
                      <option key={`${item.value}`} value={`${item.value}`}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className='col-span-1'>
              <label
                htmlFor='postcode'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Post code
              </label>
              <div className='mt-1'>
                <input
                  type='number'
                  name='postcode'
                  id='postcode'
                  className='did-floating-input capitalize w-full'
                  {...register("postcode", {
                    maxLength: 4,
                    minLength: 4,
                    onChange: (e) => {
                      e.target.value = e.target.value.toString().slice(0, 4);
                    },
                  })}
                />

                {errors?.postcode?.type === "maxLength" && (
                  <span className='text-sm text-red-500'>
                    Postal Code must have 4 digits
                  </span>
                )}
                {errors?.postcode?.type === "minLength" && (
                  <span className='text-sm text-red-500'>
                    Postal Code must have 4 digits
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-4 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='account_title'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Account Title
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='account_title'
                  id='account_title'
                  className='did-floating-input capitalize w-full'
                  {...register(
                    "account_title",

                    {
                      maxLength: 254,
                    }
                  )}
                />
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='bsb_no'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                BSB
              </label>
              <div className='mt-1'>
                <input
                  type='number'
                  name='bsb_no'
                  id='bsb_no'
                  className='did-floating-input capitalize w-full'
                  {...register("bsb_no")}
                />
              </div>
            </div>
            <div className='col-span-2'>
              <label
                htmlFor='account_number'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Account Number
              </label>
              <div className='mt-1'>
                <input
                  type='number'
                  name='account_number'
                  id='account_number'
                  className='did-floating-input capitalize w-full'
                  {...register("account_number")}
                />
                {errors.account_number && (
                  <span className='text-red-500'>
                    Account Number is required
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='abn'
                className='block text-sm font-medium text-gray-700'
              >
                ABN
              </label>
              <input
                {...register("abn", {
                  pattern: "[0-9]*",
                  maxLength: 11,
                  minLength: 11,
                  onChange: (e) =>
                    (e.target.value = e.target.value.toString().slice(0, 11)),
                })}
                className='did-floating-input mt-1'
                type='number'
                id='abn'
              />
              {errors?.abn?.type === "required" ? (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              ) : errors?.abn?.type === "maxLength" ? (
                <span className='text-sm text-red-500'>
                  Please enter a valid ABN number max 11 digits.
                </span>
              ) : errors?.abn?.type === "minLength" ? (
                <span className='text-sm text-red-500'>
                  Please enter a valid ABN number min 11 digits.
                </span>
              ) : null}
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='ndis_regis_no'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                NDIS Registration No.
              </label>
              <div className='mt-1'>
                <input
                  type='number'
                  name='ndis_regis_no'
                  id='ndis_regis_no'
                  className='did-floating-input capitalize w-full'
                  {...register("ndis_regis_no", {
                    pattern: {
                      value: REGEX_NDIS_NUMBER,
                      message:
                        "NDIS number should start with either '43' or '5'",
                    },
                  })}
                />
                {errors.required
                  ? errors.ndis_regis_no && (
                      <span className='text-red-500'>
                        This field is required
                      </span>
                    )
                  : errors?.ndis_regis_no?.type === "pattern" && (
                      <span className='text-red-500'>
                        {errors?.ndisNo?.message}
                      </span>
                    )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='provider_no'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Provider Code
              </label>
              <div className='mt-1'>
                <input
                  type='number'
                  name='provider_no'
                  id='provider_no'
                  className='did-floating-input capitalize w-full'
                  {...register("provider_no")}
                />
              </div>
            </div>
          </div>
        </form>
      </ModalSkeleton>
    </>
  );
};

export default EditExternalServiceProviderModal;
