import React, { useEffect, useState, useMemo, useContext } from "react";
import { BiPencil } from "react-icons/bi";
import {
  emptyPaginationObj,
  LOADER_STATUS,
  PLAN_BUDGET_LIMIT,
} from "Constants/constant";
import {
  baseURLImage,
  downloadFileFromServer,
  getFileExtension,
  getPaginationObj,
} from "Utils/utils";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import Loader from "components/common/Loader/Loader";
import PaginationComponent from "components/common/Pagination/index";
import TableHeader from "components/common/Table/TableHeader";
import {
  HiOutlineDownload,
  HiOutlineEye,
  HiOutlineTrash,
} from "react-icons/hi";
import { LoginContext } from "helpers/LoginContext";

const ServiceCategoryTable = (props) => {
  const {
    participant,
    fetchLoader,
    removeLoader,
    funding,
    setEditServiceCategory,
    getServiceCategoryList,
    serviceCategories,
    removeServiceCategory,
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [paginationObj, setPaginationObj] = useState(
    serviceCategories ? getPaginationObj(serviceCategories) : emptyPaginationObj
  );

  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (selectedId && !removeLoader) {
      setSelectedId(null);
    }
  }, [removeLoader]);

  useEffect(() => {
    if (serviceCategories) {
      setPaginationObj({
        ...getPaginationObj(serviceCategories),
      });
    }
  }, [serviceCategories]);

  useEffect(() => {
    if (
      !serviceCategories ||
      (serviceCategories &&
        Object.keys(serviceCategories).length > 0 &&
        serviceCategories.hasOwnProperty("data") &&
        serviceCategories.data.length === 0)
    ) {
      fetchServiceCategory({ page: paginationObj.page });
    }
  }, []);

  useEffect(() => {
    if (
      serviceCategories &&
      serviceCategories.current_page !== paginationObj.page
    ) {
      fetchServiceCategory({ page: paginationObj.page });
    }
  }, [paginationObj.page]);

  const serviceCategoryToMap = useMemo(() => {
    if (serviceCategories) {
      return serviceCategories.data;
    }
    return [];
  }, [serviceCategories]);

  const fetchServiceCategory = async (filters) => {
    if (!filters) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: PLAN_BUDGET_LIMIT });
    Object.assign(filters, {
      pt_id: participant.uuid || 1,
      funding_uuid: funding.uuid || 1,
    });
    if (renderLoading === LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.PENDING);
    }
    getServiceCategoryList(filters);
  };

  const handleDelete = (serviceCategoryId) => {
    setSelectedId(serviceCategoryId);
    removeServiceCategory({ id: serviceCategoryId });
    // fetchServiceCategory({ page: 1 }).then();
  };

  return (
    <>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='min-w-full align-middle'>
            <div className='overflow-x-auto border'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50 capitalize'>
                  <tr>
                    {/* <TableHeader
                      sortName="funding_contract_id"
                      isSort={false}
                      label={"Funding ID"}
                      cssCLass="text-center"
                    /> */}
                    <TableHeader
                      isSort={false}
                      sortName=''
                      label={"External SP"}
                      cssCLass='text-start w-[20%]'
                    />
                    <TableHeader
                      isSort={false}
                      label={"Plan Category"}
                      cssCLass='text-start w-[10%]'
                    />
                    <TableHeader
                      isSort={false}
                      label={"Plan Subcategory"}
                      cssClass='text-left'
                    />
                    <TableHeader
                      isSort={false}
                      label={"SP Budget Amount"}
                      cssClass='text-center'
                    />
                    <TableHeader
                      isSort={false}
                      label={"SP Category Allocated"}
                      cssClass='text-center'
                    />
                    <TableHeader
                      isSort={false}
                      label={"SP Category Delivered"}
                      cssClass='text-center'
                    />
                    {/* <TableHeader
                      isSort={false}
                      sortName="amount_claimed"
                      label={"Amount Claimed"}
                      cssClass="text-center"
                    />
                    <TableHeader
                      isSort={false}
                      sortName="amount_unclaimed"
                      label={"Amount Unclaimed"}
                      cssClass="text-center"
                    /> */}
                    <TableHeader
                      isSort={false}
                      label={"SP Category Remaining"}
                      cssClass='text-center'
                    />
                    <TableHeader
                      isSort={false}
                      label={"Actions"}
                      cssClass='text-center'
                    />
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {fetchLoader ? (
                    <tr>
                      <td colSpan={9} className='bg-gray-50 py-2'>
                        <CircularLoader
                          classes='flex justify-center items-center'
                          classloader='loader-l'
                        />
                      </td>
                    </tr>
                  ) : serviceCategoryToMap &&
                    serviceCategoryToMap.length > 0 ? (
                    serviceCategoryToMap.map((fund, index) => (
                      <tr key={index} className='text-center hover:bg-blue-50'>
                        {/* <td className="whitespace-nowrap py-4  px-3 text-sm">
                          <div className="flex items-center ml-0 font-medium text-gray-500 capitalize">
                            {fund.funding_contract_id}
                          </div>
                        </td> */}
                        <td className=' whitespace-nowrap px-3 py-4 text-start text-sm text-gray-500 capitalize'>
                          {fund?.external_sp?.name}
                        </td>
                        <td className=' whitespace-nowrap px-3 py-4 text-start text-sm text-gray-500 capitalize'>
                          {fund?.service_sub_category?.service_category?.name}
                        </td>
                        <td className=' px-3 text-left py-4 text-sm text-gray-500 w-[20%] overflow-hidden text-ellipsis'>
                          {fund?.service_sub_category?.title}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>
                            {`$${fund.total_budget_amount}`}
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>
                            {`$${parseFloat(fund.amount_allocated ?? 0).toFixed(
                              2
                            )}`}
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>
                            {`$${parseFloat(fund.amount_delivered ?? 0).toFixed(
                              2
                            )}`}
                          </div>
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-500">
                            {`$${fund.amount_claimed ?? 0}`}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-500">
                            {`$${fund.amount_unclaimed ?? 0}`}
                          </div>
                        </td> */}
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-500'>
                            {parseInt(fund.amount_remaining ?? 0) <= 0
                              ? "---"
                              : `$${parseFloat(
                                  fund.amount_remaining ?? 0
                                ).toFixed(2)}`}
                          </div>
                        </td>
                        <td className='relative whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='flex justify-between space-x-2'>
                            <button
                              className='bg-white hover:bg-green-500 hover:text-white text-green-600 rounded-md'
                              onClick={() => setEditServiceCategory(fund)}
                            >
                              <div className='icon-pencil-wrapper' title='Edit'>
                                <BiPencil className='icon-size' />
                              </div>
                            </button>
                            {fund.file && (
                              <div className='gap-x-2 flex items-center justify-center'>
                                <a
                                  href={`${baseURLImage(fund.file)}`}
                                  without
                                  rel='noopener noreferrer'
                                  target='_blank'
                                  className='icon-eye-wrapper'
                                >
                                  <HiOutlineEye className='icon-size' />
                                </a>
                                <p
                                  onClick={() => {
                                    downloadFileFromServer(
                                      fund.file,
                                      `${fund.file_name}.${getFileExtension(
                                        fund.file
                                      )}`
                                    );
                                  }}
                                  className='cursor-pointer icon-download-wrapper'
                                >
                                  <HiOutlineDownload className='icon-size' />
                                </p>
                              </div>
                            )}
                            {selectedId === fund.uuid && removeLoader ? (
                              <Loader isSmallView cssClass='mt-1 text-black' />
                            ) : (
                              <button
                                disabled={isUserGracePeriodOver}
                                onClick={() => {
                                  if (isUserGracePeriodOver) return;
                                  handleDelete(fund.uuid);
                                }}
                                className='bg-white hover:bg-red-500 hover:text-white text-red-500 rounded-md'
                              >
                                <div
                                  className='icon-delete-wrapper'
                                  title='Delete'
                                >
                                  <HiOutlineTrash className='icon-size' />
                                </div>
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={9} className='py-6 text-center text-red-600'>
                        No Data Found!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white py-4'>
        <PaginationComponent
          page={paginationObj.page}
          activeListLength={serviceCategoryToMap}
          limit={PLAN_BUDGET_LIMIT}
          totalList={
            paginationObj.totalPages === 1
              ? serviceCategoryToMap && serviceCategoryToMap.length
              : paginationObj.total
          }
          handlePagination={(e) => {
            const obj = paginationObj;
            obj.page = e;
            setPaginationObj({ ...obj });
          }}
          totalPages={paginationObj.totalPages}
        />
      </div>
    </>
  );
};

export default ServiceCategoryTable;
