import { connect } from "react-redux";
import CosParticipant from "./ParticipantList";

import {
  supportCoordinatorsRequest,
  getCosParticipantListRequest,
} from "../../../../store/actions/cos.actions";
import {
  getSupportCoordinator,
  getSupportCoordinatorLoader,
  getCosParticipant,
  getCosParticipantLoader,
} from "../../../../store/selector/cos.selector";

const mapDispatchToProps = {
  getCosParticipant: getCosParticipantListRequest,
  getSupportCoordinators: supportCoordinatorsRequest,
};

const mapStateToProps = (state) => {
  const cosParticipant = getCosParticipant(state);
  const cosParticipantLoader = getCosParticipantLoader(state);

  const supportCoordinator = getSupportCoordinator(state);
  const supportCoordinatorLoader = getSupportCoordinatorLoader(state); // these are support coordinators not a category

  return {
    cosParticipant,
    cosParticipantLoader,
    supportCoordinator,
    supportCoordinatorLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CosParticipant);
