import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "../../../../components/common/Button/CustomButton";
import ManualDeliveredModal from "./ManualDeliveryModal";
import ManaulDeliveryTable from "./ManualDeliveryTable";

const ManualDelivery = (props) => {
  const {
    selectedFunding,
    participant,
    getPlanCatagories,
    PlanCatagories,
    catalogStates,
    getCatalogServicesSubCategory,
    storeManualDeliveryLoader,
    manualDeliveredCreate,
    getManaulDeliveriesList,
    fetchManualDeliveriesLoader,
    manualDeliveries,
    storeManualDeliveryError,
  } = props;

  const [PlanCatagoriesList, setPlanCatagoriesList] = useState(null);
  const [catalogState, setCatalogState] = useState(null);
  const [openManualModal, setOpenManualModal] = useState(false);

  useEffect(() => {
    if (!PlanCatagories && selectedFunding && participant) {
      getPlanCatagories({
        funding_id: selectedFunding.uuid,
        pt_id: participant.uuid,
      });
    }
    setPlanCatagoriesList(PlanCatagories);
  }, [PlanCatagories]);

  useEffect(() => {
    if (participant && participant.state_id && catalogStates) {
      const stateObj = catalogStates.find(
        (x) => x.value === participant.state_id
      );
      setCatalogState({ ...stateObj });
    }
  }, [participant, catalogStates]);

  useEffect(() => {
    if (!catalogStates) {
      getCatalogServicesSubCategory({ isService: false });
    }
  }, []);

  return (
    <div className='max-w-7xl mx-auto py-3 bg-white'>
      <div className='flex justify-between items-center px-6 bg-gray-25 border md:rounded-t-lg font-bold text-lg text-gray-900 py-4'>
        <div>Manual Delivery</div>
        <CustomButton
          label='Manual Service'
          clickHandler={() => setOpenManualModal(true)}
          Icon={<BiPlus />}
          cssClass='border-0'
        />
      </div>

      <div>
        <ManaulDeliveryTable
          fetchLoader={fetchManualDeliveriesLoader}
          funding={selectedFunding}
          participant={participant}
          manualDeliveries={manualDeliveries}
          getManaulDeliveriesList={getManaulDeliveriesList}
        />
      </div>
      {openManualModal && (
        <ManualDeliveredModal
          handleClose={() => setOpenManualModal(false)}
          funding={selectedFunding}
          participant={participant}
          showModal={openManualModal}
          PlanCatagories={PlanCatagoriesList}
          catalogState={catalogState}
          storeManualDeliveryLoader={storeManualDeliveryLoader}
          manualDeliveredCreate={manualDeliveredCreate}
          storeManualDeliveryError={storeManualDeliveryError}
        />
      )}
    </div>
  );
};

export default ManualDelivery;
