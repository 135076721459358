import React, { useEffect, useMemo, useState } from "react";
import { apiGetRequest, apiPostRequest } from "../../../../helpers/Requests";
import CircularLoader from "../../../common/CircularLoader/CircularLoader";
import {
  LOADER_STATUS,
  PARENT_FORM_CATEGORY_SLUG,
} from "../../../../Constants/constant";
import SelectOption from "../../../common/Select/Select";
import RiskRatingForm from "./RiskRatingForm";
import {
  capitalizeAllLetters,
  getUrlRole,
  parseBooleanVal,
} from "../../../../Utils/utils";

const RiskRatingTab = (props) => {
  const { participantsUid, isReadOnly = false } = props;

  const RISK_CATEGORY_UUID = PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.UUID;

  const [formUid, setFormUid] = useState("");
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [viewFormData, setViewFormData] = useState([]);
  const [formRequest, setFormRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);

  const fetchSubmitedForms = async () => {
    //first api to hit get all the field name inside dropdown
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiPostRequest(
        `${getUrlRole()}/participant/submitted-forms?c_id=${RISK_CATEGORY_UUID}&pt_id=${participantsUid}&page=1&per_page=100`
      );
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        setSelectedOptions(
          res?.data?.data?.submitted_forms?.data.map((x) => {
            const obj = {
              label: capitalizeAllLetters(
                `${x.title} ${x.version === 1 ? "" : ` - V${x.version}`}`
              ),
              value: x.uuid,
            };
            return obj;
          })
        );
      }
      setRequest({ ...request, isRequested: false });
    } catch (err) {
      setRequest({ ...request, isRequested: false });
      console.log(err);
    }
  };

  const fetchFormTempletes = async (uuid) => {
    //second api hit after someone selected from dropdown
    setFormRequest({ ...formRequest, isRequested: true });
    setRenderLoading(LOADER_STATUS.PENDING);
    try {
      let res = await apiGetRequest(
        `${getUrlRole()}/participant/submitted-forms-view?form_id=${uuid}`
      );
      if (res.status !== 200) {
        setFormRequest({ ...formRequest, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (res.status === 200) {
        setFormRequest({ ...formRequest, isRequested: false, isSuccess: true });
        setFormUid(res?.data?.submitted_form?.uuid);
        if (res?.data?.submitted_form?.template.length > 0) {
          const data = JSON.parse(res?.data?.submitted_form?.template);
          setViewFormData(data);
        }
      }
      setRequest({ ...request, isRequested: false });
      setRenderLoading(LOADER_STATUS.COMPLATE);
    } catch (err) {
      setRequest({ ...request, isRequested: false });
      setRenderLoading(LOADER_STATUS.COMPLATE);
      console.log(err);
    }
  };

  useEffect(() => {
    if (RISK_CATEGORY_UUID && participantsUid) {
      fetchSubmitedForms();
    }
  }, []);

  useEffect(() => {
    if (selectedForm && selectedForm.value) {
      fetchFormTempletes(selectedForm.value);
    }
  }, [selectedForm]);

  const viewFormDataToMap = useMemo(() => {
    let counter = 0;
    if (viewFormData && viewFormData.length > 0 && viewFormData !== null) {
      viewFormData.forEach((form, index) => {
        if (form && form?.input_fields && form?.input_fields.length > 0) {
          form?.input_fields.forEach((field, i) => {
            if (
              field.type === "sub_section" &&
              field?.sub_fields &&
              field?.sub_fields.length > 0
            ) {
              field?.sub_fields.forEach((item, sf) => {
                if (
                  parseBooleanVal(item.isRisk) &&
                  item.hasOwnProperty("riskPositive") &&
                  item.value === item.options[parseInt(item.riskPositive)].lable
                ) {
                  if (item.type === "radio" && parseBooleanVal(item.isRisk)) {
                    counter++;
                  }
                }
              });
            } else if (
              parseBooleanVal(field.isRisk) &&
              field.hasOwnProperty("riskPositive") &&
              field.value === field.options[parseInt(field.riskPositive)].lable
            ) {
              if (field.type === "radio" && parseBooleanVal(field.isRisk)) {
                counter++;
              }
            }
          });
        }
      });
    }
    return {
      counter,
      viewFormData: viewFormData && viewFormData.length > 0 ? viewFormData : [],
    };
  }, [viewFormData]);

  return (
    <div className="px-4">
      <div>
        <SelectOption
          isDisabled={request.isRequested}
          data={selectedOptions}
          isSearch={false}
          selectedOption={selectedForm}
          handleChange={(e) => {
            setSelectedForm(e);
          }}
          cssClass=""
        />
      </div>
      <div className="flex flex-col py-4">
        <div className="min-w-full align-middle">
          <div className="overflow-x-auto  overflow-hidden border md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-blue-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[40%]"
                  >
                    Question
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[10%]"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[20%]"
                  >
                    Likelihood
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[20%]"
                  >
                    Impact
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-[10%]"
                  >
                    Risk Rating  
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {!selectedForm &&
                !formRequest.isRequested &&
                !request.isRequested ? (
                  <tr>
                    <td
                      className="w-full text-center py-4 bg-white text-red-500"
                      colSpan="6"
                    >
                      Please select risk form!
                    </td>
                  </tr>
                ) : formRequest.isRequested ||
                  request.isRequested ||
                  renderLoading === LOADER_STATUS.PENDING ? (
                  <tr>
                    <td
                      className="w-full text-center py-4 bg-gray-50"
                      colSpan="6"
                    >
                      <CircularLoader
                        classes="flex justify-center items-center"
                        classloader="loader-l"
                      />
                    </td>
                  </tr>
                ) : viewFormDataToMap.counter > 0 ? (
                  viewFormDataToMap.viewFormData.map((section, index) => (
                    <RiskRatingForm
                      isReadOnly={isReadOnly}
                      key={index}
                      form={section}
                      index={index}
                      formUUID={formUid}
                      viewFormData={viewFormData}
                      handleChangeFormData={(e) => setViewFormData([...e])}
                    />
                  ))
                ) : (
                  <tr>
                    <td
                      className="w-full text-center py-4 bg-white text-red-500"
                      colSpan="6"
                    >
                      There is no Risk Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskRatingTab;
