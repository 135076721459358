import React, { useContext, useEffect, useState } from "react";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import TableHeader from "../../../../components/common/Table/TableHeader";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import Search from "../../../../components/common/Search/Search";
import { LoginContext } from "../../../../helpers/LoginContext";
import {
  emptyPaginationObj,
  POLICIES_LIST_LIMIT,
  SORT_TYPE,
} from "../../../../Constants/constant";
import {
  baseURLImage,
  getExtension,
  getPaginationObj,
} from "../../../../Utils/utils";
import {
  addFiltersAndPagination,
  apiGetRequest,
} from "../../../../helpers/Requests";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import {
  EMPLOYEE_BREADCRUMB,
  EMPLOYEE_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import PaginationComponent from "../../../../components/common/Pagination/index";
import { saveAs } from "file-saver";
import { HiOutlineEye, HiOutlineDownload } from "react-icons/hi";
import Header from "../../../../components/common/Header/Header";

export default function EmployeePolicies() {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const { sideBarPadding } = useContext(LoginContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );

  const getDocuments = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: getPaginationObj.page,
      });
    }
    Object.assign(filters, { per_page: POLICIES_LIST_LIMIT });
    try {
      setIsLoading(true);
      let res = await apiGetRequest(
        addFiltersAndPagination("hrm/get-policies", filters)
      );
      if (res.status === 200) {
        setDocuments(res?.data?.data?.employee_documents);
        setPaginationObj(
          getPaginationObj(
            res?.data?.data?.employee_documents,
            res?.data?.data?.query_params
          )
        );
      }
      if (res.status !== 200) {
        console.log("error in fetching");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err.message);
    }
  };

  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order)
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
  }, [paginationObj]);

  useEffect(() => {
    const obj = {};
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (!search) handleFetch({ ...obj });
  }, [paginationObj.page]);

  const handleFetch = (obj) => {
    if (paginationObj.page) Object.assign(obj, { page: paginationObj.page });
    if (paginationObj.filter && !obj.hasOwnProperty("status")) {
      Object.assign(obj, { filter: paginationObj.filter });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status],
        },
      });
    }
    getDocuments({ ...obj });
  };

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
        }),
      600
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col`}
        >
          <main className='flex-1'>
            <Header title='Policies' />
            <div className='max-w-7xl mx-auto flex flex-col'>
              <div className='px-6 border-gray-200 sticky top-20 py-4 pb-0 z-9 bg-white'>
                <Breadcrumb
                  pages={EMPLOYEE_BREADCRUMB(
                    EMPLOYEE_BREADCRUMB_PAGES.POLICIES
                  )}
                />
              </div>
              <div
                className='px-6 flex justify-between items-center sticky z-8 bg-white py-2'
                style={{ top: "115px" }}
              >
                <div className='w-1/3 md:w-2/12 lg:w-2/12'></div>
                <Search setSearch={setSearch} search={search} />
              </div>

              <div className='px-6 11inline-block min-w-full align-middle'>
                <div className='shadow ring-1 ring-black ring-opacity-5 rounded-md'>
                  <div className='table-wrp block'>
                    <table className='min-w-full divide-y divide-gray-300'>
                      {isLoading ? (
                        <div
                          className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                        >
                          <CircularLoader
                            classes='flex justify-center items-center'
                            classloader='loader-l'
                          />
                        </div>
                      ) : (
                        <>
                          <thead
                            className='bg-blue-50 sticky capitalize'
                            style={{ top: "168px" }}
                          >
                            <tr>
                              <th
                                scope='col'
                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-blue-50'
                              >
                                Policies
                              </th>
                              <th
                                scope='col'
                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-blue-50'
                              >
                                Category
                              </th>
                              <TableHeader
                                sortName='posted_time'
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                label={"Date Uploaded"}
                              />
                              <th
                                scope='col'
                                className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900 bg-blue-50'
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                            {documents &&
                              documents?.data?.map((document, index) => (
                                <tr
                                  key={index}
                                  className='even:bg-white odd:bg-gray-50'
                                >
                                  <td className='whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-2'>
                                    {document.name}
                                  </td>
                                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {document.category_name}
                                  </td>
                                  <td className='whitespace-nowrap px-3 pl-6 py-4 text-sm text-gray-500'>
                                    {document.posted_time}
                                  </td>
                                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                    {document.link && (
                                      <div className='gap-x-3 flex items-center justify-center'>
                                        <a
                                          href={`${baseURLImage(
                                            document.link
                                          )}`}
                                          without
                                          rel='noopener noreferrer'
                                          target='_blank'
                                          className='icon-eye-wrapper'
                                        >
                                          <HiOutlineEye className='icon-size' />
                                        </a>
                                        <p
                                          onClick={() =>
                                            saveAs(
                                              `${baseURLImage(document.link)}`,
                                              `${
                                                document.name
                                                  ? document.name
                                                  : getExtension(document.link)
                                              }.`
                                            )
                                          }
                                          className='cursor-pointer icon-download-wrapper'
                                        >
                                          <HiOutlineDownload className='icon-size' />
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            {documents?.data?.length === 0 ? (
                              <tr>
                                <td
                                  colSpan={7}
                                  className='py-6 text-center text-red-600'
                                >
                                  No data found !
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </>
                      )}
                    </table>
                  </div>
                </div>
              </div>

              <div className='px-6 bg-white mt-5'>
                <PaginationComponent
                  page={paginationObj.page}
                  activeListLength={documents?.data}
                  limit={POLICIES_LIST_LIMIT}
                  totalList={paginationObj?.total}
                  handlePagination={(e) => {
                    setPage(e);
                    const obj = paginationObj;
                    obj.page = e;
                    setPaginationObj({ ...obj });
                  }}
                  totalPages={paginationObj?.totalPages}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
