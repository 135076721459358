import React from "react";
import NumberFormatter from "../NumberFormatter/NumberFormatter";

const StatsCard = (props) => {
  const { item, index } = props;
  const { name, color, stat, isAmount } = item;

  return (
    <div
      key={index}
      style={{ borderLeft: `4px solid ${color}` }}
      className="grid-span-1 overflow-hidden rounded-lg py-5 pl-4 bg-white border transition ease-in-out duration-150 hover:shadow-lg"
    >
      <dt className="truncate text-sm font-medium text-gray-500">{name}</dt>
      <dd
        title={stat}
        className="mt-1 text-[22px] font-semibold tracking-tight text-gray-900"
      >
        {isAmount ? (
          <NumberFormatter
            cssClass="mt-1 text-[22px] font-semibold tracking-tight text-gray-900"
            prefix={"$"}
            value={stat}
          />
        ) : (
          stat
        )}
      </dd>
    </div>
  );
};
export default StatsCard;
