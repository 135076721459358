import React from "react";
import CheckBox from "components/common/checkBox/checkbox";
import moment from "moment";
import { capitalizeFirstLetter, formatDateIntoDMYToDisplay } from "Utils/utils";
import AvatarGroup from "components/common/Avatar/AvatarGroup";

const ExportRosterShiftItem = ({
  shift,
  handleCheckboxChange,
  checkboxes,
  calendarView,
}) => {
  const renderParticipantNames = () => {
    if (calendarView === "worker") {
      return shift.participants.map((participant, index) => (
        <div key={index}>
          {participant.first_name} {participant.last_name}
        </div>
      ));
    } else {
      if (shift.participants.length > 0) {
        return (
          <AvatarGroup
            type='worker'
            arrayData={shift?.participants || []}
            cssClass='mt-0'
            maxLength={10}
          />
        );
      } else {
        return shift?.participant_name;
      }
    }
  };

  return (
    <tr className='text-center even:bg-white odd:bg-gray-50'>
      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          <CheckBox
            handleChange={handleCheckboxChange}
            checked={
              checkboxes.find((x) => x.uuid === shift.uuid) ? true : false
            }
          />
        </div>
      </td>
      <td className='text-start px-3 py-4 text-sm text-gray-500'>
        <div className='capitalize text-gray-500'>{shift?.worker_name}</div>
      </td>
      <td className='text-start px-3 py-4 text-sm text-gray-500'>
        <div className='capitalize text-gray-500'>
          {renderParticipantNames()}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {moment(shift.shift_date).format("DD/MM/YYYY")}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {capitalizeFirstLetter(shift?.status)}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift.shift_length && shift.shift_length.length > 4
            ? shift?.shift_length?.slice(0, 5)?.split(":").length >= 1 &&
              `${shift?.shift_length?.slice(0, 5)?.split(":")[0]}h ${
                shift?.shift_length?.slice(0, 5)?.split(":")[1]
              }m`
            : shift.shift_length}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {moment(shift.start_time, "YYYY-MM-DD HH:mm:ss").format("hh:mm a")}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {moment(shift.end_time, "YYYY-MM-DD HH:mm:ss").format("hh:mm a")}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift?.worker_start_time
            ? moment(shift?.worker_start_time, "YYYY-MM-DD HH:mm:ss").format(
                "hh:mm a"
              )
            : "N/A"}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {shift?.worker_end_time
            ? moment(shift?.worker_end_time, "YYYY-MM-DD HH:mm:ss").format(
                "hh:mm a"
              )
            : "N/A"}
        </div>
      </td>
    </tr>
  );
};

export default ExportRosterShiftItem;
