import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
// import Style from 'components/ProfileMenu/Settings/Setting.module.css';
// import Input from 'components/controls/input/dashboardInput/dashboardInput';
// import visaImage from 'assets/images/visa-card.png';
// import masterCardImage from 'assets/images/masterCard.png';
import { useDispatch, useSelector } from "react-redux";
import InputField from "components/common/Input/Input";
import CustomButton from "components/common/Button/CustomButton";
import { stripeSaveCardDataRequest } from "store/actions/sp.actions";
import CheckBox from "components/common/checkBox/checkbox";
import SwitchInput from "components/common/Input/SwitchInput";
import { getPaymentSettings } from "store/selector/auth.selector";
import {
  getStripeSaveCardDataError,
  getStripeSaveCardDataLoader,
} from "store/selector/sp.selector";
import { LOADER_STATUS } from "store/constants";
// import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';

const CheckoutForm = (props) => {
  const { handleClose } = props;
  const [name, setName] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [isDefaultCard, setIsDefaultCard] = useState(false);

  const [message, setMessage] = useState("");
  const [disable, setDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [saveCardLoader, setSaveCardLoader] = useState(LOADER_STATUS.NA);

  const paymentSettings = useSelector(getPaymentSettings);
  const stripeSaveCardDataError = useSelector(getStripeSaveCardDataError);
  const stripeSaveCardDataLoader = useSelector(getStripeSaveCardDataLoader);

  useEffect(() => {
    if (
      saveCardLoader === LOADER_STATUS.PENDING &&
      stripeSaveCardDataError?.length > 0
    ) {
      setMessage(stripeSaveCardDataError);
    } else if (
      saveCardLoader === LOADER_STATUS.PENDING &&
      stripeSaveCardDataError?.length === 0 &&
      stripeSaveCardDataLoader === false
    ) {
      setMessage("");
      handleClose();
    }
  }, [stripeSaveCardDataError, stripeSaveCardDataLoader]);

  useEffect(() => {
    if (
      paymentSettings === null ||
      paymentSettings?.default_payment_method_id === null
    ) {
      setIsDefaultCard(true);
    }
  }, []);

  // console.log("paymentSettings", paymentSettings);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || disable) {
      return;
    }
    if (!name) {
      setMessage("Enter card holder name");
      return;
    }

    if (!isAgree || isAgree === false) {
      setMessage("Please review and agree to our Terms and Conditions");
      return;
    }

    setMessage("");
    setDisabled(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement) || { token: "123" },
    });
    setDisabled(false);
    if (error) {
      setMessage(error.message || "Incomplete card data");
      console.log("[error]", error.message);
    } else if (paymentMethod) {
      setMessage("");
      console.log("[PaymentMethod]", paymentMethod);
      setSaveCardLoader(LOADER_STATUS.PENDING);
      dispatch(
        stripeSaveCardDataRequest(
          {
            payment_method_id: paymentMethod.id,
            card_holder_name: name,
            is_default: isDefaultCard,
          },
          {
            id: paymentMethod.id,
            card: paymentMethod?.card || null,
            metadata: { card_name: name },
          }
        )
      );
    }
  };

  useEffect(() => {
    if (
      isAgree &&
      message === "Please review and agree to our Terms and Conditions"
    ) {
      setMessage("");
    }
  }, [isAgree]);

  return (
    <form className='w-full' onSubmit={handleSubmit} autoComplete='off'>
      {/* <div className='flex items-center justify-between'>
        <p
        className={`mb-0 ${Style.settingHeading}`}
        >
          Card Details
        </p>
        <div
        className={Style.billingMethodImages}
        >
          <img src={visaImage} alt='Visa Card' />
          <img src={masterCardImage} alt='Master Card' />
        </div>
      </div> */}
      <div className='grid py-1'>
        <div className='grid-cols-12'>
          <InputField
            placeHolder='Card Holder Name'
            type='text'
            // cssClass={Style.input}
            // inputLabel={Style.settingInputLabel}
            label='Card Holder Name'
            handleChange={setName}
            autoComplete='nope'
          />
        </div>
      </div>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "14px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
              display: "none",
            },
            invalid: {
              color: "#9e2146",
            },
          },
          hidePostalCode: true,
        }}
      />

      {paymentSettings !== null &&
        paymentSettings?.default_payment_method_id !== null && (
          <div
            className={
              "pt-2 pb-4 flex justify-content-start text-start items-center mt-2 space-x-2 text-sm"
            }
          >
            <SwitchInput
              isDisabled={
                paymentSettings === null ||
                paymentSettings?.default_payment_method_id === null
              }
              handleChange={setIsDefaultCard}
              checked={isDefaultCard}
              label='Default Card'
              // subLabel='make it default card'
            />
          </div>
        )}

      <div
        className={
          "ml-1 py-2 flex justify-content-start text-start items-center mt-2 space-x-2 text-sm"
        }
      >
        <span className='flex'>
          <CheckBox
            checked={isAgree}
            handleChange={setIsAgree}
            cssClass='rounded accent-blue-dark'
          />
        </span>
        <span className='text-gray-500'>
          I have read and agree to the
          <a
            href='https://vertex360.io/terms-and-conditions'
            target='_blank'
            className='text-indigo-400 hover:underline cursor-pointer ml-1'
            rel='noreferrer'
          >
            {`Terms and Service `}
          </a>
          &
          <a
            href='https://vertex360.io/terms-and-conditions'
            target='_blank'
            className='text-indigo-400 hover:underline cursor-pointer ml-1'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
        </span>
      </div>
      <div className='text-gray-500 py-2 text-start text-sm w-[95%]'>
        <span className='font-bold text-black'>Note:</span>
        {` We use Stripe for
        processing payments securely. Your card information is directly
        transmitted to Stripe and is not stored on our servers. Your security is
        our top priority.`}
      </div>
      {message && <h5 className={"mt-2 errorMessage"}>{message}</h5>}
      <div className='flex justify-end text-center pt-4 w-full'>
        <CustomButton
          cssClass={`border-0`}
          isDisabled={!stripe || disable || stripeSaveCardDataLoader}
          label='Save Card'
          type='submit'
          showLoading={disable || stripeSaveCardDataLoader}
          //   clickHandler={() => {}}
        />
      </div>
    </form>
  );
};

export default CheckoutForm;
