import React from "react";
import { Link } from "react-router-dom";
import { BsCheck2Circle } from "react-icons/bs";

const ConfirmedPassword = () => {
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <div className="flex justify-center">
            <div className="rounded-full bg-layer/40 h-20 w-20 flex items-center justify-center">
              <div className="h-14 w-14 rounded-full bg-blue-200/60 flex items-center justify-center">
                <BsCheck2Circle className="h-8 w-8 text-blue-dark" />
              </div>
            </div>
          </div>
          <h2 className="mt-6 text-center text-xl sm:text-3xl font-semibold text-gray-900">
            Password reset
          </h2>
          <p className="mt-2 text-center text-md text-gray-500 font-normal">
            Your password has been successfully reset. Click below to log in
            magically.
          </p>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <div className="space-y-4">
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
            >
              Continue
            </button>

            <Link
              to="/setpassword"
              className="w-full flex justify-center items-center space-x-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-500"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 17l-5-5m0 0l5-5m-5 5h12"
                  />
                </svg>
              </span>
              <span>Back to log in</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmedPassword;
