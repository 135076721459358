import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { apiGetRequest, apiPostRequest } from "../../helpers/Requests";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { setRefreshToken, setToken } from "../../helpers/Tokens";
import CircularLoader from "../../components/common/CircularLoader/CircularLoader";
import LoginScreenSection from "../../components/common/LoginScreenSection/LoginScreenSection";
import PasswordValidationBox from "components/common/PasswordValidationBox/PasswordValidationBox";
import { EMAIL_REGEX_EXP } from "Constants/constant";

const TeamMemberSetup = (props) => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPassword, setconfirmPassword] = useState("password");
  const showPasswordHandler = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const showConfirmPasswordHandler = () => {
    confirmPassword === "password"
      ? setconfirmPassword("text")
      : setconfirmPassword("password");
  };
  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = React.useState(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: userInfo,
  });
  const watchFormData = watch();

  const fetchUserInfo = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiGetRequest(`get-member-invitation-link-info?id=${id}`);
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        throw new Error(res.data.error);
      }
      if (res.status === 200) {
        setUserInfo(res.data.data);
        reset(res.data.data);
        setRequest({ ...request, isRequested: false, isSuccess: true });
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message);
    }
  };

  React.useEffect(() => {
    fetchUserInfo();
  }, []);

  const dataSubmitHandler = async (data) => {
    data.link_id = userInfo.link_id;
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiPostRequest("member-account-setup", data);
      if (!res) {
        throw new Error("Network Error");
      }
      console.log(res.data);
      if (res.status !== 201) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        throw new Error(res.data.error);
      }
      if (res.status === 201) {
        localStorage.setItem("userType", res.data.user.userType);
        setRefreshToken(res.data.user.RefreshToken);
        setToken(res.data.user.AccessToken);
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        const refreshTokenDate = new Date(new Date().getTime() + 3600 * 1000);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("refreshTokenDate", refreshTokenDate);
        setRequest({ ...request, isRequested: false, isSuccess: true });
        navigate("/dashboard");
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    dataSubmitHandler(data);
    // navigate("/sp-setup");
  };

  // Password Validations
  const [pass, setPass] = useState("");
  const [confrimPassword, setConfirmPassword] = useState("");

  // When the user clicks on the password field, show the message box
  const passwordFocus = () => {
    document.getElementById("message").style.display = "block";
  };

  const passwordBluer = () => {
    document.getElementById("message").style.display = "none";
  };
  const CpasswordHanlder = (value) => {
    setConfirmPassword(value);
  };

  const passwordChangeHandler = (value) => {
    setPass(value);
  };

  return (
    <div className="flex min-h-screen">
      {request.isRequested && (
        <div className="absolute top-1/2 -translate-y-1/2 z-50 bg-blue-dark bg-opacity-20  flex  w-full flex-1  flex-col justify-center py-12 px-4 sm:px-6 lg:flex lg:justify-center lg:px-20 lg:w-1/2 xl:px-24 order-1 h-full">
          <CircularLoader />
        </div>
      )}
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex lg:justify-center lg:px-20 lg:w-1/2 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-6 text-3xl font-semibold text-gray-900">
              Welcome to Vertex 360
            </h2>
            <p className="text-sm text-gray-400 pl-1">
              please provide the details to set up your account
            </p>
          </div>

          <div className="mt-8">
            <form onSubmit={handleSubmit(onSubmit)} className="">
              <div className="mt-1 did-floating-label-content">
                <input
                  {...register("name", {
                    required: true,
                    maxLength: 254,
                  })}
                  className="did-floating-input"
                  type="text"
                />
                <label className="did-floating-label">Name</label>
                {errors?.name?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>
              <div className="mt-1 did-floating-label-content">
                <input
                  {...register("phone", {
                    required: true,
                    maxLength: 11,
                    minLength: 11,
                    // pattern:
                    //   /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
                  })}
                  className="did-floating-input"
                  type="text"
                />
                <label className="did-floating-label">Phone No.</label>
                {errors?.phone?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
                {errors?.phone?.type === "maxLength" && (
                  <span className="text-sm text-red-500">
                    Please enter a valid phone number!
                  </span>
                )}
                {errors?.phone?.type === "minLength" && (
                  <span className="text-sm text-red-500">
                    Please enter a valid phone number!
                  </span>
                )}
              </div>
              <div className="mt-1 did-floating-label-content">
                <select
                  className="did-floating-select"
                  {...register("user_type", {
                    required: true,
                  })}
                >
                  <option value="sp_manager">Manager</option>
                </select>
                <label className="did-floating-label">User Type</label>
                {errors?.state?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>
              <div className="mt-1 did-floating-label-content">
                <input
                  {...register("email", {
                    required: true,
                    pattern: EMAIL_REGEX_EXP,
                  })}
                  className="did-floating-input"
                  type="email"
                />
                <label className="did-floating-label">Email address</label>
                {errors?.email?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
                {errors?.email?.type === "pattern" && (
                  <span className="text-sm text-red-500">
                    Please enter a valid email address!
                  </span>
                )}
              </div>
              <div className="space-y-4">
                <div>
                  <div className="did-floating-label-content relative">
                    <input
                      {...register("password", {
                        required: true,
                        onChange: (e) => {
                          passwordChangeHandler(e.target.value);
                        },
                      })}
                      onFocus={passwordFocus}
                      onBlur={passwordBluer}
                      id="password"
                      type={passwordType}
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                      autoComplete="password"
                      className="did-floating-input"
                    />
                    <label
                      htmlFor="email-address"
                      className="did-floating-label"
                    >
                      Password
                    </label>
                    <span
                      onClick={showPasswordHandler}
                      className="pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark"
                    >
                      {passwordType === "password" ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </span>
                  </div>
                  {errors?.password?.type === "required" && (
                    <span className="text-sm text-red-500">
                      This field is required!
                    </span>
                  )}
                  <div id="message" style={{ display: "none" }}>
                    <PasswordValidationBox password={watchFormData?.password} />
                  </div>
                </div>
                <div className="">
                  <div className="did-floating-label-content relative">
                    <input
                      {...register("password_confirmation", {
                        required: true,
                        onChange: (e) => {
                          CpasswordHanlder(e.target.value);
                        },
                      })}
                      id="confirm_password"
                      type={confirmPassword}
                      autoComplete="password"
                      className="did-floating-input"
                    />
                    <label
                      htmlFor="email-address"
                      className="did-floating-label"
                    >
                      Confirm Password
                    </label>
                    <span
                      onClick={showConfirmPasswordHandler}
                      className="pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark"
                    >
                      {confirmPassword === "password" ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </span>
                  </div>
                  {errors?.password_confirmation?.type === "required" && (
                    <div className="-mt-4 mb-4 text-sm text-red-500">
                      This field is required!
                    </div>
                  )}
                  {pass && confrimPassword && pass === confrimPassword ? (
                    <div className="-mt-4 mb-4 text-green-500">
                      password matched!
                    </div>
                  ) : pass && confrimPassword && pass !== confrimPassword ? (
                    <div className="-mt-4 mb-4 text-red-500">
                      password not matched!
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <button
                  disabled={request.isRequested}
                  type="submit"
                  className="
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      bg-blue-dark
                      hover:bg-blue-dark
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-dark
                    "
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        ltr={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <LoginScreenSection
        heading="Thank you for setting up your account!"
        name="Imran Bhai"
        desc="CEO Vertex@360"
      />
    </div>
  );
};

export default TeamMemberSetup;
