import Pusher from "pusher-js";
import { BASE_URL, ENVIRONMENT_URL } from "store/services/URL";
 
const QA_PUSHER_KEY = "081e6e07fc4df8797dfd";
 
const UAT_PUSHER_KEY = "957cce8f93822d9a9efe";
 
const PRODUCTION_PUSHER_KEY = "76c4f6943ea6fe70f8a5";
 
const pusher = new Pusher(
  BASE_URL === ENVIRONMENT_URL.PRODUCTION_BASE_URL
    ? PRODUCTION_PUSHER_KEY
    : BASE_URL === ENVIRONMENT_URL.UAT_BASE_URL
    ? UAT_PUSHER_KEY
    : QA_PUSHER_KEY,
  {
    cluster: "ap4",
  }
);
 
export default pusher;
