import React, { useEffect, useState } from "react";
import { STRIPE_DASHBOARD_TABS } from "Constants/constant";
import StripeTabItem from "./StripeTabItem";
import PaymentMethod from "../Payment";
import StripeInvoices from "../Invoices/Invoices";
import "./StripeTabs.css";
import SubscriptionDetails from "../Subscription/SubscriptionDetails";
import { stripeDefaultPaymentMethod } from "store/actions/sp.actions";
import { useDispatch } from "react-redux";

const StripeTabs = ({ tabQuery }) => {
  const [tabs] = useState(STRIPE_DASHBOARD_TABS);
  const [toggleState, setToggleState] = useState(tabs[0]);

  const dispatch = useDispatch();

  const toggleTab = (tab) => {
    setToggleState(tab);
  };

  useEffect(() => {
    dispatch(stripeDefaultPaymentMethod());
  }, []);

  useEffect(() => {
    if (tabQuery && tabQuery === "card") {
      setToggleState(tabs[1]);
    }
  }, [tabQuery]);

  const loadTabComponent = () => {
    switch (toggleState.id) {
      case 1:
        return <PaymentMethod />;
      case 2:
        return <StripeInvoices />;
      case 3:
        return <SubscriptionDetails />;
      default:
        return <></>;
    }
  };

  return (
    <div className='bg-white shadow-lg rounded-lg'>
      <div className='print:hidden sticky z-[8] top-[79px]'>
        <div className='bloc-tabs bg-gradient py-4 rounded-t-lg text-white '>
          {tabs.map((tab, index) => (
            <StripeTabItem
              tabItem={tab}
              key={index}
              toggleTab={toggleTab}
              selectedTab={toggleState.id}
            />
          ))}
        </div>
      </div>
      <div className='content-tabs'>
        <div className={"content active-content"}>{loadTabComponent()}</div>
      </div>
    </div>
  );
};

export default StripeTabs;
