import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const CreateCaseNotesKM = (props) => {
  const {
    setDeleteUUID,
    deleteClientTask,
    deleteUUID,
    setConfirmation,
    createCaseNotesKmModel,
    closeModal,
  } = props;

  return (
    <Transition.Root show={createCaseNotesKmModel} as={Fragment}>
      <Dialog
        as="div"
        onClose={closeModal}
        className="fixed inset-0 z-20 top-50 overflow-y-auto"
        initialFoc
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <main className="antialiased bg-gray-200 text-gray-900 font-sans overflow-x-hidden">
          <div className="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
            <div className="bg-black opacity-25 w-full h-full absolute z-10 inset-0"></div>
            <div className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
              <div className="md:flex items-center">
                <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                  <i className="bx bx-error text-3xl"></i>
                </div>
                <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                  <p className="font-bold">Delete This Record</p>
                  <p className="text-sm text-gray-700 mt-1">Are You Sure</p>
                </div>
              </div>
              <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                <button
                  onClick={() => {
                    deleteClientTask({ uuid: deleteUUID });
                    setConfirmation(false);
                  }}
                  className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2"
                >
                  Delete
                </button>
                <button
                  onClick={() => {
                    setDeleteUUID(null);
                    setConfirmation(false);
                  }}
                  className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
          md:mt-0 md:order-1"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </main>
      </Dialog>
    </Transition.Root>
  );
};
export default CreateCaseNotesKM;
