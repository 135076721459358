import React, { useContext, useState } from "react";
import { BiPlus } from "react-icons/bi";
import Header from "../../../components/common/Header/Header";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import { LoginContext } from "../../../helpers/LoginContext";
import AddServiceProviderModal from "./AddserviceProviderModal";
import ServiceProvidersTable from "./ServiceProvidersTable";

const ServiceProviderList = () => {
  const { sideBarPadding } = useContext(LoginContext);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="min-h-full">
      <SideBar />
      <div
        className={`${
          sideBarPadding
            ? "lg:pl-[4rem] duration-300 flex flex-col"
            : "lg:pl-64 duration-300 flex flex-col"
        }`}
      >
        <main className="flex-1">
          <Header
            handleClick={() => setOpenModal(true)}
            btnLabel="Service Provider"
            icon={<BiPlus />}
            isButton
            title="Service Providers"
          />
          {<ServiceProvidersTable />}
        </main>
      </div>
      {openModal && (
        <AddServiceProviderModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default ServiceProviderList;
