import React from "react";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import PermissionItems from "./permissionItems";
// import { roleAccess } from "helpers/RolesPermission";
// import { USER_ROLE, portalTypeOptions } from "Constants/constant";

const PermissionTable = (props) => {
  const {
    isLoading,
    rolesPermissions,
    handleRowClick,
    expandedRows,
    handleSwitchChange,
    portalType,
  } = props;

  return (
    <div className='flex flex-col'>
      <div className='min-w-full'>
        <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <div className='table-wrp block'>
            {isLoading ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <table className='min-w-full  divide-y divide-gray-300  capitalize'>
                {portalType && portalType !== null && (
                  <thead
                    style={{ top: "130px" }}
                    className='bg-gray-50 z-[5] capitalize'
                  >
                    <tr>
                      <TableHeader
                        isSort={false}
                        label={""}
                        cssClass='sm:pl-3 w-[40%] border'
                      />
                      <TableHeader
                        cssClass='sm:pl-3 text-center border'
                        isSort={false}
                        label={"Permission"}
                      />
                    </tr>
                  </thead>
                )}
                <tbody className='divide-y divide-gray-200  bg-white overflow-y-auto'>
                  {!portalType || portalType === null ? (
                    <tr>
                      <td
                        colSpan={2}
                        className='py-6 text-center text-green-600'
                      >
                        Select Portal Type!
                      </td>
                    </tr>
                  ) : rolesPermissions && rolesPermissions?.length > 0 ? (
                    rolesPermissions
                      // .filter(
                      //   (x) =>
                      //     !(
                      //       x.name === "cos" &&
                      //       x.parent_id === null &&
                      //       portalType === portalTypeOptions[1].value
                      //     )
                      // )
                      .filter(
                        (x) =>
                          x.name !== "participant.dashboard" &&
                          x.parent_id === null
                      )
                      .map((item, index) => (
                        <PermissionItems
                          key={index}
                          index={index}
                          handleRowClick={handleRowClick}
                          expandedRows={expandedRows}
                          item={item}
                          handleSwitchChange={handleSwitchChange}
                        />
                      ))
                  ) : rolesPermissions.length === 0 ? (
                    <tr>
                      <td colSpan={2} className='py-6 text-center text-red-600'>
                        No Permission Found!
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionTable;
