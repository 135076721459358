import React, { Fragment, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { baseURLImage, getNameProfile, selectedTabState } from "Utils/utils";
import { useNavigate } from "react-router-dom";
// import { PARTICIPANT_PROFILE_TABS } from "../../../Constants/constant";
// import { apiGetRequest } from "../../../helpers/Requests";
// import { useEffect } from "react";
import QuickSnapshots from "components/common/QuickSnapshots/QuickSnapshots";
import moment from "moment";
import CustomButton from "components/common/Button/CustomButton";
import { workerProfileDetailsFetchRequest } from "store/actions/sp.actions";
import { connect } from "react-redux";
import { getSelectedWorker } from "store/selector/sp.selector";

const QuickView = (props) => {
  const {
    selectedParticipant,
    participant,
    isOpen,
    setIsOpen,
    paddingLeft,
    WorkerProfileDataRequest,
    selectedWorker,
  } = props;
  const navigate = useNavigate();

  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const fetchWorkerList = async () => {
    if (selectedParticipant.uuid) {
      WorkerProfileDataRequest({ profile_id: selectedParticipant.uuid });
    }
  };

  React.useEffect(() => {
    fetchWorkerList();
  }, [selectedParticipant]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };



  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50" onClose={setIsOpen}>
        <div className="min-h-screen absolute top-0 right-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-blue-dark/30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              style={{ float: "right", maxWidth: "500px", width: "500px" }}
              className="min-h-screen inline-block py-2 px-6 text-left align-middle transition-all transform bg-white shadow-xl border border-gray-100 min-h-screen max-h-screen overflow-auto custom-scroll"
            >
              {request.isRequested ? (
                <div
                  className="min-h-screen flex justify-center items-center"
                  style={{ minWidth: "25rem", overflow: "hidden !important" }}
                >
                  <CircularLoader
                    classes="flex justify-center items-center"
                    classloader="loader-l"
                  />
                </div>
              ) : (
                <>
                  <div className="flex justify-between items-center">
                    <span className="font-bold capitalize">Quick View</span>
                    <button onClick={setIsOpen}>
                      <AiOutlineClose className="h-5 w-5 hover:text-gray-400" />
                    </button>
                  </div>
                  <div className="mt-6 mb-8 flex items-start space-x-4">
                    <div className="h-16 w-16 rounded-full">
                      <img
                        onError={(e) =>
                          handleImageError(
                            e,
                            selectedParticipant.first_name,
                            selectedParticipant.last_name
                          )
                        }
                        className="object-cover rounded-full border-2-white h-16 w-16"
                        src={
                          selectedParticipant.photo
                            ? baseURLImage(selectedParticipant.photo)
                            : getNameProfile(
                                selectedParticipant.first_name,
                                selectedParticipant.last_name || " "
                              )
                        }
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="text-gray-900 text-lg font-medium capitalize">
                        {`${selectedParticipant.first_name} ${selectedParticipant.last_name}`}
                      </p>
                      <p className="text-gray-500">
                        {selectedParticipant.email}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 flex space-x-4">
                    {/* <CustomButton
                      variant="secondary"
                      clickHandler={handleCaseNotes}
                      label="Add Case Notes"
                    /> */}
                    <CustomButton
                      clickHandler={() =>
                        navigate(`/worker-profile/${selectedParticipant?.uuid}`)
                      }
                      label="View profile"
                    />
                  </div>
                  <div className="mt-1">
                    <QuickSnapshots
                      isQuickViewCall={true}
                      cssClass="mt-0"
                      paddingLeft={paddingLeft}
                      participantsInfo={selectedParticipant}
                      settingsData={selectedWorker}
                      worker={true}
                    />
                  </div>
                  <div>
                    <h2 className="text-lg leadin-6 font-medium text-gray-900 font-bold">
                      Participant List
                    </h2>
                    <ul className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                      {selectedWorker &&
                        selectedWorker.participants &&
                        selectedWorker.participants.length > 0 &&
                        selectedWorker.participants.map((casenote) => (
                          <li className="grid  items-center justify-between py-3">
                            <div className="flex justify-center items-center gap-3">
                            <div className="h-8 w-8 rounded-full">
                              <img
                                onError={(e) =>
                                  handleImageError(
                                    e,
                                    casenote.first_name,
                                    casenote.last_name
                                  )
                                }
                                className="object-cover rounded-full border-2-white h-8 w-8"
                                src={
                                  casenote.profile_picture
                                    ? baseURLImage(casenote.profile_picture)
                                    : getNameProfile(
                                        casenote.first_name,
                                        casenote.last_name || " "
                                      )
                                }
                                alt=""
                              />
                            </div>
                            <div>
                            <div className="flex items-center">
                              <p className="text-medium font-bold text-gray-900">
                                {casenote?.first_name} {casenote?.last_name}
                              </p>
                            </div>
                            <div className="">
                              <p className="text-gray-500 text-sm font-sm hover:text-gray-600">
                                {casenote?.email}
                              </p>
                            </div>
                            </div>
                            </div>
                          </li>
                        ))}
                      {selectedWorker &&
                        selectedWorker.participants &&
                        selectedWorker.participants.length <= 0 && (
                          <h2 className="text-center p-5 text-gray-500">No participant available</h2>
                        )}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const mapDispatchToProps = {
  WorkerProfileDataRequest: workerProfileDetailsFetchRequest,
};

const mapStateToProps = (state) => {
  const selectedWorker = getSelectedWorker(state);
  return {
    selectedWorker,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(QuickView);

// export default QuickView;
