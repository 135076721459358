import React from "react";
import { BsFillCircleFill } from "react-icons/bs";
import { USER_ROLE } from "../../../Constants/constant";
import CircularLoader from "../CircularLoader/CircularLoader";
import CustomButton from "../Button/CustomButton";
import { roleAccess } from "../../../helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const LikesDislikes = (props) => {
  const {
    isLoading,
    supportPlanData,
    setToggleState,
    viewInitialAssesmentForm,
    addInitialAssesmentForm,
  } = props;

  const getLikes = () => {
    let likes = [];
    let dislikes = [];
    let goals = [];
    if (supportPlanData) {
      const getData = supportPlanData.support_plan_data.find(
        (x) => x.lable === "Interests"
      );
      if (getData && getData.rows && getData.rows.length > 0) {
        getData.rows.forEach((x) => {
          if (x && x.length > 0) {
            x.forEach((y) => {
              if (y.lable === "Likes" && y.value) likes.push(y);
              if (y.lable === "Dislikes" && y.value) dislikes.push(y);
              if (y.lable === "Goals" && y.value) goals.push(y);
            });
          }
        });
      }
    }
    return { likes, dislikes, goals };
  };

  // const handleTabs = () => {
  //   setToggleState({
  //     name: "Participant Assessment",
  //     id: 6,
  //     children: [],
  //     isOpen: "supportPlan",
  //   });
  // };

  const getMessageText = (type) => (
    <>
      {roleAccess([USER_ROLE.Sp_Standard_User]) ? (
        <div className='col-span-2'>
          <dt className='text-sm text-red-500 font-semibold text-gray-900 border-b pb-3 flex justify-center items-center space-x-3'>
            <span className='text-red-500 print:text-red-500'>
              No Data found!
            </span>
          </dt>
        </div>
      ) : (
        <div
          onClick={() => {
            if (
              roleAccess(
                [],
                PERMISSION_KEYS.PARTICIPANT,
                PERMISSION_KEYS_CHILDREN.PARTICIPANT.SHOW
              )
            ) {
              supportPlanData !== null
                ? viewInitialAssesmentForm(supportPlanData.form_data_uuid)
                : addInitialAssesmentForm();
            }
          }}
          tabIndex={0}
          role='button'
          className='col-span-2'
        >
          <dt className='flex justify-end items-center'>
            <CustomButton
              cssClass='print:text-black'
              label={`Add ${type}`}
              variant='primary'
            />
          </dt>
        </div>
      )}
    </>
  );

  return (
    <div className='min-h-full'>
      <div className='mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3'>
        <div className='space-y-6 lg:col-start-1 lg:col-span-3 '>
          {/* Description list*/}
          <section aria-labelledby='applicant-information-title'>
            <div className='bg-light-purple-gradient shadow sm:rounded-lg custom-box-shadow h-[400px] overflow-y-auto print:h-full'>
              <div className='px-4 py-3 sm:px-6'>
                <h2
                  id='applicant-information-title'
                  className='text-lg leading-6 font-bold text-white print:text-black'
                >
                  Goals, Likes & Dislikes
                </h2>
                <p className='mt-1 max-w-5xl text-sm text-white print:text-black'>
                  Some measurable goals for the participant.
                </p>
              </div>
              {isLoading ? (
                <CircularLoader
                  classes='h-1/2 flex justify-center items-center'
                  classloader='loader-l'
                />
              ) : (
                <div className='px-4 pb-4 sm:px-6'>
                  <dl className='grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2 bg-white py-2 px-4 rounded-2xl'>
                    <div className='col-span-2'>
                      <dt className='text-sm font-medium text-gray-900 border-b pb-3 print:text-black'>
                        Goals
                      </dt>
                    </div>
                    {getLikes().goals && getLikes().goals.length > 0 ? (
                      getLikes()
                        .goals.filter((x) => x.value && x.value.length > 0)
                        .map((goal, index) => (
                          <div key={index} className='col-span-2'>
                            <dt className='text-sm font-semibold text-gray-900 border-b pb-3 flex items-center space-x-3'>
                              <span>
                                <BsFillCircleFill className='h-2 w-2 text-[#4B6CB7]' />
                              </span>
                              <span className='break-all print:text-black'>
                                {goal.value}
                              </span>
                            </dt>
                          </div>
                        ))
                    ) : (
                      <>{getMessageText("Goals")}</>
                    )}
                  </dl>

                  <dl className='mt-2 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2 bg-white py-2 px-4 rounded-2xl'>
                    <div className='col-span-2'>
                      <dt className='text-sm font-medium text-gray-900 border-b pb-3 print:text-black'>
                        Likes
                      </dt>
                    </div>
                    {getLikes().likes && getLikes().likes.length > 0 ? (
                      getLikes()
                        .likes.filter((x) => x.value && x.value.length > 0)
                        .map((like, index) => (
                          <div key={index} className='col-span-2'>
                            <dt className='text-sm font-semibold text-gray-900 border-b pb-3 flex items-center space-x-3'>
                              <span>
                                <BsFillCircleFill className='h-2 w-2 text-[#4B6CB7]' />
                              </span>
                              <span className='break-all'>{like.value}</span>
                            </dt>
                          </div>
                        ))
                    ) : (
                      <>{getMessageText("Likes")}</>
                    )}
                  </dl>

                  <dl className='mt-2 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-2 bg-white py-2 px-4 rounded-2xl'>
                    <div className='col-span-2'>
                      <dt className='text-sm font-medium text-gray-900 border-b pb-3 print:text-black'>
                        Dislikes
                      </dt>
                    </div>
                    {getLikes().dislikes && getLikes().dislikes.length > 0 ? (
                      getLikes()
                        .dislikes.filter((x) => x.value && x.value.length > 0)
                        .map((dislike, index) => (
                          <div key={index} className='col-span-2'>
                            <dt className='text-sm font-semibold text-gray-900 border-b pb-3 flex items-center space-x-3'>
                              <span>
                                <BsFillCircleFill className='h-2 w-2 text-[#4B6CB7]' />
                              </span>
                              <span className='break-all'>{dislike.value}</span>
                            </dt>
                          </div>
                        ))
                    ) : (
                      <>{getMessageText("Dislikes")}</>
                    )}
                  </dl>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LikesDislikes;
