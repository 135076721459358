import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = (props) => {
  const { tabs, handleChange, selectedTab, count, setChildId } = props;
  const checkChildTabId = (tab) => {
    if (tab?.children.find((x) => x.id === selectedTab)) {
      return "tabs active-tabs";
    }
    return "tabs";
  };

  return (
    <>
      <div className='sm:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        Tabs.jsx
        <select
          onChange={(e) => {
            handleChange(e.target.value);
            setChildId(null);
          }}
          id='tabs'
          name='tabs'
          className='mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md'
          defaultValue={
            tabs.find((tab) => tab.current)
              ? tabs.find((tab) => tab.current).id
              : ""
          }
        >
          {tabs.map((tab, index) => (
            <option key={index} role='button' tabIndex={0} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <div className=''>
          <nav className='flex space-x-4 px-6' aria-label='Tabs'>
            {tabs.map((tab, index) => (
              <span
                onClick={() => {
                  !tab?.children && handleChange(tab.id);
                }}
                role='button'
                tabIndex={0}
                key={index}
                className={classNames(
                  tab.id === selectedTab
                    ? " text-white tabss active-tabs cursor-pointer"
                    : "text-white ",
                  "whitespace-nowrap font-medium text-sm"
                )}
              >
                {count && count[tab.value] >= 0 ? (
                  <span
                    className={classNames(
                      tab.value === selectedTab
                        ? "bg-blue-100 text-blue-600"
                        : "bg-gray-100 text-gray-900",
                      "hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                    )}
                  >
                    {count[tab.value]}
                  </span>
                ) : null}
                {tab.children && tab.children.length > 0 ? (
                  <div className={checkChildTabId(tab)}>
                    <Menu as='div' className='relative inline-block text-left'>
                      <div>
                        <Menu.Button className='inline-flex justify-center items-center w-full rounded-md border border-gray-300'>
                          {tab.name}
                          <ChevronDownIcon
                            className='-mr-1 ml-1 h-5 w-5'
                            aria-hidden='true'
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white z-50 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <div className='py-1'>
                            {tab.children.map((childTab, index) => (
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <div className='flex items-center gap-x-1 px-2'>
                                    <span
                                      onClick={() => {
                                        setChildId(childTab?.id);
                                      }}
                                      className='text-gray-700 block px-4 py-2 text-sm w-full hover:bg-gray-100 hover:text-gray-900'
                                    >
                                      {childTab.name}
                                    </span>
                                    {/* {roleAccess([
                                      USER_ROLE.Sp_Admin,
                                      USER_ROLE.Sp_manager
                                    ]) &&
                                      childTab.slug && (
                                        <span
                                          tabIndex={0}
                                          role="button"
                                          onClick={() =>
                                            toggleTab({
                                              ...childTab,
                                              isModalOpen: true
                                            })
                                          }
                                          className="text-black"
                                        >
                                          <BiPlus />
                                        </span>
                                      )} */}
                                  </div>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                ) : (
                  <>{tab.name}</>
                )}
              </span>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Tabs;
