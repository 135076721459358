import { connect } from "react-redux";
import NdisClaimReview from "./NdisClaimReview";
import {
  changeNDISClaimCaseNoteStatusRequest,
  getCaseNotesReviewRequest,
  RemoveReviewCaseNoteList,
} from "store/actions/cos.actions";
import {
  changeNDISCaseNotesStatusLoadingSelector,
  changeStatusSuccessSelector,
  getCaseNoteReviewLoadingSelector,
  getCaseNoteReviewSelector,
  getCaseNotesListForClaimLoaderSelector,
  getCaseNotesListForClaimSelector,
  getSelectedClientsListSelector,
  getStatusFromNdisListSelector,
} from "store/selector/cos.selector";

const mapDispatchToProps = {
  emptyTableData: RemoveReviewCaseNoteList,
  changeStatus: changeNDISClaimCaseNoteStatusRequest,
  getCaseNotesReviewRequest,
};

const mapStateToProps = (state) => {
  const caseNoteReview = getCaseNoteReviewSelector(state);
  const caseNotesListForClaim = getCaseNotesListForClaimSelector(state);
  const caseNotesListForClaimLoader =
    getCaseNotesListForClaimLoaderSelector(state);
  const caseNoteReviewLoading = getCaseNoteReviewLoadingSelector(state);
  const selectedClientList = getSelectedClientsListSelector(state);
  const changeStatusSuccess = changeStatusSuccessSelector(state);
  const statusList = getStatusFromNdisListSelector(state);
  const changeNDISCaseNotesStatusLoading =
    changeNDISCaseNotesStatusLoadingSelector(state);

  return {
    caseNoteReviewLoading,
    statusList,
    changeStatusSuccess,
    selectedClientList,
    caseNotesListForClaim,
    caseNoteReview,
    caseNotesListForClaimLoader,
    changeNDISCaseNotesStatusLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NdisClaimReview);
