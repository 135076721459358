import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiPencil, BiPrinter } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";

export const DropDown = (props) => {
  const {
    isC = false,
    openConfirmationModalHandler,
    openEditSectionModalHandler,
    handlePrint,
    sectionIndex,
    fieldIndex,
    subFieldIndex,
    item,
    type,
    print,
    isUserGracePeriodOver,
  } = props;
  return (
    <div className='max-w-56 text-right top-16'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='inline-flex justify-center w-full  text-sm font-medium rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
            <BsThreeDotsVertical />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute z-50 right-4 w-56 mt-1 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='px-1 py-1 '>
              {print && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() =>
                        handlePrint(
                          sectionIndex,
                          fieldIndex,
                          subFieldIndex,
                          type
                        )
                      }
                      className={`${
                        active ? "bg-blue-dark text-white" : "text-gray-900"
                      } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                    >
                      {active ? (
                        <div className='icon-print-wrapper mr-2'>
                          <BiPrinter className='rounded-md text-center h-4 w-4' />
                        </div>
                      ) : (
                        <div className='icon-print-wrapper mr-2'>
                          <BiPrinter className='rounded-md text-center h-4 w-4' />
                        </div>
                      )}
                      Print
                    </button>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button
                    disabled={isUserGracePeriodOver}
                    onClick={() => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      openEditSectionModalHandler(
                        sectionIndex,
                        fieldIndex,
                        item,
                        subFieldIndex,
                        type
                      );
                    }}
                    className={`${
                      active ? "bg-blue-dark text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <div className='icon-pencil-wrapper mr-2' title='Edit'>
                        <BiPencil className='icon-size' />
                      </div>
                    ) : (
                      <div className='icon-pencil-wrapper mr-2' title='Edit'>
                        <BiPencil className='icon-size' />
                      </div>
                    )}
                    Edit
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    disabled={isUserGracePeriodOver}
                    onClick={() => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      openConfirmationModalHandler(
                        sectionIndex,
                        fieldIndex,
                        subFieldIndex,
                        type
                      );
                    }}
                    className={`${
                      active ? "bg-blue-dark text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <div className='icon-delete-wrapper mr-2'>
                        <HiOutlineTrash className='icon-size' />
                      </div>
                    ) : (
                      <div className='icon-delete-wrapper mr-2'>
                        <HiOutlineTrash className='icon-size' />
                      </div>
                    )}
                    Delete
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
