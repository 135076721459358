import React from "react";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { useNavigate } from "react-router";

const COSNewModal = (props) => {
  const { openModel, handleClose, amount, createCosInvoices, checkboxes } =
    props;

  const navigate = useNavigate();

  const modalFooter = () => (
    <>
      <CustomButton
        type="submit"
        // showLoading={caseNotesCreateLoading}
        variant="secondary"
        label="Cancel"
        clickHandler={() => {
          handleClose(false);
        }}
      />
      <CustomButton
        type="submit"
        // showLoading={caseNotesCreateLoading}
        variant="primary"
        label="Create "
        // isDisabled={checkboxes.length === 0 ? true : false}
        clickHandler={() => {
          createCosInvoices({
            cos_invoice_ids: checkboxes,
            navigate,
          });
          handleClose(false);
        }}
      />
    </>
  );

  return (
    <ModalSkeleton
      isOpen={openModel}
      title="Confirm Selection"
      cssClass={"w-[40%]"}
      modalFooter={modalFooter()}
      closeModal={() => handleClose(false)}
    >
      <div>
        <div>
          <div>
            <p className="text-sm text-gray-500">
              Please confirm the following.
            </p>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Total Case Notes:{checkboxes.length}
          </label>
        </div>
        <div className="mt-2">
          <label className="block text-sm font-medium text-gray-700">
            Total Amount:{"$" + amount.toFixed(2)}
          </label>
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default COSNewModal;
