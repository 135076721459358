import React from "react";
import clockIcon from "../../../assets/svg/clock-1.svg";
import { getNameProfile, getShiftLength } from "Utils/utils";
import AvatarGroup from "components/common/Avatar/AvatarGroup";

const WorkerShiftDetail = (props) => {
  const { userItem, shiftDetail,isGroupShift } = props;
  const arrayData = userItem && Array.isArray(userItem) ? userItem : [userItem];
 
  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname, "0D8ABC"));
  };



  return (
    <>
      <div className="flex gap-4">
        {
          arrayData && arrayData?.length > 0 && arrayData[0] !==null &&    <AvatarGroup
          type="worker"
          arrayData={arrayData || []}
          cssClass="mt-0"
          maxLength={10}
          isForRostering
          isGroupShift={isGroupShift}
        />
         }
       
      </div>
      <div className="flex gap-4">
        {/* <div className="flex gap-4">
          <img src={dollarIcon} alt="user" width="50" height="50" />
          <div>
            <h6 className="text-gray-700 text-base font-semibold mb-0">
              {`$${userItem.hourly_rate ?? 0}`}
            </h6>
            <label className="text-gray-500 text-sm font-normal mb-0">
              Weekly Cost
            </label>
          </div>
        </div> */}
        {((shiftDetail?.start_time && shiftDetail?.end_time) ||
          (shiftDetail?.worker_start_time && shiftDetail?.worker_end_time) ||
          (!shiftDetail?.start_time &&
            !shiftDetail?.worker_start_time &&
            !shiftDetail?.end_time &&
            !shiftDetail?.worker_end_time)) &&
        getShiftLength(
          shiftDetail?.start_time || shiftDetail?.worker_start_time,
          shiftDetail?.end_time || shiftDetail?.worker_end_time,
          false,
          true
        ) === true ? (
          // Display this block if the conditions are met
          <div className="flex gap-4">
            <img src={clockIcon} alt="user" width="50" height="50" />
            <div>
              <h6 className="text-gray-700 text-base font-semibold mb-0">
                {getShiftLength(
                  shiftDetail?.start_time || shiftDetail?.worker_start_time,
                  shiftDetail?.end_time || shiftDetail?.worker_end_time
                )}
              </h6>
              <label className="text-gray-500 text-sm font-normal mb-0">
                Shift Length
              </label>
            </div>
          </div>
        ) : (
          // Display this block if the conditions are not met
          <div className="flex gap-4">
            <img src={clockIcon} alt="user" width="50" height="50" />
            <div>
              <h6 className="text-gray-700 text-base font-semibold mb-0">NA</h6>
              <label className="text-gray-500 text-sm font-normal mb-0">
                Shift Length
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WorkerShiftDetail;
