import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CustomButton from "components/common/Button/CustomButton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import SelectBox from "components/common/DropDownWithIocnsForPrint copy/DropDown";
import Header from "components/common/Header/Header";
import SelectOption from "components/common/Select/Select";
import SideBarNew from "components/common/SideBar/SideBarNew";
import {
  REPORTING_BREADCRUMB,
  REPORTING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import React, { useContext, useEffect, useState } from "react";
import { BsFilterCircle } from "react-icons/bs";

import Search from "components/common/Search/Search";

import PaginationComponent from "components/common/Pagination/index";
import {
  DOC_DOWNLOAD_URL,
  emptyPaginationObj,
  REPORTING_LIMIT,
  SHIFT_STATUS_LIST,
  SHIFT_STATUS_LIST_IN_REPORTING,
} from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import { apiPostRequest } from "helpers/Requests";
import { URL_REPORTING_ROC_WEEKLY_REPORTS } from "store/services/URL";
import moment from "moment";
import { FiDelete } from "react-icons/fi";

import { toast } from "react-toastify";

import { addDays, exportTOExcel } from "Utils/utils";
import ROCWeeklyTable from "./ROCWeeklyReportTable";

const ROCWeeklyReport = ({ getTeamManagmentListCall, teamManagementList }) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [selectedWorker, setSelectedWorker] = useState(null);

  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };

    getTeamManagmentListCall({ filters, bodyFilter: null });
  }, []);

  const [workerList, setWorkerList] = useState([
    { label: "All", value: "all" },
  ]);

  const [status, setStatus] = useState(null);

  const [search, setSearch] = useState(null);
  const [reportList, setReports] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);

  const [isOverTime, setIsOverTime] = useState(null);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return; // Skip the first render
    }

    fetchListReports();
  }, [page]);
  const [isDisableSearch,setIsDisableSearch]=useState(false)
  useEffect(() => {
    const updateLists = async () => {
      const workers =
        teamManagementList?.employees?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      const sortedWorkers = workers.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );

      await setWorkerList(sortedWorkers);

      setWorkerList((prevWorkerList) => [
        { label: "All", value: "all" },
        ...prevWorkerList,
      ]);
    };

    updateLists();
  }, [teamManagementList]);

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(() => fetchListReports(), 600);
    return () => clearTimeout(timeOutId);
  }, [search]);

  const fetchListReports = async (
    isClearAll = false,
    report = null,
    filters = null
  ) => {
    if (!fromDate && !toDate) {
      toast("Select create date range to get data");
      return;
    }
    setLoader(true);
    let url = `${URL_REPORTING_ROC_WEEKLY_REPORTS}?per_page=25&page=${page}`;
    if (filters?.sort_field && filters?.sort_order) {
      url = `${URL_REPORTING_ROC_WEEKLY_REPORTS}?per_page=25&page=${page}&sort_field=${filters?.sort_field}&sort_order=${filters?.sort_order}`;
    }
    const payload = {
      search: search ? search : "",

      worker: isClearAll
        ? "all"
        : selectedWorker
        ? selectedWorker?.value
        : "all",
      status: isClearAll ? "all" : status ? status.value : "all",

      schedule_shift_date: isClearAll
        ? "all"
        : toDate && fromDate
        ? [fromDate, toDate]
        : "all",
      overtime: isClearAll ? "all" : isOverTime ? isOverTime.value : "all",
    };
    if (report) {
      payload.report = report=== "xlsx" ? "excel" : report;
    }
    await apiPostRequest(url, payload)
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          if (payload.hasOwnProperty("report")) {
            if(report === "xlsx"){
              const data=res?.data?.data;
              exportTOExcelFile(data)
            }else{
              window.open(`${res?.data?.data}`, "_blank");
            }
            // window.open(`${DOC_DOWNLOAD_URL}${res?.data?.data}`, "_blank");
          } else {
            setReports(res?.data?.data);
            setPaginationObj((prev) => ({
              ...prev,
              total: res?.data?.data?.workers?.total,
              totalPages: Math.ceil(
                res?.data?.data?.workers?.total /
                  res?.data?.data?.workers?.per_page
              ),
            }));
          }
        }
      })
      .catch((error) => {
      
        toast.error(`${error.message}`);
        console.log( error );
        setLoader(false);
      });
  };
  const handleFileDownLoad = (e) => {
    fetchListReports(false, e);

    // if (e === "xlsx") {
    //   exportTOExcelFile();
    // } else {
    //   fetchListReports(false, e);
    // }
  };

  const exportTOExcelFile = (reportData) => {
    const transformedData =
      reportData &&
      reportData.workers &&
      reportData.workers &&
      reportData?.workers?.flatMap((item) => {
        if (item.shifts && item.shifts.length > 0) {
          return item.shifts.map((shift) => {
            const overTime = item.overtime ? "Yes" : "No";
            return {
              Worker: `${item.first_name} ${item.last_name}`,
              OverTime: overTime,
              Hours: item.total_overtime_seconds,
              ShiftDate: moment(shift.shift_date).format("DD/MM/YYYY"),
              StartTime: moment(shift.start_time).format("DD/MM/YYYY HH:mm"),
              EndTime: moment(shift.end_time).format("DD/MM/YYYY HH:mm"),
              Shift_Length: shift.shift_length,
              Status: shift.status,
            };
          });
        } else {
          return [];
        }
      });

    if (
      !transformedData ||
      transformedData === undefined ||
      transformedData?.length === 0
    ) {
      toast("No data found!");
      return;
    }

    exportTOExcel(transformedData);
  };

  const OVERTIME_10H = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Yes",
      value: "yes",
    },
    {
      label: "No",
      value: "no",
    },
  ];

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col `}
      >
        <main className='flex-1 bg-gray-50 min-h-screen'>
          <Header title='ROC Weekly Reports' />
          <div className='max-w-full mx-auto my-6'>
            <div className='sm:px-6 lg:px-8 flex d-flex justify-between'>
              <Breadcrumb
                pages={REPORTING_BREADCRUMB(
                  REPORTING_BREADCRUMB_PAGES.ROC_WEEKLY_REPORTS
                )}
              />
              <div className='flex  items-end gap-x-2'>
                <div className='w-full flex items-center gap-x-3'>
                  <label
                    htmlFor='from-date-to-to-date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Search by worker name
                  </label>
                  <Search
                    setSearch={setSearch}
                    search={search}
                    width='w-full'
                  />
                </div>
                <div>
                  <SelectBox
                    isUserGracePeriodOver={isUserGracePeriodOver}
                    isLoading={loader}
                    setReportType={(e) => {
                      if (isUserGracePeriodOver) {
                        return;
                      }
                      handleFileDownLoad(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='px-8'>
              <div className='bg-gray-50  pt-0 sticky top-[67px] z-[7]'>
                <section>
                  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 py-4 w-full'>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700'>
                          Support Worker
                        </label>
                        <SelectOption
                          name='worker_id'
                          isSearch={true}
                          data={workerList || []}
                          selectedOption={selectedWorker}
                          handleChange={(e) => {
                            setSelectedWorker(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-sm font-medium text-gray-700 text-start'>
                          Status
                        </label>
                        <SelectOption
                          name='status'
                          isSearch={true}
                          data={SHIFT_STATUS_LIST_IN_REPORTING || []}
                          handleChange={(e) => {
                            setStatus(e);
                          }}
                          selectedOption={status}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-sm font-medium text-gray-700 text-start'>
                          Shift Date Range (Max one week){" "}
                          <span className='text-red-400'>*</span>
                        </label>
                        <DateRangePickerNew
                          handleCancel={() => {
                            setFromDate(null);
                            setToDate(null);
                            setReports([]);
                          }}
                          handleChange={(obj) => {
                            if (obj) {
                              obj.fromDate && setFromDate(obj.fromDate);
                              obj.toDate && setToDate(obj.toDate);
                            }
                          }}
                          setSelectedFromDate={setSelectedFromDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          maxDate={addDays(selectedFromDate, 6)}
                          setIsDisableSearch={setIsDisableSearch}
                        />
                      </div>
                    </div>
                    <div className='w-full'>
                      <div className='col-span-1'>
                        <label className='block text-start text-sm font-medium text-gray-700'>
                          Overtime
                        </label>
                        <SelectOption
                          name='overtime'
                          isSearch={true}
                          data={OVERTIME_10H || []}
                          selectedOption={isOverTime}
                          handleChange={(e) => {
                            setIsOverTime(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div className='flex py-2 w-full justify-between space-x-2'>
                    <div className='w-full'>
                      <div
                        className='flex d-flex justify-end space-x-2'
                        // style={{ marginTop: "2.5px" }}
                      >
                        <CustomButton
                          Icon={<BsFilterCircle />}
                          clickHandler={() => fetchListReports(false)}
                          label='Search'
                          showLoading={loader || false}
                          isDisabled={loader || isDisableSearch}
                        />
                        <CustomButton
                          isDisabled={loader || false}
                          Icon={<FiDelete />}
                          clickHandler={() => {
                            setStatus(null);
                            setSelectedWorker(null);
                            setIsOverTime(null);
                            setSearch(null);
                            setReports([]);
                            setFromDate(null)
                            setToDate(null)
                            setSelectedFromDate(null)
                            // fetchListReports(true);
                          }}
                          label='Clear Filter'
                          cssClass='whitespace-nowrap'
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className='min-w-full  flex justify-center overflow-x-auto custom-scroll'>
                <table className='border min-w-full divide-y divide-gray-300'>
                  <thead
                    // style={{ top: "130px" }}
                    className='bg-gray-50 sticky z-[5]'
                  >
                    <tr>
                      <TableHeader isSort={false} />

                      <TableHeader isSort={false} label={"Worker"} />

                      <TableHeader isSort={false} label={"Over Time"} />
                      <TableHeader isSort={false} label={"Weekly Hours"} />
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
                    {loader ? (
                      <tr className='bg-white'>
                        <td colSpan={4} className='py-6 text-center bg-white'>
                          <div
                            className={`flex flex-col w-full min-h-screen items-center justify-start`}
                          >
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          </div>
                        </td>
                      </tr>
                    ) : reportList !== null &&
                      reportList?.workers?.data?.length > 0 ? (
                      reportList?.workers?.data?.map((item, index) => (
                        <ROCWeeklyTable item={item} />
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={4}
                          className='py-6 text-center text-red-600'
                        >
                          No data found. Please adjust your filters to get
                          relevant results
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {reportList && reportList?.workers?.data?.length > 0 && (
                <div className='mt-3'>
                  <PaginationComponent
                    page={paginationObj?.page || 1}
                    activeListLength={reportList?.workers?.data || []}
                    limit={REPORTING_LIMIT}
                    totalList={paginationObj?.total}
                    handlePagination={(e) => {
                      setPage(e);
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                    }}
                    totalPages={paginationObj?.totalPages}
                  />
                </div>
              )}
           
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ROCWeeklyReport;
