import { connect } from "react-redux";
import {
  getPrioritySettingRequest,
  updatePriorityRequest,
  createNewPeriorityRequest,
  emptyNewData,
} from "../../../../../store/actions/cos.actions";
import {
  createNewPriorityErrorSelector,
  createNewPriorityLoadingSelector,
  getCosPrioritySetting,
  getCosSettingPriorityLoader,
  newPeriorityListSelector,
  updatePriorityLoadingSelector,
  updatePrioritySuccessSelector,
  updatePriorityErrorSelector,
} from "../../../../../store/selector/cos.selector";
import PriorityPage from "./PriorityPage";

const mapDispatchToProps = {
  updatePriorityRequest,
  getPrioritySetting: getPrioritySettingRequest,
  createNewPeriorityRequest,
  emptyNewData,
};

const mapStateToProps = (state) => {
  const createNewPriorityLoading = createNewPriorityLoadingSelector(state);
  const createNewPriorityError = createNewPriorityErrorSelector(state);
  const updatePriorityLoading = updatePriorityLoadingSelector(state);
  const updatePriorityError = updatePriorityErrorSelector(state);
  const cosPrioritySetting = getCosPrioritySetting(state);
  const newPeriorityList = newPeriorityListSelector(state);
  const updatePrioritySuccess = updatePrioritySuccessSelector(state);
  const getListLoader = getCosSettingPriorityLoader(state);

  return {
    updatePrioritySuccess,
    createNewPriorityLoading,
    createNewPriorityError,
    updatePriorityLoading,
    updatePriorityError,
    newPeriorityList,
    cosPrioritySetting,
    getListLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriorityPage);
