import axios from "axios";
import getItemFromLocalStorage from "../../Utils/GetItemFromLocalstorage";

// Define the URL you want to block
const BLOCKED_URL = 'https://forms.hscollectedforms.net';

// Create a custom axios instance
const axiosInstance = axios.create();

// Add a request interceptor to the custom instance
axiosInstance.interceptors.request.use(
    (config) => {
        // Check if the request URL starts with the blocked URL
        if (config.url?.startsWith(BLOCKED_URL)) {
            // Reject the request if it matches the blocked URL
            return Promise.reject(new Error(`Requests to ${BLOCKED_URL} are not allowed`));
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Your existing response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;

        if (
            response &&
            response.status &&
            (response.status === 401 ||
                response.status === 322 ||
                response.status === 423)
        ) {
            const user = getItemFromLocalStorage("user");
            if (user) {
                localStorage.clear();
                sessionStorage.clear();
                window.location.reload();
            }
        }
        return Promise.reject(error);
    }
);

// Override global fetch function
const originalFetch = window.fetch;
window.fetch = async (...args) => {
    const [resource, config] = args;
    if (resource.toString().startsWith(BLOCKED_URL)) {
        throw new Error(`Requests to ${BLOCKED_URL} are not allowed`);
    }
    return originalFetch.apply(window, args);
};

// Override XMLHttpRequest
const originalXHROpen = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function(...args) {
    const [method, url] = args;
    if (url.startsWith(BLOCKED_URL)) {
        throw new Error(`Requests to ${BLOCKED_URL} are not allowed`);
    }
    return originalXHROpen.apply(this, args);
};

export default axiosInstance;