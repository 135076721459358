import { connect } from "react-redux";
import COSInvoicePreviewPage from "./COSInvoicePagePreview";
import {
  changeNDISClaimCaseNoteInvoiceStatusRequest,
  getClientsRequest,
  getCreatedCosInvoicesReviewRequest,
  RemoveReviewCaseNoteList,
} from "store/actions/cos.actions";
import {
  changeCaseNotesInvoiceStatusSuccessSelector,
  changeNDISCaseNotesInvoiceStatusLoadingSelector,
  createdCosInvoiceReviewLoadingSelector,
  createdCosReviewInvoiceClientsSelector,
  createdCosReviewInvoiceDeliveryStatusSelector,
  getCOSInvoiceLoaderSelector,
  getCreatedCosInvoiceReviewSelector,
} from "store/selector/cos.selector";

const mapDispatchToProps = {
  getClientsRequest,
  emptyTableData: RemoveReviewCaseNoteList,
  getInvoicesList: getCreatedCosInvoicesReviewRequest,
  changeInvoiceStatus: changeNDISClaimCaseNoteInvoiceStatusRequest,
};

const mapStateToProps = (state) => {
  const cosInvoiceLoading = getCOSInvoiceLoaderSelector(state); // these are support coordinators not a category
  const createdCosRiviewInvoice = getCreatedCosInvoiceReviewSelector(state);
  const createdCosInvoiceReviewLoading =
    createdCosInvoiceReviewLoadingSelector(state);
  const createdCosReviewInvoiceDeliveryStatus =
    createdCosReviewInvoiceDeliveryStatusSelector(state);
  const createdCosReviewInvoiceClients =
    createdCosReviewInvoiceClientsSelector(state);
  const changeNDISCaseNotesInvoiceStatusLoading =
    changeNDISCaseNotesInvoiceStatusLoadingSelector(state);
  const changeCaseNotesInvoiceStatusSuccess =
    changeCaseNotesInvoiceStatusSuccessSelector(state);

  return {
    createdCosReviewInvoiceDeliveryStatus,
    createdCosReviewInvoiceClients,
    changeCaseNotesInvoiceStatusSuccess,
    changeNDISCaseNotesInvoiceStatusLoading,
    createdCosRiviewInvoice,
    createdCosInvoiceReviewLoading,
    cosInvoiceLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(COSInvoicePreviewPage);
