import React from "react";
import { useForm } from "react-hook-form";
import ModalSkeleton from "../../Modals/ModalSkeleton";
import CustomButton from "../Button/CustomButton";

function Modal(props) {
  const {
    closeModalHandler,
    dataSubmitHandler,
    editData,
    type,
    title,
    showModal,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: editData,
  });

  const onSubmit = (data) => {
    dataSubmitHandler(data);
    reset();
    closeModalHandler();
  };

  const modalFooter = () => {
    return (
      <>
        <CustomButton
          variant="danger"
          label="Close"
          clickHandler={closeModalHandler}
        />
        <CustomButton
          label="Save Changes"
          clickHandler={() => {
            const buttonRef = document.getElementById(
              "sectionCreationModalButton"
            );
            if (buttonRef) {
              buttonRef.click();
            }
          }}
        />
      </>
    );
  };

  return (
    <ModalSkeleton
      isOpen={showModal}
      title={title}
      closeModal={closeModalHandler}
      modalFooter={modalFooter()}
      cssClass="w-[40%]"
    >
      <div className="flex flex-col">
        <p className="text-sm text-gray-500 mb-3">
          Please enter following details to
          {` ${title.toLowerCase()}`}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            <div className="grid grid-cols-3 gap-3">
              <div
                className={`${
                  type === "sub_section" ? " col-span-2" : " col-span-3"
                } flex flex-col did-floating-label-content`}
              >
                <label htmlFor="" className="did-floating-label">
                  {type === "sub_section"
                    ? "Sub Section Title"
                    : " Section Title"}
                </label>
                <input
                  {...register("section_title", {
                    required: true,
                    maxLength: 254,
                  })}
                  name="section_title"
                  type="text"
                  className="did-floating-input"
                />
                {errors?.section_title?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>
              {type === "sub_section" && (
                <div className="ml-3 flex flex-col did-floating-label-content">
                  <label htmlFor="" className="did-floating-label">
                    Add Other
                  </label>
                  <select
                    {...register("isAddOther", {
                      required: false,
                    })}
                    name="isAddOther"
                    id="isAddOther"
                    className="did-floating-input"
                    defaultValue={false}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>

                  {errors?.isAddOther?.type === "required" && (
                    <span className="text-sm text-red-500">
                      This field is required!
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>

          <button
            className="hidden sr-only"
            id="sectionCreationModalButton"
            type="submit"
          />
        </form>
      </div>
    </ModalSkeleton>
  );
}

export default Modal;
