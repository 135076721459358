import React from "react";

const CaseNotesList = () => {
  return (
    <div className="my-2">
      <div className="flex justify-between items-center p-5 bg-gray-100 rounded-lg border-1 border-black">
        <h1 className="text-lg font-bold leading-6 text-gray-900 sm:truncate">
          Case Notes List
        </h1>
        <button className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-offset-2 sm:w-auto">
          Case Note Report
        </button>
      </div>
      <div className="">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50 capitalize">
            <tr className="pt-2 sticky top-[8rem]">
              <th
                scope="col"
                className="bg-blue-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Subject
              </th>

              <th
                scope="col"
                className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Delivered Date
              </th>
              <th
                scope="col"
                className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Bill Table
              </th>
              <th
                scope="col"
                className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Created By
              </th>
              <th
                scope="col"
                className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Created Type
              </th>
              <th
                scope="col"
                className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Duration
              </th>
              <th
                scope="col"
                className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Service Provider
              </th>
              <th
                scope="col"
                className="bg-blue-50 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              ></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white overflow-y-auto">
            <tr>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                Testing
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                22/07/2022
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                Yes
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                Afnan
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                Health testing
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                5 mins
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                Expert care
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="w-64 truncate flex space-x-4 items-center"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CaseNotesList;
