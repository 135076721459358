import React, { useContext, useEffect, useMemo, useState } from "react";
import { AiOutlineClockCircle, AiOutlineQuestion } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import CircularLoader from "../../../common/CircularLoader/CircularLoader";
import CustomButton from "../../../common/Button/CustomButton";
import {
  baseURLImage,
  getDifferenceArray,
  getNameProfile,
  parseBooleanVal,
  validateCurrentDate,
} from "../../../../Utils/utils";
import SelectOption from "../../../common/Select/Select";
import {
  // DateValidaionOptions,
  EMAIL_REGEX_EXP,
  planManagedByOptions,
  PRIMARY_DISABILITY_OPTIONS,
  STATES_LIST,
  // SERVICE_CATALOG_STATES,
  CATEGORY_ITEM_NAME_AND_NOTES,
  REGEX_PHONE_NUMBER,
  REGEX_NDIS_NUMBER,
} from "../../../../Constants/constant";
import SwitchInput from "../../../common/Input/SwitchInput";
import moment from "moment";
import _, { isArray } from "lodash";
import { roleAccess } from "helpers/RolesPermission";
import {
  PAYROLL_PLATFORMS,
  PERMISSION_KEYS,
  PERMISSION_KEYS_CHILDREN,
} from "store/constants";
// import { HiOutlineTrash } from "react-icons/hi";
// import InputField from "components/common/Input/Input";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { LoginContext } from "helpers/LoginContext";
import { toast } from "react-toastify";
import DeleteParticipantModal from "pages/ServiceProvider/ParticipantsList/DeleteParticipantModal";
import usePayrollAuthentication from "hooks/usePayrollAuthentication";
import { FiArrowUpRight } from "react-icons/fi";
import Loader from "components/common/Loader/Loader";
import ImageDisplayModal from "pages/HRM/Pages/TeamManagment/ImageDisplayModalForXeroWorker";
import MultiLevelDropdown from "components/common/MultilevelDropdown";
import api from "store/services/sp.services";
import { apiGetRequest } from "helpers/Requests";

const Settings = (props) => {
  const {
    settingData,
    fetchSettingsData,
    workersList,
    fetchWorkers,
    isLoading,
    settingLoading,
    updateSettingRequest,
    supportCoordinatorsList,
    getCatalogServicesSubCategory,
    catalogStates,
  } = props;

  const { uuid } = useParams();
  const { isUserGracePeriodOver } = useContext(LoginContext);
  const { isAuthenticateForPayrolls, isCheckPayrollAuthLoading } =
    usePayrollAuthentication();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm();
  const watchFormData = watch();
  // console.log("watchFormData", watchFormData);

  const [enabled, setEnabled] = useState(false);
  const [supportCoordination, setSupportCoordination] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [supportCoordinators, setSupportCoordinators] = useState([]);
  const [picture, setPicture] = useState(null);
  const [assignedWorkers, setAssignedWorkers] = useState([]);
  const [assignedSupportCoordinators, setAssignedSupportCoordinators] =
    useState([]);
  const [user, setUser] = useState(null);
  const [file, setFile] = useState(undefined);
  const [planBy, setPlanBy] = useState([]);
  const [primaryDisability, setPrimaryDisability] = useState(null);
  const [secondaryDisability, setSecondaryDisability] = useState(null);
  const [catalogState, setCatalogState] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);

  // const [emailFields, setEmailFields] = useState([""]);
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);
  const [rosterInvoicing, setRosterInvoicing] = useState(null);

  const [showXeroImage, setXeroImage] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [selectedItems, setSelectedItems] = useState(null);
  const [loader, setLoader] = useState(false);

  const [isFetchServiceCatLoader, setIsFetchServiceCatLoader] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);

  const watchAllFields = watch();
  // const handleEmailChange = (index, value) => {
  //   const newEmailFields = [...emailFields];
  //   newEmailFields[index] = value;
  //   setEmailFields(newEmailFields);
  // };

  // const handleAddEmailField = () => {
  //   setEmailFields([...emailFields, ""]);
  // };

  // const handleRemoveEmailField = (index) => {
  //   const newEmailFields = [...emailFields];
  //   newEmailFields.splice(index, 1);
  //   setEmailFields(newEmailFields);
  // };

  const settingsData = useMemo(() => {
    if (settingData) {
      return settingData;
    }
    return null;
  }, [settingData]);
  useEffect(() => {
    if (settingData) {
      setValue("timeZone", settingsData?.timezone);
    }
    getServiceTypes();
  }, []);

  const getServiceTypes = async () => {
    setIsFetchServiceCatLoader(true);
    try {
      const res = await apiGetRequest("/roster-invoices/service-type");
      if (res.data) {
        const modifiedValues = res.data.data.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        }));
        setServiceTypes(modifiedValues);
        setIsFetchServiceCatLoader(false);
      }
    } catch (error) {
      setIsFetchServiceCatLoader(false);
      console.log({ error });
    }
  };

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size > 6 * 1024 * 1024) {
      // Display error message if file size exceeds 6MB
      toast.error("Image size should not exceed 6MB.");
      event.target.value = null;
    } else {
      // Set the selected file as the image and picture if size is within limits
      setFile(URL.createObjectURL(selectedFile));
      setPicture(selectedFile);
    }
  };

  useEffect(() => {
    if (!catalogStates) {
      getCatalogServicesSubCategory({ isService: false });
    }
    if (workersList.length === 0 || supportCoordinatorsList.length === 0) {
      fetchWorkers();
    }
    if (!settingData && uuid) {
      fetchSettingsData({ participantUUID: uuid });
    }
  }, []);

  const onSubmit = async (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    const scoordinators = !supportCoordination
      ? []
      : [...assignedSupportCoordinators];
    const assign_workers = [...assignedWorkers, ...scoordinators];

    let formData = new FormData();
    if (file) {
      formData.append("profile_picture", picture);
    }

    formData.append("street_no", data.address ? data.address : "");
    formData.append("state", data.state ? data.state : "");
    formData.append("suburb", data.suburb ? data.suburb : "");
    formData.append("post_code", data.postcode ? data.postcode : "");
    formData.append("primary_no", data.phone ? data.phone : "");
    formData.append(
      "emergency_contact",
      data.emergencyContactPhone ? data.emergencyContactPhone : ""
    );
    formData.append(
      "emergency_contact_name",
      data.emergencyContactName ? data.emergencyContactName : ""
    );
    formData.append(
      "quick_support",
      data.quickSnapshot ? data.quickSnapshot : ""
    );
    formData.append("timezone", data.timeZone ? data.timeZone : "");
    formData.append("first_name", data.firstName);
    formData.append("last_name", data.lastName);
    formData.append("ndis_no", data.ndisNumber ? data.ndisNumber : "");
    formData.append("dob", data.dob ? data.dob : "");
    formData.append(
      "primary_disability",
      data.primary_disability ? data.primary_disability : ""
    );
    formData.append(
      "secondary_disability",
      data.secondary_disability ? data.secondary_disability : ""
    );
    formData.append("email", data.primary_email ? data.primary_email : "");
    // formData.append("email_for_invoice", data.email ? data.email : "");
    formData.append(
      "plan_start_date",
      data.planStartDate ? data.planStartDate : ""
    );
    formData.append("plan_end_date", data.planEndDate ? data.planEndDate : "");
    formData.append("plan_managed_by", JSON.stringify([planBy]));
    formData.append("status", enabled === true ? "active" : "inactive");
    formData.append("support_coordination", supportCoordination);
    formData.append(
      "status_description",
      data.status_description ? data.status_description : ""
    );
    formData.append(
      "important_alert",
      data.important_alert ? data.important_alert : ""
    );

    formData.append("state_id", data.state_id);
    formData.append("invoice_emails", emails ? emails : []);
    formData.append(
      "roster_invoicing_item",
      data.roster_invoicing_item ? data.roster_invoicing_item : ""
    );

    if (assign_workers && assign_workers.length > 0) {
      for (let i = 0; i < assign_workers.length; i++) {
        formData.append("assign_workers[]", assign_workers[i].value);
      }
    }

    if (data?.xero_contact_id) {
      formData.append("xero_contact_id", data?.xero_contact_id);
    }

    if (data?.invoice_category_id) {
      formData.append("invoice_category_id", data?.invoice_category_id);
    }

    if (selectedItems) {
      const selectedRegion = [selectedItems];
      if (selectedRegion?.length > 0) {
        for (let i = 0; i < selectedRegion?.length; i++) {
          formData.append("region_id[]", selectedRegion[i]?.id);
        }
      }
    }

    updateSettingRequest({
      participantUUID: uuid,
      formData,
    });
  };

  useEffect(() => {
    if (planBy !== null) {
      setValue("plan", planBy);
    }
  }, [planBy]);

  useEffect(() => {
    if (
      primaryDisability &&
      primaryDisability !== null &&
      primaryDisability.value === "other"
    ) {
      setValue("primary_disability", null);
    }
    if (
      primaryDisability &&
      primaryDisability !== null &&
      primaryDisability.value !== "other"
    ) {
      setValue("primary_disability", primaryDisability.value);
    }
  }, [primaryDisability]);

  useEffect(() => {
    if (
      secondaryDisability &&
      secondaryDisability !== null &&
      secondaryDisability.value === "other"
    ) {
      setValue("secondary_disability", null);
    }
    if (
      secondaryDisability &&
      secondaryDisability !== null &&
      secondaryDisability.value !== "other"
    ) {
      setValue("secondary_disability", secondaryDisability.value);
    }
  }, [secondaryDisability]);

  useEffect(() => {
    if (catalogState && catalogState !== null && catalogState.value) {
      setValue("state_id", catalogState.value);
      clearErrors("state_id");
    }
  }, [catalogState]);

  useEffect(() => {
    if (rosterInvoicing && rosterInvoicing !== null && rosterInvoicing.value) {
      setValue("roster_invoicing_item", rosterInvoicing.value);
      clearErrors("roster_invoicing_item");
    }
  }, [rosterInvoicing]);

  useEffect(() => {
    const emailArray = [];
    settingData?.invoice_emails?.map((item) => emailArray.push(item?.email));
    setEmails(emailArray);
  }, []);

  useEffect(() => {
    // simulate async api call with set timeout
    if (settingsData && settingsData !== undefined) {
      let primaryDisabilityValue = null;
      let secondaryDisabilityValue = null;
      let rosterInvoicingItem = null;
      const state_id_int =
        typeof settingData.state_id === "string"
          ? parseInt(settingData.state_id, 10)
          : settingData.state_id;
      if (settingData.primary_disability) {
        primaryDisabilityValue = PRIMARY_DISABILITY_OPTIONS.find(
          (x) =>
            x.value.toLowerCase() ===
            settingData.primary_disability.toLowerCase()
        );
        if (
          (primaryDisabilityValue === null ||
            primaryDisabilityValue === undefined) &&
          settingData.primary_disability &&
          settingData.primary_disability.length > 0
        ) {
          setPrimaryDisability({ label: "Other", value: "other" });
        } else {
          setPrimaryDisability(primaryDisabilityValue);
        }
      }
      if (settingData.roster_invoicing_item) {
        rosterInvoicingItem = CATEGORY_ITEM_NAME_AND_NOTES.find(
          (x) =>
            x.value.toLowerCase() ===
            settingData.roster_invoicing_item.toLowerCase()
        );
        if (
          (rosterInvoicingItem === null || rosterInvoicingItem === undefined) &&
          settingData.roster_invoicing_item
        ) {
          setRosterInvoicing(null);
        } else {
          setRosterInvoicing(rosterInvoicingItem);
        }
      }
      if (settingData.secondary_disability) {
        secondaryDisabilityValue = PRIMARY_DISABILITY_OPTIONS.find(
          (x) =>
            x.value.toLowerCase() ===
            settingData.secondary_disability.toLowerCase()
        );
        if (
          (secondaryDisabilityValue === null ||
            secondaryDisabilityValue === undefined) &&
          settingData.secondary_disability &&
          settingData.secondary_disability.length > 0
        ) {
          setSecondaryDisability({ label: "Other", value: "other" });
        } else {
          setSecondaryDisability(secondaryDisabilityValue);
        }
      }
      if (settingData.state_id && catalogStates) {
        const stateObj = catalogStates.find((x) => x.value === state_id_int);
        setCatalogState({ ...stateObj });
      }
      const parsePlanBy =
        typeof settingsData.plan_managed_by === "string" &&
        settingsData.plan_managed_by.includes("label")
          ? JSON.parse(settingsData.plan_managed_by)
          : null;

      setPlanBy(
        parsePlanBy && isArray(parsePlanBy) && parsePlanBy.length > 0
          ? parsePlanBy[0]
          : parsePlanBy
      );

      setUser({
        primary_email: settingsData.email !== "null" ? settingsData.email : "",
        firstName: settingsData.first_name,
        lastName: settingsData.last_name,
        quickSnapshot: settingsData.quick_support,
        address: settingsData.street_no,
        state: settingsData.state,
        suburb: settingsData.suburb,
        postcode: settingsData.post_code,
        phone: settingsData.primary_no,
        emergencyContactName: settingsData.emergency_contact_name,
        emergencyContactPhone: settingsData.emergency_contact,
        ndisNumber: settingsData.ndis_no,
        dob: settingsData.dob,
        // email:
        //   settingData.email_for_invoice !== "null"
        //     ? settingData.email_for_invoice
        //     : "",
        planStartDate: settingsData.plan_start_date,
        planEndDate: settingsData.plan_end_date,
        roster_invoicing_item:
          rosterInvoicingItem === null || rosterInvoicingItem === undefined
            ? settingsData.roster_invoicing_item
            : rosterInvoicingItem
            ? rosterInvoicingItem.value
            : null,
        plan: parsePlanBy,
        primary_disability:
          primaryDisabilityValue === null ||
          primaryDisabilityValue === undefined
            ? settingsData.primary_disability
            : primaryDisabilityValue
            ? primaryDisabilityValue.value
            : null,
        secondary_disability:
          secondaryDisabilityValue === null ||
          secondaryDisabilityValue === undefined
            ? settingsData.secondary_disability
            : secondaryDisabilityValue
            ? secondaryDisabilityValue.value
            : null,
        state_id: state_id_int ?? null,
        status_description: settingData.status_description || "",
        important_alert: settingsData.important_alert || "",
        xero_contact_id: settingData?.xero_contact_id,
        invoice_category_id: settingData?.invoice_category_id || "",
      });
      setAssignedWorkers(
        settingsData.hasOwnProperty("sp_workers")
          ? settingData.sp_workers.map((x) => {
              const obj = {
                value: x.id,
                label: `${x.first_name} ${x.last_name}`,
              };
              return obj;
            })
          : []
      );
      setAssignedSupportCoordinators(
        settingsData.hasOwnProperty("sp_support_coordinators")
          ? settingData.sp_support_coordinators.map((x) => {
              const obj = {
                value: x.id,
                label: `${x.first_name} ${x.last_name}`,
              };
              return obj;
            })
          : []
      );
      setEnabled(settingsData.status === "active" ? true : false);
      setSupportCoordination(
        parseBooleanVal(settingsData.support_coordination)
      );
    }
  }, [catalogStates, workersList]);

  useEffect(() => {
    setWorkers(workersList);
    setSupportCoordinators(supportCoordinatorsList);
  }, [workersList, supportCoordinatorsList]);

  useEffect(() => reset(user), [user]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const addBlockDiv = () => {
    if (
      primaryDisability &&
      primaryDisability !== null &&
      primaryDisability.value === "other" &&
      secondaryDisability &&
      secondaryDisability !== null &&
      secondaryDisability.value !== "other"
    ) {
      return <div className='block' />;
    }

    if (
      secondaryDisability &&
      secondaryDisability !== null &&
      secondaryDisability.value === "other" &&
      primaryDisability &&
      primaryDisability !== null &&
      primaryDisability.value !== "other"
    ) {
      return <div className='block' />;
    }

    return <></>;
  };

  useEffect(() => {
    const getManagersList = async () => {
      setLoader(true);
      const res = await api.getTeamLeadManagerAndRegions(1);

      setRegionList(res?.data?.regions);
      setLoader(false);
    };
    getManagersList();
  }, []);

  useEffect(() => {
    if (settingData) {
      setSelectedItems(...settingData?.regions);
    }
  }, []);

  return (
    <div>
      <div className='md:grid md:gap-6'>
        {settingLoading && !settingData ? (
          <div className='col-span-3 h-screen'>
            <CircularLoader
              classes='h-full flex justify-center items-center'
              classloader='loader-l'
            />
          </div>
        ) : (
          <div className='mt-5 md:mt-0 md:col-span-2'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='shadow sm:rounded-md sm:overflow-hidden'>
                <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                  <div>
                    <label className='block text-base font-medium text-gray-700'>
                      Photo
                    </label>
                    <div className='mt-1 flex items-center'>
                      {file ? (
                        <img
                          className='object-cover w-16 h-16 rounded-full lg:w-10 lg:h-10'
                          src={file}
                          alt=''
                        />
                      ) : (
                        <img
                          onError={(e) =>
                            handleImageError(
                              e,
                              settingData.first_name,
                              settingData.last_name || " "
                            )
                          }
                          className='object-cover w-10 h-10 rounded-full'
                          src={
                            settingData && settingData.profile_picture
                              ? baseURLImage(settingData.profile_picture)
                              : getNameProfile(
                                  settingData.first_name,
                                  settingData.last_name || " "
                                )
                          }
                          alt=''
                        />
                      )}
                      <label
                        htmlFor='file-upload'
                        className='relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 ml-5'
                      >
                        <span>Upload Image</span>

                        <input
                          id='file-upload'
                          name='file-upload'
                          type='file'
                          className='sr-only'
                          accept='image/*'
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    <span className='text-xs text-gray-400 ml-1'>
                      (File size should not be greater than 6MB)
                    </span>
                  </div>
                  <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                    <div>
                      <label
                        htmlFor='f_name'
                        className='capitalize block text-sm font-medium text-gray-700'
                      >
                        First Name
                      </label>
                      <div className='mt-1'>
                        <input
                          maxLength={50}
                          type='text'
                          name='f_name'
                          id='f_name'
                          className='did-floating-input capitalize'
                          {...register("firstName", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.firstName && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='f_name'
                        className='capitalize block text-sm font-medium text-gray-700'
                      >
                        Last Name
                      </label>
                      <div className='mt-1'>
                        <input
                          maxLength={50}
                          type='text'
                          name='f_name'
                          id='f_name'
                          className='did-floating-input capitalize'
                          {...register("lastName", {
                            required: true,
                            maxLength: 50,
                          })}
                        />
                        {errors.lastName && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                    {roleAccess(
                      [],
                      PERMISSION_KEYS.PARTICIPANT,
                      PERMISSION_KEYS_CHILDREN.PARTICIPANT.DOB
                    ) && (
                      <div>
                        <label
                          htmlFor=''
                          className='block text-sm font-medium text-gray-700'
                        >
                          Date of Birth
                        </label>
                        <div className='mt-1'>
                          <input
                            type='date'
                            name=''
                            id=''
                            // validate
                            className='did-floating-input'
                            {...register("dob", {
                              // required: true
                              pattern: validateCurrentDate(
                                moment(watchAllFields.dob),
                                moment(new Date()),
                                ["past", "present"],
                                true
                              ),
                            })}
                          />
                          {errors?.dob?.type === "required" && (
                            <span className='text-red-500'>
                              This field is required
                            </span>
                          )}
                          {errors?.dob?.type === "pattern" && (
                            <span className='text-sm text-red-500'>
                              {validateCurrentDate(
                                moment(watchAllFields.dob),
                                moment(new Date()),
                                ["past", "present"],
                                false
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {roleAccess(
                      [],
                      PERMISSION_KEYS.PARTICIPANT,
                      PERMISSION_KEYS_CHILDREN.PARTICIPANT.NDIS_NUMBER
                    ) && (
                      <div>
                        <label
                          htmlFor=''
                          className='block text-sm font-medium text-gray-700'
                        >
                          NDIS Number
                        </label>
                        <div className='mt-1'>
                          <input
                            type='number'
                            name=''
                            id=''
                            className='did-floating-input'
                            {...register("ndisNumber", {
                              // required: true,
                              pattern: {
                                value: REGEX_NDIS_NUMBER,
                                message:
                                  "NDIS number should start with either '43' or '5'",
                              },
                            })}
                          />
                          {errors.required
                            ? errors.ndisNumber && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )
                            : errors?.ndisNumber?.type === "pattern" && (
                                <span className='text-red-500'>
                                  {errors?.ndisNumber?.message}
                                </span>
                              )}
                        </div>
                      </div>
                    )}
                    {roleAccess(
                      [],
                      PERMISSION_KEYS.PARTICIPANT,
                      PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_PHONE
                    ) && (
                      <div>
                        <label
                          htmlFor='phone'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Primary Phone Number
                        </label>
                        <div className='mt-1'>
                          <input
                            type='number'
                            // minLength={10}
                            maxLength={10}
                            name='phone'
                            id='phone'
                            className='did-floating-input'
                            {...register("phone", {
                              // required: true,
                              // minLength: 10,
                              maxLength: 10,
                              pattern: REGEX_PHONE_NUMBER,
                              onChange: (e) => {
                                const sanitizedValue = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                                const formattedValue = sanitizedValue.slice(
                                  0,
                                  10
                                );
                                e.target.value = formattedValue;
                              },
                            })}
                          />
                          {/* {errors?.phone?.type === "required" ? (
                          errors.phone && (
                            <span className="text-red-500">
                              This field is required
                            </span>
                          )
                        ) : ( */}
                          <>
                            {errors.phone?.type === "pattern" && (
                              <span className='text-red-500'>
                                {/* Phone number must start with 04, 1300 or 1800 */}
                                Please enter a valid phone number!
                              </span>
                            )}
                            {/* {errors.phone?.type === "maxLength" && (
                            <span className="text-red-500">
                              Please enter a valid phone number with the max
                              length of 10
                            </span>
                          )} */}
                            {errors.phone?.type === "minLength" && (
                              <span className='text-red-500'>
                                Please enter a valid phone number with the min
                                length of 10
                              </span>
                            )}
                          </>
                          {/* )} */}
                        </div>
                      </div>
                    )}
                    {roleAccess(
                      [],
                      PERMISSION_KEYS.PARTICIPANT,
                      PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_EMAIL
                    ) && (
                      <div>
                        <label
                          htmlFor=''
                          className='block text-sm font-medium text-gray-700'
                        >
                          Primary Email Address
                        </label>
                        <div className='mt-1'>
                          <input
                            type='text'
                            name=''
                            id=''
                            className='did-floating-input'
                            {...register("primary_email", {
                              // required: false,
                              pattern: EMAIL_REGEX_EXP,
                            })}
                          />
                          {errors.required
                            ? errors.primary_email && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )
                            : errors?.primary_email?.type === "pattern" && (
                                <span className='text-red-500'>
                                  Invalid email address
                                </span>
                              )}
                        </div>
                      </div>
                    )}

                    <div>
                      <label
                        htmlFor=''
                        className='block text-sm font-medium text-gray-700'
                      >
                        Street Address
                      </label>
                      <div className='relative mt-1'>
                        <input
                          type='text'
                          name='address'
                          id='address'
                          className='did-floating-input '
                          {...register("address", {
                            // required: true
                            maxLength: 254,
                          })}
                        />
                        {errors.address && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor=''
                        className='block text-sm font-medium text-gray-700'
                      >
                        Suburb
                      </label>
                      <div className='relative mt-1'>
                        <input
                          type='text'
                          name='suburb'
                          id='suburb'
                          className='did-floating-input '
                          {...register("suburb", {
                            //  required: true
                            maxLength: 254,
                          })}
                        />
                        {errors.suburb && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='col-span-1'>
                      <div className=''>
                        <label className='block text-sm font-medium text-gray-700 mb-1'>
                          State
                        </label>
                        <SelectOption
                          cssClass='truncate'
                          isRequired={true}
                          register={register}
                          setValue={setValue}
                          name='state'
                          isSearch={false}
                          data={STATES_LIST}
                          selectedOption={STATES_LIST.find(
                            (x) =>
                              x.value.toLowerCase() ===
                              watchAllFields?.state?.toLowerCase()
                          )}
                          errorMessage={
                            errors?.state?.type === "required" ? (
                              <div className='text-sm text-red-500'>
                                This field is required!
                              </div>
                            ) : null
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor=''
                        className='block text-sm font-medium text-gray-700'
                      >
                        Postcode
                      </label>
                      <div className='relative mt-1'>
                        <input
                          type='number'
                          name='postcode'
                          id='postcode'
                          minLength={4}
                          maxLength={4}
                          className='did-floating-input '
                          {...register("postcode", {
                            // required: true,
                            maxLength: 4,
                            minLength: 4,
                            onChange: (e) => {
                              e.target.value = e.target.value
                                .toString()
                                .slice(0, 4);
                            },
                          })}
                        />
                        {errors?.postcode?.type === "maxLength" ? (
                          <span className='text-red-500 text-sm'>
                            Postcode must be 4 digits
                          </span>
                        ) : errors?.postcode?.type === "minLength" ? (
                          <span className='text-red-500 text-sm'>
                            Postcode must be 4 digits
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='comment'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Quick Snapshot
                      </label>
                      <div className='mt-1'>
                        <textarea
                          rows={3}
                          name='comment'
                          id='comment'
                          className='px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md resize-none overflow-y-auto v ln lk'
                          {...register("quickSnapshot", {
                            //  required: true
                          })}
                        />
                        {errors.quickSnapshot && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                        {/* <span className="text-gray-500 text-sm">275 characters left</span> */}
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor=''
                        className='block text-sm font-medium text-gray-700'
                      >
                        Status Description{" "}
                        <label className='text-gray-500 text-xs font-normal mb-0'>
                          (max. 256)
                        </label>
                      </label>
                      <div className='mt-1'>
                        <input
                          type='text'
                          name=''
                          id=''
                          className='did-floating-input'
                          {...register("status_description", {
                            // required: true,
                            maxLength: 254,
                          })}
                        />
                        {errors.status_description && (
                          <span className='text-red-500'>Limit exceeded.</span>
                        )}
                      </div>
                    </div>

                    {roleAccess(
                      [],
                      PERMISSION_KEYS.PARTICIPANT,
                      PERMISSION_KEYS_CHILDREN.PARTICIPANT
                        .EMERGENCY_CONTACT_NAME
                    ) && (
                      <div>
                        <label
                          htmlFor='name'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Emergency Contact Name
                        </label>
                        <div className='mt-1'>
                          <input
                            type='text'
                            name='name'
                            id='name'
                            className='did-floating-input'
                            {...register("emergencyContactName", {
                              // required: true,
                              maxLength: 254,
                            })}
                          />
                          {errors.emergencyContactName && (
                            <span className='text-red-500'>
                              This field is required
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {roleAccess(
                      [],
                      PERMISSION_KEYS.PARTICIPANT,
                      PERMISSION_KEYS_CHILDREN.PARTICIPANT
                        .EMERGENCY_CONTACT_PHONE
                    ) && (
                      <div>
                        <label
                          htmlFor='number'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Emergency Contact Number
                        </label>
                        <div className='mt-1'>
                          <input
                            type='number'
                            // minLength={10}
                            maxLength={10}
                            name='emergencyContactPhone'
                            id='emergencyContactPhone'
                            className='did-floating-input'
                            {...register("emergencyContactPhone", {
                              // required: true,
                              // minLength: 10,
                              maxLength: 10,
                              pattern: REGEX_PHONE_NUMBER,
                              onChange: (e) => {
                                const sanitizedValue = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                                const formattedValue = sanitizedValue.slice(
                                  0,
                                  10
                                );
                                e.target.value = formattedValue;
                              },
                            })}
                          />
                          {errors?.emergencyContactPhone?.type ===
                          "required" ? (
                            errors.emergencyContactPhone && (
                              <span className='text-red-500'>
                                Please enter a valid phone number with the max
                                length of 10
                              </span>
                            )
                          ) : (
                            <>
                              {errors.emergencyContactPhone?.type ===
                                "pattern" && (
                                <span className='text-red-500'>
                                  {/* Phone number must start with 04, 1300 or 1800 */}
                                  Please enter a valid phone number!
                                </span>
                              )}
                              {errors.emergencyContactPhone?.type ===
                                "minLength" && (
                                <span className='text-red-500'>
                                  Please enter a valid phone number with the min
                                  length of 10
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Primary Disability */}
                    <div>
                      <div className='hidden'>
                        <select
                          {...register("primary_disability", {
                            // required: true,
                          })}
                          name='primary_disability'
                          id=''
                          className='hidden did-floating-input'
                        />
                      </div>
                      <label className='mb-1 block text-sm font-medium text-gray-700'>
                        Primary Disability
                      </label>
                      <SelectOption
                        // isMulti={true}
                        isSearch={false}
                        data={PRIMARY_DISABILITY_OPTIONS}
                        selectedOption={primaryDisability}
                        handleChange={setPrimaryDisability}
                      />
                      {!primaryDisability &&
                        errors?.primary_disability?.type === "required" && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                    </div>
                    {primaryDisability &&
                      primaryDisability !== null &&
                      primaryDisability.value === "other" && (
                        <div>
                          <label
                            htmlFor=''
                            className='block text-sm font-medium text-gray-700'
                          >
                            Other Primary Disability
                          </label>
                          <div className='mt-1'>
                            <input
                              type='text'
                              name=''
                              id=''
                              className='did-floating-input'
                              {...register("primary_disability", {
                                required: true,
                                maxLength: 254,
                              })}
                            />
                            {primaryDisability &&
                              errors?.primary_disability?.type ===
                                "required" && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                          </div>
                        </div>
                      )}

                    {/* Secondary Disability */}
                    <div>
                      <div className='hidden'>
                        <select
                          {...register("secondary_disability", {
                            // required: true,
                          })}
                          name='secondary_disability'
                          id=''
                          className='hidden did-floating-input'
                        />
                      </div>
                      <label className='mb-1 block text-sm font-medium text-gray-700'>
                        Secondary Disability
                      </label>
                      <SelectOption
                        // isMulti={true}
                        isSearch={false}
                        data={PRIMARY_DISABILITY_OPTIONS}
                        selectedOption={secondaryDisability}
                        handleChange={setSecondaryDisability}
                      />
                      {!secondaryDisability &&
                        errors?.secondary_disability?.type === "required" && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                    </div>
                    {secondaryDisability &&
                      secondaryDisability !== null &&
                      secondaryDisability.value === "other" && (
                        <div>
                          <label
                            htmlFor=''
                            className='block text-sm font-medium text-gray-700'
                          >
                            Other Secondary Disability
                          </label>
                          <div className='mt-1'>
                            <input
                              type='text'
                              name=''
                              id=''
                              className='did-floating-input'
                              {...register("secondary_disability", {
                                required: true,
                                maxLength: 254,
                              })}
                            />
                            {secondaryDisability &&
                              errors?.secondary_disability?.type ===
                                "required" && (
                                <span className='text-red-500'>
                                  This field is required
                                </span>
                              )}
                          </div>
                        </div>
                      )}

                    {/* <div>
                      <label
                        htmlFor=''
                        className='block text-sm font-medium text-gray-700'
                      >
                        Email for Invoice
                      </label>
                      <div className='mt-1'>
                        <input
                          type='text'
                          name=''
                          id=''
                          className='did-floating-input'
                          {...register("email", {
                            // required: false,
                            maxLength: 254,

                            pattern: EMAIL_REGEX_EXP,
                          })}
                        />
                        {errors.required
                          ? errors.email && (
                              <span className='text-red-500'>
                                This field is required
                              </span>
                            )
                          : errors?.email?.type === "pattern" && (
                              <span className='text-red-500'>
                                Invalid email address
                              </span>
                            )}
                      </div>
                    </div> */}

                    <div>
                      <label
                        htmlFor='location'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Timezone
                      </label>
                      <div className='relative mt-1'>
                        <select
                          id='location'
                          name='location'
                          className='shadow-sm border mt-1 block w-full pl-8 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:bg-[#4b6bb710] focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
                          defaultValue=''
                          {...register("timeZone", {
                            //  required: true
                          })}
                        >
                          <option value={"Australia/Perth"}>
                            Australian Western Standard Time (AWST) UTC +8
                          </option>
                          <option value={"Australia/Eucla"}>
                            Australian Central Western Standard Time (ACWST) UTC
                            +8:45
                          </option>
                          <option value={"Australia/Adelaide"}>
                            Australian Central Standard Time (ACST) UTC +9:30
                          </option>
                          <option value={"Australia/Melbourne"}>
                            Australian Eastern Standard Time (AEST) UTC +10
                          </option>
                          <option value={"Australia/Hobart"}>
                            Lord Howe Standard Time (LHST) UTC +10:30
                          </option>
                        </select>
                        {errors.timeZone && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                        <div className='absolute top-1/2 -translate-y-1/2 left-3'>
                          <AiOutlineClockCircle />
                        </div>
                      </div>
                    </div>
                    {/* <SelectBox /> */}
                    <div>
                      <div className='hidden'>
                        <select
                          {...register("plan", {
                            value: false,
                            // required: true,
                          })}
                          name='plan'
                          id=''
                          className='hidden did-floating-input'
                          defaultValue={false}
                        />
                      </div>
                      <label className='mb-1 block text-sm font-medium text-gray-700'>
                        Plan Managed By
                      </label>
                      <SelectOption
                        isSearch={false}
                        isMulti={false}
                        data={planManagedByOptions}
                        selectedOption={planBy}
                        handleChange={setPlanBy}
                      />
                      {errors.plan && (
                        <span className='text-red-500'>
                          This field is required
                        </span>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor=''
                        className='block text-sm font-medium text-gray-700'
                      >
                        Plan Start Date
                      </label>
                      <div className='mt-1'>
                        <input
                          min={moment().format("DD-MM-YYYY")}
                          type='date'
                          name=''
                          id=''
                          className='did-floating-input'
                          {...register("planStartDate", {
                            required: false,
                            validate: (value) =>
                              value
                                ? Date.parse(value) <
                                  Date.parse(watchAllFields.planEndDate)
                                : undefined,
                          })}
                        />
                        {errors?.planStartDate?.type === "required" && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                        {errors?.planStartDate?.type === "validate" && (
                          <span className='text-red-500'>
                            Start Date should be less than End Date
                          </span>
                        )}
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor=''
                        className='block text-sm font-medium text-gray-700'
                      >
                        Plan End Date
                      </label>
                      <div className='mt-1'>
                        <input
                          min={moment().format("DD-MM-YYYY")}
                          type='date'
                          name=''
                          id=''
                          className='did-floating-input'
                          {...register("planEndDate", {
                            required: false,
                          })}
                        />
                        {errors.planEndDate && (
                          <span className='text-red-500'>
                            This field is required &nbsp;
                          </span>
                        )}
                        {errors?.planStartDate?.type === "validate" && (
                          <span className='text-red-500'>
                            End date should be greater than start date
                          </span>
                        )}
                      </div>
                    </div>
                  

                    <div>
                      <label className='mb-1 block text-sm font-medium text-gray-700'>
                        Assigned Workers
                      </label>
                      <SelectOption
                        isMulti={true}
                        data={
                          assignedWorkers && assignedWorkers.length > 0
                            ? workers.filter((x) =>
                                assignedWorkers.find((y) => y.value !== x.value)
                              )
                            : workers
                        }
                        selectedOption={assignedWorkers}
                        handleChange={(data) => {
                          const diffArray = getDifferenceArray(
                            assignedWorkers,
                            data
                          );
                          setAssignedSupportCoordinators(
                            getDifferenceArray(
                              assignedSupportCoordinators,
                              diffArray
                            )
                          );

                          setAssignedWorkers(data);
                        }}
                      />
                    </div>

                    {/* <div>
                      <div className='hidden'>
                        <select
                          {...register("plan", {
                            value: false,
                            // required: true,
                          })}
                          name='plan'
                          id=''
                          className='hidden did-floating-input'
                          defaultValue={false}
                        />
                      </div>
                      <label className='mb-1 block text-sm font-medium text-gray-700'>
                        Plan Managed By
                      </label>
                      <SelectOption
                        isSearch={false}
                        isMulti={false}
                        data={planManagedByOptions}
                        selectedOption={planBy}
                        handleChange={setPlanBy}
                      />
                      {errors.plan && (
                        <span className='text-red-500'>
                          This field is required
                        </span>
                      )}
                    </div> */}

                    <div className='col-span-1 sm:col-span-1'>
                      {regionList?.length > 0 && (
                        <>
                          <label className='block text-sm font-medium text-gray-700'>
                            Region
                          </label>
                          <MultiLevelDropdown
                            regions={regionList}
                            setSelectedItem={setSelectedItems}
                            selectedItems={selectedItems}
                            parentRegionLoader={loader}
                          />
                        </>
                      )}
                    </div>
                    {/* <div>
                      <label
                        htmlFor='comment'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Important Alert <span className='text-red-500'>* </span>
                        <label className='text-gray-500 text-xs font-normal mb-0'>
                          (max. 256)
                        </label>
                      </label>

                      <div className='mt-1'>
                        <textarea
                          rows={1}
                          name='important_alert'
                          id='important_alert'
                          className='mb-0 w-full text-gray-900 border border-gray-300 font-normal text-base rounded-md'
                          {...register("important_alert", {
                            //  required: true
                          })}
                        />
                        {errors.important_alert && (
                          <span className='text-red-500'>Limit exceeded.</span>
                        )}
                    
                      </div>
                    </div> */}
                  </div>
                  <div>
                    <label
                      htmlFor='comment'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Important Alert {" "}
                      {/* <span className='text-red-500'>* </span> */} 
                      <label className='text-gray-500 text-xs font-normal mb-0'>
                         (max. 256)
                      </label>
                    </label>

                    <div className='mt-1'>
                      <textarea
                        rows={1}
                        name='important_alert'
                        id='important_alert'
                        className='mb-0 w-full text-gray-900 border border-gray-300 font-normal text-base rounded-md'
                        {...register("important_alert", {
                          //  required: true
                        })}
                      />
                      {errors.important_alert && (
                        <span className='text-red-500'>Limit exceeded.</span>
                      )}
                      {/* <span className="text-gray-500 text-sm">275 characters left</span> */}
                    </div>
                  </div>
                  <div className='border border-gray-200 py-7 px-6 rounded-lg mt-2'>
                    <div>
                      <label className='text-gray-700 text-lg font-bold '>
                        Pricing
                      </label>
                    </div>
                    <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                      <div>
                        <div className='hidden'>
                          <select
                            {...register("state_id", {
                              //value: false,
                              required: true,
                            })}
                            name='state_id'
                            id=''
                            className='hidden did-floating-input'
                            defaultValue={false}
                          />
                        </div>
                        <label className='block mb-1 text-sm font-medium text-gray-700'>
                          Service Catalogue State{" "}
                          <span className='text-red-600'>*</span>
                        </label>
                        <SelectOption
                          isSearch={false}
                          data={catalogStates}
                          selectedOption={catalogState}
                          handleChange={setCatalogState}
                        />
                        {errors.state_id && (
                          <span className=' text-sm text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>

                      <div>
                        <div className='hidden'>
                          <select
                            {...register("roster_invoicing_item", {
                              required: true,
                            })}
                            name='roster_invoicing_item'
                            id=''
                            className='hidden did-floating-input'
                          />
                        </div>
                        <label className='mb-1 block text-sm font-medium text-gray-700'>
                          Category Item Name
                        </label>
                        <SelectOption
                          // isMulti={true}
                          isSearch={false}
                          data={CATEGORY_ITEM_NAME_AND_NOTES}
                          selectedOption={rosterInvoicing}
                          handleChange={setRosterInvoicing}
                        />
                        {!rosterInvoicing &&
                          errors?.roster_invoicing_item?.type ===
                            "required" && (
                            <span className='text-red-500'>
                              This field is required
                            </span>
                          )}
                      </div>

                      <div>
                        <div className='hidden'>
                          <select
                            {...register("invoice_category_id", {
                              //value: false,
                              required: true,
                            })}
                            name='invoice_category_id'
                            id=''
                            className='hidden did-floating-input'
                            defaultValue={false}
                          />
                        </div>
                        <label className='block mb-1 text-sm font-medium text-gray-700'>
                          Service Category
                          <span className='text-red-600'>*</span>
                        </label>
                        <SelectOption
                          isDisabled={isFetchServiceCatLoader}
                          loading={isFetchServiceCatLoader}
                          isSearch={false}
                          data={serviceTypes}
                          selectedOption={
                            serviceTypes?.find(
                              (o) => o.id === watchFormData?.invoice_category_id
                            ) || null
                          }
                          handleChange={(e) =>
                            setValue("invoice_category_id", e.value)
                          }
                        />
                        {errors.invoice_category_id && (
                          <span className=' text-sm text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>

                      <div className='col-span-2'>
                        <label className='text-gray-700 text-sm font-medium mb-1'>
                          Emails for Invoice (Enter one email, press "Enter" to
                          add another. repeat for more emails.)
                        </label>

                        <div className='mb-2'>
                          <ReactMultiEmail
                            placeholder='Input your emails'
                            emails={emails}
                            onChange={(_emails) => {
                              setEmails(_emails);
                            }}
                            autoFocus={true}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            getLabel={(email, index, removeEmail) => {
                              return (
                                <div data-tag key={index}>
                                  <div data-tag-item>{email}</div>
                                  <span
                                    data-tag-handle
                                    onClick={() => removeEmail(index)}
                                  >
                                    ×
                                  </span>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* For Xero */}
                  {isCheckPayrollAuthLoading ? (
                    <Loader isSmallView={true} cssClass={"text-black"} />
                  ) : (
                    <div className='border border-gray-200 py-7 px-6 rounded-lg mt-2'>
                      <div>
                        <label className='pb-2 text-gray-700 text-lg font-bold '>
                          Payroll
                        </label>
                      </div>
                      <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                        <div className='col-span-2'>
                          <label className='text-gray-700 text-sm font-medium mb-1'>
                            Xero Contact ID
                          </label>
                          {isAuthenticateForPayrolls !== null &&
                          parseBooleanVal(
                            isAuthenticateForPayrolls[PAYROLL_PLATFORMS.XERO]
                          ) ? (
                            <div className='w-full flex space-x-2'>
                              <div className='w-[95%]'>
                                <input
                                  type='text'
                                  name='name'
                                  id='name'
                                  className='did-floating-input'
                                  {...register("xero_contact_id", {
                                    // required: true,
                                    maxLength: 254,
                                  })}
                                />
                                {errors.xero_contact_id && (
                                  <span className='text-red-500'>
                                    This field is required
                                  </span>
                                )}
                              </div>

                              <div className='w-[5%] flex justify-center items-center'>
                                <span
                                  onClick={() => setXeroImage(true)}
                                  title='xero employee id'
                                  className='h-6 w-6 flex  cursor-pointer  justify-center items-center rounded-full bg-[#3E5A99] text-white'
                                >
                                  <AiOutlineQuestion />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              onClick={() => navigate("/payroll-setting")}
                              className='mt-1 text-sm text-gray-900 flex items-center space-x-2 cursor-pointer'
                            >
                              <span className='text-blue-700'>
                                Connect Xero
                              </span>
                              <span className='text-blue-600'>
                                <FiArrowUpRight />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className='px-4 py-5 bg-white space-y-6 sm:p-6 '>
                  {addBlockDiv()}

                  <div className='flex gap-x-4'>
                    <SwitchInput
                      handleChange={setEnabled}
                      checked={enabled}
                      label='Is active'
                      subLabel='is the participant active'
                    />

                    <SwitchInput
                      handleChange={setSupportCoordination}
                      checked={supportCoordination}
                      label='Support Coordination'
                      subLabel='Require Support Coordination Services'
                    />
                  </div>
                  {supportCoordination && (
                    <>
                      <div>
                        <div className='hidden'>
                          <select
                            {...register("support_coordinators", {
                              // required: false,
                            })}
                            name='support_coordinators'
                            id=''
                            className='hidden did-floating-input'
                          />
                        </div>
                        <label className='mb-1 block text-sm font-medium text-gray-700'>
                          Assigned Support Coordinators
                        </label>
                        <SelectOption
                          isMulti={true}
                          data={
                            assignedSupportCoordinators &&
                            assignedSupportCoordinators.length > 0
                              ? supportCoordinators.filter((x) =>
                                  assignedSupportCoordinators.find(
                                    (y) => y.value !== x.value
                                  )
                                )
                              : supportCoordinators
                          }
                          selectedOption={assignedSupportCoordinators}
                          handleChange={(data) => {
                            const diffArray = getDifferenceArray(
                              assignedSupportCoordinators,
                              data
                            );
                            setAssignedWorkers(
                              getDifferenceArray(assignedWorkers, diffArray)
                            );
                            setAssignedSupportCoordinators(data);
                          }}
                        />
                        {errors.support_coordinators && (
                          <span className='text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>
                    </>
                  )}
                  <div className='py-4 border-b border-gray-300'></div>
                  {roleAccess(
                    [],
                    PERMISSION_KEYS.PARTICIPANT,
                    PERMISSION_KEYS_CHILDREN.PARTICIPANT.SETTING.UPDATE
                  ) && (
                    <div className='px-4 py-3 bg-gray-50 text-right sm:px-6 space-x-4'>
                      <CustomButton
                        showLoading={isLoading}
                        label='Save Changes'
                        isDisabled={isLoading || isUserGracePeriodOver}
                        type='submit'
                      />
                    </div>
                  )}
                  {roleAccess(
                    [],
                    PERMISSION_KEYS.PARTICIPANT,
                    PERMISSION_KEYS_CHILDREN.PARTICIPANT.DELETE
                  ) && (
                    <>
                      <div className='py-4 border-b border-gray-300'></div>
                      <div className='pt-4 flex justify-between items-center'>
                        <div>
                          <h4 className='text-red-600 font-bold'>
                            Delete Participant
                          </h4>
                          <p className='text-sm text-gray-600'>
                            This will permanently delete the user, and all their
                            data will be removed from the system.
                          </p>
                        </div>

                        <CustomButton
                          type='button'
                          variant='danger'
                          cssClass='px-3 py-2 text-right sm:px-2 space-x-4'
                          label='Delete Participant'
                          clickHandler={() => setIsShowModal(true)}
                        />
                      </div>
                      <div className='py-4 border-b border-gray-300'></div>
                    </>
                  )}

                  {isShowModal && (
                    <DeleteParticipantModal
                      isUserGracePeriodOver={isUserGracePeriodOver}
                      handleClose={() => setIsShowModal(false)} // Close modal on close
                      isShow={isShowModal} // Show the modal when isShowModal is true
                      participantId={uuid} // Uncomment and pass participant ID if needed
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      {showXeroImage && (
        <ImageDisplayModal
          isOpen={showXeroImage}
          setOpen={setXeroImage}
          type={PAYROLL_PLATFORMS.XERO}
          forCall='xero_contact'
          customTitle='Xero Contact ID'
        />
      )}
    </div>
  );
};

export default Settings;
