import { AiOutlineUsergroupAdd, AiOutlineAlert } from "react-icons/ai";
import { RiCustomerService2Fill, RiDashboardLine } from "react-icons/ri";
import { MdOutlineDocumentScanner, MdOutlinePolicy } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";
import { PERMISSION_KEYS } from "store/constants";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { USER_ROLE } from "Constants/constant";

export const SpStandardUserNavigation = [
  {
    name: "Dashboard",
    icon: RiDashboardLine,
    current: false,
    to: "/dashboard",
    // permissionName: PERMISSION_KEYS.DASHBOARD,
  },
  {
    name: "Participants",
    icon: AiOutlineUsergroupAdd,
    current: false,
    to: "/participants",
    permissionName: PERMISSION_KEYS.PARTICIPANT,
  },
  {
    name: "My Documents",
    icon: MdOutlineDocumentScanner,
    current: false,
    to: "/documents",
    permissionName: PERMISSION_KEYS.DOCUMENT,
  },
  {
    name: "Submitted Forms",
    icon: MdOutlineDocumentScanner,
    current: false,
    to: "/submitted-forms",
    permissionName: PERMISSION_KEYS.SUBMITTED,
  },
  {
    name: "Support Coordination",
    icon: RiCustomerService2Fill,
    current: "/cos/",
    to: "/cos/dashboard",
    permissionName: PERMISSION_KEYS.COS,
  },
  {
    name: "Policies",
    icon: MdOutlinePolicy,
    current: false,
    to: "/employee-policies",
    permissionName: PERMISSION_KEYS.POLICy,
  },
  {
    name: "Complaint",
    icon: AiOutlineAlert,
    current: false,
    to: "/complaint",
    permissionName: PERMISSION_KEYS.COMPLAINT,
  },
  {
    name: "Feedback",
    icon: VscFeedback,
    current: false,
    to: "/feedback",
    permissionName: PERMISSION_KEYS.FEEDBACK,
  },
  {
    name: "Ask a Question",
    icon: AiOutlineQuestionCircle,
    current: false,
    to: "/ask-a-question",
    permissionName: PERMISSION_KEYS.ASK_QUESTION,
  },
];
