import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import { authActions, generalActions } from "../actions";
import api from "../services/auth.services";
import ACTIONS from "../constants";
import { TOAST_TYPE } from "Constants/constant";
import { groupByPermissionsByName } from "Utils/utils";
import GetItemFromLocalstorage from "Utils/GetItemFromLocalstorage";

function* login(state) {
  // try {
  //   yield put(authActions.loginAttempt.pending);
  //   const response = yield call(api.loginRequest, state.payload);
  //   if (response.access) {
  //     yield localStorage.setItem("accessToken", response.access);
  //     const userData = yield call(api.getProfile);
  //     userData.access = response.access;
  //     userData.refresh = response.refresh;
  //     yield localStorage.setItem("user", JSON.stringify(userData));
  //     yield put(authActions.loginAttempt.success(response));
  //   }
  // } catch (error) {
  //   yield put(authActions.loginAttempt.error(error));
  // }
}

function* logout() {
  try {
    yield put(authActions.logoutAttempt.pending);
    yield call(api.logoutRequest);
    yield localStorage.clear();
    yield sessionStorage.clear();
    yield put(authActions.logoutAttempt.success());
  } catch (error) {
    yield localStorage.clear();
    yield sessionStorage.clear();
    yield put(authActions.logoutAttempt.error());
  }
}

function* reSendOTPSaga(state) {
  try {
    yield put(authActions.reSendOTP.pending);
    const response = yield call(api.resendOTPApi, state.payload);
    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "OTP Sent Successfully!",
        })
      );
      yield put(authActions.reSendOTP.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(authActions.reSendOTP.error(error));
  }
}

function* sendOTPForConfirmSaga(state) {
  try {
    yield put(authActions.sendOTPForConfirmation.pending);

    const response = yield call(api.sendOTPForConfirmationApi, state.payload);
    if (response) {
      yield localStorage.setItem("2FARequired", true);
      yield localStorage.setItem("step", "3");
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "OTP Verified Successfully!",
        })
      );
      yield put(authActions.sendOTPForConfirmation.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(authActions.sendOTPForConfirmation.error(error));
  }
}

function* sendOTPSaga(state) {
  try {
    yield put(authActions.sendOTP.pending);
    const response = yield call(api.sendOTPApi, state.payload);
    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "OTP Sent Successfully!",
        })
      );
      localStorage.setItem("attempt", "0");
      if (state.payload.phone) {
        localStorage.setItem("step", "2");
        localStorage.setItem("2FASetup", state.payload.phone);
        state.payload.navigate("/confirm-otp");
      }
      if (localStorage.getItem("2FASetup") === "null") {
        if (
          localStorage.getItem("2FASetup") === "null" &&
          localStorage.getItem("2FARequired") === "false" &&
          state.payload.via === "phone"
        ) {
          // alert("step 1");
          localStorage.setItem("step", "1");
          state.payload.navigate(`/setup-otp`);
        } else if (
          localStorage.getItem("2FASetup") === "null" &&
          localStorage.getItem("2FARequired") === "false"
          //  &&    state.payload.via === "email"
        ) {
          // alert("step 2");
          localStorage.setItem("step", "2.2");
          state.payload.navigate("/verify-otp");
        } else {
          // alert("step 3");
          localStorage.setItem("step", false);
          state.payload.navigate("/dashboard");
        }
      }
      if (localStorage.getItem("step") === "1") {
        localStorage.setItem("step", "2.2");
        state.payload.navigate("/verify-otp");
      }

      yield put(authActions.sendOTP.success(response));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error.message,
      })
    );
    yield put(authActions.sendOTP.error(error));
  }
}

function* resendEmailVerificatioCode(state) {
  try {
    yield put(authActions.resendEmailVerificatioCode.pending);
    const response = yield call(api.resendEmailVerificatioCode, state.payload);

    if (response) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title:
            response?.message ??
            "Email verification code successfully send, Check your email",
        })
      );
      yield put(authActions.resendEmailVerificatioCode.success(response));
      if (state?.callback) {
        state.callback();
      }
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something went wrong!",
      })
    );
    yield put(authActions.resendEmailVerificatioCode.error(error));
  }
}

function* roleAndPermissionCreateOrUpdate(state) {
  try {
    yield put(authActions.roleAndPermisisonCreatedOrUpdated.pending);
    const response = yield call(
      api.roleAndPermissionCreateOrUpdateApi,
      state.payload
    );
    if (response && response.data) {
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title:
            response?.message ??
            `Role and their permissions ${
              state.payload.hasOwnProperty("id") ? "updated" : "created"
            } successfully!`,
        })
      );
      yield put(
        authActions.roleAndPermisisonCreatedOrUpdated.success(
          response.data,
          state.payload.hasOwnProperty("id")
        )
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something went wrong!",
      })
    );
    yield put(authActions.roleAndPermisisonCreatedOrUpdated.error(error));
  }
}

function* roleAndPermissionList(state) {
  try {
    yield put(authActions.rolesAndPermissionslist.pending);
    const response = yield call(api.roleAndPermissionList, state.payload);
    if (response && response.data) {
      yield put(authActions.rolesAndPermissionslist.success(response.data));
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something went wrong!",
      })
    );
    yield put(authActions.rolesAndPermissionslist.error(error));
  }
}

function* defaultRoleAndPermission(state) {
  try {
    yield put(authActions.defaultRolesAndPermissions.pending);
    const response = yield call(api.defaultRoleAndPermission, state.payload);
    if (response) {
      const data = [
        ...response?.catagorizePermission,
        ...response.generalPermission,
      ].map((item) => ({
        ...item,
        isChecked: true,
        children: item?.children?.map((y) => ({ ...y, isChecked: true })) || [],
      }));

      yield put(
        authActions.defaultRolesAndPermissions.success({
          permissions: data,
          role: response?.role ?? null,
        })
      );
    }
  } catch (error) {
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error?.message ?? "Something went wrong!",
      })
    );
    yield put(authActions.defaultRolesAndPermissions.error(error));
  }
}

function* roleAuthenticateUser() {
  try {
    const response = yield call(api.roleAuthenticateUser);
    if (response && response.data) {
      const groupedPermissions = yield groupByPermissionsByName(
        response?.data?.permissions || []
      );
      let user = yield GetItemFromLocalstorage("user");
      yield (user.permissions = groupedPermissions);
      yield (user.role_id = response.data.role_id);
      yield localStorage.setItem("user", JSON.stringify(user));
      yield put(authActions.authenticatedUser(user));
      const customEvent = yield new Event("refreshPermissions");
      window.dispatchEvent(customEvent);
    }
  } catch (error) {
    console.log("error", error?.message || error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.RESEND_OTP_CALL, reSendOTPSaga),
    takeEvery(ACTIONS.SEND_OTP_FOR_CONFIRMATION_CALL, sendOTPForConfirmSaga),
    takeEvery(ACTIONS.SEND_OTP_CALL, sendOTPSaga),
    takeEvery(ACTIONS.LOGIN_CALL, login),
    // takeEvery(ACTIONS.LOGOUT_BEGIN, logout)
    takeEvery(ACTIONS.LOGOUT_BEGIN, logout),
    takeEvery(
      ACTIONS.RESEND_VERIFICATION_CODE_CALL,
      resendEmailVerificatioCode
    ),
    takeLatest(
      ACTIONS.ROLE_AND_PERMISSION_CREATE_OR_UPDATE_CALL,
      roleAndPermissionCreateOrUpdate
    ),
    takeEvery(ACTIONS.ROLE_AND_PERMISSION_LIST_CALL, roleAndPermissionList),
    takeEvery(
      ACTIONS.DEFAULT_ROLE_AND_PERMISSION_CALL,
      defaultRoleAndPermission
    ),
    takeLatest(ACTIONS.ROLE_AUTHENTICATE_USER_CALL, roleAuthenticateUser),
  ]);
}
