import React from "react";
import moment from "moment";
import { BsPlusLg } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import TableHeader from "components/common/Table/TableHeader";

const FundingSubTable = (props) => {
  const { item } = props;
  const [expandedRows, setExpandedRows] = React.useState([]);

  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(index)) {
      const indexToRemove = newExpandedRows.indexOf(index);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };

  return (
    <>
      <tr className='text-center even:bg-white odd:bg-gray-50'>
        <td
          className='flex justify-center items-center h-[50px]  cursor-pointer'
          onClick={() => handleRowClick(item.uuid)}
        >
          <div className='flex align-center cursor-pointer '>
            {expandedRows.includes(item.uuid) ? (
              <BiMinus className='mt-1 text-gray-600' />
            ) : (
              <BsPlusLg className='h-3 w-3 mt-1 text-gray-600' />
            )}
          </div>
        </td>

        <td className=' px-3 py-4 text-sm text-gray-500 text-start capitalize'>
          <div className='text-gray-500 capitalize'>
            {item?.service_contract_ref_no}{" "}
          </div>
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {`${item?.participant?.first_name} ${item?.participant?.last_name}`}
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {item?.start_date
            ? moment(item?.start_date).format("DD/MM/YYYY")
            : "-"}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {item?.end_date ? moment(item?.end_date).format("DD/MM/YYYY") : "-"}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {/* {
          item?.total_service_contract_amount || "-"
        } */}
          {item?.total_service_contract_amount
            ? `$${item?.total_service_contract_amount}`
            : "-"}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {item?.amount_remaining ? `$${item?.amount_remaining}` : "-"}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {item?.amount_delivered ? `$${item?.amount_delivered}` : "-"}
        </td>
      </tr>

      {expandedRows.includes(item.uuid) && (
        <tr className='text-center '>
          <td colSpan={10} className='px-3 py-4'>
            <table className='min-w-full divide-y divide-gray-300 border'>
              <thead>
                <tr>
                  <TableHeader
                    isSort={false}
                    label={"Plan Category"}
                    cssClass='text-center text-xs'
                  />
                  <TableHeader
                    isSort={false}
                    label={"Support Item Name"}
                    cssClass='text-center text-xs'
                  />
                  <TableHeader
                    isSort={false}
                    label={"External Service Provider"}
                    cssClass='text-center text-xs'
                  />

                  <TableHeader
                    isSort={false}
                    label={"Total Budget Amount"}
                    cssClass='text-center text-xs'
                  />
                  <TableHeader
                    isSort={false}
                    label={"Total Delivered"}
                    cssClass='text-center text-xs'
                  />

                  <TableHeader
                    isSort={false}
                    label={"Remaining Amount"}
                    cssClass='text-center text-xs'
                  />
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-whit'>
                {item?.categories && item?.categories?.length > 0 ? (
                  item?.categories?.map((el, index) => (
                    <tr key={index}>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.service_category?.name || "-"}
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.service_sub_category?.title || "-"}
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.sp_category_budget &&
                          el?.sp_category_budget?.external_sp
                            ? el?.sp_category_budget?.external_sp?.name
                            : "-"}
                        </div>
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        {el?.total_budget_amount
                          ? `$${el?.total_budget_amount}`
                          : "-"}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        {el?.amount_delivered
                          ? `$${el?.amount_delivered}`
                          : "-"}
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        {el?.amount_remaining
                          ? `$${el?.amount_remaining}`
                          : "-"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className='py-6 text-sm text-center text-red-600'
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default FundingSubTable;
