import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

export const DropdownItem = (props) => {
  const { clickHandler, item, type, isUserGracePeriodOver } = props;

  const handleClick = () => {
    clickHandler(item.label);
  };
  return (
    <Menu.Item>
      <button
        onClick={() => !isUserGracePeriodOver && handleClick(item)}
        className='text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm'
      >
        <div className=' mr-2' title='Edit'>
          {item.icon}
        </div>
        {item.label}
      </button>
    </Menu.Item>
  );
};
