import TwoFANotEnabledPage from "./TwoFANotEnabledPageOTPVarified";
import { connect } from "react-redux";
// import {} from "../../../../store/actions/participant.actions";
// import {} from "../../../../store/selector/participant.selector";

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFANotEnabledPage);
