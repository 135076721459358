import React from 'react'

const Progressbar = ({compliance=0,title}) => {

  const progressBar=100-compliance;
  return (
    <>
    <div className="flex items-center">
    <div
  className={`${
    title === "Participants Compliance" ? "bg-[#b157bd]" : "bg-green-400"
  } h-1 rounded-l-full`}
  style={{ width: `${compliance}%` }}
></div>  <div className="bg-yellow-400 h-1 rounded-r-full" style={{width: `${progressBar}%`}}></div>
  
</div>
<p className="ml-2 text-sm text-gray-600 text-end">{compliance}%</p>
</>
  )
}

export default Progressbar
