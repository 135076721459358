import React, { useState, useEffect, useMemo } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "components/common/Button/CustomButton";
import SelectOption from "components/common/Select/Select";
import SideBar from "components/common/SideBar/SideBarNew";
import TableHeader from "components/common/Table/TableHeader";
import { LOADER_STATUS } from "Constants/constant";
import { LoginContext } from "helpers/LoginContext";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import NdisClaimNewTableItem from "./NdisClaimNewTableItem";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import NdisClaimNewModal from "./NdisClaimNewModal";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import CheckBox from "components/common/checkBox/checkbox";

const NdisClaimNew = (props) => {
  const {
    createClaim,
    getClientsRequest,
    clientsList,
    supportCoordinatorsRequest,
    getCaseNotesRequest,
    caseNotesListForClaim,
    supportCoordinator,
    caseNotesListForClaimLoader,
    clientsListLoader,
    addCaseNotesToListLoading,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } =
    React.useContext(LoginContext);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [supportCoordiantion, setSupportCoordination] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const ndisClaimNewToMap = useMemo(() => {
    if (caseNotesListForClaim && caseNotesListForClaim.length > 0) {
      return caseNotesListForClaim;
    }
    return [];
  }, [caseNotesListForClaim]);

  const handleCheckboxChange = (e, id, item) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e) {
      filteredList.push(id);
      setAmount(() => parseFloat(amount) + parseFloat(item.total_amount));
      setQuantity(() => parseInt(quantity) + parseInt(item.quantity));
    } else {
      setAmount(() => parseFloat(amount) - parseFloat(item.total_amount));
      setQuantity(() => parseInt(quantity) - parseInt(item.quantity));
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    if (val) {
      const data = ndisClaimNewToMap.reduce((obj, data) => {
        Object.assign(obj, {
          totalAmount: obj["totalAmount"]
            ? obj["totalAmount"] + parseFloat(data.total_amount)
            : parseFloat(data.total_amount),
          totalQuantity: obj["totalQuantity"]
            ? obj["totalQuantity"] + parseFloat(data.quantity)
            : parseFloat(data.quantity),
        });
        return obj;
      }, {});
      setAmount(data?.totalAmount || 0);
      setQuantity(data?.quantity || 0);
      setCheckboxes(ndisClaimNewToMap.map((t) => t.id));
    } else {
      setCheckboxes([]);
      setAmount(0);
      setQuantity(0);
    }
  };

  useEffect(() => {
    if (
      ndisClaimNewToMap &&
      checkboxes &&
      checkboxes.length === ndisClaimNewToMap.length &&
      ndisClaimNewToMap.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);

  useEffect(() => {
    getClientsRequest();
    supportCoordinatorsRequest();
    //   return () => emptyTableData();
  }, []);

  useEffect(() => {
    if (!caseNotesListForClaimLoader && renderLoading !== LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
    }
  }, [caseNotesListForClaimLoader]);

  useEffect(() => {
    setRenderLoading(LOADER_STATUS.PENDING);
    setCheckboxes([]);
    getCaseNotesRequest({
      pt_id: selectedClient ? selectedClient.value : "all",
      from_date: fromDate ? fromDate : "all",
      to_date: toDate ? toDate : "all",
      cos_id: supportCoordiantion ? supportCoordiantion.value : "all",
    });
    setAmount(0);
  }, [supportCoordiantion, fromDate, selectedClient]);

  const handleOnCreateClaimList = () => {
    if (isUserGracePeriodOver) return;
    if (checkboxes && checkboxes.length > 0) {
      setIsModelOpen(true);
    }
  };

  useEffect(() => {
    if (reportType) {
      getCaseNotesRequest({
        pt_id: selectedClient ? selectedClient.value : "all",
        from_date: fromDate ? fromDate : "all",
        to_date: toDate ? toDate : "all",
        cos_id: supportCoordiantion ? supportCoordiantion.value : "all",
        report: reportType,
      });
      setReportType(null);
    }
  }, [reportType]);
  const [isOpenModel, setIsModelOpen] = useState(false);

  useEffect(() => {
    if (clientsList && clientsList) {
      clientsList.unshift({ label: "All", value: "all" });
    }
  }, [clientsList]);

  useEffect(() => {
    if (supportCoordinator && supportCoordinator) {
      supportCoordinator.unshift({ label: "All", value: "all" });
    }
  }, [supportCoordinator]);

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <main className='h-screen flex-1 bg-white'>
            <Header title='Create NDIS Claims' />
            <div className='max-w-7xl mx-auto py-3 bg-white'>
              <div className='sm:px-6 lg:px-8'>
                <Breadcrumb
                  pages={COS_BREADCRUMB(
                    COS_BREADCRUMB_PAGES.CREATE_NDIS_CLAIMS
                  )}
                />
              </div>
              <div
                className='flex justify-between items-center px-4 sm:px-8 lg:px-8 bg-white max-w-7xl mx-auto py-2 my-3 sticky z-[7]'
                style={{ top: "70px" }}
              >
                <div className='flex gap-x-2 w-[70%]'>
                  <div className='col-span-1 w-[25%]'>
                    <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                      Participant
                    </label>
                    <SelectOption
                      isClearable={true}
                      isDisabled={
                        clientsListLoader || caseNotesListForClaimLoader
                      }
                      cssClass=''
                      name='client'
                      isSearch={false}
                      data={clientsList && clientsList}
                      selectedOption={selectedClient}
                      handleChange={(e) => {
                        setSelectedClient(e);
                      }}
                      // placeHolder="Participant Name"
                    />
                  </div>
                  <div className='col-span-1 w-[30%]'>
                    <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                      Support Coordinator
                    </label>
                    <SelectOption
                      isClearable={true}
                      isDisabled={
                        clientsListLoader || caseNotesListForClaimLoader
                      }
                      cssClass=''
                      name='sp_cos_id'
                      isSearch={false}
                      data={supportCoordinator && supportCoordinator}
                      selectedOption={supportCoordiantion}
                      handleChange={(e) => {
                        setSupportCoordination(e);
                      }}
                    />
                  </div>
                  <div className='w-[30%] '>
                    <label className='block text-start text-sm font-medium text-gray-700 text-start'>
                      Select Date
                    </label>
                    <DateRangePickerNew
                      isDisabled={clientsListLoader}
                      handleCancel={() => {
                        setFromDate(null);
                        setToDate(null);
                      }}
                      handleChange={(obj) => {
                        if (obj && obj.fromDate && obj.toDate) {
                          setFromDate(obj.fromDate);
                          setToDate(obj.toDate);
                        }
                      }}
                      fromDate={fromDate}
                      toDate={toDate}
                    />
                  </div>
                </div>

                <div className='mt-5'>
                  <CustomButton
                    clickHandler={handleOnCreateClaimList}
                    Icon={<BiPlus />}
                    label='Create Claim'
                    isDisabled={
                      (checkboxes.length === 0 ? true : false) ||
                      addCaseNotesToListLoading ||
                      isUserGracePeriodOver
                    }
                  />
                  {/* <Search setSearch={setSearch} search={search} /> */}
                </div>
              </div>
              <div className='sm:px-6 lg:px-8'>
                <div className='flex flex-col'>
                  <div className='-my-2 overflow-x-auto'>
                    <div className='min-w-full py-2 align-middle'>
                      <div className='overflow-x-auto border md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead
                            className='bg-blue-50 sticky capitalize'
                            // style={{ top: "183px" }}
                          >
                            <tr>
                              <th
                                scope='col'
                                className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <CheckBox
                                  isDisabled={isUserGracePeriodOver}
                                  checked={allSelected}
                                  handleChange={(e) => {
                                    if (isUserGracePeriodOver) {
                                      return;
                                    }
                                    if (
                                      ndisClaimNewToMap &&
                                      ndisClaimNewToMap.length > 0
                                    ) {
                                      setAllSelected(e);
                                      toggleCheckboxes(e);
                                    }
                                  }}
                                />
                              </th>

                              <TableHeader isSort={false} label={"Reference"} />
                              <TableHeader
                                isSort={false}
                                label={"Support Item"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Support Item No."}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Created By"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Created Date"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Participant"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Mgmt Style"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Date Delivered"}
                              />
                              <TableHeader isSort={false} label={"Hours"} />
                              <TableHeader isSort={false} label={"Rate"} />
                              <TableHeader
                                isSort={false}
                                label={"Total Value"}
                              />
                              <TableHeader isSort={false} label={"Status"} />
                              <th>
                                <div className='sr-only'>View</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white'>
                            {(renderLoading === LOADER_STATUS.PENDING &&
                              caseNotesListForClaimLoader) ||
                            addCaseNotesToListLoading ? (
                              <tr>
                                <td colSpan={13} className='bg-gray-50 py-2'>
                                  <CircularLoader
                                    classes='flex justify-center items-center'
                                    classloader='loader-l'
                                  />
                                </td>
                              </tr>
                            ) : ndisClaimNewToMap &&
                              ndisClaimNewToMap.length > 0 ? (
                              ndisClaimNewToMap.map((item, index) => (
                                <NdisClaimNewTableItem
                                  isUserGracePeriodOver={isUserGracePeriodOver}
                                  checkboxes={checkboxes}
                                  handleCheckboxChange={(e) =>
                                    handleCheckboxChange(e, item.id, item)
                                  }
                                  item={item}
                                  key={index}
                                />
                              ))
                            ) : (
                              renderLoading !== LOADER_STATUS.NA &&
                              ndisClaimNewToMap.length === 0 && (
                                <tr>
                                  <td
                                    colSpan={13}
                                    className='py-6 text-center text-red-600'
                                  >
                                    No Data Found !
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {isOpenModel && (
        <NdisClaimNewModal
          checkboxes={checkboxes}
          createClaim={createClaim}
          amount={amount}
          quantity={quantity}
          openModel={isOpenModel}
          handleClose={setIsModelOpen}
        />
      )}
    </>
  );
};

export default NdisClaimNew;
