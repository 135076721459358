import { USER_ROLE } from "Constants/constant";
import { roleAccess } from "helpers/RolesPermission";
import { apiCall } from "./service";
import {
  URL_CATALOG_VERSION,
  CATALOG_SERVICES,
  URL_FUNDING_SETUP_CREATE,
  URL_FUNDING_SETUP_UPDATE,
  URL_FUNDING_SETUP_PARTICIPAANT,
  CATALOG_SERVICES_SUB_CATEGORY,
  URL_SELECTED_FUNDING_SETUP,
  URL_PLAN_CATAGORIES,
  URL_PLAN_BUDGET,
  URL_PLAN_BUDGET_LIST,
  URL_EXTERNAL_SP,
  URL_PLAN_BUDGET_UPDATE,
  URL_PLAN_BUDGET_CAT_AND_SUB_CAT,
  URL_SERVICE_PROVIDER_CATEGORY_PLAN_BUDGET,
  URL_SERVICE_PROVIDER_CATEGORY_PLAN_BUDGET_LIST,
  URL_SERVICE_CATEGORY,
  URL_SEARCH_CATALOG_SERVICES,
  URL_FUNDING_NOTES_LIST,
  URL_FUNDING_NOTES_LIST_UPDATE,
  URL_SERVICE_PROVIDER_CATEGORY_PLAN_BUDGET_UPDATE,
  URL_GET_CATALOG_PRICE_AGAINST_STATE,
  URL_SERVICE_SUPPORT_BUDGET_STORE,
  URL_SERVICE_SUPPORT_BUDGET_LIST,
  URL_SERVICE_SUPPORTS_FOR_DELIVERED_SERVICE,
  URL_COS_PARTICIPANT_LIST,
  URL_SUPPORT_COORDINATOR,
  URL_DELIVERED_SERVICE_STORE,
  URL_DELIVERED_SERVICE,
  URL_CREATE_CASENOTES,
  URL_GET_CASENOTES,
  URL_GET_SERVICE_PROVIDER_SERCH_RESULT,
  URL_CREATE_CLIENT_TASKS,
  URL_UPDATE_CLIENT_TASK,
  URL_DELETE_CLIENT_TASKS,
  REPORT_DOWNLOAD_URL,
  BASE_URL,
  URL_GET_SUPPORT_TRACK_LIST,
  URL_GET_SINGLE_SUPPORT_TRACK,
  URL_GET_CASE_NOTES_CLIENTS,
  URL_GET_CASE_NOTES_HISTORY_List,
  URL_GET_CASE_NOTES_LIST_FOR_NDIS_CLAIM,
  URL_ADD_CASE_NOTES_TO_NDIS_CLAIM_LIST,
  URL_GET_CLAIMED_CASE_NOTES_LIST,
  URL_GET_CASE_NOTES_REVIEW,
  URL_GET_COS_INVOICES,
  URL_CREATE_COS_INVOICES,
  URL_GET_CREATED_COS_INVOICES,
  URL_GET_CREATED_COS_INVOICES_PREVIEW_LIST,
  URL_CHANGE_NDIS_CLAIM_ACTIONS,
  URL_CHANGE_NDIS_INVOICE_CLAIM_ACTIONS,
  URL_GEL_REPORTING,
  URL_FUNDING_SETUP_PARTICIPAANT_FOR_COS,
  URL_DASHBOARD_DATA,
  URL_GET_COS_PARTICIPANT,
  URL_MANUAL_DELIVERED_SERVICE_STORE,
  URL_MANUAL_DELIVERIES,
  URL_SERVICE_SUPPORT_BUDGET_UPDATE,
  URL_HRM_CREATE_FOLDER,
  URL_HRM_UPDATE_FOLDER,
  URL_HRM_REMOVE_FOLDER,
  URL_HRM_FOLDER_LIST,
  URL_WORKER_CREATE_FOLDER,
  URL_HRM_REMOVE_FILE,
  URL_WORKER_UPDATE_FOLDER,
  URL_WORKER_REMOVE_FOLDER,
  URL_WORKER_FOLDER_LIST,
  URL_GET_COS_CASE_NOTE_DETAIL,
  URL_WORKER_REMOVE_FILE,
  URL_UPDATE_CASE_NOTE,
  URL_PARTICIPANT_CREATE_FOLDER,
  URL_PARTICIPANT_UPDATE_FOLDER,
  URL_PARTICIPANT_REMOVE_FOLDER,
  URL_PARTICIPANT_FOLDER_LIST,
  URL_GET_FILE,
  URL_HRM_PARTICIPANT_FOLDER_LIST,
  URL_HRM_PARTICIPANT_CREATE_FOLDER,
  URL_COS_PERIORITY_SETTING_CREATE_UPDATE_COS,
  URL_CONTACT_TYPE_SETTING_CREATE_UPDATE_COS,
  URL_COS_CATEGORY_SETTING_DATA_COS,
  URL_COS_CONTACT_SETTING_DATA_COS,
  URL_COS_PRIORITY_SETTING_DATA_COS,
  URL_TASK_PRIORITY_SETTING_CREATE_UPDATE_COS,
} from "./URL";

const token = localStorage.getItem("accessToken");

export const api = {
  getFile(params) {
    return apiCall.get({
      url: `${URL_GET_FILE}?file_management_uuid=${params.uuid}`,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },
  updateCaseNotesApi(params) {
    return apiCall.post({
      url: URL_UPDATE_CASE_NOTE,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },
  getCaseNotesDetails(params) {
    return apiCall.get({
      url: `${URL_GET_COS_CASE_NOTE_DETAIL}?case_note_id=${params.uuid}&pt_id=${params.pt_id}`,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },
  getFolderLIstForHrm(params) {
    return apiCall.get({
      url:
        params.role === "participant" &&
        params &&
        params.folder_uuid &&
        !roleAccess([USER_ROLE.Sp_Standard_User])
          ? `${URL_PARTICIPANT_FOLDER_LIST}?pt_uuid=${params.uuid}&folder_uuid=${params.folder_uuid}`
          : params.role === "participant" &&
            roleAccess([USER_ROLE.Sp_Standard_User]) &&
            !params.folder_uuid
          ? `${URL_HRM_PARTICIPANT_FOLDER_LIST}?pt_uuid=${params.uuid}`
          : params &&
            params.folder_uuid &&
            params.role === "participant" &&
            params &&
            params.folder_uuid &&
            roleAccess([USER_ROLE.Sp_Standard_User])
          ? `${URL_HRM_PARTICIPANT_FOLDER_LIST}?pt_uuid=${params.uuid}&folder_uuid=${params.folder_uuid}`
          : params.role === "participant" &&
            !roleAccess([USER_ROLE.Sp_Standard_User])
          ? `${URL_PARTICIPANT_FOLDER_LIST}?pt_uuid=${params.uuid}`
          : params && params.folder_uuid
          ? `${
              roleAccess([USER_ROLE.Sp_Standard_User])
                ? URL_WORKER_FOLDER_LIST
                : URL_HRM_FOLDER_LIST
            }?worker_uuid=${params.uuid}&folder_uuid=${params.folder_uuid}`
          : `${
              roleAccess([USER_ROLE.Sp_Standard_User])
                ? URL_WORKER_FOLDER_LIST
                : URL_HRM_FOLDER_LIST
            }?worker_uuid=${params.uuid}`,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  createNewFolderApi(params) {
    if (params.role === "participant") {
      params = { ...params, pt_id: params.worker_id };
    }
    return apiCall.post({
      url:
        params.role === "participant"
          ? roleAccess([USER_ROLE.Sp_Standard_User])
            ? URL_HRM_PARTICIPANT_CREATE_FOLDER
            : URL_PARTICIPANT_CREATE_FOLDER
          : `${
              roleAccess([USER_ROLE.Sp_Standard_User])
                ? URL_WORKER_CREATE_FOLDER
                : URL_HRM_CREATE_FOLDER
            }`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  updateFolderApi(params) {
    return apiCall.post({
      url:
        params.role === "participant"
          ? URL_PARTICIPANT_UPDATE_FOLDER
          : `${
              roleAccess([USER_ROLE.Sp_Standard_User])
                ? URL_WORKER_UPDATE_FOLDER
                : URL_HRM_UPDATE_FOLDER
            }`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  removeFolderApi(params) {
    return apiCall.delete({
      url:
        params.role === "participant"
          ? URL_PARTICIPANT_REMOVE_FOLDER
          : `${
              roleAccess([USER_ROLE.Sp_Standard_User])
                ? URL_WORKER_REMOVE_FOLDER
                : URL_HRM_REMOVE_FOLDER
            }?folder_id=${params.folder_id}`,
      isAuthToken: token,
      params,
    });
  },

  removeFileApi(params) {
    return apiCall.delete({
      url: `${
        roleAccess([USER_ROLE.Sp_Standard_User])
          ? URL_WORKER_REMOVE_FILE
          : URL_HRM_REMOVE_FILE
      }?file_management_id=${params.file_management_id}`,
      isAuthToken: token,
      params,
    });
  },

  getDashboardDataApi(params) {
    return apiCall.get({
      url:
        params.filter === "all"
          ? URL_DASHBOARD_DATA
          : `${URL_DASHBOARD_DATA}?filter=${params.filter}`,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },
  getCosFundingReportApi(params) {
    return apiCall.get({
      url: `${URL_GEL_REPORTING}?pt_id=${params.pt_id}&sp_cos_id=${params.cos_id}
      &from_date=${params.from_date}&to_date=${params.to_date}&type=${params.type}&report=${params.reportType}`,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },
  getCosReportingFundingTableDataApi(params) {
    return apiCall.get({
      url: `${URL_GEL_REPORTING}?pt_id=${params.pt_id}&sp_cos_id=${params.cos_id}
      &from_date=${params.from_date}&to_date=${params.to_date}&type=${params.type}`,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },

  getPeriorityListApi(params) {
    return apiCall.get({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User])   ?
        URL_COS_PRIORITY_SETTING_DATA_COS
        // : URL_COS_PRIORITY_SETTING_DATA_SP
      }?status=${params.status}`,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  changeActionsClaimInvoiceApi(params) {
    const url = `${URL_CHANGE_NDIS_INVOICE_CLAIM_ACTIONS}?${
      params.hasOwnProperty("report")
        ? `report=${params.report}`
        : `status=${params.status}`
    }`;
    return apiCall.post({
      url,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },
  changeNDISClaimActionsApi(params) {
    const url = `${URL_CHANGE_NDIS_CLAIM_ACTIONS}?${
      params.hasOwnProperty("report")
        ? `report=${params.report}`
        : `status=${params.status}`
    }`;
    return apiCall.post({
      url,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },
  getCreatedCosInvoicesReviewsApi(params) {
    return apiCall.get({
      url: `${URL_GET_CREATED_COS_INVOICES_PREVIEW_LIST}?cos_invoice_id=${params.id}&pt_id=${params.pt_id}&status=${params.status}`,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  getCreatedCosInvoicesApi() {
    return apiCall.get({
      url: `${URL_GET_CREATED_COS_INVOICES}`,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  createCosInvoicesApi(params) {
    return apiCall.post({
      url: `${URL_CREATE_COS_INVOICES}`,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },

  getCosInvoicesReportApi(params) {
    const uRl = `${URL_GET_COS_INVOICES}?pt_id=${params.pt_id}&from_date=${params.from_date}&to_date=${params.to_date}&sp_cos_id=${params.cos_id}&report${params.report}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  getCosInvoicesApi(params) {
    const uRl = `${URL_GET_COS_INVOICES}?pt_id=${params.pt_id}&from_date=${params.from_date}&to_date=${params.to_date}&sp_cos_id=${params.cos_id}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  getCaseNotesReviewApi(params) {
    const uRl = `${URL_GET_CASE_NOTES_REVIEW}?cos_ndis_claim_id=${params.id}&pt_id=${params.pt_id}&status=${params.status}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  getClaimedCaseNotesListApi(params) {
    const uRl = `${URL_GET_CLAIMED_CASE_NOTES_LIST}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  addToClaimApi(params) {
    return apiCall.post({
      url: `${URL_ADD_CASE_NOTES_TO_NDIS_CLAIM_LIST}`,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },

  getCaseNotesForClaimListReportApi(params) {
    const uRl = `${URL_GET_CASE_NOTES_LIST_FOR_NDIS_CLAIM}?pt_id=${params.pt_id}&sp_cos_id=${params.cos_id}&from_date=${params.from_date}&to_date=${params.to_date}&report=${params.report}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  getCaseNotesForClaimListApi(params) {
    const uRl = `${URL_GET_CASE_NOTES_LIST_FOR_NDIS_CLAIM}?pt_id=${params.pt_id}&sp_cos_id=${params.cos_id}&from_date=${params.from_date}&to_date=${params.to_date}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  getCaseNotesHistoryListApi(params) {
    const paramsString = `pt_id=${params.pt_id}&from_date=${params.from_date}&to_date=${params.to_date}&contact_type_id=${params.contact_type_id}`;
    const uRl = `${URL_GET_CASE_NOTES_HISTORY_List}?${
      params.hasOwnProperty("report")
        ? `${paramsString}&report=${params.report}`
        : paramsString
    }`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  getCaseNotesClients(params) {
    const uRl = `${URL_GET_CASE_NOTES_CLIENTS}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  getSingleSupportTrackListApi(params) {
    const uRl = `${URL_GET_SINGLE_SUPPORT_TRACK}?service_support_id=${params.id}&pt_id=${params.pt_id}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },

  getSupportTrackListApi(params) {
    const uRl = `${URL_GET_SUPPORT_TRACK_LIST}?pt_id=${params.id}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },
  reportingCaseNotesHistory(params) {
    const uRl = `${BASE_URL}${REPORT_DOWNLOAD_URL}/report?from_date=${params.from_date}&funding_id=${params.uuid}&to_date=${params.to_date}&pt_id=${params.pt_id}&bill_types=${params.bill_types}&file_type=${params.file_type}`;
    return apiCall.get({
      url: uRl,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },
  clientTaskDelete(params) {
    return apiCall.delete({
      url: `${URL_DELETE_CLIENT_TASKS}${params.uuid}`,
      isAuthToken: token,
      params,
    });
  },
  updateClientTask(params) {
    return apiCall.post({
      url: `${URL_UPDATE_CLIENT_TASK}?task_id=${params.get("task_id")}`,
      isAuthToken: token,
      // skipQueryParams: true,
      params,
    });
  },
  getClientTaskList(params) {
    return apiCall.get({
      url: `${URL_CREATE_CLIENT_TASKS}?pt_id=${params.pt_id}&per_page=${params.per_page}&page=${params.page}&funding_id=${params.funding_id}`,
      isAuthToken: token,
      params: {},
    });
  },
  createClientTask(params) {
    return apiCall.post({
      url: URL_CREATE_CLIENT_TASKS,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },
  getServiceProviderSearchList(params) {
    return apiCall.get({
      url: `${URL_GET_SERVICE_PROVIDER_SERCH_RESULT}?search=${
        params.name || ""
      }`,
      isAuthToken: token,
      params: {},
    });
  },
  createCaseNotes(params) {
    return apiCall.post({
      url: URL_CREATE_CASENOTES,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },
  getCosCaseNote(params) {
    return apiCall.get({
      url: `${URL_GET_CASENOTES}?pt_id=${params.id}& page= ${params.page}& per_page= ${params.per_page}&funding_id=${params.funding_id}`,
      isAuthToken: token,
      params: {},
    });
  },
  getCosParticipantProfile(params) {
    return apiCall.get({
      url: `${URL_GET_COS_PARTICIPANT}?pt_id=${params.id}`,
      isAuthToken: token,
      params: {},
    });
  },
  getAllCatalogServices(params) {
    return apiCall.get({
      url: CATALOG_SERVICES,
      isAuthToken: token,
      params: params,
    });
  },
  getCatalogVersions() {
    return apiCall.get({
      url: URL_CATALOG_VERSION,
      isAuthToken: token,
      params: {},
    });
  },
  getCatalogServicesSubCategoryApi() {
    return apiCall.get({
      url: CATALOG_SERVICES_SUB_CATEGORY,
      isAuthToken: token,
      params: {},
    });
  },
  storeFundingSetup(params) {
    return apiCall.post({
      url: URL_FUNDING_SETUP_CREATE,
      isAuthToken: token,
      params,
    });
  },
  updateFundingSetup(params) {
    return apiCall.post({
      url: URL_FUNDING_SETUP_UPDATE,
      isAuthToken: token,
      params,
    });
  },
  getFundingSetupParticipantFosCos(params) {
    return apiCall.get({
      url: `${URL_FUNDING_SETUP_PARTICIPAANT_FOR_COS}`,
      isAuthToken: token,
      params,
    });
  },
  getFundingSetupParticipant(params) {
    return apiCall.get({
      url: URL_FUNDING_SETUP_PARTICIPAANT,
      isAuthToken: token,
      params,
    });
  },
  getSelectedFundingSetup(params) {
    return apiCall.get({
      url: URL_SELECTED_FUNDING_SETUP,
      isAuthToken: token,
      params,
    });
  },
  getPlanCatagories(params) {
    return apiCall.get({
      url: URL_PLAN_CATAGORIES,
      isAuthToken: token,
      params,
    });
  },
  storePlanBudget(params) {
    return apiCall.post({
      url: URL_PLAN_BUDGET,
      isAuthToken: token,
      params,
    });
  },
  updatePlanBudget(params) {
    return apiCall.post({
      url: URL_PLAN_BUDGET_UPDATE,
      isAuthToken: token,
      params,
    });
  },
  planBudgetList(params) {
    return apiCall.get({
      url: URL_PLAN_BUDGET_LIST,
      isAuthToken: token,
      params,
    });
  },
  planBudgetRemove(params) {
    return apiCall.delete({
      url: URL_PLAN_BUDGET_LIST,
      isAuthToken: token,
      params,
    });
  },
  serviceCategoryRemove(params) {
    return apiCall.delete({
      url: URL_SERVICE_CATEGORY,
      isAuthToken: token,
      params,
    });
  },
  planBudgetCatAndSubCat(params) {
    return apiCall.get({
      url: URL_PLAN_BUDGET_CAT_AND_SUB_CAT,
      isAuthToken: token,
      params,
    });
  },
  storeSeriveCategoryPlanBudget(params) {
    return apiCall.post({
      url: URL_SERVICE_PROVIDER_CATEGORY_PLAN_BUDGET,
      isAuthToken: token,
      params,
    });
  },
  updateSeriveCategoryPlanBudget(params) {
    return apiCall.post({
      url: URL_SERVICE_PROVIDER_CATEGORY_PLAN_BUDGET_UPDATE,
      isAuthToken: token,
      params,
    });
  },
  fetchSeriveCategoryPlanBudget(params) {
    return apiCall.get({
      url: URL_SERVICE_PROVIDER_CATEGORY_PLAN_BUDGET_LIST,
      isAuthToken: token,
      params,
    });
  },
  getExternalSPListsCall(params) {
    return apiCall.get({
      url: URL_EXTERNAL_SP,
      isAuthToken: token,
      params: params,
    });
  },
  postServiceProvider(params) {
    return apiCall.post({
      url: URL_EXTERNAL_SP,
      isAuthToken: token,
      params: params.externalSpData,
    });
  },

  getCatalogServicesSearch(params) {
    return apiCall.get({
      url: URL_SEARCH_CATALOG_SERVICES,
      isAuthToken: token,
      params,
    });
  },
  getServicePriceAgainstState(params) {
    return apiCall.get({
      url: URL_GET_CATALOG_PRICE_AGAINST_STATE,
      isAuthToken: token,
      params,
    });
  },
  fundingNotesListCall(params) {
    return apiCall.get({
      url: URL_FUNDING_NOTES_LIST,
      isAuthToken: token,
      params,
    });
  },
  storeFundingNotes(params) {
    return apiCall.post({
      url: URL_FUNDING_NOTES_LIST,
      isAuthToken: token,
      params,
    });
  },
  updateFundingNotes(params) {
    return apiCall.post({
      url: URL_FUNDING_NOTES_LIST_UPDATE,
      isAuthToken: token,
      params,
    });
  },
  fundingNotesRemove(params) {
    return apiCall.delete({
      url: `${URL_FUNDING_NOTES_LIST}/${params.fundingNotesId}`,
      isAuthToken: token,
      params,
    });
  },
  storeServiceSupport(params) {
    return apiCall.post({
      url: URL_SERVICE_SUPPORT_BUDGET_STORE,
      skipQueryParams: true,
      isAuthToken: token,
      params,
    });
  },
  updateServiceSupport(params) {
    return apiCall.put({
      url: `${URL_SERVICE_SUPPORT_BUDGET_UPDATE}?service_support_id=${params.service_support_id}`,
      skipQueryParams: true,
      isAuthToken: token,
      params,
    });
  },
  fetchServiceSupport(params) {
    return apiCall.get({
      url: URL_SERVICE_SUPPORT_BUDGET_LIST,
      isAuthToken: token,
      params,
    });
  },

  fetchServiceSupportsForDeliveredServices(params) {
    return apiCall.get({
      url: `${URL_SERVICE_SUPPORTS_FOR_DELIVERED_SERVICE}?funding_id=${params.funding_id}&pt_id=${params.pt_id}&cos_case_notes=${params.cos_case_notes}`, //case notes =true
      isAuthToken: token,
      params,
      skipQueryParams: true,
    });
  },

  cosParticipantListCall(params) {
    return apiCall.get({
      url: URL_COS_PARTICIPANT_LIST,
      isAuthToken: token,
      params,
    });
  },
  supportCoordinatorCall() {
    return apiCall.get({
      url: URL_SUPPORT_COORDINATOR,
      skipQueryParams: true,
      isAuthToken: token,
      params: {},
    });
  },
  manualDeliveredServiceStore(params) {
    return apiCall.post({
      url: URL_MANUAL_DELIVERED_SERVICE_STORE,
      isAuthToken: token,
      params,
      skipQueryParams: true,
    });
  },
  deliveredServiceStore(params) {
    return apiCall.post({
      url: URL_DELIVERED_SERVICE_STORE,
      isAuthToken: token,
      params,
    });
  },
  deliveredServiceFetch(params) {
    return apiCall.get({
      url: URL_DELIVERED_SERVICE,
      isAuthToken: token,
      params,
    });
  },
  manualDeliveriesFetch(params) {
    return apiCall.get({
      url: URL_MANUAL_DELIVERIES,
      isAuthToken: token,
      params,
    });
  },

  cosCategorySettingFetch(payload) {
    return apiCall.get({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User]) ?
        URL_COS_CATEGORY_SETTING_DATA_COS
        // : URL_COS_CATEGORY_SETTING_LIST_SP
      }?status=${payload.status}`,
      isAuthToken: token,
      skipQueryParams: true,

      params: {},
    });
  },
  createNewCategoryApi(params) {
    return apiCall.post({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User]) ?
        URL_TASK_PRIORITY_SETTING_CREATE_UPDATE_COS
        // : URL_TASK_PRIORITY_SETTING_CREATE_UPDATE_SP
      }`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  updateCategoryApi(params) {
    return apiCall.put({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User]) ?
        URL_TASK_PRIORITY_SETTING_CREATE_UPDATE_COS
        // : URL_TASK_PRIORITY_SETTING_CREATE_UPDATE_SP
      }`,
      // ?client_task_category_id=${params.id}`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  cosContactSettingFetch(payload) {
    return apiCall.get({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User]) ?
        URL_COS_CONTACT_SETTING_DATA_COS
        // : URL_COS_CONTACT_SETTING_DATA_SP
      }?status=${payload.status}`,
      isAuthToken: token,
      skipQueryParams: true,
      params: {},
    });
  },
  createNewContactApi(params) {
    return apiCall.post({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User]) ?
        URL_CONTACT_TYPE_SETTING_CREATE_UPDATE_COS
        // : URL_CONTACT_TYPE_SETTING_CREATE_UPDATE_SP
      }`,
      isAuthToken: token,
      skipQueryParams: true,
      params,
    });
  },
  updateContactApi(params) {
    return apiCall.put({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User]) ?
        URL_CONTACT_TYPE_SETTING_CREATE_UPDATE_COS
        // : URL_CONTACT_TYPE_SETTING_CREATE_UPDATE_SP
      }`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },

  cosPrioritySettingFetch(payload) {
    return apiCall.get({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User]) ?
        URL_COS_PRIORITY_SETTING_DATA_COS
        // : URL_COS_PRIORITY_SETTING_DATA_SP
      }?status=${payload.status}`,
      skipQueryParams: true,
      isAuthToken: token,
      params: {},
    });
  },
  createNewPeriorityApi(params) {
    return apiCall.post({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User])?
        URL_COS_PERIORITY_SETTING_CREATE_UPDATE_COS
        // : URL_COS_PERIORITY_SETTING_CREATE_UPDATE_SP
      }`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
  updatePriorityApi(params) {
    return apiCall.put({
      url: `${
        // roleAccess([USER_ROLE.Sp_Standard_User])   ?
        URL_COS_PERIORITY_SETTING_CREATE_UPDATE_COS
        // : URL_COS_PERIORITY_SETTING_CREATE_UPDATE_SP
      }`,
      isAuthToken: token,
      skipQueryParams: false,
      params,
    });
  },
};

export default api;
