import React, { useEffect, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { getPaymentSettings, getUser } from "store/selector/auth.selector";
import { classNames, getExpireDate } from "Utils/utils";
import {
  STRIPE_CHECK_SUBSCRIPTION_STATUS,
  checkSubscriptionStatus,
} from "helpers/RolesPermission";
import { useNavigate } from "react-router";

const VertexBanner = ({ setIsTrailChoosePlanBtnClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const currentUser = useSelector(getUser);
  const paymentSetting = useSelector(getPaymentSettings);

  const istrailExpiredVal = checkSubscriptionStatus(currentUser);

  const navigate = useNavigate();

  useEffect(() => {
    if (istrailExpiredVal === STRIPE_CHECK_SUBSCRIPTION_STATUS.SUBSCRIBED) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [istrailExpiredVal]);

  const navigateToPlan = () => {
    setIsTrailChoosePlanBtnClick((prev) => prev + 1);
    navigate("/subscription/plans");
  };

  return (
    <div
      className={classNames(
        "border-t-0 border-b-2 border border-1 flex items-center gap-x-6 bg-amber-300 px-6 py-2.5 sm:px-3.5 sm:before:flex-1",
        isOpen === false && "hidden"
      )}
    >
      <p className='text-sm leading-6 text-black w-[60%] text-center'>
        <span>
          {istrailExpiredVal ===
          STRIPE_CHECK_SUBSCRIPTION_STATUS.TRAIL_EXPIRED ? (
            <>
              Attention! Your trial period for Vertex360 has ended. To continue
              accessing our services, please choose a suitable plan now.
              <br />
              <span
                role='button'
                tabIndex={0}
                onClick={navigateToPlan}
                className='mr-1 text-indigo-700 underline cursor-pointer'
              >
                Click here
              </span>
              to select your plan and unlock all features.
            </>
          ) : istrailExpiredVal ===
            STRIPE_CHECK_SUBSCRIPTION_STATUS.SUBSCRIPTION_UNPAID ? (
            <>
              Your account is currently in 'Read Only' mode. Update payment card
              details for uninterrupted service.
              <br />
              <span
                role='button'
                tabIndex={0}
                onClick={() => navigate("/subscription")}
                className='mr-1 text-indigo-700 underline cursor-pointer'
              >
                Click here
              </span>
              to update payment card details.
            </>
          ) : istrailExpiredVal ===
            STRIPE_CHECK_SUBSCRIPTION_STATUS.RETRY_PAYMENT_FAILED ? (
            <>
              {`Enjoy a one month grace period: Your access to Vertex360 is until ${getExpireDate(
                paymentSetting?.next_payment_attempt
              )}. After this date, your account will have limited access
              until payment is made. To avoid any disruption in service, please
              ensure payment is processed before the grace period ends. Thank
              you for your cooperation. `}
              <span
                role='button'
                tabIndex={0}
                onClick={() => navigate("/subscription")}
                className='ml-1 text-indigo-700 underline cursor-pointer'
              >
                Click here
              </span>
            </>
          ) : istrailExpiredVal ===
            STRIPE_CHECK_SUBSCRIPTION_STATUS.PAYMENTFAILED ? (
            <>
              Attention: Your Vertex360 subscription is presently inactive due
              to payment issues. To regain access to all features, please update
              your payment information.
            </>
          ) : istrailExpiredVal ===
            STRIPE_CHECK_SUBSCRIPTION_STATUS.PAYMENT_FAILED_TRAIL_EXISTS ? (
            <>
              <span className='font-bold'>Attention:</span> Your Vertex360
              subscription is presently inactive due to payment issues. To
              regain access to all features, please update your payment
              information.
              <br />
              <span className='underline'>
                {`You can still use a trial account that will expire on ${getExpireDate(
                  paymentSetting?.trial_end_date
                )}`}
              </span>
            </>
          ) : (
            istrailExpiredVal === STRIPE_CHECK_SUBSCRIPTION_STATUS.TRAILING && (
              <>
                {`Enjoy exploring Vertex360's features during your trial period, valid until ${getExpireDate(
                  paymentSetting?.trial_end_date
                )}.`}

                {`Upgrade your subscription plan for even greater benefits and functionality – `}
                <span
                  role='button'
                  tabIndex={0}
                  onClick={navigateToPlan}
                  className='ml-1 text-indigo-700 underline cursor-pointer'
                >
                  subscribe now!
                </span>
              </>
            )
          )}
        </span>
      </p>

      <div className='flex flex-1 justify-end'>
        {istrailExpiredVal === false && (
          <button
            onClick={() => setIsOpen(!isOpen)}
            className='-m-3 p-3 focus-visible:outline-offset-[-4px]'
          >
            <span className='sr-only'>Dismiss</span>
            <RiCloseCircleFill
              className='h-5 w-5 text-black'
              aria-hidden='true'
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default VertexBanner;
