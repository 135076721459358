import { connect } from "react-redux";
import NdisClaim from "./NdisClaim";
import {
  getCaseNotesListForClaimsRequest,
  getClaimedCaseNotesListRequest,
  getClientsRequest,
} from "../../../store/actions/cos.actions";
import {
  getClaimedCaseNotesListLoadingSelector,
  getClaimedCaseNotesListSelector,
  getSelectedClientsListSelector,
} from "../../../store/selector/cos.selector";

const mapDispatchToProps = {
  getCaseNotesRequest: getCaseNotesListForClaimsRequest,
  getClientsRequest,
  getClaimCaseNotesRequest: getClaimedCaseNotesListRequest,
};

const mapStateToProps = (state) => {
  const claimedCaseNotesList = getClaimedCaseNotesListSelector(state);
  const selectedClientList = getSelectedClientsListSelector(state);
  const claimedCaseNotesListLoading =
    getClaimedCaseNotesListLoadingSelector(state);
  return {
    claimedCaseNotesList,
    claimedCaseNotesListLoading,
    selectedClientList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NdisClaim);
