import React from "react";
import { HiOutlineEye } from "react-icons/hi";

const GoogleDocsViewer = ({ url }) => {
  if (!url) {
    // If no URL is provided, render a disabled view button
    return (
      <div className="gap-x-3 flex bg-red-500">
        <button
          className="icon-eye-wrapper"
          title="View"
          disabled
          style={{ cursor: "not-allowed" }}
        >
          <HiOutlineEye className="icon-size" />
        </button>
      </div>
    );
  }

  const fileExtension = url.split('.').pop().toLowerCase();
  const supportedImageExtensions = ["png", "jpg", "jpeg", "gif"];

  const openInNewTab = (e) => {
    e.preventDefault(); 
    window.open(url, "_blank");
  };

  const openWithGoogleDocsViewer = (e) => {
    e.preventDefault(); 
    const googleDocsUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(url)}&embedded=true`;
    window.open(googleDocsUrl, "_blank");
  };

  const isImage = supportedImageExtensions.includes(fileExtension);

  return (
    <div className="gap-x-3 flex">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="icon-eye-wrapper"
        title="View"
        onClick={isImage ? openInNewTab : openWithGoogleDocsViewer}
      >
        <HiOutlineEye className="icon-size" />
      </a>
    </div>
  );
};

export default GoogleDocsViewer;
