import React, { useEffect, useState } from "react";
import InputField from "components/common/Input/Input";
import CustomButton from "components/common/Button/CustomButton";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./styles.css";
import { toast } from "react-toastify";
import api from "store/services/sp.services";
import { LOADER_STATUS } from "store/constants";
import { useDispatch, useSelector } from "react-redux";
import { stripeSaveCardDataRequest } from "store/actions/sp.actions";
import {
  getStripeSaveCardDataError,
  getStripeSaveCardDataLoader,
} from "store/selector/sp.selector";
import { convertCentsToDollars } from "Utils/utils";

const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      // fontSize: "16px",
      // color: "#424770",
      // "::placeholder": {
      //   color: "#aab7c4",
      // },
    },
  },
};

const SubscriptionCheckout = (props) => {
  const { handleBack, handleCloseCheckoutPage, data, defaultCardDetails } =
    props;
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const stripeFirstCallLoader = useSelector(getStripeSaveCardDataLoader);
  const stripeFirstCallError = useSelector(getStripeSaveCardDataError);
  const [saveCardLoader, setSaveCardLoader] = useState(LOADER_STATUS.NA);

  useEffect(() => {
    if (
      stripeFirstCallLoader === false &&
      saveCardLoader === 1 &&
      stripeFirstCallError?.length === 0
    ) {
      setSaveCardLoader(LOADER_STATUS.NA);
      handleCloseCheckoutPage();
    }
  }, [stripeFirstCallLoader]);

  const [buyPlanLoader, setBuyPlanLoader] = useState(false);
  const [buyPlanError, setBuyPlanError] = useState("");

  const [promoCode, setPromoCode] = useState("");
  const [isSubmitCoupon, setSubmitCoupon] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [couponInfo, setCouponInfo] = useState(null);
  const [discount, setDiscount] = useState(0);

  const [disable, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const [cardData, setCardData] = useState({
    name: "",
    // firstName: "",
    // lastName: "",
    // phone: "",
    // zip: "",
  });

  useEffect(() => {
    if (defaultCardDetails !== null) {
      setCardData((prev) => ({
        ...prev,
        name: defaultCardDetails.card_name,
        // firstName: defaultCardDetails.card_name?.split(" ")?.[0],
        // lastName: defaultCardDetails.card_name?.split(" ")?.[1],
      }));
    }
  }, [defaultCardDetails]);

  const handleSubmitPaymentMethod = async () => {
    try {
      if (!stripe || !elements || disable) {
        return;
      }
      if (!cardData.name) {
        setMessage("Enter Card Holder Name");
        throw { message: "Enter Card Holder Name" };
      }
      setMessage("");
      setDisabled(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement) || { token: "123" },
      });
      setDisabled(false);
      if (error) {
        setMessage(error.message || "Incomplete card data");
        console.log("[error]", error.message);
        throw error;
      } else if (paymentMethod) {
        setMessage("");
        console.log("[PaymentMethod]", paymentMethod);
        return paymentMethod;
      }
    } catch (err) {
      throw new Error(err?.message);
    }
  };

  const handleBuyPlan = async (event) => {
    event.preventDefault();
    let paymentMethod = null;
    try {
      if (defaultCardDetails === null) {
        paymentMethod = await handleSubmitPaymentMethod();
      }
      if (paymentMethod === null && defaultCardDetails === null) {
        toast.error("Please Add Payment Method", {
          toastId: "add-payment-method-checkout-screen",
        });
        return;
      }
      if (
        data.selectedPrice === null ||
        !data.selectedPrice ||
        buyPlanLoader === true ||
        !data.selectedPrice?.price_id
      ) {
        return;
      }

      const payload = {
        price_id: data.selectedPrice?.price_id,
      };
      if (couponInfo && couponInfo?.id) {
        Object.assign(payload, {
          coupon_id: couponInfo.id,
        });
      }

      if (defaultCardDetails === null) {
        Object.assign(payload, {
          payment_method_id: paymentMethod.id,
          // card_holder_name: `${cardData.firstName} ${cardData.lastName}`,
          card_holder_name: cardData.name,
          is_default: true,
        });
        setSaveCardLoader(LOADER_STATUS.PENDING);
        dispatch(
          stripeSaveCardDataRequest(payload, {
            id: paymentMethod.id,
            card: paymentMethod?.card || null,
            metadata: {
              // card_name: `${cardData.firstName} ${cardData.lastName}`,
              card_name: cardData.name,
            },
          })
        );
      } else {
        setBuyPlanError("");
        setBuyPlanLoader(true);
        const response = await api.stripeBuyPlanApi(payload);
        if (response) {
          setBuyPlanLoader(false);
          toast.info(
            "Thank you for subscribing! Your subscription is currently being processed and will be updated shortly."
          );
          handleCloseCheckoutPage();
        }
        setBuyPlanLoader(false);
      }
    } catch (err) {
      console.log("err", err);
      setBuyPlanLoader(false);
      if (defaultCardDetails !== null) {
        setBuyPlanError(err?.message ? err?.message : "Something Went Wrong!");
        toast.error(err?.message ? err?.message : "Something Went Wrong!");
      } else {
        toast.error(err?.message || "Something went wrong!", {
          toastId: "buyPlanAPI",
        });
      }
    }
  };

  const handleSubmitPromoCode = async () => {
    try {
      setSubmitCoupon(true);
      setCouponError("");
      const response = await api.stripeCouponInfo({
        coupon_name: promoCode,
      });
      if (response?.coupon_info && response.coupon_info.valid === true) {
        const discountRateOrPrice = getDiscount(
          data?.selectedPrice?.monthly?.split(" ")?.[0]?.replace("$", ""),
          response?.coupon_info
        );
        if (
          data?.selectedPrice?.monthly?.split(" ")?.[0]?.replace("$", "") -
            discountRateOrPrice <
          0
        ) {
          throw new Error("Coupon not allowed for this package!");
        }

        console.log("discountRateOrPrice", discountRateOrPrice);
        setCouponInfo(response?.coupon_info);
        setDiscount(discountRateOrPrice);
        toast.success("Promo Code Applied Successfully!");
        setPromoCode("");
      }
      setSubmitCoupon(false);
    } catch (err) {
      setCouponError(err?.message || "Promo is not valid!");
      setSubmitCoupon(false);
      console.log("err", err);
      toast.error(err?.message ? err?.message : "Something Went Wrong!");
    }
  };

  const getDiscount = (price, coupon) => {
    if (coupon && coupon.percent_off !== null) {
      return price * (coupon.percent_off / 100);
    } else if (coupon && coupon.amount_off !== null && coupon.amount_off > 0) {
      return convertCentsToDollars(coupon.amount_off);
    } else {
      return 0;
    }
  };

  const calculateTotalAmount = () => {
    const gstAmount = calculateGST();
    const ttlAmount = Number(
      discount === 0 && couponInfo === null
        ? data?.selectedPrice?.monthly?.split(" ")?.[0]?.replace("$", "")
        : data?.selectedPrice?.monthly?.split(" ")?.[0]?.replace("$", "") -
            discount
    );

    return ttlAmount + gstAmount;
  };

  const calculateGST = () => {
    return (
      (Number(
        discount === 0 && couponInfo === null
          ? data?.selectedPrice?.monthly?.split(" ")?.[0]?.replace("$", "")
          : data?.selectedPrice?.monthly?.split(" ")?.[0]?.replace("$", "") -
              discount
      ) /
        100) *
      10
    );
  };

  return (
    <div className='w-full mx-auto max-w-7xl'>
      {/* <form className='w-full' onSubmit={handleBuyPlan} autoComplete='off'> */}
      <div className='w-full flex gap-3'>
        <div className='w-[70%] bg-[#fcfbfb] border-r border-1 border-[#ecebeb]'>
          {/* <div className='px-20 border-gray-200 pt-2'>
              <CustomButton
                Icon={<IoArrowBackOutline />}
                label='Back'
                clickHandler={handleBack}
              />
            </div> */}
          <div className='px-20 py-6'>
            <div>
              <h1 className='font-semibold text-2xl'>Add Payment Method</h1>
              <span className='text-xs pt-2 text-[#706868]'>
                Please add your credit card details.
              </span>
            </div>
            <div className='grid grid-cols-2 gap-7 py-5'>
              <div className='col-span-2'>
                <div className='pb-2'>
                  <label>Card Holder Name</label>
                </div>
                <InputField
                  isDisabled={defaultCardDetails !== null}
                  type='text'
                  value={cardData.name}
                  placeHolder='Card Holder Name'
                  handleChange={(e) => {
                    if (defaultCardDetails !== null) return;
                    setCardData((prev) => ({
                      ...prev,
                      name: e,
                    }));
                  }}
                />
              </div>
              {/* <div className='col-span-1'>
                  <div className='pb-2'>
                    <label>Last Name</label>
                  </div>
                  <InputField
                    isDisabled={defaultCardDetails !== null}
                    type='text'
                    value={cardData.lastName}
                    placeHolder='Last Name'
                    handleChange={(e) => {
                      if (defaultCardDetails !== null) return;
                      setCardData((prev) => ({
                        ...prev,
                        lastName: e,
                      }));
                    }}
                  />
                </div> */}
            </div>
            <div className='grid grid-cols-1 py-5'>
              <div className='col-span-1'>
                <div className='pb-2'>
                  <label>Card Number</label>
                </div>
                <div className='stripe-input'>
                  {defaultCardDetails !== null ? (
                    <InputField
                      isDisabled={defaultCardDetails !== null}
                      type='text'
                      value={`**** **** **** ${defaultCardDetails.last4}`}
                    />
                  ) : (
                    <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                  )}
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-7 py-5'>
              <div className='col-span-1'>
                <div className='pb-2'>
                  <label>Expiry Date</label>
                </div>
                <div className='stripe-input'>
                  {defaultCardDetails !== null ? (
                    <InputField
                      isDisabled={defaultCardDetails !== null}
                      type='text'
                      value={`${defaultCardDetails.exp_month} / ${defaultCardDetails.exp_year}`}
                    />
                  ) : (
                    <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                  )}
                </div>
              </div>
              <div className='col-span-1'>
                <div className='pb-2'>
                  <label>CVC</label>
                </div>
                <div className='stripe-input'>
                  {defaultCardDetails !== null ? (
                    <InputField
                      isDisabled={defaultCardDetails !== null}
                      type='text'
                      value={`***`}
                    />
                  ) : (
                    <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                  )}
                </div>
              </div>
            </div>
            {/* <div className='grid grid-cols-2 gap-7 py-5'>
                <div className='col-span-1'>
                  <div className='pb-2'>
                    <label>Mobile Number</label>
                  </div>
                  <InputField
                    minLength='10'
                    pattern={/^0[0-9]*$/}
                    placeHolder='Enter Mobile Number'
                  />
                </div>
                <div className='col-span-1'>
                  <div className='pb-2'>
                    <label>ZIP</label>
                  </div>
                  <InputField placeHolder='A1B 2C3' />
                </div>
              </div> */}

            {/* Buttons */}
            {/* <div className='pt-5 flex justify-start gap-x-3'>
                <CustomButton
                  label='Save'
                  cssClass='w-[100px] rounded-full flex justify-center items-center'
                />
                <CustomButton
                  label='Save & Add Another'
                  cssClass='rounded-full'
                />
                <CustomButton
                  label='Cancel'
                  variant='secondary'
                  cssClass='rounded-full'
                />
              </div> */}
          </div>
        </div>
        <div className='w-[30%]'>
          <div className='py-6 px-6'>
            <div>
              <h4 className='font-extrabold'>Order Summary</h4>
            </div>
            <div className='py-2'>
              <span className='font-bold text-sm text-[#4362a5]'>
                Selected Plan
              </span>
              <div className='grid gap-y-1.5 pt-2 text-[#706868]'>
                <span className='text-xs'>{data?.selectedPlan}</span>
                <span className='text-xs'>{data?.selectedPrice?.monthly}</span>
              </div>
            </div>
            <div className='py-2'>
              <span className='font-bold text-sm text-[#4362a5]'>
                Billing Schedule
              </span>
              <div className='grid gap-y-1'>
                <span className='text-xs pt-2 text-[#706868]'>Monthly</span>
              </div>
            </div>
            <div className='py-2'>
              <div className='font-extrabold text-xs text-black py-2'>
                Number of Participants
              </div>
              <div className='py-3 px-4 border border-[#bcbbbb] bg-[#fffefe] rounded-md flex justify-center items-center'>
                <span className='text-xs'>{data?.selectedParticipants}</span>
              </div>
              <div className='pt-3 font-extrabold text-md text-black flex justify-between items-center'>
                <span>Plan Price</span>
                <span>
                  $
                  {data?.selectedPrice?.monthly
                    ?.split(" ")?.[0]
                    ?.replace("$", "")}
                </span>
              </div>
            </div>
            <hr />
            <div className='py-3'>
              <span className='text-md text-[#193070] font-black'>
                Promo Code
              </span>
              <div className='flex justify-start gap-x-3 items-center w-full'>
                <div className='w-[60%]'>
                  <InputField
                    ErrorMessage={couponError}
                    value={promoCode}
                    handleChange={setPromoCode}
                    placeHolder='Enter your Promo Code'
                  />
                </div>
                <div className='w-[30%]'>
                  <CustomButton
                    // showLoading={isSubmitCoupon}
                    isDisabled={isSubmitCoupon}
                    clickHandler={() => {
                      if (!promoCode || (promoCode && promoCode.length === 0)) {
                        toast.error("Promo Code is required!", {
                          toastId: "promoCodeError",
                        });
                        return;
                      }
                      handleSubmitPromoCode();
                    }}
                    label='Apply'
                    cssClass='mt-[2px]'
                  />
                  {couponError.length > 0 && (
                    <span
                      style={{
                        fontSize: "13px",
                        color: "red",
                        marginLeft: "5px",
                        visibility: "hidden",
                      }}
                    >
                      Promo Error
                    </span>
                  )}
                </div>
              </div>
              {couponInfo && discount > 0 && (
                <div className='mt-2 text-sm py-1 px-2 promo-applied'>
                  Promo Applied
                </div>
              )}
            </div>
            {couponInfo && (
              <div className='pt-1 pb-3'>
                <div className='font-extrabold text-md text-black flex justify-between items-center'>
                  <span>Promo Discount</span>
                  <span>{`-$${discount}`}</span>
                </div>
              </div>
            )}
            <hr />
            <div className='py-2'>
              <div className='pb-2 font-extrabold text-md text-black flex justify-between items-center'>
                <span>Promo Price Apply</span>
                <span>
                  $
                  {Number(
                    data?.selectedPrice?.monthly
                      ?.split(" ")?.[0]
                      ?.replace("$", "")
                  ) - discount}
                </span>
              </div>
              <div className='py-2 font-extrabold text-md text-black flex justify-between items-center'>
                <span>GST</span>
                <span>${calculateGST()}</span>
              </div>
              <hr />
              <div className='py-2 font-extrabold text-md text-black flex justify-between items-center'>
                <span>Total</span>
                <span>${calculateTotalAmount()}</span>
              </div>
            </div>
            <div className='flex justify-start items-center space-x-2 w-full'>
              <div className='w-[70%]'>
                <CustomButton
                  showLoading={
                    stripeFirstCallLoader || buyPlanLoader || disable
                  }
                  isDisabled={disable || stripeFirstCallLoader || buyPlanLoader}
                  clickHandler={handleBuyPlan}
                  label='Subscribe Plan'
                  cssClass='rounded-md w-full flex justify-center items-center'
                />
              </div>
              <div className='w-[30%]'>
                <CustomButton
                  clickHandler={() => handleBack()}
                  variant='secondary'
                  label='Cancel'
                  cssClass='rounded-md w-full flex justify-center items-center'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </form> */}
    </div>
  );
};

export default SubscriptionCheckout;
