import React from "react";
import "./ProgressBarNew.css";

const ProgressBarNew = ({ progressline, cssClass, isLoading = false }) => {
  const onBoardingProgress = parseFloat(
    progressline?.on_boarding_progress ?? 0
  );

  if (isLoading) {
    return (
      <div
        style={{ display: "flex" }}
        className={`animate-pulse sm:flex text-center text-sm w-full justify-content-start ${cssClass}`}
      >
        <div className={`mt-2 meter`}>
          <span style={{ width: `100%` }} data-progress={100}></span>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ display: "flex" }}
      className={`sm:flex text-center text-sm w-full justify-content-start ${cssClass}`}
    >
      <div
        className={`mt-2 meter ${
          Math.floor(onBoardingProgress) <= 75 ? "red" : "green"
        }`}
      >
        <span
          style={{ width: `${onBoardingProgress}%` }}
          data-progress={onBoardingProgress}
        ></span>
      </div>
      {onBoardingProgress > 0 ? (
        <span className='ml-2 progressBarCount'>{`${Math.floor(
          onBoardingProgress
        )}%`}</span>
      ) : (
        <span>{onBoardingProgress}%</span>
      )}
    </div>
  );
};

export default ProgressBarNew;
