import React, { useEffect, useState } from "react";
import InputField from "components/common/Input/Input";
import SelectOption from "components/common/Select/Select";
import { SHIFT_TIME_OPTIONS } from "Constants/constant";
import { getShiftLength, parseBooleanVal } from "Utils/utils";
import moment from "moment";

const TimesheetBreakdown = ({
  watchFormData,
  register,
  setValue,
  clearErrors,
  errors,
  item,
  index,
  itemNumber,
  selectedTimeSheet,
  suggestedItemNumber,
  isNonBillable,
  disabledAllComponent = false,
  spRosteringSetting,
}) => {
  const [selectedItemNumber, setSelectedItemNumber] = useState("");

  useEffect(() => {
    if (item && item.start_time) {
      setValue(
        `start_time_${index}`,
        moment(item?.start_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
      );
    }
    if (item && item.end_time) {
      setValue(
        `end_time_${index}`,
        moment(item.end_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
      );
      if (item?.invoice_item_id) {
        setValue(`invoice_item_id_${index}`, item?.invoice_item_id);
      }
    }
  }, [item, index, setValue]);

  const val = watchFormData[`invoice_item_id_${index}`];
  useEffect(() => {
    if (item && val) {
      const selectedItem = itemNumber?.find((item) => item.value === val);
      if (selectedItem) {
        setSelectedItemNumber(selectedItem);
      }
    }
  }, [itemNumber, val, index, item, watchFormData]);

  useEffect(() => {
    setValue(`invoice_item_name_${index}`, selectedItemNumber.number);
  }, [selectedItemNumber.number, index, setValue]);

  const NDIS_RATE = [
    {
      label: "Apply Alternative Rate",
      value: "alternate",
    },
    {
      label: selectedItemNumber ? selectedItemNumber?.price : "",
      value: selectedItemNumber ? selectedItemNumber?.price : "",
    },
  ];

  useEffect(() => {
    if (watchFormData[`invoice_ndis_rate_${index}`] !== "alternate") {
      setValue(
        `rate_amount_${index}`,
        watchFormData[`invoice_ndis_rate_${index}`]
      );
    } else if (watchFormData[`invoice_ndis_rate_${index}`] === "alternate") {
      if (parseBooleanVal(spRosteringSetting?.apply_alternate_rate)) {
        setValue(
          `rate_amount_${index}`,
          spRosteringSetting?.default_alternate_rate || 0
        );
      } else {
        setValue(`rate_amount_${index}`, item?.ndis_rate);
      }
    }
  }, [watchFormData[`invoice_ndis_rate_${index}`]]);

  useEffect(() => {
    if (parseBooleanVal(spRosteringSetting?.apply_alternate_rate)) {
      setValue(`invoice_ndis_rate_${index}`, "alternate");
      setValue(
        `rate_amount_${index}`,
        spRosteringSetting?.default_alternate_rate || 0
      );
    }
  }, [spRosteringSetting]);

  const getAppliedAmount = () => {
    if (
      watchFormData &&
      watchFormData[`invoice_ndis_rate_${index}`] &&
      selectedTimeSheet &&
      selectedTimeSheet?.participants &&
      watchFormData[`invoice_ndis_rate_${index}`] !== "alternate"
    ) {
      const result =
        watchFormData[`invoice_ndis_rate_${index}`] /
        selectedTimeSheet?.participants?.length;

      return result.toFixed(2);
    } else if (watchFormData[`invoice_ndis_rate_${index}`] === "alternate") {
      const result2 =
        watchFormData[`rate_amount_${index}`] /
        selectedTimeSheet?.participants?.length;

      return !isNaN(result2.toFixed(2)) ? result2.toFixed(2) : "";
    }
  };

  useEffect(() => {
    if (watchFormData && isNonBillable) {
      setValue(`rate_amount_${index}`, 0);
      setValue(`invoice_ndis_rate_${index}`, 0);
    }
  }, [isNonBillable]);

  return (
    <div className='border border-gray-200 py-7 px-6 rounded-lg mt-4'>
      <div className='flex items-start border-gray-200 border-b py-5'>
        <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
          Scheduled Start/Finish.
        </label>
        <div className='flex items-center w-full space-x-2'>
          <div className='w-[45%]'>
            <InputField
              isDisabled={disabledAllComponent}
              readOnly={true}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              name={`start_time_${index}`}
              cssClass='w-full'
              selectedOption={SHIFT_TIME_OPTIONS.find(
                (x) => x.value === watchFormData[`start_time_${index}`]
              )}
              errorMessage={
                errors[`start_time_${index}`]?.type === "required"
                  ? "This field is required!"
                  : null
              }
            />
          </div>
          <div className='w-[10%] text-center'>To</div>
          <div className='w-[45%]'>
            <InputField
              readOnly={true}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              name={`end_time_${index}`}
              isDisabled={
                watchFormData[`start_time_${index}`] === null ||
                watchFormData[`start_time_${index}`] === undefined ||
                disabledAllComponent
              }
              cssClass='w-full'
              selectedOption={SHIFT_TIME_OPTIONS.find(
                (x) => x.value === watchFormData[`end_time_${index}`]
              )}
              errorMessage={
                errors[`end_time_${index}`]?.type === "required"
                  ? "This field is required!"
                  : errors[`end_time_${index}`]?.type === "validate" &&
                    getShiftLength(
                      watchFormData[`start_time_${index}`],
                      watchFormData[`end_time_${index}`],
                      true
                    ) === "00:00"
                  ? "Shift can't be zero hours & minutes!"
                  : null
              }
              validate={getShiftLength(
                watchFormData[`start_time_${index}`],
                watchFormData[`end_time_${index}`],
                false,
                true
              )}
            />
          </div>
        </div>
      </div>
      <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
        <div className='flex w-full gap-x-4'>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            NDIS Item Name<span className='text-red-600'>*</span>
          </label>
          <div className='w-full'>
            <SelectOption
              isDisabled={disabledAllComponent}
              name={`invoice_item_id_${index}`}
              data={itemNumber}
              cssClass='w-full'
              isRequired={true}
              isSearch={false}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              selectedOption={
                (itemNumber && watchFormData[`invoice_item_id_${index}`]) ||
                item?.invoice_item_id
                  ? itemNumber?.find(
                      (x) =>
                        x.value === watchFormData[`invoice_item_id_${index}`]
                    )
                  : suggestedItemNumber
                  ? itemNumber?.find((x) => x.value === suggestedItemNumber?.id)
                  : ""
              }
              //   onChange={handleItemNumberChange}
            />
          </div>
        </div>
      </div>

      <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
        <div className='flex w-full gap-x-4'>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            NDIS Item Number<span className='text-red-600'>*</span>
          </label>
          <div className='w-full'>
            <InputField
              isDisabled={disabledAllComponent}
              readOnly={true}
              cssClass='mt-0 h-40-important'
              inputWidth='w-full'
              name={`invoice_item_name_${index}`}
              value={selectedItemNumber.number}
              isRequired={true}
              register={register}
            />
          </div>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            Hourly Rate<span className='text-red-600'>*</span>
          </label>
          <div className='w-full'>
            <SelectOption
              isDisabled={isNonBillable || disabledAllComponent}
              data={NDIS_RATE}
              name={`invoice_ndis_rate_${index}`}
              cssClass='w-full'
              isRequired={true}
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              selectedOption={
                watchFormData[`invoice_ndis_rate_${index}`] &&
                NDIS_RATE?.find(
                  (x) => x.value === watchFormData[`invoice_ndis_rate_${index}`]
                )
              }
              //   onChange={handleItemNumberChange}
            />
          </div>
        </div>
      </div>
      <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
        <div className='flex w-full gap-x-4'>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            Rate Amount <span className='text-red-600'>*</span>
          </label>
          <div className='w-full'>
            {watchFormData?.[`invoice_ndis_rate_${index}`] === "alternate" ? (
              <InputField
                isDisabled={watchFormData?.non_billable || disabledAllComponent}
                cssClass='mt-0 h-40-important'
                inputWidth='w-full'
                setValue={setValue}
                // value={watchFormData?.rate_amount}
                name={`rate_amount_${index}`}
                isRequired={true}
                register={register}
                type='number'
                step='0.01'
              />
            ) : (
              <InputField
                isDisabled={true || disabledAllComponent}
                cssClass='mt-0 h-40-important'
                inputWidth='w-full'
                name={`rate_amount_${index}`}
                isRequired={true}
                register={register}
                setValue={setValue}
              />
            )}
          </div>
          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
            Applied Rate
          </label>
          <div className='w-full'>
            <InputField
              isDisabled={true || disabledAllComponent}
              cssClass='mt-0 h-40-important'
              inputWidth='w-full'
              name={`applied_rate_${index}`}
              value={
                watchFormData[`invoice_ndis_rate_${index}`] &&
                getAppliedAmount()
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimesheetBreakdown;
