import React, { useState } from "react";
import moment from "moment";
import Loader from "components/common/Loader/Loader";
import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "Constants/constant";
import { baseURLImage, getNameProfile, textTruncate } from "Utils/utils";
import { toast } from "react-toastify";
import api from "store/services/participant.services";
import { CalendarIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { BiPencil } from "react-icons/bi";
import { HiOutlineTrash } from "react-icons/hi";

const AppointmentItem = (props) => {
  const {
    index,
    meeting,
    setSelectedAppointment,
    setIsOpen,
    participantAppointmentDeleteLocally,
    isUserGracePeriodOver,
  } = props;

  const [deleteAppointmentLoader, setDeleteAppointmentLoader] = useState(null);

  const handleDelete = async (id) => {
    if (isUserGracePeriodOver) return;
    try {
      setDeleteAppointmentLoader(id);
      const response = await api.deleteParticipantAppointment({
        appointmentId: id,
      });
      if (response) {
        participantAppointmentDeleteLocally({ appointmentId: id });
        setDeleteAppointmentLoader(null);
        toast.success("Appointment Deleted Successfully!");
      }
    } catch (err) {
      setDeleteAppointmentLoader(null);
      console.log("err", err);
    }
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <li key={`${index}-${meeting.id}`} className=''>
      <div key={`${index}-${meeting.id}`} className='flex justify-between'>
        <div className='flex space-x-6 py-6'>
          <img
            onError={(e) => handleImageError(e, meeting.name, " ")}
            className='h-12 w-12 rounded-full group-hover:opacity-75 object-cover'
            src={
              meeting?.profile_picture
                ? baseURLImage(meeting.profile_picture)
                : getNameProfile(meeting.name, " ")
            }
            alt='avatar'
          />
          <div className=''>
            <h3
              title={meeting.notes}
              className='pr-10 font-semibold text-gray-900 xl:pr-0'
            >
              {textTruncate(meeting.notes, 90, 89)}
            </h3>
            <dl className='mt-2 flex flex-col text-gray-500 xl:flex-row'>
              <div className='flex items-start space-x-3'>
                <dt className='mt-0.5'>
                  <span className='sr-only'>Date</span>
                  <CalendarIcon
                    className='h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                </dt>
                <dd>
                  <time>
                    {meeting
                      ? `${moment(new Date(meeting.date)).format(
                          "DD MMM, YYYY"
                        )} at ${meeting.time}`
                      : ""}
                  </time>
                </dd>
              </div>
              <div className='mt-2 flex items-start space-x-3 xl:mt-0 xl:ml-3.5 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5'>
                <dt className='mt-0.5'>
                  <span className='sr-only'>Location</span>
                  <LocationMarkerIcon
                    className='h-5 w-5 text-gray-400'
                    aria-hidden='true'
                  />
                </dt>
                <dd>{meeting.location}</dd>
              </div>
            </dl>
          </div>
        </div>
        {roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]) && (
          <div className='flex justify-center items-center space-x-2'>
            <button
              className='bg-white hover:bg-green-500 hover:text-white text-green-600 rounded-md'
              onClick={() => {
                setSelectedAppointment(meeting);
                setIsOpen(true);
              }}
            >
              <div className='icon-pencil-wrapper' title='Edit'>
                <BiPencil className='icon-size' />
              </div>
            </button>
            {deleteAppointmentLoader !== null &&
            deleteAppointmentLoader === meeting.id ? (
              <Loader isSmallView cssClass='text-black' />
            ) : (
              <span
                onClick={() =>
                  !isUserGracePeriodOver && handleDelete(meeting.id)
                }
                className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
              >
                <div className='icon-delete-wrapper' title='Edit'>
                  <HiOutlineTrash className='icon-size' />
                </div>
              </span>
            )}
          </div>
        )}
      </div>
    </li>
  );
};
export default AppointmentItem;
