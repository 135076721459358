import DocumentsPage from "./DocumentsPage";
import { connect } from "react-redux";
import {
  createFolderRequest,
  updateFolderRequest,
  removeFolderRequest,
  removeFileRequest,
  getFolderListForHRMRequest,
  setUpdatedFile,
  handleDynamicBreadCrumbForHRMRequest,
} from "store/actions/cos.actions";
import {
  createNewFolderLoadingSelector,
  createNewFolderSuccessSelector,
  DynamicBreadCrumbSelector,
  getFolderListForHRMLoadingSelector,
  getFolderListForHRMSelector,
  getFileListForHRMSelector,
  getRemoveFolder,
  getRemoveFolderLoading,
  getRemoveFolderError,
} from "store/selector/cos.selector";


const mapDispatchToProps = {
  createFolderRequest,
  updateFolderRequest,
  setUpdatedFile,
  removeFolderRequest,
  removeFileRequest,
  getFolderListForHRMRequest,
  handleDynamicBreadCrumbForHRMRequest,

};

const mapStateToProps = (state) => {
  const getFolderListForHRM = getFolderListForHRMSelector(state);
  const getFileListForHRM = getFileListForHRMSelector(state);
  const DynamicBreadCrumb = DynamicBreadCrumbSelector(state);
  const createNewFolderLoading = createNewFolderLoadingSelector(state);
  const createNewFolderSuccess = createNewFolderSuccessSelector(state);
  const getFolderListForHRMLoading = getFolderListForHRMLoadingSelector(state);
  const removeFolderLoading = getRemoveFolderLoading(state);
  const removeFolderSuccess = getRemoveFolder(state);
  const removeFolderError = getRemoveFolderError(state);

  return {
    DynamicBreadCrumb,
    FolderListForHRM: getFolderListForHRM,
    FileListForHRM: getFileListForHRM,
    createNewFolderLoading,
    createNewFolderSuccess,
    getFolderListForHRMLoading,
    removeFolderLoading,
    removeFolderSuccess,
    removeFolderError,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);
