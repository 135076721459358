import { connect } from "react-redux";
import SubmittedForms from "./SubmittedForms";
import {
  getHrmFormCategories,
  getHrmFormCatsLoader,
  getHrmSubmittedFormListLoader,
  getHrmSubmittedFormsList,
} from "store/selector/employee.selector";
import {
  hrmFormCategoriesListRequest,
  getHrmSubmittedFormsListByCategoryRequest,
} from "store/actions/employee.action";
import { getUser } from "store/selector/auth.selector";
const mapDispatchToProps = {
  hrmFormCategoriesListRequest,
  getHrmSubmittedFormsListByCategoryRequest,
};

const mapStateToProps = (state) => {
  const hrmFormCategories = getHrmFormCategories(state);
  const hrmFormCatsLoader = getHrmFormCatsLoader(state);
  const currentUser = getUser(state);

  const hrmSubmittedFormListLoader = getHrmSubmittedFormListLoader(state);
  const hrmSubmittedFormsList = getHrmSubmittedFormsList(state);
  return {
    hrmFormCategories,
    hrmFormCatsLoader,
    currentUser,
    hrmSubmittedFormListLoader,
    hrmSubmittedFormsList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedForms);
