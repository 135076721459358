import React, { useEffect, useState } from "react";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import api from "store/services/sp.services";
import SelectOption from "components/common/Select/Select";
import { StripeStatusOptions } from "Constants/constant";
import {
  baseURLImage,
  capitalizeFirstLetter,
  convertCentsToDollars,
} from "Utils/utils";
import { HiOutlineDownload, HiOutlineEye } from "react-icons/hi";
import { saveAs } from "file-saver";

const StripeInvoices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoicesData, setInvoicesData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(StripeStatusOptions[0]);

  useEffect(() => {
    getInvoices(StripeStatusOptions[0].value);
  }, []);

  const getInvoices = async (status) => {
    try {
      setIsLoading(true);
      const repsonse = await api.stripeInvoices({ status });
      if (repsonse.invoices.data) {
        setInvoicesData(repsonse.invoices.data);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  return (
    <div className='min-h-full'>
      <div className='px-2'>
        <div className='mb-5 border-gray-200 pt-2'>
          <div>
            {isLoading ? (
              <div className='flex items-center min-h-screen justify-center'>
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <div>
                <div className='flex justify-between items-center py-2 bg-white'>
                  <div className='w-1/3 md:w-2/12 lg:w-2/12'>
                    <SelectOption
                      isDisabled={isLoading}
                      isSearch={false}
                      selectedOption={selectedStatus}
                      handleChange={(e) => {
                        setSelectedStatus(e);
                        getInvoices(e.value);
                      }}
                      data={StripeStatusOptions}
                      // cssClass='z-10'
                    />
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='overflow-x-auto111'>
                    <div className='inline-block min-w-full py-2 align-middle'>
                      <div className='overflow-hidden111 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-white capitalize'>
                            <tr className='sticky top-[80px] z-7'>
                              <th
                                scope='col'
                                className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <div className='flex items-center flex-start space-x-2'>
                                  Invoice Number
                                </div>
                              </th>
                              <th
                                scope='col'
                                className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <div className='flex items-center flex-start space-x-2'>
                                  Plan
                                </div>
                              </th>
                              <th
                                scope='col'
                                className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <div className='flex items-center flex-start space-x-2'>
                                  Paid
                                </div>
                              </th>
                              <th
                                scope='col'
                                className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <div className='flex items-center flex-start space-x-2'>
                                  Status
                                </div>
                              </th>
                              <th
                                scope='col'
                                className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <div className='flex items-center flex-start space-x-2'>
                                  Total Amount
                                </div>
                              </th>
                              <th
                                scope='col'
                                className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <div className='flex items-center flex-start space-x-2'>
                                  Action
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200'>
                            {invoicesData.map((item) => (
                              <tr
                                key={item.id}
                                className='bg-white hover:bg-gray-100'
                              >
                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm'>
                                  <div className='flex items-center'>
                                    <div className=' font-medium text-gray-900'>
                                      {item.number ? item.number : "NA"}
                                    </div>
                                  </div>
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-900'>
                                    {
                                      item?.subscription_details?.metadata
                                        ?.product_name
                                    }
                                  </div>
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-900'>
                                    {item.paid === true ? "Paid" : "Unpaid"}
                                  </div>
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-900'>
                                    {capitalizeFirstLetter(item.status)}
                                  </div>
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-900'>
                                    {`$${convertCentsToDollars(item.total)}`}
                                  </div>
                                </td>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                                  <div className='flex items-center justify-start gap-x-3'>
                                    <a
                                      href={`${item.hosted_invoice_url}`}
                                      without
                                      rel='noopener noreferrer'
                                      target='_blank'
                                      className='icon-eye-wrapper'
                                    >
                                      <HiOutlineEye className='icon-size' />
                                    </a>
                                    <span>
                                      <p
                                        onClick={() =>
                                          saveAs(`${item.invoice_pdf}`)
                                        }
                                        className='cursor-pointer icon-download-wrapper'
                                      >
                                        <HiOutlineDownload className='icon-size' />
                                      </p>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {invoicesData?.length === 0 ? (
                              <tr>
                                <td
                                  colSpan={7}
                                  className='py-6 text-center text-red-600'
                                >
                                  No data found!
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeInvoices;
