import React, { useState, useEffect, useMemo } from "react";
import { Switch } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import Alert from "../../../common/Alert/Alert";
import { useForm } from "react-hook-form";
import { apiPutRequest } from "../../../../helpers/Requests";
import { toast } from "react-toastify";
import _ from "lodash";
import CustomButton from "../../../common/Button/CustomButton";
import ModalSkeleton from "../../../Modals/ModalSkeleton";
import { getSPWorkerRequest } from "../../../../store/actions/sp.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getSpWorkersAndManagers,
} from "../../../../store/selector/sp.selector";
import SelectOption from "../../../common/Select/Select";
import moment from "moment";
import { getUrlRole, parseBooleanVal } from "../../../../Utils/utils";

const RiskRatingModal = (props) => {
  const {
    subSectionIndex,
    fieldIndex,
    sectionIndex,
    show,
    setOpen,
    previousData,
    fieldLabel,
    viewFormData,
    formUUID,
    handleChangeFormData,
    fieldValue,
    isDisabled,
    isReadOnly = false,
    isUserGracePeriodOver,
  } = props;

  const [riskMigrated, setRiskMigrated] = useState(
    previousData ? previousData.riskMigrated : false
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const dispatch = useDispatch();
  const workersAndManagersList = useSelector(getSpWorkersAndManagers);
  const isLoadingSelectOptions = useSelector(getLoading);

  const updatedWorkerAndManagerList = useMemo(() => {
    return workersAndManagersList?.filter(
      (x) => parseBooleanVal(x.status) === true
    );
  }, [workersAndManagersList]);

  const [riskOwner, setRiskOwner] = useState(null);
  const [approvedBy, setApprovedBy] = useState(null);

  useEffect(() => {
    dispatch(getSPWorkerRequest());
  }, []);

  useEffect(() => {
    if (
      previousData &&
      "riskOwner" in previousData &&
      updatedWorkerAndManagerList &&
      updatedWorkerAndManagerList.length > 0
    ) {
      const getRiskOwner = updatedWorkerAndManagerList.find(
        (x) => x.uuid === previousData.riskOwner
      );
      if (getRiskOwner) {
        setRiskOwner(getRiskOwner);
      }
    }

    if (
      previousData &&
      "approvedBy" in previousData &&
      updatedWorkerAndManagerList &&
      updatedWorkerAndManagerList.length > 0
    ) {
      const getApprovedBy = updatedWorkerAndManagerList.find(
        (x) => x.uuid === previousData.approvedBy
      );
      if (getApprovedBy) {
        setApprovedBy(getApprovedBy);
      }
    }
  }, [previousData, updatedWorkerAndManagerList]);

  useEffect(() => {
    if (riskOwner) {
      clearErrors("riskOwner");
      setValue("riskOwner", riskOwner.uuid);
    } else {
      setValue("riskOwner", null);
    }
  }, [riskOwner]);

  useEffect(() => {
    if (approvedBy) {
      clearErrors("approvedBy");
      setValue("approvedBy", approvedBy.uuid);
    } else {
      setValue("approvedBy", null);
    }
  }, [approvedBy]);

  const updateRiskRating = async (data) => {
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiPutRequest(
        `${getUrlRole()}/participant/submitted-form-update?form_id=${formUUID}`,
        {
          template: data,
        }
      );
      if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        toast.success("Mitigation Strategy Added Successfully!");
        setRequest({ ...request, isRequested: false });
        setOpen(false);
        return;
      }
      if (res.status !== 201) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        toast.error("Something Went Wrong");
      }
      setRequest({ ...request, isRequested: false });
    } catch (err) {
      toast.error("Something Went Wrong");
      setRequest({ ...request, isRequested: false, isFailed: true });
    }
  };

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    const mitigationStratgey = {
      // participantName: data.participant,
      strategyDate: data.strategyDate,
      migrationStrategy: data.mitigationStrategy,
      riskMigrated,
    };
    if (riskOwner) {
      Object.assign(mitigationStratgey, {
        riskOwner: riskOwner.uuid,
      });
    }
    if (approvedBy) {
      Object.assign(mitigationStratgey, {
        approvedBy: approvedBy.uuid,
      });
    }
    const obj = _.cloneDeep(viewFormData);

    if (subSectionIndex !== null) {
      Object.assign(
        obj[sectionIndex].input_fields[fieldIndex].sub_fields[subSectionIndex],
        { mitigationStratgey: mitigationStratgey }
      );
    } else {
      Object.assign(obj[sectionIndex].input_fields[fieldIndex], {
        mitigationStratgey: mitigationStratgey,
      });
    }
    handleChangeFormData([...obj]);
    updateRiskRating([...obj]);
  };

  const modalFooter = () => (
    <CustomButton
      variant='primary'
      showLoading={request.isRequested}
      label='Submit Details'
      isDisabled={isDisabled || request.isRequested || isUserGracePeriodOver}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById(
          `riskRatingModal${fieldIndex}`
        );
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <ModalSkeleton
      title='Mitigation Strategy'
      isOpen={show}
      closeModal={() => setOpen(false)}
      cssClass='w-[50%]'
      modalFooter={!isReadOnly ? modalFooter() : null}
    >
      <div className=''>
        <div className='relative bg-white rounded-lg px-4 pt-3 pb-5 text-left transform transition-all sm:mx-auto sm:px-6'>
          <div>
            <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
              <CheckIcon
                className='h-6 w-6 text-green-600'
                aria-hidden='true'
              />
            </div>
            <div className='mt-3 text-center sm:mt-5'>
              <div className='col-span-2 mt-2'>
                <Alert QA={fieldValue} textAlert={fieldLabel} />
              </div>
              <p className='text-sm text-gray-500 mb-2'>
                Please enter the risk mitigation strategy for the aforementioned
                question
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
              <div className='col-span-1'>
                <div>
                  <label
                    htmlFor='text'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Strategy Date
                  </label>
                  <div className='mt-1'>
                    <input
                      type='date'
                      min={moment().format("DD-MM-YYYY")}
                      name='name'
                      id='name'
                      // disabled={isDisabled || isReadOnly}
                      className='p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                      defaultValue={
                        previousData && previousData !== null
                          ? previousData.strategyDate
                          : ""
                      }
                      {...register("strategyDate", {
                        required: true,
                        disabled: isDisabled || isReadOnly,
                      })}
                    />
                    {errors.strategyDate && (
                      <span className='text-red-600'>
                        This field is required
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* Risk Owner */}
              <div className='col-span-1'>
                <div className='hidden'>
                  <select
                    {...register("riskOwner", {
                      required: true,
                    })}
                    name='riskOwner'
                    id=''
                    className='hidden did-floating-input'
                  />
                </div>
                <label className='mb-1 block text-sm font-medium text-gray-700'>
                  Risk Owner
                </label>
                <SelectOption
                  isClearable
                  isSearch={true}
                  data={
                    updatedWorkerAndManagerList &&
                    updatedWorkerAndManagerList.length > 0
                      ? updatedWorkerAndManagerList
                      : []
                  }
                  selectedOption={riskOwner}
                  handleChange={setRiskOwner}
                  // placeHolder={
                  //   isLoadingSelectOptions ? "Loading..." : "Select Risk Owner"
                  // }
                  loading={isLoadingSelectOptions}
                  isDisabled={
                    isLoadingSelectOptions || isDisabled || isReadOnly
                  }
                />
                {!riskOwner && errors?.riskOwner?.type === "required" && (
                  <span className='text-red-500'>This field is required</span>
                )}
              </div>

              {/* Approved By */}
              <div className='col-span-1'>
                <div className='hidden'>
                  <select
                    {...register("approvedBy", {
                      required: true,
                    })}
                    name='approvedBy'
                    id=''
                    className='hidden did-floating-input'
                  />
                </div>
                <label className='mb-1 block text-sm font-medium text-gray-700'>
                  Approved By
                </label>
                <SelectOption
                  isClearable
                  isSearch={true}
                  data={
                    updatedWorkerAndManagerList &&
                    updatedWorkerAndManagerList.length > 0
                      ? updatedWorkerAndManagerList
                      : []
                  }
                  selectedOption={approvedBy}
                  handleChange={setApprovedBy}
                  // placeHolder={
                  //   isLoadingSelectOptions ? "Loading..." : "Select Approved By"
                  // }
                  loading={isLoadingSelectOptions}
                  isDisabled={
                    isLoadingSelectOptions || isDisabled || isReadOnly
                  }
                />
                {!approvedBy && errors?.approvedBy?.type === "required" && (
                  <span className='text-red-500'>This field is required</span>
                )}
              </div>

              <div className='col-span-2'>
                <div>
                  <label
                    htmlFor='comment'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Mitigation Strategy
                  </label>
                  <div className='mt-2'>
                    <textarea
                      rows={3}
                      name='comment'
                      id='comment'
                      // disabled={isDisabled || isReadOnly}
                      className='border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md resize-none overflow-y-auto'
                      defaultValue={
                        previousData && previousData !== null
                          ? previousData.migrationStrategy
                          : ""
                      }
                      {...register("mitigationStrategy", {
                        required: true,
                        disabled: isDisabled || isReadOnly,
                      })}
                    />
                    {errors.mitigationStrategy && (
                      <span className='text-red-600'>
                        This field is required
                      </span>
                    )}
                    <p className='text-sm text-gray-500 pt-1'>
                      Please enter the mitigation strategy here. This strategy
                      is viewable throughout the organization.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-span-2'>
                <Switch.Group as='div' className='flex items-center'>
                  <Switch
                    checked={riskMigrated}
                    onChange={setRiskMigrated}
                    disabled={isDisabled || isReadOnly}
                    className={classNames(
                      riskMigrated ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    )}
                  >
                    <span
                      aria-hidden='true'
                      className={classNames(
                        riskMigrated ? "translate-x-5" : "translate-x-0",
                        "h-full w-[55%] pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                      )}
                    />
                  </Switch>
                  <Switch.Label as='span' className='ml-3'>
                    <p className='text-sm font-medium text-gray-900'>
                      Risk Mitigated
                    </p>
                    <span className='text-sm text-gray-500'>
                      Please turn this checkbox on if the risk has been catered
                    </span>
                  </Switch.Label>
                </Switch.Group>
              </div>
              <button
                type='submit'
                className='hidden sr-only'
                id={`riskRatingModal${fieldIndex}`}
              />
            </div>
          </form>
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default RiskRatingModal;
