import TwoFANotEnabledPage from "./TwoFANotEnabledPage";
import { connect } from "react-redux";
// import {} from "../../../../store/actions/participant.actions";
// import {} from "../../../../store/selector/participant.selector";

import { sendOTPRequest, reSendOTPRequest } from "store/actions/auth.actions";
import { sendOTPLoadingSelector } from "store/selector/auth.selector";

const mapDispatchToProps = {
  sendOTPRequest,
  reSendOTPRequest,
};

const mapStateToProps = (state) => {
  const sendOTPLoading=sendOTPLoadingSelector(state)
  return {
    sendOTPLoading,

  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFANotEnabledPage);
