import React, { useState, useEffect } from "react";
import { hasBlankSpaces } from "../../../../Utils/utils";
import InputField from "../../../../components/common/Input/Input";
import moment from "moment";
import {
  APPLICANT_STATUS,
  APPLICANT_STATUS_UUID,
  REGEX_PHONE_NUMBER,
} from "../../../../Constants/constant";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import { MdEmail } from "react-icons/md";
import { checkPhoneNo } from "Utils/FormValidation";

const InterviewModal = (props) => {
  const {
    showModal,
    closeModalHandler,
    title,
    applicant,
    handleSubmitSchedule,
    isUserGracePeriodOver,
  } = props;
  const [newObj, setNewObj] = useState({
    date: "",
    time: "",
    phone: "",
    address: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const closeModal = () => {
    closeModalHandler();
  };

  const handleSubmit = () => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (validateInput()) return;
    closeModal();
    handleSubmitSchedule(newObj, {
      label: APPLICANT_STATUS.interview,
      value: APPLICANT_STATUS_UUID.Interview,
      isCheck: false,
    });
  };

  useEffect(() => checkValidInterviewDate(), [newObj.date]);

  const checkValidInterviewDate = () => {
    if (
      newObj.date &&
      !moment(newObj.date).isSame(new Date(), "day") &&
      moment(newObj.date).isBefore()
    ) {
      setErrors({
        ...errors,
        interviewDate: "Interview Date should be today or future",
      });
    } else {
      setErrors({
        ...errors,
        interviewDate: null,
      });
    }
  };

  const validateInput = () => {
    const fnErrors = errors;
    if (!newObj.date || newObj.date.length === 0) {
      Object.assign(fnErrors, { interviewDate: "This field is required!" });
    }
    if (!newObj.time || newObj.time.length === 0) {
      Object.assign(fnErrors, { interviewTime: "This field is required!" });
    }
    if (!newObj.phone || hasBlankSpaces(newObj.phone)) {
      Object.assign(fnErrors, { phone: "This field is required!" });
    }
    if (!newObj.message || hasBlankSpaces(newObj.message)) {
      Object.assign(fnErrors, { message: "This field is required!" });
    }
    if (!newObj.address || hasBlankSpaces(newObj.address)) {
      Object.assign(fnErrors, { address: "This field is required!" });
    }
    checkValidInterviewDate();
    if (Object.values(fnErrors).filter((x) => x !== null).length > 0) {
      setErrors({ ...fnErrors });
      return true;
    }
    return false;
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (hasBlankSpaces(value) || value.length === 0) {
      // setErrors({ ...errors, [e.target.name]: `${name} is Required` });
      setErrors({ ...errors, [e.target.name]: `This field is required!` });
    } else if (name === "interviewDate") {
      checkValidInterviewDate();
    } else if (errors[e.target.name] && value.length > 0) {
      setErrors({ ...errors, [e.target.name]: null });
    }
    if (name === "phone" && value.length > 0) {
      const inValidPhoneNo = checkPhoneNo(value);
      // console.log({ inValidPhoneNo });
      if (inValidPhoneNo) {
        setErrors({ ...errors, phone: inValidPhoneNo });
      } else {
        setErrors({ ...errors, phone: null });
      }
    }
  };

  // const getError = (value) => {
  //   if (!value) return "";
  //   return errors[value] && errors[value].length > 0 ? errors[value] : "";
  //   // return errors[value] && errors[value].length > 0
  //   //   ? "This field is required!"
  //   //   : "";
  // };

  const getError = (value) => {
    if (!value) return "";
    // return errors[value] && errors[value].length > 0 ? errors[value] : "";

    return errors[value] && errors[value].length > 0 && value !== "phone"
      ? `Field is Required`
      : errors[value] && errors[value].length > 0 && value === "phone"
      // ? "Phone No required & must start with 04, 1300 or 1800 & 10 digits"
      ? "Please enter a valid phone number!"
      : "";
  };

  const modalFooterInterviewSchedule = () => (
    <>
      <CustomButton
        clickHandler={closeModal}
        type='button'
        label='Close'
        variant='danger'
      />
      <CustomButton
        clickHandler={handleSubmit}
        isDisabled={isUserGracePeriodOver}
        type='button'
        Icon={<MdEmail />}
        label='Send Invite'
      />
    </>
  );

  return (
    <>
      {showModal && (
        <ModalSkeleton
          isOpen={showModal}
          cssClass='w-[50%]'
          title={title}
          closeModal={closeModal}
          modalFooter={modalFooterInterviewSchedule()}
        >
          {/* <p className="mb-3 text-sm text-gray-500">
            
          </p> */}
          <div className='md:grid '>
            <div className='mt-5 md:mt-0'>
              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <InputField
                    readOnly
                    label='Applicant Name'
                    type='text'
                    handleChange={() => {}}
                    value={applicant.name}
                    name='applicantName'
                  />
                </div>
                <div>
                  <InputField
                    readOnly
                    label='Applicant Email'
                    type='text'
                    handleChange={() => {}}
                    value={applicant.email || ""}
                    name='applicantEmail'
                  />
                </div>
                <div>
                  <InputField
                    label='Interview Date'
                    // min={moment().format("DD-MM-YYYY")}
                    type='date'
                    handleChange={(e) => setNewObj({ ...newObj, date: e })}
                    value={newObj.date}
                    name='interviewDate'
                    blurHandler={handleBlur}
                    ErrorMessage={getError("interviewDate")}
                  />
                </div>
                <div>
                  <InputField
                    label='Interview Time'
                    type='time'
                    handleChange={(e) => setNewObj({ ...newObj, time: e })}
                    value={newObj.time}
                    name='interviewTime'
                    blurHandler={handleBlur}
                    ErrorMessage={getError("interviewTime")}
                  />
                </div>
                <div>
                  <InputField
                    maxLength='10'
                    label='HR Phone Number'
                    pattern={REGEX_PHONE_NUMBER}
                    type='number'
                    handleChange={(e) => {
                      const sanitizedValue = e.replace(/\D/g, "");
                      const formattedValue = sanitizedValue.slice(0, 10);
                      setNewObj({ ...newObj, phone: formattedValue });
                    }}
                    value={newObj.phone}
                    ErrorMessage={getError("phone")}
                    blurHandler={handleBlur}
                    name='phone'
                  />
                </div>
                <div className='col-span-2'>
                  <label
                    htmlFor='Address'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Address/Online link
                  </label>
                  <textarea
                    onBlur={(e) => handleBlur(e)}
                    value={newObj.address}
                    onChange={(e) =>
                      setNewObj({
                        ...newObj,
                        address: e.target.value,
                      })
                    }
                    name='address'
                    rows={3}
                    className='mt-1 overflow-y-auto focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                  {getError("address") && (
                    <span className='errorMessage'>{getError("address")}</span>
                  )}
                </div>
                <div className='col-span-2'>
                  <label
                    htmlFor='Address'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Message
                  </label>
                  <textarea
                    onBlur={(e) => handleBlur(e)}
                    value={newObj.message}
                    onChange={(e) =>
                      setNewObj({
                        ...newObj,
                        message: e.target.value,
                      })
                    }
                    name='message'
                    rows={3}
                    className='mt-1 focus:ring-indigo-500 overflow-y-auto focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'
                  />
                  {getError("message") && (
                    <span className='errorMessage'>{getError("message")}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalSkeleton>
      )}
    </>
  );
};

export default InterviewModal;
