import React, { useEffect } from "react";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { useForm } from "react-hook-form";
import { updateFolderSuccessMessge } from "store/actions/cos.actions";
import { useDispatch } from "react-redux";

function CreateFolderModel(props) {
  const {
    role,
    setParentId,
    parentId,
    updateFolder,
    setCurrentFolder,
    createNewFolderSuccess,
    createNewFolderLoading,
    handleClose,
    isOpen,
    createFolderRequest,
    id,
    currentFolder,
    isUserGracePeriodOver,
  } = props;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (currentFolder) {
      setValue("folder_Name", currentFolder.name);
    }
    // return () => setCurrentFolder(null);
  }, [currentFolder]);

  const modalFooter = () => (
    <>
      <CustomButton
        label='Cancel'
        variant='secondary'
        clickHandler={() => {
          handleClose(false);
          reset({
            folder_Name: " ",
          });
          const buttonRef = document.getElementById("Cancel");
          if (buttonRef) {
            buttonRef.click();
          }
        }}
      />
      <CustomButton
        showLoading={createNewFolderLoading}
        isDisabled={createNewFolderLoading || isUserGracePeriodOver}
        label={currentFolder ? "Update" : "Create"}
        variant='primary'
        // type="submit"
        clickHandler={() => {
          if (isUserGracePeriodOver) {
            return;
          }
          const buttonRef = document.getElementById("Create");
          if (buttonRef) {
            buttonRef.click();
          }
        }}
      />
    </>
  );

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (currentFolder) {
      updateFolder({
        folder_id: currentFolder.id,
        // parent_id: parentId,
        name: data.folder_Name,
      });
    } else {
      createFolderRequest({
        worker_id: id,
        name: data.folder_Name,
        parent_id: parentId ? parentId : null,
        role: role,
      });
    }
  };

  useEffect(() => {
    if (createNewFolderSuccess) {
      reset({
        folder_Name: " ",
      });
      handleClose(false);
      dispatch(updateFolderSuccessMessge(false));
    }
  }, [createNewFolderSuccess]);

  return (
    <ModalSkeleton
      title={`${currentFolder ? "Update Folder Name" : "Create New Folder"} `}
      cssClass='w-[35%]'
      modalFooter={modalFooter()}
      isOpen={isOpen}
      closeModal={() => handleClose(false)}
    >
      <div className='flex flex-col'>
        <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
          <div>
            <div className='grid grid-cols-2 gap-x-4'>
              <div className='col-span-2 sm:col-span-2'>
                <div className='mt-3 '>
                  <label className='block text-sm font-medium text-gray-700'>
                    Name
                  </label>
                  <input
                    {...register("folder_Name", {
                      required: false,
                      maxLength: 50,
                    })}
                    type='text'
                    className='did-floating-input'
                  />
                  {errors?.folder_Name?.type === "required" && (
                    <span className='text-sm text-red-500'>
                      This field is required!
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button className='hudden sr-only' type='submit' id='Create' />
          <button className='hudden sr-only' type='Button' id='Cancel' />
        </form>
      </div>
    </ModalSkeleton>
  );
}

export default CreateFolderModel;
