import React from "react";
import { HiPlusSm } from "react-icons/hi";
import WorkerAvailabiltyModal from "components/Modals/WorkerAvailabiltyModal";
import { convertTo12HourFormat } from "Utils/utils";

const WorkerAvailabilty = ({
  WorkerId,
  setRefreshPageForWorkerTimeSlot,
  worker,
  isWorkerAvailablity,
  isUserGracePeriodOver,
}) => {
  const [openModal, setOpenModal] = React.useState(false);

  // Parse the JSON data for worker availability

  const availabilityData =
    worker?.availability && JSON.parse(worker?.availability);

  return (
    <div>
      <label className='text-md font-bold'>Worker Availability</label>

      <div className='px-3 mt-1 mb-10'>
        <div className='flex justify-end text-blue-400 '>
          <div
            className='flex items-center cursor-pointer '
            onClick={() => setOpenModal(true)}
          >
            <span>
              <HiPlusSm />
            </span>
            <span className='text-sm font-semibold'>Add Availability</span>
          </div>
        </div>
        <div className='overflow-x-auto mt-1'>
          <table className='border border-collapse w-full'>
            <thead>
              <tr className='bg-[#e1e5f0]'>
                <th className='border p-2'>Day</th>
                <th className='border p-2'>Availability</th>
              </tr>
            </thead>
            {availabilityData ? (
              <tbody>
                {/* Render availability data for each day */}
                {Object.keys(availabilityData).map((day) => (
                  <tr key={day}>
                    <td className='border p-2 capitalize text-center'>{day}</td>
                    <td className='border p-2'>
                      {availabilityData[day]?.length > 0 ? (
                        <ul className='text-center'>
                          {availabilityData[day].map((slot, index) => (
                            <li key={index}>
                              {slot.from && slot.to
                                ? `${convertTo12HourFormat(
                                    slot.from
                                  )} - ${convertTo12HourFormat(slot.to)}`
                                : "Not Available"}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span className='text-red-500'>Not available</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan='2'>
                    <div className='text-sm text-center text-gray-500 p-3'>
                      No data available
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
      {openModal && (
        <WorkerAvailabiltyModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          openModel={openModal}
          handleClose={setOpenModal}
          WorkerId={WorkerId}
          setRefreshPageForWorkerTimeSlot={setRefreshPageForWorkerTimeSlot}
          TimeSlots={availabilityData}
          isWorkerAvailablity={isWorkerAvailablity}
        />
      )}
    </div>
  );
};

export default WorkerAvailabilty;
