import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  emptyPaginationObj,
  LOADER_STATUS,
  PLAN_BUDGET_LIMIT,
} from "../../../../Constants/constant";
import { getPaginationObj } from "../../../../Utils/utils";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import PaginationComponent from "../../../../components/common/Pagination/index";
import TableHeader from "../../../../components/common/Table/TableHeader";
import { useParams } from "react-router-dom";
import FundingNotesItem from "./FundingNotesItem";
import { LoginContext } from "helpers/LoginContext";

const FundingNotesTable = (props) => {
  const {
    fetchLoader,
    removeFundingNotes,
    removeLoader,
    funding,
    setEditFundingNotes,
    getFundingNotesList,
    fundingNotes,
  } = props;

  let { uuid: paramsFundingId } = useParams();

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [paginationObj, setPaginationObj] = useState(
    fundingNotes ? getPaginationObj(fundingNotes) : emptyPaginationObj
  );
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    if (selectedId && !removeLoader) {
      setSelectedId(null);
    }
  }, [removeLoader]);

  useEffect(() => {
    if (fundingNotes) {
      setPaginationObj({
        ...getPaginationObj(fundingNotes),
      });
    }
  }, [fundingNotes]);

  useEffect(() => {
    if (
      !fundingNotes ||
      (Object.keys(fundingNotes).length > 0 &&
        fundingNotes.hasOwnProperty("count") &&
        fundingNotes.count === 0)
    )
      fetchFundingNotes();
  }, []);

  useEffect(() => {
    if (fundingNotes && fundingNotes.current_page !== paginationObj.page) {
      fetchFundingNotes({ page: paginationObj.page });
    }
    if (
      fundingNotes &&
      fundingNotes.total > 0 &&
      fundingNotes.data.length === 0
    ) {
      fetchFundingNotes({ page: fundingNotes.current_page });
    }
  }, [paginationObj.page]);

  const fundingNotesToMap = useMemo(() => {
    if (fundingNotes) {
      return fundingNotes.data;
    }
    return [];
  }, [fundingNotes]);

  const fetchFundingNotes = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: PLAN_BUDGET_LIMIT });
    Object.assign(filters, {
      funding_id: funding.uuid ? funding.uuid : paramsFundingId,
    });
    if (renderLoading === LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.PENDING);
    }
    getFundingNotesList(filters);
  };

  return (
    <>
      <div className='flex flex-col'>
        <div className='min-w-full align-middle'>
          <div className='overflow-hidden border'>
            <table className='w-full divide-y divide-gray-300 table-fixed'>
              <thead className='bg-gray-50 capitalize'>
                <tr>
                  <TableHeader
                    sortName='note'
                    isSort={false}
                    label={"Notes"}
                    cssClass='w-[40%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='type'
                    label={"Type"}
                    cssClass='w-[20%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='create_at'
                    label={"Date"}
                    cssClass='w-[20%]'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='Actions'
                    label={"Actions"}
                    cssClass='w-20%]'
                  />
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {fetchLoader ? (
                  <tr>
                    <td colSpan={4} className='bg-gray-50 py-2'>
                      <CircularLoader
                        classes='flex justify-center items-center'
                        classloader='loader-l'
                      />
                    </td>
                  </tr>
                ) : fundingNotesToMap.length > 0 ? (
                  fundingNotesToMap.map((notes, index) => (
                    <FundingNotesItem
                      isUserGracePeriodOver={isUserGracePeriodOver}
                      notes={notes}
                      index={index}
                      setEditFundingNotes={setEditFundingNotes}
                      fundingNotesRemoved={removeFundingNotes}
                    />
                  ))
                ) : (
                  renderLoading !== LOADER_STATUS.NA &&
                  fundingNotesToMap.length === 0 && (
                    <tr>
                      <td colSpan={4} className='py-6 text-center text-red-600'>
                        No Data found!
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='bg-white py-4'>
        <PaginationComponent
          page={paginationObj.page}
          activeListLength={fundingNotesToMap}
          limit={PLAN_BUDGET_LIMIT}
          totalList={
            paginationObj.totalPages === 1
              ? fundingNotesToMap.length
              : paginationObj.total
          }
          handlePagination={(e) => {
            const obj = paginationObj;
            obj.page = e;
            setPaginationObj({ ...obj });
            // handleFetch({}, sortBy);
          }}
          totalPages={paginationObj.totalPages}
        />
      </div>
    </>
  );
};

export default FundingNotesTable;
