import React from "react";
import { connect } from "react-redux";
import { getUser } from "store/selector/auth.selector";

const Version = ({ sideBarPadding, currentLoginUser }) => {
  return (
    <>
      <p className={`text-xs text-white`}>
        {!sideBarPadding ? (
          <>
            Version: <b>{currentLoginUser && currentLoginUser?.release_version &&  currentLoginUser?.release_version?.version}</b>
          </>
        ) : (
          currentLoginUser && currentLoginUser?.release_version &&  currentLoginUser?.release_version?.version
        )}
      </p>
    </>
  );
};

const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);
  return { currentLoginUser };
};

export default connect(mapStateToProps, null)(Version);
