import { connect } from "react-redux";
import Feedback from "./Feedback";
import { getFeedbackRequest } from "../../../store/actions/sp.actions";
import { getLoading, getSPFeedback } from "../../../store/selector/sp.selector";

const mapDispatchToProps = {
  //actions
  getFeedbackList: getFeedbackRequest,
};

const mapStateToProps = (state) => {
  //selectors
  const feedbackObj = getSPFeedback(state);
  const loading = getLoading(state);
  return { feedbackObj, loading };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
