import React from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { FaClone } from "react-icons/fa";
import { HiOutlineEye } from "react-icons/hi";
import { MdOutlineNotStarted } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {
  USER_ROLE,
  PARENT_FORM_CATEGORY_SLUG,
} from "../../../Constants/constant";
import { roleAccess } from "../../../helpers/RolesPermission";
import { parseBooleanVal } from "../../../Utils/utils";
import Loader from "../../common/Loader/Loader";

const FormsActionButtons = (props) => {
  const {
    role,
    item,
    parentSlug,
    participantsUid,
    participantId,
    selectedPdfId,
    handleDownload,
  } = props;

  const navigate = useNavigate();

  const handleCloneInitialAssForm = (uuid) => {
    navigate(
      `/view-template/${participantsUid}/${parentSlug}/initial-assessment-form`,
      {
        state: {
          isCloneFormUUID: uuid,
        },
      }
    );
  };

  return (
    <>
      <Link
        to={`/${
          role === "hrm"
            ? "worker"
            : roleAccess([USER_ROLE.Sp_Standard_User])
            ? "participant"
            : "sp"
        }-profile/${parentSlug}/${item.uuid}/view`}
        state={{ particpantUuid: participantsUid }}
        className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
      >
        <div className='icon-eye-wrapper'>
          <HiOutlineEye className='icon-size' />
        </div>
      </Link>

      {roleAccess([USER_ROLE.Sp_Standard_User]) &&
        item.is_complete &&
        (item.category_name === PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.NAME ||
          item.category_name === PARENT_FORM_CATEGORY_SLUG.MEDICATION.NAME ||
          item.category_name ===
            PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.NAME) && (
          <Link
            role='button'
            to={`${
              role === "hrm" ? "/worker" : "/participant"
            }-profile/${parentSlug}/${item.uuid}/update`}
            state={{ particpantUuid: participantsUid }}
            className='text-indigo-600 hover:text-indigo-900'
          >
            <div className='icon-pencil-wrapper'>
              <BiPencil className='icon-size' />
            </div>
          </Link>
        )}

      {!roleAccess([USER_ROLE.Sp_Standard_User]) &&
        parseBooleanVal(item.is_complete) && (
          <Link
            role='button'
            to={`${role === "hrm" ? "/worker" : "/sp"}-profile/${parentSlug}/${
              item.uuid
            }/update`}
            state={{
              participantId: participantId,
              particpantUuid: participantsUid,
            }}
            className='text-indigo-600 hover:text-indigo-900'
          >
            <div className='icon-pencil-wrapper'>
              <BiPencil className='icon-size' />
            </div>
          </Link>
        )}

      {!roleAccess([USER_ROLE.Sp_Standard_User]) &&
        !parseBooleanVal(item.is_complete) && (
          <Link
            to={`/view-template/${
              role === "hrm" ? "hrm/" : ""
            }${participantsUid}/${parentSlug}/${item.uuid}/${window.btoa(
              encodeURIComponent("false")
            )}`}
            className='text-indigo-600 hover:text-indigo-900'
          >
            <div className='icon-stopPlay-wrapper'>
              <MdOutlineNotStarted className='icon-size' />
            </div>
          </Link>
        )}

      {item.title &&
        item.title.toLowerCase() === "ndis service agreement form" &&
        parseBooleanVal(item.is_complete) && (
          <>
            {selectedPdfId === item.uuid ? (
              <div>
                <Loader cssClass='text-black mb-1' />
              </div>
            ) : (
              <div
                onClick={() => handleDownload(item.uuid)}
                className='icon-filePdf-wrapper cursor-pointer'
              >
                <AiOutlineFilePdf className='icon-size' />
              </div>
            )}
          </>
        )}

      {item.title &&
        item.title.toLowerCase() === "initial assessment form" &&
        parseBooleanVal(item.is_complete) &&
        roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]) && (
          <div
            onClick={() => handleCloneInitialAssForm(item.uuid)}
            className='icon-clone-wrapper cursor-pointer'
          >
            <FaClone className='icon-size' />
          </div>
        )}
    </>
  );
};

export default FormsActionButtons;
