import React, { useState, useEffect } from "react";
import { apiPostRequest } from "helpers/Requests";
import { toast } from "react-toastify";
import InputField from "components/common/Input/Input";
import {
  capitalizeAllLetters,
  checkPasswordValidity,
  hasBlankSpaces,
} from "Utils/utils";
import SwitchInput from "components/common/Input/SwitchInput";
import CustomButton from "components/common/Button/CustomButton";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const PasswordSetting = (props) => {
  const { worker, isUserGracePeriodOver } = props;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);

  const [passwordType, setPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false);

  const handleSubmitPassword = async (obj) => {
    const data = {
      password: obj.password,
      password_confirmation: obj.confirmPassword,
      notify_with_mail: obj.enabled,
    };
    try {
      setIsLoadingPassword(true);
      const res = await apiPostRequest(
        `/hrm/employee/change-password?profile_id=${worker.uuid}`,
        data
      );
      if (res.status === 422) {
        toast.error(res.data.message);
      }
      if (res.status === 200) {
        toast.success(capitalizeAllLetters(res.data.message));
      }
      setIsLoadingPassword(false);
    } catch (err) {
      console.log("err", err);
      setIsLoadingPassword(false);
      toast.error(err ? err.message : "something went wrong!");
    }
  };

  useEffect(() => {
    if (!isLoadingPassword && apiCall) {
      handleClear();
    }
  }, [isLoadingPassword]);

  const handleClear = () => {
    setPassword("");
    setConfirmPassword("");
    setEnabled(false);
    setErrors([]);
    setApiCall(false);
  };

  const validateInput = () => {
    const fnErrors = errors;
    if (!password || password.length === 0) {
      Object.assign(fnErrors, { password: "Password is Required" });
    }
    if (!confirmPassword || confirmPassword.length === 0) {
      Object.assign(fnErrors, {
        confirmPassword: "Confirm Password is Required",
      });
    }
    if (Object.values(fnErrors).filter((x) => x !== null).length > 0) {
      setErrors({ ...fnErrors });
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (validateInput()) return;
    setApiCall(true);
    handleSubmitPassword({
      password,
      confirmPassword,
      enabled,
    });
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({ ...errors, [e.target.name]: `${name} is Required` });
    } else if (
      (name === "Password" || name === "Confirm Password") &&
      value &&
      value.length > 0
    ) {
      if (checkPasswordValidity(password) !== null) {
        setErrors({
          ...errors,
          password: checkPasswordValidity(password),
        });
      } else if (password !== confirmPassword) {
        setErrors({
          ...errors,
          password: "Password does not match",
          confirmPassword: "Confirm Password does not match",
        });
      } else {
        setErrors({
          ...errors,
          password: null,
          confirmPassword: null,
        });
      }
    } else if (errors[e.target.name] && value.length > 0) {
      setErrors({ ...errors, [e.target.name]: null });
    }
  };
  const getError = (value) => {
    if (!value) return "";
    return errors[value] && errors[value].length > 0 ? errors[value] : "";
    // return errors[value] && errors[value].length > 0
    //   ? "The Field is required!"
    //   : "";
  };

  return (
    <div className='grid grid-cols-2 gap-y-4 gap-x-5 pt-4 items-center'>
      <div>
        <InputField
          label='New Password'
          type={passwordType ? "text" : "password"}
          handleChange={setPassword}
          value={password}
          name='Password'
          blurHandler={handleBlur}
          ErrorMessage={getError("password")}
          icon={!passwordType ? <AiFillEyeInvisible /> : <AiFillEye />}
          handleClickIcon={() => setPasswordType(!passwordType)}
        />
      </div>
      <div>
        <InputField
          label='Confirm New Password'
          type={confirmPasswordType ? "text" : "password"}
          handleChange={setConfirmPassword}
          value={confirmPassword}
          name='Confirm Password'
          blurHandler={handleBlur}
          ErrorMessage={getError("confirmPassword")}
          icon={!confirmPasswordType ? <AiFillEyeInvisible /> : <AiFillEye />}
          handleClickIcon={() => setConfirmPasswordType(!confirmPasswordType)}
        />
      </div>
      <div>
        <SwitchInput
          handleChange={setEnabled}
          checked={enabled}
          label='Email Notifications'
          subLabel="Send an email to employee's email confirming the password change"
        />
      </div>
      <div className='w-100 text-right'>
        <CustomButton
          isDisabled={isLoadingPassword || isUserGracePeriodOver}
          showLoading={isLoadingPassword}
          cssClass='px-5'
          label='Change Password'
          clickHandler={handleSubmit}
        />
      </div>
    </div>
  );
};

export default PasswordSetting;
