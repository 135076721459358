import { connect } from "react-redux";
import settingsPage from "./SettingsPage.jsx";
import { emptyNewData } from "store/actions/cos.actions";

const mapDispatchToProps = {
  emptyNewData,
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(settingsPage);
