import { connect } from "react-redux";
import FundingBudget from "./FundingBudget";
import {
  getParticipantCall,
  resetParticipant,
  resetSelectedParticipant,
} from "../../../../store/actions/participant.actions";
import {
  getSelectedFundingSetupRequest,
  resetParticipantFundings,
} from "../../../../store/actions/cos.actions";
import {
  getSelectedFunding,
  getSelectedFundingLoading,
} from "../../../../store/selector/cos.selector";
import {
  getLoading,
  getSelectedParticipant,
} from "../../../../store/selector/participant.selector";

const mapDispatchToProps = {
  resetParticipant,
  getFundingSetup: getSelectedFundingSetupRequest,
  resetSelectedParticipant,
  resetParticipantFundings,
  getParticipant: getParticipantCall,
};

const mapStateToProps = (state) => {
  const participant = getSelectedParticipant(state);
  const isLoading = getSelectedFundingLoading(state);
  const selectedFunding = getSelectedFunding(state);
  const getParticipantLoader = getLoading(state);
  return { participant, isLoading, selectedFunding, getParticipantLoader };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundingBudget);
