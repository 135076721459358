import React, { useState } from "react";
import CustomButton from "../../common/Button/CustomButton";
import HumanBody from "../../common/HumanBody/HumanBody";
import ModalSkeleton from "../ModalSkeleton";
const HumanBodyModal = ({
  isOpen,
  closeModal,
  handleSubmit,
  selectedValue,
  view = false,
  title,
}) => {
  const [bodyPart, setBodyPart] = useState(selectedValue);
  const [error, setError] = useState("");

  const handleSave = () => {
    if (typeof bodyPart === "string" && !bodyPart) {
      setError("Select body part!");
      return;
    }
    handleSubmit(bodyPart);
    setError("");
  };

  const modalFooterButton = () => {
    if (!view) return <CustomButton label="Submit" clickHandler={handleSave} />;
    return null;
  };

  return (
    <ModalSkeleton
      title={title ? title : "Select Body Part"}
      isOpen={isOpen}
      cssClass="w-[60%]"
      closeModal={closeModal}
      modalFooter={modalFooterButton()}
    >
      <HumanBody
        view={view}
        error={error}
        bodyPart={bodyPart}
        handleChange={setBodyPart}
      />
    </ModalSkeleton>
  );
};
export default HumanBodyModal;
