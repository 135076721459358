import { connect } from "react-redux";
import {
  getContactSettingRequest,
  createNewContactRequest,
  updateContactRequest,
  emptyNewData,
  emptySettingData,
} from "store/actions/cos.actions";
import {
  createNewContactErrorSelector,
  createNewContactLoadingSelector,
  createNewContactSelector,
  getCosContactSetting,
  getCosSettingContactTypeLoader,
  updateContactErrorSelector,
  updateContactLoadingSelector,
  updateContactSuccessSelector,
} from "store/selector/cos.selector";
import ContactPage from "./ContactPage.jsx";

const mapDispatchToProps = {
  createNewContactRequest,
  getContactSetting: getContactSettingRequest,
  updateContactRequest,
  emptyNewData,
  emptySettingData,
};

const mapStateToProps = (state) => {
  const createNewContactLoading = createNewContactLoadingSelector(state);
  const createNewContactError = createNewContactErrorSelector(state);
  const updateContactLoading = updateContactLoadingSelector(state);
  const updateContactError = updateContactErrorSelector(state);
  const cosContactSetting = getCosContactSetting(state);
  const createNewContact = createNewContactSelector(state);
  const updateContactSuccess = updateContactSuccessSelector(state);
  const getListLoader = getCosSettingContactTypeLoader(state);

  return {
    updateContactSuccess,
    createNewContactLoading,
    createNewContactError,
    updateContactLoading,
    cosContactSetting,
    createNewContact,
    getListLoader,
    updateContactError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
