import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "../../../../components/common/Button/CustomButton";
import FundingNotesModal from "./FundingNotesModal";
import FundingNotesTable from "./FundingNotesTable";

const FundingNotes = (props) => {
  const {
    selectedFunding,
    participant,
    getFundingNotesList,
    fundingNotes,
    fundingNotesLoader,
    createFundingNotes,
    storeFundingNotesLoading,
    storeFundingNotesError,
    // removeFundingNotes,
    removeFundingNoteLoader,
    fundingNotesRemoved,
  } = props;

  const [editFundingNotes, setEditFundingNotes] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (editFundingNotes) {
      setOpenModal(true);
    }
  }, [editFundingNotes]);

  return (
    <div className="max-w-7xl mx-auto py-3 bg-white">
      <div>
        <div  className="flex justify-between items-center px-6 bg-gray-25 border md:rounded-t-lg font-bold text-lg text-gray-900 py-4">
          <div>Funding Notes</div>
          <CustomButton
            label="Funding Notes"
            clickHandler={() => setOpenModal(true)}
            Icon={<BiPlus />}
            cssClass="border-0"
          />
        </div>
        <div>
          <FundingNotesTable
            getFundingNotesList={getFundingNotesList}
            fundingNotes={fundingNotes}
            funding={selectedFunding}
            participant={participant}
            fetchLoader={fundingNotesLoader}
            removeLoader={removeFundingNoteLoader}
            removeFundingNotes={fundingNotesRemoved}
            setEditFundingNotes={setEditFundingNotes}
          />
        </div>
      </div>

      {openModal && (
        <FundingNotesModal
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
            setEditFundingNotes(null);
          }}
          funding={selectedFunding}
          createFundingNotes={createFundingNotes}
          storeFundingNotesLoading={storeFundingNotesLoading}
          storeFundingNotesError={storeFundingNotesError}
          participant={participant}
          editFundingNotes={editFundingNotes}
          getFundingNotesList={getFundingNotesList}
          fundingNotes={fundingNotes}
        />
      )}
    </div>
  );
};

export default FundingNotes;
