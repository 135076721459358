import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import {
  EMAIL_REGEX_EXP,
  REGEX_NDIS_NUMBER,
  REGEX_PHONE_NUMBER,
  STATES_LIST,
} from "Constants/constant";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { apiPutRequest } from "helpers/Requests";
import { toast } from "react-toastify";
import InputField from "components/common/Input/Input";

const ViewExternalServiceProviderModal = (props) => {
  const {
    isUserGracePeriodOver,
    handleClose,
    open,
    setOpen,
    selectedServiceProvider,
    refreshServiceProviderList,
  } = props;
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const {
    uuid,
    name,
    email,
    phone,
    street,
    state,
    suburb,
    postcode,
    billing_contact,
    abn,
    ndis_regis_no,
    bsb_no,
    account_title,
    //account_name,
    account_number,
    provider_no,
    send_remittance,
  } = selectedServiceProvider;

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  // const handleView = async (data) => {
  //   setRequest({ isRequested: true, isSuccess: false, isFailed: false });
  //   try {
  //     const response = await apiPutRequest(
  //       `/cos/external-sp-view/${uuid}`,
  //       data
  //     );
  //     setRequest({ isRequested: false, isSuccess: true, isFailed: false });
  //   } catch (error) {
  //     setRequest({ isRequested: false, isSuccess: false, isFailed: true });
  //     console("Unable to fetch details. Please try again later.");
  //   }
  // };

  const modalFooter = () => (
    <>
      <CustomButton variant='danger' label='Close' onClick={handleClose} />
    </>
  );

  return (
    <>
      <ModalSkeleton
        modalFooter={modalFooter()}
        isOpen={open}
        closeModal={() => setOpen(false)}
        cssClass='w-[55%]'
        title={"View External Service Provider Details"}
      >
        <div>
          <p>
            Details of External Service Provider{" "}
            <strong>{name.charAt(0).toUpperCase() + name.slice(1)}</strong>.
          </p>
        </div>
        <form className='mt-3 space-y-2'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='firstName'
                className='block text-sm font-medium text-gray-700'
              >
                Name
              </label>
              <div className='mt-1'>
                <InputField
                  type='text'
                  name='name'
                  id='firstName'
                  value={name}
                  readOnly
                  className='did-floating-input capitalize w-full'
                />
                {errors.name && (
                  <span className='text-sm text-red-500'>Name is required</span>
                )}
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='phone'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Phone
              </label>
              <div className='mt-1'>
                <InputField
                  type='text'
                  name='phone'
                  id='phone'
                  value={phone}
                  readOnly
                  className='did-floating-input capitalize w-full'
                />
                {errors.phone && (
                  <span className='text-sm text-red-500'>
                    Phone number is required!
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='email'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Email
              </label>
              <div className='mt-1'>
                <InputField
                  type='text'
                  name='email'
                  id='email'
                  value={email}
                  readOnly
                  className='did-floating-input w-full'
                />
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='billing_contact'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Billing Contact
              </label>
              <div className='mt-1'>
                <InputField
                  type='number'
                  name='billing_contact'
                  id='billing_contact'
                  value={billing_contact}
                  readOnly
                  className='did-floating-input capitalize w-full'
                />
                {errors?.billing_contact?.type === "minLength" && (
                  <span className='text-sm text-red-500'>
                    Phone number must have 10 digits
                  </span>
                )}
                {errors?.billing_contact?.type === "pattern" && (
                  <span className='text-sm text-red-500'>
                    Please enter a valid phone number!
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='street'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Street
              </label>
              <div className='mt-1'>
                <InputField
                  type='text'
                  name='street'
                  id='street'
                  value={street}
                  readOnly
                  className='did-floating-input capitalize w-full'
                />
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='suburb'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Suburb
              </label>
              <div className='mt-1'>
                <InputField
                  type='text'
                  name='suburb'
                  id='suburb'
                  value={suburb}
                  readOnly
                  className='did-floating-input capitalize w-full'
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className=''>
                <label className='block text-sm font-medium text-gray-700'>
                  State
                </label>
                <InputField
                  type='text'
                  className='mt-1 did-floating-input w-full'
                  value={state}
                  readOnly
                />
              </div>
            </div>

            <div className='col-span-1'>
              <label
                htmlFor='postcode'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Post code
              </label>
              <div className='mt-1'>
                <InputField
                  type='number'
                  name='postcode'
                  id='postcode'
                  value={postcode}
                  readOnly
                  className='did-floating-input capitalize w-full'
                />

                {errors?.postcode?.type === "maxLength" && (
                  <span className='text-sm text-red-500'>
                    Postal Code must have 4 digits
                  </span>
                )}
                {errors?.postcode?.type === "minLength" && (
                  <span className='text-sm text-red-500'>
                    Postal Code must have 4 digits
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-4 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='account_title'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Account Title
              </label>
              <div className='mt-1'>
                <InputField
                  type='text'
                  name='account_title'
                  id='account_title'
                  value={account_title}
                  readOnly
                  className='did-floating-input capitalize w-full'
                />
              </div>
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='bsb_no'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                BSB
              </label>
              <div className='mt-1'>
                <InputField
                  type='number'
                  name='bsb_no'
                  id='bsb_no'
                  value={bsb_no}
                  readOnly
                  className='did-floating-input capitalize w-full'
                />
              </div>
            </div>
            <div className='col-span-2'>
              <label
                htmlFor='account_number'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Account Number
              </label>
              <div className='mt-1'>
                <InputField
                  type='number'
                  name='account_number'
                  readOnly
                  id='account_number'
                  value={account_number}
                  className='did-floating-input capitalize w-full'
                />
                {errors.accountNumber && (
                  <span className='text-red-500'>
                    Account Number is required
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='abn'
                className='block text-sm font-medium text-gray-700'
              >
                ABN
              </label>
              <InputField
                className='did-floating-input mt-1'
                type='number'
                readOnly
                value={abn}
                id='abn'
              />
              {errors?.abn?.type === "required" ? (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              ) : errors?.abn?.type === "maxLength" ? (
                <span className='text-sm text-red-500'>
                  Please enter a valid ABN number max 11 digits.
                </span>
              ) : errors?.abn?.type === "minLength" ? (
                <span className='text-sm text-red-500'>
                  Please enter a valid ABN number min 11 digits.
                </span>
              ) : null}
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='ndis_regis_no'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                NDIS Registration No.
              </label>
              <div className='mt-1'>
                <InputField
                  type='number'
                  name='ndis_regis_no'
                  readOnly
                  id='ndis_regis_no'
                  value={ndis_regis_no}
                  className='did-floating-input capitalize w-full bg-gray-700 text-gray-700'
                />
                {errors.required
                  ? errors.ndis_regis_no && (
                      <span className='text-red-500'>
                        This field is required
                      </span>
                    )
                  : errors?.ndis_regis_no?.type === "pattern" && (
                      <span className='text-red-500'>
                        {errors?.ndisNo?.message}
                      </span>
                    )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='provider_no'
                className='capitalize block text-sm font-medium text-gray-700'
              >
                Provider Code
              </label>
              <div className='mt-1'>
                <InputField
                  type='number'
                  name='provider_no'
                  id='provider_no'
                  value={provider_no}
                  readOnly
                  className='did-floating-input capitalize w-full'
                />
              </div>
            </div>
          </div>
        </form>
      </ModalSkeleton>
    </>
  );
};

export default ViewExternalServiceProviderModal;
