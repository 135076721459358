import React from "react";
import { baseURLImage, getNameProfile } from "../../../Utils/utils";
import PopoverContainer from "../Popover/PopoverContainer";

const ParticipantImageToolTip = (props) => {
  const { participant, imgCssClass, cssClass } = props;

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  // const handleTooltipPosition = () => {
  //   switch (position) {
  //     case "right":
  //       return (
  //         <div className="absolute left-full top-1/2 z-20 -translate-y-1/2 z-[5] mb-3 whitespace-nowrap rounded bg-black py-[6px] px-2 text-sm text-white opacity-0 group-hover:opacity-100">
  //           <span className="absolute left-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-sm bg-black"></span>
  //           {participant.status_description}
  //         </div>
  //       );
  //     default:
  //       return (
  //         <div className="absolute bottom-full left-0 z-[5] mb-3 whitespace-nowrap rounded bg-black py-[6px] px-2 text-sm text-white opacity-0 group-hover:opacity-100">
  //           <span className="absolute bottom-[-3px] left-[15px] -z-10 h-2 w-2 rotate-45 rounded-sm bg-black"></span>
  //           {participant.status_description}
  //         </div>
  //       );
  //   }
  // };

  return (
    <div>
      <PopoverContainer
        cssClass={`${cssClass} text-sm bg-gradient`}
        itemComponent={() => {}}
        toolTip={participant?.status_description}
      >
        <img
          onError={(e) =>
            handleImageError(e, participant.first_name, participant.last_name)
          }
          className={imgCssClass}
          src={
            participant.profile_picture
              ? baseURLImage(participant.profile_picture)
              : getNameProfile(
                  participant.first_name,
                  participant.last_name || " "
                )
          }
          alt="participant_profile_picture"
        />
      </PopoverContainer>
      {/* {participant && participant.status_description && handleTooltipPosition()} */}
    </div>
  );
};

export default ParticipantImageToolTip;
