import React from "react";
import LoginScreenSection from "components/common/LoginScreenSection/LoginScreenSection";
import PersonalDetail from "pages/Authentication/SignUp/PersonalDetail/PersonalDetail";

const SignUp = () => {
  return (
    <div className="min-h-screen flex">
      <div className="w-full flex-1 flex flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 lg:w-1/2 xl:px-24">
        <PersonalDetail submitHandler={(e) => {}} />
      </div>
      <LoginScreenSection
        heading="Vertex360 has streamlined our NDIS business to an extent that we don’t have to work anymore!"
        userImg="use image here"
        name="Dale Florence"
        desc="NDIS Service Provider"
      />
    </div>
  );
};

export default SignUp;
