import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getPaymentSettings, getUser } from "store/selector/auth.selector";
import { parseBooleanVal } from "Utils/utils";
import CustomButton from "components/common/Button/CustomButton";

const GracePeriodModal = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const currentUser = useSelector(getUser);

  const paymentSetting = useSelector(getPaymentSettings);

  useEffect(() => {
    if (currentUser) {
      fireModal();
    }
  }, [currentUser]);

  const fireModal = () => {
    const isGracePeriodModalExist = localStorage.getItem("isStripeGracePeriod");
    if (
      isGracePeriodModalExist !== null &&
      parseBooleanVal(isGracePeriodModalExist) === false &&
      paymentSetting.current_subscription_status === "past_due" &&
      paymentSetting.subscription_id !== null &&
      parseBooleanVal(paymentSetting.subscription_status) === false
    ) {
      setOpen(true);
    }
  };
  const closeModal = () => {
    localStorage.setItem("isStripeGracePeriod", true);
    setOpen(false);
    navigate("/subscription");
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-50 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                <div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      Grace Period
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>
                        Attention! You have a one-month grace period to update
                        your payment card details and enjoy uninterrupted
                        Vertex360 services. Update your card details now to
                        ensure seamless access to all our features!
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-6'>
                  <CustomButton
                    clickHandler={closeModal}
                    label='Click To Proceed'
                    cssClass='w-full flex justify-center items-center'
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GracePeriodModal;
