import { apiCall } from "./service";
import {
  URL_LOGIN,
  URL_LOGOUT,
  URL_OTP,
  URL_CONFIRM_OTP,
  URL_RESEND_OTP,
  URL_RESEND_EMAIL_VERFIICATION,
  URL_ROLE_AND_PERMISSION,
  URL_ROLE_AND_PERMISSION_LIST,
  URL_ROLE_AUTHENTICATE_USER,
} from "./URL";
import momentTimeZone from "moment-timezone";

const token = localStorage.getItem("accessToken");

const currentTimeZone = momentTimeZone.tz.guess();

export const api = {
  unAthenticateMe() {
    return apiCall.get({
      url: "reset-is-2fa-status",
      isAuthToken: token,
      skipQueryParams: false,
      params: {},
    });
  },

  resendOTPApi(otp) {
    return apiCall.post({
      url: URL_RESEND_OTP,
      isAuthToken: token,
      skipQueryParams: true,
      params: { ...otp, timezone: currentTimeZone },
    });
  },
  sendOTPForConfirmationApi(otpParams) {
    return apiCall.post({
      url: URL_CONFIRM_OTP,
      isAuthToken: token,
      params: { ...otpParams, timezone: currentTimeZone },
    });
  },

  sendOTPApi(otpParams) {
    return apiCall.post({
      url: URL_OTP,
      isAuthToken: token,
      params: { ...otpParams, timezone: currentTimeZone },
    });
  },

  resendEmailVerificatioCode(params) {
    return apiCall.post({
      url: URL_RESEND_EMAIL_VERFIICATION,
      isAuthToken: false,
      params,
    });
  },

  loginRequest(loginParams) {
    return apiCall.post({
      url: URL_LOGIN,
      isAuthToken: false,
      params: loginParams,
    });
  },
  logoutRequest() {
    return apiCall.post({ url: URL_LOGOUT, isAuthToken: true, params: {} });
  },
  defaultRoleAndPermission(params) {
    return apiCall.get({
      url: params.hasOwnProperty("id")
        ? `${URL_ROLE_AND_PERMISSION}/edit?id=${params.id}`
        : `${URL_ROLE_AND_PERMISSION}/create?type=${params.type}`,
      isAuthToken: true,
      params: {},
    });
  },
  roleAndPermissionCreateOrUpdateApi(params) {
    return apiCall.post({
      url: `${URL_ROLE_AND_PERMISSION}/${
        params?.hasOwnProperty("id") ? "update" : "store"
      }`,
      isAuthToken: true,
      params,
    });
  },
  roleAndPermissionList(params) {
    return apiCall.get({
      url: `${URL_ROLE_AND_PERMISSION_LIST}?type=${params.type}`,
      isAuthToken: true,
      params: {},
    });
  },
  roleAuthenticateUser() {
    return apiCall.get({
      url: `${URL_ROLE_AUTHENTICATE_USER}`,
      isAuthToken: true,
      params: {},
    });
  },
};

export default api;
