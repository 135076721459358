import ACTIONS from "../constants";

export const showMessageBox = (data) => ({
  type: ACTIONS.SHOW_MESSAGE_BOX,
  data,
});

export const showConfirmationBox = (data) => ({
  type: ACTIONS.SHOW_CONFIRMATION_BOX,
  data,
});

export const clearExipryDialogue = () => ({
  type: ACTIONS.CLEAR_MESSAGE_BOX_FOR_TIME_EXPIRY,
});

export const showMessageBoxForTimeExpiry = (data) => ({
  type: ACTIONS.SHOW_MESSAGE_BOX_FOR_TIME_EXPIRY,
  data,
});

export const showToast = (data) => ({
  type: ACTIONS.ADD_TOAST_IN_LIST,
  data,
});

export const removeToast = () => ({
  type: ACTIONS.REMOVE_TOAST_IN_LIST,
  data: "",
});
