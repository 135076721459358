import React from "react";
import clockIcon from "assets/svg/clock-black.svg";
import { formatTimeSheetLength } from "Utils/utils";

const SelectionSummary = ({ selectedWorkers, selectedChilds }) => {
  const childItemCalculation = () => {
    let count = 0;
    let ttlTimesheetLength = 0;
    selectedChilds.forEach((element) => {
      count += element.children.length;
      element.children.forEach((citem) => {
        if (citem.timesheet_length && citem.timesheet_length.includes(":")) {
          const [hours, minutes, seconds = 0] =
            citem.timesheet_length.split(":");
          const timesheetSeconds =
            parseInt(hours, 10) * 3600 +
            parseInt(minutes, 10) * 60 +
            parseInt(seconds, 10);
          ttlTimesheetLength += timesheetSeconds;
        }
      });
    });
    ttlTimesheetLength = formatTimeSheetLength(ttlTimesheetLength);
    return { count, ttlTimesheetLength };
  };

  return (
    <>
      <section className='border border-gray-200 realtive p-5 bg-gray-50 rounded-md'>
        <h5 className='text-xl font-bold'>Selection Summary</h5>
        <div className='flex justify-between mt-5'>
          <div className='w-[30%] p-3.5 border border-gray-200 rounded-md'>
            <h3 className='text-base font-bold leading-7'>
              {selectedWorkers.length}
            </h3>
            <p className='mb-0 text-sm font-normal text-gray-500'>
              Selected Members
            </p>
          </div>
          <div className='w-[30%] p-3.5 border border-gray-200 rounded-md'>
            <h3 className='text-base font-bold leading-7'>
              {childItemCalculation().count}
            </h3>
            <p className='mb-0 text-sm font-normal text-gray-500'>
              Items Selected
            </p>
          </div>
          <div className='w-[30%] p-3.5 border border-gray-200 rounded-md'>
            <h3 className='text-base font-bold leading-7'>
              {childItemCalculation().ttlTimesheetLength}
            </h3>
            <p className='mb-0 text-sm font-normal text-gray-500'>
              Currently Selected
            </p>
          </div>
          {/* <div className='w-[23%] p-3.5 border border-gray-200 rounded-md'>
          <h3 className='text-base font-bold leading-7'>0</h3>
          <p className='mb-0 text-sm font-normal text-gray-500'>
            Previously Selected
          </p>
        </div> */}
        </div>
      </section>

      <section className='mt-8 border border-gray-200 realtive p-5 bg-gray-50 rounded-md'>
        <div className='flex items-start gap-x-5'>
          <img src={clockIcon} alt='clock' />
          <div>
            <h5 className='text-xl font-bold leading-none'>
              {childItemCalculation().count === 0
                ? "No "
                : `${childItemCalculation().count} `}
              Items Selected
            </h5>
            <p className='text-sm text-gray-500 font-normal mb-0 leading-7'>
              Please select one or more items from the left side bar to start
              timesheet exporting process.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectionSummary;
