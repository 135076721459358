import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

const InteractiveStarRating = ({
  label,
  control,
  required,
  errors,
  setValue,
  initialValue,
  watchFormData,
  onRatingChange,

  selectedItems,
  isDisabled,
  setSelectedItems,
}) => {


  const modifiedLabel=label+"-rating";
  useEffect(() => {
    if (initialValue) {
      // Set the initial value when the component mounts
      setValue(label+"-rating", initialValue);
    }
  }, [initialValue, label, setValue]);

  return (
    <div className="flex items-start space-x-2">
      <span className="w-40 text-left pr-2 whitespace-no-wrap">
        {required ? (
          <>
            {label}
            <span className="text-red-500">*</span>
          </>
        ) : (
          label
        )}
      </span>
      <Controller
        name={label+"-rating"}
        control={control}
        
        defaultValue={initialValue || ""}
        rules={required ? { required: "This field is required" } : {}}
        render={({ field }) => (
          <>
            {renderStars(
              field.value,
              required,
              (newValue) => {
                // field.onChange(newValue)

                const isLabelIncluded = selectedItems?.some(
                  (item) => item.label === label
                );

                if (!isDisabled) {
                  onRatingChange(label, newValue);
                  field.onChange(newValue);
                } else if (isLabelIncluded) {
                 
                  field.onChange(newValue);
                }
                if (newValue === 0) {
                  // Remove the item when star value is 0
                  setSelectedItems((prevItems) =>
                    prevItems.filter((item) => item.label !== label)
                  );
                }
              }
            )}
            <span className="ml-2 text-gray-400">
              {field.value ? field.value : 0}/5
            </span>
            {errors && errors[label + "-rating"] && (
              <span className="ml-2 text-red-500">
                {errors[label + "-rating"].message}
              </span>
            )}
          </>
        )}
      />
    </div>
  );
};

const renderStars = (rating, required, handleStarClick) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    const starClasses =
      "cursor-pointer fill-current " +
      (i <= rating ? "text-yellow-400" : "text-gray-200");
    stars.push(
      <svg
        key={i}
        className={`w-6 h-6 ${starClasses}`}
        onClick={() => handleStarClick((i === rating) && !required ? 0 : i)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M6.379 17.615a1 1 0 0 1-1.488-1.054l.848-4.946-3.568-3.47a1 1 0 0 1 .552-1.705l4.94-.717 2.208-4.471a1 1 0 0 1 1.79 0l2.208 4.47 4.941.716a1 1 0 0 1 .552 1.705l-3.569 3.47.849 4.946a1 1 0 0 1-1.488 1.054l-4.169-2.193-4.168 2.193z"
        />
      </svg>
    );
  }
  return stars;
};

export default InteractiveStarRating;


// import React, { useState } from "react";

// const InteractiveStarRating = ({ label,watchFormData,register,setValue }) => {
//   const [rating, setRating] = useState(0);

//   const handleStarClick = (selectedRating) => {
//     setRating(selectedRating);
//   };

//   const renderStars = () => {
//     const stars = [];
//     for (let i = 1; i <= 5; i++) {
//       const starClasses =
//         "cursor-pointer fill-current " +
//         (i <= rating ? "text-yellow-400" : "text-gray-200");
//       stars.push(
//         <svg
//           key={i}
//           className={`w-6 h-6 ${starClasses}`}
//           onClick={() => handleStarClick(i)}
//           xmlns="http://www.w3.org/2000/svg"
//           viewBox="0 0 20 20"
//           fill="currentColor"
//         >
//           <path
//             fillRule="evenodd"
//             d="M6.379 17.615a1 1 0 0 1-1.488-1.054l.848-4.946-3.568-3.47a1 1 0 0 1 .552-1.705l4.94-.717 2.208-4.471a1 1 0 0 1 1.79 0l2.208 4.47 4.941.716a1 1 0 0 1 .552 1.705l-3.569 3.47.849 4.946a1 1 0 0 1-1.488 1.054l-4.169-2.193-4.168 2.193z"
//           />
//         </svg>
//       );
//     }
//     return stars;
//   };

//   return (
//     <div className="flex items-start space-x-2">
//       <span className="w-40 text- pr-2 whitespace-no-wrap">{label}</span>
//       {renderStars()}
//       <span className="ml-2 text-gray-400">{rating}/5</span>
//     </div>
//   );
// };

// export default InteractiveStarRating;
