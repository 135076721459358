import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { BiPlus } from "react-icons/bi";
import CustomButton from "../../../../../components/common/Button/CustomButton";
import FileUploadInput from "../../../../../components/common/FileUpload/FileUploadInput";
import SelectOption from "../../../../../components/common/Select/Select";
import ModalSkeleton from "../../../../../components/Modals/ModalSkeleton";
import { parseBooleanVal } from "../../../../../Utils/utils";
import { LoginContext } from "helpers/LoginContext";

const ClientTasksModal = (props) => {
  const {
    selectedFundingId,
    createclientTaskListLoading,
    updateClientTaskResponse,
    ClientTaskListUnmount,
    removeClientTaskSuccess,
    clientTaskResponse,
    periorityList,
    getCatogeryListRequest,
    getPeriorityListRequest,
    CatogeryList,
    setOpenCTModel,
    openModel,
    createTaskRequest,
    participantId,
    updateClientTask,
    editObjact,
    list,
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const [file, setFile] = useState();
  const [priority, setPriority] = useState();
  const [status, setStatus] = useState(null);

  const [catogery, setCatogery] = useState();
  // const [catogery, setCatogery] = useState();Priority

  const [selectedStatus, setSelectedStatus] = useState();

  useEffect(() => {
    if (updateClientTaskResponse.client_task) {
      setOpenCTModel(false);
    }
  }, [updateClientTaskResponse]);

  useEffect(() => {
    if (clientTaskResponse.sp_id) {
      setOpenCTModel(false);
    }
  }, [clientTaskResponse]);

  useEffect(() => {
    getCatogeryListRequest({ status: "active" });
    getPeriorityListRequest({ status: "active" });

    return () => {
      ClientTaskListUnmount();
      removeClientTaskSuccess();
    };
  }, []);

  const [assignedTo, setAssignedTo] = useState([]);
  const [updateFileName, setUpdateFileName] = useState();
  useEffect(() => {
    if (editObjact) {
      setUpdateFileName(editObjact?.file_name);
      setValue("notes", editObjact?.notes);
      setValue("assigned_to", editObjact?.assigned_to);
      setValue("due_date", editObjact?.due_date);
      setValue("task_name", editObjact?.task_name);
      if (editObjact && editObjact.send_reminder) {
        setValue("send_reminder", parseBooleanVal(editObjact.send_reminder));
      }

      setCatogery({
        ...editObjact?.category,
        value: editObjact?.category?.id,
        label: editObjact?.category?.title,
      });
      setPriority({
        ...editObjact?.priority,
        value: editObjact?.priority?.id,
        label: editObjact?.priority?.title,
      });
      setAssignedTo({
        ...editObjact?.assigned_to,
        value: editObjact?.assigned_to?.id,
        label: editObjact?.assigned_to?.first_name.concat(
          " ",
          editObjact?.assigned_to?.last_name
        ),
      });
      setFile(editObjact?.attachment);
      {
        editObjact?.priority === "High" &&
          setPriority({ label: "High", value: 3 });
      }
      {
        editObjact?.priority === "Medium" &&
          setPriority({ label: "Medium", value: 2 });
      }
      {
        editObjact?.priority === "Low" &&
          setPriority({ label: "Low", value: 1 });
      }
      {
        editObjact?.status === "Cancelled" &&
          setStatus({ label: "Cancelled", value: 1 }) &&
          setValue("status", status) &&
          clearErrors("status");
      }
      {
        editObjact?.status === "Complete" &&
          setStatus({ label: "Complete", value: 2 }) &&
          setValue("status", status) &&
          clearErrors("status");
      }
      {
        editObjact?.status === "In Progress" &&
          setStatus({ label: "In Progress", value: 3 }) &&
          clearErrors("status") &&
          setValue("status", status);
      }
      {
        editObjact?.status === "Not Started" &&
          setStatus({ label: "Not Started", value: 4 }) &&
          clearErrors("status") &&
          setValue("status", status);
      }
    } else {
      setSelectedStatus();
      setPriority();
      setValue("notes", "");
      setValue("assigned_to", "");
      setValue("due_date", "");
      setValue("task_name", "");
      setValue("send_reminder", false);
    }
  }, [editObjact]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) return;

    let formData = new FormData();
    formData.append("pt_id", participantId);
    formData.append("priority_id", priority?.value);
    formData.append("status", status?.label);
    formData.append("notes", data?.notes);
    formData.append("send_reminder", data?.send_reminder);
    formData.append("task_name", data?.task_name);
    formData.append("assigned_to", data?.assigned_to.value);
    formData.append("due_date", data?.due_date);
    formData.append("funding_id", selectedFundingId);
    if (file) {
      formData.append("attachment", file);
    }
    formData.append("category_id", data?.category_id.id);

    if (editObjact) {
      formData.append("task_id", editObjact?.uuid);
      updateClientTask(formData);
    } else {
      createTaskRequest(formData);
    }
  };

  useEffect(() => {
    if (catogery) {
      setValue("category_id", catogery);
      clearErrors("category_id");
    }
  }, [catogery]);

  useEffect(() => {
    if (priority) {
      setValue("priority_id", priority);
      clearErrors("priority_id");
    }
  }, [priority]);

  useEffect(() => {
    if (status) {
      setValue("status", status);
      clearErrors("status");
    }
  }, [status]);

  useEffect(() => {
    if (assignedTo) {
      setValue("assigned_to", assignedTo);
      clearErrors("assigned_to");
    }
  }, [assignedTo]);

  const modalFooter = () => (
    <CustomButton
      type='submit'
      isDisabled={createclientTaskListLoading || isUserGracePeriodOver}
      showLoading={createclientTaskListLoading}
      label={editObjact ? `Update` : "Create"}
      {...((!editObjact || editObjact === null) && {
        Icon: <BiPlus />,
      })}
      clickHandler={() => {
        const buttonRef = document.getElementById("clientTask");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <ModalSkeleton
      cssClass='w-[60%]'
      title={
        editObjact ? `Update Participant Task ` : "Create New Participant Task"
      }
      modalFooter={modalFooter()}
      isOpen={openModel}
      closeModal={() => setOpenCTModel(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
        <div className='grid grid-cols-2 space-x-2'>
          <div className='col-span-2'>
            <label htmlFor='' className='text-start did-floating-label'>
              Task Name
            </label>
            <input
              {...register("task_name", {
                required: true,
                maxLength: 254,
              })}
              // value={participantStateId.task_name}
              name='task_name'
              type='text'
              id='task_name'
              className='did-floating-input'
            />
            {errors?.task_name?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
          </div>
        </div>
        <div className='grid grid-cols-2 space-x-2'>
          <div className='col-span-1'>
            <div className='hidden'>
              <select
                {...register("category_id", {
                  required: true,
                })}
                name='category_id'
                id='category_id'
                className='hidden did-floating-input'
              />
            </div>
            <label className='text-start did-floating-label'>Category</label>
            <SelectOption
              cssClass=''
              isSearch={false}
              data={CatogeryList && CatogeryList}
              selectedOption={catogery && catogery}
              handleChange={setCatogery}
              // placeHolder="Select Category"
            />
            {errors?.category_id?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required
              </span>
            )}
          </div>
          <div className='col-span-1'>
            <div className='hidden'>
              <select
                {...register("assigned_to", {
                  required: true,
                })}
                name='assigned_to'
                id='assigned_to'
                className='hidden did-floating-input'
              />
            </div>
            <label className='text-start did-floating-label'>Assigned to</label>
            <SelectOption
              cssClass=''
              isSearch={false}
              data={list || []}
              selectedOption={assignedTo && assignedTo}
              handleChange={setAssignedTo}
              // placeHolder="Select "
            />
            {errors?.assigned_to?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required
              </span>
            )}
          </div>
          <div className='col-span-1 ml-0' style={{ marginLeft: "0px" }}>
            <div className='hidden'>
              <select
                {...register("status", {
                  required: true,
                })}
                name='status'
                id='status'
                className='hidden did-floating-input'
              />
            </div>
            <label className='text-start did-floating-label'>Status</label>
            <SelectOption
              cssClass=''
              isSearch={false}
              data={[
                { label: "Cancelled", value: 1 },
                { label: "Complete", value: 2 },
                { label: "In Progress", value: 3 },
                { label: "Not Started", value: 4 },
              ]}
              selectedOption={status && status}
              handleChange={setStatus}
              // placeHolder="Select Status"
            />
            {errors?.status?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required
              </span>
            )}
          </div>
          <div className='col-span-1 '>
            <div className='hidden'>
              <select
                {...register("priority_id", {
                  required: true,
                })}
                name='priority_id'
                id='priority_id'
                className='hidden did-floating-input'
              />
            </div>
            <label className='text-start did-floating-label'>Priority</label>
            <SelectOption
              cssClass=''
              isSearch={false}
              data={periorityList && periorityList}
              selectedOption={priority && priority}
              handleChange={setPriority}
              // placeHolder="Select Priority"
            />
            {errors?.priority_id?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required
              </span>
            )}
          </div>
          <div className='col-span-2 ml-0' style={{ marginLeft: "0px" }}>
            <div className='text-start'>
              <label className='text-start did-floating-label'>
                Task Details
              </label>
            </div>
            <textarea
              {...register("notes", {
                required: true,
              })}
              rows='3'
              className='w-full border-gray-300 rounded  overflow-y-auto resize-none'
            ></textarea>
            {errors?.notes?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
          </div>
          <div className='col-span-1ml-0 ' style={{ marginLeft: "0px" }}>
            <label className='text-start did-floating-label '>Due Date</label>
            <input
              {...register("due_date", {
                required: true,
              })}
              name='due_date'
              id='due_date'
              type='date'
              min={moment().format("DD-MM-YYYY")}
              // placeholder="OCT 12, 2020"
              className='did-floating-input'
            />
            {errors?.due_date?.type === "required" && (
              <span className='text-sm text-red-500'>
                This field is required!
              </span>
            )}
          </div>
          <div className='col-span-1 flex items-center mt-2 space-x-2'>
            <div>
              <label className='text-start did-floating-label'>
                Send Reminder
              </label>
            </div>
            <div>
              <input
                {...register("send_reminder", {
                  // required: true,
                })}
                name='send_reminder'
                type='checkbox'
              />
              {errors?.send_reminder?.type === "required" && (
                <span className='pl-3 text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
          <br />
          <div className='col-span-2 ml-0' style={{ marginLeft: "0px" }}>
            <label className='text-start did-floating-label'>
              File Attachment
            </label>
            <div>
              <FileUploadInput
                updateFileName={updateFileName}
                type='any'
                handleFile={(e) => {
                  setUpdateFileName(null);
                  setFile(e);
                }}
                size='2'
                file={file}
                handleDelete={() => setFile("")}
              />
            </div>
          </div>
        </div>
        <button type='submit' className='hidden sr-only' id='clientTask' />
      </form>
    </ModalSkeleton>
  );
};
export default ClientTasksModal;
