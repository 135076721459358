import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import momentTimeZone from "moment-timezone";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import {
  baseURLImage,
  capitalizeAllLetters,
  capitalizeFirstLetter,
  checkDisabilityOfShiftStatus,
  checkRunningShiftStatus,
  convertDaysName,
  convertTimeToDatetimeLocal,
  convertWeeksToFormat,
  fetchOptionsForWorkerAndParticpant,
  formatRecurringValue,
  getNameProfile,
  getShiftLength,
  isCheckNextDayShift,
  parseBooleanVal,
  selectedTabState,
  validateCurrentDate,
} from "Utils/utils";
import api from "store/services/rostering.services";
import { Controller, useForm } from "react-hook-form";
import InputField from "components/common/Input/Input";
import moment from "moment";
import {
  DUMMY_REGEX_EXP,
  LOADER_STATUS,
  SHIFT_STATUS,
  SHIFT_TIME_OPTIONS,
  TOAST_TYPE,
} from "Constants/constant";
import { isEmpty, isNumber } from "lodash";
import { HiCheckCircle, HiOutlineTrash } from "react-icons/hi";
import WorkerShiftDetail from "./WorkerShiftDetail";
import SelectOption from "components/common/Select/Select";
import rosteringApi from "store/services/rostering.services";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import { toast } from "react-toastify";
import { URL_ROSTERING_SHIFT_CHECK_OVERLAPP } from "store/services/URL";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import { FaQuestionCircle } from "react-icons/fa";
import RecurringShift from "./RecurringShift";
import { FaExclamationCircle } from "react-icons/fa";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";
import { IoWarningOutline } from "react-icons/io5";
import WorkerAvailabiltyModal from "components/Modals/WorkerAvailabiltyModal";
import Loader from "components/common/Loader/Loader";
import { getSearchtextOfScheduleShifts } from "store/selector/rostering.selector";
import { useSelector, useDispatch } from "react-redux";
import { RECURRING_INTERVAL, TOAST_IDS } from "store/constants";
import WorkerSelection from "./Selection/WorkerSelection";
import ParticipantSelection from "./Selection/ParticiapantSelection";
import UpdateRecurringShiftsModal from "components/Modals/RecurringUpdateShiftsModal";
import { showToast } from "store/actions/general.actions";
import TimeInputRange from "components/common/TimeInput";
import SweetAlertConfirmationModal from "components/common/SweetAlertConfirmation/SweetAlertConfirmation";

const WorkerShift = (props) => {
  const {
    createOrUpdateWorkerShiftRequest,
    workerAndParticipantOptions,
    userItem,
    selectedDate,
    calendarView,
    handleClose,
    isShow,
    selectedShift = null,
    createOrUpdateWorkerShiftLoader,
    createOrUpdateWorkerShiftError,
    refreshCalendar,
    isUserGracePeriodOver,
    workerProfileDetailsFetchRequest,
    workerProfileDetails,
    getWorkerProfileLoader,
    //For Add Shift Props
    isCallFromAddShift = false,
    spRosteringSetting,
    spRosteringSettingLoader,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
    control,
    clearErrors,
  } = useForm();
  const dispatch = useDispatch();

  const [workerOptions, setWorkerOptions] = useState([]);
  const [participantOptions, setParticipantOptions] = useState([]);
  const [isFetchRecordDetailLoader, setIsFetchRecordDetailLoader] =
    useState(false);
  const [isInitialCreateShiftLoader, setISInitialCreateShiftLoader] = useState(
    LOADER_STATUS.NA
  );
  const [shiftDetailLoader, setShiftDetailLoader] = useState(false);
  const [shiftDetailData, setShiftDetailData] = useState([]);

  const [recurringType, setRecurringType] = useState(null);
  const [recurrInterval, setRecurrInterval] = useState(null);
  const [recurringDates, setRecurringDates] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

  const [isWorkerLoaded, setIsWorkerLoaded] = useState(false);
  const [isParticipantLoaded, setIsParticipantLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  // const createOrUpdateWorkerShiftLoader = false;
  const [openModal, setOpenModal] = useState(false);
  const [showUpdateShiftRecurringModal, setUpdateShiftRecurringModal] =
    useState(false);
  const [workerProfileUUID, setWorkerProfileUUID] = useState(null);
  const [recheckAvailability, setRecheckAvailability] = useState(false);
  const [loadingItemId, setLoadingItemId] = useState(null);
  const [tasks, setTasks] = useState([{ task: "" }]);
  const [selectedDays, setSelectedDays] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });

  // const [recurringEffectedShiftCount, setRecurringEffectedShiftCount] =
  //   useState(null);
  const [recurringFor, setRecurringFor] = useState(null);
  const searchText = useSelector(getSearchtextOfScheduleShifts);

  const navigate = useNavigate();

  const isDateInPast = (date) => {
    if (!date) return false;
    const today = moment().startOf("day");
    const compareDate = moment(date).startOf("day");
    return compareDate.isBefore(today);
  };

  const [isPastShift, setIsPastShift] = useState(false);

  const watchFormData = watch();
  const modalContentRef = useRef(null);
  const [notes, setNotes] = useState([]);
  const participantRatio = watch("pt_id");
  const workerRatio = watch("worker_id");

  const [recurringUpdateShiftData, setRecurringUpdateShiftData] =
    useState(null);

  const workerAvailabilityData =
    workerProfileDetails?.availability &&
    JSON.parse(workerProfileDetails?.availability);

  useEffect(() => {
    getServiceTypes();
    return () => handleClose();
  }, []);

  const getServiceTypes = async () => {
    setShiftDetailLoader(true);
    try {
      const res = await apiGetRequest("/roster-invoices/service-type");
      if (res.data) {
        const modifiedValues = res.data.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setServiceTypes(modifiedValues);
        setShiftDetailLoader(false);
      }
    } catch (error) {
      setShiftDetailLoader(false);
    }
  };
  useEffect(() => {
    if (watchFormData?.is_recurring && modalContentRef.current) {
      modalContentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [watchFormData?.is_recurring, recurringType]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedShift && selectedShift.uuid) {
        await getShiftDetails(selectedShift.uuid);
      }
    };
    fetchData();
  }, [selectedShift]);

  useEffect(() => {
    if (selectedDate && selectedShift === null) {
      setValue("shift_date", selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    const isCheckForIds = Object.keys(watchFormData ?? {}).length;

    // if (
    //   userItem &&
    //   selectedShift === null &&
    //   calendarView === "participant-view"
    // ) {
    //   setValue("invoice_service_type_id", userItem?.invoice_category_id);
    // }

    if (userItem && calendarView === "worker-view") {
      if (isCheckForIds > 0 && watchFormData?.worker_id) return;
      setValue("worker_id", userItem.uuid);
    } else if (userItem && calendarView === "participant-view") {
      if (isCheckForIds > 0 && watchFormData?.pt_id) return;
      setValue("pt_id", userItem.uuid);
    }
  }, [userItem]);

  useEffect(() => {
    if (workerAndParticipantOptions) {
      const { workers, participants } = workerAndParticipantOptions;
      if (calendarView === "worker-view" && workers?.length > 0) {
        setWorkerOptions(workers);
      }
      if (calendarView === "participant-view" && participants?.length > 0) {
        setParticipantOptions(participants);
      }
    }
  }, [workerAndParticipantOptions]);

  const fetchParticipantsorWorkersForAddShift = async (obj = {}) => {
    try {
      const response = await fetchOptionsForWorkerAndParticpant(
        calendarView === "worker-view" ? "participant" : "worker"
      );
      if (response) {
        if (calendarView === "worker-view") {
          setParticipantOptions([...response]);
        } else {
          setWorkerOptions([...response]);
        }
      }
    } catch (err) {
      console.log("err:", err);
    }
  };

  useEffect(() => {
    if (isCallFromAddShift) {
      fetchParticipantsorWorkersForAddShift();
    }
  }, [isCallFromAddShift]);

  useEffect(() => {
    if (
      userItem !== null &&
      watchFormData?.worker_id &&
      watchFormData?.pt_id === null
    ) {
      handleChangeWorkerOrParticipant({
        type: "worker",
        uuid: watchFormData.worker_id,
      })
        .then(() => setIsWorkerLoaded(false))
        .catch(() => setIsWorkerLoaded(false));
    }
  }, [watchFormData.worker_id]);

  useEffect(() => {
    if (
      userItem !== null &&
      watchFormData?.pt_id &&
      watchFormData?.worker_id === null
    ) {
      handleChangeWorkerOrParticipant({
        type: "participant",
        uuid: watchFormData.pt_id,
      }).then(() => setIsParticipantLoaded(true));
    }
  }, [watchFormData.pt_id]);

  const getShiftDetails = async (shiftUuid) => {
    try {
      setShiftDetailLoader(true);
      const response = await rosteringApi.workerShiftDetails({ shiftUuid });

      if (response && response?.shift_detail) {
        const data = response.shift_detail;

        setShiftDetailData(data);

        setValue(
          "shift_date",
          moment(data.shift_date, "YYYY-MM-DD").format("YYYY-MM-DD")
        );

        setValue(
          "end_time",
          data?.end_time
            ? moment(data.end_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
            : // moment(data.end_time).toDate()
              null
        );

        setValue(
          "start_time",
          data?.start_time
            ? moment(data.start_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
            : // moment(data.start_time).toDate()
              null
        );

        // setValue("timeRange", [
        //   data?.start_time
        //     ? // ? moment(data.start_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
        //       moment(data.start_time).toDate()
        //     : null,
        //   data?.end_time
        //     ? // ? moment(data.end_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
        //       moment(data.end_time).toDate()
        //     : null,
        // ]);

        setValue(
          "worker_start_time",
          data?.worker_start_time
            ? moment(data.worker_start_time, "YYYY-MM-DD HH:mm:ss").format(
                "hh:mm A"
              )
            : null
        );
        setValue(
          "worker_end_time",
          data?.worker_end_time
            ? moment(data.worker_end_time, "YYYY-MM-DD HH:mm:ss").format(
                "hh:mm A"
              )
            : null
        );
        setValue("location_tracking", data?.location_tracking);
        if (data?.shift_tasks) {
          setTasks(data?.shift_tasks);
        }
        if (data?.shift_notes) {
          setNotes(data?.shift_notes);
        }
        setValue("is_group_shift", data?.is_group_shift ?? false);
        setValue("shift_accept", data?.shift_accept ?? true);
        setValue("is_signature", data?.is_signature ?? true);
        setValue("invoice_service_type_id", data?.invoice_service_type_id);
        setValue("sleepover", data?.sleepover);
      }
      setShiftDetailLoader(false);
    } catch (err) {
      setShiftDetailLoader(false);
      console.log("err", err);
    }
  };

  const handleChangeWorkerOrParticipant = async (
    { type, uuid, id = null },
    additionalParams = {}
  ) => {
    try {
      setIsFetchRecordDetailLoader(true);
      const response = await api.getWorkerAndParticipantList(
        type === "worker"
          ? { type: type, worker_id: uuid }
          : { type: type, pt_id: uuid }
      );
      if (
        isCallFromAddShift &&
        additionalParams?.isCheckWorkerExist &&
        watchFormData?.worker_id
      ) {
        if (type === "participant") {
          const index =
            response?.worker_participant_listing?.participant.sp_workers?.findIndex(
              (c) => c.uuid === watchFormData?.worker_id
            );
          if (index === -1) {
            setValue("worker_id", null);
            setWorkerOptions([
              ...response.worker_participant_listing.participant.sp_workers.map(
                (x) => ({
                  ...x,
                  label: `${x.first_name} ${x.last_name}`,
                  value: x.uuid,
                  icon: x?.photo
                    ? baseURLImage(x.photo)
                    : getNameProfile(x.first_name, x.last_name || " "),
                })
              ),
            ]);
            setIsFetchRecordDetailLoader(false);
            return;
          }
        }
      } else if (
        isCallFromAddShift &&
        additionalParams?.isCheckParticipantExist &&
        watchFormData?.pt_id
      ) {
        if (type === "worker") {
          const index =
            response?.worker_participant_listing?.worker?.participants?.findIndex(
              (c) => c.uuid === watchFormData?.pt_id
            );
          if (index === -1) {
            setValue("pt_id", null);
            setParticipantOptions([
              ...response.worker_participant_listing.participant?.participants.map(
                (x) => ({
                  ...x,
                  label: `${x.first_name} ${x.last_name}`,
                  value: x.uuid,
                  icon: x?.profile_picture
                    ? baseURLImage(x.profile_picture)
                    : getNameProfile(x.first_name, x.last_name || " "),
                })
              ),
            ]);
            setIsFetchRecordDetailLoader(false);
            return;
          }
        }
      }

      if (
        type === "worker" &&
        response &&
        response.worker_participant_listing &&
        response.worker_participant_listing.worker &&
        response.worker_participant_listing.worker.participants
      ) {
        setParticipantOptions([
          ...response.worker_participant_listing.worker.participants.map(
            (x) => ({
              ...x,
              label: `${x.first_name} ${x.last_name}`,
              value: x.uuid,
              icon: x?.profile_picture
                ? baseURLImage(x.profile_picture)
                : getNameProfile(x.first_name, x.last_name || " "),
            })
          ),
        ]);

        if (id && isCallFromAddShift) {
          setValue("worker_id", id);
        }
      } else if (
        type === "participant" &&
        response &&
        response.worker_participant_listing &&
        response.worker_participant_listing.participant &&
        response.worker_participant_listing.participant.sp_workers
      ) {
        setWorkerOptions([
          ...response.worker_participant_listing.participant.sp_workers.map(
            (x) => ({
              ...x,
              label: `${x.first_name} ${x.last_name}`,
              value: x.uuid,
              icon: x?.photo
                ? baseURLImage(x.photo)
                : getNameProfile(x.first_name, x.last_name || " "),
            })
          ),
        ]);
      }
      setIsFetchRecordDetailLoader(false);

      if (selectedShift !== null) {
        if (calendarView === "worker-view" && selectedShift?.participants) {
          if (selectedShift?.participants.length === 1) {
            setValue("pt_id", selectedShift?.participants[0]?.uuid);
          } else {
            setValue("pt_id", selectedShift?.participants);
          }
        } else if (
          calendarView === "participant-view" &&
          selectedShift?.worker
        ) {
          setValue("worker_id", selectedShift?.worker?.uuid);
        }
      }
    } catch (err) {
      setIsFetchRecordDetailLoader(false);
      console.log("err", err.message);
    }
  };

  const onSubmit = (data, isPublish = null) => {
    if (isUserGracePeriodOver) {
      return;
    }
    const showToast = (message, toastId) => {
      setTimeout(() => {
        if (!toast.isActive(toastId)) {
          toast.warning(message, { toastId: toastId });
        }
      }, 200);
    };

    if (
      watchFormData?.is_group_shift &&
      calendarView === "worker-view" &&
      watchFormData?.pt_id.length < 2
    ) {
      const toastId = "isSingleGroupShift";

      setTimeout(() => {
        if (!toast.isActive(toastId)) {
          toast.warning(
            "Single participant can't be selected for group shift",
            { toastId: toastId }
          );
        }
      }, 200);
      return;
    }

    if (watchFormData?.is_group_shift) {
      if (calendarView === "worker-view" && watchFormData?.pt_id.length < 2) {
        showToast(
          "Single participant can't be selected for group shift",
          "isSingleGroupShift"
        );
        return;
      }

      if (
        calendarView === "participant-view" &&
        watchFormData?.worker_id?.length < 2
      ) {
        showToast(
          "Single worker can't be selected for group shift",
          "isSingleGroupShiftWorker"
        );
        return;
      }
    }

    if (
      selectedShift === null &&
      watchFormData?.is_recurring &&
      recurringDates.length === 0
    ) {
      showToast(
        "Choose recurring interval or disable the recurring option",
        "isRecurringShiftToast"
      );
      return;
    }

    if (
      watchFormData?.is_recurring &&
      recurringDates.length >= 0 &&
      watchFormData?.shift_date &&
      !validateCurrentDate(
        moment(watchFormData?.shift_date),
        moment(new Date()),
        ["past"],
        false
      )
    ) {
      showToast(
        "Recurring shifts cannot be created for past date.",
        "isRecurringShiftDateValidationToast"
      );
      return;
    }

    const currentTimeZone = momentTimeZone.tz.guess();

    const filteredTasks = tasks.filter(
      (task) => task.task !== "" && task.task !== null
    );
    if (filteredTasks?.length > 0) {
      Object.assign(data, {
        shift_tasks: filteredTasks,
      });
    }

    Object.assign(data, {
      timezone: currentTimeZone,
      shift_length: getShiftLength(
        watchFormData?.start_time,
        watchFormData?.end_time,
        true
      ),
      is_group_shift: watchFormData?.is_group_shift || false,
      is_signature: watchFormData?.is_signature,
      shift_date: moment(new Date(watchFormData.shift_date)).toISOString(),
      start_time: convertTimeToDatetimeLocal(
        moment(watchFormData.shift_date),
        watchFormData.start_time
      ),
      end_time:
        isCheckNextDayShift(
          watchFormData?.shift_date,
          watchFormData?.start_time,
          watchFormData?.end_time
        ) !== false
          ? isCheckNextDayShift(
              watchFormData?.shift_date,
              watchFormData?.start_time,
              watchFormData?.end_time
            )
          : convertTimeToDatetimeLocal(
              moment(watchFormData.shift_date),
              watchFormData.end_time
            ),
      worker_start_time: watchFormData?.worker_start_time
        ? moment(watchFormData?.worker_start_time, "hh:mm A").format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          )
        : "",
      worker_end_time: watchFormData?.worker_end_time
        ? moment(watchFormData?.worker_end_time, "hh:mm A").format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          )
        : "",

      location_tracking: data?.location_tracking,
    });
    if (data.hasOwnProperty("task")) delete data["task"];
    if (calendarView === "worker-view") {
      let participantId;

      if (Array.isArray(watchFormData?.pt_id)) {
        //for multiple purpose
        participantId =
          watchFormData?.is_group_shift &&
          watchFormData &&
          watchFormData.pt_id.length > 0
            ? watchFormData.pt_id?.map((item) => {
                const matchingParticipant = participantOptions.find(
                  (option) => option.uuid === item.uuid
                );
                return matchingParticipant?.id;
              })
            : [];
      } else {
        //for one to one
        participantId = participantOptions.find(
          (x) => x.uuid === watchFormData.pt_id
        )?.id;
      }

      let workerId = null;

      if (isCallFromAddShift && isNumber(watchFormData?.worker_id)) {
        workerId = watchFormData?.worker_id;
      } else {
        workerId = workerOptions.find(
          (x) => x.uuid === watchFormData.worker_id
        )?.id;
      }

      Object.assign(data, {
        worker_id: [workerId],
        pt_id: Array.isArray(participantId)
          ? [...participantId]
          : [participantId],
      });
    } else if (calendarView === "participant-view") {
      let participantId = null;

      participantId = participantOptions?.find(
        (x) => x.uuid === watchFormData?.pt_id
      )?.id;

      let workerId;
      if (Array.isArray(watchFormData?.worker_id)) {
        workerId =
          parseBooleanVal(watchFormData?.is_group_shift) &&
          watchFormData.worker_id?.length > 0
            ? watchFormData.worker_id?.map((item) => {
                const matchingParticipant = workerOptions.find(
                  (option) => option.uuid === item.uuid
                );
                return matchingParticipant?.id;
              })
            : [null];
      } else {
        workerId = workerOptions?.find(
          (x) => x.uuid === watchFormData?.worker_id
        )?.id;
      }

      Object.assign(data, {
        pt_id: [participantId],
        worker_id: workerId
          ? Array.isArray(watchFormData?.worker_id)
            ? [...workerId]
            : [workerId]
          : [null],
      });
    }
    setISInitialCreateShiftLoader(LOADER_STATUS.PENDING);

    if (watchFormData?.is_recurring && recurringDates.length > 0) {
      Object.assign(data, {
        till_dates: recurringDates,
        recurring_days: convertDaysName(selectedDays),
        recurring_interval: capitalizeFirstLetter(recurrInterval.value),
        repeat_for: formatRecurringValue(recurringType.value),
      });
    }

    Object.assign(data, {
      invoice_service_type_id: data?.invoice_service_type_id,
      sleepover: data?.sleepover,
      shift_accept: data?.shift_accept,
      overlap: data?.shift_overlapped,
    });

    //   if (selectedShift !== null) {
    //   Object.assign(data, {
    //     recurring_impact: watchFormData?.recurring_impact,
    //   });
    // }
    if (isPublish === "isPublish") {
      Object.assign(data, {
        is_publish: true,
      });
    }
    if (
      selectedShift !== null &&
      shiftDetailData?.recurring_interval !== null
    ) {
      setRecurringUpdateShiftData({
        data:
          selectedShift !== null
            ? { ...data, uuid: selectedShift.uuid, id: selectedShift.id }
            : data,
        calendarView: calendarView,
        isUpdate: selectedShift !== null,
        isWorkerChange:
          userItem && data.worker_id[0] === userItem?.id ? false : true,
        prevWorkerUUID:
          userItem && data.worker_id[0] === userItem?.id
            ? null
            : userItem?.uuid,
        name: capitalizeAllLetters(
          `${userItem?.first_name} ${userItem?.last_name}`
        ),
      });
      setUpdateShiftRecurringModal(true);
    } else {
      createOrUpdateWorkerShiftRequest(
        {
          data:
            selectedShift !== null
              ? { ...data, uuid: selectedShift.uuid, id: selectedShift.id }
              : data,
          calendarView: calendarView,
          isUpdate: selectedShift !== null,
          isWorkerChange:
            userItem && data.worker_id[0] === userItem?.id ? false : true,
          prevWorkerUUID:
            userItem && data.worker_id[0] === userItem?.id
              ? null
              : userItem?.uuid,
          name: capitalizeAllLetters(
            `${userItem?.first_name} ${userItem?.last_name}`
          ),
        },
        (e) => {
          // setTimeout(() => {
          if (
            selectedShift !== null ||
            watchFormData?.is_group_shift ||
            false
          ) {
            const payload = { isRefreshCall: true };
            if (!isEmpty(searchText)) {
              Object.assign(payload, {
                search: searchText,
              });
            }
            refreshCalendar(payload);
          }
          // }, [2000]);
        }
      );
    }
  };

  const [runAgain, setRunAgain] = useState(false);

  const generateOptions = useCallback((count, label) => {
    return Array.from({ length: count }, (_, index) => ({
      label: `${index + 1} ${label}${index === 0 ? "" : "s"}`,
      value: `${index + 1}_${label}`,
    }));
  }, []);

  const handleIntervalChange = useCallback(
    (interval) => {
      setRecurrInterval(interval);
      setRecurringType(null);
      setSelectedDays({
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
      });

      let options = [];
      if (interval.value === "weekly") {
        options = generateOptions(52, "Week");
      } else if (interval.value === "fortnight") {
        options = generateOptions(26, "Fortnight");
      } else if (interval.value === "monthly") {
        options = generateOptions(12, "Month");
      }
      setRecurringFor(options);
    },
    [setRecurrInterval, setRecurringType]
  );

  useEffect(() => {
    if (
      shiftDetailData?.recurring_interval !== undefined &&
      shiftDetailData?.recurring_interval !== null &&
      selectedShift !== null
    ) {
      setValue("is_recurring", true);

      const matchingInterval = RECURRING_INTERVAL?.find(
        (el) =>
          capitalizeFirstLetter(el.value) === shiftDetailData.recurring_interval
      );

      if (matchingInterval) {
        handleIntervalChange(matchingInterval);
      }

      setRecurrInterval(matchingInterval);
    }
  }, [shiftDetailData, runAgain]);

  useEffect(() => {
    if (shiftDetailData?.recurring_interval !== null && recurringFor) {
      const recurringValue = convertWeeksToFormat(
        shiftDetailData.recurring_repeat
      );
      const matchingRecurringDuration =
        recurringFor && recurringFor?.find((el) => el.value === recurringValue);
      if (matchingRecurringDuration) {
        setRecurringType(matchingRecurringDuration);
      }
    }
  }, [runAgain, shiftDetailData, recurringFor]);

  useEffect(() => {
    if (
      !createOrUpdateWorkerShiftLoader &&
      (!createOrUpdateWorkerShiftError ||
        createOrUpdateWorkerShiftError.length === 0) &&
      isInitialCreateShiftLoader !== LOADER_STATUS.NA
    ) {
      handleClose();
      setIsFetchRecordDetailLoader(false);
      reset();
    }
  }, [createOrUpdateWorkerShiftLoader]);

  const handleTaskChange = (index, value) => {
    const updatedTasks = [...tasks];

    updatedTasks[index] = { task: value };

    setTasks(updatedTasks);
  };

  const handleAddTask = () => {
    if (checkRunningShiftStatus(selectedShift?.status)) return;
    setTasks([...tasks, { task: "" }]);
  };

  const handleTaskDelete = (Taskindex) => {
    if (!isNaN(Taskindex) && Taskindex !== null) {
      const updatedTasks = tasks.filter((_, i) => i !== Taskindex);
      setTasks(updatedTasks);
    }
  };

  const getSelectedOption = (value) => {
    const watchFormValue = watchFormData && watchFormData?.[`${value}`];
    if (Array.isArray(watchFormValue)) {
      return watchFormValue?.map((val) =>
        workerOptions?.find((x) => x?.value === val?.value)
      );
    } else {
      return watchFormValue
        ? workerOptions?.find((x) => x?.value === watchFormValue)
        : null;
    }
  };

  const getSelectedParticipant = (value) => {
    const watchFormValue = watchFormData && watchFormData?.[`${value}`];

    if (Array.isArray(watchFormValue)) {
      const updatedids = watchFormValue
        ?.map((val) => participantOptions?.find((x) => x?.uuid === val?.uuid))
        ?.filter((x) => !isEmpty(x));

      return updatedids;
    } else {
      return watchFormValue
        ? participantOptions?.find((x) => x?.value === watchFormValue)
        : null;
    }
  };

  useEffect(() => {
    if (
      watchFormData?.start_time &&
      watchFormData?.end_time &&
      getShiftLength(
        watchFormData?.start_time,
        watchFormData?.end_time,
        false
      ) !== "00:00"
    ) {
      clearErrors("end_time");
    }
  }, [watchFormData?.end_time]);

  const checkDisabliltyOfShiftForWorker = () => {
    // if (isFetchRecordDetailLoader) return true;
    // else if (selectedShift !== null) {
    //   return selectedShift?.status === SHIFT_STATUS.DID_NOT_ACCEPT
    //     ? false
    //     : selectedShift !== null;
    // }
    return userItem && calendarView === "worker-view" ? true : false;
  };

  const handleRedirection = (uuid) => {
    selectedTabState.save(
      JSON.stringify({
        name: "Settings",
        id: 13,
        children: [],
      })
    );
    navigate(`/sp-profile/${uuid}`);
  };

  const isParticipantStateMissing = () => {
    const selectedParticipant = getSelectedParticipant("pt_id");

    if (selectedParticipant) {
      if (Array.isArray(selectedParticipant)) {
        return selectedParticipant.some((item) => !item?.state_id);
      } else {
        return !selectedParticipant.state_id;
      }
    }

    return false;
  };

  const handleLimitTheParticipant = () => {
    const selectedParticipant = getSelectedParticipant("pt_id");
    if (selectedParticipant) {
      if (Array.isArray(selectedParticipant)) {
        return selectedParticipant.length > 4;
      } else {
        return false;
      }
    }

    return false;
  };

  const selectedParticipant = useMemo(
    () => getSelectedParticipant("pt_id"),
    [getSelectedParticipant("pt_id")]
  );

  const selectedWorker = useMemo(
    () => getSelectedOption("worker_id"),
    [getSelectedOption("worker_id")]
  );

  const [shiftOverlap, setShiftOverlap] = useState(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    } else {
      if (
        watchFormData?.shift_date &&
        watchFormData?.end_time &&
        watchFormData?.start_time
        // &&
        // (isWorkerLoaded || isParticipantLoaded)
      ) {
        setLoading(true);
        const payloadData = {
          id: selectedShift?.id,
          worker_id: Array.isArray(selectedWorker)
            ? selectedWorker?.map((item) => item.id)
            : [selectedWorker?.id],
          pt_id: Array.isArray(selectedParticipant)
            ? selectedParticipant?.map((item) => item.id)
            : [selectedParticipant?.id],
          end_time:
            isCheckNextDayShift(
              watchFormData?.shift_date,
              watchFormData?.start_time,
              watchFormData?.end_time
            ) !== false
              ? isCheckNextDayShift(
                  watchFormData?.shift_date,
                  watchFormData?.start_time,
                  watchFormData?.end_time
                )
              : convertTimeToDatetimeLocal(
                  moment(watchFormData.shift_date),
                  watchFormData.end_time
                ),
          shift_date: moment(new Date(watchFormData.shift_date)).toISOString(),
          start_time: convertTimeToDatetimeLocal(
            moment(watchFormData.shift_date),
            watchFormData.start_time
          ),
          is_group_shift: watchFormData?.is_group_shift || false,
        };

        apiPostRequest(URL_ROSTERING_SHIFT_CHECK_OVERLAPP, payloadData)
          .then((res) => {
            setLoading(false);
            setShiftOverlap(res?.data?.data);
          })
          .catch((error) => {
            setLoading(false);
            console.log({ error });
          });
      }
    }
  }, [
    watchFormData?.end_time,
    watchFormData?.worker_id,
    watchFormData?.pt_id,
    watchFormData?.is_group_shift,
    watchFormData?.start_time,
    watchFormData?.shift_date,
    recheckAvailability,
  ]);

  const handleShiftUpdatePermissionParticipantView = () => {
    if (
      selectedShift !== null &&
      watchFormData?.is_group_shift &&
      calendarView === "participant-view" &&
      shiftDetailData?.participants?.length > 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getToolTipContent = () => {
    return (
      <div className='flex flex-col gap-4 p-2'>
        <p className='text-xs text-white flex items-center '>
          <SwitchWithIcon checked={true} disabled={true} cssClass='mr-2' />
          Worker need to manually accept this shift
        </p>
        <p className='text-xs text-white flex items-center '>
          <SwitchWithIcon checked={false} disabled={true} cssClass='mr-2' />{" "}
          Shifts will be automatically accepted without requiring worker manual
          confirmation
        </p>
      </div>
    );
  };

  const handleWorkerAvailability = (item) => {
    setLoadingItemId(item.uuid);
    workerProfileDetailsFetchRequest({
      profile_id: item?.uuid,
    });
    setWorkerProfileUUID(item?.uuid);
    setOpenModal(true);
  };

  const disablDueToRecurringShift = () => {
    if (selectedShift !== null) {
      if (
        shiftDetailData?.recurring_interval !== null &&
        shiftDetailData?.recurring_repeat !== null
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleChangeServiceCategory = (serviceId) => {
    setValue("invoice_service_type_id", serviceId);
  };

  const selectedServiceCategory = useMemo(() => {
    const invoice_service_type_id = watchFormData?.invoice_service_type_id
      ? watchFormData?.invoice_service_type_id
      : selectedParticipant?.invoice_category_id;
    return (
      serviceTypes?.find((x) => x.value === invoice_service_type_id) || null
    );
  }, [watchFormData?.invoice_service_type_id]);

  const createShiftPopup = () => {
    return (
      <div>
        {watchFormData && (
          <>
            <div className='flex justify-between px-3'>
              {
                <WorkerShiftDetail
                  userItem={
                    calendarView === "worker-view"
                      ? getSelectedParticipant("pt_id")
                      : getSelectedOption("worker_id")
                  }
                  shiftDetail={watchFormData}
                  isGroupShift={watchFormData?.is_group_shift}
                />
              }
            </div>
            <div className='divide-y pt-4 border-b border-text-gray-700'></div>
          </>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='px-5 pt-7'>
            <div className='flex  gap-x-2  gap-y-2'>
              <div className='flex flex-col w-full sm:w-1/2 gap-y-4'>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                    Worker Tracking
                  </label>
                  <Controller
                    name='location_tracking'
                    control={control}
                    defaultValue={watchFormData?.location_tracking || true}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("location_tracking", value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1  sm:text-xs'>
                    Group Shift
                  </label>
                  <SwitchWithIcon
                    additionalCheck={(e) => {
                      if (
                        calendarView === "participant-view" &&
                        e &&
                        (watchFormData?.pt_id === null ||
                          watchFormData?.pt_id === undefined ||
                          watchFormData?.pt_id === "")
                      ) {
                        dispatch(
                          showToast({
                            id: TOAST_IDS.GROUP_SHIFT_CANNOT_CREATED,
                            type: TOAST_TYPE.ERROR,
                            title: "Plase Select Participant First!",
                          })
                        );
                        setValue("is_group_shift", false);
                        return false;
                      } else if (
                        calendarView === "worker-view" &&
                        e &&
                        (watchFormData?.worker_id === null ||
                          watchFormData?.worker_id === undefined ||
                          watchFormData?.worker_id === "")
                      ) {
                        dispatch(
                          showToast({
                            id: TOAST_IDS.GROUP_SHIFT_CANNOT_CREATED,
                            type: TOAST_TYPE.ERROR,
                            title: "Plase Select Worker First!",
                          })
                        );
                        setValue("is_group_shift", false);
                        return false;
                      }
                      return true;
                    }}
                    onChange={(value) => {
                      if (calendarView === "worker-view") {
                        const getIdFromParticipantsOpt =
                          getSelectedParticipant("pt_id");
                        setValue(
                          "pt_id",
                          value === true && getIdFromParticipantsOpt?.uuid
                            ? [{ ...getIdFromParticipantsOpt }]
                            : null
                        );
                      } else {
                        const getIdFromWorkersOpt =
                          getSelectedOption("worker_id");
                        setValue(
                          "worker_id",
                          value === true && getIdFromWorkersOpt?.uuid
                            ? [{ ...getIdFromWorkersOpt }]
                            : null
                        );
                      }
                      setValue("is_group_shift", value);
                    }}
                    checked={parseBooleanVal(watchFormData?.is_group_shift)}
                    disabled={
                      selectedShift &&
                      checkRunningShiftStatus(selectedShift?.status)
                    }
                  />
                </div>
              </div>
              <div className='w-0.5 mx-1 h-15 bg-gray-300'></div>

              <div className='flex flex-col w-full sm:w-1/2 gap-y-4'>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                    Participant's Signature
                  </label>
                  <Controller
                    name='is_signature'
                    control={control}
                    defaultValue={watchFormData?.is_signature || false}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("is_signature", value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1  sm:text-xs flex items-center justify-center'>
                    Shift Acceptance Required
                    <a data-tooltip-id='my-tooltip'>
                      <FaQuestionCircle
                        data-tip
                        data-tooltip-id='manualAcceptHelp'
                        className='ml-1 text-gray-500 cursor-pointer'
                      />
                    </a>
                  </label>
                  <Tooltip
                    id='my-tooltip'
                    place='top'
                    content={getToolTipContent()}
                    variant='dark'
                    className='z-20'
                  />
                  <Controller
                    name='shift_accept'
                    control={control}
                    defaultValue={watchFormData?.shift_accept || true}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("shift_accept", value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='w-0.5 h-15 mx-1 bg-gray-300'></div>
              <div className='flex flex-col w-full sm:w-1/2 gap-y-4'>
                <div className='flex items-center justify-between'>
                  <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                    Allow Shift Overlapping
                  </label>
                  <Controller
                    name='shift_overlapped'
                    control={control}
                    defaultValue={watchFormData?.shift_overlapped || false}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue("shift_overlapped", value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {shiftOverlap &&
              shiftOverlap?.overlapping_shift &&
              shiftOverlap?.overlapping_shift[0]?.overlapped &&
              !watchFormData?.shift_overlapped && (
                <div className='flex items-center mt-2 bg-red-100 border border-red-400 text-red-700 p-1 rounded'>
                  <FaExclamationCircle className='text-red-500 mr-2 h-6 w-6' />
                  <p className='text-sm font-semibold'>
                    {shiftOverlap &&
                      shiftOverlap?.overlapping_shift[0]?.worker_name?.length >
                        0 &&
                      shiftOverlap?.overlapping_shift[0]?.worker_name?.map(
                        (item) => `${item},`
                      )}
                    {shiftOverlap?.overlapping_shift[0]?.worker_name?.length >
                      0 &&
                    shiftOverlap?.overlapping_shift[0]?.worker_name?.length ===
                      1
                      ? "already has"
                      : "already have"}
                    shift during this time, causing an overlap. To proceed with
                    scheduling, please turn on the "Allow Shift Overlapping"
                    option
                  </p>
                </div>
              )}
            {loading ? (
              <div className='flex justify-center items-center w-full my-2'>
                <Loader isScheduleScreen />
              </div>
            ) : (
              shiftOverlap &&
              shiftOverlap?.availability?.length > 0 &&
              shiftOverlap?.availability?.map((item) => (
                <div className='flex items-center mt-2 bg-yellow-100 border border-yellow-500 text-yellow-700 p-1 rounded'>
                  <IoWarningOutline className='text-yellow-500 mr-2 h-6 w-6' />
                  <p className='text-sm font-semibold'>
                    {item?.name} is not available for the selected time slot,
                    <span
                      onClick={() => handleWorkerAvailability(item)}
                      className=' text-blue-500 text-sm hover:text-blue-700 cursor-pointer underline'
                    >
                      {getWorkerProfileLoader &&
                      loadingItemId === item?.uuid ? (
                        <Loader cssClass='ml-2' isScheduleScreen isSmallView />
                      ) : (
                        " Please click here to check their availability."
                      )}
                    </span>
                  </p>
                </div>
              ))
            )}
            <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
              <WorkerSelection
                selectedWorker={selectedWorker}
                watchFormData={watchFormData}
                calendarView={calendarView}
                selectedShift={selectedShift}
                workerOptions={workerOptions}
                register={register}
                setValue={setValue}
                control={control}
                watch={watch}
                getSelectedOption={getSelectedOption}
                setSelectedOptions={setSelectedOptions}
                selectedOptions={selectedOptions}
                clearErrors={clearErrors}
                errors={errors}
                isCallFromAddShift={isCallFromAddShift}
                setIsWorkerLoaded={setIsWorkerLoaded}
                isWorkerLoaded={isWorkerLoaded}
                setWorkerOptions={setWorkerOptions}
                setParticipantOptions={setParticipantOptions}
                checkDisabilityOfShiftStatus={checkDisabilityOfShiftStatus}
                checkDisabliltyOfShiftForWorker={
                  checkDisabliltyOfShiftForWorker
                }
                checkRunningShiftStatus={checkRunningShiftStatus}
                handleChangeWorkerOrParticipant={
                  handleChangeWorkerOrParticipant
                }
                userItem={userItem}
                isFetchRecordDetailLoader={isFetchRecordDetailLoader}
                setIsParticipantLoaded={setIsParticipantLoaded}
              />

              <ParticipantSelection
                getSelectedOption={getSelectedOption}
                getSelectedParticipant={getSelectedParticipant}
                createOrUpdateWorkerShiftLoader={
                  createOrUpdateWorkerShiftLoader
                }
                handleChangeServiceCategory={handleChangeServiceCategory}
                watchFormData={watchFormData}
                calendarView={calendarView}
                setParticipantOptions={setParticipantOptions}
                participantOptions={participantOptions}
                register={register}
                setValue={setValue}
                selectedShift={selectedShift}
                checkRunningShiftStatus={checkRunningShiftStatus}
                checkDisabilityOfShiftStatus={checkDisabilityOfShiftStatus}
                handleChangeWorkerOrParticipant={
                  handleChangeWorkerOrParticipant
                }
                isFetchRecordDetailLoader={isFetchRecordDetailLoader}
                control={control}
                watch={watch}
                setSelectedOptions={setSelectedOptions}
                selectedOptions={selectedOptions}
                clearErrors={clearErrors}
                errors={errors}
                userItem={userItem}
                setIsWorkerLoaded={setIsWorkerLoaded}
                isCallFromAddShift={isCallFromAddShift}
                setWorkerOptions={setWorkerOptions}
                setIsParticipantLoaded={setIsParticipantLoaded}
                isParticipantLoaded={isParticipantLoaded}
                selectedParticipant={selectedParticipant}
              />

              {getSelectedParticipant("pt_id") && (
                <div className='col-span-2 '>
                  {Array.isArray(getSelectedParticipant("pt_id"))
                    ? getSelectedParticipant("pt_id").length > 0 &&
                      getSelectedParticipant("pt_id").some(
                        (item) => !item.state_id
                      ) && (
                        <div>
                          {getSelectedParticipant("pt_id")
                            .filter((item) => !item.state_id)
                            .map((el, index, array) => (
                              <div
                                key={index}
                                className='flex items-center bg-red-100 border border-red-400 text-red-700 p-2 rounded'
                                style={{ marginTop: index !== 0 ? "7px" : "" }}
                              >
                                <FaExclamationCircle className='text-red-500 mr-2' />
                                <p className='text-sm font-semibold'>
                                  The Catalog Service State is missing for
                                  <span className='capitalize '>
                                    {el?.first_name} {el?.last_name}{" "}
                                  </span>{" "}
                                  {/* has missing the Catalog Service State,{" "} */}
                                  <span
                                    onClick={() => handleRedirection(el?.uuid)}
                                    className='capitalize text-blue-500 text-sm hover:text-blue-700 cursor-pointer underline'
                                  >
                                    , Please add here
                                  </span>
                                </p>
                              </div>
                            ))}
                        </div>
                      )
                    : !getSelectedParticipant("pt_id")?.state_id && (
                        <div className='flex items-center bg-red-100 border border-red-400 text-red-700 p-2 rounded'>
                          <FaExclamationCircle className='text-red-500 mr-2' />
                          <p className='text-sm font-semibold'>
                            The Catalog Service State is missing for{" "}
                            <span className='capitalize '>
                              {getSelectedParticipant("pt_id")?.first_name}{" "}
                              {getSelectedParticipant("pt_id")?.last_name}
                            </span>{" "}
                            {/* has missing the Catalog Service State, */}
                            <span
                              onClick={() =>
                                handleRedirection(
                                  getSelectedParticipant("pt_id")?.uuid
                                )
                              }
                              className='capitalize text-blue-500 text-sm  hover:text-blue-700 cursor-pointer underline'
                            >
                              {" "}
                              , Please add here
                            </span>
                          </p>
                        </div>
                      )}
                </div>
              )}

              <div className='col-span-2'>
                <div className='grid grid-cols-3 gap-x-5'>
                  <div className='col-span-1'>
                    <InputField
                      setValue={setValue}
                      register={register}
                      pattern={
                        validateCurrentDate(
                          moment(watchFormData?.date ?? null),
                          moment(new Date()),
                          ["present", "future", "past"],
                          true
                        )
                          ? DUMMY_REGEX_EXP
                          : null
                      }
                      label={"Shift Date"}
                      type='date'
                      value={
                        watchFormData?.date
                          ? moment(watchFormData?.date).format("DD-MM-YYYY")
                          : null
                      }
                      name='shift_date'
                      isDisabled={
                        selectedShift ||
                        checkDisabilityOfShiftStatus(selectedShift?.status)
                      }
                      readOnly={disablDueToRecurringShift()}
                      isRequired={true}
                      ErrorMessage={
                        errors?.shift_date?.type === "required"
                          ? "This field is required!"
                          : errors?.shift_date?.type === "pattern"
                          ? validateCurrentDate(
                              moment(watchFormData?.shift_date ?? null),
                              moment(new Date()),
                              ["present", "future", "past"],
                              false
                            )
                          : null
                      }
                    />
                  </div>
                  <div className='col-span-1'>
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      Shift Start Time
                    </label>
                    <SelectOption
                      isDisabled={checkRunningShiftStatus(
                        selectedShift?.status
                      )}
                      isRequired={true}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      name='start_time'
                      cssClass='z-20'
                      data={SHIFT_TIME_OPTIONS}
                      selectedOption={SHIFT_TIME_OPTIONS.find(
                        (x) => x.value === watchFormData?.start_time
                      )}
                      errorMessage={
                        errors?.start_time?.type === "required"
                          ? "This field is required!"
                          : null
                      }
                    />
                  </div>
                  <div className='col-span-1'>
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      Shift End Time
                    </label>
                    <SelectOption
                      isDisabled={checkRunningShiftStatus(
                        selectedShift?.status
                      )}
                      isRequired={true}
                      register={register}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      readOnly={false}
                      name='end_time'
                      cssClass='z-20'
                      data={SHIFT_TIME_OPTIONS}
                      selectedOption={SHIFT_TIME_OPTIONS.find(
                        (x) => x.value === watchFormData?.end_time
                      )}
                      errorMessage={
                        errors?.end_time?.type === "required"
                          ? "This field is required!"
                          : errors?.end_time?.type === "validate" &&
                            getShiftLength(
                              watchFormData?.start_time,
                              watchFormData?.end_time,
                              true
                            ) === "00:00"
                          ? "Shift can't be zero hours & minutes!"
                          : null
                      }
                      validate={getShiftLength(
                        watchFormData?.start_time,
                        watchFormData?.end_time,
                        false,
                        true
                      )}
                    />
                  </div>
                  {/* <div className='col-span-2'>
                    <Controller
                      name='timeRange'
                      control={control}
                      rules={{
                        validate: {
                          required: (value) => {
                            const [start, end] = value || [];
                            return (
                              (!!start && !!end) ||
                              "Both start and end times are required"
                            );
                          },
                          positiveDifference: (value) => {
                            const [start, end] = value || [];
                            if (!start || !end) return true; // Skip if required rule is already handling this
                            return getShiftLength(
                              moment(start).format("HH:mm"),
                              moment(end).format("HH:mm"),
                              true
                            ) === "00:00"
                              ? "Shift can't be zero hours & minutes!"
                              : true;
                          },
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        // fieldState: { error },
                      }) => (
                        <TimeInputRange
                          // clearable
                          noSeperator={true}
                          invalid={errors.timeRange}
                          format='12'
                          amLabel='AM'
                          pmLabel='PM'
                          value={value || [null, null]}
                          onChange={(e) => {
                            onChange(e); // update the form field value
                            setValue("start_time", e?.[0]);
                            setValue("end_time", e?.[1]);
                          }}
                        />
                      )}
                    />
                    {errors.timeRange && (
                      <span
                        style={{ color: "red", fontSize: "13px" }}
                        // className='capitalize'
                      >
                        {errors.timeRange.message}
                      </span>
                    )}
                  </div> */}
                </div>
              </div>
              {shiftDetailData && shiftDetailData?.worker_start_time && (
                <div className='col-span-2'>
                  <div className='grid grid-cols-3 gap-x-5'>
                    <div className='col-span-1'>
                      <InputField
                        setValue={setValue}
                        register={register}
                        pattern={
                          validateCurrentDate(
                            moment(watchFormData?.date ?? null),
                            moment(new Date()),
                            ["present", "future", "past"],
                            true
                          )
                            ? DUMMY_REGEX_EXP
                            : null
                        }
                        label={"Shift Date"}
                        type='date'
                        value={
                          watchFormData?.date
                            ? moment(watchFormData?.date).format("DD-MM-YYYY")
                            : null
                        }
                        name='shift_date'
                        isDisabled={selectedShift}
                        isRequired={true}
                        readOnly={true}
                        ErrorMessage={
                          errors?.shift_date?.type === "required"
                            ? "This field is required!"
                            : errors?.shift_date?.type === "pattern"
                            ? validateCurrentDate(
                                moment(watchFormData?.shift_date ?? null),
                                moment(new Date()),
                                ["present", "future", "past"],
                                false
                              )
                            : null
                        }
                      />
                    </div>
                    <div className='col-span-1'>
                      <label className='text-gray-700 text-sm font-medium mb-1'>
                        Shift Start Time(worker)
                      </label>
                      <InputField
                        readOnly={true}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        name='worker_start_time'
                        cssClass='z-20'
                        // data={SHIFT_TIME_OPTIONS}
                        // selectedOption={SHIFT_TIME_OPTIONS.find(
                        //   (x) => x.value === watchFormData?.worker_start_time
                        // )}
                        // errorMessage={
                        //   errors?.worker_start_time?.type === "required"
                        //     ? "This field is required!"
                        //     : null
                        // }
                      />
                    </div>

                    <div className='col-span-1'>
                      <label className='text-gray-700 text-sm font-medium mb-1'>
                        Shift End Time(worker)
                      </label>
                      <InputField
                        readOnly={true}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        name='worker_end_time'
                        isDisabled={
                          watchFormData?.worker_start_time === null ||
                          watchFormData?.worker_start_time === undefined
                        }
                        cssClass='z-20'

                        // errorMessage={
                        //   errors?.worker_end_time?.type === "required"
                        //     ? "This field is required!"
                        //     : errors?.worker_end_time?.type === "validate" &&
                        //       getShiftLength(
                        //         watchFormData?.worker_start_time,
                        //         watchFormData?.worker_end_time,
                        //         true
                        //       ) === "00:00"
                        //     ? "Shift can't be zero hours & minutes!"
                        //     : null
                        // }
                        // validate={getShiftLength(
                        //   watchFormData?.worker_start_time,
                        //   watchFormData?.worker_end_time,
                        //   false,
                        //   true
                        // )}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='grid grid-cols-3 gap-x-5 mt-3'>
              <div className='col-span-1'>
                <label className='text-gray-700 text-sm font-medium mb-1'>
                  Service Category
                </label>
                <SelectOption
                  data={serviceTypes}
                  isDisabled={checkRunningShiftStatus(selectedShift?.status)}
                  isRequired={true}
                  register={register}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  name='invoice_service_type_id'
                  cssClass='z-20'
                  selectedOption={selectedServiceCategory}
                  errorMessage={
                    errors?.invoice_service_type_id?.type === "required"
                      ? "This field is required!"
                      : null
                  }
                />
              </div>
              <div className='flex items-center gap-x-2'>
                <label className='text-gray-700 text-sm font-medium '>
                  Ratio (Worker: Participants)
                </label>
                {Array.isArray(workerRatio)
                  ? workerRatio?.length
                  : workerRatio
                  ? 1
                  : 0}{" "}
                :{" "}
                {Array.isArray(participantRatio)
                  ? participantRatio?.length
                  : participantRatio
                  ? 1
                  : 0}
              </div>

              <div
                className='flex items-center gap-x-2'
                title='select this option if sleepover shift'
              >
                <label className='text-gray-700 text-sm font-medium mb-1'>
                  Sleepover Shift
                </label>
                <div>
                  <Controller
                    name={`sleepover`}
                    control={control}
                    defaultValue={watchFormData?.sleepover || false}
                    render={({ field }) => (
                      <SwitchWithIcon
                        onChange={(value) => {
                          field.onChange(value);
                          setValue(`sleepover`, value);
                        }}
                        checked={field.value}
                        disabled={checkRunningShiftStatus(
                          selectedShift?.status
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='col-span-2 mt-2'>
              <label className='text-gray-700 text-sm font-medium mb-1'>
                HR Tasks
              </label>

              {tasks.map((taskObj, index) => (
                <div key={index} className='flex justify-between'>
                  <div className='w-[95%]'>
                    <InputField
                      readOnly={checkRunningShiftStatus(selectedShift?.status)}
                      placeholder='Add your tasks here...'
                      value={taskObj.task}
                      handleChange={(e) => handleTaskChange(index, e)}
                      type='text'
                      name={`task[${index}]`}
                      icon={
                        taskObj?.is_completed && (
                          <HiCheckCircle
                            className=' h-5 w-5 text-green-500'
                            title='Task Completed'
                          />
                        )
                      }
                    />
                  </div>

                  <div className='pt-3'>
                    <div
                      onClick={() =>
                        !checkRunningShiftStatus(selectedShift?.status) &&
                        handleTaskDelete(index)
                      }
                      tabIndex={0}
                      role='button'
                      className='icon-delete-wrapper'
                      title='Delete Task'
                    >
                      <HiOutlineTrash className='icon-size' />
                    </div>
                  </div>
                </div>
              ))}

              <div className='mt-1 text-[#4B6CB7] text-[15px] font-medium max-w-ma'>
                <span
                  onClick={handleAddTask}
                  className={`${
                    !checkRunningShiftStatus(selectedShift?.status) &&
                    "cursor-pointer"
                  }`}
                >
                  + Add More Tasks
                </span>
              </div>

              {shiftDetailData?.shift_notes?.map((singleShiftNote, index) => (
                <div
                  key={index}
                  className='flex flex-col items-start border-gray-200 border-b py-5'
                >
                  <label className='text-gray-700 text-sm font-medium mb-1'>
                    Shift Notes for{" "}
                    {capitalizeAllLetters(singleShiftNote?.first_name)}{" "}
                    {capitalizeAllLetters(singleShiftNote?.last_name)}
                  </label>
                  <br />
                  <textarea
                    className='bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 text-base rounded-md resize-y overflow-y-auto'
                    rows={1}
                    value={singleShiftNote?.notes}
                    readOnly
                  />
                </div>
              ))}
              {selectedShift !== null &&
                shiftDetailData?.recurring_interval !== null && (
                  <div
                    className='col-span-2 flex gap-5  items-center   mt-6 '
                    ref={modalContentRef}
                  >
                    <div className='flex gap-3'>
                      <label className='text-gray-700 text-sm font-medium mb-1'>
                        Do you want to make this shift recurring?
                      </label>
                      <div>
                        <Controller
                          name={`is_recurring`}
                          control={control}
                          defaultValue={watchFormData?.is_recurring || false}
                          render={({ field }) => (
                            <SwitchWithIcon
                              onChange={(value) => {
                                field.onChange(value);
                                setValue(`is_recurring`, value);
                                setRecurringType(null);
                                setRecurrInterval(null);
                              }}
                              checked={field.value}
                              disabled={selectedShift !== null}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {/* {selectedShift !== null && (
                      <>
                        <RecurringShiftSwitch
                          control={control}
                          watchFormData={watchFormData}
                          selectedShift={selectedShift}
                          setValue={setValue}
                          setRecurringEffectedShiftCount={
                            setRecurringEffectedShiftCount
                          }
                          setRunAgain={setRunAgain}
                          setRecurringType={setRecurringType}
                          setRecurrInterval={setRecurrInterval}
                          setLoading={setLoader}
                        />
                      </>
                    )} */}
                  </div>
                )}
              {!isDateInPast(watchFormData?.shift_date) &&
                selectedShift === null && (
                  <div
                    className='col-span-2 flex gap-2  items-center  mt-6 '
                    ref={modalContentRef}
                  >
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      Do you want to make this shift recurring?
                    </label>
                    <div>
                      <Controller
                        name={`is_recurring`}
                        control={control}
                        defaultValue={watchFormData?.is_recurring || false}
                        render={({ field }) => (
                          <SwitchWithIcon
                            onChange={(value) => {
                              field.onChange(value);
                              setValue(`is_recurring`, value);
                              setRecurringType(null);
                              setRecurrInterval(null);
                            }}
                            checked={field.value}
                            // disabled={selectedShift!==null}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}

              {/* {recurringEffectedShiftCount !== undefined &&
                recurringEffectedShiftCount !== null && (
                  <div className='flex items-center mt-2 bg-yellow-100 border border-yellow-500 text-yellow-700 p-1 rounded'>
                    <IoWarningOutline className='text-yellow-500 mr-2 h-6 w-6' />
                    <p className='text-sm font-semibold'>
                      {recurringEffectedShiftCount > 0
                        ? `The changes will impact ${recurringEffectedShiftCount} additional shifts.`
                        : "There are no other recurring shifts aside from this one."}
                    </p>
                  </div>
                )}
                )} */}

              {watchFormData?.is_recurring && (
                <div className='col-span-2'>
                  <RecurringShift
                    setRecurringType={setRecurringType}
                    recurringType={recurringType}
                    watchFormData={watchFormData}
                    setRecurrInterval={setRecurrInterval}
                    recurrInterval={recurrInterval}
                    setRecurringDates={setRecurringDates}
                    setSelectedDays={setSelectedDays}
                    selectedDays={selectedDays}
                    selectedShift={selectedShift}
                    setRecurringFor={setRecurringFor}
                    recurringFor={recurringFor}
                    handleIntervalChange={handleIntervalChange}
                    shiftDetailData={shiftDetailData}
                  />
                </div>
              )}
            </div>
            <div className='hidden'>
              <button
                id='createShiftButton'
                type='submit'
                className='hidden sr-only'
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  const createAndPublishFnCheck = () => {
    if (!selectedWorker) {
      return true;
    }

    if (
      watchFormData?.shift_date === undefined ||
      watchFormData?.shift_date === null ||
      !watchFormData?.shift_date
    ) {
      return true;
    }
    const participantTimezone = selectedParticipant?.timezone;
    const today = moment().startOf("day");
    const shiftDate = moment(watchFormData?.shift_date).startOf("day");
    const isToday = today.isSame(shiftDate, "day");

    if (isToday) {
      const now = participantTimezone
        ? momentTimeZone.tz(new Date(), participantTimezone)
        : momentTimeZone();

      const shiftStartString = `${watchFormData?.shift_date} ${watchFormData?.start_time}`;
      const shiftStartTime = participantTimezone
        ? momentTimeZone.tz(
            shiftStartString,
            "YYYY-MM-DD hh:mm A",
            participantTimezone
          )
        : momentTimeZone(shiftStartString, "YYYY-MM-DD hh:mm A");

      if (!shiftStartTime.isBefore(now)) {
        return false || parseBooleanVal(watchFormData?.is_recurring);
      }
    }

    return (
      today.isAfter(watchFormData?.shift_date) ||
      parseBooleanVal(watchFormData?.is_recurring) ||
      !today.isBefore(watchFormData?.shift_date)
    );
  };

  const updateAndPublishFnCheck = () => {
    const today = moment().startOf("day");
    const statusArray = [
      SHIFT_STATUS.ACCEPTED,
      SHIFT_STATUS.APPROVED,
      SHIFT_STATUS.EXPIRED,
    ];
    return (
      parseBooleanVal(watchFormData?.is_recurring) ||
      statusArray.includes(selectedShift.status) ||
      today.isAfter(watchFormData?.shift_date)
    );
  };

  const modalFooter = () => (
    <>
      {handleShiftUpdatePermissionParticipantView() && (
        <p className='text-sm text-red-400 flex'>
          {" "}
          <FaExclamationCircle className='text-red-500 mr-1 h-5 w-5' /> Group
          shifts for multiple participants can only be updated from worker view.
          Please switch to worker view to make changes.
        </p>
      )}
      {selectedShift === null && (
        <CustomButton
          clickHandler={() => {
            if (isUserGracePeriodOver) {
              return;
            }
            if (checkRunningShiftStatus(selectedShift?.status)) {
              return;
            }
            const buttonRef = document.getElementById("createShiftButton");
            if (buttonRef) {
              // buttonRef.click();
              handleSubmit(onSubmit)("isPublish");
            }
          }}
          // showLoading={loading || createOrUpdateWorkerShiftLoader}
          isDisabled={
            createOrUpdateWorkerShiftLoader ||
            loading ||
            checkRunningShiftStatus(selectedShift?.status) ||
            isUserGracePeriodOver ||
            isParticipantStateMissing() ||
            (shiftOverlap?.overlapping_shift &&
              shiftOverlap?.overlapping_shift[0]?.overlapped &&
              !watchFormData?.shift_overlapped) ||
            handleShiftUpdatePermissionParticipantView() ||
            createAndPublishFnCheck()
          }
          label={"Create and Publish"}
        />
      )}
      {selectedShift !== null && selectedShift?.uuid && selectedWorker && (
        // selectedShift.status === SHIFT_STATUS.PENDING &&
        <CustomButton
          clickHandler={() => {
            if (isUserGracePeriodOver) {
              return;
            }
            if (checkRunningShiftStatus(selectedShift?.status)) {
              return;
            }
            const buttonRef = document.getElementById("createShiftButton");
            if (buttonRef) {
              // buttonRef.click();
              handleSubmit(onSubmit)("isPublish");
            }
          }}
          // showLoading={loading || createOrUpdateWorkerShiftLoader}
          isDisabled={
            createOrUpdateWorkerShiftLoader ||
            loading ||
            checkRunningShiftStatus(selectedShift?.status) ||
            isUserGracePeriodOver ||
            isParticipantStateMissing() ||
            (shiftOverlap?.overlapping_shift &&
              shiftOverlap?.overlapping_shift[0]?.overlapped &&
              !watchFormData?.shift_overlapped) ||
            handleShiftUpdatePermissionParticipantView() ||
            updateAndPublishFnCheck()
          }
          label={"Update and Publish"}
        />
      )}
      <CustomButton
        clickHandler={() => {
          if (isUserGracePeriodOver) {
            return;
          }
          if (checkRunningShiftStatus(selectedShift?.status)) {
            return;
          }

          if (isDateInPast(watchFormData?.shift_date)) {
            setIsPastShift(true);
            return;
          }

          const buttonRef = document.getElementById("createShiftButton");
          if (buttonRef) {
            buttonRef.click();
          }
        }}
        // showLoading={createOrUpdateWorkerShiftLoader}
        showLoading={loading || createOrUpdateWorkerShiftLoader}
        isDisabled={
          createOrUpdateWorkerShiftLoader ||
          loading ||
          checkRunningShiftStatus(selectedShift?.status) ||
          isUserGracePeriodOver ||
          isParticipantStateMissing() ||
          (shiftOverlap?.overlapping_shift &&
            shiftOverlap?.overlapping_shift[0]?.overlapped &&
            !watchFormData?.shift_overlapped) ||
          handleShiftUpdatePermissionParticipantView()
        }
        label={`${selectedShift !== null ? "Update" : "Create"}`}
      />
    </>
  );

  const getRefereshedShiftdate = () => {
    if (watchFormData?.shift_date && selectedShift === null && !selectedDate) {
      return moment(watchFormData.shift_date, "YYYY-MM-DD").format(
        "dddd, Do MMM YYYY"
      );
    } else if (
      watchFormData?.shift_date &&
      selectedShift === null &&
      selectedDate &&
      !moment(selectedDate).isSame(watchFormData.shift_date)
    ) {
      return moment(watchFormData.shift_date, "YYYY-MM-DD").format(
        "dddd, Do MMM YYYY"
      );
    } else if (selectedDate) {
      return moment(selectedDate, "YYYY-MM-DD").format("dddd, Do MMM YYYY");
    } else if (selectedShift && selectedShift?.shift_date) {
      return moment(selectedShift.shift_date, "YYYY-MM-DD").format(
        "dddd, Do MMM YYYY"
      );
    }
    return moment().format("dddd, Do MMM YYYY");
  };

  useEffect(() => {
    if (spRosteringSetting) {
      setValue(
        "is_signature",
        parseBooleanVal(spRosteringSetting?.participant_signature)
      );
      setValue(
        "location_tracking",
        parseBooleanVal(spRosteringSetting?.worker_tracking)
      );
      setValue(
        "shift_accept",
        parseBooleanVal(spRosteringSetting?.shift_acceptance_required)
      );
    }
  }, [spRosteringSetting]);

  if (spRosteringSettingLoader) {
    return (
      <div
        className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
      >
        <CircularLoader
          classes='flex justify-center items-center'
          classloader='loader-l'
        />
      </div>
    );
  }

  return (
    <div>
      {isShow && (
        <ModalSkeleton
          title={
            watchFormData?.shift_date
              ? `${
                  selectedShift === null ? "New" : ""
                } Shift On ${getRefereshedShiftdate()} ${
                  isCheckNextDayShift(
                    watchFormData?.shift_date,
                    watchFormData?.start_time,
                    watchFormData?.end_time
                  ) !== false &&
                  getShiftLength(
                    watchFormData?.start_time,
                    watchFormData?.end_time,
                    true
                  ) !== "00:00"
                    ? ` - ${moment(
                        isCheckNextDayShift(
                          watchFormData?.shift_date,
                          watchFormData?.start_time,
                          watchFormData?.end_time
                        )
                      ).format("dddd, Do MMM YYYY")}`
                    : ""
                }`
              : ``
          }
          isOpen={isShow}
          cssClass='w-[70%]'
          bodyPadding='px-0'
          closeModal={() => {
            // setCreateShift(false);
            handleClose();
            setIsFetchRecordDetailLoader(false);
            reset();
          }}
          modalFooter={shiftDetailLoader ? null : modalFooter()}
        >
          {shiftDetailLoader ? (
            <div
              className={`min-h-screen bg-gray-50 items-center justify-center`}
            >
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            createShiftPopup()
          )}
        </ModalSkeleton>
      )}

      {openModal && workerAvailabilityData && !getWorkerProfileLoader && (
        <WorkerAvailabiltyModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          openModel={openModal}
          handleClose={setOpenModal}
          WorkerId={workerProfileUUID}
          setRefreshPageForWorkerTimeSlot={setRecheckAvailability}
          TimeSlots={workerAvailabilityData}
          workerProfileDetails={workerProfileDetails}
          isWorkerAvailablity={recheckAvailability}
        />
      )}

      {showUpdateShiftRecurringModal && (
        <UpdateRecurringShiftsModal
          isOpen={showUpdateShiftRecurringModal}
          content={null}
          title='Update Recurring Shift(s)'
          setIsConfirm={setUpdateShiftRecurringModal}
          shiftData={selectedShift}
          refreshCalendar={refreshCalendar}
          watch={watch}
          control={control}
          watchFormData={watchFormData}
          setValue={setValue}
          recurringUpdateShiftData={recurringUpdateShiftData}
          createOrUpdateWorkerShiftRequest={createOrUpdateWorkerShiftRequest}
        />
      )}

      {isPastShift && (
        <SweetAlertConfirmationModal
          onlyOkBtn={["Cancel", "Proceed"]}
          isOpen={isPastShift}
          // dangerMode={true}
          title='Shift added in the past'
          content={`The selected shift date is in the past. Please confirm if you would like to proceed. Note that past shifts are automatically accepted, and a corresponding timesheet will be generated.`}
          setIsConfirm={(e) => {
            if (e) {
              setIsPastShift(false);
              const buttonRef = document.getElementById("createShiftButton");
              if (buttonRef) {
                buttonRef.click();
              }
            } else {
              setIsPastShift(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default WorkerShift;
