import React from "react";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";

import { FaSort } from "react-icons/fa";

const SortIcon = ({ sortName, sortBy, sortDescending }) => {
  return (
    <>
      {sortBy !== sortName ? (
        <FaSort className="w-4 h-4" />
      ) : sortDescending ? (
        <AiOutlineSortDescending className="w-4 h-4" />
      ) : (
        <AiOutlineSortAscending className="w-4 h-4" />
      )}
    </>
  );
};

export default SortIcon;
