import React, { useContext, useEffect, useState } from "react";
import Xero from "assets/images/xero.png";
import MYOB from "assets/images/MYOB.png";
import QuickBooks from "assets/images/quickbook.png";
import { connect } from "react-redux";
import {
  capitalizeFirstLetter,
  classNames,
  currentLoggedInIdAndUUID,
  parseBooleanVal,
} from "Utils/utils";
import Header from "components/common/Header/Header";
import SideBarNew from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import { PAYROLL_PLATFORMS } from "store/constants";
import {
  URL_ROSTERING_MYOB_LOGIN,
  URL_ROSTERING_QUICK_BOOKS_LOGIN,
  URL_ROSTERING_XERO_LOGIN,
} from "store/services/URL";
import { getPayrollAuth } from "store/selector/auth.selector";
import { payrollAuthenticationUpdateLocally } from "store/actions/auth.actions";
import { toast } from "react-toastify";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import usePayrollAuthentication from "hooks/usePayrollAuthentication";
import { useParams } from "react-router";
import ApikeyModal from "./ApiKeyModal";

const PayrollSetting = (props) => {
  const { payrollAuthenticationUpdate, payrollAuth } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const { platform, status } = useParams();
  const {
    isAuthenticateForPayrolls,
    checkPayrollAuthentication,
    isCheckPayrollAuthLoading,
    isCallBackResp,
    clearIsCallBackResp,
  } = usePayrollAuthentication();

  const [seconds, setSeconds] = useState(60);
  const [isRunning, setIsRunning] = useState(false);

  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    let timer;

    if (isRunning) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          clearInterval(timer);
          setSeconds(60);
          setIsRunning(false);
          payrollAuthenticationUpdate(null);
        }
      }, 1000);
    } else {
      clearInterval(timer);
      setSeconds(60);
    }

    return () => {
      clearInterval(timer);
    };
  }, [seconds, isRunning]);

  useEffect(() => {
    if (platform && status) {
      checkPayrollAuthentication(platform?.replace("-", "_"));
    }
  }, [platform, status]);

  useEffect(() => {
    if (
      isCallBackResp &&
      platform &&
      platform?.replace("-", "_") === isCallBackResp?.platform
    ) {
      isCallBackResp?.status === true
        ? toast.success(
            `Successfully authorized with ${capitalizeFirstLetter(
              platform?.replace("-", " ")
            )}!`
          )
        : toast.error(
            `Authorization failed with ${capitalizeFirstLetter(
              platform?.replace("-", " ")
            )}!`
          );
      clearIsCallBackResp();
    }
  }, [isCallBackResp]);

  useEffect(() => {
    if (payrollAuth && typeof payrollAuth === "object") {
      if (payrollAuth.status === "success") {
        const type = payrollAuth.type?.replace("-", "_") || null;
        if (type) {
          checkPayrollAuthentication();
        }
        toast.success(payrollAuth.message);
        setIsRunning(false);
      } else if (payrollAuth.status === "error") {
        toast.error(payrollAuth.message);
      }
      payrollAuthenticationUpdate(null);
    }
  }, [payrollAuth]);

  const cards = [
    {
      name: "Xero",
      description: "Make payroll simple with Xero",
      type: PAYROLL_PLATFORMS.XERO,
      icon: Xero,
      isApiKey: false,
    },
    {
      name: "QuickBooks",
      description: "Fast and smart payroll",
      type: PAYROLL_PLATFORMS.QUICKBOOKS,
      icon: QuickBooks,
      isApiKey: true,
    },
    {
      name: "MYOB",
      description: "Simplify staff payroll",
      type: PAYROLL_PLATFORMS.MYOB,
      icon: MYOB,
      isApiKey: false,
    },
  ];

  const isDisabledOrLoaderForPayrollAuthOrExport = (
    isFor = "disabled",
    type = PAYROLL_PLATFORMS.XERO
  ) => {
    if (isFor === "loader") {
      return isAuthenticateForPayrolls !== null &&
        !parseBooleanVal(isAuthenticateForPayrolls?.[type]) &&
        typeof payrollAuth === "string" &&
        payrollAuth === `${type}-apiCall`
        ? true
        : false;
    } else {
      if (
        isAuthenticateForPayrolls !== null &&
        parseBooleanVal(isAuthenticateForPayrolls?.[type])
      ) {
        return true;
      }
      return isAuthenticateForPayrolls !== null &&
        !parseBooleanVal(isAuthenticateForPayrolls?.[type]) &&
        typeof payrollAuth === "string" &&
        payrollAuth.includes("apiCall")
        ? true
        : false;
    }
  };

  const handlePayrollAuth = async (payrollType, isapiKey = false) => {
    if (parseBooleanVal(isapiKey)) {
      setisOpen(true);
      return;
    }
    try {
      const userObjForSp = currentLoggedInIdAndUUID(false);
      const userObjForNonSp = currentLoggedInIdAndUUID(false);
      if (
        isAuthenticateForPayrolls !== null &&
        payrollType &&
        !parseBooleanVal(isAuthenticateForPayrolls?.[payrollType])
      ) {
        payrollAuthenticationUpdate(`${payrollType}-apiCall`);
        setIsRunning(true);
        const url =
          payrollType === PAYROLL_PLATFORMS.XERO
            ? URL_ROSTERING_XERO_LOGIN
            : payrollType === PAYROLL_PLATFORMS.QUICKBOOKS
            ? URL_ROSTERING_QUICK_BOOKS_LOGIN
            : payrollType === PAYROLL_PLATFORMS.MYOB
            ? URL_ROSTERING_MYOB_LOGIN
            : null;

        if (url && userObjForSp) {
          window.open(
            `${url}/${userObjForSp.id}/${userObjForSp.uuid}`,
            "_self"
          );
        } else if (url && userObjForNonSp) {
          window.open(
            `${url}/${userObjForNonSp?.sp_id}/${userObjForNonSp?.sp_uuid}`,
            "_self"
          );
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className='min-h-full'>
      <SideBarNew />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className='h-screen flex-1 bg-white'>
          <Header title='Payroll Settings' />
          {isCheckPayrollAuthLoading ? (
            <div className='flex justify-center items-center min-h-screen'>
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            <>
              <div className='max-w-7xl mx-auto'>
                <div
                  style={{ paddingTop: "15px", top: "70px" }}
                  className='sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center sticky py-2 bg-white z-[6]'
                >
                  <p className='font-bold text-xl'>Integrations</p>
                </div>
                <div className='sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center py-6'>
                  <div
                    className={`w-full bg-gray-50 px-5 py-4 grid gap-3 md:rounded-lg lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1`}
                  >
                    {cards.map((card, index) => (
                      <div
                        key={index}
                        className='cursor-pointer relative rounded-lg border border-gray-300 bg-white p-1 shadow-sm  items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 capitalize'
                      >
                        <div className='flex'>
                          <div
                            className={classNames(
                              "flex-shrink-0 flex items-center justify-center w-16 h-12 py-8 text-white text-sm font-medium rounded-md"
                            )}
                            style={{ backgroundColor: `${card.bgColor}` }}
                          >
                            <img
                              className={classNames(
                                "flex-shrink-0 h-10 w-10 text-white"
                              )}
                              aria-hidden='true'
                              alt={card.name}
                              src={card.icon}
                            />
                          </div>
                          <div className='flex-1 min-w-0'>
                            <div className='focus:outline-none'>
                              <p className='text-sm font-medium text-gray-900 mt-3'>
                                {card.name}
                              </p>
                              <p className='text-sm text-gray-500 truncate mb-3'>
                                {card.description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='py-1 flex justify-end px-3'>
                          <CustomButton
                            showLoading={
                              isDisabledOrLoaderForPayrollAuthOrExport(
                                "loader",
                                card.type
                              ) &&
                              typeof payrollAuth === "string" &&
                              payrollAuth === `${card.type}-apiCall`
                            }
                            isDisabled={
                              isDisabledOrLoaderForPayrollAuthOrExport(
                                "disabled",
                                card.type
                              ) || isUserGracePeriodOver
                            }
                            label={
                              isAuthenticateForPayrolls !== null &&
                              parseBooleanVal(
                                isAuthenticateForPayrolls?.[card.type]
                              )
                                ? "Connected"
                                : "Connect"
                            }
                            clickHandler={() => {
                              if (isUserGracePeriodOver) {
                                return;
                              }
                              handlePayrollAuth(card.type, card.isApiKey);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {isOpen && (
                <ApikeyModal
                  type={PAYROLL_PLATFORMS.QUICKBOOKS}
                  closeModal={() => setisOpen(false)}
                  isOpen={isOpen}
                  checkPayrollAuthentication={checkPayrollAuthentication}
                />
              )}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const payrollAuth = getPayrollAuth(state);

  return {
    payrollAuth,
  };
};

const mapDispatchToProps = {
  payrollAuthenticationUpdate: payrollAuthenticationUpdateLocally,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayrollSetting);