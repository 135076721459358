import ServiceCategoryBudget from "./ServiceCategoryBudget";
import { connect } from "react-redux";
import {
  getCreatePlanBudgetError,
  getExternalServiceProviders,
  getFetchServiceCategoryLoader,
  getPlanCatagories,
  getRemoveServiceCategoryLoader,
  getSelectedFunding,
  getServiceCatalogBudgetPlanCatAndSubCatOptions,
  getServiceCatalogStates,
  getStoreServiceCategoryPlanBudgetError,
  getServiceCategoryPlanBudget,
  getCreateServiceCategoryBudgetLoader,
} from "../../../../store/selector/cos.selector";
import { getSelectedParticipant } from "../../../../store/selector/participant.selector";
import {
  fetchServiceCategoryPlanBudgetRequest,
  getCatalogServicesSubCategoryRequest,
  getPlanBudgetCatAndSubCatRequest,
  getServiceCategoryRemoveRequest,
  // getPlanCategoriesRequest,
  storeServiceCategoryPlanBudgetRequest,
} from "../../../../store/actions/cos.actions";

const mapDispatchToProps = {
  getCatalogServicesSubCategory: getCatalogServicesSubCategoryRequest,
  // getPlanCatagories: getPlanCategoriesRequest,
  getPlanBudgetCatAndSubCat: getPlanBudgetCatAndSubCatRequest,
  storeServiceCategoryPlanBudget: storeServiceCategoryPlanBudgetRequest,
  getServiceCategoryList: fetchServiceCategoryPlanBudgetRequest,
  removeServiceCategory: getServiceCategoryRemoveRequest,
};

const mapStateToProps = (state) => {
  const planBudgetCatAndSubCatOptions =
    getServiceCatalogBudgetPlanCatAndSubCatOptions(state);
  const externalSps = getExternalServiceProviders(state);
  const storeServicePlanError = getStoreServiceCategoryPlanBudgetError(state);
  const PlanCategories = getPlanCatagories(state);
  const selectedFunding = getSelectedFunding(state);
  const participant = getSelectedParticipant(state);
  const catalogStates = getServiceCatalogStates(state);
  const storeServicePlanLoader = getCreateServiceCategoryBudgetLoader(state);
  const serviceCategories = getServiceCategoryPlanBudget(state);
  const fetchServiceCategoryLoader = getFetchServiceCategoryLoader(state);
  const removeServiceCategoryLoader = getRemoveServiceCategoryLoader(state);

  return {
    selectedFunding,
    participant,
    catalogStates,
    PlanCategories,
    planBudgetCatAndSubCatOptions,
    externalSps,
    storeServicePlanLoader,
    storeServicePlanError,
    serviceCategories,
    fetchServiceCategoryLoader,
    removeServiceCategoryLoader,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCategoryBudget);
