import React, { useContext, useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "../../../../components/common/Button/CustomButton";
import { PARTICIPANT_PROFILE_TABS } from "../../../../Constants/constant";
import FundingSetupModal from "./FundingSetupModal";
import FundingSetupTable from "./FundingSetupTable";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { LoginContext } from "helpers/LoginContext";

const FundingSetup = (props) => {
  const {
    participant,
    storeAndUpdateFundingSetupRequest,
    fundingSetupLoading,
    type,
    getFundingSetup,
    participantFundings,
    participantFundingsLoading,
    participantOnBoarding,
    getCatalogVersions,
    catalogVersions,
    catalogVerionLoading,
    setToggleState,
    updateFundingSetupRequest,
    storeAndUpdateError,
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [openModal, setOpenModal] = useState(false);
  const [editFundingData, setEditFundingData] = useState(null);

  useEffect(() => {
    if (
      type === "Header_button" &&
      catalogVersions.length === 0 &&
      participant.support_coordination
    ) {
      getCatalogVersions(false);
    }
    if (setToggleState && participant && !participant.support_coordination) {
      setToggleState(PARTICIPANT_PROFILE_TABS[0]);
    }
  }, []);

  if (type === "Header_button") {
    return (
      <>
        {roleAccess(
          [],
          PERMISSION_KEYS.PARTICIPANT,
          PERMISSION_KEYS_CHILDREN.PARTICIPANT.FUNDING.CREATE
        ) && (
          <CustomButton
            label='Add Funding'
            Icon={<BiPlus />}
            clickHandler={() => {
              setOpenModal(true);
            }}
          />
        )}
        {openModal && !catalogVerionLoading && (
          <FundingSetupModal
            isUserGracePeriodOver={isUserGracePeriodOver}
            participant={participant}
            storeAndUpdateFundingSetupRequest={
              storeAndUpdateFundingSetupRequest
            }
            fundingSetupLoading={fundingSetupLoading}
            openModal={openModal}
            setOpenModal={setOpenModal}
            catalogVersions={catalogVersions}
            storeAndUpdateError={storeAndUpdateError}
          />
        )}
      </>
    );
  }
  return (
    <>
      <FundingSetupTable
        participant={participant}
        getParticipantFundingsSetup={getFundingSetup}
        participantFundings={participantFundings}
        isLoading={participantFundingsLoading}
        participantOnBoarding={participantOnBoarding}
        setEditFundingData={setEditFundingData}
      />
      {editFundingData && !catalogVerionLoading && (
        <FundingSetupModal
          isUserGracePeriodOver={isUserGracePeriodOver}
          editFundingData={editFundingData}
          participant={participant}
          storeAndUpdateFundingSetupRequest={storeAndUpdateFundingSetupRequest}
          updateFundingSetupRequest={updateFundingSetupRequest}
          fundingSetupLoading={fundingSetupLoading}
          openModal={editFundingData ? true : false}
          setOpenModal={() => setEditFundingData(null)}
          catalogVersions={catalogVersions}
          storeAndUpdateError={storeAndUpdateError}
        />
      )}
    </>
  );
};

export default FundingSetup;
