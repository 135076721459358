import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const key = process.env.REACT_APP_STRIPE;

const stripePromise = loadStripe(key);

const StripePayment = (props) => {
  const { handleClose } = props;

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm handleClose={handleClose} />
    </Elements>
  );
};

export default StripePayment;
