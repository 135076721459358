import React, { useState, useEffect } from "react";
import {
  baseURLImage,
  classNames,
  downloadFileFromServer,
  getFileExtension,
  getFileExtensions,
  getFileSize,
  isValidFile,
  maxFileSize,
  textTruncate,
  // getFileExtensionForDocuments,
} from "Utils/utils";
import Style from "../FileUpload/FileUpload.module.css";
import { useDropzone } from "react-dropzone";
import { apiCall } from "store/services/service";
import { BASE_URL } from "store/services/URL";
import axios from "store/services/axios";
import fileUploadIcon from "assets/svg/file-upload.svg";
import { connect } from "react-redux";
import * as FileSaver from "file-saver";
import { getUser } from "store/selector/auth.selector";
import FileIcon from "../FileIcon/FileIcon";
import { cloneDeep } from "lodash";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import ConfirmationModal from "../Modal/ConfirmationModal";

const MultiFileInput = (props) => {
  const {
    type,
    handleFile,
    size,
    handleDelete,
    handleError,
    setFileExt,
    clearParentError,
    handleFileUploadBaseVapour,
    showDragAndDrop = true,
    isHardClearAllStates = false,
    isDisabled = false,
    files,
    currentLoginUser,
    maxFilesAllow = 5,
    handleDeleteItem,
    deleteFileLoader = null,
    filenameLimit = 50,
    updateFileName,
    selectedFiles,
    isVapourFileUpload = false,
    isFileUploadToS3 = false,
    handleClear,
  } = props;

  const [fileError, setFileError] = useState(null);
  const [progress, setProgress] = useState({});
  const [showProgress, setShowProgress] = useState(false);

  const [isOpenDeleteConfirmationModal, setIsOpenDeleteConfirmationModal] =
    useState(null);

  useEffect(() => {
    if (isHardClearAllStates) {
      // setFileList([]);
      setProgress({});
      setShowProgress(false);
      setFileError(null);
      if (clearParentError) {
        clearParentError();
      }
    }
  }, [isHardClearAllStates]);

  const clearAllStates = () => {
    // setFileList([]);
    setFileError(null);
    setProgress({});
    setShowProgress(false);
  };

  const handleChange = (event) => {
    if (event.currentTarget.files && handleFile) {
      const newFiles = Array.from(event.currentTarget.files);
      const totalFiles = files?.length + newFiles.length;

      if (totalFiles > maxFilesAllow) {
        setFileError(`You can only upload up to ${maxFilesAllow} files.`);
        return;
      }

      let validFiles = [];
      for (let file of newFiles) {
        let message = isValidFile(file, type);
        if (message.length > 0) {
          setFileError(message);
          return;
        }
        message = maxFileSize(file, size ? size : 10);
        if (message.length > 0) {
          setFileError(message);
          return;
        }
        if (file.name.length > filenameLimit) {
          setFileError(
            `File name exceeds the limit. It's up to ${filenameLimit} characters!`
          );
          return;
        }
        validFiles.push(file);
      }

      setFileError("");
      handleFile([
        ...files?.map((x) => {
          // if (x?.id) {
          //   return x.file;
          // }
          return x;
        }),
        ...validFiles,
      ]);
    }
  };
  // console.log("files", files);
  const handleRemove = (file) => {
    const prevFiles = cloneDeep(files);
    const fileName = file?.id ? file?.file?.name : file?.name;
    const index = prevFiles?.findIndex((file) =>
      file?.id ? file?.file?.name === fileName : file?.name === fileName
    );
    if (index !== -1) {
      if (handleDeleteItem && prevFiles[index]?.id && prevFiles[index].id) {
        handleDeleteItem(prevFiles[index], () => {
          prevFiles.splice(index, 1);
          handleDelete(
            prevFiles?.map((x) => {
              // if (x?.id) {
              //   return x.file;
              // }
              return x;
            })
          );
          toast.success("Attachment Deleted Successfully!");
        });
      } else if (handleDeleteItem && !prevFiles[index]?.id) {
        prevFiles.splice(index, 1);
        handleDelete(
          prevFiles?.map((x) => {
            // if (x?.id) {
            //   return x.file;
            // }
            return x;
          })
        );
      } else {
        prevFiles.splice(index, 1);
        handleDelete(
          prevFiles?.map((x) => {
            // if (x?.id) {
            //   return x.file;
            // }
            return x;
          })
        );
      }
    }
  };

  const onDrop = (uploadFiles) => {
    if (uploadFiles && uploadFiles.length && handleFile) {
      const totalFiles = files?.length + uploadFiles.length;

      if (totalFiles > 5) {
        setFileError("You can only upload up to 5 files.");
        return;
      }

      let validFiles = [];
      for (let file of uploadFiles) {
        let message = isValidFile(file, type);
        if (message.length > 0) {
          setFileError(message);
          return;
        }
        message = maxFileSize(file, size ? size : 10);
        if (message.length > 0) {
          setFileError(message);
          return;
        }
        if (file.name.length > filenameLimit) {
          setFileError(
            `File name exceeds the limit. It's up to ${filenameLimit} characters!`
          );
          return;
        }

        validFiles.push(file);
      }

      setFileError("");
      handleFile([
        ...files?.map((x) => {
          // if (x?.id) {
          //   return x.file;
          // }
          return x;
        }),
        ...validFiles,
      ]);
    }
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    disabled: isDisabled,
  });

  const uploadFileOnVapour = (file) => {
    if (!file) return;

    apiCall
      .post({
        url: `${BASE_URL}signed-url`,
        params: { contentType: file.type },
        isAuthToken: currentLoginUser?.AccessToken,
      })
      .then((response) => {
        storeFileOnS3(file, {}, response);
        if (setFileExt) {
          setFileExt(response.key);
        }
      })
      .catch((err) => {
        console.error("Error getting signed URL:", err);
        if (handleError) handleError();
      });
  };

  const storeFileOnS3 = (file, options = {}, response) => {
    const headers = response.headers;
    if ("Host" in headers) {
      delete headers.Host;
    }
    if (typeof options.progress === "undefined") {
      options.progress = () => {};
    }
    const cancelToken = options.cancelToken || "";
    axios
      .put(response.url, file, {
        cancelToken: cancelToken,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          options.progress(progressEvent.loaded / progressEvent.total);
          setShowProgress(true);
          setProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: (progressEvent.loaded / progressEvent.total) * 100,
          }));
        },
      })
      .then(() => {
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
        }));

        handleFileUploadBaseVapour({
          key: response.key,
          ext: file.name.split(".").pop(),
          name: file.name,
        });
        if (setFileExt) {
          setFileExt(response.key);
        }
      })
      .catch((e) => {
        console.error("Error uploading file to S3:", e);
        if (handleError) handleError();
      });
  };

  const handleDownload = (
    file,
    serverGlitchFile = false,
    fileUrlThings = {}
  ) => {
    if (file instanceof File) {
      const blob = new Blob([file], { type: file.type });
      FileSaver.saveAs(blob, file.name || "downloadedFile");
    } else if (
      serverGlitchFile &&
      fileUrlThings?.attachments &&
      fileUrlThings?.fileNameByCheck
    ) {
      FileSaver.saveAs(
        baseURLImage(fileUrlThings?.attachments),
        fileUrlThings?.fileNameByCheck
      );
    } else {
      downloadFileFromServer(file, file.name);
    }
  };

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          position: "relative",
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "10px",
          backgroundColor: "white",
        }}
      >
        <input
          {...getInputProps()}
          disabled={isDisabled}
          multiple
          onChange={handleChange}
          className='disabled:opacity-50'
        />
        <div
          className={`${
            showDragAndDrop
              ? "border-2 border-dashed border-gray-300 rounded-lg px-6 pt-5 pb-6"
              : ""
          } max-w-full`}
        >
          <div
            className={`${
              showDragAndDrop
                ? "space-y-1 text-center"
                : "text-center flex items-center gap-2 truncate"
            }`}
          >
            {showDragAndDrop ? (
              <>
                <svg
                  className='mx-auto h-12 w-12 text-gray-400'
                  stroke='currentColor'
                  fill='none'
                  viewBox='0 0 48 48'
                  aria-hidden='true'
                >
                  <path
                    d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <div className='flex text-sm text-gray-600'>
                  <label
                    htmlFor='file-upload'
                    className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                  >
                    <span>Upload files</span>
                    <input
                      disabled={isDisabled}
                      className='disabled:opacity-50'
                      {...getInputProps()}
                      multiple
                      onChange={handleChange}
                    />
                  </label>
                  <p className='pl-1'>or drag and drop</p>
                </div>
              </>
            ) : (
              <div
                className={classNames(
                  "flex items-center",
                  deleteFileLoader !== null && "opacity-50"
                )}
              >
                <img src={fileUploadIcon} alt='file-upload' className='mr-1' />
                <p className='text-xs text-gray-500'>
                  {`${getFileExtensions(type)
                    .join(",")
                    .toString()
                    .toUpperCase()} up to ${size ? size : "10"}MB`}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {fileError && (
        <div className='py-3 flex items-center justify-between text-sm'>
          <span className={`borderError ${Style.fileSizeError}`}>
            {fileError}
          </span>
        </div>
      )}
      {files?.length > 0 && (
        <div className='mt-4'>
          {files.map((file, index) => {
            let fileNameByCheck = file?.id ? file?.file?.name : file?.name;
            let serverGlitchFile = false;
            if (fileNameByCheck === null || fileNameByCheck === undefined) {
              serverGlitchFile = true;
              fileNameByCheck = file?.original_name;
            }
            return (
              <div
                key={index}
                className='border rounded-lg border-gray-300 px-2 mt-2'
              >
                <li className='py-3 flex justify-between text-sm'>
                  <div className='flex-1 flex items-center'>
                    <div className='bg-blue-50 rounded-full h-10 w-10 flex items-center text-center justify-center'>
                      {/* <img
                      src={FileImage}
                      alt=''
                      className='flex-shrink-0 h-7 w-7 pt-[10px] pl-[14px]'
                    /> */}
                      {FileIcon(getFileExtension(fileNameByCheck), 20)}
                    </div>
                    <div>
                      <span
                        onClick={() =>
                          handleDownload(
                            file?.id ? file?.file : file,
                            serverGlitchFile,
                            {
                              attachments: file?.id ? file?.attachments : null,
                              fileNameByCheck,
                            }
                          )
                        }
                        style={{ fontWeight: "500" }}
                        className='ml-2 flex-1 w-0 font-medium hover:underline cursor-pointer'
                        title={fileNameByCheck}
                      >
                        {textTruncate(fileNameByCheck, 30, 29)}
                      </span>
                      {/* <div>
                        <span className='text-sm font-normal ml-2 text-gray-500'>
                          {getFileSize(file?.id ? file?.file : file)}
                        </span>
                      </div> */}
                    </div>
                  </div>
                  <div className='ml-4 flex-shrink-0'>
                    {deleteFileLoader === fileNameByCheck ? (
                      <Loader isSmallView={true} cssClass={"text-black"} />
                    ) : (
                      <span
                        role='button'
                        tabIndex={0}
                        onClick={() => {
                          if (deleteFileLoader === null) {
                            setIsOpenDeleteConfirmationModal(file);
                          }
                        }}
                        style={deleteFileLoader ? { cursor: "default" } : {}}
                        // target='_blank'
                        className={classNames(
                          "font-medium text-red-500 hover:text-red-600",
                          deleteFileLoader !== null && "opacity-50"
                        )}
                      >
                        Remove
                      </span>
                    )}
                  </div>
                </li>
                <div className='px-10 mb-4'>
                  {showProgress && progress[fileNameByCheck] > 0 && (
                    <div className='w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2'>
                      <div
                        className='animate-pulse transition duration-300 ease-in-out bg-purple-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full'
                        style={{
                          width: `${progress[fileNameByCheck]}%`,
                        }}
                      >
                        {progress[fileNameByCheck].toFixed(0)}%
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {/* {updateFileName && !fileError && files?.length === 0 && (
        <div
          onClick={() => {
            if (file && !(file instanceof File)) {
              downloadFileFromServer(
                file,
                `${updateFileName}.${getFileExtension(file)}`
              );
            }
          }}
          title={
            file
              ? `${updateFileName}.${getFileExtension(file)}  `
              : `${updateFileName}.${getFileExtensionForDocuments(
                  selectedFiles?.link,
                  30,
                  29
                )}`
          }
          className='ml-2 flex-1 w-full font-medium hover:underline cursor-pointer'
        >
          {file
            ? textTruncate(
                `${updateFileName}.${getFileExtension(file)}`,
                30,
                29
              )
            : textTruncate(
                `${updateFileName}.${getFileExtensionForDocuments(
                  selectedFiles?.link
                )}`,
                30,
                29
              )}
        </div>
      )} */}
      {isOpenDeleteConfirmationModal !== null && (
        <ConfirmationModal
          isNew={true}
          showModal={isOpenDeleteConfirmationModal !== null}
          submitHandler={() => {
            handleRemove(isOpenDeleteConfirmationModal);
            setIsOpenDeleteConfirmationModal(null);
          }}
          closeModalHandler={() => setIsOpenDeleteConfirmationModal(null)}
          title='Delete Attachment'
          heading='Are you sure you want to delete this attachment?'
          cancelBtnLabel={"Cancel"}
          confirmBtnLabel={"Delete"}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);

  return { currentLoginUser };
};

export default connect(mapStateToProps, null)(MultiFileInput);
