import React, { useState } from "react";
import CustomButton from "components/common/Button/CustomButton";
import { USER_ROLE } from "Constants/constant";
import { roleAccess } from "helpers/RolesPermission";
import { BiPencil } from "react-icons/bi";
import { FaClone } from "react-icons/fa";
import { MdPublish } from "react-icons/md";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import api from "store/services/superAdmin.services";
import { updateAllFormTemplatesLocally } from "store/actions/sp.actions";
import SweetAlertConfirmationModal from "components/common/SweetAlertConfirmation/SweetAlertConfirmation";
import { HiEye } from "react-icons/hi";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const FormTemplateListActionButtons = (props) => {
  const {
    formTemplateStatus,
    template,
    editFormTemplateHandler,
    updateFormTemplateLocally,
    routeRole,
    isUserGracePeriodOver,
  } = props;
  const navigate = useNavigate();

  const [selectedPublishTemp, setSelectedPublishTemp] = useState(null);
  const [showConfirmationModalPublish, setShowConfirmationModalPublish] =
    useState(false);

  const viewFormTemplateHandler = () => {
    if (roleAccess([USER_ROLE.Super_Admin])) {
      navigate(
        `/${routeRole}/view-template/${template.uuid}/${formTemplateStatus.value}`
      );
    } else if (roleAccess([USER_ROLE.Sp_Admin])) {
      navigate(
        `/view-template/${routeRole}/${template.slug}/${formTemplateStatus.value}/read-only`
      );
    }
  };

  const handlePublishDraftTemplate = async (templateId) => {
    try {
      if (isUserGracePeriodOver) {
        return;
      }
      setSelectedPublishTemp(templateId);

      const response = roleAccess([USER_ROLE.Super_Admin])
        ? await api.formTemplateDraftToPublish({
            id: templateId,
            role: routeRole,
          })
        : await api.formTemplateDraftToPublishSP({
            id: templateId,
            role: routeRole,
          });

      if (response) {
        setSelectedPublishTemp(null);
        toast.success("Form Template Published Successfully!");
      }
      updateFormTemplateLocally({ id: templateId, type: "delete" });
      setSelectedPublishTemp(null);
    } catch (err) {
      setSelectedPublishTemp(null);
      toast.error(err.message ?? "Something went wrong!");
      console.log("err", err);
    }
  };

  return (
    <>
      <div className='flex items-center justify-center gap-x-3'>
        {roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]) &&
        roleAccess(
          [],
          routeRole === "hrm"
            ? PERMISSION_KEYS.HRM_FORM
            : PERMISSION_KEYS.CRM_FORM,
          routeRole === "hrm"
            ? PERMISSION_KEYS_CHILDREN.HRM_FORM.UPDATE
            : PERMISSION_KEYS_CHILDREN.CRM_FORM.UPDATE
        ) ? (
          formTemplateStatus.value === "publish" ? (
            <button
              className='bg-white hover:bg-green-500 hover:text-white text-green-600 rounded-md'
              onClick={() => editFormTemplateHandler(template)}
            >
              <div className='icon-pencil-wrapper' title='Edit'>
                <BiPencil className='icon-size' />
              </div>
            </button>
          ) : (
            <>
              <CustomButton
                isDisabled={
                  template.id === selectedPublishTemp || isUserGracePeriodOver
                }
                showLoading={template.id === selectedPublishTemp}
                clickHandler={() => {
                  if (isUserGracePeriodOver) {
                    return;
                  }
                  setShowConfirmationModalPublish(true);
                }}
                label='Publish'
                Icon={<MdPublish />}
              />
              <button
                disabled={template.id === selectedPublishTemp}
                className='bg-white hover:bg-green-500 hover:text-white text-green-600 rounded-md'
                onClick={() => {
                  const encode = window.btoa(encodeURIComponent("draft"));
                  const redirectionUrl =
                    routeRole === "hrm"
                      ? "/hrm/edit-template"
                      : "/crm/edit-template";
                  navigate(`${redirectionUrl}/${template.slug}/${encode}`);
                }}
              >
                <div className='icon-pencil-wrapper' title='Edit'>
                  <BiPencil className='icon-size' />
                </div>
              </button>
            </>
          )
        ) : (
          <></>
        )}

        {roleAccess([USER_ROLE.Super_Admin]) &&
          (formTemplateStatus.value === "publish" ? (
            <button
              onClick={() => {
                const encode = window.btoa(encodeURIComponent("publish"));
                const redirectionUrl =
                  routeRole === "hrm"
                    ? "/hrm/edit-template"
                    : "/crm/edit-template";
                navigate(`${redirectionUrl}/${template.uuid}/${encode}`);
              }}
            >
              <div className='icon-clone-wrapper' title='Clone'>
                <FaClone className='icon-size' />
              </div>
            </button>
          ) : (
            <>
              <CustomButton
                isDisabled={template.id === selectedPublishTemp}
                showLoading={template.id === selectedPublishTemp}
                clickHandler={() => setShowConfirmationModalPublish(true)}
                label='Publish'
                Icon={<MdPublish />}
              />
              <button
                disabled={template.id === selectedPublishTemp}
                className='bg-white hover:bg-green-500 hover:text-white text-green-600 rounded-md'
                onClick={() => {
                  const encode = window.btoa(encodeURIComponent("draft"));
                  const redirectionUrl =
                    routeRole === "hrm"
                      ? "/hrm/edit-template"
                      : "/crm/edit-template";
                  navigate(`${redirectionUrl}/${template.uuid}/${encode}`);
                }}
              >
                <div className='icon-pencil-wrapper' title='Edit'>
                  <BiPencil className='icon-size' />
                </div>
              </button>
            </>
          ))}

        {roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Super_Admin]) && (
          <button onClick={() => viewFormTemplateHandler(template)}>
            <div className='icon-eye-wrapper' title='View'>
              <HiEye className='icon-size' />
            </div>
          </button>
        )}
      </div>
      {showConfirmationModalPublish && (
        <SweetAlertConfirmationModal
          isOpen={showConfirmationModalPublish}
          title='Confirm your action?'
          content='Are you sure to publish this form?'
          setIsConfirm={(e) => {
            if (e) {
              setShowConfirmationModalPublish(false);
              handlePublishDraftTemplate(template.id);
            } else {
              setShowConfirmationModalPublish(false);
            }
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  updateFormTemplateLocally: updateAllFormTemplatesLocally,
};

const mapStateToprops = (state) => {
  return {};
};

export default connect(
  mapStateToprops,
  mapDispatchToProps
)(FormTemplateListActionButtons);
