import { connect } from "react-redux";
import StaffCompliance from "./StaffCompliance";
import {
  spStaffComplianceSettingsRequest,
  spStaffComplianceSettingsUpdateRequest,
} from "store/actions/sp.actions";
import {
  getSpStaffComplianceSetting,
  getSpStaffComplianceSettingFetchLoader,
  getSpStaffComplianceSettingUpdateLoader,
} from "store/selector/sp.selector";

const mapDispatchToProps = {
  spStaffComplianceSettingsRequest,
  spStaffComplianceSettingsUpdateRequest,
};

const mapStateToProps = (state) => {
  const spStaffComplianceSetting = getSpStaffComplianceSetting(state);
  const spStaffComplianceLoader = getSpStaffComplianceSettingFetchLoader(state);
  const spStaffComplianceUpdateLoader =
    getSpStaffComplianceSettingUpdateLoader(state);

  return {
    spStaffComplianceSetting,
    spStaffComplianceLoader,
    spStaffComplianceUpdateLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffCompliance);
