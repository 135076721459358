import React from "react";
import SubscriptionCheckout from "./checkout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const key = process.env.REACT_APP_STRIPE;

const stripePromise = loadStripe(key);

const checkoutWrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionCheckout {...props} />
    </Elements>
  );
};

export default checkoutWrapper;
