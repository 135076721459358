import { formatDateToAustralian } from "Utils/utils";
import React from "react";
import { ImArrowDown2 } from "react-icons/im";

const TrainingTable = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div className='flex justify-center mt-3 text-sm text-gray-500'>
        No completed training yet
      </div>
    );
  }

  const headers = data[0].input_fields[0].sub_fields.map(
    (field) => field.lable
  );

  return (
    <div className=''>
      <div className='flex justify-between mt-5 bg-[#e1e5f0] rounded-full py-3 px-5'>
        {headers?.map((header, index) => {
          return (
            <div className='flex items-center gap-1' key={index}>
              <h3 className='font-bold text-sm'>{header}</h3>{" "}
              {index === 0 && <ImArrowDown2 />}
            </div>
          );
        })}
      </div>
      <div className='py-4 px-6 max-h-60 overflow-y-auto overflow-x-auto custom-scroll'>
        {data[0]?.input_fields?.map((training, index) => (
          <div
            className='flex justify-between item-start p-2'
            key={`training-${index}`}
          >
            {training?.sub_fields?.map((field, index) => (
              <p
                className=' text-sm font-semi-bold text-left whitespace-nowrap overflow-hidden text-gray-600 capitalize'
                style={{
                  maxWidth: "85px",
                  textOverflow: "ellipsis",
                }}
                key={`field-${index}`}
                title={`${field?.value}`}
              >
                {field?.type === "date"
                  ? formatDateToAustralian(field?.value)
                  : field?.value}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrainingTable;
