import { Fragment } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import {
  ArrowNarrowLeftIcon,
  CheckIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  SearchIcon,
  ThumbUpIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import FormsActionButtons from "components/ServiceProvider/SubmittedFormsView/FormsActionButtons";
import { DropDown } from "components/common/DropDown/DropDown";

export default function Profile(props) {
  const { profile } = props;
  return (
    <>
      <div className="min-h-full">
        <main className="py-6">
          {/*<div className="mt-8 max-w-7xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">*/}
          {/*    */}
          {/*</div>*/}
          <section aria-labelledby="applicant-information-title">
            <div className="px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Applicant Suburb
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{profile.city}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Phone Number</dt>  
                  <dd className="mt-1 text-sm text-gray-900">
                    {profile.phone}
                  </dd>
                </div>
                {/* <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Cover Letter</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat.
                                        Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia
                                        proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                                    </dd>
                                </div> */}
                {/*<div className="sm:col-span-2">*/}
                {/*    <dt className="text-sm font-medium text-gray-500">Attachments</dt>*/}
                {/*    <dd className="mt-1 text-sm text-gray-900">*/}
                {/*        <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">*/}
                {/*            {attachments.map((attachment) => (*/}
                {/*                <li*/}
                {/*                    key={attachment.name}*/}
                {/*                    className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"*/}
                {/*                >*/}
                {/*                    <div className="w-0 flex-1 flex items-center">*/}
                {/*                        <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                {/*                        <span className="ml-2 flex-1 w-0 truncate">{attachment.name}</span>*/}
                {/*                    </div>*/}
                {/*                    <div className="ml-4 flex-shrink-0">*/}
                {/*                        <a href={attachment.href} className="font-medium text-blue-600 hover:text-blue-500">*/}
                {/*                            Download*/}
                {/*                        </a>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*            ))}*/}
                {/*        </ul>*/}
                {/*    </dd>*/}
                {/*</div>*/}
              </dl>
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
