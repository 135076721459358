import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiKey2Line } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import LoaderButton from "components/common/LoaderButton/LoaderButton";
import AutoShiftBoxes from "components/common/AutoShiftBoxes/AutoShiftBoxes";
import { apiPostRequest } from "helpers/Requests";
import PasswordValidationBox from "components/common/PasswordValidationBox/PasswordValidationBox";

const SetPassword = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPassword, setconfirmPassword] = useState("password");

  const showPasswordHandler = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const showConfirmPasswordHandler = () => {
    confirmPassword === "password"
      ? setconfirmPassword("text")
      : setconfirmPassword("password");
  };

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [confirmationCode, setConfirmationCode] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const watchFormData = watch();

  const resetPasswordHandler = async (data) => {
    setRequest({ ...request, isRequested: true });
    data.email = localStorage.getItem("email");
    data.code = confirmationCode;
    try {
      let res = await apiPostRequest("reset-password", data);
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        throw new Error("Wrong Code");
      }
      if (res.status === 200) {
        console.log(res.data);
        setRequest({ ...request, isRequested: false, isSuccess: true });
        console.log(res.data.data);
        toast.success("password reset successfully!");
        navigate("/");
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message);
      console.log(err);
    }
  };
  const onSubmit = (data) => {
    resetPasswordHandler(data);
  };
  // Password Validations
  const [pass, setPass] = useState("");
  const [confrimPassword, setConfirmPassword] = useState("");

  // When the user clicks on the password field, show the message box
  const passwordFocus = () => {
    document.getElementById("message").style.display = "block";
  };

  const passwordBluer = () => {
    document.getElementById("message").style.display = "none";
  };
  const CpasswordHanlder = (value) => {
    setConfirmPassword(value);
  };

  const passwordChangeHandler = (value) => {
    setPass(value);
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <div className="flex justify-center">
            <div className="rounded-full bg-layer/40 h-20 w-20 flex items-center justify-center">
              <div className="h-14 w-14 rounded-full bg-blue-200/60 flex items-center justify-center">
                <RiKey2Line className="h-8 w-8 text-blue-dark" />
              </div>
            </div>
          </div>
          <h2 className="mt-6 text-center text-xl sm:text-3xl font-semibold text-gray-900">
            Set new password
          </h2>
          <p className="mt-2 text-center text-md text-gray-500 font-normal pb-6">
            Your new password must be different to previously used passwords.
          </p>

          <div className="flex justify-center">
            <AutoShiftBoxes
              setConfirmationCode={setConfirmationCode}
              length={6}
              label="Code Label"
              onComplete={(code) => {}}
              classes={`border h-10 sm:h-12 w-10 sm:w-12 mx-1 sm:mx-2 text-center border-blue-dark rounded-lg text-blue-dark text-sm sm:text-xl focus-visible:outline-none focus-visible:border-2 ${
                request.isFailed ? "borderRed" : "border"
              }`}
            />
          </div>
          <p className="text-center text-gray-500 mt-3 mb-6">
            Verification Code sent to your Email
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="space-y-4">
            <div className="">
              <div className="did-floating-label-content">
                <label
                  htmlFor="email-address"
                  className="block text-sm font-medium text-gray-700 mb-[3px]"
                >
                  Enter New Password
                </label>
                <div className="relative">
                  <input
                    {...register("password", {
                      required: true,
                      onChange: (e) => {
                        passwordChangeHandler(e.target.value);
                      },
                    })}
                    onFocus={passwordFocus}
                    onBlur={passwordBluer}
                    id="password"
                    type={passwordType}
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                    autoComplete="password"
                    className={`did-floating-input ${
                      request.isFailed ? "borderRed" : "border"
                    }`}
                    // placeholder="Enter new password "
                  />

                  <span
                    onClick={showPasswordHandler}
                    className={`pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark ${
                      request.isFailed ? "textRed" : "null"
                    }`}
                  >
                    {passwordType === "password" ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </span>
                </div>
              </div>
              {errors?.password?.type === "required" && (
                <div className="-mt-4 mb-4 text-sm text-red-500">
                  This field is required!
                </div>
              )}
              <div id="message" style={{ display: "none" }}>
                <PasswordValidationBox password={watchFormData?.password} />
              </div>
            </div>
            <div className="">
              <div className="did-floating-label-content">
                <label
                  htmlFor="email-address"
                  className="block text-sm font-medium text-gray-700 mb-[3px]"
                >
                  Confirm New Password
                </label>
                <div className="relative">
                  <input
                    {...register("password_confirmation", {
                      required: true,
                      onChange: (e) => {
                        CpasswordHanlder(e.target.value);
                      },
                    })}
                    id="confirm_password"
                    type={confirmPassword}
                    autoComplete="password"
                    className={`did-floating-input ${
                      request.isFailed ? "borderRed" : "border"
                    }`}
                    // placeholder="Confirm new password "
                  />

                  <span
                    onClick={showConfirmPasswordHandler}
                    className={`pointer absolute top-1/2 -translate-y-1/2 right-4 text-blue-dark ${
                      request.isFailed ? "textRed" : "null"
                    }`}
                  >
                    {confirmPassword === "password" ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </span>
                </div>
              </div>
              {errors?.password_confirmation?.type === "required" && (
                <div className="-mt-4 mb-4 text-sm text-red-500">
                  This field is required!
                </div>
              )}
              {pass && confrimPassword && pass === confrimPassword ? (
                <div className="-mt-4 mb-4 text-green-500">
                  password matched!
                </div>
              ) : pass && confrimPassword && pass !== confrimPassword ? (
                <div className="-mt-4 mb-4 text-red-500">
                  password not matched!
                </div>
              ) : null}
            </div>
          </div>

          <div className="space-y-4">
            {request.isRequested ? (
              <LoaderButton
                classes="w-full flex justify-center items-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
                svgClasses="inline mr-3 w-5 h-5 text-white animate-spin"
                textbutton="Loading..."
              />
            ) : (
              <button
                disabled={confirmationCode.length < 6}
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-dark hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
              >
                Reset Password
              </button>
            )}

            <Link
              to="/"
              className="w-full flex justify-center items-center space-x-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-500"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 17l-5-5m0 0l5-5m-5 5h12"
                  />
                </svg>
              </span>
              <span>Back to log in</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
