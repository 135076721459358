import { connect } from "react-redux";
import RiskRatingTab from "./RiskRatingTab";
import { getSPRiskRatingListRequest } from "../../../../store/actions/sp.actions";
import { getRiskRatingTabList } from "../../../../store/selector/sp.selector";

const mapDispatchToProps = {
  getRiskRatingTabList: getSPRiskRatingListRequest,
};

const mapStateToProps = (state) => {
  const spRiskRatingTabList = getRiskRatingTabList(state);

  return { spRiskRatingTabList };
};

export default connect(mapStateToProps, mapDispatchToProps)(RiskRatingTab);
