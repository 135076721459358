import { EMAIL_REGEX_EXP } from "Constants/constant";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import { apiPostRequest } from "../../../helpers/Requests";
import SideBar from "../../common/SideBar/SideBarNew";

const InviteTeamMember = (props) => {
  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = React.useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: userInfo,
  });

  const dataSubmitHandler = async (data) => {
    setRequest({ ...request, isRequested: true });

    try {
      let res = await apiPostRequest("sp/invite-member", data);
      if (res.status !== 200) {
        toast.error(res.data.message);
        setRequest({ ...request, isRequested: false, isFailed: true });
      }
      if (res.status === 200) {
        navigate("/dashboard");
        setRequest({ ...request, isRequested: false, isSuccess: true });
      }
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: true });
      toast.error(err.message);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    dataSubmitHandler(data);
    // navigate("/sp-setup");
  };

  return (
    <>
      <div className=" min-h-screen">
        {request.isRequested && (
          <div className="absolute top-1/2 -translate-y-1/2 z-50 bg-blue-dark bg-opacity-20  flex  w-full flex-1  flex-col justify-center py-12 px-4 sm:px-6 lg:flex lg:justify-center lg:px-20 xl:px-24 h-full">
            <CircularLoader
              classes="flex justify-center items-center"
              classloader="loader-l"
            />
          </div>
        )}
        <SideBar />
        <div className=" bg-gray-100 lg:pl-64 flex min-h-screen">
          {/* <HeaderAdmin /> */}
          <div className="my-8 bg-white h-full max-w-2xl lg:max-w-3xl xl:max-w-4xl w-full mx-auto px-4 sm:px-6 lg:px-8 py-10 rounded-lg shadow">
            <form onSubmit={handleSubmit(onSubmit)} className="">
              <p className="text-3xl font-medium text-gray-700 mb-6">
                Please add details of the Member.
              </p>
              <div className="mt-3">
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  {...register("name", {
                    required: true,
                    maxLength: 254,
                  })}
                  className="did-floating-input"
                  type="text"
                />
                {errors?.name?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>
              <div className="mt-3">
                <label className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <input
                  {...register("email", {
                    required: true,
                    pattern: EMAIL_REGEX_EXP,
                  })}
                  className="did-floating-input"
                  type="email"
                />
                {errors?.email?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
                {errors?.email?.type === "pattern" && (
                  <span className="text-sm text-red-500">
                    Please enter a valid email address!
                  </span>
                )}
              </div>
              <div className="mt-3">
                <label className="block text-sm font-medium text-gray-700">
                  Role
                </label>
                <select
                  className="did-floating-select"
                  {...register("user_type", {
                    required: true,
                  })}
                >
                  <option value=""></option>
                  <option value="sp_manager">Manager</option>
                </select>
                {errors?.state?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
              </div>
              <div className="mt-3">
                <label className="block text-sm font-medium text-gray-700">
                  Phone No.
                </label>
                <input
                  {...register("phone", {
                    required: true,
                    maxLength: 11,
                    minLength: 11,
                    // pattern:
                    //   /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
                  })}
                  className="did-floating-input"
                  type="text"
                />
                {errors?.phone?.type === "required" && (
                  <span className="text-sm text-red-500">
                    This field is required!
                  </span>
                )}
                {errors?.phone?.type === "maxLength" && (
                  <span className="text-sm text-red-500">
                    Please enter a valid phone number!
                  </span>
                )}
                {errors?.phone?.type === "minLength" && (
                  <span className="text-sm text-red-500">
                    Please enter a valid phone number!
                  </span>
                )}
              </div>
              <div>
                <button
                  disabled={request.isRequested}
                  type="submit"
                  className="
                  mt-8
                      w-full
                      flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      bg-blue-dark
                      hover:bg-blue-dark
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-dark
                    "
                >
                  Invite a Member
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteTeamMember;
