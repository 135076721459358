import React, { useEffect, useState } from "react";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import moment from "moment"; // Make sure you have the moment library installed

import { apiFormDataRequest } from "helpers/Requests";
import { connect } from "react-redux";
import { getHRMTeamManagmentRequest } from "store/actions/sp.actions";

import { toast } from "react-toastify";
import { USER_ROLE } from "Constants/constant";
import { roleAccess } from "helpers/RolesPermission";
import { getUser } from "store/selector/auth.selector";

const WorkerAvailabilityModal = (props) => {
  const {
    handleClose,
    openModel,
    WorkerId,
    setRefreshPageForWorkerTimeSlot,
    isWorkerAvailablity,
    TimeSlots,
    currentLoginUser,
    isUserGracePeriodOver,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [toastId, setToastId] = useState(null);

  const [availability, setAvailability] = useState(
    TimeSlots
      ? {
          monday:
            TimeSlots && TimeSlots?.monday
              ? TimeSlots?.monday?.map((slot, index) => ({
                  ...slot,
                  editable: index === TimeSlots?.monday?.length - 1,
                }))
              : [],
          tuesday:
            TimeSlots && TimeSlots?.tuesday
              ? TimeSlots?.tuesday?.map((slot, index) => ({
                  ...slot,
                  editable: index === TimeSlots?.tuesday?.length - 1,
                }))
              : [],
          wednesday:
            TimeSlots && TimeSlots?.wednesday
              ? TimeSlots.wednesday?.map((slot, index) => ({
                  ...slot,
                  editable: index === TimeSlots?.wednesday?.length - 1,
                }))
              : [],
          thursday:
            TimeSlots && TimeSlots?.thursday
              ? TimeSlots?.thursday?.map((slot, index) => ({
                  ...slot,
                  editable: index === TimeSlots?.thursday?.length - 1,
                }))
              : [],
          friday:
            TimeSlots && TimeSlots?.friday
              ? TimeSlots?.friday?.map((slot, index) => ({
                  ...slot,
                  editable: index === TimeSlots?.friday?.length - 1,
                }))
              : [],
          saturday:
            TimeSlots && TimeSlots?.saturday
              ? TimeSlots?.saturday?.map((slot, index) => ({
                  ...slot,
                  editable: index === TimeSlots?.saturday?.length - 1,
                }))
              : [],
          sunday:
            TimeSlots && TimeSlots?.sunday
              ? TimeSlots?.sunday?.map((slot, index) => ({
                  ...slot,
                  editable: index === TimeSlots?.sunday?.length - 1,
                }))
              : [],
        }
      : {
          monday: [{ from: "", to: "", validation: "", editable: true }],
          tuesday: [{ from: "", to: "", validation: "", editable: true }],
          wednesday: [{ from: "", to: "", validation: "", editable: true }],
          thursday: [{ from: "", to: "", validation: "", editable: true }],
          friday: [{ from: "", to: "", validation: "", editable: true }],
          saturday: [{ from: "", to: "", validation: "", editable: true }],
          sunday: [{ from: "", to: "", validation: "", editable: true }],
        }
  );

  // const [availability, setAvailability] = useState(
  //   TimeSlots
  //     ? {
  //         monday:
  //           TimeSlots && TimeSlots.monday
  //             ? TimeSlots.monday.map((slot) => ({ ...slot, editable: true }))
  //             : [],
  //         tuesday:
  //           TimeSlots && TimeSlots.tuesday
  //             ? TimeSlots.tuesday.map((slot) => ({ ...slot, editable: true }))
  //             : [],
  //         wednesday:
  //           TimeSlots && TimeSlots.wednesday
  //             ? TimeSlots.wednesday.map((slot) => ({ ...slot, editable: true }))
  //             : [],
  //         thursday:
  //           TimeSlots && TimeSlots.thursday
  //             ? TimeSlots.thursday.map((slot) => ({ ...slot, editable: true }))
  //             : [],
  //         friday:
  //           TimeSlots && TimeSlots.friday
  //             ? TimeSlots.friday.map((slot) => ({ ...slot, editable: true }))
  //             : [],
  //         saturday:
  //           TimeSlots && TimeSlots.saturday
  //             ? TimeSlots.saturday.map((slot) => ({ ...slot, editable: true }))
  //             : [],
  //         sunday:
  //           TimeSlots && TimeSlots.sunday
  //             ? TimeSlots.sunday.map((slot) => ({ ...slot, editable: true }))
  //             : [],
  //       }
  //     : {
  //         monday: [{ from: "", to: "", validation: "", editable: true }],
  //         tuesday: [{ from: "", to: "", validation: "", editable: true }],
  //         wednesday: [{ from: "", to: "", validation: "", editable: true }],
  //         thursday: [{ from: "", to: "", validation: "", editable: true }],
  //         friday: [{ from: "", to: "", validation: "", editable: true }],
  //         saturday: [{ from: "", to: "", validation: "", editable: true }],
  //         sunday: [{ from: "", to: "", validation: "", editable: true }],
  //       }
  // );

  const [validationMessage, setValidationMessage] = useState("");

  const handleAvailabilityChange = (day, index, field, value) => {
    const updatedAvailability = [...availability[day]];
    updatedAvailability[index] = {
      ...updatedAvailability[index],
      [field]: value,
    };

    setAvailability({
      ...availability,
      [day]: updatedAvailability,
    });

    if (field === "to" && !value) {
      updatedAvailability[
        index
      ].validation = `Please select a "from" time first.`;
    } else {
      updatedAvailability[index].validation = "";

      // Check for conflicts when modifying an existing slot
      if (field === "to" && index > 0) {
        const currentSlot = updatedAvailability[index];
        const previousSlot = updatedAvailability[index - 1];

        if (
          moment(currentSlot.from, "HH:mm") <= moment(previousSlot.to, "HH:mm")
        ) {
          updatedAvailability[index].validation =
            "Time slot overlaps with the previous slot.";
          // You can also reset the 'to' value to prevent the overlap
          updatedAvailability[index] = {
            ...updatedAvailability[index],
            to: "",
          };
        }
      }

      // Check if 'to' time is the same as 'from' time
      if (field === "to" && value === updatedAvailability[index].from) {
        updatedAvailability[index].validation =
          "The 'to' time cannot be the same as the 'from' time.";
        // Reset the 'to' value to prevent an invalid selection
        updatedAvailability[index] = {
          ...updatedAvailability[index],
          to: "",
        };
      }

      // Check if 'to' time is less than 'from' time
      if (
        field === "to" &&
        moment(updatedAvailability[index].from, "HH:mm") >
          moment(value, "HH:mm")
      ) {
        updatedAvailability[index].validation =
          "The 'to' time cannot be less than the 'from' time.";
        // Reset the 'to' value to prevent an invalid selection
        updatedAvailability[index] = {
          ...updatedAvailability[index],
          to: "",
        };
      }
    }
    setAvailability({
      ...availability,
      [day]: updatedAvailability,
    });
  };

  const handleAddTimeSlot = (day) => {
    // Check if the "from" and "to" times for the last slot are selected
    const lastSlot = availability[day][availability[day]?.length - 1];
    if (lastSlot.from && lastSlot.to) {
      if (availability[day]?.length >= 3) {
        if (!toast.isActive(toastId)) {
          const newToastId = toast.error(
            `You can only add a maximum of 3 slots for ${day}`
          );
          setToastId(newToastId);
        }

        return;
      }
      // Check for conflicts before adding a new slot
      if (moment(lastSlot.to, "HH:mm") >= moment(lastSlot.from, "HH:mm")) {
        // Set editable to false for all existing slots
        const updatedAvailability = availability[day]?.map((slot) => ({
          ...slot,
          editable: false,
        }));
        // Set editable to true for the newly added slot
        updatedAvailability?.push({
          from: "",
          to: "",
          validation: "",
          editable: true,
        });
        setAvailability({
          ...availability,
          [day]: updatedAvailability,
        });
      } else {
        lastSlot.validation =
          "Time slot must be chosen above from the previous slot";
        setValidationMessage(
          "Time slot must be chosen above from the previous slot"
        );
      }
    } else {
      lastSlot.validation = `Please select both "from" and "to" times for the previous slot.`;
      setValidationMessage(
        `Please select both "from" and "to" times for the previous slot.`
      );
    }
  };

  const handleRemoveTimeSlot = (day, index) => {
    if (index >= 0) {
      const updatedAvailability = [...availability[day]];
      // Set editable to true for the last slot before the removed one (if exists)
      if (index > 0) {
        updatedAvailability[index - 1].editable = true;
      }
      updatedAvailability?.splice(index, 1);

      setAvailability({
        ...availability,
        [day]: updatedAvailability,
      });
    }
  };

  const isAddButtonDisabled = (day) => {
    // Disable the "Add" button if the first slot has empty "from" or "to" time
    return (
      !availability[day][0].from ||
      !availability[day][0].to ||
      !availability[day]?.length === 3
    );
  };

  const areTimeInputsDisabled = (day, index) => {
    return !availability[day][index].from;
  };

  const handleSubmitButton = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    const formData = new FormData();
    const url = roleAccess([USER_ROLE.Sp_Standard_User])
      ? "/hrm/worker/update-availability-slot"
      : "/hrm/update-availability-slot";

    for (const day in availability) {
      availability[day]?.forEach((item, index) => {
        delete item.validation;
        delete item.editable;

        for (const key in item) {
          formData.append(
            `${day}[${index}][${key}]`,
            item[key] ? item[key] : ""
          );
        }
      });
    }
    formData.append(
      "profile_id",
      roleAccess([USER_ROLE.Sp_Standard_User])
        ? currentLoginUser?.employee_uuid
        : WorkerId
    );

    try {
      setIsLoading(true);
      const res = await apiFormDataRequest(url, formData);
      if (res && res.status === 200) {
        toast.success("Worker's time slots added successfully");
        setIsLoading(false);
        setRefreshPageForWorkerTimeSlot(!isWorkerAvailablity);
        handleClose(false);

        return;
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);

      toast.error(err ? err.message : "Something Went Wrong");

      const restoredAvailability = JSON.parse(JSON.stringify(availability));
      for (const day in restoredAvailability) {
        restoredAvailability[day] = restoredAvailability[day]?.map(
          (slot, index, arr) => ({
            ...slot,
            editable: index === arr?.length - 1,
          })
        );
      }
      setAvailability(restoredAvailability);
    }
  };

  const isSubmitButtonDisabled = () => {
    // Check if any slot has an empty "from" or "to" time
    for (const day in availability) {
      for (const slot of availability[day]) {
        if ((!slot?.from && slot?.to) || (slot?.from && !slot?.to)) {
          return true;
        }
      }
    }
    return isLoading || isUserGracePeriodOver;
  };

  const modalFooter = () => (
    <>
      <CustomButton
        showLoading={isLoading}
        isDisabled={
          isLoading || isUserGracePeriodOver || isSubmitButtonDisabled()
        }
        label='Submit'
        clickHandler={handleSubmitButton}
        // className='bg-gradient-to-r from-blue-400 to-blue-600 hover:from-blue-600 hover:to-blue-800 text-white rounded-lg px-4 py-2'
      />
    </>
  );

  return (
    <ModalSkeleton
      isOpen={openModel}
      title="Modify Worker's Availability"
      cssClass='w-[50%]'
      closeModal={() => handleClose(false)}
      modalFooter={modalFooter()}
    >
      <div className='w-full'>
        <p className='px-2 bg-white text-sm text-gray-500'>
          Please, provide worker's availability in the below table
        </p>
        <div className='mt-5'>
          <table className='w-full border border-gray-300'>
            <thead>
              <tr className='bg-gray-400 text-white'>
                <th className='py-2 px-4 border-r border-gray-300'>Day</th>
                <th className='py-2 px-4'>Available Time</th>
              </tr>
            </thead>

            <tbody>
              {Object.keys(availability)?.map((day, index) => (
                <tr key={`day-${index}`} className='border-b border-gray-300'>
                  <td className='py-2 px-4 border-r border-gray-300 capitalize'>
                    {day}
                  </td>
                  <td className='py-2 px-4'>
                    <div>
                      {availability[day]?.map((slot, index) => (
                        <>
                          <div
                            key={`slot-${index}`}
                            className='flex items-center space-x-2 space-y-2'
                          >
                            <input
                              type='time'
                              value={slot?.from}
                              onChange={(e) =>
                                handleAvailabilityChange(
                                  day,
                                  index,
                                  "from",
                                  e.target.value
                                )
                              }
                              className={`border rounded-lg h-8 px-3 py-2 h-8 focus:outline-none focus:ring-2 focus:ring-blue-400`}
                              disabled={!slot?.editable}
                            />
                            <span className='font-semibold text-gray-600'>
                              To
                            </span>
                            <input
                              type='time'
                              value={slot?.to}
                              onChange={(e) =>
                                handleAvailabilityChange(
                                  day,
                                  index,
                                  "to",
                                  e.target.value
                                )
                              }
                              className={`border rounded-lg  h-8 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400`}
                              disabled={!slot?.editable}
                            />
                            <button
                              onClick={() => handleAddTimeSlot(day)}
                              className={`bg-[#334D81] text-white w-6 flex justify-center items-center h-6 rounded-full hover:bg-blue-600 mt-2 ${
                                isAddButtonDisabled(day)
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              }`}
                              disabled={
                                !slot.editable || isAddButtonDisabled(day)
                              }
                            >
                              +
                            </button>
                            {index === availability[day]?.length - 1 &&
                              index !== 0 && (
                                <button
                                  onClick={() =>
                                    handleRemoveTimeSlot(day, index)
                                  }
                                  className='bg-red-400  text-bold text-white w-6 flex justify-center items-center h-6 rounded-full hover:bg-red-500'
                                >
                                  -
                                </button>
                              )}
                          </div>
                          <div>
                            {slot?.validation && (
                              <p className='text-red-500 text-xs mt-1'>
                                {slot?.validation}
                              </p>
                            )}
                          </div>
                        </>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ModalSkeleton>
  );
};

const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);
  return { currentLoginUser };
};
const mapDispatchToProps = {
  getTeamManagmentListCall: getHRMTeamManagmentRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerAvailabilityModal);
