import React from "react";
import Loader from "../../../../../components/common/Loader/Loader";
import {
  formatDateIntoDMYToDisplay,
  gethoursAndMins,
} from "../../../../../Utils/utils";

const SupportServiceStats = (props) => {
  const {
    singleSupportTrackLoading,
    singleSupportTrack,
    selectedFunding,
    fundingsForCos,
  } = props;

  return (
    <>
      {singleSupportTrackLoading ? (
        <div className='pl-0 p-6'>
          <Loader isSmallView cssClass='text-black' />
        </div>
      ) : (
        singleSupportTrack && (
          <div>
            <div className='py-8'>
              <div className='bg-green-50 text-center font-semibold text-lg text-blue-700 py-2'>
                {singleSupportTrackLoading
                  ? "Loading"
                  : singleSupportTrack?.price_catalog?.support_item_name}
              </div>
            </div>

            <div className='flex items-center gap-3'>
              <div
                className='truncate rounded-md shadow-md hover:shadow-lg flex flex-wrap items-center justify-center flex-col p-3 w-full h-28 relative
                before:absolute before:w-10 before:h-10 before:bg-[rgba(59,166,222,255)] before:rounded-tl-[10px] before:rounded-br-[70px]
                before:top-0 before:left-0'
              >
                <h4 className='font-semibold'>Support Funding</h4>
                <p className='text-gray-500'>
                  {singleSupportTrackLoading
                    ? "Loading"
                    : "$" +
                      parseFloat(
                        singleSupportTrack?.total_budget_amount
                      ).toFixed(2)}
                </p>
              </div>

              <div
                className='rounded-md shadow-md hover:shadow-lg flex flex-wrap items-center justify-center flex-col p-3 w-full h-28 relative
                before:absolute before:w-10 before:h-10 before:bg-[rgba(154,210,243,255)] before:rounded-tl-[10px] before:rounded-br-[70px]
                before:top-0 before:left-0'
              >
                <h4 className='font-semibold'>Support Delivered</h4>
                <p className='text-gray-500'>
                  {singleSupportTrackLoading
                    ? "Loading"
                    : !isNaN(
                        parseFloat(
                          singleSupportTrack?.delivered_amount
                        ).toFixed(2)
                      ) &&
                      "$" +
                        parseFloat(
                          singleSupportTrack?.delivered_amount
                        ).toFixed(2)}
                </p>
              </div>

              <div
                className='truncate rounded-md shadow-md hover:shadow-lg flex flex-wrap items-center justify-center flex-col p-3 w-full h-28 relative
                before:absolute before:w-10 before:h-10 before:bg-[rgba(193,228,247,255)] before:rounded-tl-[10px] before:rounded-br-[70px]
                before:top-0 before:left-0'
              >
                <h4 className='font-semibold'>Support Remaining</h4>
                <p className='text-gray-500'>
                  {singleSupportTrackLoading
                    ? "Loading"
                    : !isNaN(
                        parseFloat(singleSupportTrack?.remain_amount).toFixed(2)
                      ) &&
                      "$" +
                        parseFloat(singleSupportTrack?.remain_amount).toFixed(
                          2
                        )}
                </p>
              </div>

              <div
                className='truncate rounded-md shadow-md hover:shadow-lg flex flex-wrap items-center justify-center flex-col p-3 w-full h-28 relative
                before:absolute before:w-10 before:h-10 before:bg-[rgba(132,187,228,255)] before:rounded-tl-[10px] before:rounded-br-[70px]
                before:top-0 before:left-0'
              >
                <h4 className='font-semibold'>Support Time Left</h4>
                <p className='text-gray-500'>
                  {singleSupportTrackLoading
                    ? "Loading"
                    : gethoursAndMins(
                        singleSupportTrack?.remain_total_hours,
                        singleSupportTrack?.remain_total_mint
                      )}
                </p>
              </div>

              <div
                className='truncate rounded-md shadow-md hover:shadow-lg flex flex-wrap items-center justify-center flex-col p-3 w-full h-28 relative
                before:absolute before:w-10 before:h-10 before:bg-[rgba(12,124,187,255)] before:rounded-tl-[10px] before:rounded-br-[70px]
                before:top-0 before:left-0'
              >
                <h4 className='font-semibold'>Funding End Date</h4>
                <p className='text-gray-500'>
                  {singleSupportTrackLoading && selectedFunding
                    ? "Loading"
                    : formatDateIntoDMYToDisplay(
                        singleSupportTrack?.funding?.end_date ??
                          selectedFunding.end_date
                      )}
                </p>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default SupportServiceStats;
