import { connect } from "react-redux";
import { getHRMTeamManagmentRequest, getSPParticipantsListRequest } from "store/actions/sp.actions";
import { getParticipantsList, getTeamManagmentList } from "store/selector/sp.selector";

import WorkerExpiringDocuments from "./WorkerExpiringDocumentsReports";

const mapDispatchToProps = {
  getTeamManagmentListCall: getHRMTeamManagmentRequest,
  getParticipantList: getSPParticipantsListRequest,
};

const mapStateToProps = (state) => {
  const teamManagementList = getTeamManagmentList(state);
  const spParticipants = getParticipantsList(state);
  return {
    teamManagementList,
  spParticipants

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerExpiringDocuments);
