import React, { useEffect, useState } from "react";
import CustomButton from "../../../../components/common/Button/CustomButton";
import ModalSkeleton from "../../../../components/Modals/ModalSkeleton";
import { toast } from "react-toastify";
import moment from "moment";
import { checkEmail, checkPhoneNo } from "../../../../Utils/FormValidation";
import InputField from "../../../../components/common/Input/Input";
import { apiFormDataRequest } from "../../../../helpers/Requests";
import { hasBlankSpaces } from "../../../../Utils/utils";
import FileUploadInput from "../../../../components/common/FileUpload/FileUploadInput";
import PasswordFields from "./Password";
import { connect } from "react-redux";
import { getHRMTeamManagmentRequest } from "../../../../store/actions/sp.actions";
import { AiOutlineQuestion } from "react-icons/ai";
import ImageDisplayModal from "./ImageDisplayModalForXeroWorker";
import SelectOption from "components/common/Select/Select";
import api from "store/services/auth.services";
import { REGEX_PHONE_NUMBER, portalTypeOptions } from "Constants/constant";
import CircularLoader from "components/common/CircularLoader/CircularLoader";

const AddNewWorkerModal = (props) => {
  const {
    handleClose,
    openModel,
    setAddWorker,
    addWorkerRefresh,
    isUserGracePeriodOver,
  } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [applicantCity, setApplicantCity] = useState("");
  const [resume, setResume] = useState("");
  const [joinDate, setJoiningDate] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [file, setFile] = useState(null);
  const [isFileUploadToS3, setIsFileUploadToS3] = useState(false);
  const [fileExt, setFileExt] = useState("");
  const [address, setAddress] = React.useState("");
  const [xeroId, setXeroId] = useState("");
  const [showXeroImage, setXeroImage] = useState(false);

  const [selectedRole, setSelectedRole] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);
  const [fetchRoleLoader, setFetchRoleLoader] = useState(false);

  useEffect(() => {
    if (selectedRole && getError("role")) {
      setErrors({ ...errors, role: null });
    }
  }, [selectedRole]);

  useEffect(() => getRoles(), []);

  const getRoles = async () => {
    try {
      setFetchRoleLoader(true);
      const response = await api.roleAndPermissionList({
        type: portalTypeOptions[1].value,
      });
      if (response && response.data) {
        setRoleOptions(
          response?.data?.map((x) => ({ label: x.name, value: x.id }))
        );
      }
      setFetchRoleLoader(false);
    } catch (err) {
      setFetchRoleLoader(false);
      console.log("err", err);
    }
  };

  const getError = (value) => {
    if (!value) return "";
    return errors[value] && errors[value].length > 0 && value !== "contact"
      ? `Field is Required`
      : errors[value] && errors[value].length > 0 && value === "contact"
      // ? "Phone No required & must start with 04, 1300 or 1800 & 10 digits"
      ? "Please enter a valid phone number!"
      : "";
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({ ...errors, [name]: `${name} is Required` });
    } else if (name === "email" && value && value.length > 0) {
      const inValid = checkEmail(value);
      if (inValid) {
        setErrors({ ...errors, email: "Invalid email" });
      } else {
        setErrors({ ...errors, email: null });
      }
      return;
    } else if (name === "contact" && value.length > 0) {
      const inValidPhoneNo = checkPhoneNo(value);
      if (inValidPhoneNo) {
        setErrors({ ...errors, contact: "phone no must start with 0" });
      } else {
        setErrors({ ...errors, contact: null });
      }
    } else if (errors[name] && !hasBlankSpaces(value) && value.length > 0) {
      setErrors({ ...errors, [name]: null });
    }

    if (name === "contact" && value.length > 0) {
      const inValidPhoneNo = checkPhoneNo(value);
      if (inValidPhoneNo) {
        setErrors({ ...errors, contact: "phone no must start with 0" });
      } else {
        setErrors({ ...errors, contact: null });
      }
    }
  };

  const handleClear = () => {
    setFirstName("");
    setLastName("");
    setJoiningDate("");
    setApplicantCity("");
    setEmail("");
    setContact("");
    setJobTitle("");
    setResume("");
    setErrors({});
    // setAddress("");
  };

  const handleSubmitButton = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    const fnError = errors;
    if (!firstName || firstName.length === 0) {
      Object.assign(fnError, { firstName: "First Name is Required" });
    }
    if (!lastName || lastName.length === 0) {
      Object.assign(fnError, { lastName: "Last Name is Required" });
    }
    if (!joinDate || joinDate.length === 0) {
      Object.assign(fnError, { joinDate: "Applied On is Required" });
    }
    if (!jobTitle || !jobTitle.length === 0) {
      Object.assign(fnError, { jobTitle: "Job Title is Required" });
    }
    if (!contact || !contact.length === 0) {
      Object.assign(fnError, { contact: "contact is Required" });
    }
    if (!email || email.length <= 0 || checkEmail(email)) {
      const inValid = checkEmail(email);
      if (inValid) {
        Object.assign(fnError, { email: "Invalid email" });
      } else {
        Object.assign(fnError, { email: "Email is Required" });
      }
    }
    if (!contact || contact.length <= 0 || checkPhoneNo(contact)) {
      const inValidPhoneNo = checkPhoneNo(contact);

      Object.assign(fnError, { contact: "Contact is Required" });
    }
    if (!applicantCity || applicantCity.length <= 0) {
      Object.assign(fnError, { applicantCity: "Applicant Suburb is Required" });
    }
    if (!password || password.length === 0) {
      Object.assign(fnError, { password: "Password is Required" });
    }
    if (!confirmPassword || confirmPassword.length === 0) {
      Object.assign(fnError, {
        confirmPassword: "Confirm Password is Required",
      });
    }
    if (selectedRole === null) {
      Object.assign(fnError, { role: "Role is Required" });
    }
    const errorKeysToExclude = ["Confirm Password"];
    if (
      Object.entries(fnError)
        .filter(([key, value]) => !errorKeysToExclude.includes(key))
        .some(([key, value]) => value !== null) ||
      checkEmail(email)
    ) {
      if (checkEmail(email)) {
        Object.assign(fnError, { email: checkEmail(email) });
      }
      setErrors({ ...fnError });

      const firstErrorKey = Object.keys(fnError).find(
        (key) => fnError[key] !== null
      );
      const doc = document.getElementById("AddworkerModal");
      if (doc && firstErrorKey) {
        const inputWithName = doc.querySelector(
          `input[name="${firstErrorKey}"]`
        );
        if (inputWithName) {
          inputWithName.focus();
        }
      }

      return;
    }
    let formData = new FormData();

    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("job_title", jobTitle);
    formData.append("phone", contact);
    formData.append("city", applicantCity);
    formData.append("email", email);
    formData.append("joining_date", joinDate);
    // formData.append("address", address);
    formData.append("password", password);
    formData.append("password_confirmation", confirmPassword);
    if (file) {
      formData.append("file_key", fileExt || " ");
      formData.append("file_ext", file?.name?.split(".").pop());
    }
    // if (xeroId) {
    //   formData.append("xero_worker_id", xeroId ? xeroId : "");
    // }

    if (selectedRole) {
      formData.append("role_id", selectedRole.value);
    }

    // formData.append("photo", resume);
    try {
      setIsLoading(true);
      const res = await apiFormDataRequest("/hrm/add-worker", formData);
      if (res && res.status === 200) {
        handleClear();
        toast.success("Worker Added Successfully");
        setIsLoading(false);
        setAddWorker(!addWorkerRefresh);
        handleClose(false);
        return;
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setError(err ? err.message : "Something Went Wrong");
      toast.error(err ? err.message : "Something Went Wrong");
    }
  };

  const handleSubmitForFile = async () => {
    setIsFileUploadToS3(true);
  };

  const modalFooter = () => (
    <>
      <CustomButton
        showLoading={isLoading}
        isDisabled={isLoading || isUserGracePeriodOver}
        label='Submit'
        clickHandler={handleSubmitButton}
      />
    </>
  );

  return (
    <>
      <ModalSkeleton
        isOpen={openModel}
        title='Add Worker'
        cssClass={"w-[50%]"}
        modalFooter={modalFooter()}
        closeModal={() => handleClose(false)}
      >
        <div className='w-full'>
          <p className='px-2 bg-white text-sm text-gray-500'>
            Please enter following details to create new worker
          </p>
          <div className='sm:rounded-md sm:overflow-hidden' id='AddworkerModal'>
            <div className='px-2 py-5 bg-white'>
              <div className='grid grid-cols-6 gap-6'>
                <div className='col-span-6 sm:col-span-3'>
                  <InputField
                    label='First Name'
                    type='text'
                    // placeHolder="Enter First Name"
                    handleChange={setFirstName}
                    value={firstName}
                    ErrorMessage={getError("firstName")}
                    blurHandler={handleBlur}
                    name='firstName'
                    redstar
                  />
                </div>
                <div className='col-span-6 sm:col-span-3'>
                  <InputField
                    label='Last Name'
                    type='text'
                    // placeHolder="Enter Last Name"
                    handleChange={setLastName}
                    value={lastName}
                    ErrorMessage={getError("lastName")}
                    blurHandler={handleBlur}
                    name='lastName'
                    redstar
                  />
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <InputField
                    // min={moment().format("DD-MM-YYYY")}
                    label='Joining Date'
                    type='date'
                    handleChange={(e) =>
                      moment(e).isAfter() ? "" : setJoiningDate(e)
                    }
                    value={joinDate}
                    ErrorMessage={getError("joinDate")}
                    blurHandler={handleBlur}
                    name='joinDate'
                    redstar
                  />
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <InputField
                    label='Job Title'
                    type='text'
                    value={jobTitle}
                    // placeHolder="Enter Job Title"
                    handleChange={setJobTitle}
                    ErrorMessage={getError("jobTitle")}
                    name='jobTitle'
                    blurHandler={handleBlur}
                    redstar
                  />
                </div>

                <div className='col-span-6 sm:col-span-3'>
                  <InputField
                    label='Email Address'
                    type='email'
                    value={email}
                    // placeHolder="Enter Email Address"
                    handleChange={setEmail}
                    ErrorMessage={getError("email")}
                    name='email'
                    blurHandler={handleBlur}
                    redstar
                  />
                </div>
                <div className='col-span-6 sm:col-span-3'>
                  <InputField
                    maxLength='10'
                    label='Phone Number'
                    value={contact}
                    // placeHolder="Enter Contact"
                    pattern={REGEX_PHONE_NUMBER}
                    type='number'
                    handleChange={(e) => {
                      const sanitizedValue = e.replace(/\D/g, "");
                      const formattedValue = sanitizedValue.slice(0, 10);
                      setContact(formattedValue);
                    }}
                    ErrorMessage={getError("contact")}
                    blurHandler={handleBlur}
                    name='contact'
                    redstar
                  />
                </div>

                <div className='col-span-12 sm:col-span-6'>
                  <PasswordFields
                    password={password}
                    setErrors={setErrors}
                    errors={errors}
                    setPassword={setPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                  />
                </div>
                <div className='col-span-6 sm:col-span-3'>
                  <InputField
                    label='Applicant Suburb'
                    type='text'
                    value={applicantCity}
                    // placeHolder="Enter Applicant Suburb"
                    handleChange={setApplicantCity}
                    ErrorMessage={getError("applicantCity")}
                    blurHandler={handleBlur}
                    name='applicantCity'
                    redstar
                  />
                </div>
                {/* <div className='col-span-6 sm:col-span-3'>
                  <InputField
                    label='Address'
                    type='text'
                    // placeHolder="Enter Last Name"
                    handleChange={setAddress}
                    value={address}
                    // ErrorMessage={getError("address")}
                    // blurHandler={handleBlur}
                    name='address'
                  />
                </div> */}
                {/* <div className='col-span-6 sm:col-span-3'>
                  <InputField
                    label='Xero Employee ID'
                    type='text'
                    value={xeroId}
                    // placeHolder="Enter Contact"
                    handleChange={setXeroId}
                    name='xeroId'
                  />
                </div>
                <div className='col-span-6 sm:col-span-3 flex items-end mb-2'>
                  <span
                    onClick={() => setXeroImage(true)}
                    title='xero employee id'
                    className='h-6 w-6 flex  cursor-pointer  justify-center items-center rounded-full bg-[#3E5A99] text-white'
                  >
                    <AiOutlineQuestion />
                  </span>
                </div> */}
                <div className='col-span-6 sm:col-span-3 mb-2'>
                  <label
                    htmlFor={"Role"}
                    className='block text-sm font-medium text-gray-700'
                  >
                    Role <span className='text-red-500'>*</span>
                  </label>
                  <SelectOption
                    isDisabled={fetchRoleLoader}
                    loading={fetchRoleLoader}
                    isSearch={false}
                    data={roleOptions}
                    selectedOption={selectedRole}
                    handleChange={setSelectedRole}
                    errorMessage={getError("role")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalSkeleton>
      {showXeroImage && (
        <ImageDisplayModal isOpen={showXeroImage} setOpen={setXeroImage} />
      )}
    </>
  );
};

const mapDispatchToProps = {
  getTeamManagmentListCall: getHRMTeamManagmentRequest,
};

export default connect(null, mapDispatchToProps)(AddNewWorkerModal);
// export default AddNewWorkerModal;
