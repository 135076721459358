import React, { useEffect, useRef, useState } from "react";
import Header from "components/common/Header/Header";
import { LoginContext } from "helpers/LoginContext";
import { CiSettings } from "react-icons/ci";
import SideBar from "components/common/SideBar/SideBarNew";
import CosStatsCard from "pages/HRM/Components/CosStatsCard/CosStatsCard";
import LeaderBoard from "assets/Leaderboard.png";
import casenoteshistory from "assets/casenoteshistory.svg";
import catalogueservice from "assets/catalogueservice.svg";
import ndiscalims from "assets/ndisclaims.svg";
import participant from "assets/participantlist.svg";
import reporting from "assets/reporting.svg";
import serviceprovider from "assets/serviceproviders.svg";
import Avatar from "assets/Avatar.png";
import ArrowDownIcon from "assets/arrowdownicon.svg";
import DropDownIcon from "assets/dropdownicon.svg";
import Chart from "chart.js/auto";
import NewProgressBar from "pages/HRM/Pages/Dashboard/progressBar/NewProgressBar";
import NewCoSStatsCard from "./NewCharts/NewCoSStatsCard";
import { USER_ROLE } from "Constants/constant";
import CustomButton from "components/common/Button/CustomButton";
import { useNavigate } from "react-router";
import { FaFileInvoice } from "react-icons/fa";
import SelectOption from "components/common/Select/Select";
import { capitalizeFirstLetter, gethoursAndMins } from "Utils/utils";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import _, { isArray } from "lodash";
import Loader from "components/common/Loader/Loader";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { roleAccess } from "helpers/RolesPermission";

function Dashboard(props) {
  const { sideBarPadding } = React.useContext(LoginContext);
  const {
    supportCoordinatorsRequest,
    supportCoordinatorListLoader,
    supportCoordinatorOptions,
    getDashboardDataRequest,
    getDashboardData,
    getDashboardDataLoading,
  } = props;

  const chartRef = useRef(null);
  const navigate = useNavigate();

  const [selectedSupportCoorinator, setSelectedSupportCoordinator] = useState({
    value: "all",
    label: "All",
  });
  const [supportCoordinatorsDropdown, setSupportCoordinatorsDropDown] =
    useState([]);

  useEffect(() => {
    if (supportCoordinatorOptions && supportCoordinatorOptions.length > 0) {
      const data = _.cloneDeep(supportCoordinatorOptions);
      data.unshift({
        value: "all",
        label: "All",
      });
      setSupportCoordinatorsDropDown([...data]);
    }
  }, [supportCoordinatorOptions]);

  useEffect(() => {
    supportCoordinatorsRequest();
  }, []);

  useEffect(() => {
    if (
      selectedSupportCoorinator &&
      roleAccess(
        [],
        PERMISSION_KEYS.COS,
        PERMISSION_KEYS_CHILDREN.COS.TEAM_STATUS.SHOW
      )
    ) {
      getDashboardDataRequest({ filter: selectedSupportCoorinator.value });
    }
  }, [selectedSupportCoorinator]);

  useEffect(() => {
    let myChartRef = null;
    if (chartRef && chartRef.current) {
      myChartRef = new Chart(chartRef.current.getContext("2d"), {
        type: "bar",
        data: {
          labels: getDashboardData?.label || [],
          datasets: [
            {
              label: "Billable",
              data: getDashboardData?.billable,
              backgroundColor: "#4B6CB7",
              barPercentage: 0.5,
              categoryPercentage: 0.5,
              borderRadius: 30,
            },
            {
              label: "Non Bilalble",
              data: getDashboardData?.non_billable,
              backgroundColor: "#4BBBB7",
              barPercentage: 0.5,
              categoryPercentage: 0.5,
              borderRadius: 30,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              enabled: true,
              usePointStyle: true,
              callbacks: {
                label: (data) => {
                  const val =
                    data?.raw.toString() && data.raw.toString().includes(".")
                      ? data.raw.toString().split(".")
                      : data.raw;
                  return `${data.dataset.label}: ${gethoursAndMins(
                    parseFloat(isArray(val) ? val[0] : val),
                    parseFloat(isArray(val) && val.length > 0 ? val[1] : 0)
                  )}`;
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                font: {
                  size: 10,
                },
                autoSkip: false,
                maxRotation: 65,
                minRotation: 65,
              },
            },
            y: {
              suggestedMin: 0,
              suggestedMax: 100,
              ticks: {
                stepSize: 20, // optional - set the tick interval to 20
              },
            },
          },
        },
      });
    }
    return () => {
      if (myChartRef !== null && myChartRef !== undefined) {
        myChartRef.destroy();
      }
    };
  }, [getDashboardData]);

  const getHrAndMin = (data) => {
    if (data && data.toString().includes(":")) {
      const arr = data.split(":");
      return gethoursAndMins(parseInt(arr[0]), parseInt(arr[1]));
    }
    return "00:00";
  };

  const stats = [
    {
      name: "Billable  Hours",
      color: "#F38744",
      Hours:
        getDashboardData?.billable_hours &&
        getDashboardData?.billable_hours.includes(":")
          ? getHrAndMin(getDashboardData?.billable_hours)
          : getDashboardData?.billable_hours,

      title: " ",
      totalhours: getDashboardData?.last_week_amount,
      week: "Total amount logged last week",
      isAmount: true,
    },
    {
      name: "Total COS Clients",
      color: "#32D583",
      Hours: getDashboardData?.total_cos_client,
    },
  ];

  const cards = [
    {
      imageSrc: participant,
      name: "Participant List",
      color: "bg-green-50 ",
      shadow: "hover:shadow-lg hover:shadow-green-100 hover:border-green-100",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      href: "cos/participant-list",
      for: "cos",
    },
    {
      imageSrc: catalogueservice,
      name: "Catalogue Service",
      color: "bg-yellow-50 ",
      shadow: "hover:shadow-lg hover:shadow-yellow-100 hover:border-yellow-100",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      href: "cos/catalog-services",
      for: "cos",
    },
    {
      imageSrc: serviceprovider,
      name: "Service Providers",
      color: "bg-red-50 ",
      shadow: "hover:shadow-lg hover:shadow-red-100 hover:border-red-100",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      href: "cos/external-service-provider",
      for: "cos",
    },
    {
      imageSrc: casenoteshistory,
      name: "Case Note History",
      color: "bg-purple-50 ",
      shadow: "hover:shadow-lg hover:shadow-purple-100 hover:border-purple-100",
      href: "cos/casenotes-history",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      for: "cos",
    },
    {
      imageSrc: ndiscalims,
      name: "NDIS Claims",
      color: "bg-blue-50 ",
      shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      href: "cos/ndis-claims",
      for: "cos",
      permissionChild: PERMISSION_KEYS_CHILDREN.COS.NDIS_CLAIM.SHOW,
    },
    {
      imageSrc: null,
      icon: (
        <FaFileInvoice className='w-[38px] h-[38px] px-2 py-2 text-fuchsia-200 bg-fuchsia-50 rounded-lg' />
      ),
      name: "Invoices",
      color: "bg-fuchsia-50",
      shadow:
        "hover:shadow-lg hover:shadow-fuchsia-100 hover:border-fuchsia-100",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      href: "cos/invoice",
      for: "cos",
      permissionChild: PERMISSION_KEYS_CHILDREN.COS.INVOICES.SHOW,
    },
    {
      imageSrc: reporting,
      name: "Reporting",
      shadow: "hover:shadow-lg hover:shadow-blue-100 hover:border-blue-100",
      color: "bg-blue-50 ",
      userRole: [USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager],
      href: "cos/reporting",
      for: "cos",
      permissionChild: PERMISSION_KEYS_CHILDREN.COS.REPORTING.SHOW,
    },
  ];

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding ? "lg:pl-[4rem] " : "lg:pl-64"
          }  flex flex-col duration-300`}
        >
          <main className='flex-1'>
            <Header title='Coordination Of Support' />
            <div className='flex flex-col min-h-full'>
              <div className='sm:px-6 lg:px-8'>
                <div className='max-w-7xl mx-auto'>
                  <div className='mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-4'>
                    {cards
                      .filter((x) =>
                        roleAccess(
                          [],
                          PERMISSION_KEYS.COS,
                          x?.permissionChild || null
                        )
                      )
                      .map((item, index) => (
                        <NewCoSStatsCard
                          item={item}
                          index={index}
                          key={index}
                          cssClass='text-gray-900 mb-4 font-semibold text-sm '
                        />
                      ))}
                  </div>

                  <div
                    className={`mt-4 flex ${
                      roleAccess(
                        [],
                        PERMISSION_KEYS.COS,
                        PERMISSION_KEYS_CHILDREN.COS.TEAM_STATUS.SHOW
                      )
                        ? "justify-between"
                        : "justify-end"
                    } items-center`}
                  >
                    {roleAccess(
                      [],
                      PERMISSION_KEYS.COS,
                      PERMISSION_KEYS_CHILDREN.COS.TEAM_STATUS.SHOW
                    ) && (
                      <div className='w-[20%]'>
                        <div className='text-gray-700 text-sm'>
                          Filter by Support Coordinators
                        </div>
                        <div className='mt-1'>
                          <SelectOption
                            loading={supportCoordinatorListLoader}
                            isDisabled={supportCoordinatorListLoader}
                            showIcon={true}
                            isSearch={false}
                            data={supportCoordinatorsDropdown}
                            handleChange={setSelectedSupportCoordinator}
                            selectedOption={selectedSupportCoorinator}
                          />
                        </div>
                      </div>
                    )}
                    {roleAccess(
                      [],
                      PERMISSION_KEYS.COS,
                      PERMISSION_KEYS_CHILDREN.COS.SETTING.SHOW
                    ) && (
                      <CustomButton
                        className='text-gray-700 text-sm flex items-center gap-1 cursor-pointer mt-1'
                        Icon={<CiSettings />}
                        label='Settings'
                        clickHandler={() => navigate("/cos/settings")}
                      />
                    )}
                  </div>

                  {getDashboardDataLoading ? (
                    <div className='py-4'>
                      <Loader isSmallView={true} cssClass='text-black' />
                    </div>
                  ) : roleAccess(
                      [],
                      PERMISSION_KEYS.COS,
                      PERMISSION_KEYS_CHILDREN.COS.TEAM_STATUS.SHOW
                    ) ? (
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4'>
                      <div className='sm:col-span-2 lg:col-span-1'>
                        {stats.map((item, index) => (
                          <CosStatsCard
                            item={item}
                            index={index}
                            key={index}
                            cssClass=''
                          />
                        ))}
                      </div>
                      <div className='sm:col-span-2 lg:col-span-1 mt-4 '>
                        <div
                          style={{ borderLeft: `4px solid #4B6CB7` }}
                          className='bg-white border-l-4 shadow-sm   rounded-lg py-5 pl-4 border transition ease-in-out duration-150 hover:shadow-lg h-full'
                        >
                          <div className='text-gray-900 mb-4 font-semibold text-sm'>
                            Weekly Hours Breakdown
                          </div>
                          <canvas ref={chartRef} />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div
                    style={{ borderLeft: `4px solid #7A5AF8` }}
                    className='mt-4 bg-white border-l-4 shadow-sm grid-span-1 overflow-hidden rounded-lg py-5 pl-4 border transition ease-in-out duration-150 hover:shadow-lg mb-4'
                  >
                    <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                      <img src={LeaderBoard} alt='' />
                    </div>
                    <div className='inline-block min-w-full py-2 align-middle sd:px-6 md:px-6 lg:px-8'>
                      <div className='table-wrap shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-gray-50 pt-2 capitalize'>
                            <tr className=''>
                              <th
                                scope='col'
                                className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-900 flex items-center gap-2'
                              >
                                <span className='text-gray-500 text-sm'>
                                  Support Coordinator
                                </span>
                                {/* <span>
                                  <img src={ArrowDownIcon} alt="" />
                                </span> */}
                              </th>
                              <th
                                scope='col'
                                className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-500'
                              >
                                {/* Hours Logged */}
                                Email
                              </th>
                              <th
                                scope='col'
                                className=' px-3 py-3.5 text-left text-sm font-semibold text-gray-500 text-center'
                              >
                                Number of Active Participants
                              </th>
                              {/* <th
                                scope="col"
                                className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                              >
                                Target Level
                              </th> */}
                              {/* <th
                                scope="col"
                                className=" px-3 py-3.5 text-center text-sm font-semibold text-gray-500"
                              ></th> */}
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white'>
                            {supportCoordinatorListLoader ? (
                              <tr>
                                <td
                                  className='w-full text-center py-4 bg-gray-50'
                                  colSpan={3}
                                >
                                  <CircularLoader
                                    classes='flex justify-center items-center'
                                    classloader='loader-l'
                                  />
                                </td>
                              </tr>
                            ) : (
                              <>
                                {supportCoordinatorOptions &&
                                  supportCoordinatorOptions.length > 0 &&
                                  supportCoordinatorOptions.map(
                                    (item, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          className='even:bg-white odd:bg-gray-50'
                                        >
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm'>
                                            <div className='flex items-center'>
                                              <div className='h-10 w-10 flex-shrink-0'>
                                                <img
                                                  src={item?.icon}
                                                  alt={"user_image"}
                                                  className='object-cover h-10 w-10 rounded-full'
                                                />
                                              </div>
                                              <div className='ml-4 capitalize'>
                                                <p className='w-40 truncate '>
                                                  {`${item.first_name} ${item.last_name}`}
                                                </p>
                                              </div>
                                            </div>
                                          </td>

                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                            {/* <div className="flex items-center text-gray-500"> */}
                                            {capitalizeFirstLetter(item?.email)}
                                            {/* </div> */}
                                          </td>
                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                                            {item.participants_count}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div>
                                  <NewProgressBar percent={40} />
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                                <button>
                                  <div title="View">
                                    <img src={DropDownIcon} alt="" />
                                  </div>
                                </button>
                              </td> */}
                                {supportCoordinatorOptions.length === 0 && (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      className='py-6 text-center text-red-600'
                                    >
                                      No data found!
                                    </td>
                                  </tr>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
