import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  CLAIM_TYPE_OPTIONS,
  DELIVERED_SERVICE_STATUS_OPTIONS,
  DUMMY_ADD_SERVICE_PROVIDER_VALUE,
  CASE_NOTES_TIME_VALUES,
  REGEX_HHH_MM,
} from "Constants/constant";
import api from "store/services/cos.services";
import { LOADER_STATUS } from "store/constants";
import SelectOption from "components/common/Select/Select";
import CheckBox from "components/common/checkBox/checkbox";
import Loader from "components/common/Loader/Loader";
import { gethoursAndMins } from "Utils/utils";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import FileUploadInput from "components/common/FileUpload/FileUploadInput";
import { LoginContext } from "helpers/LoginContext";

const UpdateCaseNoteModal = (props) => {
  const {
    updateCaseNotesLoading,
    updateCaseNoteRequest,
    singleCaseNoteData,
    ContactList,
    getContactListRequest,
    caseNotesCreateLoading,
    SupportItems,
    // isOpen,
    closeModal,
    selectedFunding,
    cosParticipantsProfile,
    selectedServiceSupport,
    // setSelectedServiceSupport,
    singleSupportTrackLoading,
    openUpdateCaseNoteModal,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cosParticipantState, setCosParticipantState] = useState(null);
  const [selectedExternalSp, setSelectedExternalSp] = useState(null);
  const [selectedServiceProvider, setSelectedServiceProvider] = useState({
    name: "",
    id: "",
  });
  const [file, setFile] = useState("");
  const [remAmount, setRemAmount] = useState(0.0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [serviceSupportId, setServiceId] = useState(null);
  const [serviceTimeEntered, setServiceTimeEntered] = useState();
  const [contractType, setContractType] = useState(null);
  const [isBillabel, setIsBillabel] = useState();
  const [externalSpFetchRequest, setExternalSpFetchRequest] = useState(false);
  const [externalSpOptions, setExternalSpOptions] = useState([]);
  const [pricePerMinute, setPricePerMinute] = useState(0);
  const [claimType, setClaimType] = useState();
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [caseNoteType, setCaseNoteTypeFieldDIsable] = useState(false);
  const [isCreateCaseNoteLoader, setIsCreateCaseNoteLoader] = useState(
    LOADER_STATUS.NA
  );
  const [selectedDeliveredDate, setSelectedDeliveredDate] = useState("");
  const [subject, setSubject] = useState("");
  const [fileName, setFileName] = useState(null);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();

  const [selectedTime, setSelectedTime] = useState(null);

  const watchFormData = watch();

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const currentSelectedSupportItem = useMemo(() => {
    if (selectedServiceSupport && SupportItems && SupportItems.length > 0) {
      const item = SupportItems.find(
        (x) => x.uuid === selectedServiceSupport.uuid
      );
      return item ? item : selectedServiceSupport;
    }
  }, [SupportItems, selectedServiceSupport]);

  useEffect(() => {
    if (singleCaseNoteData) {
      setIsBillabel(
        singleCaseNoteData.bill_types === "non_billable_hour" ? false : true
      );
      setClaimType({
        label: singleCaseNoteData.claim_type,
        value: singleCaseNoteData.claim_type,
      });
      setSelectedExternalSp({
        ...singleCaseNoteData.external_sp,
        label:
          singleCaseNoteData &&
          singleCaseNoteData.external_sp &&
          singleCaseNoteData.external_sp.name,
        value:
          singleCaseNoteData &&
          singleCaseNoteData.external_sp &&
          singleCaseNoteData.external_sp.id,
      });
      setSubject(singleCaseNoteData.subject);
      setValue(
        "note",
        singleCaseNoteData.notes === "null" ? "" : singleCaseNoteData.notes
      );
      setFileName(singleCaseNoteData?.file_name);
      setFile(singleCaseNoteData?.attachment);
      setSelectedDeliveredDate(singleCaseNoteData.delivered_date);
      setCaseNoteTypeFieldDIsable(
        (singleCaseNoteData?.status === null &&
          singleCaseNoteData?.invoice_status === "ready_for_extraction") ||
          (singleCaseNoteData?.status === "ready_for_extraction" &&
            singleCaseNoteData?.invoice_status === null)
          ? false
          : true
      );
      let hr = "";
      if (singleCaseNoteData.hours === 0) {
        if (singleCaseNoteData.mints === 3) {
          hr = "00:03";
        } else if (singleCaseNoteData.mints === 5) {
          hr = "00:05";
        } else {
          hr = "00:" + singleCaseNoteData.mints;
        }
      } else if (singleCaseNoteData?.mints > 1) {
        hr = "0" + singleCaseNoteData.hours + ":" + singleCaseNoteData.mints;
      } else {
        hr = "0" + singleCaseNoteData.hours + ":0" + singleCaseNoteData.mints;
      }
      setSelectedTime(CASE_NOTES_TIME_VALUES.find((x) => x.value === hr));

      const selectedContactType = ContactList.find((item) => {
        return item.id === singleCaseNoteData.cos_contact_type_id;
      });
      setContractType(selectedContactType);
    }
  }, [singleCaseNoteData]);

  const handleCancelCaseNote = () => {
    if (currentSelectedSupportItem) {
      reset({
        service_support_id: currentSelectedSupportItem?.id,
        totalAmount: "$0",
        delivered_date: null,
        note: null,
        subject: null,
        Hours: null,
      });
      handleChangeSupportItems({
        ...currentSelectedSupportItem,
        value: currentSelectedSupportItem?.id,
        label:
          currentSelectedSupportItem?.price_catalog?.support_item_number.concat(
            " ",
            currentSelectedSupportItem?.price_catalog?.support_item_name
          ),
      });
    }

    setClaimType(null);
    setContractType(null);
    setIsBillabel(true);
    setFile("");
    // setHours("");
  };

  useEffect(() => {
    if (remainingAmount >= 0) {
      clearErrors("remainingamount");
    }
    setValue(
      "remainingamount",
      remainingAmount > 0 ? parseFloat(remainingAmount).toFixed(2) : 0
    );
  }, [remainingAmount]);

  useEffect(() => {
    getContactListRequest({ status: "active" });
    getEXternalSpSearchList();
  }, []);

  useEffect(() => {
    if (
      !caseNotesCreateLoading &&
      currentSelectedSupportItem &&
      isCreateCaseNoteLoader !== LOADER_STATUS.PENDING
    ) {
      handleChangeSupportItems({
        ...currentSelectedSupportItem,
        value: currentSelectedSupportItem?.id,
        label:
          currentSelectedSupportItem?.price_catalog?.support_item_number.concat(
            " ",
            currentSelectedSupportItem?.price_catalog?.support_item_name
          ),
      });
    }
  }, [currentSelectedSupportItem]);

  useEffect(() => {
    if (
      !caseNotesCreateLoading &&
      isCreateCaseNoteLoader === LOADER_STATUS.PENDING &&
      isCreateCaseNoteLoader !== LOADER_STATUS.NA
    ) {
      setIsCreateCaseNoteLoader(LOADER_STATUS.COMPLETE);
    }
  }, [caseNotesCreateLoading]);

  useEffect(() => {
    if (
      !caseNotesCreateLoading &&
      isCreateCaseNoteLoader === LOADER_STATUS.COMPLETE
    ) {
      setIsCreateCaseNoteLoader(LOADER_STATUS.NA);
      handleCancelCaseNote();
    }
  }, [isCreateCaseNoteLoader, caseNotesCreateLoading]);

  useEffect(() => {
    if (contractType) {
      setValue("contract_type", contractType);
      clearErrors("contract_type");
    }
    if (isBillabel) {
      setValue("bill_types", isBillabel);
      clearErrors("bill_types");
    }
  }, [contractType, isBillabel]);

  useEffect(() => {
    if (claimType) {
      setValue("claim_type", claimType);
      clearErrors("claim_type");
    }
  }, [claimType]);

  const onSubmit = (data, event) => {
    if (isUserGracePeriodOver) return;
    event.preventDefault();
    const formData = new FormData();

    if (selectedExternalSp) {
      formData.append("external_sp_id", selectedExternalSp.value);
    }
    formData.append("case_note_id", singleCaseNoteData.id);

    if (cosParticipantState) {
      formData.append("state_id", cosParticipantState.id);
    }

    if (claimType) {
      formData.append("claim_type", claimType.label);
    }
    if (selectedDeliveredDate) {
      formData.append("delivered_date", selectedDeliveredDate);
    }
    if (data.delivered_from) {
      formData.append("delivered_from", data.delivered_from);
    }
    if (data.delivered_to) formData.append("delivered_to", data?.delivered_to);
    formData.append("delivered_quantity", serviceTimeEntered || "00:00");
    formData.append("funding_id", selectedFunding.uuid);
    formData.append("subject", subject);
    formData.append("notes", data?.note);
    formData.append("pt_id", cosParticipantsProfile.id);
    formData.append("cos_contact_type_id", contractType?.id || "");
    formData.append("service_support_id", serviceSupportId);
    formData.append("status", DELIVERED_SERVICE_STATUS_OPTIONS[9].value);
    formData.append("total_amount", calculatedPrice.toFixed(2) || 0);
    formData.append(
      "bill_types",
      isBillabel ? "billable_hours" : "non_billable_hour"
    );
    if (file) {
      formData.append("attachment", file);
    }

    if (selectedServiceSupport) {
      formData.append(
        "service_support_id",
        selectedServiceSupport.service_support_budget_id
      );
    }
    updateCaseNoteRequest(formData);
    setIsCreateCaseNoteLoader(LOADER_STATUS.PENDING);
  };

  const getEXternalSpSearchList = async () => {
    try {
      setExternalSpFetchRequest(true);
      const response = await api.getServiceProviderSearchList({ name: "" });
      if (response && response.external_sps) {
        setExternalSpFetchRequest(false);
        const data = response.external_sps.map((x) => {
          const obj = { ...x, label: x.name, value: x.id };
          return { ...obj };
        });
        data.unshift({
          value: "add_service_provider",
          label: "Add New Service Provider",
        });
        setExternalSpOptions([...data]);
        return [...data];
      }
      setExternalSpFetchRequest(false);

      setExternalSpOptions([]);
    } catch (err) {
      console.log("err", err);
      setExternalSpFetchRequest(false);
    }
  };

  const handleChangeSupportItems = (e) => {
    // setHours("");
    setValue("totalAmount", "$" + singleCaseNoteData?.total_amount);
    setCalculatedPrice(0);
    if (e.external_sp) {
      setSelectedExternalSp({
        ...singleCaseNoteData.external_sp,
        label:
          singleCaseNoteData &&
          singleCaseNoteData.external_sp &&
          singleCaseNoteData.external_sp.name,
        value:
          singleCaseNoteData &&
          singleCaseNoteData.external_sp &&
          singleCaseNoteData.external_sp.id,
      });
    } else {
      setSelectedExternalSp(null);
    }
    setCosParticipantState(e.state);

    setRemAmount(
      e.service_support_budget && e.service_support_budget.remain_amount
    );

    setRemainingAmount(
      parseFloat(e.amount_allocated || 0) ===
        parseFloat(
          (e.service_support_budget &&
            e.service_support_budget.remain_amount) ||
            0
        ) &&
        parseFloat(
          (e.service_support_budget &&
            e.service_support_budget.delivered_amount) ||
            0
        ) === 0
        ? parseFloat(
            e.service_support_budget &&
              e.service_support_budget.total_budget_amount
          )
        : parseFloat(
            (e.service_support_budget &&
              e.service_support_budget.remain_amount) ||
              0
          )
    );
    setServiceId(e.id);
    if (e.value) {
      setServiceId(e.id);
      if (
        e.service_support_budget &&
        e.service_support_budget.alternative_rate &&
        parseFloat(
          e.service_support_budget && e.service_support_budget.alternative_rate
        ) > 0
      ) {
        setPricePerMinute(
          parseFloat(e?.service_support_budget?.alternative_rate) / 60
        );
      } else {
        setPricePerMinute(
          parseFloat(e?.service_support_budget?.original_rate) / 60
        );
      }
      if (e?.external_service_provider) {
        setSelectedServiceProvider({
          label: e?.external_service_provider?.name,
          value: e?.external_service_provider?.id,
        });
      } else {
        setSelectedServiceProvider({
          label: "",
          value: "",
        });
      }
      setValue("service_support_id", e);
      clearErrors("service_support_id");
    }
  };

  const handleHoursInputOnChange = (inputValue) => {
    setSelectedTime(null);
    if (inputValue && REGEX_HHH_MM.test(inputValue)) {
      // setHours(inputValue);
      clearErrors("Hours");
      const valueEntered = inputValue;
      setServiceTimeEntered(valueEntered);
      const hourToMinutes = parseInt(valueEntered.split(":")[0] * 60);
      let min =
        parseInt(valueEntered.split(":")[1]) > 0
          ? parseInt(valueEntered.split(":")[1])
          : 0;
      const minutes = parseInt(hourToMinutes) + parseInt(min);
      setValue(
        "totalAmount",
        `$${parseFloat(pricePerMinute * minutes).toFixed(2)}`
      );
      if (pricePerMinute * minutes > 0) {
        if (
          inputValue.toString() ===
          gethoursAndMins(
            currentSelectedSupportItem.remain_total_hours,
            currentSelectedSupportItem.remain_total_mint
          )
        ) {
          setCalculatedPrice(remAmount);
          setRemainingAmount(0.0);
          setValue("totalAmount", `$${remAmount}`);
        } else {
          setCalculatedPrice(pricePerMinute * minutes);
          const ttlRem =
            parseFloat(remAmount) - parseFloat(pricePerMinute * minutes);
          setRemainingAmount(ttlRem > 0.0 ? ttlRem : 0.0);
        }
      } else {
        setRemainingAmount(remAmount);
      }
    } else {
      setValue("totalAmount", "$0");
      setRemainingAmount(remAmount);
    }
  };

  useEffect(() => {
    if (watchFormData?.Hours && caseNoteType === false) {
      handleHoursInputOnChange(watchFormData?.Hours);
    }
  }, [watchFormData?.Hours]);

  return (
    <ModalSkeleton
      cssClass='w-[65%]'
      title='Update Case Notes'
      // modalFooter={modalFooter()}
      isOpen={openUpdateCaseNoteModal}
      closeModal={closeModal}
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-cols-3 gap-4 px-3'>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("service_support_id", {})}
                  name='service_support_id'
                  id='service_support_id'
                  className='hidden did-floating-input'
                />
              </div>
              <label className='did-floating-label text-start mt-1'>
                Support Items
              </label>
              <SelectOption
                selectedOption={
                  currentSelectedSupportItem
                    ? {
                        ...currentSelectedSupportItem,
                        value: currentSelectedSupportItem?.id,
                        label:
                          currentSelectedSupportItem?.price_catalog?.support_item_number.concat(
                            " ",
                            currentSelectedSupportItem?.price_catalog
                              ?.support_item_name
                          ),
                      }
                    : null
                }
                name='service_support_id'
                isSearch={false}
                data={SupportItems}
                isDisabled={true}
                placeHolder={singleSupportTrackLoading ? "Loading..." : ""}
                loading={singleSupportTrackLoading}
              />
              {errors?.service_support_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  disabled={caseNoteType}
                  {...register("claim_type", {})}
                  name='claim_type'
                  id='claim_type'
                  className='hidden did-floating-input'
                />
              </div>
              <div className='text-start'>
                <label htmlFor='claim_type' className='did-floating-label '>
                  Claim Type
                </label>
              </div>
              <SelectOption
                isDisabled={caseNoteType}
                isSearch={false}
                data={CLAIM_TYPE_OPTIONS}
                selectedOption={claimType}
                handleChange={setClaimType}
              />
              {errors?.claim_type?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <div className=''>
                <div className='hidden'>
                  <input
                    {...register("serviceProvider")}
                    name='price_catalog_id'
                  />
                </div>
                <label className='text-start did-floating-label'>
                  Service Provider
                </label>
                <SelectOption
                  isDisabled={true}
                  isClearable
                  isSearch={true}
                  data={
                    externalSpOptions && externalSpOptions.length > 0
                      ? externalSpOptions
                      : []
                  }
                  selectedOption={selectedExternalSp}
                  handleChange={(e) => {
                    if (e.value === DUMMY_ADD_SERVICE_PROVIDER_VALUE) {
                      navigate("/cos/external-service-provider");
                    } else {
                      setSelectedExternalSp(e);
                    }
                  }}
                  loading={externalSpFetchRequest}
                />
              </div>
            </div>
          </div>

          <div className='hr width-full col-span-3 border-b border-b-gray-200 mt-4 mb-3'></div>

          <div className='grid grid-cols-3 gap-4 px-3'>
            <div className='col-span-1'>
              <label htmlFor='' className=' text-start did-floating-label'>
                Delivered Date
              </label>
              <input
                {...register("delivered_date", {})}
                disabled={caseNoteType}
                value={selectedDeliveredDate}
                onChange={(e) => setSelectedDeliveredDate(e.target.value)}
                name='delivered_date'
                id='delivered_date'
                type='date'
                min={moment().format("DD-MM-YYYY")}
                className='did-floating-input'
              />
              {errors?.delivered_date?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>

          <div className='hr width-full col-span-3 border-b border-b-gray-200 mt-4 mb-3'></div>
          <div className='grid grid-cols-3 gap-4 px-3'>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("contract_type", {})}
                  name='contract_type'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label htmlFor='contract_type' className='did-floating-label '>
                Contact Type
              </label>
              <SelectOption
                isSearch={false}
                name='contract_type'
                isDisabled={caseNoteType}
                data={ContactList}
                selectedOption={contractType}
                handleChange={setContractType}
              />
              {errors?.contract_type?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <label htmlFor='' className=' text-start did-floating-label'>
                Subject
              </label>
              <input
                {...register("subject", {
                  maxLength: 254,
                })}
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
                name='subject'
                id='subject'
                type='text'
                className='did-floating-input'
              />
              {errors?.subject?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <div className='hidden'>
                <input
                  {...register("bill_types", {})}
                  name='bill_types'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label htmlFor='' className='did-floating-label '>
                Is Billable
              </label>
              <div className='flex gap-4 justify-start'>
                <div className='flex gap-x-2 items-center'>
                  <CheckBox
                    name='bill_types'
                    label='Billable'
                    checked={isBillabel}
                    handleChange={() =>
                      !caseNoteType && setIsBillabel(!isBillabel)
                    }
                  />
                </div>
                <div className='flex gap-x-2 items-center'>
                  <CheckBox
                    name='bill_types'
                    label='Non-Billable'
                    checked={!isBillabel}
                    handleChange={() =>
                      !caseNoteType && setIsBillabel(!isBillabel)
                    }
                  />
                </div>
                {errors?.bill_types?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='hr width-full col-span-3 border-b border-b-gray-200 mt-4 mb-3'></div>
          <div className='grid grid-cols-3 gap-4 px-3'>
            <div className='col-span-2'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='col-span-1'>
                  <label className='text-gray-700 text-sm font-medium mb-1'>
                    Hours
                  </label>
                  <SelectOption
                    isDisabled={caseNoteType}
                    cssClass='mt-[1%]'
                    isRequired={true}
                    register={register}
                    setValue={setValue}
                    name='Hours'
                    isSearch={false}
                    data={CASE_NOTES_TIME_VALUES}
                    selectedOption={
                      watchFormData?.Hours
                        ? CASE_NOTES_TIME_VALUES.find(
                            (x) => x.value === watchFormData?.Hours
                          )
                        : selectedTime
                    }
                    errorMessage={
                      errors?.Hours?.type === "required" ? (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      ) : errors?.Hours?.type === "validate" ? (
                        <span className='text-sm text-red-500'>
                          Hours should be Equal or less than Time Left
                        </span>
                      ) : null
                    }
                  />
                </div>
                <div className='col-span-1'>
                  <label htmlFor='' className=' text-start did-floating-label'>
                    Total Amount
                  </label>
                  <input
                    {...register("totalAmount", {
                      disabled: true,
                      //   ? `$${parseFloat(calculatedPrice).toFixed(2)}`
                    })}
                    name='totalAmount'
                    type='text'
                    className='did-floating-input'
                    style={{
                      backgroundColor: "#4b6bb710",
                    }}
                  />
                </div>
                <div className='col-span-1'>
                  <label htmlFor='' className='text-start did-floating-label'>
                    Remaining Amount
                  </label>
                  <input
                    {...register("remainingamount")}
                    value={
                      parseFloat(remainingAmount).toFixed(2)
                        ? `$${parseFloat(remainingAmount).toFixed(2)}`
                        : ""
                    }
                    disabled
                    name='remainingamount'
                    type='text'
                    className='did-floating-input'
                    style={{
                      backgroundColor: "#4b6bb710",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-span-1'>
              <label htmlFor='' className='did-floating-label text-start'>
                Attachment
              </label>
              <div>
                <FileUploadInput
                  type='any'
                  handleFile={(e) => {
                    setFile(e);
                  }}
                  isDisabled={caseNoteType}
                  size='2'
                  file={file}
                  updateFileName={fileName}
                  handleDelete={() => {
                    setFile("");
                    setFileName(null);
                  }}
                  showDragAndDrop={false}
                />
              </div>
            </div>
          </div>
          <div className='grid grid-cols-3 gap-4 px-3 mt-4'>
            <div className='col-span-2'>
              <label htmlFor='' className='did-floating-label text-start'>
                Case Notes
              </label>
              <textarea
                {...register("note", {})}
                rows='4'
                className='w-full border-gray-300 rounded oeverflow-y-auto resize-none'
              ></textarea>
              {errors?.note?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <label>&nbsp;</label>
              <div>
                <button
                  type='submit'
                  disabled={
                    updateCaseNotesLoading ||
                    singleSupportTrackLoading ||
                    isUserGracePeriodOver
                  }
                  className='bg-green-50 w-full h-[44px] text-green-700 text-base font-medium mb-4 rounded-lg	shadow-sm border border-green-50'
                >
                  {updateCaseNotesLoading && (
                    <Loader cssClass='text-black' isSmallView />
                  )}
                  Update
                </button>
              </div>
              <div></div>
            </div>
          </div>
        </form>
      </div>
    </ModalSkeleton>
  );
};
export default UpdateCaseNoteModal;
