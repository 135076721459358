import React, { useState, useEffect, useMemo } from "react";
import Select, { components } from "react-select";
import {
  fetchOptionsForWorkerAndParticpant,
  getNameProfile,
} from "Utils/utils";
import "components/common/Select/Select.css";

// WorkerShiftCustomSelect component
const WorkerShiftCustomSelect = ({
  cssClass,
  isDisabled,
  selectedValue,
  setValue,
  name,
  clearErrors,
  errorMessage,
  isValueChanges,
  isMultiple,
  selectFor = "participant",
  defaultOptions,
  isClearable = true,
  isOptionRefreshed,
  isClearCall,
  loading,
}) => {
  const [options, setOptions] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // console.log("name:defaultOptions", name, defaultOptions);
  // console.log("name:options", name, options);
  const selectOptions = useMemo(() => {
    if (defaultOptions) {
      return defaultOptions;
    } else if (defaultOptions === null) {
      if (isOptionRefreshed) {
        isOptionRefreshed(options);
      }
    }

    return options;
  }, [defaultOptions, options]);

  useEffect(() => {
    const loadOptions = async () => {
      const fetchedOptions = await fetchOptionsForWorkerAndParticpant(
        selectFor
      );
      if (isOptionRefreshed) {
        isOptionRefreshed(fetchedOptions);
      }
      setOptions(fetchedOptions);
      setIsLoading(false);
    };

    loadOptions();
  }, [selectFor]);

  const getIcon = (type) => {
    // console.log("here");
    return type;
  };

  const handleImageError = (e, fname, lname) => {
    // color=fff
    return (e.target.src = getNameProfile(fname, lname, "0D8ABC"));
  };

  const CustomSelectValue = (csprops) => {
    return (
      <components.SingleValue {...csprops}>
        <div className='flex items-center capitalize'>
          {csprops.data.label && csprops.data?.icon && (
            <img
              onError={(e) => handleImageError(e, csprops.data.label, " ")}
              className='w-7 h-7 bg-gray-300 rounded-full flex-shrink-0 object-cover mr-2'
              src={getIcon(csprops.data.icon || csprops.data.value)}
              alt=''
            />
          )}
          {csprops.data.label}
        </div>
      </components.SingleValue>
    );
  };

  const CustomOption = (props) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div className='flex items-center capitalize'>
          {data.label && data?.icon && (
            <img
              className='w-7 h-7 bg-gray-300 rounded-full flex-shrink-0 object-cover mr-2'
              src={getIcon(data.icon || data.value)}
              alt=''
            />
          )}
          {data.label}
        </div>
      </components.Option>
    );
  };

  return (
    <>
      <Select
        components={{
          Option: CustomOption,
          SingleValue: CustomSelectValue,
        }}
        className={`w-100 roleSelector scrollOptions capitalize ${cssClass}`}
        isMulti={isMultiple}
        options={selectOptions}
        placeholder='Search...'
        noOptionsMessage={() => "No options"}
        menuPlacement='auto'
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        isLoading={isLoading || loading}
        onChange={(selectedOption) => {
          if (selectedOption === null && isClearCall) {
            isClearCall();
          }

          if (setValue && name) {
            setValue(name, selectedOption?.value);
            if (clearErrors && errorMessage && name) {
              clearErrors(name, "");
            }
            isValueChanges(true, selectedOption);
          }
        }}
        value={
          selectedValue ? options.find((x) => x.value === selectedValue) : null
        }
        isClearable={isClearable}
        isDisabled={isDisabled}
      />
      {errorMessage && (
        <span
          style={{
            fontSize: "13px",
            color: "red",
          }}
          className='capitalize'
        >
          {errorMessage}
        </span>
      )}
    </>
  );
};

export default WorkerShiftCustomSelect;
