import React, { useState } from "react";
import { saveAs } from "file-saver";
import api from "store/services/cos.services";
import { toast } from "react-toastify";
import Loader from "components/common/Loader/Loader";
import {
  baseURLImage,
  formatDateIntoDMYToDisplay,
  getExtension,
  textTruncate,
} from "Utils/utils";
import { BiPencil } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { HiOutlineDownload, HiOutlineEye } from "react-icons/hi";

const FundingNotesItem = ({
  notes,
  index,
  setEditFundingNotes,
  fundingNotesRemoved,
  isUserGracePeriodOver,
}) => {
  const [isDeleteLoader, setIsDeleteLoader] = useState(false);

  const handleDeleteRow = async (id) => {
    try {
      setIsDeleteLoader(true);
      const response = await api.fundingNotesRemove({ fundingNotesId: id });
      if (response) {
        const obj = { fundingNotesId: id, funding: response.funding };
        fundingNotesRemoved(obj);
        setIsDeleteLoader(false);
        toast.success("Funding Notes Successfully Deleted!");
      }
    } catch (err) {
      setIsDeleteLoader(false);
    }
  };

  return (
    <tr key={index} className=' hover:bg-blue-50'>
      <td className='whitespace-nowrap py-4  px-3 text-sm ' title={notes.note}>
        <div className='flex items-center'>
          <div className='ml-0'>
            <div className='font-medium text-gray-500 capitalize'>
              {textTruncate(notes.note, 50, 49)}
            </div>
          </div>
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{notes.type}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {formatDateIntoDMYToDisplay(notes.created_at)}
        </div>
      </td>

      <td className='relative whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='flex justify-start space-x-2'>
          <button
            className='bg-white hover:bg-green-500 hover:text-white text-green-600 rounded-md'
            onClick={() => setEditFundingNotes(notes)}
          >
            <div className='icon-pencil-wrapper' title='Edit'>
              <BiPencil className='icon-size' />
            </div>
          </button>
          {notes.file && (
            <div className='gap-x-2 flex items-center justify-center'>
              <a
                href={`${baseURLImage(notes.file)}`}
                without
                rel='noopener noreferrer'
                target='_blank'
                className='icon-eye-wrapper'
              >
                <HiOutlineEye className='icon-size' />
              </a>
              <p
                onClick={() =>
                  saveAs(
                    `${baseURLImage(notes.file)}`,
                    `${notes.type ? notes.type : getExtension(notes.file)}.`
                  )
                }
                className='cursor-pointer icon-download-wrapper'
              >
                <HiOutlineDownload className='icon-size' />
              </p>
            </div>
          )}
          {isDeleteLoader ? (
            <Loader isSmallView cssClass='mt-1 text-black' />
          ) : (
            <button
              disabled={isUserGracePeriodOver}
              onClick={() => {
                if (isUserGracePeriodOver) return;
                handleDeleteRow(notes.uuid);
              }}
              className='bg-white hover:bg-red-500 hover:text-white text-red-500 rounded-md'
            >
              <div className='icon-delete-wrapper cursor-pointer'>
                <MdDelete className='icon-size' />
              </div>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default FundingNotesItem;
