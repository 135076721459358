import React, { useState, useContext, useEffect } from "react";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SideBar from "components/common/SideBar/SideBarNew";
import Forms from "./Forms";
import { useNavigate } from "react-router";
import { LoginContext } from "helpers/LoginContext";
import {
  ALL_FORM_LIST_LIMIT,
  emptyPaginationObj,
  SORT_TYPE,
  USER_ROLE,
} from "Constants/constant";
import { getPaginationObj } from "Utils/utils";
import { LOADER_STATUS } from "store/constants";
import { roleAccess } from "helpers/RolesPermission";

const AllForms = (props) => {
  const {
    categoryLoader,
    fetchFormTemplate,
    fetchFormCategories,
    formCategoriesList,
    spFormTemplatesList,
    spFormTemplateLoader,
    clearCrmHrmformstemplatesLocally,
    role,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const navigate = useNavigate();
  const [routeroleAccess, setRouteroleAccess] = useState(null);

  useEffect(() => {
    if (routeroleAccess && routeroleAccess !== role) {
      window.location.reload();
    }
  }, [role]);

  useEffect(() => {
    setRouteroleAccess(role);
    if (formCategoriesList.length <= 0) {
      setRenderLoading(LOADER_STATUS.PENDING);
      fetchFormCategories({ role: role });
    }

    return () => clearCrmHrmformstemplatesLocally();
  }, []);

  const [paginationObj, setPaginationObj] = useState(
    spFormTemplatesList
      ? getPaginationObj(
          spFormTemplatesList.form_templates,
          spFormTemplatesList.query_params
        )
      : emptyPaginationObj
  );
  const [sortBy, setSortBy] = useState(null);

  useEffect(() => {
    if (spFormTemplatesList) {
      setPaginationObj(
        getPaginationObj(
          spFormTemplatesList.form_templates,
          spFormTemplatesList.query_params
        )
      );
    }
  }, [spFormTemplatesList]);

  useEffect(() => {
    if (spFormTemplatesList) {
      setPaginationObj(
        getPaginationObj(
          spFormTemplatesList.form_templates,
          spFormTemplatesList.query_params
        )
      );
    }
  }, []);

  const editFormTemplateHandler = (item) => {
    const url = role === "hrm" ? "/edit-template/hrm" : "/edit-template";
    navigate(`${url}/${item.slug}`, {
      state: { templateUUID: item.uuid },
    });
  };

  const fetchFormTemplateList = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: ALL_FORM_LIST_LIMIT });
    const bodyFilter = filters.hasOwnProperty("filter") ? filters.filter : null;
    if (filters.hasOwnProperty("filter")) {
      delete filters.filter;
    }
    fetchFormTemplate({ filters, bodyFilter, role });
  };

  useEffect(() => {
    if (!categoryLoader && renderLoading === LOADER_STATUS.PENDING) {
      setRenderLoading(LOADER_STATUS.COMPLETE);
    }
  }, [categoryLoader]);

  const handleFetch = (obj, sortBy) => {
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (paginationObj.page || obj?.page) {
      Object.assign(obj, { page: obj.page ? obj.page : paginationObj.page });
    }
    if (paginationObj.filter) {
      !paginationObj.filter.hasOwnProperty("form_category")
        ? Object.assign(obj, {
            filter: paginationObj.filter,
          })
        : Object.assign(obj, {
            filter: {
              form_category: paginationObj.filter.form_category.map(
                (page) => page.id
              ),
            },
          });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.form_category !== "both" && obj.hasOwnProperty("form_category")) {
      Object.assign(obj, {
        filter: {
          form_category: obj.form_category.map((some) => some.id),
        },
      });
    }
    if (roleAccess([USER_ROLE.Super_Admin]) && obj.hasOwnProperty("status")) {
      const filterData = { status: obj.status, form_category: [] };
      if (obj?.filter) {
        Object.assign(filterData, {
          ...obj?.filter,
        });
      }
      Object.assign(obj, {
        page: obj.page ? obj.page : 1,
        filter: {
          ...filterData,
        },
      });
    }

    fetchFormTemplateList({ ...obj });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginationObj.page]);

  return (
    <>
      <div className='min-h-full'>
        {renderLoading === LOADER_STATUS.PENDING && (
          <div className='absolute top-1/2 -translate-y-1/2 z-50 bg-blue-dark bg-opacity-20  flex  w-full flex-1  flex-col justify-center py-12 px-4 sm:px-6 lg:flex lg:justify-center lg:px-20 xl:px-24 h-full'>
            <CircularLoader
              classes='flex justify-center items-center'
              classloader='loader-l'
            />
          </div>
        )}
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col`}
        >
          {formCategoriesList && formCategoriesList.length > 0 && (
            <Forms
              editFormTemplateHandler={editFormTemplateHandler}
              templateList={
                spFormTemplatesList
                  ? spFormTemplatesList.form_templates.data
                  : []
              }
              isLoading={spFormTemplateLoader}
              queryParams={paginationObj}
              formCategoriesList={formCategoriesList}
              handleFetch={handleFetch}
              getSortBy={(e) => setSortBy(e)}
              paginationObj={paginationObj}
              setPaginationObj={setPaginationObj}
              routeRole={role}
              isUserGracePeriodOver={isUserGracePeriodOver}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AllForms;
