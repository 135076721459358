import React, { useEffect, useState } from "react";
import api from "store/services/sp.services";
import { useForm } from "react-hook-form";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import { toast } from "react-toastify";
import { AiOutlineQuestion } from "react-icons/ai";
import { capitalizeFirstLetter } from "Utils/utils";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { PAYROLL_PLATFORMS } from "store/constants";
import QuickbookGuide from "assets/images/quick-books-api-key.png";

const ApikeyModal = (props) => {
  const { closeModal, isOpen, checkPayrollAuthentication, type } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    reset();
    return () => reset();
  }, []);

  const onSubmit = async (data) => {
    if (!data || !data.api_key) return;
    try {
      setIsLoading(true);
      const response = await api.connectPayrollIntegration(data);
      if (response?.data) {
        toast.success("Payroll Successfully Integrated!");
        checkPayrollAuthentication();
        closeModal();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
      toast.error(err?.message ?? "Something Went Wrong!");
    }
  };

  const modalFooter = () => (
    <CustomButton
      type="submit"
      label={"submit"}
      showLoading={isLoading}
      isDisabled={isLoading}
      clickHandler={() => {
        const buttonRef = document.getElementById("addApiKey");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  const getImage = () => {
    switch (type) {
      case PAYROLL_PLATFORMS.QUICKBOOKS:
        return QuickbookGuide;
      default:
        return "";
    }
  };

  return (
    <ModalSkeleton
      isOpen={isOpen}
      cssClass="w-[30%]"
      title={`Quickbooks integration`}
      closeModal={closeModal}
      modalFooter={modalFooter()}
    >
      <>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="api_key"
                className=" text-sm font-bold text-gray-700"
              >
                API Key
              </label>
              <span className="text-xs font-sm mr-1">
                {" "}
                (Kindly input your QuickBooks account API key in below text
                field. Need help locating your API key?{" "}
                <span
                  className="text-blue-600 cursor-pointer underline"
                  onClick={() => setShowImage(true)}
                >
                  Find it here
                </span>
                )
              </span>

              <textarea
                id="api_key"
                name="api_key"
                rows={3}
                className="mt-1 shadow-sm block w-full resize-none overflow-y-auto focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md p-2 focus:bg-gray-50"
                defaultValue={""}
                {...register("api_key", { required: true })}
              />
              {errors?.api_key?.type === "required" && (
                <span
                  style={{ fontSize: "13px", color: "red", marginLeft: "5px" }}
                  // className="capitalize"
                >
                  This field is required!
                </span>
              )}
            </div>
            <button type="submit" className="hidden sr-only" id="addApiKey" />
          </form>
        </div>

        {showImage && (
          <ModalSkeleton
            isOpen={showImage}
            title={`${capitalizeFirstLetter(type?.replace("_", ""))} Api Key`}
            cssClass={"w-[47%] bg-red-400"}
            closeModal={() => setShowImage(false)}
          >
            {isOpen ? (
              <img src={getImage()} alt={type} />
            ) : (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes="flex justify-center items-center"
                  classloader="loader-l"
                />
              </div>
            )}
          </ModalSkeleton>
        )}
      </>
    </ModalSkeleton>
  );
};

export default ApikeyModal;
