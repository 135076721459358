import React, { useEffect, useState, useMemo } from "react";
import { Map, GoogleApiWrapper, Marker, Polyline } from "google-maps-react";
import homeIcon from "assets/rostering/house.png";
import carIcon from "assets/rostering/car.png";
import endIcon from "assets/rostering/end.png";

const MapComponent = ({ google, selectedTimeSheet }) => {
  const [addressCoordinates, setAddressCoordinates] = useState(null);
  // const [directions, setDirections] = useState([]);


  //to display welfare markers
  const pathCoordinates =
    selectedTimeSheet &&
    selectedTimeSheet.shiftTravelings?.length > 0 &&
    selectedTimeSheet.shiftTravelings?.filter((item) => item.action === "welfare")
      .map(formatItem);


      //to display home address of participant
  const participantHomeAdress = `${
    selectedTimeSheet?.participant?.street_address ?? ""
  } ${selectedTimeSheet?.participant?.suburb ?? ""} ${
    selectedTimeSheet?.participant?.state ?? ""
  }`;

  useEffect(() => {
    const getCoordinatesFromAddress = async () => {
      if (participantHomeAdress && participantHomeAdress.trim() !== "") {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          { address: participantHomeAdress },
          (results, status) => {
            if (status === "OK" && results && results[0]) {
              const { lat, lng } = results[0].geometry.location;
              setAddressCoordinates({ lat: lat(), lng: lng() });
            } else {
              console.error("Error fetching coordinates from address");
            }
          }
        );
      } else {
        console.warn("Invalid address: Address is null, undefined, or an empty string");
      }
    };
  
    getCoordinatesFromAddress();
  }, [participantHomeAdress, google]);
  

  // useEffect(() => {
  //   const getCoordinatesFromAddress = async () => {
  //     if (participantHomeAdress) {
  //       const geocoder = new google.maps.Geocoder();
  //       geocoder.geocode(
  //         { address: participantHomeAdress },
  //         (results, status) => {
  //           if (status === "OK" && results && results[0]) {
  //             const { lat, lng } = results[0].geometry.location;
  //             setAddressCoordinates({ lat: lat(), lng: lng() });
  //           } else {
  //             console.error("Error fetching coordinates from address");
  //           }
  //         }
  //       );
  //     }
  //   };

  //   getCoordinatesFromAddress();
  // }, [participantHomeAdress, google]);

  //style the map
  const mapStyles = {
    width: "100%",
    height: "100%",
  };
  const iconSize = {
    width: 32,
    height: 32,
  };


  //if multiple start & stop points
  const multiplePolyline =
    selectedTimeSheet?.shiftTravelings?.length > 0 &&
    selectedTimeSheet?.shiftTravelings;

  const paths = useMemo(() => {
    const updatedPaths = [];
    let currentSegment = [];
    let startEncountered = false;

    for (let i = 0; i < multiplePolyline?.length; i++) {
      const item = multiplePolyline[i];

      if (item.action === "start") {
        currentSegment = [formatItem(item)];
        startEncountered = true;
      } else if (item.action === "stop") {
        currentSegment.push(formatItem(item));
        updatedPaths.push([...currentSegment]);
        currentSegment = [];
        startEncountered = false;
      } else {
        currentSegment.push(formatItem(item));
      }

      if (i === multiplePolyline.length - 1) {
        if (startEncountered) {
          currentSegment.push(formatItem(item));
          currentSegment.pop();
          updatedPaths.push([...currentSegment]);
        }
      }
    }

    return updatedPaths;
  }, [multiplePolyline]);

  // Function to format item
  function formatItem(item) {
    return {
      lat: parseFloat(item?.lat),
      lng: parseFloat(item?.lng),
      action: item?.action,
      time: item?.time,
    };
  }

  // useEffect(() => {
  //   const getDirections = (origin, destination, index) => {
  //     const directionsService = new google.maps.DirectionsService();

  //     directionsService.route(
  //       {
  //         origin: new google.maps.LatLng(origin.lat, origin.lng),
  //         destination: new google.maps.LatLng(destination.lat, destination.lng),
  //         travelMode: google.maps.TravelMode.DRIVING,
  //       },
  //       (result, status) => {
  //         if (status === google.maps.DirectionsStatus.OK) {
  //           setDirections((prevDirections) => {
  //             const updatedDirections = [...prevDirections];
  //             updatedDirections[index] = result;
  //             return updatedDirections;
  //           });
  //         } else {
  //           console.error("Error fetching directions");
  //         }
  //       }
  //     );
  //   };

  //   // Clear existing directions
  //   setDirections([]);

  //   // Fetch directions for each path segment
  //   paths.forEach((path, index) => {
  //     const origin = path[0];
  //     const destination = path[path.length - 1];
  //     getDirections(origin, destination, index);
  //   });
  // }, [paths, google]);

  
  //  TO HANDLE THE START AND END POINTS
  // const bounds = new google.maps.LatLngBounds();
  // bounds.extend(
  //   new google.maps.LatLng(
  //     selectedTimeSheet?.shift_start_lat,
  //     selectedTimeSheet?.shift_start_lng
  //   )
  // );
  // bounds.extend(
  //   new google.maps.LatLng(
  //     selectedTimeSheet?.shift_end_lat,
  //     selectedTimeSheet?.shift_end_lng
  //   )
  // );
  // bounds.extend(
  //   new google.maps.LatLng(addressCoordinates?.lat, addressCoordinates?.lng)
  // );
  // paths.forEach((path) => {
  //   path.forEach((coordinate) => {
  //     bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
  //   });
  // });
  const bounds = new google.maps.LatLngBounds();

  if (selectedTimeSheet?.shift_start_lat && selectedTimeSheet?.shift_start_lng) {
    bounds.extend(
      new google.maps.LatLng(
        selectedTimeSheet.shift_start_lat,
        selectedTimeSheet.shift_start_lng
      )
    );
  }
  
  if (selectedTimeSheet?.shift_end_lat && selectedTimeSheet?.shift_end_lng) {
    bounds.extend(
      new google.maps.LatLng(
        selectedTimeSheet.shift_end_lat,
        selectedTimeSheet.shift_end_lng
      )
    );
  }
 
  if (addressCoordinates?.lat && addressCoordinates?.lng) {
    bounds.extend(
      new google.maps.LatLng(addressCoordinates.lat, addressCoordinates.lng)
    );
  }
  
  paths.forEach((path) => {
    path.forEach((coordinate) => {
      if (coordinate.lat && coordinate.lng) {
        bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
      }
    });
  });
  

  return (
    <div style={{ position: "relative", width: "100%", height: "50vh" }}>
      <Map
        google={google}
        style={mapStyles}
        initialCenter={{
          lat: selectedTimeSheet?.shift_start_lat,
          lng: selectedTimeSheet?.shift_start_lng,
        }}
   
        bounds={bounds}
      >
        {selectedTimeSheet?.shift_start_lat &&
          selectedTimeSheet?.shift_start_lng && (
            <Marker
              title="Shift Start Location"
              name="Shift Start Location"
              position={{
                lat: selectedTimeSheet?.shift_start_lat,
                lng: selectedTimeSheet?.shift_start_lng,
              }}
              icon={"https://maps.google.com/mapfiles/ms/icons/green-dot.png"}
            />
          )}

        {selectedTimeSheet?.shift_end_lat &&
          selectedTimeSheet?.shift_end_lng && (
            <Marker
              title="Shift End Location"
              name="Shift End Location"
              position={{
                lat: selectedTimeSheet?.shift_end_lat,
                lng: selectedTimeSheet?.shift_end_lng,
              }}
            />
          )}
          

        {/* {directions?.map((direction, index) => (
          direction && (
            <Polyline
              key={index}
              path={direction.routes[0].overview_path.map((point) => ({
                lat: point.lat(),
                lng: point.lng(),
              }))}
              strokeColor="#0000FF"
              strokeOpacity={0.8}
              strokeWeight={5}
            />
          )
        ))} */}

        {paths &&
          paths.length > 0 &&
          paths.map((item, index) =>
            item?.map((coordinate, elementIndex) => (
              <Marker
                key={elementIndex}
                title={`${
                  elementIndex === 0
                    ? "Traveling start point"
                    : elementIndex === item.length - 1
                    ? "Traveling end point"
                    : `lat:${coordinate.lat} long:${coordinate.lng} & timing: ${coordinate.time}`
                }`}
                position={coordinate}
                icon={{
                  url:
                    elementIndex === 0
                      ? carIcon
                      : elementIndex === item.length - 1
                      ? endIcon
                      : "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                  scaledSize: iconSize,
                }}
              />
            ))                                                                                  
          )}
        {pathCoordinates &&
          pathCoordinates.length > 0 &&
          pathCoordinates.map((item, index) => (
            <Marker
              key={index}
              title={`welfare lat:${item?.lat} long:${item?.lng} & timing: ${item?.time}`}
              position={item}
              icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
              }}
            />
          ))}
              {paths &&
          paths.length > 0 &&
          paths.map((item, index) => (
            <Polyline
              key={index}
              path={item.map((coord) => coord || [])}
              strokeColor="#0000FF"
              strokeOpacity={0.8}
              strokeWeight={5}
            />
          ))}

        {addressCoordinates && (
          <Marker
            title={`${participantHomeAdress}`}
            name={`${participantHomeAdress}`}
            position={addressCoordinates}
            icon={{
              url: homeIcon,
              scaledSize: iconSize,
            }}
          />
        )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(MapComponent);

// import React, { useEffect, useState } from "react";
// import { Map, GoogleApiWrapper, Marker, Polyline } from "google-maps-react";
// import homeIcon from "assets/rostering/house.png";
// import carIcon from "assets/rostering/car.png";
// import endIcon from "assets/rostering/end.png";


// const MapComponent = ({ google, selectedTimeSheet }) => {
//   const [addressCoordinates, setAddressCoordinates] = useState(null);

//   const participantHomeAdress = `${
//     selectedTimeSheet?.participant?.street_address ?? ""
//   } ${selectedTimeSheet?.participant?.suburb ?? ""} ${
//     selectedTimeSheet?.participant?.state ?? ""
//   }`;

//   useEffect(() => {
//     const getCoordinatesFromAddress = async () => {
//       if (participantHomeAdress) {
//         const geocoder = new google.maps.Geocoder();
//         geocoder.geocode(
//           { address: participantHomeAdress },
//           (results, status) => {
//             if (status === "OK" && results && results[0]) {
//               const { lat, lng } = results[0].geometry.location;
//               setAddressCoordinates({ lat: lat(), lng: lng() });
//             } else {
//               console.error("Error fetching coordinates from address");
//             }
//           }
//         );
//       }
//     };

//     getCoordinatesFromAddress();
//   }, []);

//   const mapStyles = {
//     width: "100%",
//     height: "100%",
//   };
//   const iconSize = {
//     width: 32,
//     height: 32,
//   };

//   const multiplePolyline =
//     selectedTimeSheet &&
//     selectedTimeSheet?.shiftTravelings.length > 0 &&
//     selectedTimeSheet?.shiftTravelings;

//   const paths = [];
//   let currentSegment = [];
//   let startEncountered = false;

//   for (let i = 0; i < multiplePolyline.length; i++) {
//     const item = multiplePolyline[i];

//     if (item.action === "start") {
//       // If it's a "start" action, start a new segment
//       currentSegment = [formatItem(item)];
//       startEncountered = true;
//     } else if (item.action === "stop") {
//       // If it's a "stop" action, add the current item to the segment and push the segment to the result
//       currentSegment.push(formatItem(item));
//       paths.push([...currentSegment]);
//       currentSegment = [];
//       startEncountered = false;
//     } else {
//       // For other actions, add the item to the current segment
//       currentSegment.push(formatItem(item));
//     }

//     // Check if it's the last item and it has an action type of null
//     if (i === multiplePolyline.length - 1) {
//       // If a "start" has been encountered, consider the last item as a "stop"
//       if (startEncountered) {
//         currentSegment.push(formatItem(item));
//         currentSegment.pop();
//         paths.push([...currentSegment]);
//       }
//     }
//   }

//   // Function to format item
//   function formatItem(item) {
//     return {
//       lat: parseFloat(item.lat),
//       lng: parseFloat(item.lng),
//       action: item.action,
//     };
//   }

//   // Calculate bounds for all markers to display zoom level
//   const bounds = new google.maps.LatLngBounds();
//   bounds.extend(
//     new google.maps.LatLng(
//       selectedTimeSheet?.shift_start_lat,
//       selectedTimeSheet?.shift_start_lng
//     )
//   );
//   bounds.extend(
//     new google.maps.LatLng(
//       selectedTimeSheet?.shift_end_lat,
//       selectedTimeSheet?.shift_end_lng
//     )
//   );
//   bounds.extend(
//     new google.maps.LatLng(addressCoordinates?.lat, addressCoordinates?.lng)
//   );
//   paths.forEach((path) => {
//     path.forEach((coordinate) => {
//       bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
//     });
//   });

//   return (
//     <div style={{ position: "relative", width: "100%", height: "50vh" }}>
//       <Map
//         google={google}
//         style={mapStyles}
//         initialCenter={{
//           lat: selectedTimeSheet?.shift_start_lat,
//           lng: selectedTimeSheet?.shift_start_lng,
//         }}
//         zoom={13}
//         // bounds={bounds}
//       >
//         <Marker
//           title="Shift Start Location"
//           name="Shift Start Location"
//           position={{
//             lat: selectedTimeSheet?.shift_start_lat,
//             lng: selectedTimeSheet?.shift_start_lng,
//           }}
//           icon={"https://maps.google.com/mapfiles/ms/icons/green-dot.png"}
//         />

//         <Marker
//           title="Shift End Location"
//           name="Shift End Location"
//           position={{
//             lat: selectedTimeSheet?.shift_end_lat,
//             lng: selectedTimeSheet?.shift_end_lng,
//           }}
//         />
//         {paths &&
//           paths.length > 0 &&
//           paths.map((item, index) => (
//             <Polyline
//               key={index}
//               path={item.map((coord) => coord || [])}
//               strokeColor="#0000FF"
//               strokeOpacity={0.8}
//               strokeWeight={5}
//             />
//           ))}

//         {paths &&
//           paths.length > 0 &&
//           paths.map((item, index) =>
//             item?.map((coordinate, elementIndex) => (
//               <Marker
//                 key={elementIndex}
//                 title={`${
//                   elementIndex === 0
//                     ? "Traveling start point"
//                     : elementIndex === item.length - 1
//                     ? "Traveling end point"
//                     : `lat:${coordinate.lat} long:${coordinate.lng}`
//                 }`}
//                 position={coordinate}
//                 icon={{
//                   url:
//                     elementIndex === 0
//                       ? carIcon
//                       : elementIndex === item.length - 1
//                       ? endIcon
//                       : "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
//                   scaledSize: iconSize,
//                 }}
//               />
//             ))
//           )}

//         {addressCoordinates && (
//           <Marker
//             title={`${participantHomeAdress}`}
//             name={`${participantHomeAdress}`}
//             position={addressCoordinates}
//             icon={{
//               url: homeIcon,
//               scaledSize: iconSize,
//             }}
//           />
//         )}
//       </Map>
//     </div>
//   );
// };

// export default GoogleApiWrapper({
//   apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
// })(MapComponent);
