import React, { useState, useMemo, useEffect } from "react";
import CircularLoader from "../../common/CircularLoader/CircularLoader";
import {
  apiGetRequest,
  addFiltersAndPagination,
} from "../../../helpers/Requests";
import { BiPlus } from "react-icons/bi";
import PaginationComponent from "../../common/Pagination/index";
import CustomButton from "../../common/Button/CustomButton";
import Search from "../../common/Search/Search";
import {
  emptyPaginationObj,
  PARENT_FORM_CATEGORY_SLUG,
  PARTICIPANT_LIST_LIMIT,
  SORT_TYPE,
} from "../../../Constants/constant.js";
import {
  getPaginationObj,
  getUrlRole,
  selectedTabState,
} from "../../../Utils/utils.js";
import ModalSkeleton from "../ModalSkeleton";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getSelectedTabSelector } from "../../../store/selector/auth.selector";
import ActionsForSubmitPtForms from "./ActionsForSubmitPtForms";

const CategoryFormsListing = (props) => {
  const { buttonText, slug, particpantUuid, role = null } = props;
  const [toggleState, setToggleState] = useState(selectedTabState.get());
  const selectedTabSelector = useSelector(getSelectedTabSelector);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState(null);
  const [supportPlans, setSupportPlans] = React.useState([]);
  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );

  useEffect(() => {
    const currentTab = selectedTabState.get();
    if (
      currentTab &&
      currentTab.hasOwnProperty("isModalOpen") &&
      currentTab.hasOwnProperty("slug") &&
      currentTab.slug === slug
    ) {
      setIsModalOpen(true);
      fetchFormTempletes(`${slug}`, { page: 1 });
      delete currentTab.isModalOpen;
      selectedTabState.save(JSON.stringify(currentTab));
      setSearch(null);
    }
  }, [selectedTabState.get(), slug, selectedTabSelector]);

  const fetchFormTempletes = async (slug, filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: PARTICIPANT_LIST_LIMIT });
    setRequest({ ...request, isRequested: true });
    try {
      let url = null;

      if (role === "worker") {
        url = addFiltersAndPagination(`worker/hrm/form-templates-by`, filters);
      } else {
        url = addFiltersAndPagination(
          `${getUrlRole()}/hrm/form-templates-by`,
          filters
        );
      }
      const urlHrm = addFiltersAndPagination(
        `${getUrlRole()}/hrm/form-templates-by`,
        filters
      );

      let res = await apiGetRequest(
        `${role === "hrm" ? urlHrm : url}&cat_slug=${slug}`
      );
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        if (slug === PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG) {
          setSupportPlans(res?.data?.data?.forms);
        } else setSupportPlans(res?.data?.data?.forms);
        setPaginationObj(
          getPaginationObj(res.data.data.forms, res.data.data.query_params)
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSearch(null);
    if (toggleState.hasOwnProperty("isModalOpen")) {
      const obj = {
        name: toggleState.name,
        id: toggleState.id,
        children: toggleState.children,
      };
      setToggleState(obj);
      selectedTabState.save(JSON.stringify(obj));
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    fetchFormTempletes(`${slug}`);
  };

  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order)
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
  }, [paginationObj]);

  useEffect(() => {
    const obj = { page: 1 };
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (!search) handleFetch({ ...obj });
  }, []);

  const handleFetch = (obj) => {
    if (paginationObj.page) Object.assign(obj, { page: paginationObj.page });
    if (paginationObj.filter && !obj.hasOwnProperty("status")) {
      Object.assign(obj, { filter: paginationObj.filter });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, { page: 1, search: obj.search });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.status !== "both" && obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filter: {
          status: [obj.status],
        },
      });
    }
    if (obj.hasOwnProperty("page")) Object.assign(obj, { page: obj.page });
    if (isModalOpen) fetchFormTempletes(`${slug}`, { ...obj });
  };

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
        }),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const supportPlanToMap = useMemo(() => {
    if (
      supportPlans &&
      supportPlans.data &&
      slug === PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG
    ) {
      return supportPlans.data.sort((a, b) =>
        a.sequence < b.sequence ? -1 : 1
      );
      // .sort((a, b) => (a.is_mandatory === "Mandatory" ? -1 : 1));
    } else if (supportPlans && supportPlans.data) {
      return supportPlans.data;
    }
    return [];
  }, [supportPlans]);

  return (
    <>
      <CustomButton
        label={buttonText}
        Icon={<BiPlus />}
        clickHandler={openModal}
      />
      <ModalSkeleton
        isOpen={isModalOpen}
        closeModal={() => closeModal()}
        cssClass='w-[60%]'
        title={
          slug === PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG
            ? "Risk Evaluation"
            : buttonText
        }
      >
        <>
          <div className='flex justify-between items-center mt-3 mb-5'>
            <div className='w-1/3 md:w-3/12 lg:w-3/12'>
              {/* <SelectOption
                            isDisabled={request.isRequested}
                            isSearch={false}
                            selectedOption={selectedStatus}
                            handleChange={(e) => {
                              setSelectedStatus(e);
                              handleFetch({ status: e.value });
                            }}
                            data={StatusOptions}
                            placeHolder="Filter Status"
                          /> */}
            </div>
            <Search setSearch={setSearch} search={search} />
          </div>
          <div className='shadow ring-1 ring-black ring-opacity-5 md:rounded-lg '>
            {request.isRequested ? (
              <div className='flex justify-center items-center custom-width-min'>
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <table className='min-w-full divide-y divide-gray-300 mb-5 capitalize'>
                <thead className='bg-blue-50 text-sm font-semibold text-gray-900 sticky top-[-13px] capitalize'>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6'
                    >
                      Title
                    </th>
                    <th
                      scope='col'
                      className='px-3 py-3.5 text-left text-sm font-semibold '
                    >
                      Category
                    </th>
                    {(slug === PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG ||
                      slug === PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG) && (
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold '
                      >
                        Sub Category
                      </th>
                    )}
                    {/* {slug ===
                      PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG && (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold "
                      >
                        Priority
                      </th>
                    )} */}
                    <ActionsForSubmitPtForms
                      role='worker'
                      slug={slug}
                      view={"table_header"}
                    />
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 odd-bg-red-400 even-bg-white'>
                  {supportPlanToMap.map((item) => {
                    return (
                      <tr
                        key={item.uuid}
                        className='even:bg-white odd:bg-gray-50'
                      >
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6'>
                          <div className='flex items-center'>
                            <div className=''>
                              <div className='capitalize font-medium text-gray-900'>
                                {item.title}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          <div className='text-gray-900'>
                            {item.category_name}
                          </div>
                        </td>
                        {(slug ===
                          PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.SLUG ||
                          slug ===
                            PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG) && (
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                            <div className='text-gray-900'>
                              {item.sub_category_name
                                ? item.sub_category_name
                                : "-"}
                            </div>
                          </td>
                        )}
                        {/* {slug ===
                          PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT
                            .SLUG && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              {item.is_mandatory ? "Mandatory" : "Optional"}
                            </div>
                          </td>

                        )} */}
                        <ActionsForSubmitPtForms
                          role='worker'
                          particpantUuid={particpantUuid}
                          item={item}
                          slug={slug}
                          view={"table_data"}
                        />
                      </tr>
                    );
                  })}
                  {supportPlans?.data?.length === 0 ? (
                    <tr>
                      <td colSpan={7} className='py-6 text-center text-red-600'>
                        No data found !
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            )}
          </div>
          <div className='bg-white max-w-7xl mt-5 pb-10'>
            <PaginationComponent
              page={paginationObj.page}
              activeListLength={supportPlanToMap ? supportPlanToMap : []}
              limit={PARTICIPANT_LIST_LIMIT}
              totalList={paginationObj.total}
              handlePagination={(e) => {
                const data = _.cloneDeep(paginationObj);
                const obj = paginationObj;
                obj.page = e;
                setPaginationObj({ ...obj });
                if (e !== data.page) {
                  handleFetch({});
                }
              }}
              totalPages={paginationObj.totalPages}
            />
          </div>
        </>
      </ModalSkeleton>
    </>
  );
};

export default CategoryFormsListing;
