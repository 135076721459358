import React from "react";
import { Switch } from "@headlessui/react";

const SwitchInput = (props) => {
  const {
    handleChange,
    checked,
    label,
    subLabel,
    cssClass,
    isDisabled = false,
  } = props;
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Switch.Group as='div' className={`flex items-center ${cssClass} `}>
      <Switch
        disabled={isDisabled}
        checked={checked}
        onChange={handleChange}
        className={classNames(
          checked ? "bg-blue-600" : "bg-gray-200",
          "relative inline-flex items-center flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
          isDisabled && "cursor-auto"
        )}
      >
        <span
          aria-hidden='true'
          className={classNames(
            checked ? "translate-x-5" : "translate-x-0",
            "h-full w-[55%] pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
      <Switch.Label as='span' className='ml-3'>
        {label && <p className='text-sm font-medium text-gray-900'>{label}</p>}
        {subLabel && <span className='text-sm text-gray-500'>{subLabel}</span>}
      </Switch.Label>
    </Switch.Group>
  );
};

export default SwitchInput;
