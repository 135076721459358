import CircularLoader from 'components/common/CircularLoader/CircularLoader';
import React, { useEffect, useState } from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const NdisNews = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [timelineHeight, setTimelineHeight] = useState(450);

  useEffect(() => {
    // Create a function to adjust the Twitter timeline width and height on window resize
    const handleResize = () => {
      const twitterTimeline = document.getElementById('twitter-timeline');
      if (twitterTimeline) {
        // Calculate the desired width based on the parent container's width
        const parentWidth = twitterTimeline.parentElement.clientWidth;
        const maxWidth = Math.min(520, parentWidth); // Adjust the max width as needed
        twitterTimeline.style.width = `${maxWidth}px`;

        // Calculate the desired height based on the available space
        const parentHeight = window.innerHeight; // Adjust this as needed
        const maxTimelineHeight = parentHeight - 100; // Adjust this as needed
        setTimelineHeight(Math.min(250, maxTimelineHeight));
      }
    };

    // Add an event listener for window resize
    window.addEventListener('resize', handleResize);

    // Call the resize handler once initially to set the initial width and height
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const url = 'https://twitter.com/NDIS?ref_src=twsrc%5Etfw';
  const screenNameMatch = url.match(/twitter\.com\/(\w+)/);

  if (screenNameMatch && screenNameMatch.length === 2) {
    const screenName = screenNameMatch[1];

    return (
      <div className="w-full">
        {isLoading && (
          <div className="flex justify-center items-center">
            <CircularLoader classloader="loader-l" />
          </div>
        )}
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName={screenName}
          options={{ height: timelineHeight, theme: 'light' }}
          id="twitter-timeline" // Add an ID to the Twitter timeline for styling
          onLoad={() => setIsLoading(false)} // Set isLoading to false when the timeline loads
        />
      </div>
    );
  } else {
    return <div>Error: Invalid Twitter URL</div>;
  }
};

export default NdisNews;

// export default NdisNews;


// import React from 'react'
// import { TwitterTimelineEmbed,TwitterFollowButton } from 'react-twitter-embed';


// const NdisNews = () => {
//     // Extract the Twitter screen name from the URL
//     const url = 'https://twitter.com/NDIS?ref_src=twsrc%5Etfw';
//     const screenNameMatch = url.match(/twitter\.com\/(\w+)/);
  
//     // Check if a match is found
//     if (screenNameMatch && screenNameMatch.length === 2) {
//       const screenName = screenNameMatch[1];
  
//       return (
     
//         <div className='w-full'>
//           <TwitterTimelineEmbed
//             sourceType="profile"
//             screenName={screenName} // Use the extracted screen name
//             options={{ maxWidth:"100%", height: 400, theme: 'light' }}
//           />
//         </div>
       
//       );
//     } else {
//       return <div>Error: Invalid Twitter URL</div>;
//     }
// }

// export default NdisNews
