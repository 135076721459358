import React from 'react';
import {BsArrowUpShort} from 'react-icons/bs'
const stats = [
    { name: 'Total Forms Submitted', stat: '71,897' },
    { name: 'Last 7 days', stat: '58' },
    { name: 'Incomplete Forms', stat: '24' },
  ]
  
   const ActivityStat = () => {
    return (
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {stats.map((item) => (
            <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 flex justify-between items-end">
              <div>
              <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
              </div>
              <button className='rounded-full bg-green-50 text-green-700 flex items-center space-x-1 px-2 text-sm font-medium'>
                  <BsArrowUpShort/>
                  <span>20%</span>
                </button>
            </div>
          ))}
        </dl>
      </div>
    )
  }
  
  export default ActivityStat