import React, { useContext, useEffect, useState } from "react";
import ModalSkeleton from "../../../../components/Modals/ModalSkeleton";
import { useForm } from "react-hook-form";
import SelectOption from "../../../../components/common/Select/Select";
import CustomButton from "../../../../components/common/Button/CustomButton";
import {
  FUNDING_NOTES_TYPES,
  LOADER_STATUS,
} from "../../../../Constants/constant";
import FileUploadInput from "../../../../components/common/FileUpload/FileUploadInput";
import { textTruncate } from "../../../../Utils/utils";
import { LoginContext } from "helpers/LoginContext";

const FundingNotesModal = (props) => {
  const {
    openModal,
    closeModal,
    funding,
    participant,
    editFundingNotes,
    storeFundingNotesLoading,
    storeFundingNotesError,
    createFundingNotes,
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [storeRenderLoader, setStoreRenderLoader] = useState(LOADER_STATUS.NA);
  const [type, setType] = useState(null);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");

  useEffect(() => {
    if (type !== null) {
      setValue("type", type.value);
    }
  }, [type]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (editFundingNotes) {
      setType({
        label: editFundingNotes.type,
        value: editFundingNotes.type,
      });
      if (editFundingNotes.file_name !== null) {
        setFileName(editFundingNotes.file_name);
      }
      if (editFundingNotes.file) {
        setFile(editFundingNotes.file);
      }
      reset({
        type: editFundingNotes.type,
        note: editFundingNotes.note,
      });
    }
  }, [editFundingNotes]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    let formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("type", data.type);
    formData.append("note", data.note);
    formData.append("funding_id", funding.id);
    formData.append("pt_id", participant ? participant.id : "1");
    if (editFundingNotes) {
      formData.append("funding_note_id", editFundingNotes.uuid);
    }
    setStoreRenderLoader(LOADER_STATUS.PENDING);
    createFundingNotes({ formData, isUpdate: editFundingNotes ? true : false });
  };

  useEffect(() => {
    if (
      !storeFundingNotesLoading &&
      storeRenderLoader !== LOADER_STATUS.NA &&
      storeFundingNotesError.length <= 0
    ) {
      handleClose();
    }
  }, [storeFundingNotesLoading]);

  const handleClose = () => {
    reset();
    closeModal();
  };

  const modalFooter = () => (
    <CustomButton
      showLoading={storeFundingNotesLoading}
      isDisabled={storeFundingNotesLoading || isUserGracePeriodOver}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("fundingNotesButtonModal");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
      label={editFundingNotes ? "Update" : "Submit"}
    />
  );

  return (
    <ModalSkeleton
      title={editFundingNotes ? "Update Funding Notes" : "Create Funding Notes"}
      modalFooter={modalFooter()}
      isOpen={openModal}
      closeModal={handleClose}
      cssClass='w-[50%]'
    >
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500 mb-2'>
          Please enter the following details to
          {editFundingNotes ? " update" : " create"} funding notes
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <div className='hidden'>
                <select
                  {...register("type", {
                    required: true,
                  })}
                  name='type'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Type
              </label>
              <SelectOption
                cssClass='mt-1'
                isSearch={false}
                data={FUNDING_NOTES_TYPES}
                selectedOption={type}
                handleChange={setType}
                // placeHolder={"Select Type"}
              />
              {errors?.type?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <label className='block text-sm font-medium text-gray-700'>
                Notes
              </label>
              <textarea
                rows={3}
                name='note'
                id='note'
                className='overflow-y-auto px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register("note", { required: true })}
              />
              {errors?.note?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <label
                htmlFor='total_budget_amount'
                className='block text-sm font-medium text-gray-700'
              >
                Attachments
              </label>
              <div className='mt-1'>
                <FileUploadInput
                  type='pdf'
                  handleFile={(e) => setFile(e)}
                  size={"2"}
                  file={file}
                  handleDelete={() => setFile("")}
                  updateFileName={textTruncate(fileName, 30, 29)}
                  title={fileName}
                />
              </div>
            </div>
          </div>
          <button
            id='fundingNotesButtonModal'
            type='submit'
            className='hidden sr-only'
          />
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default FundingNotesModal;
