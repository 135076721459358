import { connect } from "react-redux";
import {
  getCatalogServicesSubCategoryRequest,
  getPlanCategoriesRequest,
  manaulDeliveriesFetchRequest,
  manualDeliveredServiceCreateRequest,
} from "../../../../store/actions/cos.actions";
import {
  getManualDeliveredServiceStoreError,
  getManualDeliveredServiceStoreLoader,
  getManualDeliveries,
  getManualDeliveriesFetchLoader,
  getPlanCatagories,
  getSelectedFunding,
  getServiceCatalogStates,
} from "../../../../store/selector/cos.selector";
import { getSelectedParticipant } from "../../../../store/selector/participant.selector";
import ManualDelivery from "./ManualDelivery";

const mapDispatchToProps = {
  getPlanCatagories: getPlanCategoriesRequest,
  getCatalogServicesSubCategory: getCatalogServicesSubCategoryRequest,
  manualDeliveredCreate: manualDeliveredServiceCreateRequest,
  getManaulDeliveriesList: manaulDeliveriesFetchRequest,
};

const mapStateToProps = (state) => {
  const selectedFunding = getSelectedFunding(state);
  const participant = getSelectedParticipant(state);
  const PlanCatagories = getPlanCatagories(state);
  const catalogStates = getServiceCatalogStates(state);
  const storeManualDeliveryLoader = getManualDeliveredServiceStoreLoader(state);
  const storeManualDeliveryError = getManualDeliveredServiceStoreError(state);
  const fetchManualDeliveriesLoader = getManualDeliveriesFetchLoader(state);
  const manualDeliveries = getManualDeliveries(state);

  return {
    participant,
    selectedFunding,
    PlanCatagories,
    catalogStates,
    storeManualDeliveryLoader,
    fetchManualDeliveriesLoader,
    manualDeliveries,
    storeManualDeliveryError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualDelivery);
