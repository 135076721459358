import React, { useEffect, useState, useMemo } from "react";
import {
  FUNDING_SETUP_LIMIT,
  emptyPaginationObj,
  LOADER_STATUS,
} from "../../../../Constants/constant";
import { getPaginationObj } from "../../../../Utils/utils";
import PaginationComponent from "../../../../components/common/Pagination/index";
import TableHeader from "../../../../components/common/Table/TableHeader";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import FundingItem from "./fundingItem";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { roleAccess } from "helpers/RolesPermission";

const FundingSetupTable = (props) => {
  const {
    participantFundings,
    participant,
    isLoading,
    getParticipantFundingsSetup,
    participantOnBoarding,
    setEditFundingData,
  } = props;

  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);

  const [paginationObj, setPaginationObj] = useState(
    participantFundings
      ? getPaginationObj(participantFundings)
      : emptyPaginationObj
  );

  useEffect(() => {
    if (!isLoading && renderLoading === LOADER_STATUS.PENDING) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
    }
  }, [isLoading]);

  useEffect(() => {
    if (participantFundings) {
      setPaginationObj({
        ...getPaginationObj(participantFundings),
      });
    }
  }, [participantFundings]);

  const fundingToMap = useMemo(() => {
    if (participantFundings && participantFundings.data) {
      return participantFundings.data;
    }
    return [];
  }, [participantFundings]);

  useEffect(() => {
    setRenderLoading(LOADER_STATUS.PENDING);
    if (participantFundings) {
      setPaginationObj(getPaginationObj(participantFundings));
    }
    if (!participantFundings) fetchFundingSetups({ page: 1 });
  }, []);

  useEffect(() => {
    if (
      participantFundings &&
      participantFundings.current_page !== paginationObj.page
    ) {
      fetchFundingSetups({ page: paginationObj.page });
    }
  }, [paginationObj.page]);

  const fetchFundingSetups = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: FUNDING_SETUP_LIMIT });
    Object.assign(filters, { pt_id: participant.uuid || 1 });
    if (renderLoading === LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.PENDING);
    }
    getParticipantFundingsSetup(filters);
  };

  return (
    <div className='px-4 py-4'>
      <div className='flex flex-col'>
        <div className=' overflow-x-auto'>
          <div className='inline-block min-w-full align-middle '>
            <div className='overflow-hidden border md:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead className='bg-gray-50 capitalize'>
                  <tr>
                    <TableHeader isSort={false} label={"Funding Id"} />
                    <TableHeader
                      isSort={false}
                      label={"Start Date"}
                      cssClass='px-0'
                    />
                    <TableHeader isSort={false} label={"End Date"} />
                    <TableHeader
                      isSort={false}
                      label={"Total Funding"}
                      cssClass='px-0'
                    />
                    <TableHeader
                      isSort={false}
                      label={"Total Allocated"}
                      cssClass='px-0'
                    />
                    <TableHeader
                      isSort={false}
                      label={"Total Delivered"}
                      cssClass='px-0'
                    />
                    {/* <TableHeader 
                      isSort={false}
                      sortName="claimed"
                      //   flipSort={flipSort}
                      //   sortBy={sortBy}
                      //   sortDescending={sortDescending}
                      label={"Claimed"}
                      cssClass="px-0"

                    />
                    <TableHeader 
                      isSort={false}
                      sortName="Unclaimed"
                      //   flipSort={flipSort}
                      //   sortBy={sortBy}
                      //   sortDescending={sortDescending}
                      label={"Unclaimed"}
                      cssClass="px-0"

                    /> */}
                    <TableHeader
                      isSort={false}
                      label={"Unallocated"}
                      cssClass='px-0'
                    />
                    <TableHeader
                      isSort={false}
                      label={"Status"}
                      cssClass='px-0'
                    />
                    {roleAccess(
                      [],
                      PERMISSION_KEYS.PARTICIPANT,
                      PERMISSION_KEYS_CHILDREN.PARTICIPANT.FUNDING.UPDATE
                    ) && (
                      <TableHeader
                        isSort={false}
                        label={"Actions"}
                        cssClass='px-0'
                      />
                    )}
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200 bg-white'>
                  {isLoading ? (
                    <tr>
                      <td colSpan={11} className='bg-gray-50 py-2'>
                        <CircularLoader
                          classes='flex justify-center items-center'
                          classloader='loader-l'
                        />
                      </td>
                    </tr>
                  ) : fundingToMap.length > 0 ? (
                    fundingToMap.map((fund, index) => (
                      <FundingItem
                        key={index}
                        fund={fund}
                        index={index}
                        participant={participant}
                        participantOnBoarding={participantOnBoarding}
                        setEditFundingData={setEditFundingData}
                      />
                    ))
                  ) : (
                    renderLoading !== LOADER_STATUS.NA &&
                    fundingToMap.length === 0 && (
                      <tr>
                        <td
                          colSpan={11}
                          className='py-6 text-center text-red-600'
                        >
                          No data found !
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {fundingToMap.length > 0 && (
        <div className='bg-white py-4'>
          <PaginationComponent
            page={paginationObj.page}
            activeListLength={fundingToMap}
            limit={FUNDING_SETUP_LIMIT}
            totalList={
              paginationObj.totalPages === 1
                ? fundingToMap.length
                : paginationObj.total
            }
            handlePagination={(e) => {
              const obj = paginationObj;
              obj.page = e;
              setPaginationObj({ ...obj });
              // handleFetch({}, sortBy);
            }}
            totalPages={paginationObj.totalPages}
          />
        </div>
      )}
    </div>
  );
};

export default FundingSetupTable;
