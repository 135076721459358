import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { apiDeleteRequest } from "helpers/Requests";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { getUrlRole } from "Utils/utils";

const DeleteExterenalServiceModal = (props) => {
  const {
    handleClose,
    open,
    setOpen,
    isUserGracePeriodOver,
    selectedServiceProvider,
    refreshServiceProviderList,
  } = props;
  const { uuid } = selectedServiceProvider;
  const navigate = useNavigate();
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const handleDelete = async () => {
    setRequest({ isRequested: true, isSuccess: false, isFailed: false });
    setDeleteLoader(true);
    try {
      const response = await apiDeleteRequest(
        `/cos/external-sp-delete/${uuid}`
      );
      setRequest({ isRequested: true, isSuccess: false, isFailed: false });
      if (response.status === 200) {
        toast.success("External Sp deleted successfully!");
        handleClose();

        refreshServiceProviderList();
      }
    } catch (error) {
      setRequest({ isRequested: true, isSuccess: false, isFailed: false });
      console.error("Failed to delete External Sp:", error);
    }
  };

  const modalFooter = () => (
    <>
      <CustomButton
        showLoading={request.isRequested}
        isDisabled={request.isRequested || isUserGracePeriodOver}
        label='Yes, delete External SP'
        clickHandler={handleDelete}
        variant='danger'
      />
      <CustomButton label='No, cancel changes' onClick={handleClose} />
    </>
  );

  return (
    <>
      <ModalSkeleton
        modalFooter={modalFooter()}
        isOpen={open}
        closeModal={() => setOpen(false)}
        cssClass='w-[55%]'
        title={"Delete External Service Provider"}
      >
        <div>
          <p className='text-gray-700'>
            Are you sure you want to delete this Service Provider?
          </p>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default DeleteExterenalServiceModal;
