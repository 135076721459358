import React from "react";
import SupportPlanPage from "../../../ServiceProvider/Participant/OverviewPage/SupportPlanPage";

const ProfileDetails = ({ participantInfo, setToggleState }) => {
  return (
    <SupportPlanPage
      setToggleState={setToggleState}
      participantsInfo={participantInfo}
    />
  );
};

export default ProfileDetails;
