import React, { Fragment, useState } from "react";
import { DotsHorizontalIcon, CheckIcon } from "@heroicons/react/outline";
import MasterCard from "assets/BillingPayments/master.png";
import Visa from "assets/BillingPayments/visa.png";
import AmericanExpress from "assets/BillingPayments/american-express.png";
import unionPay from "assets/BillingPayments/unionpay1.png";
import JCB from "assets/BillingPayments/jcb.svg";
import Eftpos from "assets/BillingPayments/eftpos.png";
import {
  capitalizeFirstLetter,
  classNames,
  parseBooleanVal,
} from "Utils/utils";
import { Menu, Transition } from "@headlessui/react";
import api from "store/services/sp.services";
import Loader from "components/common/Loader/Loader";
import { authenticatedUser } from "store/actions/auth.actions";
import GetItemFromLocalstorage from "Utils/GetItemFromLocalstorage";
import { useDispatch } from "react-redux";
import { stripeDeleteCardLocally } from "store/actions/sp.actions";
import { toast } from "react-toastify";

const CardItem = (props) => {
  const { card, isDefaultCard } = props;
  const dispatch = useDispatch();

  const [makeDefaultLoader, setMakeDefaultLoader] = useState(false);
  const [dettachCardLoader, setDettachCardLoader] = useState(false);

  const getCardIcon = () => {
    switch (card?.card?.brand) {
      case "mastercard":
        return MasterCard;
      case "visa":
        return Visa;
      case "amex":
        return AmericanExpress;
      case "unionpay":
        return unionPay;
      case "jcb":
        return JCB;
      case "Eftpos Australia":
        return Eftpos;
      default:
        return "";
    }
  };

  const HandleMakeCardDefault = async (payment_method_id) => {
    try {
      setMakeDefaultLoader(true);
      const response = await api.stripeMakeDefaultCard({ payment_method_id });
      if (response?.payment_setting) {
        const user = await GetItemFromLocalstorage("user");
        if (user && response?.payment_setting?.default_payment_method_id) {
          await (user.is_default_card =
            response.payment_setting.default_payment_method_id);
        }
        if (
          user &&
          response?.payment_setting?.default_payment_method_id &&
          user?.payment_setting?.default_payment_method_id !==
            response?.payment_setting?.default_payment_method_id
        ) {
          await (user.is_default_card =
            response.payment_setting.default_payment_method_id);
          await (user.payment_setting = response.payment_setting);
        }
        localStorage.setItem("user", JSON.stringify(user));
        handleClickOutside(payment_method_id);
        dispatch(authenticatedUser(user));
        toast.success("Card Mark Default Successfully!");
      }
      setMakeDefaultLoader(false);
    } catch (err) {
      setMakeDefaultLoader(false);
      console.log("err", err);
    }
  };

  const HandleDettachCard = async (payment_method_id) => {
    try {
      setDettachCardLoader(true);

      const response = await api.stripeDettachCard({ payment_method_id });

      if (response?.payment_setting) {
        const user = await GetItemFromLocalstorage("user");
        if (user && response?.payment_setting?.default_payment_method_id) {
          await (user.is_default_card =
            response.payment_setting.default_payment_method_id);
        }
        if (
          user &&
          response?.payment_setting?.default_payment_method_id &&
          user?.payment_setting?.default_payment_method_id !==
            response?.payment_setting?.default_payment_method_id
        ) {
          await (user.is_default_card =
            response.payment_setting.default_payment_method_id);
          await (user.payment_setting = response.payment_setting);
        }
        localStorage.setItem("user", JSON.stringify(user));
        handleClickOutside(payment_method_id);
        dispatch(authenticatedUser(user));
        dispatch(stripeDeleteCardLocally(payment_method_id));
        toast.success("Card Detach Successfully!");
      }

      setDettachCardLoader(false);
    } catch (err) {
      setDettachCardLoader(false);
      console.log("err", err);
      toast.error(err || "Something went wrong!");
    }
  };

  const handleClickOutside = (id) => {
    const component = document.getElementsByClassName(
      `buttonForCardItem-${id}`
    );
    if (component && component?.length > 0) {
      component[0].click();
    }
  };

  return (
    <div className='relative flex items-center justify-between rounded-xl border-2 border-layer-3 bg-layer-0 p-4'>
      <div className='flex space-x-4'>
        <div className='relative flex h-12 w-18 items-center justify-center rounded-xl bg-layer-3 px-2'>
          <img
            src={getCardIcon()}
            alt={`${card?.card?.branch} logo`}
            className='w-full w-full w-11 h-8 rounded-md'
          />
          {isDefaultCard && (
            <div className='absolute right-[0px] top-[-3px] flex h-4 w-4 items-center justify-center rounded-xl bg-gradient'>
              <CheckIcon className='h-3 w-3 text-heading' />
            </div>
          )}
        </div>
        <div>
          {card?.metadata?.card_name && (
            <p
              className='text-base font-medium capitalize'
              style={{ height: "auto" }}
            >
              {card?.metadata?.card_name}
            </p>
          )}
          <h3 className='text-base font-semibold text-heading'>
            {`${capitalizeFirstLetter(card.card.branch)} •••• •••• •••• ${
              card.card.last4
            }`}
          </h3>
          <div className='text-sm'>{`Expires ${card.card.exp_month}/${card.card.exp_year}`}</div>
        </div>
      </div>
      {isDefaultCard && (
        <div>
          <span className='absolute right-[10px] top-[10px] rounded-full bg-indigo-100  text-xs font-medium text-[#027A48] px-2 py-1'>
            Default
          </span>
        </div>
      )}

      {!isDefaultCard && (
        <Menu as='div'>
          <div>
            <Menu.Button
              className={classNames(
                `buttonForCardItem-${card.id}`,
                "inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 "
              )}
            >
              <DotsHorizontalIcon className='h-5 w-5' />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='z-[9] origin-top absolute right-[10px] mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'>
              <div className='py-1 cursor-pointer'>
                <Menu.Item>
                  {({ active }) => (
                    <>
                      <span
                        tabIndex={0}
                        role='button'
                        onClick={() =>
                          !parseBooleanVal(isDefaultCard) &&
                          HandleMakeCardDefault(card.id)
                        }
                        className={classNames(
                          "items-center flex gap-x-2 ",
                          "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                          "block px-4 py-2 text-sm",
                          isDefaultCard &&
                            "bg-gray-100 text-gray-900 cursor-default"
                        )}
                      >
                        Make it Default
                        {makeDefaultLoader && (
                          <Loader isSmallView={true} cssClass={"text-black"} />
                        )}
                      </span>
                      <span
                        tabIndex={0}
                        role='button'
                        onClick={() => HandleDettachCard(card.id)}
                        className={classNames(
                          "items-center flex gap-x-2",
                          "hover:bg-gray-100 hover:text-gray-900 text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        Detach Card
                        {dettachCardLoader && (
                          <Loader isSmallView={true} cssClass={"text-black"} />
                        )}
                      </span>
                    </>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};

export default CardItem;
