import { AdjustmentsIcon } from "@heroicons/react/outline";
import { BiGroup, BiLineChartDown } from "react-icons/bi";
import { AiOutlineUserAdd } from "react-icons/ai";
import { ImFileText2 } from "react-icons/im";
import { RiDashboardLine } from "react-icons/ri";

export const SuperAdminNavigation = [
  {
    name: "Dashboard",
    icon: RiDashboardLine,
    current: false,
    to: "/dashboard",
  },
  {
    name: "Service Provider",
    icon: AiOutlineUserAdd,
    current: false,
    to: "/sp-list",
  },
  {
    name: "Plan Management",
    icon: AdjustmentsIcon,
    current: false,
    to: "#",
  },
  {
    name: "Forms Management CRM",
    icon: BiLineChartDown,
    current: false,
    to: "/forms-list",
  },
  {
    name: "Forms Management HRM",
    icon: BiLineChartDown,
    current: false,
    to: "/forms-list-hrm",
  },
  {
    name: "Logs",
    icon: ImFileText2,
    current: false,
    to: "#",
  },
  {
    name: "User Accounts",
    icon: BiGroup,
    current: false,
    to: "#",
  },
];
