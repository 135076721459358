import React from "react";
import moment from "moment";
import { BsPlusLg } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import TableHeader from "components/common/Table/TableHeader";


const ROCWeeklyTable = (props) => {
  const { item } = props;
  const [expandedRows, setExpandedRows] = React.useState([]);

  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(index)) {

      const indexToRemove = newExpandedRows.indexOf(index);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
 
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };

  return (
    <>
      <tr className='text-center even:bg-white odd:bg-gray-50'>
       
      <td
          className='flex justify-center items-center h-[50px]  cursor-pointer'
          onClick={() => handleRowClick(item.uuid)}
        >
          <div className='flex align-center cursor-pointer '>
            {expandedRows.includes(item.uuid) ? (
              <BiMinus className='mt-1 text-gray-600' />
            ) : (
              <BsPlusLg className='h-3 w-3 mt-1 text-gray-600' />
            )}
          </div>
        </td>

        <td className=' px-3 py-4 text-sm text-gray-500 text-start capitalize'>
          <div className='text-gray-500 capitalize'>
            {item?.first_name}{" "}
            {item?.last_name}
          </div>
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          {item?.overtime ? "Yes" :"No"}
        </td>
    
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
         {item?.total_overtime_seconds}
        </td>
      </tr>

      {expandedRows.includes(item.uuid) && (
        <tr className='text-center '>
          <td colSpan={10} className='px-3 py-4'>
            <table className='min-w-full divide-y divide-gray-300 border'>
              <thead>
                <tr>
                  <TableHeader
                    isSort={false}
                    label={"Shift Date"}
                    cssClass='text-center text-xs'
                  />
                  <TableHeader
                    isSort={false}
                    label={"Start Time"}
                    cssClass='text-center text-xs'
                  />
                  <TableHeader
                    isSort={false}
                    label={"End Time"}
                    cssClass='text-center text-xs'
                  />
                  {/* <TableHeader isSort={false} label={"Service Item Number"} cssClass="text-center text-xs" /> */}
                  <TableHeader
                    isSort={false}
                    label={"Shift Length"}
                    cssClass='text-center text-xs'
                  />
                
                  <TableHeader
                    isSort={false}
                    label={"Status"}
                    cssClass='text-center text-xs'
                  />
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-whit'>
                {item?.shifts &&
                item?.shifts?.length > 0 ? (
                  item?.shifts?.map((el, index) => (
                    <tr key={index}>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.shift_date
                            ? moment(el?.shift_date).format("DD/MM/YYYY")
                            : "N/A"}
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.start_time
                            ? moment(el?.start_time).format("DD/MM/YYYY HH:mm")
                            : "N/A"}
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.end_time
                            ? moment(el?.end_time).format("DD/MM/YYYY HH:mm")
                            : "N/A"}
                        </div>
                      </td>

                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                       {
                        el?.shift_length || "-"
                       }
                        </div>
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.status ? el?.status : "-"}
                        </div>
                      </td>
                    
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className='py-6 text-sm text-center text-red-600'
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

         

          </td>
        </tr>
      )}

    </>
  );
};

export default ROCWeeklyTable;
