import React, { useState } from "react";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";

const SuperAdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex">
      <div className="w-full absolute md:static">
        <div className="min-h-full">
          <div className="py-10">
            {isLoading ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes="flex justify-center items-center"
                  classloader="loader-l"
                />
              </div>
            ) : (
              <div className="max-w-full mx-auto max-w-full px-8 grid grid-cols-4 lg:gap-8 capitalize">
                <main className="col-span-4 lg:col-span-3"></main>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SuperAdminDashboard;
