import React, { useEffect } from "react";
import {
  capitalizeAllLetters,
  isExistPayrollPlatform,
  parseBooleanVal,
} from "Utils/utils";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { HiOutlineChevronDoubleLeft } from "react-icons/hi";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { authenticationPayrollUpdateLocally } from "store/actions/rostering.actions";
import usePayrollAuthentication from "hooks/usePayrollAuthentication";

const PayrollAuthCallback = ({ type }) => {
  const { apiResponse } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isAuthenticateForPayrolls,
    checkPayrollAuthentication,
    isCheckPayrollAuthLoading,
  } = usePayrollAuthentication();

  useEffect(() => {
    if (isAuthenticateForPayrolls !== null) {
      dispatch(authenticationPayrollUpdateLocally(isAuthenticateForPayrolls));
    }
  }, [isAuthenticateForPayrolls]);

  useEffect(() => {
    checkPayrollAuthentication();
  }, [apiResponse]);

  const getPayrollLabel = () => {
    return type && capitalizeAllLetters(type.replace("_", " "));
  };

  return (
    <>
      {isCheckPayrollAuthLoading ? (
        <div
          className={`flex flex-col w-full h-screen bg-gray-50 items-center justify-center`}
        >
          <CircularLoader
            classes='flex justify-center items-center'
            classloader='loader-l'
          />
        </div>
      ) : (
        apiResponse &&
        isAuthenticateForPayrolls !== null && (
          <div className='h-screen pt-16 pb-12 flex flex-col bg-white'>
            <main className='flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
              <div className='flex-shrink-0 flex justify-center'>
                {apiResponse === "success" &&
                parseBooleanVal(isAuthenticateForPayrolls?.[type]) &&
                isExistPayrollPlatform(type) ? (
                  <AiOutlineCheckCircle className='text-green-400 h-20 w-20' />
                ) : apiResponse === "error" &&
                  !parseBooleanVal(isAuthenticateForPayrolls?.[type]) ? (
                  <AiOutlineCloseCircle className='text-red-400 h-20 w-20' />
                ) : (
                  ""
                )}
              </div>
              <div className='py-16'>
                <div className='text-center'>
                  <h1 className='mt-2 text-4xl font-extrabold text-blue-dark tracking-tight sm:text-5xl'>
                    {apiResponse === "success" &&
                    parseBooleanVal(isAuthenticateForPayrolls?.[type]) &&
                    isExistPayrollPlatform(type)
                      ? `${getPayrollLabel()} authentication has been successfully authorized!`
                      : apiResponse === "error" &&
                        !parseBooleanVal(isAuthenticateForPayrolls?.[type])
                      ? `${getPayrollLabel()} authentication can't be authorized!`
                      : ""}
                  </h1>
                  <div className='mt-6 flex justify-center'>
                    <button
                      className='text-base font-medium text-blue-dark hover:underline flex items-center space-x-4 justify-center'
                      onClick={() => navigate("/")}
                    >
                      <HiOutlineChevronDoubleLeft />
                      <span>Go Back to dashboard</span>
                    </button>
                  </div>
                </div>
              </div>
            </main>
            <footer className='flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
              <nav className='flex justify-center space-x-4'>
                <p className='text-sm font-medium text-blue-dark'>
                  &copy; all rights are reserved by Vertex
                </p>
              </nav>
            </footer>
          </div>
        )
      )}
    </>
  );
};

export default PayrollAuthCallback;
