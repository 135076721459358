import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  FORM_TEMPLATE_INITIAL_ASSESMENT_SLUG,
  FORM_TEMPLATE_INITIAL_ASSESMENT_UUID,
  PARENT_FORM_CATEGORY_SLUG,
  USER_ROLE,
} from "Constants/constant";
import { apiGetRequest } from "helpers/Requests";
import { roleAccess } from "helpers/RolesPermission";
import {
  participantQuickSnapshotCall,
  participantSupportPlanCall,
} from "store/actions/participant.actions";
import {
  getParticipantQuickSnapshot,
  getParticipantSupportPlan,
  getQuickSnapshotLoading,
  getSupportPlanLoading,
} from "store/selector/participant.selector";
import QuickSnapshots from "components/common/QuickSnapshots/QuickSnapshots";
import SupportPlan from "components/common/SupportPlan/SupportPlan";
import RiskRatingTab from "../RiskRating/RiskRatingTab";
import LikesDislikes from "components/common/QuickSnapshots/LikesDislikes";

const SupportPlanPage = (props) => {
  const {
    participantsInfo,
    setToggleState,
    getParticipantSupportPlan,
    isLoading,
    selectedSupportPlan,
    quickSnapShotLoading,
  } = props;

  const { uuid } = useParams();
  const [supportPlanData, setSupportPlanData] = useState(null);
  const navigate = useNavigate();
  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const getEmployeeSupportPlan = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      let response = await apiGetRequest(
        `hrm/participant/support-plan?pt_id=${participantsInfo.id}`
      );
      if (response.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (response.status === 200) {
        setSupportPlanData(response.data.data);
      }
      setRequest({ ...request, isRequested: false, isSuccess: true });
    } catch (err) {
      setRequest({ ...request, isRequested: false, isSuccess: true });
      console.log(err);
    }
  };

  useEffect(() => {
    if (participantsInfo && uuid) {
      if (
        !selectedSupportPlan &&
        roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])
      ) {
        getParticipantSupportPlan({ participantId: participantsInfo.id });
      }
    }
  }, []);

  useEffect(() => {
    if (roleAccess([USER_ROLE.Sp_Standard_User])) {
      getEmployeeSupportPlan();
    } else {
      setSupportPlanData(selectedSupportPlan);
    }
  }, [selectedSupportPlan]);

  const viewInitialAssesmentForm = (form_uuid) => {
    navigate(
      `/sp-profile/${PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG}/${form_uuid}/update`,
      {
        state: {
          participantId: participantsInfo ? participantsInfo.id : "1",
          particpantUuid: participantsInfo ? participantsInfo.uuid : "1",
          redirectTo: "overview",
        },
      }
    );
  };

  const addInitialAssesmentForm = () => {
    const item = {
      uuid: FORM_TEMPLATE_INITIAL_ASSESMENT_UUID,
      slug: FORM_TEMPLATE_INITIAL_ASSESMENT_SLUG,
    };
    navigate(
      `/view-template/${participantsInfo ? participantsInfo.uuid : "1"}/${
        PARENT_FORM_CATEGORY_SLUG.PARTICIPANT_ASSESSMENT.SLUG
      }/${item.slug}`,
      {
        state: {
          redirectTo: "overview",
        },
      }
    );
  };

  return (
    <div className='max-w-7xl mx-auto'>
      <div className='grid grid-cols-2'>
        <div className='col-span-2 lg:col-span-1'>
          <QuickSnapshots
            isLoading={quickSnapShotLoading}
            setToggleState={setToggleState}
            settingsData={participantsInfo}
            participantsInfo={participantsInfo}
            cssClass='text-white'
          />
        </div>
        <div className='col-span-2 lg:col-span-1'>
          <LikesDislikes
            viewInitialAssesmentForm={viewInitialAssesmentForm}
            addInitialAssesmentForm={addInitialAssesmentForm}
            isLoading={
              roleAccess([USER_ROLE.Sp_Standard_User])
                ? request.isRequested
                : isLoading
            }
            supportPlanData={supportPlanData}
          />
        </div>
        <div className='col-span-2'>
          <SupportPlan
            viewInitialAssesmentForm={viewInitialAssesmentForm}
            addInitialAssesmentForm={addInitialAssesmentForm}
            setToggleState={setToggleState}
            participantsInfo={participantsInfo}
            isLoading={
              roleAccess([USER_ROLE.Sp_Standard_User])
                ? request.isRequested
                : isLoading
            }
            supportPlanData={supportPlanData}
          />
        </div>
        {participantsInfo && participantsInfo.uuid && (
          <div className='col-span-2 px-2 print:hidden'>
            <RiskRatingTab
              participantsUid={participantsInfo.uuid}
              isReadOnly={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getParticipantSupportPlan: participantSupportPlanCall,
  getParticipantQuickSnapshot: participantQuickSnapshotCall,
};

const mapStateToProps = (state) => {
  const isLoading = getSupportPlanLoading(state);
  const selectedSupportPlan = getParticipantSupportPlan(state);
  const quickSnapShot = getParticipantQuickSnapshot(state);
  const quickSnapShotLoading = getQuickSnapshotLoading(state);
  return {
    isLoading,
    selectedSupportPlan,
    quickSnapShot,
    quickSnapShotLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportPlanPage);
