import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import CustomButton from "../../../../components/common/Button/CustomButton";
import SelectOption from "../../../../components/common/Select/Select";
import ModalSkeleton from "../../../../components/Modals/ModalSkeleton";
import {
  DELIVERED_SERVICE_STATUS_OPTIONS,
  LOADER_STATUS,
} from "../../../../Constants/constant";
import { NumericFormat } from "react-number-format";
import { LoginContext } from "helpers/LoginContext";

const ManualDeliveredModal = (props) => {
  const {
    showModal,
    funding,
    participant,
    handleClose,
    PlanCatagories,
    catalogState,
    storeManualDeliveryLoader,
    manualDeliveredCreate,
    storeManualDeliveryError,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const watchFormData = watch();
  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [catagory, setCatagory] = useState(null);
  const [subCatagory, setSubCatagory] = useState(null);
  const [storeRenderLoader, setStoreRenderLoader] = useState(LOADER_STATUS.NA);

  useEffect(() => {
    if (
      !storeManualDeliveryLoader &&
      storeRenderLoader === LOADER_STATUS.PENDING &&
      storeManualDeliveryError.length === 0
    ) {
      setStoreRenderLoader(LOADER_STATUS.NA);
      handleClose();
    }
  }, [storeManualDeliveryLoader]);

  // useEffect(() => {
  //   if (watchFormData.delivered_date) {
  //     clearErrors("delivered_from");
  //     clearErrors("delivered_to");
  //   } else if (watchFormData.delivered_from && watchFormData.delivered_to) {
  //     clearErrors("delivered_date");
  //   }
  // }, [
  //   watchFormData.delivered_date,
  //   watchFormData.delivered_from,
  //   watchFormData.delivered_to,
  // ]);

  useEffect(() => {
    if (catagory !== null) {
      setValue("service_category_id", catagory.value);
      clearErrors("service_category_id");
    }
    const valdiate = () => {
      const index = PlanCatagories.subCatagories[
        catagory.label.replace(" ", "_")
      ].findIndex((x) => x.value === subCatagory.value);
      if (index === -1) {
        setValue("service_sub_category_id", null);
        setSubCatagory(null);
      }
    };
    if (catagory && subCatagory) {
      valdiate();
    }
  }, [catagory]);

  useEffect(() => {
    if (subCatagory !== null) {
      setValue("service_sub_category_id", subCatagory.value);
      clearErrors("service_sub_category_id");
    }
  }, [subCatagory]);

  useEffect(() => {
    if (catalogState) {
      setValue("state_id", catalogState.label);
    }
  }, [catalogState]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (catalogState) {
      Object.assign(data, { state_id: catalogState.value });
    }
    Object.assign(data, {
      funding_id: funding ? funding.uuid : "-1",
      pt_id: participant ? participant.id : "-1",
    });
    setStoreRenderLoader(LOADER_STATUS.PENDING);
    manualDeliveredCreate(data);
  };

  const modalFooter = () => (
    <CustomButton
      showLoading={storeManualDeliveryLoader}
      isDisabled={storeManualDeliveryLoader || isUserGracePeriodOver}
      label={"Submit"}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById(
          "manualDeliveredServicesButtonModal"
        );
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <div>
      <ModalSkeleton
        title={"Create Manual Delivered Service"}
        modalFooter={modalFooter()}
        isOpen={showModal}
        closeModal={handleClose}
        cssClass='w-[55%]'
      >
        <div className='flex flex-col'>
          <p className='text-sm text-gray-500 mb-2'>
            Please enter the following details to create manual service
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
            <div className='grid grid-cols-2 gap-x-4'>
              <div className='col-span-1'>
                <label className='block text-sm font-medium text-gray-700'>
                  Funding ID
                </label>
                <input
                  {...register("funding_id", {
                    required: true,
                    value: funding.contract_id,
                  })}
                  style={{
                    backgroundColor: "#4b6bb710",
                  }}
                  value={funding.contract_id}
                  disabled
                  readOnly
                  name='funding_id'
                  id=''
                  className='did-floating-input mt-1'
                />
              </div>
              <div className='col-span-1'>
                <label
                  htmlFor='name'
                  className='block text-sm font-medium text-gray-700'
                >
                  Name
                </label>
                <div className='mt-1'>
                  <input
                    disabled
                    readOnly
                    style={{ backgroundColor: "#4b6bb710" }}
                    value={`${participant.first_name} ${participant.last_name}`}
                    type='text'
                    name='name'
                    id='name'
                    className='capitalize did-floating-input bg-[#4b6bb710]'
                  />
                </div>
              </div>
            </div>

            <div className='grid grid-cols-2 gap-x-4'>
              <div className='col-span-1'>
                <label
                  htmlFor='total_budget_amount'
                  className='block text-sm font-medium text-gray-700'
                >
                  State
                </label>
                <div className='mt-1'>
                  <input
                    {...register("state_id", {
                      required: true,
                    })}
                    placeholder={
                      watchFormData.state_id === undefined
                        ? "Please select state from setting..."
                        : null
                    }
                    readOnly
                    disabled
                    type='text'
                    name='state_id'
                    id='state_id'
                    className='px-4 py-2 border shadow-sm block w-full sm:text-sm border-gray-300 rounded-md capitalize bg-[#4b6bb710]'
                  />
                  {errors?.state_id?.type === "required" && (
                    <span className='text-sm text-red-500'>
                      This field is required!
                    </span>
                  )}
                </div>
              </div>
              <div className='col-span-1'>
                <label className='block text-sm font-medium text-gray-700'>
                  Status
                </label>
                <input
                  {...register("status", {
                    required: true,
                    value: DELIVERED_SERVICE_STATUS_OPTIONS[3].value,
                    maxLength: 254,
                  })}
                  style={{ backgroundColor: "#4b6bb710" }}
                  readOnly
                  disabled
                  type='text'
                  name='status'
                  id='status'
                  className='capitalize did-floating-input mt-1'
                />
                {errors?.status?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required
                  </span>
                )}
              </div>
            </div>

            <div className='grid grid-cols-2 gap-x-4'>
              <div className='col-span-1'>
                <div className='hidden'>
                  <select
                    {...register("service_category_id", {
                      required: true,
                    })}
                    name='service_category_id'
                    id=''
                    className='hidden did-floating-input'
                  />
                </div>
                <label className='block text-sm font-medium text-gray-700'>
                  Plan Category
                </label>
                <SelectOption
                  cssClass='mt-1'
                  isSearch={false}
                  data={
                    PlanCatagories && PlanCatagories.catagory
                      ? PlanCatagories.catagory
                      : []
                  }
                  selectedOption={catagory}
                  handleChange={setCatagory}
                  // placeHolder="Select Plan Category"
                />
                {errors?.service_category_id?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required
                  </span>
                )}
              </div>
              <div className='col-span-1'>
                <div className='hidden'>
                  <select
                    {...register("service_sub_category_id", {
                      required: true,
                    })}
                    name='service_sub_category_id'
                    id=''
                    className='hidden did-floating-input'
                  />
                </div>
                <label className='block text-sm font-medium text-gray-700'>
                  Plan Subcategory
                </label>
                <SelectOption
                  cssClass='mt-1'
                  // isSearch={false}
                  data={
                    catagory && catagory.label
                      ? PlanCatagories.subCatagories[
                          catagory.label.replace(" ", "_")
                        ]
                      : []
                  }
                  selectedOption={subCatagory}
                  handleChange={setSubCatagory}
                  // placeHolder={`${
                  //   catagory === null
                  //     ? "Select Plan Category First!"
                  //     : "Select Plan Sub Category"
                  // }`}
                />
                {errors?.service_sub_category_id?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required
                  </span>
                )}
              </div>
            </div>

            <div className='grid grid-cols-2 gap-x-4'>
              <div className='col-span-1'>
                <label className='block text-sm font-medium text-gray-700'>
                  Delivered Date
                </label>
                <input
                  {...register("delivered_date", {
                    required: true,
                    // watchFormData &&
                    // Object.keys(watchFormData).length > 0 &&
                    // watchFormData.delivered_from.length === 0 &&
                    // watchFormData.delivered_to.length === 0,
                  })}
                  type='date'
                  min={moment().format("DD-MM-YYYY")}
                  name='delivered_date'
                  id=''
                  className='did-floating-input mt-1'
                />
                {errors?.delivered_date?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>

              <div className='col-span-1'>
                <label
                  htmlFor='total_amount'
                  className='block text-sm font-medium text-gray-700'
                >
                  Budget Amount
                </label>
                <div className='mt-1'>
                  <input
                    type='hidden'
                    {...register("total_amount", {
                      required: true,
                      // value: parseInt(watchFormData.total_budget_amount, 10),
                    })}
                  />
                  <NumericFormat
                    min={1}
                    type='text'
                    maxLength={"13"}
                    className={`${"focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"} w-full`}
                    value={parseInt(watchFormData.total_amount, 10)}
                    thousandsGroupStyle='thousand'
                    thousandSeparator=','
                    valueIsNumericString={true}
                    decimalScale={0}
                    prefix={"$"}
                    allowLeadingZeros={false}
                    onValueChange={(e) => {
                      const { value } = e;
                      // console.log("value", value);
                      if (value > 0) {
                        setValue("total_amount", value);
                        clearErrors("total_amount");
                      } else {
                        setValue("total_amount", null);
                      }
                    }}
                  />
                  {errors?.total_amount?.type === "required" && (
                    <span className='text-sm text-red-500'>
                      This field is required!
                    </span>
                  )}
                </div>
              </div>

              {/* <div className="col-span-1"> */}
              {/* <div className="grid grid-cols-2 gap-x-4">
                  <div className="col-span-1">
                    <label
                      htmlFor="total_budget_amount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Delivered From
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("delivered_from", {
                          required:
                            watchFormData &&
                            Object.keys(watchFormData).length > 0 &&
                            watchFormData.delivered_date.length === 0,
                        })}
                        type="date"
                        min={moment().format("DD-MM-YYYY")}
                        className="did-floating-input"
                      />
                      {errors?.delivered_from?.type === "required" && (
                        <span className="text-sm text-red-500">
                          This field is required!
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="total_budget_amount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Delivered To
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("delivered_to", {
                          required:
                            watchFormData &&
                            Object.keys(watchFormData).length > 0 &&
                            watchFormData.delivered_date.length === 0,
                        })}
                        type="date"
                        min={moment().format("DD-MM-YYYY")}
                        className="did-floating-input"
                      />
                      {errors?.delivered_to?.type === "required" && (
                        <span className="text-sm text-red-500">
                          This field is required!
                        </span>
                      )}
                    </div>
                  </div>
                </div> */}
              {/* </div> */}
            </div>

            <div className='grid grid-cols-2 gap-x-4'>
              <div className='col-span-2'>
                <label className='block text-sm font-medium text-gray-700'>
                  Notes
                </label>
                <textarea
                  rows={3}
                  name='note'
                  id='note'
                  className='resize-none overflow-y-auto px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  {...register("note", {
                    //  required: true
                  })}
                />
                {errors?.note?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required
                  </span>
                )}
              </div>
            </div>

            <button
              type='submit'
              className='hidden sr-only'
              id='manualDeliveredServicesButtonModal'
            />
          </form>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default ManualDeliveredModal;
