import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import {roleAccess} from "../../../helpers/RolesPermission";
import {USER_ROLE} from "../../../Constants/constant";

const ParticipantProgressBar = (props) => {
  const {
    uuid,
    progressBarDetails,
 
  } = props;
  



  return (
    <div className='tooltip-progressbar rounded shadow-lg p-1 bg-gray-100 mt-6 w-[400px]'>
      <div className='flex justify-between items-center px-4 py-2 bg-gray-100'>
        <p>Onboarding Update</p>
      </div>
      <div className='max-h-64 overflow-y-auto'>
        {progressBarDetails &&
          progressBarDetails.length > 0 &&
          progressBarDetails
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((x, index) => (
              <div
                key={index}
                className='cursor-default flex justify-between items-center space-x-6 px-4 py-2 text-gray-700 bg-white hover:bg-gray-100 border border-gray-200'
              >
                <p className='capitalize mt-2 flex items-center text-sm text-gray-500'>
                  {x.submitted ? (
                    <CheckCircleIcon
                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
                      aria-hidden='true'
                    />
                  ) : (
                    <XCircleIcon
                      className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400'
                      aria-hidden='true'
                    />
                  )}
                  {(!x.type || x.type === "form") && x.cat_slug && x.cat_slug != undefined ? (
                  <Link to={
                            x.user || x.user == 'participant' ? (
                                !roleAccess([USER_ROLE.Sp_Standard_User]) ? (
                                x.submitted  == true ? (`/${x.pre_url}/${x.cat_slug}/${x.uuid}/view`)
                                :
                                ( `/view-template/${uuid}/${x.cat_slug}/${x.slug}`)
                                ):
                                    
                                ('#')
                            )
                            :
                            (
                                x.submitted  == true ? (`/submitted-form/${x.cat_slug}/${x.uuid}/update`)
                                    :
                                    ( `/view-template/hrm/${x.uuid}/${x.cat_slug}/${x.slug}`)
                            )
                  }>
                    {x.name}
                  </Link>
                  ) : (
                      <span>{x.name}</span>
                  )}
                </p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default ParticipantProgressBar;
