import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { BiPlus } from "react-icons/bi";
import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "Constants/constant";

const ParticipantProfileTabItem = (props) => {
  const { toggleTab, selectedTab, tabItem } = props;

  const checkChildTabId = () => {
    if (tabItem.children.find((x) => x.id === selectedTab)) {
      return "tabs active-tabs";
    }
    return "tabs";
  };

  return (
    <>
      {tabItem.children && tabItem.children.length > 0 ? (
        <div className={checkChildTabId()}>
          <Menu as='div' className='relative  inline-block text-left'>
            <div>
              <Menu.Button className='inline-flex justify-center items-center w-full rounded-md border border-gray-300'>
                {tabItem.name}
                <ChevronDownIcon
                  className='-mr-1 ml-1 h-5 w-5'
                  aria-hidden='true'
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='origin-top-right   fixed mt-2 w-56 rounded-md shadow-lg bg-white  ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='py-1 '>
                  {tabItem.children.map((childTab, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <div className='flex items-center gap-x-1 px-2'>
                          <span
                            onClick={() => toggleTab(childTab)}
                            className='text-gray-700 block px-4 py-2 text-sm w-full hover:bg-gray-100 hover:text-gray-900'
                          >
                            {childTab.name}
                          </span>
                          {roleAccess([
                            USER_ROLE.Sp_Admin,
                            USER_ROLE.Sp_manager,
                          ]) &&
                            childTab.slug && (
                              <span
                                tabIndex={0}
                                role='button'
                                onClick={() =>
                                  toggleTab({ ...childTab, isModalOpen: true })
                                }
                                className='text-black'
                              >
                                <BiPlus />
                              </span>
                            )}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      ) : (
        <button
          className={
            selectedTab === tabItem.id
              ? "tabs active-tabs cursor-pointer"
              : "tabs"
          }
          onClick={() => toggleTab(tabItem)}
        >
          {tabItem.name}
          {/* {tabItem.id === 17 && (
            <span className='ml-2 bg-gray-100 text-gray-800 text-xs font-small mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300'>
              Beta
            </span>
          )} */}
        </button>
      )}
    </>
  );
};

export default ParticipantProfileTabItem;
