import React, { useContext, useEffect, useRef, useState } from "react";
import { PaperClipIcon } from "@heroicons/react/solid";
import sideImg from "../../../../assets/v360vertical-01.jpg";
import logo from "../../../../assets/V360Logo.svg";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../components/common/Input/Input";
import Select from "../../../../components/common/Select/Select";
import {
  GenderOptions,
  MOMENT_ADD_OPTIONS,
} from "../../../../Constants/constant";
import { apiGetRequest } from "../../../../helpers/Requests";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import {
  futureDateValidation,
  getAge,
  hasBlankSpaces,
  isValidFile,
  maxFileSize,
  parseBooleanVal,
} from "../../../../Utils/utils";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../../store/services/URL";
import CustomButton from "../../../../components/common/Button/CustomButton";
import { BiExit } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { callLogout } from "../../../../store/actions/auth.actions";
import { LoginContext } from "helpers/LoginContext";

const OnBoardingForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const identificationInput = useRef(null);
  const firstAidInput = useRef(null);
  const cprInput = useRef(null);
  const ndisInput = useRef(null);
  const dispatch = useDispatch();

  const { unSubscribeChannels } = useContext(LoginContext);

  const token = localStorage.getItem("accessToken");
  const isOnBoarding = parseBooleanVal(localStorage.getItem("isOnBoarding"));

  if (isOnBoarding) {
    navigate("/dashboard");
  }
  if (!token) {
    navigate("/");
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleSubmit = () => {
    if (validateInput()) return;
    let formData = new FormData();

    formData.append("first_name", user.first_name);
    formData.append("last_name", user.last_name);
    formData.append("job_title", user.job_title);
    formData.append("address", user.address);
    formData.append("dob", user.dob);
    formData.append("joining_date", user.joining_date);
    formData.append("gender", user.gender);
    formData.append("identification[]", user.identification);
    formData.append(
      "copy_of_first_aid_certificate[]",
      user.copy_of_first_aid_certificate
    );
    formData.append(
      "copy_of_first_aid_certificate[]",
      user.copy_of_first_aid_certificate_expiry
    );
    formData.append("copy_of_cpr_certificate[]", user.copy_of_cpr_certificate);
    formData.append(
      "copy_of_cpr_certificate[]",
      user.copy_of_cpr_certificate_expiry
    );
    formData.append(
      "ndis_worker_screening_check[]",
      user.ndis_worker_screening_check
    );

    setIsLoading(true);
    axios({
      method: "post",
      url: `${BASE_URL}hrm/employee-update?profile_id=${user.uuid}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          toast.success(res.data.message);
          setTimeout(() => navigate("/dashboard"), 1200);
          localStorage.setItem("isOnBoarding", true);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setError(err.response);
        // toast.error(
        //   err.response.data ? err.response.data.message : err.response.data
        // );
      });
  };

  const getUserDetails = async () => {
    try {
      setIsLoading(true);
      const res = await apiGetRequest("/hrm/employee");
      if (res.status === 200) {
        setUser(res.data.profile);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const validateInput = () => {
    const fnErrors = errors;
    if (!user.first_name || hasBlankSpaces(user.first_name)) {
      Object.assign(fnErrors, { first_name: "First Name is Required" });
    }
    if (!user.last_name || hasBlankSpaces(user.last_name)) {
      Object.assign(fnErrors, { last_name: "Last Name is Required" });
    }
    if (!user.address || hasBlankSpaces(user.address)) {
      Object.assign(fnErrors, { address: "Address is Required" });
    }
    if (!user.job_title || hasBlankSpaces(user.job_title)) {
      Object.assign(fnErrors, { job_title: "Job Title is Required" });
    }
    if (!user.joining_date || user.joining_date.length === 0) {
      Object.assign(fnErrors, { joining_date: "Joining Date is Required" });
    }
    if (!user.dob || user.dob.length === 0) {
      Object.assign(fnErrors, { dob: "Date of Birth is Required" });
    }
    if (user.dob && getAge(user.dob).years < 18) {
      Object.assign(fnErrors, { dob: `You should be 18+` });
    }
    if (!user.gender || user.gender.length === 0) {
      Object.assign(fnErrors, { gender: "Gender is Required" });
    }
    if (!user.identification || user.identification.length === 0) {
      Object.assign(fnErrors, { identification: "Identification is Required" });
    }
    if (
      !user.copy_of_first_aid_certificate ||
      user.copy_of_first_aid_certificate.length === 0
    ) {
      Object.assign(fnErrors, {
        copy_of_first_aid_certificate: "First Aid Certificate is Required",
      });
    }
    if (
      !user.copy_of_first_aid_certificate_expiry ||
      user.copy_of_first_aid_certificate_expiry.length === 0
    ) {
      Object.assign(fnErrors, {
        copy_of_first_aid_certificate_expiry:
          "First Aid Certificate Expiry is Required",
      });
    }
    if (
      !user.copy_of_cpr_certificate ||
      user.copy_of_cpr_certificate.length === 0
    ) {
      Object.assign(fnErrors, {
        copy_of_cpr_certificate: "CPR certificate is Required",
      });
    }
    if (
      !user.copy_of_cpr_certificate_expiry ||
      user.copy_of_cpr_certificate_expiry.length === 0
    ) {
      Object.assign(fnErrors, {
        copy_of_cpr_certificate_expiry: "CPR certificate Expiry is Required",
      });
    }
    if (
      !user.ndis_worker_screening_check ||
      user.ndis_worker_screening_check.length === 0
    ) {
      Object.assign(fnErrors, {
        ndis_worker_screening_check: "NDIS worker screening is Required",
      });
    }
    if (Object.values(fnErrors).filter((x) => x !== null).length > 0) {
      setErrors({ ...fnErrors });
      return true;
    }
    return false;
  };

  const handleBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (hasBlankSpaces(value) || value.length === 0) {
      setErrors({ ...errors, [e.target.name]: `${name} is Required` });
    } else if (name === "dob" && value.length > 0 && getAge(value).years < 18) {
      setErrors({ ...errors, [e.target.name]: `You should be 18+` });
    } else if (
      (name === "copy_of_first_aid_certificate_expiry" ||
        name === "copy_of_cpr_certificate_expiry") &&
      value.length > 0 &&
      futureDateValidation(value, 30, MOMENT_ADD_OPTIONS.DAYS)
    ) {
      setErrors({
        ...errors,
        [e.target.name]: `Certificate should be 1 month validity`,
      });
    } else if (errors[e.target.name] && value.length > 0) {
      setErrors({ ...errors, [e.target.name]: null });
    }
  };

  const getError = (value) => {
    if (!value) return "";
    return errors[value] && errors[value].length > 0 ? errors[value] : "";
  };

  const handleUploadClick = (key) => {
    if (key === "identification") identificationInput.current.click();
    if (key === "copy_of_first_aid_certificate") firstAidInput.current.click();
    if (key === "copy_of_cpr_certificate") cprInput.current.click();
    if (key === "ndis_worker_screening_check") ndisInput.current.click();
  };

  const handleUpload = (e, key) => {
    if (e.target.files.length === 0) return;
    const file = e.target.files[0];
    let message = isValidFile(file, "pdf");
    if (message.length > 0) {
      setErrors({ ...errors, [key]: message });
      return;
    }
    message = maxFileSize(file, 2);
    if (message.length > 0) {
      setErrors({ ...errors, [key]: message });
      return;
    }

    if (key === "identification") setUser({ ...user, identification: file });
    if (key === "copy_of_first_aid_certificate") {
      setUser({ ...user, copy_of_first_aid_certificate: file });
    }
    if (key === "copy_of_cpr_certificate") {
      setUser({ ...user, copy_of_cpr_certificate: file });
    }
    if (key === "ndis_worker_screening_check") {
      setUser({ ...user, ndis_worker_screening_check: file });
    }
    if (message.length === 0) setErrors({ ...errors, [key]: null });
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    unSubscribeChannels();
    dispatch(callLogout());
    navigate("/");
  };

  return (
    <div className='h-full flex'>
      <div className='flex-1 flex flex-col overflow-hidden11111'>
        <div className='flex-1 flex items-stretch overflow-hidden1111'>
          <div className='flex-1 overflow-y-auto111 max-w-7xl rounded-md m-8'>
            {isLoading ? (
              <div className='flex justify-center items-center min-h-screen'>
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <>
                <div className='mx-auto w-full max-w-4xl'>
                  <div className='sticky py-4 top-0 z-20 bg-white'>
                    <img className='h-12 w-auto' src={logo} alt='V360' />
                    <div className='flex justify-between'>
                      <div>
                        <h2 className='mt-4 text-3xl font-extrabold text-gray-900'>
                          Welcome to Vertex360
                        </h2>
                      </div>
                      <div>
                        <CustomButton
                          label='Logout'
                          Icon={<BiExit />}
                          clickHandler={handleLogout}
                        />
                      </div>
                    </div>

                    <div className='mt-2 text-sm text-gray-600'>
                      <p className='text-ndisPurple font-medium text-indigo-600 hover:text-indigo-500'>
                        Please complete your profile to get started
                      </p>
                    </div>
                  </div>
                  <div className=''>
                    <div className='mt-2'>
                      <form className='space-y-8 divide-y divide-gray-200'>
                        <div className='space-y-8 divide-y divide-gray-200'>
                          <div className='pt-4'>
                            <div>
                              <h3 className='text-lg leading-6 font-medium text-gray-900'>
                                Personal Details
                              </h3>
                              <p className='mt-1 text-sm text-gray-500'>
                                This information will be displayed publicly so
                                be careful what you share.
                              </p>
                            </div>
                            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                              <div className='sm:col-span-3'>
                                <InputField
                                  label='First Name'
                                  type='text'
                                  // placeHolder="Enter First Name"
                                  handleChange={(e) =>
                                    setUser({ ...user, first_name: e })
                                  }
                                  value={user.first_name}
                                  ErrorMessage={getError("first_name")}
                                  blurHandler={handleBlur}
                                  name='first_name'
                                />
                              </div>
                              <div className='sm:col-span-3'>
                                <InputField
                                  label='Last Name'
                                  type='text'
                                  // placeHolder="Enter Last Name"
                                  handleChange={(e) =>
                                    setUser({ ...user, last_name: e })
                                  }
                                  value={user.last_name}
                                  ErrorMessage={getError("last_name")}
                                  blurHandler={handleBlur}
                                  name='last_name'
                                />
                              </div>
                              <div className='sm:col-span-3'>
                                <InputField
                                  label='Address'
                                  type='text'
                                  // placeHolder="Enter Address"
                                  handleChange={(e) =>
                                    setUser({ ...user, address: e })
                                  }
                                  value={user.address}
                                  ErrorMessage={getError("address")}
                                  blurHandler={handleBlur}
                                  name='address'
                                />
                              </div>
                              <div className='sm:col-span-3'>
                                <InputField
                                  label='Joining Date'
                                  type='date'
                                  // min={moment().format("DD-MM-YYYY")}
                                  handleChange={(e) =>
                                    setUser({ ...user, joining_date: e })
                                  }
                                  value={user.joining_date}
                                  ErrorMessage={getError("joining_date")}
                                  blurHandler={handleBlur}
                                  name='joining_date'
                                />
                              </div>
                              <div className='sm:col-span-3'>
                                <label
                                  htmlFor='gender'
                                  className='block text-sm font-medium text-gray-700'
                                >
                                  Gender
                                </label>
                                <div className='mt-1'>
                                  <Select
                                    isSearch={false}
                                    data={GenderOptions}
                                    // placeHolder="Select Gender"
                                    selectedOption={
                                      user.gender
                                        ? GenderOptions.find(
                                            (x) => x.value === user.gender
                                          )
                                        : ""
                                    }
                                    handleChange={(e) => {
                                      setUser({ ...user, gender: e.value });
                                      setErrors({ ...errors, gender: null });
                                    }}
                                  />
                                  {getError("gender") && (
                                    <span className='errorMessage'>
                                      {getError("gender")}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='sm:col-span-3'>
                                <InputField
                                  label='Date of Birth'
                                  type='date'
                                  // min={moment().format("DD-MM-YYYY")}
                                  handleChange={(e) =>
                                    setUser({ ...user, dob: e })
                                  }
                                  value={user.dob}
                                  ErrorMessage={getError("dob")}
                                  blurHandler={handleBlur}
                                  name='dob'
                                />
                              </div>
                              <div className='sm:col-span-3'>
                                <InputField
                                  label='Job Title'
                                  type='text'
                                  handleChange={(e) =>
                                    setUser({ ...user, job_title: e })
                                  }
                                  // placeHolder="Enter Job Title"
                                  value={user.job_title}
                                  ErrorMessage={getError("job_title")}
                                  blurHandler={handleBlur}
                                  name='job_title'
                                />
                              </div>
                            </div>

                            <div className='mt-6'>
                              <h3 className='text-lg leading-6 font-medium text-gray-900'>
                                Documents
                              </h3>
                              <p className='mt-1 text-sm text-gray-500'>
                                Please attach all the documents mentioned below
                              </p>
                            </div>
                            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                              <div className='sm:col-span-6'>
                                <label
                                  htmlFor=''
                                  className='block text-sm font-medium text-gray-700'
                                >
                                  Identification (ID or Passport)
                                </label>
                                {user.identification ? (
                                  <div className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md did-floating-input'>
                                    <div className='py-2 flex items-center justify-between text-sm'>
                                      <div className='w-0 flex-1 flex items-center'>
                                        <PaperClipIcon
                                          className='flex-shrink-0 h-5 w-5 text-gray-400'
                                          aria-hidden='true'
                                        />
                                        <span className='ml-2 flex-1 w-0 truncate'>
                                          {user.identification.name}
                                        </span>
                                      </div>
                                      <div className='ml-4 flex-shrink-0'>
                                        <button
                                          onClick={() => {
                                            setUser({
                                              ...user,
                                              identification: "",
                                            });
                                            setErrors({
                                              ...errors,
                                              identification: null,
                                            });
                                          }}
                                          className='cursor-pointer font-medium text-gray-800 hover:text-ndisPurple'
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <CustomButton
                                        clickHandler={(e) =>
                                          handleUploadClick("identification")
                                        }
                                        type='button'
                                        label='Upload'
                                      />
                                      {/*<button*/}
                                      {/*  onClick={(e) =>*/}
                                      {/*    handleUploadClick("identification")*/}
                                      {/*  }*/}
                                      {/*  type="button"*/}
                                      {/*  className="mt-1 bg-ndisPurple inline-flex items-center px-3 py-2 border border-2 border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-50 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                                      {/*>*/}
                                      {/*  Upload*/}
                                      {/*</button>*/}
                                      <input
                                        ref={identificationInput}
                                        onChange={(e) =>
                                          handleUpload(e, "identification")
                                        }
                                        style={{ display: "none" }}
                                        type='file'
                                        accept='application/pdf'
                                      />
                                    </div>
                                    {getError("identification") && (
                                      <span className='errorMessage'>
                                        {getError("identification")}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                              <div className='sm:col-span-6'>
                                {user.copy_of_first_aid_certificate ? (
                                  <div className='grid grid-cols-2'>
                                    <div className='sm:col-span-1'>
                                      <label
                                        htmlFor=''
                                        className='block text-sm font-medium text-gray-700'
                                      >
                                        First aid certificate
                                      </label>
                                      <div className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md did-floating-input'>
                                        <div className='flex justify-between text-sm py-2'>
                                          <div className='w-0 flex-1 flex items-center'>
                                            <PaperClipIcon
                                              className='flex-shrink-0 h-5 w-5 text-gray-400'
                                              aria-hidden='true'
                                            />
                                            <span className='ml-2 flex-1 w-0 truncate'>
                                              {
                                                user
                                                  .copy_of_first_aid_certificate
                                                  .name
                                              }
                                            </span>
                                          </div>
                                          <div className='ml-4 flex-shrink-0'>
                                            <button
                                              onClick={() => {
                                                setUser({
                                                  ...user,
                                                  copy_of_first_aid_certificate:
                                                    "",
                                                });
                                              }}
                                              className='cursor-pointer font-medium text-gray-800 hover:text-ndisPurple'
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='ml-2 sm:col-span-1'>
                                      <InputField
                                        label='First aid certificate Expiry'
                                        type='date'
                                        // min={moment().format("DD-MM-YYYY")}
                                        handleChange={(e) =>
                                          setUser({
                                            ...user,
                                            copy_of_first_aid_certificate_expiry:
                                              e,
                                          })
                                        }
                                        value={
                                          user.copy_of_first_aid_certificate_expiry
                                        }
                                        ErrorMessage={getError(
                                          "copy_of_first_aid_certificate_expiry"
                                        )}
                                        blurHandler={handleBlur}
                                        name='copy_of_first_aid_certificate_expiry'
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <label
                                        htmlFor=''
                                        className='block text-sm font-medium text-gray-700'
                                      >
                                        First aid certificate
                                      </label>
                                      <CustomButton
                                        clickHandler={(e) =>
                                          handleUploadClick(
                                            "copy_of_first_aid_certificate"
                                          )
                                        }
                                        type='button'
                                        label='Upload'
                                      />
                                      {/*<button*/}
                                      {/*  onClick={(e) =>*/}
                                      {/*    handleUploadClick(*/}
                                      {/*      "copy_of_first_aid_certificate"*/}
                                      {/*    )*/}
                                      {/*  }*/}
                                      {/*  type="button"*/}
                                      {/*  className="mt-1 bg-ndisPurple inline-flex items-center px-3 py-2 border border-2 border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-50 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                                      {/*>*/}
                                      {/*  Upload*/}
                                      {/*</button>*/}
                                      <input
                                        ref={firstAidInput}
                                        onChange={(e) =>
                                          handleUpload(
                                            e,
                                            "copy_of_first_aid_certificate"
                                          )
                                        }
                                        style={{ display: "none" }}
                                        type='file'
                                        accept='application/pdf'
                                      />
                                    </div>
                                    {getError(
                                      "copy_of_first_aid_certificate"
                                    ) && (
                                      <span className='errorMessage'>
                                        {getError(
                                          "copy_of_first_aid_certificate"
                                        )}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                              <div className='sm:col-span-6'>
                                {user.copy_of_cpr_certificate ? (
                                  <div className='grid grid-cols-2'>
                                    <div className='sm:col-span-1'>
                                      <label
                                        htmlFor=''
                                        className='block text-sm font-medium text-gray-700'
                                      >
                                        CPR certificate
                                      </label>
                                      <div className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md did-floating-input'>
                                        <div className='py-2 flex items-center justify-between text-sm'>
                                          <div className='w-0 flex-1 flex items-center'>
                                            <PaperClipIcon
                                              className='flex-shrink-0 h-5 w-5 text-gray-400'
                                              aria-hidden='true'
                                            />
                                            <span className='ml-2 flex-1 w-0 truncate'>
                                              {
                                                user.copy_of_cpr_certificate
                                                  .name
                                              }
                                            </span>
                                          </div>
                                          <div className='ml-4 flex-shrink-0'>
                                            <button
                                              onClick={() =>
                                                setUser({
                                                  ...user,
                                                  copy_of_cpr_certificate: "",
                                                })
                                              }
                                              className='cursor-pointer font-medium text-gray-800 hover:text-ndisPurple'
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='ml-2 sm:col-span-1'>
                                      <InputField
                                        label='Copy of cpr certificate Expiry'
                                        type='date'
                                        // min={moment().format("DD-MM-YYYY")}
                                        handleChange={(e) =>
                                          setUser({
                                            ...user,
                                            copy_of_cpr_certificate_expiry: e,
                                          })
                                        }
                                        value={
                                          user.copy_of_cpr_certificate_expiry
                                        }
                                        ErrorMessage={getError(
                                          "copy_of_cpr_certificate_expiry"
                                        )}
                                        blurHandler={handleBlur}
                                        name='copy_of_cpr_certificate_expiry'
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <label
                                        htmlFor=''
                                        className='block text-sm font-medium text-gray-700'
                                      >
                                        CPR certificate
                                      </label>
                                      <CustomButton
                                        label='Upload'
                                        clickHandler={(e) =>
                                          handleUploadClick(
                                            "copy_of_cpr_certificate"
                                          )
                                        }
                                        type='button'
                                      />
                                      {/*<button*/}
                                      {/*  onClick={(e) =>*/}
                                      {/*    handleUploadClick(*/}
                                      {/*      "copy_of_cpr_certificate"*/}
                                      {/*    )*/}
                                      {/*  }*/}
                                      {/*  type="button"*/}
                                      {/*  className="mt-1 bg-ndisPurple inline-flex items-center px-3 py-2 border border-2 border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-50 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                                      {/*>*/}
                                      {/*  Upload*/}
                                      {/*</button>*/}
                                      <input
                                        ref={cprInput}
                                        onChange={(e) =>
                                          handleUpload(
                                            e,
                                            "copy_of_cpr_certificate"
                                          )
                                        }
                                        style={{ display: "none" }}
                                        type='file'
                                        accept='application/pdf'
                                      />
                                    </div>
                                    {getError("copy_of_cpr_certificate") && (
                                      <span className='errorMessage'>
                                        {getError("copy_of_cpr_certificate")}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                              <div className='sm:col-span-6'>
                                <label
                                  htmlFor=''
                                  className='block text-sm font-medium text-gray-700'
                                >
                                  NDIS worker screening check
                                </label>
                                {user.ndis_worker_screening_check ? (
                                  <div className='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md did-floating-input'>
                                    <div className='pl-3 pr-4 py-2 flex items-center justify-between text-sm'>
                                      <div className='w-0 flex-1 flex items-center'>
                                        <PaperClipIcon
                                          className='flex-shrink-0 h-5 w-5 text-gray-400'
                                          aria-hidden='true'
                                        />
                                        <span className='ml-2 flex-1 w-0 truncate'>
                                          {
                                            user.ndis_worker_screening_check
                                              .name
                                          }
                                        </span>
                                      </div>
                                      <div className='ml-4 flex-shrink-0'>
                                        <button
                                          onClick={() =>
                                            setUser({
                                              ...user,
                                              ndis_worker_screening_check: "",
                                            })
                                          }
                                          className='cursor-pointer font-medium text-gray-800 hover:text-ndisPurple'
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <CustomButton
                                        label='Upload'
                                        clickHandler={(e) =>
                                          handleUploadClick(
                                            "ndis_worker_screening_check"
                                          )
                                        }
                                        type='button'
                                      />
                                      {/*<button*/}
                                      {/*  onClick={(e) =>*/}
                                      {/*    handleUploadClick(*/}
                                      {/*      "ndis_worker_screening_check"*/}
                                      {/*    )*/}
                                      {/*  }*/}
                                      {/*  type="button"*/}
                                      {/*  className="mt-1 bg-ndisPurple inline-flex items-center px-3 py-2 border border-2 border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-50 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                                      {/*>*/}
                                      {/*  Upload*/}
                                      {/*</button>*/}
                                      <input
                                        ref={ndisInput}
                                        onChange={(e) =>
                                          handleUpload(
                                            e,
                                            "ndis_worker_screening_check"
                                          )
                                        }
                                        style={{ display: "none" }}
                                        type='file'
                                        accept='application/pdf'
                                      />
                                    </div>
                                    {getError(
                                      "ndis_worker_screening_check"
                                    ) && (
                                      <span className='errorMessage'>
                                        {getError(
                                          "ndis_worker_screening_check"
                                        )}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='pt-5'>
                          <div className='flex justify-end'>
                            <CustomButton
                              clickHandler={handleSubmit}
                              type='button'
                              label='Go To Dashboard'
                            />
                            {/* <button
                              onClick={handleSubmit}
                              type="button"
                              className="my-2 bg-ndisPurple py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-50 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Go To Dashboard
                            </button> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='hidden w-96 bg-white border-l border-gray-200 overflow-y-auto lg:block'>
            <img className='h-full w-full object-cover' src={sideImg} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingForm;
