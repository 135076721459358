import FundingSetup from "./FundingSetup";
import { connect } from "react-redux";
import {
  getSelectedParticipant,
  getSelectedParticipantOnBoardingProgress,
} from "../../../../store/selector/participant.selector";
import {
  storeAndUpdateFundingSetupRequest,
  getFundingSetupRequest,
  catalogVersionRequest,
} from "../../../../store/actions/cos.actions";
import {
  getCatalogVersions,
  getCOSLoader,
  getParticipantFundings,
  getParticipantFundingsLoading,
  getStoreFundingSetupLoading,
  getStoreFundingSetupError,
} from "../../../../store/selector/cos.selector";

const mapDispatchToProps = {
  storeAndUpdateFundingSetupRequest,
  getFundingSetup: getFundingSetupRequest,
  getCatalogVersions: catalogVersionRequest,
};

const mapStateToProps = (state) => {
  const participant = getSelectedParticipant(state);
  const fundingSetupLoading = getStoreFundingSetupLoading(state);
  const participantFundings = getParticipantFundings(state);
  const participantFundingsLoading = getParticipantFundingsLoading(state);
  const participantOnBoarding = getSelectedParticipantOnBoardingProgress(state);
  const catalogVersions = getCatalogVersions(state);
  const catalogVerionLoading = getCOSLoader(state);
  const storeAndUpdateError = getStoreFundingSetupError(state);

  return {
    participantFundings,
    participant,
    fundingSetupLoading,
    participantFundingsLoading,
    participantOnBoarding,
    catalogVersions,
    catalogVerionLoading,
    storeAndUpdateError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundingSetup);
