import { connect } from "react-redux";
import RosteringSetting from "./RosteringSetting";
import {
  spRosteringSettingsRequest,
  spRosteringSettingsUpdateRequest,
} from "store/actions/sp.actions";
import {
  getSpRosteringSetting,
  getSpRosteringSettingLoader,
  getSpRosteringSettingUpdateLoader,
} from "store/selector/sp.selector";

const mapDispatchToProps = {
  spRosteringSettingsRequest,
  spRosteringSettingsUpdateRequest,
};

const mapStateToProps = (state) => {
  const spRosteringSettingLoader = getSpRosteringSettingLoader(state);
  const spRosteringSetting = getSpRosteringSetting(state);
  const spRosteringSettingUpdateLoader =
    getSpRosteringSettingUpdateLoader(state);
  return {
    spRosteringSettingLoader,
    spRosteringSetting,
    spRosteringSettingUpdateLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RosteringSetting);
