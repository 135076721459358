import ACTIONS, { FETCH_STATUS } from "../constants";

export const reSendOTP = {
  pending: {
    type: ACTIONS.RESEND_OTP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.RESEND_OTP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.RESEND_OTP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const reSendOTPRequest = (state) => ({
  type: ACTIONS.RESEND_OTP_CALL,
  payload: state,
});

export const sendOTPForConfirmation = {
  pending: {
    type: ACTIONS.SEND_OTP_FOR_CONFIRMATION.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SEND_OTP_FOR_CONFIRMATION.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SEND_OTP_FOR_CONFIRMATION.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const sendOTPForConfirmationRequest = (state) => ({
  type: ACTIONS.SEND_OTP_FOR_CONFIRMATION_CALL,
  payload: state,
});

export const sendOTP = {
  pending: {
    type: ACTIONS.SEND_OTP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SEND_OTP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SEND_OTP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const sendOTPRequest = (state) => ({
  type: ACTIONS.SEND_OTP_CALL,
  payload: state,
});

export const loginAttempt = {
  pending: {
    type: ACTIONS.LOGIN.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.LOGIN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.LOGIN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getLoginRequest = (state) => ({
  type: ACTIONS.LOGIN_CALL,
  payload: state,
});

export const logoutAttempt = {
  pending: {
    type: ACTIONS.LOGOUT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: () => ({
    type: ACTIONS.LOGOUT.SUCCESS,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.LOGOUT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const callLogout = () => ({
  type: ACTIONS.LOGOUT_BEGIN,
});

export const storeCurrentOpenTab = (payload) => ({
  type: ACTIONS.STORE_CURRENT_OPEN_TAB,
  payload,
});

export const updateAuthUser = (data) => ({
  type: ACTIONS.UPDATE_AUTH_USER,
  data,
});

export const authenticatedUser = (state) => ({
  type: ACTIONS.AUTHENTICATED_USER,
  data: state,
});

export const resendEmailVerificatioCode = {
  pending: {
    type: ACTIONS.RESEND_VERIFICATION_CODE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.RESEND_VERIFICATION_CODE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.RESEND_VERIFICATION_CODE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const resendEmailVerificatioCodeRequest = (payload, callback) => ({
  type: ACTIONS.RESEND_VERIFICATION_CODE_CALL,
  payload,
  callback,
});

export const hasUnSeenNotification = (data) => ({
  type: ACTIONS.HAS_UNSEEN_NOTIFICATION,
  payload: data,
});

export const payrollAuthenticationUpdateLocally = (flag) => ({
  type: ACTIONS.PAYROLL_AUTHENTICATION_UPDATE_LOCALLY,
  flag,
});

export const payrollExportCallback = (data) => ({
  type: ACTIONS.PAYROLL_EXPORT_CALLBACK,
  data,
});

export const roleAndPermisisonCreatedOrUpdated = {
  pending: {
    type: ACTIONS.ROLE_AND_PERMISSION_CREATE_OR_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data, isUpdateCall) => ({
    type: ACTIONS.ROLE_AND_PERMISSION_CREATE_OR_UPDATE.SUCCESS,
    data,
    isUpdateCall,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.ROLE_AND_PERMISSION_CREATE_OR_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const roleAndPermisisonCreateOrUpdateRequest = (state) => ({
  type: ACTIONS.ROLE_AND_PERMISSION_CREATE_OR_UPDATE_CALL,
  payload: state,
});

export const defaultRolesAndPermissions = {
  pending: {
    type: ACTIONS.DEFAULT_ROLE_AND_PERMISSION.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.DEFAULT_ROLE_AND_PERMISSION.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.DEFAULT_ROLE_AND_PERMISSION.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const defaultRolesAndPermissionsRequest = (state) => ({
  type: ACTIONS.DEFAULT_ROLE_AND_PERMISSION_CALL,
  payload: state,
});

export const resetDefaultRolesPermissionLocally = () => ({
  type: ACTIONS.RESET_DEFAULT_ROLE_AND_PERMISSION_LOCALLY,
});

export const rolesAndPermissionslist = {
  pending: {
    type: ACTIONS.ROLE_AND_PERMISSION_LISTED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.ROLE_AND_PERMISSION_LISTED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.ROLE_AND_PERMISSION_LISTED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const rolesAndPermissionslistRequest = (state) => ({
  type: ACTIONS.ROLE_AND_PERMISSION_LIST_CALL,
  payload: state,
});

export const roleAuthenticateUserRequest = () => ({
  type: ACTIONS.ROLE_AUTHENTICATE_USER_CALL,
});

export const updateStripePaymentSettingLocally = (data) => ({
  type: ACTIONS.UPDATE_PAYMENT_SETTING_LOCALLY,
  data,
});
