import React, { useState, useEffect, useMemo, useRef } from "react";
import isNil from "lodash/isNil";
import { classNames } from "Utils/utils";
import { CONTROL_SIZES } from "Constants/constant";

const NewCustomInput = React.forwardRef((props, ref) => {
  const {
    asElement: Component = "input",
    className = "",
    disabled = false,
    invalid = false,
    prefix = null,
    size = "md", // Default size is set to "md"
    suffix = null,
    textArea = false,
    type = "text",
    style = {},
    unstyle = false,
    error = false,
    noStyle = false,
    ...rest
  } = props;

  const [prefixGutter, setPrefixGutter] = useState(0);
  const [suffixGutter, setSuffixGutter] = useState(0);

  const fixControlledValue = (val) => (val == null ? "" : val);

  if ("value" in props) {
    rest.value = fixControlledValue(props.value);
    delete rest.defaultValue;
  }

  const inputSizeClass = `input-${size} h-${CONTROL_SIZES[size]}`;
  const inputClass = classNames(
    // "input",
    "did-floating-input",
    // suffix && "!pl-[30px]",
    !textArea && inputSizeClass,
    !invalid && "focus:ring-blue-500 focus:border-blue-500",
    disabled && "input-disabled",
    (error || invalid) && "input-invalid",
    textArea && "input-textarea",
    className
  );

  const prefixNode = useRef();
  const suffixNode = useRef();

  const getAffixSize = () => {
    if (!prefixNode.current && !suffixNode.current) return;

    const prefixNodeWidth = prefixNode?.current?.offsetWidth;
    const suffixNodeWidth = suffixNode?.current?.offsetWidth;

    if (!isNil(prefixNodeWidth)) setPrefixGutter(prefixNodeWidth);
    if (!isNil(suffixNodeWidth)) setSuffixGutter(suffixNodeWidth);
  };

  useEffect(() => {
    getAffixSize();
  }, [prefix, suffix]);

  const remToPxConvertion = (pixel) => 0.0625 * pixel;

  const affixGutterStyle = {
    paddingLeft: prefix
      ? `${remToPxConvertion(prefixGutter) + 1}rem`
      : undefined,
    paddingRight: suffix
      ? `${remToPxConvertion(suffixGutter) + 1}rem`
      : undefined,
  };

  const inputProps = {
    className: noStyle ? "" : !unstyle ? inputClass : "",
    disabled,
    type,
    ref,
    ...rest,
  };

  const renderTextArea = <textarea style={style} {...inputProps}></textarea>;

  const renderInput = (
    <Component
      style={noStyle ? {} : { ...affixGutterStyle, ...style }}
      {...inputProps}
    />
  );

  const renderAffixInput = (
    <span className='input-wrapper'>
      {prefix && (
        <div
          className='input-suffix-start'
          ref={(node) => (prefixNode.current = node)}
        >
          {prefix}
        </div>
      )}
      {renderInput}
      {suffix && (
        <div
          className='input-suffix-end p-[10px]'
          ref={(node) => (suffixNode.current = node)}
        >
          {suffix}
        </div>
      )}
    </span>
  );

  const renderChildren = () => {
    if (textArea) return renderTextArea;
    if (prefix || suffix) return renderAffixInput;
    return renderInput;
  };

  return renderChildren();
});

export default NewCustomInput;
