import React from "react";
import workerAnniversary from "../../../../assets/dashboard/worker.jpg";
import { baseURLImage, formatDateToAustralian, getNameProfile } from "Utils/utils";

const AnniversaryCard = ({ item }) => {
  const backgroundImageStyle = {
    backgroundImage: `url(${workerAnniversary})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundRepeat: "no-repeat", // Adjust as needed
  };

  //check date if date is today
  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() && date.getMonth() === today.getMonth()
    );
  };

  // Usage example:
  const joiningDate = new Date(item?.joining_date);
  const isBirthdayToday = isToday(joiningDate);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  return (
    <div
      className={`h-full w-full p-6 border  `}
      style={backgroundImageStyle}
    >
      <div className="flex justify-end">
        <div className="mr-4 text-right">
          <div className="font-lg font-bold text-gray-900 capitalize">
            {item?.name}
          </div>
          <p className={`text-sm  text-bold } `}>
                Has Work Anniversary {isBirthdayToday?"":"on"} {isBirthdayToday ? "Today" :  formatDateToAustralian(item?.joining_date)}
              </p>
        </div>
        <div className="h-12 w-12 flex-shrink-0">
          <img
            onError={(e) => handleImageError(e, item?.name, item?.name)}
            className="object-cover h-12 w-12 rounded-full"
            src={
              item?.photo
                ? baseURLImage(item?.photo)
                : getNameProfile(item.name, item?.name || " ")
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AnniversaryCard;
