import ACTIONS, { FETCH_STATUS } from "../constants";

// export const getCaseNoteDetailsAction = {
//   pending: {
//     type: ACTIONS.GET_CASE_NOTE_DETAIL.PENDING,
//     status: FETCH_STATUS.LOADING
//   },
//   success: (data) => ({
//     type: ACTIONS.GET_CASE_NOTE_DETAIL.SUCCESS,
//     data,
//     status: FETCH_STATUS.SUCCESS
//   }),
//   error: (error) => ({
//     type: ACTIONS.GET_CASE_NOTE_DETAIL.ERROR,
//     error,
//     status: FETCH_STATUS.ERROR
//   })
// };
// export const getCaseNoteDetailRequest = (payload) => ({
//   type: ACTIONS.GET_CASE_NOTE_DETAIL_CALL,
//   payload
// });

export const getCOSDashboardDataAction = {
  pending: {
    type: ACTIONS.GET_COS_DASHBOARD_DATA.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_COS_DASHBOARD_DATA.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_COS_DASHBOARD_DATA.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCOSDashboardDataRequest = (payload) => ({
  type: ACTIONS.GET_COS_DASHBOARD_DATA_CALL,
  payload,
});

export const updateCaseNotesAction = {
  pending: {
    type: ACTIONS.UPDATE_CASE_NOTE_DETAIL.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.UPDATE_CASE_NOTE_DETAIL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.UPDATE_CASE_NOTE_DETAIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateCaseNoteRequest = (payload) => ({
  type: ACTIONS.UPDATE_CASE_NOTE_DETAIL_CALL,
  payload,
});

export const getCaseNoteDetailsAction = {
  pending: {
    type: ACTIONS.GET_CASE_NOTE_DETAIL.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CASE_NOTE_DETAIL.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CASE_NOTE_DETAIL.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCaseNoteDetailRequest = (payload) => ({
  type: ACTIONS.GET_CASE_NOTE_DETAIL_CALL,
  payload,
});

export const emptyNewData = () => ({
  type: ACTIONS.REMOVE_SETTING_DATA,
});

export const handleDynamicBreadCrumbForHRMRequest = (payload) => ({
  type: ACTIONS.DYNAMIC_BREADCRUMB_FOR_HRM_CALL,
  payload,
});

export const setUpdatedFile = (payload) => ({
  type: ACTIONS.SET_UPDATED_FILE_CALL,
  payload,
});

export const getFolderListForHRMAction = {
  pending: {
    type: ACTIONS.GET_FOLDER_LIST_FOR_HRM.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_FOLDER_LIST_FOR_HRM.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_FOLDER_LIST_FOR_HRM.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getFolderListForHRMRequest = (payload) => ({
  type: ACTIONS.GET_FOLDER_LIST_FOR_HRM_CALL,
  payload,
});

export const createFolderAction = {
  pending: {
    type: ACTIONS.CREATE_NEWFOLDER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_NEWFOLDER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_NEWFOLDER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateFolderSuccessMessge = (value) => ({
  type: ACTIONS.RESET_FOLDER_SUCCESS_VALUE,
  data: value,
});

export const createFolderRequest = (payload) => ({
  type: ACTIONS.CREATE_NEWFOLDER_CALL,
  payload,
});

export const updateFolder = {
  pending: {
    type: ACTIONS.UPDATE_FOLDER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.UPDATE_FOLDER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.UPDATE_FOLDER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateFolderRequest = (payload) => ({
  type: ACTIONS.UPDATE_FOLDER_CALL,
  payload,
});

export const removeFolder = {
  pending: {
    type: ACTIONS.REMOVE_FOLDER.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.REMOVE_FOLDER.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.REMOVE_FOLDER.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const removeFolderRequest = (payload) => ({
  type: ACTIONS.REMOVE_FOLDER_CALL,
  payload,
});

export const removeFile = {
  pending: {
    type: ACTIONS.REMOVE_FILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.REMOVE_FILE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.REMOVE_FILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const removeFileRequest = (payload) => ({
  type: ACTIONS.REMOVE_FILE_CALL,
  payload,
});

export const updatePriority = {
  pending: {
    type: ACTIONS.UPDATE_PRIORITY.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.UPDATE_PRIORITY.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.UPDATE_PRIORITY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updatePriorityRequest = (payload) => ({
  type: ACTIONS.UPDATE_PRIORITY_CALL,
  payload,
});

export const updateContact = {
  pending: {
    type: ACTIONS.UPDATE_CONTACT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.UPDATE_CONTACT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.UPDATE_CONTACT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateContactRequest = (payload) => ({
  type: ACTIONS.UPDATE_CONTACT_CALL,
  payload,
});

export const updateCategory = {
  pending: {
    type: ACTIONS.UPDATE_CATEGORY.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.UPDATE_CATEGORY.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.UPDATE_CATEGORY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateCategoryRequest = (payload) => ({
  type: ACTIONS.UPDATE_CATEGORY_CALL,
  payload,
});

export const getDashboardData = {
  pending: {
    type: ACTIONS.GET_DASHBOARD_DATA.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_DASHBOARD_DATA.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_DASHBOARD_DATA.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getDashboardDataRequest = (payload) => ({
  type: ACTIONS.GET_DASHBOARD_DATA_CALL,
  payload,
});

export const getCategorySettingData = {
  pending: {
    type: ACTIONS.COS_SETTING_CATEGORIES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.COS_SETTING_CATEGORIES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.COS_SETTING_CATEGORIES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCategorySettingRequest = (payload) => ({
  type: ACTIONS.COS_SETTING_CATEGORIES_CALL,
  payload,
});

export const getContactSettingData = {
  pending: {
    type: ACTIONS.COS_SETTING_CONTACT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.COS_SETTING_CONTACT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.COS_SETTING_CONTACT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getContactSettingRequest = (payload) => ({
  type: ACTIONS.COS_SETTING_CONTACT_CALL,
  payload,
});

export const getPrioritySettingData = {
  pending: {
    type: ACTIONS.COS_SETTING_PRIORITIES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.COS_SETTING_PRIORITIES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.COS_SETTING_PRIORITIES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getPrioritySettingRequest = (payload) => ({
  type: ACTIONS.COS_SETTING_PRIORITIES_CALL,
  payload,
});

export const fundingSetupParticipantFosCos = {
  pending: {
    type: ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fundingSetupParticipantFosCosRequest = (payload) => ({
  type: ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_FOR_COS_CALL,
  payload,
});

export const getCosReportingForCaseNotesFundingTableData = {
  success: (data) => ({
    type: ACTIONS.GET_COS_REPORTING_FOR_CASE_NOTES_TABLEDATA.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
};

export const getCosReportingFundingTableData = {
  pending: {
    type: ACTIONS.GET_COS_REPORTING_TABLEDATA.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_COS_REPORTING_TABLEDATA.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_COS_REPORTING_TABLEDATA.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCosReportingFundingTableDataRequest = (payload) => ({
  type: ACTIONS.GET_COS_REPORTING_TABLEDATA_CALL,
  payload,
});

export const getPeriorityList = {
  pending: {
    type: ACTIONS.GET_PERIORITY_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_PERIORITY_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_PERIORITY_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getPeriorityListRequest = (payload) => ({
  type: ACTIONS.GET_PERIORITY_LIST_CALL,
  payload,
});

export const createNewPeriority = {
  pending: {
    type: ACTIONS.CREATE_NEW_PERIORITY.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_NEW_PERIORITY.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_NEW_PERIORITY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createNewPeriorityRequest = (payload) => ({
  type: ACTIONS.CREATE_NEW_PERIORITY_CALL,
  payload,
});

export const getContactList = {
  pending: {
    type: ACTIONS.GET_CONTACT_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CONTACT_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CONTACT_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getContactListRequest = (payload) => ({
  type: ACTIONS.GET_CONTACT_LIST_CALL,
  payload,
});

export const getCatogeryList = {
  pending: {
    type: ACTIONS.GET_CATOGERY_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CATOGERY_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CATOGERY_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCatogeryListRequest = (payload) => ({
  type: ACTIONS.GET_CATOGERY_LIST_CALL,
  payload,
});

export const createNewContact = {
  pending: {
    type: ACTIONS.CREATE_NEW_CONTACT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_NEW_CONTACT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_NEW_CONTACT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createNewContactRequest = (payload) => ({
  type: ACTIONS.CREATE_NEW_CONTACT_CALL,
  payload,
});

export const createNewCategory = {
  pending: {
    type: ACTIONS.CREATE_NEW_CATEGORY.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_NEW_CATEGORY.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_NEW_CATEGORY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createNewCategoryRequest = (payload) => ({
  type: ACTIONS.CREATE_NEW_CATEGORY_CALL,
  payload,
});

export const changeNDISClaimInvoiceActions = {
  pending: {
    type: ACTIONS.CHANGE_INVOICE_STATUS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CHANGE_INVOICE_STATUS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CHANGE_INVOICE_STATUS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const changeNDISClaimCaseNoteInvoiceStatusRequest = (payload) => ({
  type: ACTIONS.CHANGE_INVOICE_STATUS_CALL,
  payload,
});

export const changeNDISClaimActions = {
  pending: {
    type: ACTIONS.CHANGE_NDIS_CLAIM_STATUS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CHANGE_NDIS_CLAIM_STATUS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CHANGE_NDIS_CLAIM_STATUS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const changeNDISClaimCaseNoteStatusRequest = (payload) => ({
  type: ACTIONS.CHANGE_NDIS_CLAIM_STATUS_CALL,
  payload,
});

export const getCreatedCosInvoicesRviews = {
  pending: {
    type: ACTIONS.GET_CREATED_COS_INVOICES_RIVIEW.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CREATED_COS_INVOICES_RIVIEW.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CREATED_COS_INVOICES_RIVIEW.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCreatedCosInvoicesReviewRequest = (payload) => ({
  type: ACTIONS.GET_CREATED_COS_INVOICES_RIVIEW_CALL,
  payload,
});

export const getCreatedCosInvoices = {
  pending: {
    type: ACTIONS.GET_CREATED_COS_INVOICES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CREATED_COS_INVOICES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CREATED_COS_INVOICES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCreatedCosInvoicesRequest = (payload) => ({
  type: ACTIONS.GET_CREATED_COS_INVOICES_CALL,
  payload,
});

export const createCosInvoice = {
  pending: {
    type: ACTIONS.CREATE_COS_INVOICE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_COS_INVOICE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_COS_INVOICE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCreateCosInvoicesRequest = (payload) => ({
  type: ACTIONS.CREATE_COS_INVOICE_CALL,
  payload,
});

export const getCosInvoicesListRequest = (payload) => ({
  type: ACTIONS.GET_COS_INVOICES_CALL,
  payload,
});

export const getCoseInvoices = {
  pending: {
    type: ACTIONS.GET_COS_INVOICES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_COS_INVOICES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_COS_INVOICES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const emptySettingData = () => ({
  type: ACTIONS.REMOVE_SETTINGS_DATA,
});

export const RemoveReviewCaseNoteList = (payload) => ({
  type: ACTIONS.REMOVE_CASE_NOTES_REVIEW_LIST,
  payload,
});

export const getCaseNotesReviewRequest = (payload) => ({
  type: ACTIONS.GET_CASE_NOTES_REVIEW_CALL,
  payload,
});

export const getCaseNotesReview = {
  pending: {
    type: ACTIONS.GET_CASE_NOTES_REVIEW.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CASE_NOTES_REVIEW.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CASE_NOTES_REVIEW.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getClaimedCaseNotesListRequest = (payload) => ({
  type: ACTIONS.GET_CLAIMED_CASE_NOTES_LIST_CALL,
  payload,
});

export const getClaimedCaseNotesList = {
  pending: {
    type: ACTIONS.GET_CLAIMED_CASE_NOTES_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CLAIMED_CASE_NOTES_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CLAIMED_CASE_NOTES_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const addCaseNotesToClaimListRequest = (payload) => ({
  type: ACTIONS.ADD_CASE_NOTES_TO_CLAIM_LIST_CALL,
  payload,
});

export const addCaseNotesToClaimList = {
  pending: {
    type: ACTIONS.ADD_CASE_NOTES_TO_CLAIM_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.ADD_CASE_NOTES_TO_CLAIM_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.ADD_CASE_NOTES_TO_CLAIM_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCaseNotesListForClaimsRequest = (payload) => ({
  type: ACTIONS.GET_CASE_NOTES_LIST_FOR_CLAIM_CALL,
  payload,
});

export const getCaseNotesListForClaim = {
  pending: {
    type: ACTIONS.GET_CASE_NOTES_LIST_FOR_CLAIM.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CASE_NOTES_LIST_FOR_CLAIM.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CASE_NOTES_LIST_FOR_CLAIM.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const serviceSupportsForDeliveredServiceCaseNotesFetched = (data) => ({
  type: ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CASE_NOTES,
  data,
  status: FETCH_STATUS.SUCCESS,
});

export const getCaseNotesHistoryList = {
  pending: {
    type: ACTIONS.GET_CASE_NOTES_HISTROY.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CASE_NOTES_HISTROY.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CASE_NOTES_HISTROY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCaseNotesHistoryRequest = (payload) => ({
  type: ACTIONS.GET_CASE_NOTES_HISTROY_CALL,
  payload,
});

export const getCaseNotesClients = {
  pending: {
    type: ACTIONS.GET_CASE_NOTES_CLIENT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CASE_NOTES_CLIENT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CASE_NOTES_CLIENT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getClientsRequest = (payload) => ({
  type: ACTIONS.GET_CASE_NOTES_CLIENT_CALL,
  payload,
});

export const getSingleSupportTrack = {
  pending: {
    type: ACTIONS.GET_SINGLE_SUPPORT_TRACT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_SINGLE_SUPPORT_TRACT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_SINGLE_SUPPORT_TRACT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSingleSupportTrackRequest = (payload) => ({
  type: ACTIONS.GET_SINGLE_SUPPORT_TRACT_CALL,
  payload,
});

export const getSupportTrackList = {
  pending: {
    type: ACTIONS.GET_SUPPORT_TRACT_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_SUPPORT_TRACT_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_SUPPORT_TRACT_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSupportTrackListRequest = (payload) => ({
  type: ACTIONS.GET_SUPPORT_TRACT_LIST_CALL,
  payload,
});

export const resetFundingParticipantProfileData = () => ({
  type: ACTIONS.RESET_PARTICIAPNT_PROFILE,
});

export const getReportData = {
  pending: {
    type: ACTIONS.GET_REPORT_DATA.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_REPORT_DATA.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_REPORT_DATA.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getRepoertDataRequest = (payload) => ({
  type: ACTIONS.GET_REPORT_DATA_CALL,
  payload,
});
export const deleteClientTask = {
  pending: {
    type: ACTIONS.DELETE_CLIENT_TASKS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.DELETE_CLIENT_TASKS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.DELETE_CLIENT_TASKS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deleteClientTaskListRequest = (payload) => ({
  type: ACTIONS.DELETE_CLIENT_TASKS_CALL,
  payload,
});
export const updateClientTask = {
  pending: {
    type: ACTIONS.UPDATE_CLIENT_TASKS_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.UPDATE_CLIENT_TASKS_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.UPDATE_CLIENT_TASKS_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateClientTaskListRequest = (payload) => ({
  type: ACTIONS.UPDATE_CLIENT_TASKS_LIST_CALL,
  payload,
});
export const getClientTaskList = {
  pending: {
    type: ACTIONS.GET_CLIENT_TASKS_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_CLIENT_TASKS_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_CLIENT_TASKS_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getClientTaskListRequest = (payload) => ({
  type: ACTIONS.GET_CLIENT_TASKS_LIST_CALL,
  payload,
});

export const createClientTask = {
  pending: {
    type: ACTIONS.CREATE_CLIENT_TASK.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_CLIENT_TASK.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_CLIENT_TASK.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createClientTaskRequest = (payload) => ({
  type: ACTIONS.CREATE_CLIENT_TASK_CALL,
  payload,
});

export const getCaseNotes = {
  pending: {
    type: ACTIONS.GET_COS_CASENOTES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.GET_COS_CASENOTES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.GET_COS_CASENOTES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCaseNotesRequest = (payload) => ({
  type: ACTIONS.GET_CASENOTES_LIST_CALL,
  payload,
});

export const caseNotesCreate = {
  pending: {
    type: ACTIONS.COS_CASENOTES_CREATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.COS_CASENOTES_CREATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.COS_CASENOTES_CREATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const caseNotesCreateRequest = (payload) => ({
  type: ACTIONS.CASENOTES_CREATE_CALL,
  payload,
});

export const getCOSParticipantsProfile = {
  pending: {
    type: ACTIONS.COS_PARTICIPANTS_PROFILE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.COS_PARTICIPANTS_PROFILE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.COS_PARTICIPANTS_PROFILE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCosParticipantsProfileRequest = (payload) => ({
  type: ACTIONS.COS_PARTICIPANTS_PROFILE_CALL,
  payload,
});

export const getAllCatalogServices = {
  pending: {
    type: ACTIONS.CATALOG_SERVICES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CATALOG_SERVICES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CATALOG_SERVICES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCatalogServicesRequest = (payload) => ({
  type: ACTIONS.CATALOG_SERVICES_CALL,
  payload,
});

export const catalogVersions = {
  pending: {
    type: ACTIONS.CATALOG_VERSIONS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CATALOG_VERSIONS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CATALOG_VERSIONS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const catalogVersionRequest = (isServiceCall = true) => ({
  type: ACTIONS.CATALOG_VERSIONS_CALL,
  isServiceCall,
});

export const storeAndUpdateFundingSetup = {
  pending: {
    type: ACTIONS.STORE_AND_UPDATE_FUNDING_SETUP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.STORE_AND_UPDATE_FUNDING_SETUP.SUCCESS,
    data: data.funding,
    isUpdate: data.isUpdate,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.STORE_AND_UPDATE_FUNDING_SETUP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const storeAndUpdateFundingSetupRequest = (payload) => ({
  type: ACTIONS.STORE_AND_UPDATE_FUNDING_SETUP_CALL,
  payload: payload.payload,
  isUpdate: payload.isUpdate,
});

export const fundingSetupParticipant = {
  pending: {
    type: ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getFundingSetupRequest = (payload) => ({
  type: ACTIONS.ALL_FUNDING_SETUP_PARTICIPANT_CALL,
  payload,
});

export const resetParticipantFundings = () => ({
  type: ACTIONS.RESET_PARTICIAPNT_ALL_FUNDINGS,
});

export const getAllCatalogServicesSubCategory = {
  pending: {
    type: ACTIONS.CATALOG_SERVICES_SUB_CATEGORY.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CATALOG_SERVICES_SUB_CATEGORY.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CATALOG_SERVICES_SUB_CATEGORY.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getCatalogServicesSubCategoryRequest = (payload) => ({
  type: ACTIONS.CATALOG_SERVICES_SUB_CATEGORY_CALL,
  payload,
});

export const selectedFundingSetup = {
  pending: {
    type: ACTIONS.SELCETED_FUNDING_SETUP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SELCETED_FUNDING_SETUP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SELCETED_FUNDING_SETUP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getSelectedFundingSetupRequest = (payload) => ({
  type: ACTIONS.SELCETED_FUNDING_SETUP_CALL,
  payload,
});

export const planCatagories = {
  pending: {
    type: ACTIONS.PLAN_CATAGORIES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.PLAN_CATAGORIES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.PLAN_CATAGORIES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getPlanCategoriesRequest = (payload) => ({
  type: ACTIONS.PLAN_CATAGORIES_CALL,
  payload,
});

export const planBudgetCreated = {
  pending: {
    type: ACTIONS.CREATE_PLAN_BUDGET.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_PLAN_BUDGET.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_PLAN_BUDGET.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createPlanBudgetRequest = (payload) => ({
  type: ACTIONS.CREATE_PLAN_BUDGET_CALL,
  payload,
});

export const planBudgetList = {
  pending: {
    type: ACTIONS.PLAN_BUDGET_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.PLAN_BUDGET_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.PLAN_BUDGET_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getPlanBudgetListRequest = (payload) => ({
  type: ACTIONS.PLAN_BUDGET_LIST_CALL,
  payload,
});

export const planBudgetRemoved = (data) => ({
  type: ACTIONS.PLAN_BUDGET_DELETED,
  data,
});

export const serviceCategoryRemove = {
  pending: {
    type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_DELETE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_DELETE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_DELETE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getServiceCategoryRemoveRequest = (payload) => ({
  type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_DELETE_CALL,
  payload,
});

export const planBudgetCatAndSubCat = {
  pending: {
    type: ACTIONS.PLAN_BUDGET_CAT_AND_SUB_CAT.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.PLAN_BUDGET_CAT_AND_SUB_CAT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.PLAN_BUDGET_CAT_AND_SUB_CAT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getPlanBudgetCatAndSubCatRequest = (payload) => ({
  type: ACTIONS.PLAN_BUDGET_CAT_AND_SUB_CAT_CALL,
  payload,
});

export const serviceCategoryPlanBudgetStored = {
  pending: {
    type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_STORE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_STORE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_STORE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const storeServiceCategoryPlanBudgetRequest = (payload) => ({
  type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_STORE_CALL,
  payload,
});

export const serviceCategoryPlanBudgetFetched = {
  pending: {
    type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_FETCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchServiceCategoryPlanBudgetRequest = (payload) => ({
  type: ACTIONS.SERVICE_CATEGORY_PLAN_BUDGET_FETCH_CALL,
  payload,
});

export const getExternalSPListFetched = {
  pending: {
    type: ACTIONS.EXTERNAL_SP_LIST_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.EXTERNAL_SP_LIST_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.EXTERNAL_SP_LIST_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getExternalSPListRequest = (payload) => ({
  type: ACTIONS.EXTERNAL_SP_LIST_FETCH_CALL,
  payload,
});

export const createExternalSP = {
  pending: {
    type: ACTIONS.EXTERNAL_SP_CREATED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.EXTERNAL_SP_CREATED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.EXTERNAL_SP_CREATED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createExternalSPRequest = (state) => ({
  type: ACTIONS.EXTERNAL_SP_CREATE_CALL,
  payload: state,
});

export const fundingNotesList = {
  pending: {
    type: ACTIONS.FUNDING_NOTES_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.FUNDING_NOTES_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.FUNDING_NOTES_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getFundingNotesListRequest = (payload) => ({
  type: ACTIONS.FUNDING_NOTES_LIST_CALL,
  payload,
});

export const fundingNotesCreated = {
  pending: {
    type: ACTIONS.CREATE_FUNDING_NOTES.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.CREATE_FUNDING_NOTES.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.CREATE_FUNDING_NOTES.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createFundingNotesRequest = (payload) => ({
  type: ACTIONS.CREATE_FUNDING_NOTES_CALL,
  payload,
});

// export const fundingNotesRemove = {
//   pending: {
//     type: ACTIONS.FUNDING_NOTES_DELETE.PENDING,
//     status: FETCH_STATUS.LOADING,
//   },
//   success: (data) => ({
//     type: ACTIONS.FUNDING_NOTES_DELETE.SUCCESS,
//     data,
//     status: FETCH_STATUS.SUCCESS,
//   }),
//   error: (error) => ({
//     type: ACTIONS.FUNDING_NOTES_DELETE.ERROR,
//     error,
//     status: FETCH_STATUS.ERROR,
//   }),
// };

// export const getFundingNotesRemoveRequest = (payload) => ({
//   type: ACTIONS.FUNDING_NOTES_DELETE_CALL,
//   payload,
// });

export const fundingNotesRemoved = (data) => ({
  type: ACTIONS.FUNDING_NOTES_DELETED,
  data,
});

export const ClientTaskListUnmount = () => ({
  type: ACTIONS.GET_CLIENT_TASKS_LIST_UNMOUNT,
});

export const removeClientTaskSuccess = () => ({
  type: ACTIONS.CREATE_CLIENT_TASK_UNMOUNT,
});

export const serviceSupportBudgetCreated = {
  pending: {
    type: ACTIONS.SERVICE_SUPPORT_BUDGET_CREATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SERVICE_SUPPORT_BUDGET_CREATE.SUCCESS,

    data: data.serviceSupportBudget,
    service_support_budget: data.service_support_budget,
    isUpdate: data.isUpdate,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SERVICE_SUPPORT_BUDGET_CREATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const serviceSupportCreateRequest = (payload) => ({
  type: ACTIONS.SERVICE_SUPPORT_BUDGET_CREATE_CALL,
  payload,
});

export const serviceSupportBudgetFetched = {
  pending: {
    type: ACTIONS.SERVICE_SUPPORT_BUDGET_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SERVICE_SUPPORT_BUDGET_FETCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SERVICE_SUPPORT_BUDGET_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const serviceSupportBudgetFetchRequest = (payload) => ({
  type: ACTIONS.SERVICE_SUPPORT_BUDGET_FETCH_CALL,
  payload,
});

export const serviceSupportsForDeliveredServiceFetched = {
  pending: {
    type: ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const cosParticipant = {
  pending: {
    type: ACTIONS.COS_PARTICIPANT_LIST.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.COS_PARTICIPANT_LIST.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.COS_PARTICIPANT_LIST.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const fetchServiceSupportsForDeliveredServiceRequest = (payload) => ({
  type: ACTIONS.SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CALL,
  payload,
});
export const clearServiceSupportServicesForCaseNotes = () => ({
  type: ACTIONS.CLEAR_SERVICE_SUPPORT_FOR_DELIVERED_SERVICE_CASE_NOTE,
});

export const getCosParticipantListRequest = (payload) => ({
  type: ACTIONS.COS_PARTICIPANT_LIST_CALL,
  payload,
});

export const supportCoordinators = {
  pending: {
    type: ACTIONS.SUPPORT_COORDINATORS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.SUPPORT_COORDINATORS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.SUPPORT_COORDINATORS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const supportCoordinatorsRequest = () => ({
  type: ACTIONS.SUPPORT_COORDINATORS_CALL,
});

export const manualDeliveredServiceCreated = {
  pending: {
    type: ACTIONS.MANUAL_DELIVERED_SERVICE_CREATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.MANUAL_DELIVERED_SERVICE_CREATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.MANUAL_DELIVERED_SERVICE_CREATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const manualDeliveredServiceCreateRequest = (payload) => ({
  type: ACTIONS.MANUAL_DELIVERED_SERVICE_CREATE_CALL,
  payload,
});

export const deliveredServiceCreated = {
  pending: {
    type: ACTIONS.DELIVERED_SERVICE_CREATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.DELIVERED_SERVICE_CREATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.DELIVERED_SERVICE_CREATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deliveredServiceCreateRequest = (payload) => ({
  type: ACTIONS.DELIVERED_SERVICE_CREATE_CALL,
  payload,
});

export const deliveredServiceFetched = {
  pending: {
    type: ACTIONS.DELIVERED_SERVICE_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.DELIVERED_SERVICE_FETCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.DELIVERED_SERVICE_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deliveredServiceFetchRequest = (payload) => ({
  type: ACTIONS.DELIVERED_SERVICE_FETCH_CALL,
  payload,
});

export const manualDeliveriesFetched = {
  pending: {
    type: ACTIONS.MANUAL_DELIVEREIES_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data) => ({
    type: ACTIONS.MANUAL_DELIVEREIES_FETCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error) => ({
    type: ACTIONS.MANUAL_DELIVEREIES_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const manaulDeliveriesFetchRequest = (payload) => ({
  type: ACTIONS.MANUAL_DELIVEREIES_FETCH_CALL,
  payload,
});
