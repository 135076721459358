import React from "react";
import CheckBox from "components/common/checkBox/checkbox";
import moment from "moment";
import {
  formatDateIntoDMMMY,
  formatDateIntoDMYToDisplay,
  getHrAndMinsFormatting,
} from "Utils/utils";

const ApproveTimesheetItem = (props) => {
  const { timeSheet, handleCheckboxChange, checkboxes } = props;

  return (
    <tr className='text-center even:bg-white odd:bg-gray-50'>
      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          <CheckBox
            handleChange={handleCheckboxChange}
            checked={
              checkboxes.find((x) => x.uuid === timeSheet.uuid) ? true : false
            }
          />
        </div>
      </td>
      <td className='text-start px-3 py-4 text-sm text-gray-500'>
        <div className='capitalize text-gray-500'>{timeSheet.worker_name}</div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {formatDateIntoDMYToDisplay(timeSheet.time_sheet_date)}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {getHrAndMinsFormatting(timeSheet.timesheet_length)}
        </div>
      </td>
      <td className='px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {`${moment(timeSheet.start_time).format(
            "Do MMM YY - HH:mm A"
          )} , ${moment(timeSheet.end_time).format("Do MMM YY - HH:mm A")}`}
        </div>
      </td>
      {/* <td className="px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">
          {moment(shift.end_time, "YYYY-MM-DD HH:mm:ss").format("hh:mm a")}
        </div>
      </td> */}
    </tr>
  );
};

export default ApproveTimesheetItem;
