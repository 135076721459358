import React, { useState, useEffect, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from "../../../helpers/Requests";
import _ from "lodash";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CircularLoader from "../../common/CircularLoader/CircularLoader";
import SideBar from "../../common/SideBar/SideBarNew";
import AlertModal from "../../Modals/alertModal";
import {
  baseURLImage,
  convertLabelIntoName,
  formatDateIntoDMYToDisplay,
  getFormSubTabForParticipantProfile,
  getUrlRole,
  parseBooleanVal,
  selectedTabState,
  textTruncate,
} from "../../../Utils/utils";
import { LoginContext } from "../../../helpers/LoginContext";
import { getNameProfile } from "../../../Utils/utils";
import HumanBodyModal from "../../Modals/HumanBody/HumanBodyModal";
import CustomButton from "../../common/Button/CustomButton";
import { USER_ROLE } from "../../../Constants/constant";
import { roleAccess } from "helpers/RolesPermission";
import SectionInputFields from "../SectionAndSubSectionFields/SectionsInputFields";
import { useSelector } from "react-redux";
import { getUser } from "store/selector/auth.selector";

const SubmittedFormUpdate = () => {
  const navigate = useNavigate();
  const { sideBarPadding } = useContext(LoginContext);
  const { formId, parentSlug } = useParams();
  const location = useLocation();
  const { state } = location;
  const [template, setTemplate] = useState([]);
  const [templateForm, setTemplateForm] = useState([]);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });
  const [fileUploadChecker, setFileUploadChecker] = useState(true);
  const [Index, setIndex] = useState(0);
  const [participantList, setParticipantList] = useState([]);
  const [selectedLegal, setSelectedLegal] = useState("");
  const [signatures, setSignatures] = useState([]);
  const [fileLoader, setFileLoader] = useState("");
  const [openBodyModal, setOpenBodyModal] = useState(false);
  const [selectedBody, setSelectedBody] = useState("");
  const [updatedBody, setUpdateBody] = useState("");
  const [fieldName, setFieldName] = useState();
  const sigCanvasManager = useRef({});
  const currentUser = useSelector(getUser);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchFormData = watch();

  useEffect(() => {
    if (state && !state.hasOwnProperty("particpantUuid")) {
      navigate("/");
    }
    fetchTemplate();
  }, [location]);

  useEffect(() => {
    if (template.lengh > 0) reset(template);
  }, [template]);

  useEffect(() => {
    return () => {
      if (
        state &&
        state.hasOwnProperty("redirectTo") &&
        state.hasOwnProperty("particpantUuid") &&
        getFormSubTabForParticipantProfile(parentSlug)
      ) {
        navigate(
          `/${
            roleAccess([USER_ROLE.Sp_Standard_User])
              ? "participant-profile"
              : "worker-profile"
          }/${state.particpantUuid}`
        );
      } else if (getFormSubTabForParticipantProfile(parentSlug)) {
        selectedTabState.save(
          JSON.stringify(getFormSubTabForParticipantProfile(parentSlug))
        );
      }
    };
  }, []);

  const fetchTemplate = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      let res = await apiGetRequest(
        `${getUrlRole()}/employee/submitted-forms-view?form_id=${formId}`
      );

      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      }
      if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        if (res?.data?.submitted_form?.template?.length > 0) {
          const data = JSON.parse(res?.data?.submitted_form?.template).map(
            (step) => {
              step.status = "";
              return step;
            }
          );
          countTotalSignatureFields(data);
          data[Index].status = "active";
          setTemplateForm(res?.data?.submitted_form);
          // console.log("res?.data?.submitted_form", res?.data?.submitted_form);
          const signatures = JSON.parse(res?.data?.submitted_form?.signatures);
          setTemplate(data);
          console.log("data", data);
          setSignatures(signatures ? [...signatures] : []);
        }
      }
      setRequest({ ...request, isRequested: false, isFailed: false });
    } catch (err) {
      setRequest({ ...request, isRequested: false, isFailed: false });
      console.log(err.message);
    }
  };

  const fileUploadHandler = async (
    e,
    sectionIndex,
    fieldIndex,
    subSectionIndex = null
  ) => {
    setFileUploadChecker(false);
    let fileLoaderName = `${sectionIndex}-${fieldIndex}`;
    if (subSectionIndex !== null) {
      fileLoaderName = `${fileLoaderName}-${subSectionIndex}`;
    }
    setFileLoader(fileLoaderName);
    const formData = new FormData();
    formData.append("form_file", e.target.files[0]);
    try {
      let res = await apiPostRequest(
        `${getUrlRole()}hrm/form-data-file`,
        formData
      );
      if (res.status !== 200) {
        console.log("File upload Failed");
        toast.error("File upload Failed");
        setFileUploadChecker(true);
      }
      if (res.status === 200) {
        toast.success("File uploaded successfully");
        const data = _.cloneDeep(template);
        if (subSectionIndex === null) {
          data[sectionIndex].input_fields[fieldIndex].path = `${res.data.path}`;
          data[sectionIndex].input_fields[
            fieldIndex
          ].value = `${e.target.files[0].name}`;
        } else {
          data[sectionIndex].input_fields[fieldIndex].sub_fields[
            subSectionIndex
          ].path = `${res.data.path}`;
          data[sectionIndex].input_fields[fieldIndex].sub_fields[
            subSectionIndex
          ].value = `${e.target.files[0].name}`;
        }
        setTemplate([...data]);
        setFileUploadChecker(true);
      }
      setFileUploadChecker(true);
      setFileLoader(null);
    } catch (err) {
      console.log(err);
      setFileLoader(null);
      setFileUploadChecker(true);
    }
  };

  const submitFormData = async (formData, formatedData) => {
    const supportPlan = [];
    formatedData.forEach((obj) => {
      obj.input_fields.forEach((x) => {
        if (
          x.type !== "sub_section" &&
          parseBooleanVal(x.isSupportPlan) &&
          x.isSupportPlan
        ) {
          supportPlan.push(x);
        } else if (x.type === "sub_section") {
          x.sub_fields.forEach((y) => {
            if (parseBooleanVal(y.isSupportPlan) && y.isSupportPlan) {
              supportPlan.push(y);
            }
          });
        }
      });
    });

    // console.log("formData", formData);

    const filteredSignatures = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => key !== "template")
    );

    // console.log("filteredSignatures from form Data", filteredSignatures);

    let data1 = [];
    if (filteredSignatures && Object.keys(filteredSignatures).length > 0) {
      Object.entries(filteredSignatures).forEach(
        ([keys, value]) =>
          value !== undefined && value !== null && data1.push({ ...value })
      );
    }
    // console.log("data 1 from form Data before merging", data1);

    // console.log("signatures", signatures);

    setSignatures([...data1, ...signatures]);
    data1 = [...data1, ...signatures];
    // console.log("[...data1, ...signatures]", data1);

    const data = {
      worker_id:
        state && state.hasOwnProperty("participantId")
          ? state.participantId
          : "1",
      hrm_sp_form_temp_id: templateForm.id,
      created_by: currentUser.id,
      is_complete: true,
      template: formatedData,
    };

    const imageData = data1.filter((x) => !x.image.includes("https"));
    // console.log("imageData", imageData);
    if (imageData && imageData.length > 0) {
      Object.assign(data, { signatures: imageData });
    } else {
      Object.assign(data, { signatures: null });
    }

    if (
      supportPlan.length > 0 &&
      templateForm &&
      templateForm?.title?.toLowerCase() === "initial assessment form"
    ) {
      Object.assign(data, { support_plan_data: JSON.stringify(supportPlan) });
    }
    // console.log("data", data);
    setRequest({ ...request, isRequested: true });
    try {
      const res = await apiPutRequest(
        `${getUrlRole()}/employee/submitted-form-update?form_id=${formId}`,
        data
      );

      if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        toast.success("Form updated Successfully");
        if (
          templateForm &&
          (templateForm?.participant_uuid || templateForm?.worker_uuid)
        ) {
          if (getFormSubTabForParticipantProfile(parentSlug) && !state) {
            selectedTabState.save(
              JSON.stringify(getFormSubTabForParticipantProfile(parentSlug))
            );
          }
          navigate(
            `/${
              roleAccess([USER_ROLE.Sp_Standard_User])
                ? "participant-profile"
                : "worker-profile"
            }/${templateForm?.participant_uuid ?? templateForm?.worker_uuid}`
          );
        }
      }
      setRequest({ ...request, isRequested: false });
    } catch (err) {
      console.log(err);
      setRequest({ ...request, isRequested: false, isFailed: true });
    }
  };

  const dataFormatter = async (data) => {
    const temp = _.cloneDeep(template);
    temp.forEach((t, tempIndex) => {
      for (let j = 0; j < temp[tempIndex].input_fields.length; j++) {
        if (temp[tempIndex].input_fields[j].type === "address") {
          const address = data.template[tempIndex].input_fields[j]["address"];
          temp[tempIndex].input_fields[j].address = { ...address };
        }
        if (temp[tempIndex].input_fields[j].type === "sub_section") {
          temp[tempIndex].input_fields[j].sub_fields.map((item, k) => {
            if (
              temp[tempIndex].input_fields[j].sub_fields[k].type === "address"
            ) {
              const address =
                data.template[tempIndex].input_fields[j].sub_section[k][
                  "address"
                ];
              temp[tempIndex].input_fields[j].sub_fields[k].address = {
                ...address,
              };
            }
            if (
              temp[tempIndex].input_fields[j].sub_fields[k].type === "file" ||
              temp[tempIndex].input_fields[j].sub_fields[k].type === "signature"
            ) {
              return null;
            }
            if (item.type !== "table") {
              const name = convertLabelIntoName(item.lable);
              item.value =
                data.template[tempIndex].input_fields[j].sub_section[k][
                  `${name}`
                ];
            } else {
              const rowDataTemp = _.cloneDeep(
                temp[tempIndex].input_fields[j].sub_fields[k].rows
              );
              let rows = [];
              temp[tempIndex].input_fields[j].sub_fields[k].rows.forEach(
                (rowData, rowIndex) => {
                  rows.push([]);
                  rowDataTemp[rowIndex].forEach((columnData, columnIndex) => {
                    rows[rowIndex].push({});
                    const name = convertLabelIntoName(
                      rowDataTemp[rowIndex][columnIndex].lable
                    );
                    Object.assign(rowDataTemp[rowIndex][columnIndex], {
                      value:
                        data.template[tempIndex].input_fields[j].sub_fields[k]
                          .rows[rowIndex].columns[columnIndex][name],
                    });
                    Object.assign(rows[rowIndex][columnIndex], {
                      ...rowDataTemp[rowIndex][columnIndex],
                    });
                  });
                }
              );
              Object.assign(temp[tempIndex].input_fields[j].sub_fields[k], {
                rows: [...rows],
              });
            }
            return item;
          });
        }
        if (
          temp[tempIndex].input_fields[j].type === "signature" ||
          temp[tempIndex].input_fields[j].type === "file"
        ) {
          continue;
        }
        if (temp[tempIndex].input_fields[j].type === "table") {
          const rowDataTemp = _.cloneDeep(temp[tempIndex].input_fields[j].rows);
          let rows = [];
          temp[tempIndex].input_fields[j].rows.forEach((rowData, rowIndex) => {
            rows.push([]);
            rowDataTemp[rowIndex].forEach((columnData, columnIndex) => {
              rows[rowIndex].push({});
              const name = convertLabelIntoName(
                rowDataTemp[rowIndex][columnIndex].lable
              );
              Object.assign(rowDataTemp[rowIndex][columnIndex], {
                value:
                  data.template[tempIndex].input_fields[j].rows[rowIndex]
                    .columns[columnIndex][name],
              });
              Object.assign(rows[rowIndex][columnIndex], {
                ...rowDataTemp[rowIndex][columnIndex],
              });
            });
          });
          Object.assign(temp[tempIndex].input_fields[j], { rows: [...rows] });
        } else {
          if (data.template[tempIndex]) {
            if (
              data.template[tempIndex].input_fields &&
              data.template[tempIndex].input_fields.length > 0 &&
              temp[tempIndex].input_fields[j] &&
              data.template[tempIndex].input_fields[j]
            ) {
              const name = convertLabelIntoName(
                temp[tempIndex].input_fields[j].lable
              );
              temp[tempIndex].input_fields[j].value =
                data.template[tempIndex].input_fields[j][`${name}`];
            }
          }
        }
      }
    });

    return [...temp];
  };

  const onSubmit = async (data) => {
    const formatedData = await dataFormatter(data);
    submitFormData(data, formatedData);
  };

  const countTotalSignatureFields = (formData) => {
    if (!formData) return;

    formData.forEach((obj) => {
      obj.input_fields.forEach((x) => {
        if (x.type === "signature") {
          sigCanvasManager.current[`${x.name}`] = { ...x, current: null };
        } else if (x.type === "sub_section") {
          x.sub_fields.forEach((y) => {
            if (y.type === "signature") {
              sigCanvasManager.current[`${y.name}`] = { ...y, current: null };
            }
          });
        }
      });
    });
  };

  const formatIntoPngManager = (
    name,
    templateIndex,
    inputFieldIndex,
    SubFieldIndex = null
  ) => {
    if (sigCanvasManager.current) {
      const dataURL = sigCanvasManager.current[`${name}`].current.toDataURL();
      setSignatures([...signatures.filter((X) => X.name !== name)]);
      return {
        image: dataURL,
        name,
        templateIndex,
        inputFieldIndex,
        SubFieldIndex,
      };
    }
  };

  const clearSignatureManager = (e) => {
    if (sigCanvasManager.current[`${e}`].current) {
      sigCanvasManager.current[`${e}`].current.clear();
    }
    setSignatures([...signatures.filter((X) => X.name !== e)]);
    setValue(`${e}`, null);
  };

  const addRowHandler = (si, fi, sf = null) => {
    const data = _.cloneDeep(template);
    if (sf >= 0 && sf !== null) {
      data[si].input_fields[fi].sub_fields[sf].rows.push(
        data[si].input_fields[fi].sub_fields[sf].columns
      );
    } else {
      data[si].input_fields[fi].rows.push(data[si].input_fields[fi].columns);
    }
    setTemplate([...data]);
  };

  const delteRowHandler = (si, fi, ri, sf = null) => {
    const data = _.cloneDeep(template);
    if (sf !== null) {
      const rows = data[si].input_fields[fi].sub_fields[sf].rows;
      const rowsData =
        watchFormData.template[si].input_fields[fi].sub_fields[sf].rows;
      rows.splice(ri, 1);
      rowsData.splice(ri, 1);
      Object.assign(data[si].input_fields[fi].sub_fields[sf], {
        rows: [...rows],
      });
      setValue(`template[${si}].input_fields[${fi}].sub_fields[${sf}].rows`, [
        ...rowsData,
      ]);
    } else {
      const rows = data[si].input_fields[fi].rows;
      const rowsData = watchFormData.template[si].input_fields[fi].rows;
      rows.splice(ri, 1);
      rowsData.splice(ri, 1);
      Object.assign(data[si].input_fields[fi], {
        rows: [...rows],
      });
      setValue(`template[${si}].input_fields[${fi}].rows`, [...rowsData]);
    }
    setTemplate([...data]);
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const handleAddOther = (sectionIndex = null, subSectionIndex = null) => {
    if (
      !isNaN(sectionIndex) &&
      !isNaN(subSectionIndex) &&
      sectionIndex !== null &&
      subSectionIndex !== null
    ) {
      const data = _.cloneDeep(
        template[sectionIndex].input_fields[subSectionIndex]
      );
      const newObj = { ...data, isAddOther: false, isAddOtherDelete: true };
      setTemplate(
        [...template],
        template[sectionIndex].input_fields.push(newObj)
      );
    }
  };

  const handleRemoveOther = (sectionIndex, subSectionIndex) => {
    if (
      !isNaN(sectionIndex) &&
      !isNaN(subSectionIndex) &&
      sectionIndex !== null &&
      subSectionIndex !== null
    ) {
      const data = _.cloneDeep(template);
      const inputFieldsData = data[sectionIndex].input_fields;
      const filledData = watchFormData.template[sectionIndex].input_fields;
      inputFieldsData.splice(subSectionIndex, 1);
      filledData.splice(subSectionIndex, 1);
      Object.assign(data[sectionIndex], {
        input_fields: [...inputFieldsData],
      });
      setValue(`template[${sectionIndex}].input_fields`, [...filledData]);
      setTemplate([...data]);
    }
  };

  return (
    <div className='min-h-screen bg-gray-100 pb-4'>
      <div className='flex-1 md:flex'>
        <SideBar classes='bg-gradient' />
        <div
          className={
            sideBarPadding
              ? `w-full lg:ml-[4rem] duration-300`
              : `w-full lg:ml-64 duration-300`
          }
        >
          {/* <HeaderAdmin /> */}
          {request.isRequested ? (
            <div className='flex justify-center items-center min-h-screen'>
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            <>
              <div className='sticky z-[9] top-0 px-3 md:px-6 flex justify-between items-center bg-white h-[80px] border-b border-gray-200'>
                <h1 className='text-black font-medium capitalize'>
                  {textTruncate(templateForm.title, 27, 28)}
                </h1>
                <CustomButton
                  clickHandler={() =>
                    navigate(
                      `/${
                        roleAccess([USER_ROLE.Sp_Standard_User])
                          ? "participant"
                          : "worker"
                      }-profile/${templateForm.worker_uuid}`
                    )
                  }
                  label='Back'
                />
              </div>
              <div className=''>
                <h1 className='mt-5 px-3 text-gray-400'>
                  Important Information
                </h1>
                <div className='lg:flex w-full justify-between mb-3 mt-2'>
                  <div className='grid grid-cols-1 md:grid-cols-5 gap-1 print:grid-cols-3 px-3'>
                    <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full rounded-md'>
                      <div className='flex items-center space-x-3 lg:space-x-6'>
                        <img
                          onError={(e) =>
                            handleImageError(
                              e,
                              templateForm.submitted_against || " ",
                              " "
                            )
                          }
                          className='h-10 w-10 rounded-full object-cover'
                          src={
                            templateForm.participant_pic
                              ? baseURLImage(templateForm.participant_pic)
                              : getNameProfile(
                                  templateForm.submitted_against || " ",
                                  " "
                                )
                          }
                          alt='user'
                        />
                        <div className='font-medium text-lg leading-6 space-y-1'>
                          <p className='text-gray-400 text-xs'>
                            Submitted Against
                          </p>
                          <h2 className='capitalize'>
                            {templateForm && templateForm.submitted_against}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full  md:mx-3 px-4 md:px-0 rounded-md'>
                      <div className='flex items-center space-x-4 lg:space-x-6'>
                        <img
                          onError={(e) =>
                            handleImageError(
                              e,
                              templateForm.submitted_by || " ",
                              " "
                            )
                          }
                          className='h-10 w-10 rounded-full object-cover'
                          src={
                            templateForm.profile_picture
                              ? baseURLImage(templateForm.profile_picture)
                              : getNameProfile(
                                  templateForm.submitted_by || " ",
                                  " "
                                )
                          }
                          alt='user'
                        />
                        <div className='font-medium text-lg leading-6 space-y-1'>
                          <p className='text-gray-400 text-xs'>Submitted by</p>
                          <h2 className='capitalize'>
                            {templateForm && templateForm.submitted_by}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full  md:mx-3 px-4 md:px-0 rounded-md'>
                      <span>
                        <div className='flex items-center space-x-4 lg:space-x-6'>
                          <div className='font-medium text-lg leading-6 space-y-1'>
                            <p className='text-gray-400 text-xs'>
                              Submitted on
                            </p>
                            <h2>
                              {templateForm &&
                                formatDateIntoDMYToDisplay(
                                  templateForm.posted_by
                                )}
                            </h2>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full  md:mx-3 px-4 md:px-0 rounded-md'>
                      <div className='flex items-center space-x-4 lg:space-x-6'>
                        {/* {templateForm.updated_by && (
                          <img
                            onError={(e) =>
                              handleImageError(
                                e,
                                templateForm.submitted_by || " ",
                                " "
                              )
                            }
                            className="h-10 w-10 rounded-full object-cover"
                            src={
                              templateForm.profile_picture
                                ? baseURLImage(templateForm.profile_picture)
                                : getNameProfile(
                                    templateForm.submitted_by || " ",
                                    " "
                                  )
                            }
                            alt="user"
                          />
                        )} */}
                        <div className='font-medium text-lg leading-6 space-y-1'>
                          <p className='text-gray-400 text-xs'>
                            Last updated by
                          </p>
                          <h2 className='capitalize'>
                            {templateForm?.updated_by ?? "-"}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className='bg-white border transition ease-in-out duration-150 hover:shadow-lg p-5 w-full  md:mx-3 px-4 md:px-0 rounded-md'>
                      <span>
                        <div className='flex items-center space-x-4 lg:space-x-6'>
                          <div className='font-medium text-lg leading-6 space-y-1'>
                            <p className='text-gray-400 text-xs'>
                              Last updated at
                            </p>
                            <h2>
                              {formatDateIntoDMYToDisplay(
                                templateForm?.updated_at
                              ) ?? "-"}
                            </h2>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

                <h1 className='mt-5 px-3 text-gray-400'>Submitted Form</h1>
                <div className='w-full mx-auto'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      {template.length > 0 &&
                        template.map((section, index) => {
                          return (
                            <div
                              key={index}
                              className='pb-5 border-gray-200 mt-2'
                            >
                              <div
                                key={index}
                                className='rounded bg-white border shadow-lg mx-3 mt-3'
                              >
                                <h1 className='text-lg mt-3 pl-5 leading-6 font-medium text-gray-500 mb-1'>
                                  {section?.section_title}
                                </h1>
                                <p className='text-gray-400 text-xs pl-5 mb-3'>
                                  {`Last updated on ${
                                    templateForm &&
                                    formatDateIntoDMYToDisplay(
                                      templateForm.posted_by
                                    )
                                  }`}
                                </p>

                                <div className='bg-gray-100 py-5 px-3 md:px-8 grid grid-cols-12 gap-x-8'>
                                  <SectionInputFields
                                    section={section}
                                    register={register}
                                    control={control}
                                    watchFormData={watchFormData}
                                    index={index}
                                    errors={errors}
                                    fileUploadHandler={fileUploadHandler}
                                    fileUploadChecker={fileUploadChecker}
                                    fileLoader={fileLoader}
                                    signatures={signatures}
                                    formatIntoPngManager={formatIntoPngManager}
                                    sigCanvasManager={sigCanvasManager}
                                    clearSignatureManager={
                                      clearSignatureManager
                                    }
                                    setSelectedLegal={setSelectedLegal}
                                    delteRowHandler={delteRowHandler}
                                    addRowHandler={addRowHandler}
                                    updatedBody={updatedBody}
                                    templateForm={templateForm}
                                    setFieldName={setFieldName}
                                    participantList={participantList}
                                    setSelectedBody={setSelectedBody}
                                    setOpenBodyModal={setOpenBodyModal}
                                    handleRemoveOther={handleRemoveOther}
                                    handleAddOther={handleAddOther}
                                  />
                                  {template.length - 1 === index && (
                                    <div className='col-span-12 flex justify-end py-2'>
                                      <CustomButton
                                        type='submit'
                                        label='Update'
                                        disabled={
                                          fileUploadChecker ? false : true
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </form>

                  {openBodyModal && (
                    <HumanBodyModal
                      selectedValue={updatedBody ? updatedBody : selectedBody}
                      handleSubmit={(e) => {
                        if (e) {
                          setValue(fieldName, e);
                          setUpdateBody(e);
                          setSelectedBody(e);
                        }
                        setOpenBodyModal(false);
                        // setSelectedBody("");
                      }}
                      isOpen={openBodyModal}
                      closeModal={() => setOpenBodyModal(false)}
                    />
                  )}
                  {selectedLegal && (
                    <AlertModal
                      show={selectedLegal !== "" ? true : false}
                      handleClose={() => setSelectedLegal("")}
                    >
                      {selectedLegal}
                    </AlertModal>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubmittedFormUpdate;
