import React from "react";
import CircularLoader from "../../../../../components/common/CircularLoader/CircularLoader";
import { NumberWithInternationalSign } from "../../../../../Utils/utils";

const FundingOverViewTable = (props) => {
  const { Heading, fundingList } = props;

  return (
    <div className="mt-4 relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <div className=" bg-gray-100 font-medium h-20 py-4 pl-4 border col-span-2 flex justify-between">
        <div className="text-[#101828] font-semibold text-lg pt-2">
          {Heading}
        </div>
      </div>
      <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
        <thead className="capitalize">
          <tr className="bg-[#F5FAFF]">
            <th
              scope="col"
              className="min-w-[4rem] pl-5 px-3 py-3.5 text-left text-xs text-gray-500"
            >
              ID
            </th>

            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs text-gray-500"
            >
              Total
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-xs text-gray-500"
            >
              Remaining
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {!fundingList ? (
            <tr>
              <td colSpan={3} className="py-6 text-center text-red-600">
                <CircularLoader
                  classes="flex justify-center items-center"
                  classloader="loader-l"
                />
              </td>
            </tr>
          ) : fundingList && fundingList.length > 0 ? (
            fundingList.map((funding, index) => (
              <tr key={index} className="even:bg-white odd:bg-gray-50">
                <td className="whitespace-nowrap px-3 py-4 text-sm  text-[#101828] font-medium">
                  {funding.contract_id}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {`$${NumberWithInternationalSign(
                    funding.total_contract_amount.replace("$", ""),
                    true,
                    2
                  )}`}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {`$${NumberWithInternationalSign(
                    funding.amount_remaining.replace("$", ""),
                    true,
                    2
                  )}`}
                </td>
              </tr>
            ))
          ) : (
            !fundingList ||
            (fundingList.length === 0 && (
              <tr>
                <td colSpan={3} className="py-6 text-center text-red-600">
                  No data found !
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
export default FundingOverViewTable;
