import { useState, useEffect } from "react";

const breakpoints = {
  xsm: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

const useResponsive = () => {
  const [screenSize, setScreenSize] = useState("xsm");

  const updateScreenSize = () => {
    const width = window.innerWidth;
    const sizes = Object.keys(breakpoints);

    for (let i = sizes.length - 1; i >= 0; i--) {
      if (width >= breakpoints[sizes[i]]) {
        setScreenSize(sizes[i]);
        break;
      }
    }
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  return screenSize;
};

export default useResponsive;
