import React, { useEffect, useState, useMemo } from "react";
import {
  CATEGORY_TYPE,
  emptyPaginationObj,
  LOADER_STATUS,
  PLAN_BUDGET_LIMIT,
} from "../../../../Constants/constant";
import { getPaginationObj } from "../../../../Utils/utils";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import TableHeader from "../../../../components/common/Table/TableHeader";
import PlanBudgetItem from "./PlanBudgetItem";
import Pagination from "components/common/Pagination";

const PlanBudgetTable = (props) => {
  const {
    getPlanBudgetList,
    participant,
    planBudgets,
    fetchLoader,
    planBudgetRemoved,
    funding,
    setEditPlanBudget,
    exportLoading,
    handlePrint,
    selectedData,
  } = props;
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [paginationObj, setPaginationObj] = useState(
    planBudgets ? getPaginationObj(planBudgets) : emptyPaginationObj
  );

  useEffect(() => {
    if (planBudgets) {
      setPaginationObj({
        ...getPaginationObj(planBudgets),
      });
    }
  }, [planBudgets]);

  useEffect(() => {
    setRenderLoading(LOADER_STATUS.PENDING);
    if (
      !planBudgets ||
      (planBudgets &&
        Object.keys(planBudgets).length > 0 &&
        planBudgets.hasOwnProperty("data") &&
        planBudgets.data.length === 0)
    ) {
      fetchPlanBudget({ page: paginationObj.page });
    }
  }, []);

  useEffect(() => {
    if (planBudgets && planBudgets.current_page !== paginationObj.page) {
      fetchPlanBudget({ page: paginationObj.page });
    }
    if (planBudgets && planBudgets.total > 0 && planBudgets.data.length === 0) {
      fetchPlanBudget({ page: planBudgets.current_page });
    }
  }, [paginationObj.page]);

  const getGeneralBudgetStats = (data, type) => {
    if (!data || data === undefined) return null;

    const remainCheck = (data) => {
      if (
        parseFloat(data.amount_allocated ?? 0) === 0 &&
        parseFloat(data.amount_remaining ?? 0) === 0
      ) {
        return parseFloat(data.total_budget_amount ?? 0);
      } else {
        return parseFloat(data.amount_remaining ?? 0);
      }
    };

    const getRemain = (obj, data) => {
      return obj["amount_remaining"]
        ? obj["amount_remaining"] + remainCheck(data)
        : remainCheck(data);
    };

    const reduceData = data.reduce((obj, data) => {
      Object.assign(obj, {
        type,
        isStats: true,
        total_budget_amount: obj["total_budget_amount"]
          ? obj["total_budget_amount"] + parseFloat(data.total_budget_amount)
          : parseFloat(data.total_budget_amount),
        amount_allocated: obj["amount_allocated"]
          ? obj["amount_allocated"] + parseFloat(data.amount_allocated ?? 0)
          : parseFloat(data.amount_allocated ?? 0),
        amount_claimed: obj["amount_claimed"]
          ? obj["amount_claimed"] + parseFloat(data.amount_claimed ?? 0)
          : parseFloat(data.amount_claimed ?? 0),
        amount_delivered: obj["amount_delivered"]
          ? obj["amount_delivered"] + parseFloat(data.amount_delivered ?? 0)
          : parseFloat(data.amount_delivered ?? 0),
        // amount_remaining: obj["amount_remaining"]
        //   ? obj["amount_remaining"] + getRemain(obj, data)
        //   : getRemain(obj, data),
        amount_remaining: obj["amount_remaining"]
          ? obj["amount_remaining"] + parseFloat(data.amount_remaining ?? 0)
          : parseFloat(data.amount_remaining ?? 0),
        amount_unclaimed: obj["amount_unclaimed"]
          ? obj["amount_unclaimed"] + parseFloat(data.amount_unclaimed ?? 0)
          : parseFloat(data.amount_unclaimed ?? 0),
      });
      return obj;
    }, {});
    if (reduceData && Object.keys(reduceData).length > 0) {
      return [{ ...reduceData }, ...data];
    }
    return null;
  };

  const planBudgetToMap = useMemo(() => {
    if (planBudgets) {
      const core = getGeneralBudgetStats(
        planBudgets.data.filter(
          (x) => x?.service_category?.name === CATEGORY_TYPE.CORE
        ),
        CATEGORY_TYPE.CORE
      );
      const capital = getGeneralBudgetStats(
        planBudgets.data.filter(
          (x) => x?.service_category?.name === CATEGORY_TYPE.CAPITAL
        ),
        CATEGORY_TYPE.CAPITAL
      );
      const capacityBuilding = getGeneralBudgetStats(
        planBudgets.data.filter(
          (x) => x?.service_category?.name === CATEGORY_TYPE.CAPACITY_BUILDING
        ),
        CATEGORY_TYPE.CAPACITY_BUILDING
      );
      let grandLength = 0;

      const returnObj = { data: [] };
      if (core) {
        grandLength += core.length - 1;
        Object.assign(returnObj, {
          core: [...core],
          data: [...returnObj.data, ...core],
        });
      }
      if (capacityBuilding) {
        grandLength += capacityBuilding.length - 1;
        Object.assign(returnObj, {
          capacityBuilding: [...capacityBuilding],
          data: [...returnObj.data, ...capacityBuilding],
        });
      }
      if (capital) {
        grandLength += capital.length - 1;
        Object.assign(returnObj, {
          capital: [...capital],
          data: [...returnObj.data, ...capital],
        });
      }

      return {
        ...returnObj,
        grandLength,
      };
    }
    return null;
  }, [planBudgets]);

  const fetchPlanBudget = async (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: PLAN_BUDGET_LIMIT });
    Object.assign(filters, {
      pt_id: participant.uuid || 1,
      funding_id: funding.uuid || 1,
    });
    setRenderLoading(LOADER_STATUS.PENDING);
    getPlanBudgetList(filters);
  };

  return (
    <>
      <div className='flex flex-col'>
        <div className='min-w-full align-middle'>
          <div className='border'>
            <table className='min-w-full divide-y divide-gray-300 table-fixed capitalize'>
              <thead className='bg-gray-50 capitalize'>
                <tr>
                  <TableHeader
                    isSort={false}
                    label={"Plan Category"}
                    cssClass='text-left'
                  />
                  <TableHeader
                    isSort={false}
                    label={"Plan Subcategory"}
                    cssClass='text-left'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='total_budget_amount'
                    label={"Plan Budget Amount"}
                    cssClass='text-left '
                  />
                  <TableHeader
                    isSort={false}
                    sortName='amount_allocated'
                    label={"Plan Allocated"}
                    cssClass='text-left px-0'
                  />
                  <TableHeader
                    isSort={false}
                    sortName='amount_delivered'
                    label={"Plan Delivered"}
                    cssClass='text-left px-0'
                  />
                  <TableHeader
                    isSort={false}
                    label={"Plan Budget Remaining"}
                    cssClass='text-left px-0'
                  />
                  <TableHeader
                    isSort={false}
                    label={"Actions"}
                    cssClass='text-right px-1'
                  />
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {fetchLoader ? (
                  <tr>
                    <td colSpan={8} className='bg-gray-50 py-2'>
                      <CircularLoader
                        classes='flex justify-center items-center'
                        classloader='loader-l'
                      />
                    </td>
                  </tr>
                ) : (
                  <>
                    {planBudgetToMap &&
                      planBudgetToMap.grandLength > 0 &&
                      planBudgetToMap.hasOwnProperty("core") &&
                      planBudgetToMap["core"].length > 0 && (
                        <PlanBudgetItem
                          key={"core"}
                          handlePrint={handlePrint}
                          exportLoading={exportLoading}
                          planBudgetToMap={planBudgetToMap["core"]}
                          setEditPlanBudget={setEditPlanBudget}
                          planBudgetRemoved={planBudgetRemoved}
                          selectedData={selectedData}
                          type={CATEGORY_TYPE.CORE}
                        />
                      )}
                    {planBudgetToMap &&
                      planBudgetToMap.grandLength > 0 &&
                      planBudgetToMap.hasOwnProperty("capacityBuilding") &&
                      planBudgetToMap["capacityBuilding"].length > 0 && (
                        <PlanBudgetItem
                          key={"capacityBuilding"}
                          handlePrint={handlePrint}
                          exportLoading={exportLoading}
                          planBudgetToMap={planBudgetToMap["capacityBuilding"]}
                          setEditPlanBudget={setEditPlanBudget}
                          planBudgetRemoved={planBudgetRemoved}
                          selectedData={selectedData}
                          type={CATEGORY_TYPE.CAPACITY_BUILDING}
                        />
                      )}
                    {planBudgetToMap &&
                      planBudgetToMap.grandLength > 0 &&
                      planBudgetToMap.hasOwnProperty("capital") &&
                      planBudgetToMap["capital"].length > 0 && (
                        <PlanBudgetItem
                          key={"capital"}
                          handlePrint={handlePrint}
                          exportLoading={exportLoading}
                          planBudgetToMap={planBudgetToMap["capital"]}
                          setEditPlanBudget={setEditPlanBudget}
                          planBudgetRemoved={planBudgetRemoved}
                          selectedData={selectedData}
                          type={CATEGORY_TYPE.CAPITAL}
                        />
                      )}
                  </>
                )}

                {renderLoading !== LOADER_STATUS.NA &&
                  planBudgetToMap &&
                  planBudgetToMap.grandLength === 0 && (
                    <tr>
                      <td colSpan={8} className='py-6 text-center text-red-600'>
                        No Data Found!
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='bg-white py-4'>
        <Pagination
          page={paginationObj.page}
          activeListLength={
            planBudgetToMap && planBudgetToMap.grandLength > 0
              ? [...planBudgetToMap.data]
              : []
          }
          limit={PLAN_BUDGET_LIMIT}
          totalList={
            paginationObj.totalPages === 1
              ? planBudgetToMap.grandLength > 0
                ? planBudgetToMap.grandLength
                : 0
              : paginationObj.total
          }
          handlePagination={(e) => {
            const obj = paginationObj;
            obj.page = e;
            setPaginationObj({ ...obj });
          }}
          totalPages={paginationObj.totalPages}
        />
      </div>
    </>
  );
};

export default PlanBudgetTable;
