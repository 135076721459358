import React, { useEffect, useMemo, useState } from "react";
import PaginationComponent from "../../../../components/common/Pagination/index";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import {
  COS_PARTICIPANT_LIST,
  emptyPaginationObj,
  SORT_TYPE,
} from "../../../../Constants/constant";
import { LoginContext } from "../../../../helpers/LoginContext";
import { getPaginationObj } from "../../../../Utils/utils";
import ParticipantTable from "./ParticipantTable";

const CosParticipant = (props) => {
  const {
    getCosParticipant,
    cosParticipant,
    cosParticipantLoader,
    supportCoordinatorLoader,
    supportCoordinator,
    getSupportCoordinators,
  } = props;

  const { sideBarPadding } = React.useContext(LoginContext);

  const [paginationObj, setPaginationObj] = useState(emptyPaginationObj);
  const [sortDescending, setSortDescending] = useState(
    paginationObj.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [sortBy, setSortBy] = useState(paginationObj.sort_field);
  const [search, setSearch] = useState(null);

  const getCatlogService = (filters) => {
    if (!filters || filters === undefined) {
      Object.assign((filters = {}), {
        page: paginationObj.page,
      });
    }
    Object.assign(filters, { per_page: COS_PARTICIPANT_LIST });

    if (filters?.status) {
      Object.assign(filters, {
        status: filters.status,
      });
    }

    if (filters?.sc_id) {
      Object.assign(filters, {
        sc_id: filters?.sc_id,
      });
    }

    getCosParticipant(filters);
  };

  useEffect(() => {
    if (cosParticipant) {
      setPaginationObj(
        getPaginationObj(
          cosParticipant.participants,
          cosParticipant.query_params
        )
      );
    }
  }, [cosParticipant]);

  useEffect(() => {
    getSupportCoordinators({ page: 1 });
  }, []);

  useEffect(() => {
    setSortBy(paginationObj.sort_field ? paginationObj.sort_field : null);
    if (paginationObj.sort_order) {
      setSortDescending(
        paginationObj.sort_order
          ? paginationObj.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
    }
  }, [paginationObj]);

  useEffect(() => {
    const obj = {};
    if (sortBy) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
  }, [paginationObj.page]);

  const handleFetch = (obj, sortBy) => {
    if (paginationObj.page) Object.assign(obj, { page: paginationObj.page });
    if (paginationObj.filter && !obj.hasOwnProperty("sc_id")) {
      Object.assign(obj, { filter: paginationObj.sc_id });
    }
    if (paginationObj.sort_field && !obj.hasOwnProperty("sort_field")) {
      Object.assign(obj, {
        sort_field: paginationObj.sort_field ? paginationObj.sort_field : null,
        sort_order:
          paginationObj.sort_order === SORT_TYPE.DESC
            ? SORT_TYPE.DESC
            : SORT_TYPE.ASC,
      });
    }
    if (obj.hasOwnProperty("search")) {
      Object.assign(obj, {
        page: 1,
        search: obj.search,
        // status: selectedStatus?.id === 1 ? undefined : selectedStatus?.id,
      });
    }
    if (paginationObj.search && !obj.hasOwnProperty("search")) {
      Object.assign(obj, { search: paginationObj.search });
    }
    if (obj.hasOwnProperty("status")) {
      Object.assign(obj, {
        filterStatus: {
          status: obj.filterStatus,
        },
      });
    }
    getCatlogService({ ...obj });
  };

  useEffect(() => {
    handleFetch({});
  }, []);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(
      () =>
        handleFetch({
          search: search,
        }),
      600
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  const participantListToMap = useMemo(() => {
    if (cosParticipant && cosParticipant?.participants?.data) {
      return cosParticipant?.participants?.data;
    }
    return [];
  }, [cosParticipant]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [paginationObj.page]);

  return (
    <>
      <div className="min-h-full">
        <SideBar />
        <div
          className={`${
            sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
          }  flex flex-col duration-300`}
        >
          <main className="h-screen flex-1 bg-white">
            <>
              <ParticipantTable
                cosParticipant={participantListToMap}
                cosParticipantLoader={cosParticipantLoader}
                setSearch={setSearch}
                search={search}
                supportCoordinatorLoader={supportCoordinatorLoader}
                handleFetch={handleFetch}
                supportCoordinator={supportCoordinator}
                sortBy={sortBy}
                sortDescending={sortDescending}
                flipSort={flipSort}
              />
              {participantListToMap.length > 0 && (
                <div className="bg-white px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto pb-10">
                  <PaginationComponent
                    page={paginationObj.page}
                    activeListLength={participantListToMap}
                    limit={COS_PARTICIPANT_LIST}
                    totalList={paginationObj.total}
                    handlePagination={(e) => {
                      const obj = paginationObj;
                      obj.page = e;
                      setPaginationObj({ ...obj });
                      handleFetch({}, sortBy);
                    }}
                    totalPages={paginationObj.totalPages}
                  />
                </div>
              )}
            </>
          </main>
        </div>
      </div>
    </>
  );
};

export default CosParticipant;
