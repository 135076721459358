import { LOADER_STATUS } from "Constants/constant";
import React, { useState, useRef, useEffect } from "react";

const AutoShiftBoxes = ({
  length,
  label,
  loading,
  onComplete,
  setConfirmationCode,
  classes,
  isDisabledBoxes = false,
  isClearFields,
  setIsClearOtp,
}) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);

  const checkIsComplete = (newCode) => {
    newCode.every((num) => num !== "") ? onComplete(true) : onComplete(false);
  };

  useEffect(() => {
    if (setIsClearOtp && isClearFields === LOADER_STATUS.COMPLATE) {
      setCode([...Array(length)].map(() => ""));
      setIsClearOtp(LOADER_STATUS.NA);
    } 
    // else {
    //   setIsClearOtp(LOADER_STATUS.NA);
    // }
  }, [isClearFields]);

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (!num || /[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode([...newCode]);
    setConfirmationCode(newCode.join(""));
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    checkIsComplete([...newCode]);
  };

  const onKeyUp = (e, slot) => {
    if (
      e.keyCode !== 8 &&
      code[slot] &&
      /[0-9]/.test(code[slot]) &&
      slot !== length - 1
    ) {
      inputs.current[slot + 1].focus();
    } else if (e.keyCode === 8 && slot >= 0) {
      const newCode = [...code];
      if (code[slot]) {
        newCode[slot] = "";
      } else if (slot > 0 && code[slot] === "") {
        // if (slot > 0) {
        inputs.current[slot - 1].focus();
        // }
      }
      setCode([...newCode]);
      setConfirmationCode(newCode.join(""));
      checkIsComplete([...newCode]);
    }
  };

  return (
    <div className='code-input'>
      <div className='code-inputs'>
        {code.map((num, idx) => {
          return (
            <input
              disabled={isDisabledBoxes}
              key={idx}
              type='text'
              inputMode='numeric'
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => {
                // console.log("e", e.target.value);
                processInput(e, idx);
              }}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
              className={classes}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AutoShiftBoxes;
