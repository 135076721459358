import Appointment from "./Appointments";
import { connect } from "react-redux";
import {
  participantAppointmentlistCall,
  participantAppointmentUpdateCall,
  participantAppointmentDeleteLocally,
} from "../../../../store/actions/participant.actions";
import {
  getAppointmentFetchLoading,
  getAppointmentsList,
} from "../../../../store/selector/participant.selector";

const mapDispatchToProps = {
  getAppointments: participantAppointmentlistCall,
  updateAppointment: participantAppointmentUpdateCall,
  participantAppointmentDeleteLocally,
};

const mapStateToProps = (state) => {
  const appointmentList = getAppointmentsList(state);
  const isLoading = getAppointmentFetchLoading(state);

  return { appointmentList, isLoading };
};
export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
