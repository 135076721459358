import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SelectOption from "components/common/Select/Select";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { BiPlus } from "react-icons/bi";
import InputField from "components/common/Input/Input";
import { activeAndDeactiveOptions, LOADER_STATUS } from "Constants/constant";
import { LoginContext } from "helpers/LoginContext";

const CreateCategoryModel = ({
  createNewCategoryLoading,
  createNewCategoryError,
  createNewCategoryRequest,
  selectedCategory,
  isOpen,
  closeModal,
  updateCategorySuccess,
  updateCategoryLoading,
  updateCategoryError,
  updateCategoryRequest,
}) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const watchFormData = watch();

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [isRequestCall, setIsRequestCall] = useState(LOADER_STATUS.NA);
  useEffect(() => setIsRequestCall(LOADER_STATUS.NA), []);

  useEffect(() => {
    if (
      isRequestCall === LOADER_STATUS.PENDING &&
      !createNewCategoryLoading &&
      createNewCategoryError.length === 0
    ) {
      closeModal();
      setIsRequestCall(LOADER_STATUS.NA);
    }
  }, [createNewCategoryLoading]);

  useEffect(() => {
    if (
      isRequestCall === LOADER_STATUS.PENDING &&
      !updateCategoryLoading &&
      updateCategoryError.length === 0
    ) {
      closeModal();
      setIsRequestCall(LOADER_STATUS.NA);
    }
  }, [updateCategoryLoading]);

  useEffect(() => {
    if (selectedCategory) {
      setValue("title", selectedCategory?.title);
      setValue("note", selectedCategory?.note);
      const selectedStatus = activeAndDeactiveOptions.find(
        (x) => x?.value.toLowerCase() === selectedCategory?.status.toLowerCase()
      );
      setValue("status", selectedStatus ? selectedStatus.value : null);
    }
  }, [selectedCategory]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    setIsRequestCall(LOADER_STATUS.PENDING);
    if (selectedCategory && selectedCategory.uuid) {
      updateCategoryRequest({
        ...data,
        client_task_category_id: selectedCategory.uuid,
      });
    } else {
      createNewCategoryRequest({
        ...data,
      });
    }
  };

  useEffect(() => {
    if (updateCategorySuccess) {
      closeModal();
    }
  }, [updateCategorySuccess]);

  const modalFooter = () => (
    <CustomButton
      {...(selectedCategory === null && {
        Icon: <BiPlus />,
      })}
      type='submit'
      showLoading={createNewCategoryLoading || updateCategoryLoading}
      isDisabled={
        createNewCategoryLoading ||
        updateCategoryLoading ||
        isUserGracePeriodOver
      }
      label={selectedCategory ? "Update" : "Create"}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById("cosCategorySettingModal");
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <div>
      <ModalSkeleton
        cssClass='w-[40%]'
        title={`${selectedCategory ? "Update" : "Create"} Task Category`}
        modalFooter={modalFooter()}
        isOpen={isOpen}
        closeModal={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-cols-2 space-x-4'>
            <div className='col-span-1'>
              <InputField
                isDisabled={selectedCategory ? true : false}
                readOnly={selectedCategory ? true : false}
                setValue={setValue}
                register={register}
                maxLength={254}
                label='Title'
                type='title'
                value={watchFormData?.title ?? null}
                name='title'
                isRequired={true}
                ErrorMessage={
                  errors?.title?.type === "required"
                    ? "This field is required!"
                    : null
                }
              />
            </div>
            <div className='col-span-1'>
              <label
                htmlFor=''
                className='block text-sm font-medium text-gray-700'
              >
                Status
              </label>
              <SelectOption
                cssClass='mt-1'
                isRequired={true}
                register={register}
                setValue={setValue}
                clearErrors={clearErrors}
                name='status'
                isSearch={false}
                data={activeAndDeactiveOptions}
                selectedOption={activeAndDeactiveOptions.find(
                  (x) =>
                    x.value.toLowerCase() ===
                    watchFormData?.status?.toLowerCase()
                )}
                errorMessage={
                  errors?.status?.type === "required"
                    ? "This field is required!"
                    : null
                }
              />
            </div>
            <div className='col-span-2' style={{ margin: "0px" }}>
              <label htmlFor='' className='did-floating-label text-start'>
                Description
              </label>
              <textarea
                {...register("note", {
                  required: true,
                })}
                rows='3'
                className='w-full border-gray-300 rounded oeverflow-y-auto resize-none'
              />
              {errors?.note?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>

          <button
            type='submit'
            className='hidden sr-only'
            id='cosCategorySettingModal'
          />
        </form>
      </ModalSkeleton>
    </div>
  );
};
export default CreateCategoryModel;
