import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import FundingOverViewTable from "./FundingOverview/fundingOverViewTable";
import CreateCaseNotesKM from "./Models/CreateCaseNotesKM";
import UpdateCaseNoteModal from "./CaseNotes/UpdateCaseNoteModal";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import SelectOption from "../../../../components/common/Select/Select";
import {
  formatDateIntoDMMMY,
  parseBooleanVal,
  selectedTabState,
} from "../../../../Utils/utils";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import Header from "../../../../components/common/Header/Header";
import ParticipantFundingCaseNote from "./CaseNotes/CaseNote";
import SupportServiceStats from "./SupportServiceStats/SupportServiceStats";
import ParticipantImageToolTip from "../../../../components/common/Tooltip/ParticipantImageToolTip";
import ClientTask from "./ClientTask/ClientTask";
import { LoginContext } from "helpers/LoginContext";
import StatsCard from "../../../../components/common/StatsCard/StatsCard";
import CreateCaseNotesModal from "./CaseNotes/CreateCaseNoteModal";
import { BiCopy, BiExit } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { USER_ROLE } from "Constants/constant";

const ParticipantProfile = (props) => {
  const { uuid } = useParams();
  const {
    updateCaseNotesSuccess,
    updateCaseNotesLoading,
    updateCaseNoteRequest,
    singleCaseNoteData,
    getCaseNoteDetailLoading,
    getCaseNoteDetailRequest,
    reportLoading,
    fetchServiceSupportItemsForDeliveredLoader,
    fundingsForCos,
    fundingSetupParticipantFosCosRequest,
    createclientTaskListLoading,
    updateClientTaskResponse,
    ClientTaskListUnmount,
    removeClientTaskSuccess,
    serviceSupportDropDownOptions,
    clientTaskResponse,
    periorityList,
    getPeriorityListRequest,
    CatogeryList,
    getCatogeryListRequest,
    ContactList,
    getContactListRequest,
    serviceSupportItemsCaseNotes,
    singleSupportTrackLoading,
    caseNotesCreateLoading,
    singleSupportTrack,
    getSelectedSupport,
    getReportRequest,
    reportData,
    clientTaskListLoading,
    casNotesListLoading,
    getParticipantProfile,
    cosParticipantsProfile,
    resetFundingParticipantProfileData,
    deleteClientTask,
    updateClientTask,
    getCaseNotes,
    getServiceSupportForDS,
    gettaskList,
    createTaskRequest,
    caseNotesList,
    clientTaskList,
    getAssignedToList,
    supportCoordinator,
    clearServiceSupportServicesForCaseNotes,
    caseNoteerror,
    cosParticipantsProfileLoader,
  } = props;

  const { sideBarPadding } = useContext(LoginContext);
  const navigate = useNavigate();

  const [selectedFunding, setSelectedFunding] = useState(null);
  const [openUpdateCaseNoteModal, setOpenUpdateCaseNoteModal] = useState(false);
  const [supportCoordinatorsOptions, setSupportCoordinatorsOptions] = useState(
    []
  );
  const [confirmation, setConfirmation] = useState(false);
  const [selectedServiceSupport, setSelectedServiceSupport] = useState(null);
  const [copyText, setCopyText] = useState({ copied: false });
  const [show, setShow] = useState();

  useEffect(() => {
    if (supportCoordinator && supportCoordinator.length > 0) {
      const data = supportCoordinator.map((item) => {
        return {
          label: item.first_name.concat(" ", item.last_name),
          value: item.id,
        };
      });
      setSupportCoordinatorsOptions(data);
    }
  }, [supportCoordinator]);

  useEffect(() => {
    getAssignedToList({ page: 1 });
    return () => resetFundingParticipantProfileData();
  }, []);

  useEffect(() => {
    if (uuid) {
      getParticipantProfile({ id: uuid });
      fundingSetupParticipantFosCosRequest({
        page: 1,
        per_page: 100,
        pt_id: uuid,
      });
    }
  }, [uuid]);

  useEffect(() => {
    if (selectedFunding && cosParticipantsProfile) {
      clearServiceSupportServicesForCaseNotes();
      setSelectedServiceSupport(null);
      getServiceSupportForDS({
        pt_id: cosParticipantsProfile?.id || 1,
        funding_id: selectedFunding.value,
        cos_case_notes: true,
      });
    }
  }, [selectedFunding, cosParticipantsProfile]);

  useEffect(() => {
    if (updateCaseNotesSuccess) {
      setOpenUpdateCaseNoteModal(false);
    }
  }, [updateCaseNotesSuccess]);

  useEffect(() => {
    if (
      fundingsForCos &&
      fundingsForCos.length > 0 &&
      selectedFunding === null
    ) {
      setSelectedFunding(fundingsForCos[0]);
    }
  }, [fundingsForCos]);

  useEffect(() => {
    if (selectedServiceSupport && cosParticipantsProfile) {
      getSelectedSupport({
        id: selectedServiceSupport.value,
        pt_id: cosParticipantsProfile?.id,
      });
    }
  }, [selectedServiceSupport]);

  useEffect(() => {
    if (
      selectedServiceSupport === null &&
      serviceSupportDropDownOptions &&
      serviceSupportDropDownOptions.length > 0
    ) {
      setSelectedServiceSupport(serviceSupportDropDownOptions[0]);
    }
  }, [serviceSupportDropDownOptions, serviceSupportItemsCaseNotes]);

  useEffect(() => {
    if (fundingsForCos) {
      for (let i = 0; i < fundingsForCos.length; i++) {
        if (fundingsForCos[i]?.id === selectedFunding?.id) {
          setShow(fundingsForCos[i]);
        }
      }
    }
  }, [fundingsForCos]);

  const stats = useMemo(() => {
    if (show) {
      return [
        {
          isAmount: true,
          name: "Total Funding",
          stat: show?.total_contract_amount || 0,
          color: "#84ADFF",
        },
        {
          isAmount: true,
          name: "Funding Delivered",
          stat: show?.amount_delivered || 0,
          color: "#BDB4FE",
        },
        {
          isAmount: true,
          name: "Funding Remaining",
          stat: show?.amount_remaining || 0,
          color: "#A4BCFD",
        },
        {
          isAmount: false,
          name: "Funding Start Date",
          stat: formatDateIntoDMMMY(show?.start_date),
          color: "#C3B5FD",
        },
        {
          isAmount: false,
          name: "Funding End Date",
          stat: formatDateIntoDMMMY(show?.end_date),
          color: "#EEAAFD",
        },
        {
          isAmount: false,
          name: "Funding Review Date",
          stat: formatDateIntoDMMMY(show?.review_date),
          color: "#84CAFF",
        },
      ];
    }
    return [];
  }, [show]);

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding ? "lg:pl-[4rem]" : "lg:pl-64"
          } flex flex-col duration-300`}
        >
          {cosParticipantsProfile &&
          Object.keys(cosParticipantsProfile).length > 0 &&
          !cosParticipantsProfileLoader ? (
            <main className='flex-1'>
              <Header
                title='Participant Profile'
                {...(cosParticipantsProfile &&
                  cosParticipantsProfile?.uuid &&
                  selectedFunding &&
                  selectedFunding.value &&
                  roleAccess(
                    [],
                    PERMISSION_KEYS.PARTICIPANT,
                    PERMISSION_KEYS_CHILDREN.PARTICIPANT.FUNDING.SHOW
                  ) && {
                    handleClick: () =>
                      navigate(
                        `/cos/participant-funding/${selectedFunding.value}/${cosParticipantsProfile.uuid}`
                      ),
                    btnLabel: "Redirect To Funding",
                    icon: <BiExit />,
                    isButton: true,
                  })}
              />
              <div className='max-w-7xl mx-auto'>
                <div className='sm:px-6 lg:px-8  pt-4'>
                  <Breadcrumb
                    pages={COS_BREADCRUMB(
                      COS_BREADCRUMB_PAGES.PARTICIPANT_PROFILE
                    )}
                  />
                </div>
                <div className='space-y-6 lg:col-start-1 lg:col-span-3 '>
                  <div className='mt-2 flex flex-col'>
                    <div className='inline-block min-w-full py-2 align-middle'>
                      <div className='grid grid-cols-2 sm:px-6 lg:px-8'>
                        <div className='col-span-1 sticky'>
                          <div className='flex items-center'>
                            <div className='h-16 w-16 flex-shrink-0 group relative'>
                              <ParticipantImageToolTip
                                cssClass={
                                  "bg-black py-1 px-1 rounded text-white z-[9] "
                                }
                                imgCssClass={`${
                                  cosParticipantsProfile.profile_picture
                                    ? "capitalize object-cover h-16 w-16 rounded-full"
                                    : "object-cover rounded-full border-4 border-white"
                                }`}
                                participant={cosParticipantsProfile}
                              />
                            </div>
                            <div className='block'>
                              <div className='pl-3 text-gray-700 text-sm font-bold capitalize'>
                                {typeof cosParticipantsProfile?.first_name !=
                                "undefined"
                                  ? cosParticipantsProfile?.first_name +
                                    " " +
                                    cosParticipantsProfile?.last_name
                                  : " "}
                              </div>
                              {roleAccess(
                                [],
                                PERMISSION_KEYS.PARTICIPANT,
                                PERMISSION_KEYS_CHILDREN.PARTICIPANT.NDIS_NUMBER
                              ) &&
                                cosParticipantsProfile?.ndis_no && (
                                  <div className='pl-3 text-gray-700 text-md pt-1 capitalize'>
                                    <div className='flex space-x-2 '>
                                      <div className='text-sm'>NDIS No :</div>
                                      <CopyToClipboard
                                        text={cosParticipantsProfile?.ndis_no}
                                        onCopy={() =>
                                          setCopyText({ copied: true })
                                        }
                                      >
                                        <div className='text-sm'>
                                          {cosParticipantsProfile?.ndis_no}
                                        </div>
                                      </CopyToClipboard>
                                      <CopyToClipboard
                                        text={cosParticipantsProfile?.ndis_no}
                                        onCopy={() =>
                                          setCopyText({ copied: true })
                                        }
                                      >
                                        <button className='text-sm'>
                                          {copyText.copied !== true ? (
                                            // <span>Copy</span>
                                            <BiCopy />
                                          ) : (
                                            <span
                                              style={{ color: "green" }}
                                              className='text-sm font-bold'
                                            >
                                              Copied.
                                            </span>
                                          )}
                                        </button>

                                        {/* <CustomButton
                                      // isDisabled={
                                      //   !billTypes || !filtType || !fromDate || !toDate || reportLoading
                                      // }
                                      type="submit"
                                      // showLoading={reportLoading}
                                      label="Copy"
                                      Icon={<BiCopy />}
                                      // clickHandler={onSubmit}
                                    /> */}
                                      </CopyToClipboard>
                                      {copyText.copyText === true ? (
                                        <span
                                          style={{ color: "green" }}
                                          className='text-sm font-bold'
                                        >
                                          Copied.
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                              {roleAccess(
                                [],
                                PERMISSION_KEYS.PARTICIPANT,
                                PERMISSION_KEYS_CHILDREN.PARTICIPANT
                                  .PRIMARY_EMAIL
                              ) && (
                                <span className=' pl-3 hover:text-gray-700 text-[#4B6CB7]'>
                                  {cosParticipantsProfile?.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='grid grid-cols-2 space-x-2'>
                          <div className=' col-span-1'>
                            <div className='text-start'>
                              <label
                                htmlFor=''
                                className='did-floating-label text-start'
                              >
                                Fundings
                              </label>
                            </div>
                            <SelectOption
                              selectedOption={selectedFunding}
                              cssClass=''
                              isSearch={false}
                              data={fundingsForCos}
                              handleChange={(e) => {
                                setSelectedFunding(e);
                              }}
                              // placeHolder="Select A Funding"
                            />
                          </div>
                          <div className=' col-span-1'>
                            <div className='text-start'>
                              <label
                                htmlFor=''
                                className='did-floating-label text-start'
                              >
                                Support Services
                              </label>
                            </div>
                            <SelectOption
                              isDisabled={
                                fetchServiceSupportItemsForDeliveredLoader
                              }
                              selectedOption={selectedServiceSupport}
                              isSearch={false}
                              data={serviceSupportDropDownOptions}
                              handleChange={setSelectedServiceSupport}
                              loading={
                                fetchServiceSupportItemsForDeliveredLoader
                              }
                              // placeHolder={
                              //   fetchServiceSupportItemsForDeliveredLoader
                              //     ? "Loading..."
                              //     : "Select Service Support"
                              // }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {fundingsForCos && fundingsForCos.length === 0 && (
                    <div className='sm:px-6 lg:px-8 pt-0'>
                      <div className='bg-[#FCFCFD] border rounded-lg px-10 py-6'>
                        <div
                          onClick={() => {
                            if (cosParticipantsProfile) {
                              cosParticipantsProfile &&
                              parseBooleanVal(
                                cosParticipantsProfile?.support_coordination
                              ) &&
                              roleAccess(
                                [],
                                PERMISSION_KEYS.PARTICIPANT,
                                PERMISSION_KEYS_CHILDREN.PARTICIPANT.FUNDING
                                  .SHOW
                              )
                                ? selectedTabState.save(
                                    JSON.stringify({
                                      name: "Funding",
                                      id: 17,
                                      children: [],
                                    })
                                  )
                                : selectedTabState.save(
                                    JSON.stringify({
                                      name: "Overview",
                                      id: 1,
                                      children: [],
                                    })
                                  );

                              navigate(
                                `/${
                                  roleAccess([USER_ROLE.Sp_Standard_User])
                                    ? "participant"
                                    : "sp"
                                }-profile/${cosParticipantsProfile.uuid}`
                              );
                            }
                          }}
                          className='hover:underline cursor-pointer text-center text-xl font-bold text-gray-500'
                        >
                          Please set up your participant funding.
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='sm:px-6 lg:px-8 py-4 pt-0'>
                    {/* <section>
                        <ParticipantInformation
                          cosParticipantsProfile={cosParticipantsProfile}
                        />
                      </section> */}
                    <section className='mt-0 grid lg:grid-cols-3 gap-3 sm:grid-cols-3'>
                      {stats.map((item, index) => (
                        <StatsCard item={item} index={index} key={index} />
                      ))}
                    </section>
                    {/* <section className="mt-10">
                        <StepperProgressbar />
                      </section> */}
                    <section className='mt-2'>
                      <SupportServiceStats
                        selectedFunding={selectedFunding}
                        fundingsForCos={fundingsForCos}
                        singleSupportTrackLoading={singleSupportTrackLoading}
                        singleSupportTrack={singleSupportTrack}
                      />
                    </section>
                    <section className='py-4'>
                      <div className='mt-4 relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                        <div className=' bg-green-50 font-medium h-20 py-4 pl-4 border col-span-2 flex justify-between'>
                          <div className='text-[#101828] font-semibold text-lg pt-2'>
                            Case Notes
                          </div>
                        </div>
                        <div className='wrapper py-4'>
                          <CreateCaseNotesModal
                            singleSupportTrackLoading={
                              singleSupportTrackLoading
                            }
                            selectedServiceSupport={selectedServiceSupport}
                            setSelectedServiceSupport={
                              setSelectedServiceSupport
                            }
                            ContactList={ContactList}
                            getContactListRequest={getContactListRequest}
                            SupportItems={serviceSupportItemsCaseNotes}
                            cosParticipantsProfile={cosParticipantsProfile}
                            selectedFunding={selectedFunding}
                            caseNotesCreateLoading={caseNotesCreateLoading}
                          />
                        </div>
                      </div>
                    </section>
                    <section>
                      <ParticipantFundingCaseNote
                        setOpenUpdateCaseNoteModal={setOpenUpdateCaseNoteModal}
                        getCaseNoteDetailRequest={getCaseNoteDetailRequest}
                        reportLoading={reportLoading}
                        caseNoteerror={caseNoteerror}
                        selectedServiceSupport={selectedServiceSupport}
                        singleSupportTrackLoading={singleSupportTrackLoading}
                        setSelectedServiceSupport={setSelectedServiceSupport}
                        caseNotesList={caseNotesList}
                        getCaseNotesRequest={getCaseNotes}
                        selectedFunding={selectedFunding}
                        casNotesListLoading={casNotesListLoading}
                        getCatogeryListRequest={getCatogeryListRequest}
                        uuid={uuid}
                        getReportRequest={getReportRequest}
                        cosParticipantsProfile={cosParticipantsProfile}
                        ContactList={ContactList}
                        getContactListRequest={getContactListRequest}
                        SupportItems={serviceSupportItemsCaseNotes}
                        caseNotesCreateLoading={caseNotesCreateLoading}
                      />
                    </section>
                    <section>
                      <div className='grid grid-cols-3 space-x-4'>
                        <div className='col-span-2'>
                          <ClientTask
                            clientTaskListLoading={clientTaskListLoading}
                            clientTaskList={clientTaskList}
                            uuid={uuid}
                            supportCoordinatorsOptions={
                              supportCoordinatorsOptions
                            }
                            selectedFunding={selectedFunding}
                            createclientTaskListLoading={
                              createclientTaskListLoading
                            }
                            updateClientTaskResponse={updateClientTaskResponse}
                            ClientTaskListUnmount={ClientTaskListUnmount}
                            removeClientTaskSuccess={removeClientTaskSuccess}
                            clientTaskResponse={clientTaskResponse}
                            periorityList={periorityList}
                            getPeriorityListRequest={getPeriorityListRequest}
                            getCatogeryListRequest={getCatogeryListRequest}
                            CatogeryList={CatogeryList}
                            cosParticipantsProfile={cosParticipantsProfile}
                            createTaskRequest={createTaskRequest}
                            updateClientTask={updateClientTask}
                            gettaskList={gettaskList}
                          />
                        </div>

                        <div className='col-span-1'>
                          <FundingOverViewTable
                            Heading='Funding Overview'
                            fundingList={fundingsForCos}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </main>
          ) : (
            <div className='px-0 flex justify-center items-center min-h-screen bg-gray-50'>
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          )}
        </div>
      </div>
      {openUpdateCaseNoteModal && (
        <UpdateCaseNoteModal
          updateCaseNotesLoading={updateCaseNotesLoading}
          updateCaseNoteRequest={updateCaseNoteRequest}
          singleCaseNoteData={singleCaseNoteData}
          closeModal={() => setOpenUpdateCaseNoteModal(false)}
          // selectedServiceSupport={selectedServiceSupport}
          selectedServiceSupport={singleCaseNoteData}
          singleSupportTrackLoading={singleSupportTrackLoading}
          // setSelectedServiceSupport={setSelectedServiceSupport}
          selectedFunding={selectedFunding}
          cosParticipantsProfile={cosParticipantsProfile}
          ContactList={ContactList}
          getContactListRequest={getContactListRequest}
          SupportItems={serviceSupportItemsCaseNotes}
          caseNotesCreateLoading={caseNotesCreateLoading}
          openUpdateCaseNoteModal={openUpdateCaseNoteModal}
        />
      )}
      {confirmation && (
        <CreateCaseNotesKM
          // deleteUUID={deleteUUID}
          deleteClientTask={deleteClientTask}
          setConfirmation={setConfirmation}
          createCaseNotesKmModel={confirmation}
          closeModal={setConfirmation}
        />
      )}
    </>
  );
};
export default ParticipantProfile;
