import ReactDOM from "react-dom";
import swal from "sweetalert";
import RecurringShiftSwitch from "pages/Rostering/WorkerShift/RecurringShiftSwitch";
import React, { useEffect, useState, useCallback } from "react";
import { IoWarningOutline } from "react-icons/io5";

const UpdateRecurringShiftsModal = (props) => {
  const {
    title,
    dangerMode = false,
    setIsConfirm,
    isOpen = false,
    shiftData = null,
    setIsRecurringShift,
    watch,
    setValue,
    control,
    recurringUpdateShiftData,
    refreshCalendar,
    createOrUpdateWorkerShiftRequest,
    watchFormData,
  } = props;

  const [recurringEffectedShiftCount, setRecurringEffectedShiftCount] = useState(null);
  const [loading, setLoading] = useState(false);


  const handleChangeAction = useCallback(() => {
    const container = document.createElement("div");

    ReactDOM.render(
      <div>
        <RecurringShiftSwitch
          control={control}
          watchFormData={watchFormData}
          selectedShift={shiftData}
          setValue={setValue}
          recurringEffectedShiftCount={recurringEffectedShiftCount}
          setRecurringEffectedShiftCount={setRecurringEffectedShiftCount}
          setIsRecurringShift={setIsRecurringShift}
          setLoading={setLoading}
        />
        {recurringEffectedShiftCount !== null &&
          recurringEffectedShiftCount !== undefined && (
            <div className='flex items-center mt-2 bg-yellow-100 border border-yellow-500 text-yellow-700 p-1 rounded'>
              <IoWarningOutline className='text-yellow-500 mr-2 h-6 w-6' />
              <p className='text-sm font-semibold'>
                {recurringEffectedShiftCount > 0
                  ? `The changes will impact ${recurringEffectedShiftCount} additional shifts.`
                  : "There are no other recurring shifts aside from this one."}
              </p>
            </div>
          )}
      </div>,
      container
    );

    swal({
      title: title,
      content: container,
      buttons: ["No, take me back!", "Yes, go ahead!"],
      dangerMode: dangerMode,
    }).then((willUpdate) => {
      setIsConfirm(false);

      if (willUpdate) {
        setLoading(true);
        recurringUpdateShiftData.data.recurring_impact = watchFormData?.recurring_impact;

        createOrUpdateWorkerShiftRequest(recurringUpdateShiftData, () => {
          setLoading(false);
          setIsConfirm(false);

          const payload = { isRefreshCall: true };
          refreshCalendar(payload);

          swal.close();
        });
      }
    });
  }, [
    control,
    dangerMode,
    setIsConfirm,
    setLoading,
    setIsRecurringShift,
    setValue,
    recurringEffectedShiftCount,
    recurringUpdateShiftData,
    refreshCalendar,
    shiftData,
    watchFormData,
  ]);

  
  useEffect(() => {
    if (isOpen) {
      handleChangeAction();
    }
  }, [isOpen, handleChangeAction]);

  return null;
};

export default React.memo(UpdateRecurringShiftsModal);
