import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import LoginScreenSection from "components/common/LoginScreenSection/LoginScreenSection";
import { useDispatch } from "react-redux";
import { callLogout } from "store/actions/auth.actions";
import Email from "assets/email.png";
import Phone from "../../../../assets/phonet.png";
import { LoginContext } from "helpers/LoginContext";

const TwoFANotEnabledOTPPage = (props) => {
  const {
    sendOTPRequest,
    sendOTPSuccess,
    sendOTPForConfirmationRequest,
    reSendOTPRequest,
    confirmOtpError,
    confirmOtpSuccess,
  } = props;

  const dispatch = useDispatch();
  const via = localStorage.getItem("via");

  const [counter, setCounter] = useState(120);
  const navigate = useNavigate();

  const { unSubscribeChannels } = useContext(LoginContext);

  useEffect(() => {
    if (confirmOtpSuccess === true) {
      localStorage.setItem("step", "3");
      navigate("/verified-otp");
    }
  }, [confirmOtpSuccess]);

  useEffect(() => {
    // if (sendOTPSuccess == true) {
    //   // navigate("/verified-otp");
    // }
  }, [sendOTPSuccess]);

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [errorCustom, setCustomError] = useState(false);

  useEffect(() => {
    if (confirmOtpError) {
      setCustomError(true);
      setCount(0);
      setOtp(new Array(4).fill(""));
    }
  }, [confirmOtpError]);
  const [count, setCount] = useState(0);
  const handleChnage = (element, index) => {
    !element.value
      ? setCount((prev) => prev - 1)
      : setCount((prev) => prev + 1);

    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  useEffect(() => {
    if (count === 4) {
      let temp = otp.toString();
      temp = temp.split(",").join("");
      sendOTPForConfirmationRequest({
        navigate,
        otp: temp,
        authentication_uuid: localStorage.getItem("authentication_uuid"),
      });
    }
  }, [count]);

  const handelResend = () => {
    localStorage.setItem("resend", JSON.stringify(1));
    let resend = JSON.parse(localStorage.getItem("resend"));
    if (resend <= 3) {
      localStorage.setItem("resend", JSON.stringify(resend + 1));
      reSendOTPRequest({
        authentication_uuid: localStorage.getItem("authentication_uuid"),
      });
      setCustomError(false);
      setCounter(120);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    counter > 0 &&
      setTimeout(
        () => setCounter(counter > 10 ? counter - 1 : `0${counter - 1}`),
        1000
      );
  }, [counter]);

  const handleLogoutClick = () => {
    unSubscribeChannels();
    dispatch(callLogout());
  };
  const minutes = Math.floor(counter / 60);
  const seconds = counter % 60;
  return (
    <div className=''>
      <div className='min-h-screen flex'>
        <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 lg:w-1/2 xl:px-24'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <div>
              <h2 className='mt-6 flex justify-center mb-6'>
                <div className='rounded-full bg-[#D1E9FF] w-16 h-16'>
                  <div className='rounded-full bg-blue-200 w-12 h-12 relative top-[8px] left-[8px]'>
                    <img
                      src={via === "email" ? Email : Phone}
                      alt=''
                      className='inline px-4 relative top-[10px]'
                    />
                  </div>
                </div>
              </h2>
              <div className='text-center text-gray-900 text-3xl font-semibold mt-2'>
                OTP Incoming!
              </div>
              <div className='text-center text-gray-500 font-normal text-base mt-2'>
                <p>
                  Check your phone, the OTP is on its way like a pizza delivery.
                </p>
              </div>

              <div className='grid-cols-4 gap-2 flex justify-between mt-6 px-4'>
                {otp.map((data, index) => {
                  return (
                    <input
                      autoFocus={index === 0 && true}
                      name='otp'
                      maxLength='1'
                      type='text'
                      key={index}
                      value={data}
                      onFocus={(e) => e.target.select()}
                      onChange={(e) => handleChnage(e.target, index)}
                      className=' otp-field box-border signIn-input  bg-white  shadow-md  rounded-lg w-full text-center  font-medium text-5xl text-[#4B6CB7] border-solid border border-[#84CAFF] placeholder:text-[#4B6CB7]'
                    />
                  );
                })}
              </div>

              {errorCustom === true && (
                <div className='flex justify-center mt-6'>
                  <span className='inline-flex items-center rounded-full px-1 py-0.5 text-xs font-medium  bg-[#FEF3F2] gap-3'>
                    <span className='inline-flex items-center rounded-full bg-[#FFFFFF] px-2.5 py-0.5 text-xs font-semibold text-[#B42318]'>
                      Error
                    </span>
                    <p className='text-[#B42318] font-semibold pr-1'>
                      Incorrect OTP entered!
                    </p>
                  </span>
                </div>
              )}
              <menu type='context'></menu>
              {counter > 0 ? (
                <div className='text-center mt-6'>
                  {`${minutes < 10 ? "0" + minutes : minutes}:${
                    seconds < 10 ? "0" + seconds : seconds
                  }`}
                </div>
              ) : (
                <div className='text-sm flex justify-center mt-6'>
                  <span
                    onClick={handelResend}
                    className='cursor-pointer font-semibold text-blue-dark hover:text-blue-dark text-sm'
                  >
                    Resend Code
                  </span>
                </div>
              )}
              <div
                className='text-sm  flex justify-center items-center mt-6 space-x-3'
                onClick={handleLogoutClick}
              >
                <img src={""} alt='' className='' />
                <div className='relative top-[2px]'>
                  <AiOutlineArrowLeft className=' text-gray-500 font-semibold' />
                </div>
                <div className='font-semibold text-gray-500 text-sm '>
                  <span
                    className='cursor-pointer'
                    onClick={() => {
                      localStorage.clear();
                      sessionStorage.clear();
                      navigate("/");
                    }}
                  >
                    Back to log in
                  </span>
                </div>
              </div>
              {/* <div className="text-sm flex justify-center mt-6">
                  <a
                    className="
                        cursor-pointer
                        font-semibold
                        text-blue-dark
                        hover:text-blue-dark
                        text-sm
                      "
                  >
                    Send Code to Email
                  </a>
                </div> */}
            </div>
          </div>
        </div>
        <LoginScreenSection
          heading={`Vertex360 has streamlined our NDIS compliance to an extent that we look forward to coming to work now !`}
          userImg='use image here'
          name='Dale Florence'
          desc='NDIS Service Provider'
        />
      </div>
    </div>
  );
};

export default TwoFANotEnabledOTPPage;
