import React, { useState } from "react";
import RiskRatingTab from "../RiskRating/index";
import SubmittedFormView from "../../SubmittedFormsView/index";
import { ShieldExclamationIcon } from "@heroicons/react/solid";
import CustomButton from "../../../common/Button/CustomButton";
import { RiFileWarningLine } from "react-icons/ri";
import {
  PARENT_FORM_CATEGORY_SLUG,
  USER_ROLE,
} from "../../../../Constants/constant";
import riskGuide3 from "../../../../assets/riskGuide/guide3.jpg";
import riskGuide1 from "../../../../assets/riskGuide/guide1.jpg";
import riskGuide2 from "../../../../assets/riskGuide/guide2.jpg";
import ModalSkeleton from "../../../Modals/ModalSkeleton";
import { classNames } from "Utils/utils";
import { roleAccess } from "helpers/RolesPermission";

const RiskManagementTab = (props) => {
  const { participantInfoUUID, participantId, parentSlug } = props;
  const [riskToggleState, setRiskToggleState] = useState(1);
  const [isRiskMitigation, setIsRiskMitigation] = useState(false);
  const [isRiskMgt, setIsRiskMgt] = useState(false);
  const [isRiskRating, setIsRiskRating] = useState(false);

  const closeModal = () => {
    setIsRiskMitigation(false);
    setIsRiskMgt(false);
    setIsRiskRating(false);
  };

  const riskMitigation = () => (
    <div className='w-[100%] flex justify-center'>
      <img src={riskGuide3} alt='risk guide' className=' w-[730px] h-[600px]' />
    </div>
  );
  const riskMgt = () => <img src={riskGuide1} alt='risk guide' className='' />;
  const riskRating = () => (
    <div className='w-[100%] flex justify-center'>
      <img
        src={riskGuide2}
        alt='risk guide'
        className='object-contain w-[635px] h-[470px]'
      />
    </div>
  );

  const modalFooter = () => (
    <CustomButton
      title='Close'
      label='Close'
      clickHandler={() => closeModal()}
      variant='danger'
    />
  );

  return (
    <>
      <div className='space-y-4'>
        {!roleAccess([USER_ROLE.Sp_Standard_User]) && (
          <div className='px-4'>
            <div className='border-b border-gray-200 flex items-center justify-between'>
              <nav className='flex space-x-8' aria-label='Tabs'>
                <span
                  onClick={() => setRiskToggleState(1)}
                  className={classNames(
                    riskToggleState === 1
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={riskToggleState === 1 ? "page" : undefined}
                >
                  <RiFileWarningLine
                    className={classNames(
                      riskToggleState === 1
                        ? "text-indigo-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden='true'
                  />
                  <span>Risk Assessment</span>
                </span>

                <span
                  onClick={() => setRiskToggleState(2)}
                  className={classNames(
                    riskToggleState === 2
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={riskToggleState === 2 ? "page" : undefined}
                >
                  <ShieldExclamationIcon
                    className={classNames(
                      riskToggleState === 2
                        ? "text-indigo-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5"
                    )}
                    aria-hidden='true'
                  />
                  <span>Risk Rating</span>
                </span>
              </nav>

              <div>
                <CustomButton
                  label='Risk MGT'
                  cssClass='mr-2'
                  clickHandler={() => setIsRiskMgt(true)}
                />
                <CustomButton
                  label='Risk Rating'
                  cssClass='mr-2'
                  clickHandler={() => setIsRiskRating(true)}
                />
                <CustomButton
                  label='Risk Mitigation'
                  clickHandler={() => setIsRiskMitigation(true)}
                />
              </div>
            </div>
          </div>
        )}

        {riskToggleState === 1 && participantInfoUUID && (
          <SubmittedFormView
            parentSlug={parentSlug}
            participantId={participantId}
            supportPlansId={PARENT_FORM_CATEGORY_SLUG.RISK_MANAGEMENT.UUID}
            participantsUid={participantInfoUUID}
          />
        )}
        {riskToggleState === 2 && participantInfoUUID && (
          <div>
            <RiskRatingTab participantsUid={participantInfoUUID} />
          </div>
        )}
      </div>
      {isRiskMitigation && (
        <ModalSkeleton
          isOpen={isRiskMitigation}
          closeModal={closeModal}
          modalFooter={modalFooter()}
          cssClass='w-[60%]'
          title='Risk Mitigation'
        >
          {riskMitigation()}
        </ModalSkeleton>
      )}
      {isRiskMgt && (
        <ModalSkeleton
          isOpen={isRiskMgt}
          closeModal={closeModal}
          modalFooter={modalFooter()}
          cssClass='w-[60%]'
          title='Risk MGT'
        >
          {riskMgt()}
        </ModalSkeleton>
      )}
      {isRiskRating && (
        <ModalSkeleton
          isOpen={isRiskRating}
          closeModal={closeModal}
          modalFooter={modalFooter()}
          cssClass='w-[60%]'
          title='Risk Rating'
        >
          {riskRating()}
        </ModalSkeleton>
      )}
    </>
  );
};
export default RiskManagementTab;
