import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import { generalActions, participantActions, spActions } from "../actions";
import api from "../services/participant.services";
import ACTIONS from "../constants";
import { TOAST_TYPE } from "../../Constants/constant";

function* getParticipant(state) {
  try {
    const userType = yield localStorage.getItem("userType");
    yield put(participantActions.getParticipant.pending);
    const response = yield call(api.getParticipantRequest, {
      ...state.payload,
      userType,
    });
    if (response.data) {
      yield put(participantActions.getParticipant.success(response.data));
    }
  } catch (error) {
    yield put(participantActions.getParticipant.error(error));
  }
}

function* getParticipantSupportPlan(state) {
  try {
    yield put(participantActions.getParticipantSupportPlan.pending);
    const response = yield call(
      api.getParticipantSupportPlanRequest,
      state.payload
    );
    if (response.data) {
      yield put(participantActions.getParticipantSupportPlan.success(response));
    }
  } catch (error) {
    yield put(participantActions.getParticipantSupportPlan.error(error));
  }
}

function* getParticipantQuickSnapshot(state) {
  try {
    yield put(participantActions.getParticipantQuickSnapshot.pending);
    const response = yield call(
      api.getParticipantQuickSnapshotRequest,
      state.payload
    );
    if (response.data) {
      yield put(
        participantActions.getParticipantQuickSnapshot.success(response)
      );
    }
  } catch (error) {
    yield put(participantActions.getParticipantQuickSnapshot.error(error));
  }
}

function* participantSettingUpdate(state) {
  try {
    yield put(participantActions.participantSettingUpdate.pending);
    const response = yield call(api.participantSettingUpdate, state.payload);
    if (response) {
      yield put(participantActions.participantSettingUpdate.success(response));
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Participant setting updated successfully",
        })
      );
      yield put(spActions.updateParticipantData(response));
    }
  } catch (error) {
    yield put(participantActions.participantSettingUpdate.error(error));
    yield put(
      generalActions.showToast({
        type: TOAST_TYPE.ERROR,
        title: error && error.message ? error.message : "Something went Wrong!",
      })
    );
  }
}

function* getParticipantAppointments(state) {
  try {
    yield put(participantActions.participantAppointmentList.pending);
    const response = yield call(api.getParticipantAppointments, state.payload);
    if (response) {
      yield put(
        participantActions.participantAppointmentList.success(response)
      );
    }
  } catch (error) {
    yield put(participantActions.participantAppointmentList.error(error));
  }
}

function* createParticipantAppointment(state) {
  try {
    yield put(participantActions.participantAppointmentCreate.pending);
    const response = yield call(
      api.createParticipantAppointments,
      state.payload
    );
    if (response) {
      yield put(
        participantActions.participantAppointmentCreate.success(response)
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Appointment created successfully!",
        })
      );
    }
  } catch (error) {
    yield put(participantActions.participantAppointmentCreate.error(error));
  }
}

function* updateParticipantAppointment(state) {
  try {
    yield put(participantActions.participantAppointmentUpdate.pending);
    const response = yield call(
      api.updateParticipantAppointments,
      state.payload
    );
    if (response) {
      yield put(
        participantActions.participantAppointmentUpdate.success(
          response.participant_appointments
        )
      );
      yield put(
        generalActions.showToast({
          type: TOAST_TYPE.SUCCESS,
          title: "Appointment updated successfully!",
        })
      );
    }
  } catch (error) {
    yield put(
      participantActions.participantAppointmentUpdate.error(
        error.message || error
      )
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.GET_PARTICIPANT_CALL, getParticipant),
    takeEvery(ACTIONS.PARTICIPANT_SUPPORT_PLAN_CALL, getParticipantSupportPlan),
    takeEvery(
      ACTIONS.PARTICIPANT_QUICK_SNAPSHOT_CALL,
      getParticipantQuickSnapshot
    ),
    takeEvery(
      ACTIONS.PARTICIPANT_SETTING_UPDATE_CALL,
      participantSettingUpdate
    ),
    takeEvery(
      ACTIONS.PARTICIPANT_APPOINTMENT_LIST_CALL,
      getParticipantAppointments
    ),
    takeLatest(
      ACTIONS.PARTICIPANT_APPOINTMENT_CREATE_CALL,
      createParticipantAppointment
    ),
    takeLatest(
      ACTIONS.PARTICIPANT_APPOINTMENT_UPDATE_CALL,
      updateParticipantAppointment
    ),
  ]);
}
