import { XCircleIcon } from "@heroicons/react/outline";
import React from "react";

const Alert = ({ textAlert, QA }) => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex justify-center">
        {/* <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div> */}
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800 text-left">
            {textAlert}
          </h3>
          <p className="text-sm font-normal text-red-800 text-left">{QA}</p>
          <div className="mt-2 text-sm text-red-700">
            <ul className="pl-5 space-y-1 list-none">
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
