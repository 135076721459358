import { connect } from "react-redux";
import {
  getCategorySettingRequest,
  createNewCategoryRequest,
  updateCategoryRequest,
  emptyNewData,
  emptySettingData,
} from "store/actions/cos.actions";
import {
  createNewCategoryErrorSelector,
  createNewCategoryLoadingSelector,
  createNewCategorySelector,
  getCosCategorySetting,
  getCosSettingCategoriesLoader,
  updateCategoryErrorSelector,
  updateCategoryLoadingSelector,
  updateCategorySuccessSelector,
} from "store/selector/cos.selector";
import CategoryPage from "./CategoryPage";

const mapDispatchToProps = {
  createNewCategoryRequest,
  getCategorySetting: getCategorySettingRequest,
  updateCategoryRequest,
  emptyNewData,
  emptySettingData,
};

const mapStateToProps = (state) => {
  const createNewCategoryLoading = createNewCategoryLoadingSelector(state);
  const createNewCategoryError = createNewCategoryErrorSelector(state);
  const updateCategoryLoading = updateCategoryLoadingSelector(state);
  const updateCategoryError = updateCategoryErrorSelector(state);
  const cosCategorySetting = getCosCategorySetting(state);
  const createNewCategory = createNewCategorySelector(state);
  const updateCategorySuccess = updateCategorySuccessSelector(state);

  const getListLoader = getCosSettingCategoriesLoader(state);

  return {
    updateCategorySuccess,
    createNewCategoryLoading,
    createNewCategoryError,
    updateCategoryLoading,
    updateCategoryError,
    cosCategorySetting,
    createNewCategory,
    getListLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
