import React, { useEffect, useState } from "react";
import {
  BadgeCheckIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import {
  addFiltersAndPagination,
  apiGetRequest,
} from "../../../../helpers/Requests";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import { useContext } from "react";
import { LoginContext } from "../../../../helpers/LoginContext";
import { FiArrowUpRight } from "react-icons/fi";
import {
  baseURLImage,
  capitalizeFirstLetter,
  classNames,
  getNameProfile,
} from "../../../../Utils/utils";
import Header from "../../../../components/common/Header/Header";
import moment from "moment";
import NotificationDropDown from "components/common/NotificationDropDown/NotificationDropDown";
import ParticipantProgressBar from "components/common/ProgressBarNew/ParticipantProgressBar";
import { getUser } from "store/selector/auth.selector";
import { connect } from "react-redux";
import ProgressBarNew from "components/common/ProgressBarNew/ProgressBarNew";
import { workerProfileDetailsFetchRequest } from "store/actions/sp.actions";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
  PERMISSION_KEYS_CHILDREN,
} from "store/constants";
import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "Constants/constant";

const EmployeeDashboard = ({ currentLoginUser }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboard, setDashboard] = useState({});
  const [participants, setParticipants] = useState([]);
  const userType = localStorage.getItem("userType");
  const [progressbarData, setProgressBar] = useState([]);
  const stats = [
    { name: "Participants", stat: dashboard.number_of_participants },
    {
      name: "Member Since",
      stat: dashboard.employee_joining_date
        ? moment(dashboard.employee_joining_date).format("DD-MM-YYYY")
        : null,
    },
  ];

  const { sideBarPadding } = useContext(LoginContext);

  const actions = [
    {
      title: "Participants",
      detail: "View all Assigned Participants",
      href: "/participants",
      icon: ClockIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
      permissionName: PERMISSION_KEYS.PARTICIPANT,
    },
    {
      title: "Complaints",
      detail: "Add New Complaint",
      href: "/complaint",
      icon: BadgeCheckIcon,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
      permissionName: PERMISSION_KEYS.COMPLAINT,
    },
    {
      title: "My Documents",
      detail: "View all your documents in one place",
      href: "/documents",
      icon: UsersIcon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      permissionName: PERMISSION_KEYS.DOCUMENT,
    },
    {
      title: "Settings",
      detail: "Update Your Settings",
      href: "/settings",
      icon: ReceiptRefundIcon,
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
    },
  ];

  useEffect(() => {
    const getDashboard = async () => {
      try {
        setIsLoading(true);
        const res = await apiGetRequest("/hrm/employee-dashboard");
        if (res.status === 200) {
          setDashboard(res.data.data);
          setParticipants(res.data.data.latest_participants);
        }
        if (res.status !== 200) {
          console.log("error in fetching");
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log("err", err.message);
      }
    };
    getDashboard();
  }, []);

  const viewParticipantProfile = (uuid) => {
    sessionStorage.clear();
    navigate(`/participant-profile/${uuid}`);
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        let url = "";
        if (userType === "sp_standard_user") {
          url = addFiltersAndPagination(`/hrm/employee-docs`, {
            page: 1,
            per_page: 100,
          });
        } else {
          url = addFiltersAndPagination(`/sp/employee-docs`, {
            page: 1,
            per_page: 100,
          });
        }

        const response = await apiGetRequest(
          `${url}&profile_id=${currentLoginUser?.uuid}`
        );

        const uploadedDoc = response?.data?.data?.employee_documents.data;

        // setDocuments(uploadedDoc);
        getDocumentsList(uploadedDoc);
      } catch (error) {
        console.error("Error fetching documents:", error);
      } finally {
        setIsLoading(false);
      }
    }
    if (
      roleAccess(
        [],
        PERMISSION_KEYS.DOCUMENT,
        BLK_PORTAL_PREMISSION_KEYS_CHILDREN.DOCUMEMT.SHOW
      )
    ) {
      fetchData();
    }
  }, []);

  //to get documents list
  const getDocumentsList = async (uploadedDoc) => {
    try {
      const res = await apiGetRequest("/worker/employee/documents-list");
      if (res && res.status === 200) {
        // setDocumentList(res.data?.data);
        const documentData = res.data?.data;
        const dataArray = documentData?.map((item) => ({
          form_title: item.name,
          status: uploadedDoc.some((doc) => doc.name === item.name),
        }));

        setProgressBar(res.data?.data?.[0]);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const selectedParticipant = {
    on_boarding_progress: dashboard?.compliance_score,
  };

  return (
    <div className='min-full'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        } flex flex-col`}
      >
        <main className='flex-1 bg-gray-50'>
          <div className='w-full'>
            <div className='min-h-full'>
              <main className='flex-1'>
                <Header title='Dashboard' />
                <main className='px-6 max-w-7xl mx-auto'>
                  {/*<div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">*/}
                  {/*  <div className="flex">*/}
                  {/*    <div className="flex-shrink-0">*/}
                  {/*      <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />*/}
                  {/*    </div>*/}
                  {/*    <div className="ml-3">*/}
                  {/*      <p className="text-sm text-yellow-700">*/}
                  {/*        You have not completed the onboarding form yet which is required.{' '}*/}
                  {/*        <a href="#" className="font-medium underline text-yellow-700 hover:text-yellow-600">*/}
                  {/*          Submit onboarding form now.*/}
                  {/*        </a>*/}
                  {/*      </p>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div>
                    <div className='max-w-7xl mx-auto print:hidde mt-3'>
                      <div className='px-6 has-tooltip-progress  flex justify-center cursor-pointer print:hidden'>
                        {progressbarData.length > 0 && (
                          <ParticipantProgressBar
                            progressBarDetails={progressbarData}
                          />
                        )}

                        {selectedParticipant && (
                          <ProgressBarNew progressline={selectedParticipant} />
                        )}
                      </div>
                    </div>
                    <dl className='mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-2 md:divide-y-0 md:divide-x'>
                      {stats.map((item) => (
                        <div key={item.name} className='px-8 sm:p-8'>
                          <dt className='text-base font-normal text-gray-900'>
                            {item.name}
                          </dt>
                          <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                            <div className='flex items-baseline text-2xl font-semibold text-ndisPurple'>
                              {item.stat}
                            </div>
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                  {roleAccess(
                    [USER_ROLE.Sp_Standard_User],
                    PERMISSION_KEYS.PARTICIPANT
                  ) && (
                    <div className='w-md sm:w-3xl'>
                      <div className='mt-10'>
                        <h3 className='text-xs font-semibold text-gray-500 uppercase tracking-wide'>
                          Quick access participants
                        </h3>
                        <ul
                          className={`mt-4 grid grid-cols-1 gap-4 ${
                            isLoading ? "sm:grid-cols-1" : "sm:grid-cols-2"
                          }`}
                        >
                          {isLoading ? (
                            <CircularLoader
                              classes='flex justify-center items-center'
                              classloader='loader-l'
                            />
                          ) : (
                            Object.keys("latest_participants") &&
                            participants.map((person, personIdx) => (
                              <li
                                key={personIdx}
                                className='border rounded-full'
                              >
                                <button
                                  type='button'
                                  onClick={() =>
                                    viewParticipantProfile(person.uuid)
                                  }
                                  className='bg-white group p-2 w-full flex items-center justify-between rounded-full border border-gray-300 shadow-sm space-x-3 text-left hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                >
                                  <span className='min-w-0 flex-1 flex items-center space-x-3'>
                                    <span className='block flex-shrink-0'>
                                      <img
                                        onError={(e) =>
                                          handleImageError(
                                            e,
                                            person.first_name,
                                            person.last_name || " "
                                          )
                                        }
                                        className='h-10 w-10 rounded-full object-cover'
                                        src={
                                          person.profile_picture
                                            ? baseURLImage(
                                                person.profile_picture
                                              )
                                            : getNameProfile(
                                                person.first_name,
                                                person.last_name || " "
                                              )
                                        }
                                        alt=''
                                      />
                                    </span>
                                    <span className='block min-w-0 flex-1'>
                                      <span className='block text-sm font-medium text-gray-900 truncate capitalize'>
                                        {`${person.first_name} ${person.last_name}`}
                                      </span>
                                      {roleAccess(
                                        [USER_ROLE.Sp_Standard_User],
                                        PERMISSION_KEYS.PARTICIPANT,
                                        BLK_PORTAL_PREMISSION_KEYS_CHILDREN
                                          .PARTICIPANT.PRIMARY_EMAIL
                                      ) && (
                                        <span className='block text-sm font-medium text-gray-500 truncate'>
                                          {person.email
                                            ? capitalizeFirstLetter(
                                                person.email
                                              )
                                            : ""}
                                        </span>
                                      )}
                                    </span>
                                  </span>
                                  <span className='flex-shrink-0 h-10 w-10 inline-flex items-center justify-center'>
                                    <FiArrowUpRight className='h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                  </span>
                                </button>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                  <div className='my-8 rounded-lg bg-transparent overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px'>
                    {actions
                      .filter((x) =>
                        roleAccess(
                          [USER_ROLE.Sp_Standard_User],
                          x?.permissionName
                        )
                      )
                      .map((action, actionIdx) => (
                        <div
                          key={action.title}
                          className={classNames(
                            actionIdx === 0
                              ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                              : "",
                            actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                            actionIdx === actions.length - 2
                              ? "sm:rounded-bl-lg"
                              : "",
                            actionIdx === actions.length - 1
                              ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                              : "",
                            "relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
                          )}
                        >
                          <div className='flex'>
                            <span
                              className={classNames(
                                action.iconBackground,
                                action.iconForeground,
                                "rounded-lg inline-flex p-3 ring-4 ring-white"
                              )}
                            >
                              <action.icon
                                className='h-6 w-6'
                                aria-hidden='true'
                              />
                            </span>
                            <div className='ml-4'>
                              <h3 className='text-lg font-medium'>
                                <Link
                                  to={action.href}
                                  className='focus:outline-none'
                                >
                                  {/* Extend touch target to entire panel */}
                                  <span
                                    className='absolute inset-0'
                                    aria-hidden='true'
                                  />
                                  {action.title}
                                </Link>
                              </h3>
                              <p className='mt-2 text-sm text-gray-500'>
                                {action.detail}
                              </p>
                            </div>
                            <span
                              className='pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400'
                              aria-hidden='true'
                            >
                              <svg
                                className='h-6 w-6'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='currentColor'
                                viewBox='0 0 24 24'
                              >
                                <path d='M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z' />
                              </svg>
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </main>
              </main>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLoginUser: getUser(state),
  };
};
// const mapDispatchToProps = {

//   WorkerProfileDataRequest: workerProfileDetailsFetchRequest,

// };
export default connect(mapStateToProps, {})(EmployeeDashboard);
