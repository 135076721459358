import { connect } from "react-redux";
import {
  getParticipantCall,
  resetSelectedParticipant,
  resetSelectedParticipantDocuments,
} from "../../../store/actions/participant.actions";
import { resetParticipantFundings } from "../../../store/actions/cos.actions";
import {
  getLoading,
  getSelectedParticipant,
  getSelectedParticipantOnBoarding,
} from "../../../store/selector/participant.selector";
import ParticipantProfile from "./ParticipantProfile";
import { storeCurrentOpenTab } from "../../../store/actions/auth.actions";
import {
  getFileListForHRMSelector,
  getFolderListForHRMLoadingSelector,
  getFolderListForHRMSelector,
} from "store/selector/cos.selector";
import { getCurrentUser } from "helpers/Tokens";
import { getUser } from "store/selector/auth.selector";

const mapDispatchToProps = {
  getParticipant: getParticipantCall,
  resetSelectedParticipant,
  resetParticipantFundings,
  resetSelectedParticipantDocuments,
  storeCurrentOpenTab,
};

const mapStateToProps = (state) => {
  const isLoading = getLoading(state);
  const selectedParticipant = getSelectedParticipant(state);
  const participantOnBoarding = getSelectedParticipantOnBoarding(state);
  const getFolderListForHRM = getFolderListForHRMSelector(state);
  const getFileListForHRM = getFileListForHRMSelector(state);
  const getFolderListForHRMLoading = getFolderListForHRMLoadingSelector(state);
  // const currentUser = getUser(state);

  // removeFolderRequest,
  // removeFileRequest,

  return {
    FolderListForHRM: getFolderListForHRM,
    FileListForHRM: getFileListForHRM,
    isLoading,
    selectedParticipant,
    participantOnBoarding,
    getFolderListForHRMLoading,
    // currentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantProfile);
