import React from "react";
import moment from "moment";
import { textTruncate } from "Utils/utils";

function WelcomeBanner() {
  const styles = {
    fontSize: 35,
  };

  let timeOfDay = "";

  const getTimeOfDay = () => {
    const hours = moment(new Date()).hour();
    if (hours < 7 && hours >= 0) {
      Object.assign(styles, { color: "#04756F" });
      timeOfDay = "night";
    } else if (hours >= 7 && hours < 12) {
      timeOfDay = "morning";
      Object.assign(styles, { color: "#D90000" });
    } else if (hours >= 12 && hours < 17) {
      Object.assign(styles, { color: "#04733F" });
      timeOfDay = "afternoon";
    } else if (hours >= 17 && hours < 21) {
      Object.assign(styles, { color: "#04733F" });
      timeOfDay = "evening";
    } else {
      Object.assign(styles, { color: "#04756F" });
      timeOfDay = "night";
    }
    return timeOfDay;
  };

  return (
    <div className="relative bg-indigo-200 p-4 sm:p-6 rounded-sm overflow-hidden mb-8 md:rounded-lg">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
      >
        <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
            <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
            <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
              <stop stopColor="#A5B4FC" offset="0%" />
              <stop stopColor="#818CF8" offset="100%" />
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="24.537%"
              x2="50%"
              y2="100%"
              id="welcome-c"
            >
              <stop stopColor="#4338CA" offset="0%" />
              <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="rotate(64 36.592 105.604)">
              <mask id="welcome-d" fill="#fff">
                <use xlinkHref="#welcome-a" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-d)"
                d="M64-24h80v152H64z"
              />
            </g>
            <g transform="rotate(-51 91.324 -105.372)">
              <mask id="welcome-f" fill="#fff">
                <use xlinkHref="#welcome-e" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-f)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
            <g transform="rotate(44 61.546 392.623)">
              <mask id="welcome-h" fill="#fff">
                <use xlinkHref="#welcome-g" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-h)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className="relative">
        <h1
          style={styles}
          className="text-2xl  text-slate-800 font-bold mb-1 capitalize cursor-pointer"
        >
          {`Good ${getTimeOfDay()}! ${textTruncate(
            JSON.parse(localStorage.getItem("user")).name,
            37,
            36
          )} 👋 `}
        </h1>
        {/* <p className="text-xl">
          {"Here is what’s happening with your projects last month:"}
        </p> */}
      </div>
    </div>
  );
}

export default WelcomeBanner;
