import React from "react";
import { formatDateIntoDMMMY } from "Utils/utils";

const FundingStats = (props) => {
  const { funding } = props;

  return (
    <div className="min-h-full">
      <div
        className={`max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3`}
      >
        <div className="space-y-6 lg:col-start-1 lg:col-span-3 sm:px-2">
          {/* Description list*/}
          <section aria-labelledby="funding-information-title">
            <div className="bg-white shadow sm:rounded-lg border-1 border-[#EAECF0] shdow-sm">
              {/* <div className="px-4 py-4">
                <div className="grid sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-2">
                  <div className="col-span-1">
                    <h2 className="text-md font-bold text-gray-900">
                      Funding ID
                    </h2>
                    <p className="mt-1 max-w-5xl text-sm text-gray-500 break-words">
                      {funding.contract_id}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <h2 className="text-md font-bold text-gray-900">Status</h2>
                    <p className="mt-1 max-w-5xl text-sm text-gray-500 break-words">
                      <span
                        className={`${
                          funding.status
                            ? "bg-green-100 text-green-700"
                            : "bg-red-100 text-red-700"
                        } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
                      >
                        {funding.status ? "Active" : "Inactive"}
                      </span>
                      {funding.status}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <h2 className="text-md font-bold text-gray-900">
                      Setup By
                    </h2>
                    <p className="mt-1 max-w-5xl text-sm text-gray-500 break-words capitalize">
                      {funding.created_by ? funding.created_by.name : "-"}
                    </p>
                  </div>
                   <div className="col-span-1">
                    <h2 className="text-md font-bold text-gray-900">
                      Participant Name
                    </h2>
                    <p className="mt-1 max-w-5xl text-sm text-gray-500 break-words capitalize">
                      {funding.participant
                        ? `${funding.participant.first_name} ${funding.participant.last_name}`
                        : "-"}
                    </p>
                  </div> 
                </div>
              </div> */}
              <div className="px-4 py-4">
                <div className="grid grid-cols-4 gap-2">
                  <div className="col-span-1">
                    <h2 className="text-md font-bold text-gray-900">
                      Setup By
                    </h2>
                    <p className="mt-1 max-w-5xl text-sm text-gray-500 break-words capitalize">
                      {funding?.created_by ?? "-"}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <h2 className="text-md font-bold text-gray-900">
                      Funding Start Date
                    </h2>
                    <p className="mt-1 max-w-5xl text-sm text-gray-500 break-words capitalize">
                      {formatDateIntoDMMMY(funding.start_date)}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <h2 className="text-md font-bold text-gray-900">
                      Review Date
                    </h2>
                    <p className="mt-1 max-w-5xl text-sm text-gray-500 break-words">
                      {formatDateIntoDMMMY(funding.review_date)}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <h2 className="text-md font-bold text-gray-900">
                      Funding End Date
                    </h2>
                    <p className="mt-1 max-w-5xl text-sm text-gray-500 break-words">
                      {formatDateIntoDMMMY(funding.end_date)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default FundingStats;
