import React from "react";
import { formatDateIntoDMYToDisplay, gethoursAndMins } from "Utils/utils";
import CheckBox from "../../../../../components/common/checkBox/checkbox";

const COSInvoiceTabel = (props) => {
  const { item, checkboxes, handleCheckboxChange, isUserGracePeriodOver } =
    props;

  const showDataInTable = (item) => {
    const parsedData = JSON.parse(item.manged_by);
    const data = [];
    const filtedData = parsedData.filter((items) => {
      return items.label !== "NDIA Managed";
    });
    filtedData.forEach((element) => {
      data.push(element.label);
    });
    return data;
  };

  return (
    <tr className='text-center even:bg-white odd:bg-gray-50'>
      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          <CheckBox
            isDisabled={isUserGracePeriodOver}
            handleChange={handleCheckboxChange}
            checked={checkboxes.indexOf(item.id) !== -1}
          />
        </div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{item.delivered_service_number}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize'>
        <div className='text-gray-500'>{item.created_by}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize'>
        <div className='text-gray-500'>
          {formatDateIntoDMYToDisplay(item.created_at)}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize'>
        <div className='text-gray-500'>{item.participant}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{showDataInTable(item).join()}</div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{item.support_item_name}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{item.support_item_number}</div>
      </td>
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">
          {item?.cos?.first_name.concat(" ", item?.cos?.last_name)}
        </div>
      </td> */}
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">{"Not Added yet"}</div>
      </td> */}
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {item?.invoice_status === "ready_for_extraction" &&
            "Ready For Extraction"}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {item.delivered_from && item.delivered_to_date
            ? `${formatDateIntoDMYToDisplay(
                item.delivered_from
              )} - ${formatDateIntoDMYToDisplay(item.delivered_to_date)}`
            : item.delivered_date
            ? formatDateIntoDMYToDisplay(item.delivered_date)
            : "-"}
        </div>
      </td>
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">
          {moment(item.delivered_from).format("DD/MM/YYYY")}
        </div>
      </td> */}
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500">
          {moment(item.delivered_to_date).format("DD/MM/YYYY")}
        </div>
      </td> */}
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-500"> </div>
      </td> */}
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {gethoursAndMins(item?.hours, item?.mints)}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {`$${
            item.alternative_rate && parseFloat(item.alternative_rate) > 0
              ? parseFloat(item.alternative_rate)
              : parseFloat(item.original_rate)
          }`}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{`$${item.total_amount}`}</div>
      </td>
    </tr>
  );
};

export default COSInvoiceTabel;
