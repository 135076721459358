import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import LoginScreenSection from "components/common/LoginScreenSection/LoginScreenSection";
import Email from "../../../../assets/email.png";
import Phone from "../../../../assets/phonet.png";
import { clearTimeout } from "highcharts";
import { callLogout } from "store/actions/auth.actions";
import { useDispatch } from "react-redux";
import AutoShiftBoxes from "components/common/AutoShiftBoxes/AutoShiftBoxes";
import { LOADER_STATUS } from "Constants/constant";
import { LoginContext } from "helpers/LoginContext";

const TwoFAEnabledPage = (props) => {
  const {
    sendOTPForConfirmationRequest,
    reSendOTPRequest,
    confirmOtpError,
    confirmOtpSuccess,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("***2442.");
  const [otp, setOtp] = useState(null);
  const [errorCustom, setCustomError] = useState(false);
  const [attempt, setAttempt] = useState();
  const [counter, setCounter] = useState(120);
  const via = localStorage.getItem("via");

  const { unSubscribeChannels } = useContext(LoginContext);

  const [request, setRequest] = useState(LOADER_STATUS.NA);

  const [isClearOtp, setIsClearOtp] = useState(LOADER_STATUS.NA);

  useEffect(() => {
    if (confirmOtpError) {
      setRequest(LOADER_STATUS.COMPLATE);
      setCustomError(true);
    }
  }, [confirmOtpError]);

  useEffect(() => {
    if (confirmOtpSuccess === true) {
      setRequest(LOADER_STATUS.COMPLATE);
      localStorage.setItem("step", "3");
      navigate("/verified-otp");
    }
  }, [confirmOtpSuccess]);

  useEffect(() => {
    if (otp && otp.length === 4) {
      setRequest(LOADER_STATUS.PENDING);
      sendOTPForConfirmationRequest({
        navigate,
        otp,
        authentication_uuid: localStorage.getItem("authentication_uuid"),
      });
    }
  }, [otp]);

  const handelResend = () => {
    setIsClearOtp(LOADER_STATUS.COMPLATE);
    setAttempt((prev) => parseInt(prev) + 1);
    reSendOTPRequest({
      authentication_uuid: localStorage.getItem("authentication_uuid"),
    });
    localStorage.setItem("attempt", attempt);
    setCustomError(false);
    setCounter(120);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.phone_2fa) {
      setPhone(` ***${user.phone_2fa.substr(-4)}.`);
    }
  }, []);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(
        () => setCounter(counter > 10 ? counter - 1 : `0${counter - 1}`),
        1000
      );
    } else {
      clearTimeout();
    }
  }, [counter]);

  useEffect(() => setAttempt(localStorage.getItem("attempt")), []);

  useEffect(() => attempt === 4 && handleOnLogInClick(), [attempt]);

  const handleOnLogInClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    unSubscribeChannels();
    dispatch(callLogout());
    navigate("/");
  };
  const minutes = Math.floor(counter / 60);
  const seconds = counter % 60;

  return (
    <div>
      <div className='min-h-screen flex'>
        <div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 lg:w-1/2 xl:px-24'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <div>
              <h2 className='mt-6 flex justify-center mb-6'>
                <div className='rounded-full bg-[#D1E9FF] w-16 h-16'>
                  <div className='rounded-full bg-blue-200 w-12 h-12 relative top-[8px] left-[8px]'>
                    <img
                      src={via === "email" ? Email : Phone}
                      alt='otp type'
                      className='inline px-4 relative top-[10px]'
                    />
                  </div>
                </div>
              </h2>
              <div className='text-center text-gray-900 text-3xl font-semibold mt-2'>
                Authenticate Your Account
              </div>
              {via === "phone" && (
                <div className='text-center text-gray-500 font-normal text-base mt-2'>
                  <p>
                    4 digit code has been sent to your phone number ending with
                    {phone}
                  </p>
                </div>
              )}

              {via === "email" && (
                <div className='text-center text-gray-500 font-normal text-base mt-2'>
                  <p>4 digit code has been sent to your email</p>
                </div>
              )}

              <div className='grid-cols-4 gap-2 mt-6 px-4 flex justify-center'>
                <AutoShiftBoxes
                  isClearFields={isClearOtp}
                  setIsClearOtp={setIsClearOtp}
                  // isDisabledBoxes={request}
                  setConfirmationCode={setOtp}
                  length={4}
                  onComplete={() => {}}
                  classes={`border h-10 sm:h-12 w-10 sm:w-12 mx-1 text-center border-blue-dark rounded-lg text-blue-dark text-sm sm:text-xl focus-visible:outline-none focus-visible:border-2 ${
                    request === LOADER_STATUS.COMPLATE && errorCustom === true
                      ? "borderRed"
                      : "border"
                  }`}
                />
              </div>
              {request === LOADER_STATUS.PENDING ? (
                <div className='p-2 flex justify-center mt-6'>
                  <span className='inline-flex items-center rounded-full px-3 py-1 text-xs font-medium  bg-blue-200 gap-3'>
                    <p className='text-blue font-semibold pr-1'>
                      Logging in...
                    </p>
                  </span>
                </div>
              ) : (
                errorCustom === true && (
                  <div className='flex justify-center mt-6'>
                    <span className='inline-flex items-center rounded-full px-1 py-0.5 text-xs font-medium  bg-[#FEF3F2] gap-3'>
                      <span className='inline-flex items-center rounded-full bg-[#FFFFFF] px-2.5 py-0.5 text-xs font-semibold text-[#B42318]'>
                        Error
                      </span>
                      <p className='text-[#B42318] font-semibold pr-1'>
                        Incorrect OTP entered!
                      </p>
                    </span>
                  </div>
                )
              )}

              {counter > 0 && attempt < 4 && (
                <div className='text-center mt-6'>
                  {`${minutes < 10 ? "0" + minutes : minutes}:${
                    seconds < 10 ? "0" + seconds : seconds
                  }`}
                </div>
              )}
              {(counter === "00" || counter === 0) && (
                <div className='text-sm flex justify-center mt-6'>
                  <span
                    onClick={handelResend}
                    className='
                        cursor-pointer
                        font-semibold
                        text-blue-dark
                        hover:text-blue-dark
                        text-sm'
                  >
                    Resend Code
                  </span>
                </div>
              )}
              <div className='text-sm flex justify-center mt-6'>
                <div
                  className='
                        font-normal
                        text-gray-500
                        text-sm'
                >
                  Don't have access?{" "}
                  <span
                    className='text-blue-dark
                        hover:text-blue-dark
                        cursor-pointer
                        '
                  >
                    Talk to Customer Support.
                  </span>
                </div>
              </div>
              <div className='text-sm flex justify-center mt-6 space-x-3'>
                <div className='relative top-[3px] cursor-pointer'>
                  <AiOutlineArrowLeft className='text-gray-500 font-semibold' />
                </div>
                <div
                  className='
                        font-semibold
                        text-gray-500                   
                        text-sm
                      '
                >
                  <span className='cursor-pointer' onClick={handleOnLogInClick}>
                    Back to log in
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginScreenSection
          heading={`Vertex360 has streamlined our NDIS compliance to an extent that we look forward to coming to work now !`}
          userImg='use image here'
          name='Dale Florence'
          desc='NDIS Service Provider'
        />
      </div>
    </div>
  );
};

export default TwoFAEnabledPage;
