import React from "react";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { useNavigate } from "react-router";
import { apiPostRequest } from "helpers/Requests";
import { URL_CREATE_ROSTER_INVOICES } from "store/services/URL";

const RosterModal = (props) => {
  const { openModel, handleClose, amount, quantity, checkboxes } = props;

  const navigate = useNavigate();

  const createRosterInvoice = () => {
    apiPostRequest(URL_CREATE_ROSTER_INVOICES, {
      timesheets: checkboxes?.map((item) => item) || [],
    })
      .then((res) => {
        if (res?.status === 200) {
          handleClose(false);
          navigate("/rostering/invoicing");
        
        }
      })
      .catch((error) => console.log({ error }));
  };
  const isCloseToZero = (num) => Math.abs(num) < 0.000001;

  const modalFooter = () => (
    <>
      <CustomButton
        type='button'
        variant='secondary'
        label='Cancel'
        clickHandler={() => handleClose(false)}
        className='mr-2'
      />
      <CustomButton
        type='button'
        variant='primary'
        label='Create'
        clickHandler={() => {
          createRosterInvoice();
         
        }}
      />
    </>
  );

  return (
    <ModalSkeleton
      isOpen={openModel}
      title='Confirm Selection'
      cssClass='w-[40%]'
      modalFooter={modalFooter()}
      closeModal={() => handleClose(false)}
    >
      <div className='p-6'>
        <p className='text-gray-500 mb-6 text-center'>
          Please confirm the following:
        </p>
        <div className='grid grid-cols-2 gap-4'>
          <div className='bg-gray-100 rounded-lg p-4'>
            <h3 className='text-lg font-semibold mb-2'>Total Timesheet(s):</h3>
            <p className='text-xl font-bold'>{quantity}</p>
          </div>
          <div className='bg-gray-100 rounded-lg p-4'>
            <h3 className='text-lg font-semibold mb-2'>Total Amount:</h3>
            <p className='text-xl font-bold'>
              ${isCloseToZero(amount) ? 0 : parseFloat(amount).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default RosterModal;
