import { connect } from "react-redux";
import Dashboard from "./Dashboard";
import {
  getDashboardDataRequest,
  supportCoordinatorsRequest,
} from "../../../../store/actions/cos.actions";
import {
  getDashboardDataLoadingSelector,
  getDashboardDataSelector,
  getSupportCoordinator,
  getSupportCoordinatorLoader,
} from "../../../../store/selector/cos.selector";

const mapDispatchToProps = {
  getDashboardDataRequest,
  supportCoordinatorsRequest,
};

const mapStateToProps = (state) => {
  const getDashboardData = getDashboardDataSelector(state);
  const getDashboardDataLoading = getDashboardDataLoadingSelector(state);
  const supportCoordinatorListLoader = getSupportCoordinatorLoader(state);
  const supportCoordinatorOptions = getSupportCoordinator(state);

  return {
    getDashboardData,
    getDashboardDataLoading,
    supportCoordinatorListLoader,
    supportCoordinatorOptions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
