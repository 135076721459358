import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineEye } from "react-icons/hi";
import moment from "moment";
import { IMAGE_URL } from "store/services/URL";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdMarkEmailRead } from "react-icons/md";
import CheckBox from "components/common/checkBox/checkbox";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { formatAmount } from "Utils/utils";

const RosterInvoicesTable = (props) => {
  const { item, handleCheckboxChange, checkboxes } = props;

  function handleDownload(pdfUrl) {
    const appendedURL = `${IMAGE_URL}${pdfUrl}`;
    window.open(appendedURL, "_blank");
  }
  return (
    <tr className='text-center even:bg-white odd:bg-gray-50'>
      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          <CheckBox
            handleChange={handleCheckboxChange}
            checked={checkboxes?.indexOf(item?.uuid) !== -1}
            isDisabled={item?.status === "Void"}
          />
        </div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500 text-start '>
        <div className='text-gray-500'>{item?.invoice_number}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
        <div className='text-gray-500 capitalize'>
          {/* {
            item?.participant && item?.participant?.length >0 && item?.participants?.length === 1 ? `${item?.participants[0]?.first_name} ${item?.participants[0]?.last_name}` :  
            <AvatarGroup
              arrayData={item?.participants}
              cssClass='mt-0'
              maxLength={10}
            />
          } */}
          {item?.participant?.first_name} {item?.participant?.last_name}
        </div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500 text-start capitalize'>
        <div className='text-gray-500 capitalize'>
          {item?.created_by?.name}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
        <div className='text-gray-500'>
          {moment(item?.created_at).format("DD-MM-YYYY")}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
        <div className='text-gray-500'>
          {item?.date_from
            ? moment(item?.date_from).format("DD-MM-YYYY")
            : "N/A"}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
        <div className='text-gray-500'>
          {item?.date_to ? moment(item?.date_to).format("DD-MM-YYYY") : "N/A"}
        </div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
        <div className='text-gray-500 capitalize'>{item?.status}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
        <div className='text-gray-500'>
          {" "}
          {item?.total_timesheets ? item?.total_timesheets : 0}
        </div>
      </td>

      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
        <div className='text-gray-500'>
          ${item?.total_amount ? formatAmount(parseFloat(item?.total_amount).toFixed(2)) : 0}
        </div>
      </td>

      <td>
        <div className='flex justify-center gap-x-2 p-1'>
          <Link
            className='icon-eye-wrapper inline-block'
            to={`/rostering/roster-invoice-preview/${item?.uuid}`}
            title='View'
          >
            <HiOutlineEye className='icon-size' />
          </Link>

          <span
            title={`${
              item?.file_pdf ? "Download pdf" : "Not Extracted As Pdf"
            }`}
            onClick={
              item?.file_pdf ? () => handleDownload(item.file_pdf) : null
            }
            className={`text-rose-600  border border-rose-600  p-1 rounded-md ${
              item?.file_pdf
                ? "cursor-pointer hover:text-white hover:border-rose-600 hover:bg-rose-600 hover:transition-all-custom"
                : "cursor-not-allowed opacity-30"
            }`}
          >
            <BsFillFileEarmarkPdfFill className='h-4 w-4' />
          </span>

          <span
            title={`${
              item?.file_csv ? "Download csv" : "Not Extracted As CSV"
            }`}
            onClick={
              item?.file_csv ? () => handleDownload(item?.file_csv) : null
            }
            className={`text-green-700 border border-green-700  p-1 rounded-md ${
              item?.file_csv
                ? "cursor-pointer hover:text-white border hover:border-green-700 hover:bg-green-700 hover:transition-all-custom"
                : "cursor-not-allowed opacity-30"
            }`}
          >
            <SiMicrosoftexcel className='h-4 w-4' />
          </span>

          <span
            title={`${
              item?.email_sent ? "Invoice Email Sent" : "Invoice Email Not Sent"
            }`}
            className={`text-purple-700   border border-purple-700  p-1 rounded-md ${
              item?.email_sent
                ? "cursor-pointer hover:text-white hover:border-purple-700 hover:bg-purple-700 hover:transition-all-custom"
                : "cursor-not-allowed opacity-30"
            }`}
          >
            <MdMarkEmailRead className='h-4 w-4' />
          </span>
        </div>
        {/* <div>
       
        </div> */}
      </td>
    </tr>
  );
};

export default RosterInvoicesTable;
