import { roleAccess } from "helpers/RolesPermission";
import { USER_ROLE } from "../../Constants/constant";
import { apiCall } from "./service";
import {
  URL_GET_PARTICIPANT,
  URL_PARTICIPANT_SUPPORT_PLAN,
  URL_PARTICIPANT_SETTING,
  URL_PARTICIPANT_APPOINTMENT,
  URL_HRM_APPOINTMENT,
  URL_HRM_GET_PARTICIPANT,
} from "./URL";
const token = localStorage.getItem("accessToken");

export const api = {
  getParticipantRequest(params) {
    return apiCall.get({
      url: `${
        params.userType === USER_ROLE.Sp_Standard_User
          ? URL_HRM_GET_PARTICIPANT
          : URL_GET_PARTICIPANT
      }/${params.participantUUID}`,
      isAuthToken: token,
      params: {},
    });
  },
  getParticipantSupportPlanRequest(params) {
    return apiCall.get({
      url: `${URL_PARTICIPANT_SUPPORT_PLAN}?pt_id=${params.participantId}`,
      isAuthToken: token,
      params: {},
    });
  },
  getParticipantQuickSnapshotRequest(params) {
    return apiCall.get({
      url: `${URL_PARTICIPANT_SETTING}/${params.participantUUID}`,
      isAuthToken: token,
      params: {},
    });
  },
  participantSettingUpdate(params) {
    return apiCall.post({
      url: `${URL_PARTICIPANT_SETTING}/${params.participantUUID}`,
      isAuthToken: token,
      params: params.formData,
    });
  },
  getParticipantAppointments(params) {
    return apiCall.get({
      url: roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])
        ? `${URL_PARTICIPANT_APPOINTMENT}?pt_id=${params.uuid}`
        : `${URL_HRM_APPOINTMENT}?pt_id=${params.uuid}`,
      isAuthToken: token,
      params: {},
    });
  },
  createParticipantAppointments(params) {
    return apiCall.post({
      url: URL_PARTICIPANT_APPOINTMENT,
      isAuthToken: token,
      params,
    });
  },
  updateParticipantAppointments(params) {
    return apiCall.put({
      url: `${URL_PARTICIPANT_APPOINTMENT}/update`,
      isAuthToken: token,
      params,
    });
  },
  deleteParticipantAppointment(params) {
    return apiCall.delete({
      url: `${URL_PARTICIPANT_APPOINTMENT}/${params.appointmentId}`,
      isAuthToken: token,
      params: {},
    });
  },
};

export default api;
