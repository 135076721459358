import React, { useMemo } from "react";
import NumberFormatter from "components/common/NumberFormatter/NumberFormatter";

const BudgetStats = (props) => {
  const { funding } = props;

  const loadStats = useMemo(() => {
    if (!funding) return "";
    const stats = [
      {
        name: "Total Funding",
        stat: `$${funding.total_contract_amount ?? 0}`,
        color: "#32D583",
      },
      {
        name: "Total Allocated",
        stat: `$${funding.amount_allocated ?? 0}`,
        color: "#F38744",
      },
      {
        name: "Total Delivered",
        stat: `$${funding.amount_delivered ?? 0}`,
        color: "#53B1FD",
      },
      // {
      //   name: "Total Claimed",
      //   stat: `$${funding.amount_claimed ?? 0}`,
      //   color: "#FF4405",
      // },
      // {
      //   name: "Total Unclaimed",
      //   stat: `$${funding.amount_unclaimed ?? 0}`,
      //   color: "#FAC515",
      // },
      {
        name: "Total Remaining",
        stat: `$${funding.amount_remaining ?? 0}`,
        color: "#F87171",
      },
    ];
    return stats.map((item, index) => (
      <div
        key={index}
        style={{ borderLeft: `4px solid ${item.color}` }}
        className="grid-span-1 overflow-hidden rounded-lg py-5 pl-4 bg-white border transition ease-in-out duration-150 hover:shadow-lg"
      >
        <dt className="truncate text-sm font-bold text-gray-500">
          {item.name}
        </dt>
        <dd title={item.stat}>
          {/* {`$${NumberWithInternationalSign(
            item.stat.replace("$", ""),
            true,
            3
          )}`} */}
          <NumberFormatter
            cssClass="mt-1 text-2xl font-semibold tracking-tight text-gray-900"
            prefix={"$"}
            value={item.stat.replace("$", "")}
          />
        </dd>
      </div>
    ));
  }, [funding]);

  return (
    <div>
      <div className="min-h-full">
        <div className={`py-4 max-w-3xl mx-auto sm:px-8 lg:max-w-7xl`}>
          <dl className="grid lg:grid-cols-2 gap-2 sm:grid-cols-1">
            {loadStats}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default BudgetStats;
