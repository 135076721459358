import React from "react";
import { useNavigate } from "react-router";
const NewCoSStatsCard = (props) => {
  const { item, index, cssClass } = props;
  const { name, imageSrc, color, shadow, userRole, icon } = item;
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        if (item.href) navigate(`/${item.href}`);
      }}
      key={index}
      className={`mt-2 grid-span-1 overflow-hidden rounded-lg py-3 pl-3 bg-white border transition ease-in-out duration-150 ${shadow} cursor-pointer ${cssClass} ${userRole}`}
    >
      <div className={`${color} rounded-lg inline-block`}>
        {imageSrc ? (
          <img src={imageSrc} className="px-2 py-2 bg" alt="cards" />
        ) : (
          <>{icon}</>
        )}
      </div>
      <div className="pt-4 capitalize">{name}</div>
    </div>
  );
};

export default NewCoSStatsCard;
