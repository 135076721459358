import React, { useEffect, useState, useContext } from "react";
import { BiPencil, BiPlus } from "react-icons/bi";
import { textTruncate } from "Utils/utils";
import Breadcrumb from "components/common/Breadcrumb";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import Header from "components/common/Header/Header";
import SideBar from "components/common/SideBar/SideBarNew";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import { LoginContext } from "helpers/LoginContext";
import CreatePeriorityModel from "../Models/CreatePeriorityModel";

const PriorityPage = (props) => {
  const {
    updatePrioritySuccess,
    createNewPriorityLoading,
    createNewPriorityError,
    emptyNewData,
    updatePriorityLoading,
    updatePriorityError,
    cosPrioritySetting,
    newPeriorityList,
    createNewPeriorityRequest,
    updatePriorityRequest,
    getPrioritySetting,
    getListLoader,
  } = props;

  const { sideBarPadding } = useContext(LoginContext);
  const [selectedpriority, setSelectedPriority] = useState(null);
  const [openCreateModel, setOpenCreateModel] = useState(false);

  useEffect(() => {
    getPrioritySetting({ status: "all" });
    return () => emptyNewData();
  }, []);

  return (
    <div>
      <div className="min-h-full">
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <Header
            title="Task Priorities"
            handleClick={() => {
              setSelectedPriority(null);
              setOpenCreateModel(true);
            }}
            btnLabel="Add New"
            icon={<BiPlus />}
            btnCss=""
            isButton
          />
          <div className="sm:px-6 lg:px-8 my-3">
            <Breadcrumb
              pages={COS_BREADCRUMB(COS_BREADCRUMB_PAGES.COS_PRIORITY_SETTING)}
            />
          </div>
          <div className="col-span-1 px-8 mt-4">
            <div className="block bg-white border border-gray-200 rounded-lg shadow-md">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50  sticky top-[80px] z-[2] capitaliz">
                  <tr>
                    <th
                      scope="col"
                      className="text-gray-900 py-3.5 pl-3 pr-4 text-left bg-blue-50 text-sm font-semibold w-[20%]"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="text-gray-900 py-3.5 pl-3 pr-4 text-left bg-blue-50 text-sm font-semibold w-[70%]"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="text-gray-900 py-3.5 pl-3 pr-4 text-left bg-blue-50 text-sm font-semibold w-[70%]"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="text-gray-900 py-3.5 pl-3 pr-4 text-left bg-blue-50 text-sm font-semibold"
                    >
                      Actions
                    </th>
                    <th
                      scope="col"
                      className="text-gray-900 py-3.5 pl-3 pr-4 text-left bg-blue-50 text-sm font-semibold"
                    />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white overflow-y-auto">
                  {getListLoader ? (
                    <tr>
                      <td colSpan={5}>
                        <div
                          className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                        >
                          <CircularLoader
                            classes="flex justify-center items-center"
                            classloader="loader-l"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : cosPrioritySetting !== null &&
                    cosPrioritySetting.length > 0 ? (
                    cosPrioritySetting.map((item, index) => (
                      <tr key={index}>
                        <td
                          title={item.title}
                          className=" px-3 py-4 text-sm text-gray-500"
                        >
                          {textTruncate(item.title, 40, 39)}
                        </td>
                        <td
                          title={item.note}
                          className=" px-3 py-4 text-sm text-gray-500"
                        >
                          {textTruncate(item.note, 100, 99)}
                        </td>
                        <td className=" px-3 py-4 text-sm text-gray-500">
                          <div
                            className={`${
                              item.status === "inactive"
                                ? "text-red-500"
                                : "text-green-500"
                            } flex items-center space-x-1 whitespace-nowrap`}
                          >
                            <span
                              className={`${
                                item.status === "inactive"
                                  ? "bg-red-50"
                                  : "bg-green-50"
                              } text-sm inline-flex items-center px-3 py-0.5 rounded-full font-medium`}
                            >
                              {item.status === "inactive"
                                ? "In Active"
                                : "Active"}
                            </span>
                          </div>
                        </td>
                        <td className="relative  py-4 pl-3 pr-4 text-center text-sm font-medium">
                          <div className="flex items-center justify-start gap-x-3">
                            <div
                              onClick={() => {
                                setSelectedPriority(item);
                                setOpenCreateModel(true);
                              }}
                              className="icon-pencil-wrapper cursor-pointer"
                            >
                              <BiPencil className="icon-size" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    cosPrioritySetting &&
                    cosPrioritySetting?.length === 0 && (
                      <tr>
                        <td
                          colSpan={4}
                          className="py-6 text-center text-red-600"
                        >
                          No data found !
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {openCreateModel && (
        <CreatePeriorityModel
          isOpen={openCreateModel}
          closeModal={() => {
            setSelectedPriority(null);
            setOpenCreateModel(false);
          }}
          createNewPeriorityRequest={createNewPeriorityRequest}
          createNewPriorityLoading={createNewPriorityLoading}
          createNewPriorityError={createNewPriorityError}
          updatePrioritySuccess={updatePrioritySuccess}
          updatePriorityLoading={updatePriorityLoading}
          updatePriorityError={updatePriorityError}
          updatePriorityRequest={updatePriorityRequest}
          selectedpriority={selectedpriority}
        />
      )}
    </div>
  );
};

export default PriorityPage;
