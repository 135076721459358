import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginScreenSection from "components/common/LoginScreenSection/LoginScreenSection";
import Tic from "../../../../assets/tic.png";

const TwoFANotEnabledOTPPage = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.setItem("step", false);
      navigate("/dashboard");
    }, 0); // Set a timeout for 3 seconds

    // Cleanup function to clear the timeout in case the component unmounts
    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <div className="">
      {/* <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 lg:w-1/2 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 flex justify-center mb-6">
                <div className="rounded-full bg-[#ECFDF3] w-16 h-16 ">
                  <img
                    src={Tic}
                    alt=""
                    className="inline px-4 relative top-[16px] "
                  />
                </div>
              </h2>
              <div className="text-center text-gray-900 text-3xl font-semibold mt-2 whitespace-nowrap">
                OTP Authentication Activated
              </div>
              <div className="text-center text-gray-500 font-normal text-base mt-2">
                <p>
                  Congratulation! Your account is now as secure as Fort knox on
                  Christmas Eve. Remember, sharing is caring, but sharing your
                  password is not.
                </p>
              </div>
            </div>
          </div>
        </div>
        <LoginScreenSection
          heading={`Vertex360 has streamlined our NDIS compliance to an extent that we look forward to coming to work now !`}
          userImg="use image here"
          name="Dale Florence"
          desc="NDIS Service Provider"
        />
      </div> */}
    </div>
  );
};

export default TwoFANotEnabledOTPPage;
