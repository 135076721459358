import React, { useContext } from "react";
import { classNames } from "Utils/utils";
import Header from "components/common/Header/Header";
import SideBarNew from "components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import { CgFileDocument } from "react-icons/cg";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router";
import { RiCalendar2Fill } from "react-icons/ri";
import { RiTeamLine } from "react-icons/ri";

const PersonalSetting = () => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const navigate = useNavigate();

  const cards = [
    {
      name: "SP Settings",
      role: "Service Provider Settings",
      href: "settings/sp-setting",
      icon: FiSettings,
      bgColor: "#8C198F",
      userRole: [],
      for: "Sp Setting",
      //   permissionChild: PERMISSION_KEYS_CHILDREN.HRM.APPLICANT.SHOW,
    },

    {
      name: "Worker Compliance Settings",
      role: "Manage workers compliance settings",
      href: "settings/worker-compliance-setting",
      icon: CgFileDocument,
      bgColor: "#FF456E",
      userRole: [],
      for: "Worker Compliance Settings",
      //   permissionChild: PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW,
    },
    {
      name: "Rostering Settings",
      role: "Manage Rostering settings",
      href: "settings/rostering-setting",
      icon: RiCalendar2Fill,
      bgColor: "#6C456E",
      userRole: [],
      for: "Rostering Settings",
    },
    {
      name: "Region Settings",
      role: "Manage Region Settings",
      href: "settings/region-setting",
      icon: RiTeamLine,
      bgColor: "#ADC3FB",
      userRole: [],
      for: "Region Settings",
      //   permissionChild: PERMISSION_KEYS_CHILDREN.HRM.WORKER.SHOW,
    },
  ];

  return (
    <>
      <div className='min-full'>
        <SideBarNew classes='bg-gradient' />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col fullScreenHeight`}
        >
          <main className='flex-1'>
            <Header title='Business Settings' />
            <div className='min-full'>
              <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 space-y-6 py-8'>
                <div
                  className={`grid gap-3 p-6 bg-gray-50 md:rounded-lg lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1`}
                >
                  {cards.map((card, index) => (
                    <div
                      onClick={() => {
                        if (card.href) navigate(`/${card.href}`);
                      }}
                      role='button'
                      tabIndex={0}
                      key={index}
                      className='cursor-pointer relative rounded-lg border border-gray-300 bg-white p-1 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 capitalize'
                    >
                      <div
                        className={classNames(
                          "flex-shrink-0 flex items-center justify-center w-16 h-12 py-8 text-white text-sm font-medium rounded-md"
                        )}
                        style={{ backgroundColor: `${card.bgColor}` }}
                      >
                        <card.icon
                          className={classNames(
                            "flex-shrink-0 h-5 w-5 text-white"
                          )}
                          aria-hidden='true'
                        />
                      </div>
                      <div className='flex-1 min-w-0'>
                        <div className='focus:outline-none'>
                          <span
                            className='absolute inset-0'
                            aria-hidden='true'
                          />
                          <p className='text-sm font-medium text-gray-900 mt-3'>
                            {card.name}
                          </p>
                          <p className='text-sm text-gray-500 truncate mb-3'>
                            {card.role}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default PersonalSetting;
