import { connect } from "react-redux";
import AllApplicants from "./AllApplicant";
import { getSPApplicantsListRequest } from "../../../../../store/actions/sp.actions";
import {
  getApplicants,
  getLoading,
} from "../../../../../store/selector/sp.selector";

const mapDispatchToProps = {
  getApplicantsList: getSPApplicantsListRequest,
};

const mapStateToProps = (state) => {
  const spApplicants = getApplicants(state);
  const loading = getLoading(state);
  return { loading, spApplicants };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllApplicants);
