import React, { useState } from "react";
import { useEffect } from "react";
import { BiPlus } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getSelectedTabSelector } from "store/selector/auth.selector";
import { PARENT_FORM_CATEGORY_SLUG } from "../../../Constants/constant";
import { apiGetRequest } from "../../../helpers/Requests";
import { getUrlRole, selectedTabState } from "../../../Utils/utils";
import CustomButton from "../../common/Button/CustomButton";

const DirectOpenFirstForm = (props) => {
  const { participantId, particpantUuid, slug, buttonText, role } = props;
  const navigate = useNavigate();
  const selectedTabSelector = useSelector(getSelectedTabSelector);

  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  useEffect(() => {
    const currentTab = selectedTabState.get();
    if (
      currentTab &&
      currentTab.hasOwnProperty("isModalOpen") &&
      currentTab.hasOwnProperty("slug") &&
      currentTab.slug === slug
    ) {
      handleClick();
      delete currentTab.isModalOpen;
      selectedTabState.save(JSON.stringify(currentTab));
    }
  }, [selectedTabState.get(), slug, selectedTabSelector]);

  const [form, setForm] = useState([]);

  const handleClick = async () => {
    if (request.isRequested) return;
    setRequest({ ...request, isRequested: true });
    try {
      let res = [];
      if (role === "hrm") {
        res = await apiGetRequest(
          `${getUrlRole()}/hrm/form-templates-by?page=1&cat_slug=${slug}`
        );
      } else if (!role || role === "crm") {
        res = await apiGetRequest(
          `${getUrlRole()}/form-templates-by?page=1&cat_slug=${slug}`
        );
      }

      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        setForm(res.data.data.forms.data);
        if (res.data.data.forms.data.length === 0) {
          toast.error("Form not found!");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (form.length > 0 && form) {
      const formLengthForExit =
        slug === PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.SLUG
          ? form.length
          : null;
      const selectedForm =
        formLengthForExit && formLengthForExit > 1 ? form[1] : form[0];
      navigate(
        `/view-template${
          role === "hrm" ? "/hrm/" : "/"
        }${particpantUuid}/${slug}/${selectedForm.slug}`
        // {
        //   state: {
        //     participantId: participantId ? participantId : "1",
        //     particpantUuid: particpantUuid,
        //     templateObj: {
        //       templateUUID: selectedForm.uuid,
        //       templateSlug: selectedForm.slug,
        //     },
        //   },
        // }
      );
    }
  }, [form]);

  return (
    <div>
      <CustomButton
        loaderCssClass="text-black"
        showLoading={request.isRequested}
        // isDisabled={request.isRequested}
        label={buttonText}
        Icon={<BiPlus />}
        clickHandler={handleClick}
      />
    </div>
  );
};

export default DirectOpenFirstForm;
