import { connect } from "react-redux";
import { getSPParticipantsListRequest } from "store/actions/sp.actions";
import { getParticipantsList } from "store/selector/sp.selector";

import ParticipantExpiringDocuments from "./ParticipantExpiringDocReports";

const mapDispatchToProps = {
  getParticipantList: getSPParticipantsListRequest,
};

const mapStateToProps = (state) => {
  const spParticipants = getParticipantsList(state);
  return {
    spParticipants,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParticipantExpiringDocuments);
