import React, { useRef, useState } from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const PopoverContainer = (props) => {
  const {
    children,
    itemComponent,
    isHover,
    placement = "auto",
    cssClass,
    toolTip,
    rootClose = true,
  } = props;

  const [showPopover, setShowPopover] = useState(false);
  const containerRef = useRef(null);

  const handleMouseEnter = () => {
    setShowPopover(true);
  };

  const handleMouseLeave = () => {
    setShowPopover(false);
  };

  const toolTipPopover = (
    <Popover id='popover-basic' className={`${cssClass} bg-gradient`}>
      <Popover.Content
        style={{
          maxWidth: "500px",
          overflowWrap: "break-word",
          lineHeight: "1.5",
        }}
      >
        <div>{toolTip}</div>
      </Popover.Content>
    </Popover>
  );

  if (toolTip) {
    return (
      <OverlayTrigger
        trigger='manual'
        placement='auto'
        overlay={toolTipPopover}
        show={showPopover}
      >
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={containerRef}
        >
          {children}
        </div>
      </OverlayTrigger>
    );
  }

  const popover = (
    <Popover
      id='popover-basic'
      className={`${cssClass} z-9`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Popover.Content
        style={{
          maxWidth: "500px",
          overflowWrap: "break-word",
          lineHeight: "1.5",
        }}
        onMouseEnter={handleMouseEnter} // Reset timer on mouse enter
        onMouseLeave={handleMouseLeave} // Start timer on mouse leave
      >
        {itemComponent()}
      </Popover.Content>
    </Popover>
  );

  if (isHover) {
    return (
      <OverlayTrigger
        trigger='manual'
        placement={placement}
        overlay={popover}
        show={showPopover}
      >
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={containerRef}
        >
          {children}
        </div>
      </OverlayTrigger>
    );
  }

  if (placement) {
    return (
      <OverlayTrigger
        trigger='manual'
        placement={placement}
        overlay={popover}
        show={showPopover}
      >
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={containerRef}
        >
          {children}
        </div>
      </OverlayTrigger>
    );
  }

  return (
    <OverlayTrigger
      trigger='manual'
      placement='auto'
      overlay={popover}
      show={showPopover}
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={containerRef}
      >
        {children}
      </div>
    </OverlayTrigger>
  );
};

export default PopoverContainer;
