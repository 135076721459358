import React, { Fragment, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import {
  baseURLImage,
  getNameProfile,
  selectedTabState,
} from "../../../Utils/utils";
import { useNavigate } from "react-router-dom";
import {
  PARTICIPANT_PROFILE_TABS,
  USER_ROLE,
} from "../../../Constants/constant";
import { apiGetRequest } from "../../../helpers/Requests";
import { useEffect } from "react";
import QuickSnapshots from "../../../components/common/QuickSnapshots/QuickSnapshots";
import moment from "moment";
import CustomButton from "../../../components/common/Button/CustomButton";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const QuickView = (props) => {
  const { selectedParticipant, isOpen, setIsOpen, paddingLeft } = props;

  const userRole = roleAccess([USER_ROLE.Sp_Standard_User]) ? "worker" : "sp";

  const navigate = useNavigate();
  const [caseNotes, setCaseNotes] = useState([]);
  const [settingData, setSettingData] = useState(null);
  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const fetchParticipantList = async () => {
    setRequest({ ...request, isRequested: true });
    try {
      const res = await apiGetRequest(
        `/${userRole}/participants/quick-view/${selectedParticipant.uuid}`
      );
      if (res.status !== 200) {
        setRequest({ ...request, isRequested: false, isFailed: true });
        console.log("Something Went Wrong");
      } else if (res.status === 200) {
        setRequest({ ...request, isRequested: false, isSuccess: true });
        setCaseNotes(res.data.data.case_notes);
        setSettingData(res.data.data.participant_profile);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (Object.keys(selectedParticipant).length > 0) {
      fetchParticipantList();
    }
  }, [selectedParticipant]);

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const handleCaseNotes = () => {
    if (
      roleAccess(
        [],
        PERMISSION_KEYS.PARTICIPANT,
        PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
      )
    ) {
      selectedTabState.save(JSON.stringify(PARTICIPANT_PROFILE_TABS[1]));
      const routeRole = userRole === "worker" ? "participant" : "sp";
      navigate(`/${routeRole}-profile/${selectedParticipant.uuid}`);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50' onClose={setIsOpen}>
        <div className='min-h-screen absolute top-0 right-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-blue-dark/30' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='inline-block h-screen' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div
              style={{ float: "right", maxWidth: "500px", width: "500px" }}
              className='min-h-screen inline-block py-2 px-6 text-left align-middle transition-all transform bg-white shadow-xl border border-gray-100 min-h-screen max-h-screen overflow-auto custom-scroll'
            >
              {request.isRequested ? (
                <div
                  className='min-h-screen flex justify-center items-center'
                  style={{ minWidth: "25rem", overflow: "hidden !important" }}
                >
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                </div>
              ) : (
                <>
                  <div className='flex justify-between items-center'>
                    <span className='font-bold capitalize'>Quick View</span>
                    <button onClick={setIsOpen}>
                      <AiOutlineClose className='h-5 w-5 hover:text-gray-400' />
                    </button>
                  </div>
                  <div className='mt-6 mb-8 flex items-start space-x-4'>
                    <div className='h-16 w-16 rounded-full'>
                      <img
                        onError={(e) =>
                          handleImageError(
                            e,
                            selectedParticipant.first_name,
                            selectedParticipant.last_name
                          )
                        }
                        className='object-cover rounded-full border-2-white h-16 w-16'
                        src={
                          selectedParticipant.profile_picture
                            ? baseURLImage(selectedParticipant.profile_picture)
                            : getNameProfile(
                                selectedParticipant.first_name,
                                selectedParticipant.last_name || " "
                              )
                        }
                        alt=''
                      />
                    </div>
                    <div>
                      <p className='text-gray-900 text-lg font-medium capitalize'>
                        {`${selectedParticipant.first_name} ${selectedParticipant.last_name}`}
                      </p>
                      {roleAccess(
                        [],
                        PERMISSION_KEYS.PARTICIPANT,
                        PERMISSION_KEYS_CHILDREN.PARTICIPANT.PRIMARY_EMAIL
                      ) && (
                        <p className='text-gray-500'>
                          {selectedParticipant.email}
                        </p>
                      )}
                    </div>
                  </div>
                  {roleAccess(
                    [],
                    PERMISSION_KEYS.PARTICIPANT,
                    PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                  ) && (
                    <div className='mt-4 flex space-x-4'>
                      <CustomButton
                        variant='secondary'
                        clickHandler={handleCaseNotes}
                        label='Add Case Notes'
                      />
                      <CustomButton
                        clickHandler={() => {
                          if (
                            roleAccess(
                              [],
                              PERMISSION_KEYS.PARTICIPANT,
                              PERMISSION_KEYS_CHILDREN.PARTICIPANT.DETAIL
                            )
                          ) {
                            const routeRole =
                              userRole === "worker" ? "participant" : "sp";
                            navigate(
                              `/${routeRole}-profile/${selectedParticipant.uuid}`
                            );
                          }
                        }}
                        label='View profile'
                      />
                    </div>
                  )}
                  <div className='mt-1'>
                    <QuickSnapshots
                      isQuickViewCall={true}
                      cssClass='mt-0'
                      paddingLeft={paddingLeft}
                      participantsInfo={selectedParticipant}
                      settingsData={settingData}
                    />
                  </div>
                  <div>
                    <h2 className='text-lg leadin-6 font-medium text-gray-900 font-bold'>
                      Case Notes
                    </h2>
                    <ul className='mt-2 divide-y divide-gray-200 border-t border-b border-gray-200'>
                      {caseNotes &&
                        caseNotes.length > 0 &&
                        caseNotes.map((casenote) => (
                          <li className='grid items-center justify-between py-3'>
                            <div className='flex items-center'>
                              <p className='text-sm font-medium text-gray-900'>
                                {casenote?.author?.name}
                              </p>
                            </div>
                            <div className=''>
                              <p
                                className='text-gray-500 text-sm font-medium hover:text-gray-600'
                                title={casenote.notes}
                              >
                                {casenote.notes}
                              </p>
                              <p className='text-gray-500 text-sm font-medium hover:text-gray-600'>
                                {moment(casenote.created_at).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </li>
                        ))}
                      {caseNotes && caseNotes.length <= 0 && (
                        <h1>No case notes available</h1>
                      )}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default QuickView;
