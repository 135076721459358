import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import {
  ALL_FORM_LIST_LIMIT,
  formTemplateStatusOptions,
  LOADER_STATUS,
  SORT_TYPE,
  USER_ROLE,
} from "../../../Constants/constant";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import Search from "../../../components/common/Search/Search";
import SelectOption from "../../../components/common/Select/Select";
import TableHeader from "../../../components/common/Table/TableHeader";
import Header from "../../../components/common/Header/Header";
import { useNavigate } from "react-router";
import { roleAccess } from "helpers/RolesPermission";
import PaginationComponent from "components/common/Pagination/index";
import FormsListActionButtons from "./FormsListActionButtons";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";

const Forms = ({
  templateList,
  editFormTemplateHandler,
  isLoading,
  queryParams,
  formCategoriesList,
  handleFetch,
  getSortBy,
  paginationObj,
  setPaginationObj,
  routeRole,
  isUserGracePeriodOver,
}) => {
  const [search, setSearch] = useState(null);
  const [sortBy, setSortBy] = useState(queryParams.sort_field);
  const [selectedFormCategories, setSelectedFormCategories] = useState([]);
  const [sortDescending, setSortDescending] = useState(
    queryParams.sort_order === SORT_TYPE.DESC ? true : false
  );
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [formTemplateStatus, setFormTemplateStatus] = useState(
    formTemplateStatusOptions[0]
  );

  const [renderIsLoading, setRenderIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    getSortBy(sortBy);
  }, [sortBy]);

  useEffect(() => {
    setSortBy(queryParams.sort_field ? queryParams.sort_field : null);
    if (queryParams.sort_order) {
      setSortDescending(
        queryParams.sort_order
          ? queryParams.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
    }
    if (queryParams.hasOwnProperty("filter")) {
      if (queryParams.filter !== null) {
        setSelectedFormCategories(queryParams.filter.form_category);
      }
    }
    if (queryParams.search) {
      setSearch(queryParams.search);
    }
  }, [queryParams]);

  useEffect(() => {
    setRenderLoading(LOADER_STATUS.PENDING);
    const timeOutId = setTimeout(
      () =>
        !isLoading &&
        handleFetch(
          {
            status: formTemplateStatus.value,
            search: search,
          },
          sortBy
        ),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    if (!isLoading && renderLoading === LOADER_STATUS.PENDING) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
      setRenderIsLoading(false);
    }
  }, [isLoading]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(!sortDescending);
    handleFetch({
      status: formTemplateStatus.value,
      sort_field: by,
      sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
    });
  };

  return (
    <>
      <div className='bg-white'>
        <Header
          title='Forms'
          desc='All your Forms in one place'
          {...(roleAccess([
            USER_ROLE.Super_Admin,
            USER_ROLE.Sp_Admin,
            USER_ROLE.Sp_manager,
          ]) === true &&
            roleAccess(
              [],
              routeRole === "hrm"
                ? PERMISSION_KEYS.HRM_FORM
                : PERMISSION_KEYS.CRM_FORM,
              routeRole === "hrm"
                ? PERMISSION_KEYS_CHILDREN.HRM_FORM.CREATE
                : PERMISSION_KEYS_CHILDREN.CRM_FORM.CREATE
            ) && {
              handleClick: () =>
                navigate(
                  routeRole === "hrm"
                    ? "/hrm/create-new-report"
                    : "/crm/create-new-report"
                ),
              btnLabel: `Create Form ${routeRole.toUpperCase()}`,
              icon: <BiPlus />,
              isButton: true,
            })}
        />

        <div className='flex justify-between items-center sm:px-6 lg:px-8 pt-8 pb-2 sticky top-16 bg-white z-[8]'>
          <div className='w-[50%] flex space-x-2'>
            <div className='w-[60%]'>
              <SelectOption
                cssClass='z-20'
                isDisabled={isLoading || renderIsLoading}
                isSearch={false}
                selectedOption={selectedFormCategories}
                handleChange={(e) => {
                  handleFetch(
                    {
                      status: formTemplateStatus.value,
                      form_category: e,
                      page: 1,
                    },
                    sortBy
                  );
                  const options = e.map((some) => ({
                    value: some.id,
                    label: some.label,
                  }));
                  setSelectedFormCategories([...options]);
                }}
                data={formCategoriesList}
                isMulti={true}
              />
            </div>
            {roleAccess([
              USER_ROLE.Super_Admin,
              USER_ROLE.Sp_Admin,
              USER_ROLE.Sp_manager,
            ]) && (
              <div className='w-[40%]'>
                <SelectOption
                  isDisabled={isLoading || renderIsLoading}
                  isSearch={false}
                  selectedOption={formTemplateStatus}
                  handleChange={(e) => {
                    setFormTemplateStatus(e);
                    handleFetch({ status: e.value });
                  }}
                  data={formTemplateStatusOptions}
                  cssClass='z-20'
                />
              </div>
            )}
          </div>
          <Search setSearch={setSearch} search={search} />
        </div>
        <div className='flex flex-col sm:px-6 lg:px-8 pb-6 w-full max-w-7xl mx-auto'>
          <div className='-my-2 overflow-x-auto custom-scroll'>
            <div className='inline-block min-w-full py-2 align-middle'>
              <div className='overflow-hidden111 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                {isLoading || renderIsLoading ? (
                  <div
                    className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
                  >
                    <CircularLoader
                      classes='flex justify-center items-center'
                      classloader='loader-l'
                    />
                  </div>
                ) : (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-white capitalize'>
                      <tr className='sticky top-0'>
                        <TableHeader
                          sortName='title'
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          label='Title'
                        />
                        <TableHeader
                          isSort={false}
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          label='Category'
                        />
                        <TableHeader
                          isSort={false}
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          label='Subcategory'
                        />
                        {roleAccess([USER_ROLE.Super_Admin]) && (
                          <TableHeader
                            isSort={false}
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            label='Form Status'
                          />
                        )}
                        <th
                          scope='col'
                          className='py-3.5 pl-3 pr-4 sm:pr-6 text-sm font-semibold text-gray-900 bg-blue-50 z-0'
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {templateList &&
                        templateList
                          .sort((a, b) => a.title.localeCompare(b.title))
                          .map((template) => (
                            <tr
                              key={template.id}
                              className='even:bg-white odd:bg-gray-50'
                            >
                              <td className='whitespace-nowrap py-4  px-3 text-sm'>
                                <div className='flex items-center'>
                                  <div className=''>
                                    <div className='capitalize font-medium text-gray-900'>
                                      {template.title}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-900 capitalize'>
                                  {template.category_name}
                                </div>
                              </td>
                              <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                <div className='text-gray-900 capitalize'>
                                  {template.sub_category_name
                                    ? template.sub_category_name
                                    : "-"}
                                </div>
                              </td>
                              {roleAccess([USER_ROLE.Super_Admin]) && (
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  <div className='text-gray-900 capitalize'>
                                    {queryParams?.filter?.status === "publish"
                                      ? "Published"
                                      : "Draft" ?? "-"}
                                  </div>
                                </td>
                              )}
                              <td>
                                <FormsListActionButtons
                                  editFormTemplateHandler={
                                    editFormTemplateHandler
                                  }
                                  formTemplateStatus={formTemplateStatus}
                                  template={template}
                                  routeRole={routeRole}
                                  isUserGracePeriodOver={isUserGracePeriodOver}
                                />
                              </td>
                            </tr>
                          ))}
                      {renderLoading === LOADER_STATUS.COMPLATE &&
                        templateList?.length === 0 && (
                          <tr>
                            <td
                              colSpan={7}
                              className='py-6 text-center text-red-600'
                            >
                              No data found !
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {formCategoriesList && formCategoriesList.length > 0 && (
        <div className='bg-white sm:px-6 lg:px-8 max-w-7xl pb-10'>
          <PaginationComponent
            page={paginationObj.page}
            activeListLength={templateList}
            limit={ALL_FORM_LIST_LIMIT}
            totalList={paginationObj.total}
            handlePagination={(e) => {
              const obj = paginationObj;
              obj.page = e;
              setPaginationObj({ ...obj });
              handleFetch(
                { page: e, status: formTemplateStatus.value },
                sortBy
              );
            }}
            totalPages={paginationObj.totalPages}
          />
        </div>
      )}
    </>
  );
};

export default Forms;
