import { connect } from "react-redux";
import {
  getCatalogServicesSubCategoryRequest,
  getPlanBudgetCatAndSubCatRequest,
  serviceSupportCreateRequest,
  serviceSupportBudgetFetchRequest,
} from "../../../../store/actions/cos.actions";
import {
  getCreateServiceSupportError,
  getCreateServiceSupportLoader,
  getExternalServiceProviders,
  getSelectedFunding,
  getServiceCatalogStates,
  getServiceSupportBudget,
  getServiceSupportBudgetFetchLoader,
} from "../../../../store/selector/cos.selector";
import { getSelectedParticipant } from "../../../../store/selector/participant.selector";
import ServiceSupport from "./ServiceSupport";

const mapDispatchToProps = {
  getPlanBudgetCatAndSubCat: getPlanBudgetCatAndSubCatRequest,
  getCatalogServicesSubCategory: getCatalogServicesSubCategoryRequest,
  serviceSupportCreate: serviceSupportCreateRequest,
  getServiceSupportList: serviceSupportBudgetFetchRequest,
};

const mapStateToProps = (state) => {
  const selectedFunding = getSelectedFunding(state);
  const participant = getSelectedParticipant(state);
  const externalSps = getExternalServiceProviders(state);
  const catalogStates = getServiceCatalogStates(state);
  const createServiceSupportLoader = getCreateServiceSupportLoader(state);
  const createServiceSupportError = getCreateServiceSupportError(state);
  const serviceSupportBudgets = getServiceSupportBudget(state);
  const fetchServiceSupportLoader = getServiceSupportBudgetFetchLoader(state);
  return {
    selectedFunding,
    participant,
    externalSps,
    catalogStates,
    createServiceSupportLoader,
    createServiceSupportError,
    serviceSupportBudgets,
    fetchServiceSupportLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSupport);
