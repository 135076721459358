import React, { useContext, useEffect } from "react";
import { LoginContext } from "helpers/LoginContext";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SideBarNew from "components/common/SideBar/SideBarNew";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import {
  SETTINGS_BREADCRUMB_PAGES,
  SP_SETTING_BREADCRUMB,
} from "Constants/BreadcrumbConstant";
import { FaQuestionCircle } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import { Tooltip } from "react-tooltip";
// import InputField from "components/common/Input/Input";
import SelectOption from "components/common/Select/Select";
import { calendarViewOptions } from "Constants/constant";
import DateRangeSelect from "components/common/Select/DateRangeSelect";

const RosteringSetting = ({
  spRosteringSettingLoader,
  spRosteringSetting,
  spRosteringSettingsRequest,
  spRosteringSettingsUpdateRequest,
  spRosteringSettingUpdateLoader,
}) => {
  const { sideBarPadding } = useContext(LoginContext);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
    control,
    clearErrors,
  } = useForm();

  useEffect(() => {
    spRosteringSettingsRequest();
  }, []);

  useEffect(() => {
    reset(spRosteringSetting);
  }, [spRosteringSetting]);

  const watchFormData = watch();

  const onSubmit = (data) => {
    try {
      const payload = {
        // apply_alternate_rate: data.apply_alternate_rate,
        // default_alternate_rate: data.default_alternate_rate,
        apply_km_to_invoice: data.apply_km_to_invoice,
        participant_signature: data.participant_signature,
        shift_acceptance_required: data.shift_acceptance_required,
        worker_tracking: data.worker_tracking,
        timesheet_invoices: data.timesheet_invoices,
        roster_default_view: data.roster_default_view,
      };
      if (
        data.calendar_type?.value === "previous" ||
        data.calendar_type?.value === "current"
      ) {
        Object.assign(payload, {
          calendar_type: "fortnightly",
          default_fortnight:
            data.calendar_type?.value === "previous" ? "f2" : "f1",
        });
      } else if (data.calendar_type?.value === "weekly") {
        Object.assign(payload, {
          calendar_type: "weekly",
          default_fortnight: null,
        });
      }

      spRosteringSettingsUpdateRequest(payload);
    } catch (err) {
      console.log("err", err);
    }
  };

  // console.log("watchFormData", watchFormData);

  const getToolTipContent = () => {
    return (
      <div className='flex flex-col gap-4 p-2'>
        <p className='text-xs text-white flex items-center '>
          <SwitchWithIcon checked={true} disabled={true} cssClass='mr-2' />
          Worker need to manually accept this shift
        </p>
        <p className='text-xs text-white flex items-center '>
          <SwitchWithIcon checked={false} disabled={true} cssClass='mr-2' />{" "}
          Shifts will be automatically accepted without requiring worker manual
          confirmation
        </p>
      </div>
    );
  };

  return (
    <div>
      <div className='min-h-full'>
        <SideBarNew />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <Header title='Rostering Settings' />
          <section className='container mx-auto sm:px-6 lg:px-8 mt-3'>
            <div className='mb-5 border-gray-200 py-3'>
              <Breadcrumb
                pages={SP_SETTING_BREADCRUMB(
                  SETTINGS_BREADCRUMB_PAGES.ROSTERING_SETTING
                )}
              />
            </div>
            {spRosteringSettingLoader ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <div className='mt-5 md:mt-0 md:col-span-2'>
                <div className='max-w-7xl mx-auto'>
                  <div className='shadow sm:rounded-md sm:overflow-hidden p-4 bg-gray-50'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className=''>
                        <h5 className='px-2 pb-2 font-bold'>
                          Schedule Settings
                        </h5>
                        <div className='grid grid-cols-2 gap-x-2 gap-y-6'>
                          <div className='col-span-1 px-2'>
                            <div className='flex justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Scheduled view
                              </label>
                              <Controller
                                name='roster_default_view'
                                control={control}
                                defaultValue={
                                  watchFormData?.roster_default_view || true
                                }
                                render={({ field }) => {
                                  // console.log("field", field);
                                  const feildValue = field.value?.value
                                    ? field.value.value
                                    : field.value;
                                  return (
                                    <SelectOption
                                      cssClass=''
                                      isSearch={false}
                                      selectedOption={
                                        feildValue
                                          ? calendarViewOptions?.find(
                                              (x) =>
                                                x.value === "worker-view" &&
                                                feildValue === "worker"
                                            )?.value
                                            ? calendarViewOptions[1]
                                            : calendarViewOptions[0]
                                          : null
                                      }
                                      handleChange={(e) => {
                                        field.onChange(e?.value);
                                        setValue(
                                          "roster_default_view",
                                          e?.value === "worker-view"
                                            ? "worker"
                                            : "participant"
                                        );
                                      }}
                                      data={calendarViewOptions}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Calendar View
                              </label>
                              <Controller
                                name='calendar_type'
                                control={control}
                                defaultValue={
                                  watchFormData?.calendar_type || true
                                }
                                render={({ field }) => (
                                  <DateRangeSelect
                                    defaultType={
                                      field.value?.value
                                        ? field.value?.value === "previous" ||
                                          field.value?.value === "current"
                                          ? "fortnightly"
                                          : "weekly"
                                        : field.value
                                    }
                                    defaultFortnightView={
                                      watchFormData?.default_fortnight
                                    }
                                    onChangeView={(e) => {
                                      setValue("calendar_type", e);
                                      field.onChange(e);
                                    }}
                                    onlyView={true}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Worker Tracking
                              </label>
                              <Controller
                                name='worker_tracking'
                                control={control}
                                defaultValue={
                                  watchFormData?.worker_tracking || true
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("worker_tracking", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Participant's Signature
                              </label>
                              <Controller
                                name='participant_signature'
                                control={control}
                                defaultValue={
                                  watchFormData?.participant_signature || false
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("participant_signature", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1  sm:text-xs flex items-center justify-center'>
                                Default Shift Acceptance Required
                                <a data-tooltip-id='my-tooltip'>
                                  <FaQuestionCircle
                                    data-tip
                                    data-tooltip-id='manualAcceptHelp'
                                    className='ml-1 text-gray-500 cursor-pointer'
                                  />
                                </a>
                              </label>
                              <Tooltip
                                id='my-tooltip'
                                place='top'
                                content={getToolTipContent()}
                                variant='dark'
                                className='z-[501]'
                              />
                              <Controller
                                name='shift_acceptance_required'
                                control={control}
                                defaultValue={
                                  watchFormData?.shift_acceptance_required
                                }
                                render={({ field }) => {
                                  return (
                                    <SwitchWithIcon
                                      onChange={(value) => {
                                        field.onChange(value);
                                        setValue(
                                          "shift_acceptance_required",
                                          value
                                        );
                                      }}
                                      checked={field.value}
                                      disabled={false}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className='col-span-1 px-2' /> */}
                        </div>
                      </div>

                      <div className='w-full h-0.5 mx-1 bg-gray-300 my-8'></div>

                      <div className=''>
                        <h5 className='px-2 pb-2 font-bold'>
                          Timesheet Settings
                        </h5>
                        <div className='grid grid-cols-2 gap-x-2 gap-y-6'>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Show/Hide Invoice section in Timesheet
                              </label>
                              <Controller
                                name='timesheet_invoices'
                                control={control}
                                defaultValue={
                                  watchFormData?.timesheet_invoices || false
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("timesheet_invoices", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>
                          </div>

                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Apply KMs to Invoices
                              </label>
                              <Controller
                                name='apply_km_to_invoice'
                                control={control}
                                defaultValue={
                                  watchFormData?.apply_km_to_invoice || false
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("apply_km_to_invoice", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>
                          </div>

                          {/* <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Apply Alternative Rate
                              </label>
                              <Controller
                                name='apply_alternate_rate'
                                control={control}
                                defaultValue={
                                  watchFormData?.apply_alternate_rate || true
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("apply_alternate_rate", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>
                          </div> */}

                          {/* <div className='col-span-1 px-2'>
                            <div className='flex justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Alternate Rate
                              </label>
                              <Controller
                                name='default_alternate_rate'
                                control={control}
                                defaultValue={
                                  watchFormData?.default_alternate_rate || 0
                                }
                                rules={{
                                  required: "This field is required",
                                  validate: {
                                    positive: (value) =>
                                      value >= 0 ||
                                      "Only positive numbers are allowed",
                                    // integer: (value) =>
                                    //   Number.isInteger(parseFloat(value)) ||
                                    //   "Only integers are allowed",
                                  },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                  <>
                                    <InputField
                                      cssClass='mt-0 h-40-important'
                                      // inputWidth='w-full'
                                      value={
                                        watchFormData?.default_alternate_rate
                                      }
                                      name='default_alternate_rate'
                                      isRequired={true}
                                      type='number'
                                      step='0.01'
                                      handleChange={(e) => {
                                        const value = e;
                                        if (/^\d*\.?\d*$/.test(value)) {
                                          field.onChange(value);
                                          setValue(
                                            "default_alternate_rate",
                                            value
                                          );
                                        }
                                      }}
                                    />
                                  </>
                                )}
                              />
                            </div>
                            {errors?.default_alternate_rate && (
                              <span className='text-red-500 text-sm'>
                                {errors?.default_alternate_rate?.message}
                              </span>
                            )}
                          </div> */}
                        </div>
                      </div>
                      <button
                        id='rosteringSettingsBtnUpdate'
                        type='submit'
                        className='hidden sr-only'
                      />
                    </form>
                  </div>

                  <div className='mt-6 flex justify-end'>
                    <CustomButton
                      showLoading={spRosteringSettingUpdateLoader}
                      isDisabled={spRosteringSettingUpdateLoader}
                      variant='primary'
                      clickHandler={() => {
                        const buttonRef = document.getElementById(
                          "rosteringSettingsBtnUpdate"
                        );
                        if (buttonRef) {
                          buttonRef.click();
                        }
                      }}
                      label='Update Changes'
                    />
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default RosteringSetting;
