import { connect } from "react-redux";
import CaseNotesHistoryMainPage from "./caseNotesHistoryMainPage";
import {
  getClientsRequest,
  getContactListRequest,
  getCaseNotesHistoryRequest,
} from "../../../../store/actions/cos.actions";
import {
  getClienListSelector,
  getCaseNotesHistoryListSelector,
  getCaseNotesHistoryListLoaderSelector,
  getContactListOPTSelector,
} from "../../../../store/selector/cos.selector";

const mapDispatchToProps = {
  getClientList: getClientsRequest,
  getCaseNotesHistoryList: getCaseNotesHistoryRequest,
  getContactListRequest,
};

const mapStateToProps = (state) => {
  const clientsList = getClienListSelector(state);

  const caseNotesHistoryList = getCaseNotesHistoryListSelector(state);
  const caseNotesHistoryLoading = getCaseNotesHistoryListLoaderSelector(state);
  const ContactList = getContactListOPTSelector(state);

  return {
    ContactList,
    clientsList,
    caseNotesHistoryList,
    caseNotesHistoryLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseNotesHistoryMainPage);
