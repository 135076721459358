import NumberFormatter from "components/common/NumberFormatter/NumberFormatter";
import React from "react";
import { formatDateIntoDMYToDisplay } from "Utils/utils";
import pic from "../../../../assets/logo/fav.png";

const PlanBudgetPdfView = (props) => {
  const { data } = props;

  return (
    <>
      <div
        className='invoice-box'
        style={{
          maxWidth: "75%",
          margin: "auto",
          padding: "30px",
          boxShadow: "0 0 10px rgba(0, 0, 0, .15)",
          fontSize: "16px",
          lineHeight: "0px",
        }}
      >
        <table
          cellpadding='0'
          cellspacing='0'
          style={{ width: "100%", lineHeight: "inherit", textAlign: "left" }}
        >
          <tr className='information'>
            <td colspan='2' style={{ padding: "5px", verticalAlign: "top" }}>
              <table
                style={{
                  lineHeight: "inherit",

                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <tr>
                  <td
                    style={{
                      padding: "5px",
                      verticalAlign: "top",
                      paddingBottom: "40px",

                      width: "150px",
                    }}
                  >
                    <img src={pic} className='img-fluid' alt='Logo-vertex' />
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      verticalAlign: "top",
                      textAlign: "right",
                      paddingBottom: "40px",
                    }}
                  ></td>
                </tr>
              </table>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Funding Contract Id
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span>{data.funding_contract_id}</span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Funding Provider Name
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span className='capitalize'>
                {data?.selectedFunding?.created_by}
              </span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Participant Name
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span className='capitalize'>{`${data?.participant?.first_name} ${data?.participant?.last_name}`}</span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Plan Sub Category
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                borderBottom: "1px solid #ddd",
                paddingTop: "40px",
                paddingBottom: "30px",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span>{data.service_sub_category.title}</span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Funding Source
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                borderBottom: "1px solid #ddd",
                paddingTop: "40px",
                paddingBottom: "30px",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span className='capitalize'>
                {data?.selectedFunding?.funding_source === "ndis"
                  ? "NDIS"
                  : data?.selectedFunding?.funding_source}
              </span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Total Budget
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                borderBottom: "1px solid #ddd",
                paddingTop: "40px",
                paddingBottom: "30px",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span>
                {`$${
                  data.total_budget_amount
                    ? parseFloat(data.total_budget_amount).toFixed(2)
                    : 0
                }`}
              </span>
            </td>
          </tr>

          <tr classNameclassName='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Total Allocated
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                textAlign: "right",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span>{`$${
                data.amount_allocated
                  ? parseFloat(data.amount_allocated).toFixed(2)
                  : 0
              }`}</span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              Total Delivered
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span>{`$${
                data.amount_delivered
                  ? parseFloat(data.amount_delivered).toFixed(2)
                  : 0
              }`}</span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              Remaining
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span>{`$${
                data.amount_remaining
                  ? parseFloat(data.amount_remaining).toFixed(2)
                  : 0
              }`}</span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Plan Budget Created Date
            </td>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span>{formatDateIntoDMYToDisplay(data.created_at)}</span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Funding Start Date
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span>
                {formatDateIntoDMYToDisplay(data?.selectedFunding?.start_date)}
              </span>
            </td>
          </tr>

          <tr className='heading'>
            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              Funding End Date
            </td>

            <td
              style={{
                padding: "5px",
                verticalAlign: "top",
                textAlign: "right",
                paddingTop: "40px",
                paddingBottom: "30px",
                borderBottom: "1px solid #ddd",
                fontWeight: "bold",
                fontSize: "25px",
              }}
            >
              <span>
                {formatDateIntoDMYToDisplay(data?.selectedFunding?.end_date)}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default PlanBudgetPdfView;
