import React from "react";
import { FiSearch } from "react-icons/fi";

const Search = ({
  search,
  setSearch,
  isDisabled,
  isSearchOnly,
  handleClick,
  cssClass,
  placeholder,
  width,
  customClassSearch
}) => {
  return (
    <>
      {isSearchOnly ? (
        <div role="button" tabIndex={0} onClick={handleClick}>
          <FiSearch
            className={`${
              cssClass
                ? cssClass
                : "absolute top-1/2 -translate-y-1/2 text-gray-400 left-4"
            }`}
          />
        </div>
      ) : (
        <div className="relative">
          <FiSearch className="absolute top-1/2 -translate-y-1/2 text-gray-400 left-4" />
          <input
            autoComplete="off"
            disabled={isDisabled}
            type="text"
            className={`${customClassSearch} border rounded-lg pr-4 pl-10 py-1.6 text-sm placeholder:text-sm placeholder:leading-none ${
              isDisabled ? "bg-gray-100" : ""
            } ${width ? width : "xl:w-52"}`}
            placeholder={placeholder ? placeholder : "Search"}
            value={search === null ? "" : search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      )}
    </>
  );
};

export default Search;
