import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const TextEditor = (props) => {
  const { text, setText } = props;
  return (
    <CKEditor
      editor={ClassicEditor}
      data={text}
      onReady={(editor) => {
        // console.log("Editor is ready to use!", editor);
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        setText(data);
      }}
      onBlur={(event, editor) => {
        // console.log("Blur.", editor);
      }}
      onFocus={(event, editor) => {
        // console.log("Focus.", editor);
      }}
    />
  );
};
export default TextEditor;
